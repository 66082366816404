import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { Utils } from 'src/app/utils/common-functions';
import {Router, ActivatedRoute} from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/services/message/message.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-service-request-track',
  templateUrl: './service-request-track.component.html',
  styleUrls: ['./service-request-track.component.css']
})
export class ServiceRequestTrackComponent implements OnInit {
  isHNIUSer:boolean = false;
  userDetails: any = {};
  srTrackDetails: any = [];
  SRTypeArray:any = [];
  userName;
  trackStatus;
  requestedDate;
  trackPercentage = 0;
  firstBall = false;
  secondBall = false;
  thirdBall = false;
  fourthBall = false;
  fifthBall = false;
  srTrackResp: any;
  redirectionLink: any;
  statusMessage: any;
  policyNum: any;
  srNum: any;
  srNumber: string;

  constructor(public dataService: DataService, public createSrService: CreateSRService, public _httpService: HttpService, public utils: Utils, public router: Router,@Inject(MAT_DIALOG_DATA) public data,public customLinkService: CustomLinkService,
  public httpService: HttpService,private snackBar: MatSnackBar, private message: MessageService,public dialog: MatDialog,public auth: AuthService,public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
       this.isHNIUSer = true;
     }
     if(this.data.source && this.data.source == 'SRTrackCustomLink') {
      this.getCustomSRTrack();
      this.srNumber = this.route.snapshot.queryParamMap.get('sr_num');
     }
     else {
     this.userName = `${this.userDetails.FIRST_NM && this.userDetails.FIRST_NM != "null" ? this.userDetails.FIRST_NM : ''} ${this.userDetails.LAST_NM && this.userDetails.LAST_NM != "null" ? this.userDetails.LAST_NM : ''}`;
     this.SRTypeArray = this.createSrService.getSRTypeResp();
     this.getServiceRequestDtls();
     }
     }
 getServiceRequestDtls() {
  let getSRTrackReqBody = {
    "sr_num": this.SRTypeArray[0].srNum,
    "clientID": this.utils.getClientId()
}
this.createSrService.getTrackPercentage(getSRTrackReqBody)
.subscribe((resp) => { 
  if (this._httpService.isSuccessResponse(resp) && resp.body) {
      this.srTrackDetails.push({"srType": resp.body.reqType, "policyNum": resp.body.policyNum,"srNum": this.SRTypeArray[0].srNum});
      this.trackStatus = resp.body.status;
      this.requestedDate = resp.body.createdDate;
      this.trackPercentage = resp.body.trackerPercent;
      if(this.trackPercentage < 20) {
        this.firstBall = false;
        this.secondBall = true;
        this.thirdBall = true;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 20 && this.trackPercentage < 40) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = true;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 40 && this.trackPercentage < 60) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 60 && this.trackPercentage < 100) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = false;
        this.fifthBall = true;
      } else if(this.trackPercentage < 100) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = false;
        this.fifthBall = false;
      } 
    } else {
    this._httpService.apiErrorHandler(resp);
  }
});
 }
 getCustomSRTrack() {
   let reqBody = {
     sr_num: this.data.srnumber,
     //clientID: this.data.clientId,
   }
   return this.customLinkService.fetchSRTrackDtls(reqBody).subscribe((resp) => {
    if (this._httpService.isSuccessResponse(resp)) {
      this.srTrackResp = resp.body;
      this.trackPercentage = resp.body.trackerPercent;
      this.srTrackDetails.push({"srType": resp.body.liveSRDetails.REQTYPE, "policyNum": resp.body.liveSRDetails.POLICY_NUM,"srNum": resp.body.liveSRDetails.SR_NUM,});
      this.trackStatus = resp.body.liveSRDetails.status;
      this.requestedDate = resp.body.liveSRDetails.CREATEDDATE;
      this.policyNum =  resp.body.liveSRDetails.POLICY_NUM;
      this.srNum =  resp.body.liveSRDetails.SR_NUM;
      if(this.trackPercentage < 20) {
        this.firstBall = false;
        this.secondBall = true;
        this.thirdBall = true;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 20 && this.trackPercentage < 40) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = true;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 40 && this.trackPercentage < 60) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = true;
        this.fifthBall = true;
      } else if(this.trackPercentage >= 60 && this.trackPercentage < 100) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = false;
        this.fifthBall = true;
      } else if(this.trackPercentage < 100) {
        this.firstBall = false;
        this.secondBall = false;
        this.thirdBall = false;
        this.fourthBall = false;
        this.fifthBall = false;
      } 
    } else {
      this._httpService.apiErrorHandler(resp);
    }
  });
 }
 getSrDocUploadLink(id) {
  let reqBody = {
    srNo: this.srNum,
    policy: this.policyNum,
    req_type: id,
  }
  return this.customLinkService.getSrDocUploadLink(reqBody)
   .subscribe((response) => {
     if ((this._httpService.isSuccessResponse(response))) {
       if(id == 'UPLOAD_NOW') {
         this.redirectionLink = response.body.Link;
         window.open(this.redirectionLink , '_blank','noopener noreferrer')
         this.auth.logout('M');
       }
       else if(id == 'UPLOAD_LATER') {
       if ((response.body.status).toUpperCase() == "SUCCESS") {
         this.statusMessage = "Message Triggered Successfully";
         this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar']);
       }
       else if ((response.body.status).toUpperCase() == "INVALID") {
         this.statusMessage = "Message Trigger Limit Exceeded";
         this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
       }
       else {
         this.statusMessage = "Message Trigerring Failed";
         this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
       }
     }
     }
   }),
   catchError((error) => throwError(error))
}
closePopup() {
  if(this.srNumber) {
    this.auth.logout('M');
  }
  else {
    this.dialog.closeAll();
  }
}
}
