import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { catchError, tap, startWith } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { ExistingNeftComponent } from '../existing-neft/existing-neft.component';
import { Utils } from 'src/app/utils/common-functions';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { UpdateMailingAddressComponent } from 'src/app/modules/my-account/update-mailing-address/update-mailing-address.component';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  neftDtls: any = {
    "ACCNAME": "",
    "ACC_NUM": "",
    "BANKNAME": "",
    "BRANCHNAME": "",
    "IFSCCODE": "",
    "MICRCODE": "",
    "NEFTACCOUNTTYPE": "",
  };
  @Input() context: string = ''; //MY_ACCOUNT, POLICY_DETAILS, MATURITY_CLAIM, DEEP_LINK
  role: string = '';
  policyNo: string = '';
  beneficiaryDtls: any = {};
  existingDtls: any = {};
  userDtls: any = {};
  customLinkDtls: any = {};
  existingAccount: any;
  fetchDetailsbtn: boolean;

  constructor(public httpService: HttpService, public createSrService: CreateSRService, public utils: Utils, public dialog: MatDialog, public dataService: DataService, public myAccountService: MyAccountService) {
    this.dataService.loginResp$.subscribe(userDtls => {
      if (userDtls) {
        this.customLinkDtls = this.dataService.getLoginResp();
        this.policyNo = this.customLinkDtls && this.customLinkDtls.Policyno ? this.customLinkDtls.Policyno : '';
        this.getNeftDtls();
      }
    });
  }
   
  ngOnInit(): void {
    this.customLinkDtls = this.dataService.getLoginResp();
    this.policyNo = this.customLinkDtls && this.customLinkDtls.Policyno ? this.customLinkDtls.Policyno : '';
    if (this.customLinkDtls) {
      this.getNeftDtls();
    }
  }

  /* ngOnChanges(eve: SimpleChanges) {
    
      if (eve.neftDtls && !eve.neftDtls.isFirstChange()) {
        this.neftDtls = eve.neftDtls.currentValue;
        this.existingDtls = {
          NEFTup:Object.assign({}, this.neftDtls), 
          clientid: this.utils.getClientId(), 
          policyNo: this.policyNo.replace(/\s+/g,''),
          role: "Owner",
        }
      }     
    
    if (eve.context.currentValue == "POLICY_DETAILS") {
      this.neftDtls = {
        "ACCNAME": eve.neftDtls.currentValue["BEN_ACCNAME"],
        "ACC_NUM": eve.neftDtls.currentValue["BEN_ACCNUMBER"],
        "BANKNAME": eve.neftDtls.currentValue["BEN_BANKNAME"],
        "BRANCHNAME": eve.neftDtls.currentValue["BEN_BRANCHNAME"],
        "IFSCCODE": eve.neftDtls.currentValue["BEN_IFSCCODE"],
        "MICRCODE": eve.neftDtls.currentValue["BEN_MICRCODE"],
        "NEFTACCOUNTTYPE": eve.neftDtls.currentValue["BEN_ACCTYPE"],
      }
      this.beneficiaryDtls = eve.neftDtls.currentValue;
      this.existingDtls = {
        NEFTup:Object.assign({}, this.beneficiaryDtls), 
        clientid: this.beneficiaryDtls.CLIENTID, 
        policyNo: this.policyNo.replace(/\s+/g,''),
        role: this.role, 
        id: "beneficiary", 
        mobileNo: this.beneficiaryDtls.BEN_MOBILENOR, 
        emailId: this.beneficiaryDtls.BEN_EMAILID
      }
      return;
    }   
  } */
  getNeftDtls() {
    let reqBody = {
      clientid: this.utils.getClientId(),
      policyno: this.policyNo.replace(/\s+/g,''),
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(reqBody).subscribe((response) => { 
      if (this.httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length > 0) {            
          this.existingAccount = true;            
          //this.neftDtls = response.body[0];
          /* for (let key of Object.keys(response.body[0])) {
            this.neftDtls[key] = response.body[0][key];
          } */
          Object.assign(this.neftDtls, response.body[0]);          
        }else{
          this.existingAccount = false;            
        }
        this.existingDtls = {
          NEFTup: Object.assign({}, this.neftDtls), 
          clientid: this.utils.getClientId(), 
          policyNo: this.policyNo.replace(/\s+/g,''),
          role: "Owner",
        }
      } else {          
        this.httpService.apiErrorHandler(response);
      }
    }),
    catchError((error) => throwError(error));
  }
  
  onNeftEdit() {    
    this.validateNeftSR();    
  }
  validateNeftSR() {
    let reqBody = {
      policyno: this.existingDtls.policyNo,
      subactivity: 'STND_NEFT',
      /* benId: this.existingDtls.CLIENTID,
      benRole: this.existingDtls.CLIENTROLE */
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.getPennyDropCount();
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  getPennyDropCount() {
    let reqBody = {
      clientId: this.existingDtls.clientid,
    };
    return this.createSrService.getPennyDropCount(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {

        if(resp.body.pennyStatus == true){
          this.fetchDetailsbtn = true
     }

        if(this.existingAccount){
        this.dialog.open(ExistingNeftComponent, {
          panelClass: 'existing-neft-popup', backdropClass: 'existing-neft-popup-backdrop', disableClose: true,
          data: {
            ...this.existingDtls,
            policyNo: this.existingDtls.policyNo,
            type: 'NEFT',
            neftFlag: true, 
            entryFlag: true, 
            isPennyDrop: resp.body.pennyStatus, 
              fetchDetailsbtn : this.fetchDetailsbtn
          },
        });
        }else{
          this.dialog.open(UpdateMailingAddressComponent, {
            panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
            data: {
              NEFTup: Object.assign({}, this.neftDtls),  type: 'NEFT', clientid: this.utils.getClientId(), policyNo: this.policyNo, role: "Owner",bankname : false,
              neftFlag: true, entryFlag: true, isPennyDrop: resp.body.pennyStatus,existingFlag: true,disableClose: this.dataService.isDeepLink,fetchDetailsbtn : this.fetchDetailsbtn,showFetch:true
            },
            disableClose: true
          }); 
        }
  
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }

}
