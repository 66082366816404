import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';import { AuthService } from "../../../services/auth/auth.service";
import { HttpService } from '../../../services/http/http.service';
import { loginReqBody } from "./login-data-modal";
import { validateUserReqBody } from "./login-data-modal";
import { Utils } from 'src/app/utils/common-functions';
import { throwError, from } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { loginService } from "../../../services/login/login.service";
import { usefulLinks } from "../../../utils/app-constants";
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from 'src/app/services/data/data.service';
import { FacingLoginIssuesPopupComponent } from '../facing-login-issues-popup/facing-login-issues-popup.component';
import { MessageService } from 'src/app/services/message/message.service';
import { ForgotClientIdComponent } from '../forgot-client-id/forgot-client-id.component';
import { PasaOfferPopupComponent } from '../../my-policies/pasa-offer-popup/pasa-offer-popup.component';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MyPoliciesDialogComponent } from '../../my-policies/my-policies-dialog/my-policies-dialog.component';
import {toasterReqBody} from '../../login/login/login-data-modal'
import { CaptchaComponent } from '../../shared/components/captcha/captcha.component';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { serviceURL } from 'src/environments/environment';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { DeviceDetectorService } from 'ngx-device-detector';


export function PassOTP(group: FormGroup): { [s: string]: boolean } {
  var PWD = group.controls.clientPassword;
  var OTP = group.controls.clientOTP;

  if (PWD != undefined && OTP != undefined)
    if (!(PWD.value && PWD.value.length || OTP.value && OTP.value.length))
      return { invalid: true};
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginClientForm: FormGroup;
  loginEmailForm: FormGroup;
  loginMobileForm: FormGroup;
  loginClientFormValid = false;
  loginClientFocusOut = false;
  passwordType;
  loginPasswordFocusOut = false;
  redirectLinks = usefulLinks;
  OTPGenerate = false;
  NotAvailable = false;

  loginCaptchaFocusOut = false;
  isCaptchaValid: boolean = false;
  clientSecureLoginCheck = false;
  OTPCheck = false;
  reveal = false;
  genOtpResp: any;

  validateUserResp: any = {};
  timerSec: number = 180;
  interval: any;
  maskMobileNo: string;
  maskEmailId: string;
  errorinfo: any = {};
  statusMessage: string;
  hours: number = 0;
  minutes: number = 0;


  @ViewChild('clientCaptcha') clientCaptcha: CaptchaComponent;
  @ViewChild('emailCaptcha') emailCaptcha: CaptchaComponent;
  @ViewChild('mobileCaptcha') mobileCaptcha: CaptchaComponent;

  //toaster
  showToaster: boolean = false;
  toasterData: any;
  policyDtls: Array<any> = [];
  expInfoPopupData = [];

  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  groupCustromer: string = serviceURL.groupCustomer
  initInterval;
  showPwdField: boolean = true;
  showOtpField: boolean = false;
  medium: string;
  confirmNudges: any;

  public dropdownOptions: any[] = [
    {label: 'Home screen', value: 'Home screen', src:"/assets/home2.svg"},
    {label: 'Download & Statement', value: 'Download & Statement', src:"/assets/downloads_active2.svg"},
    {label: 'Update Bank Details', value: 'Update Bank Details', src:"/assets/bank-blue2.svg"},
    {label: 'Update Communication Details', value: 'Update Communication Details', src:"/assets/call-blue2.svg"},
    {label: 'Update PAN / KYC', value: 'Update PAN / KYC', src:"/assets/personal-icon2.svg"},
    {label: 'Track Service Requests', value: 'Track Service Requests', src:"/assets/service_req2.svg"},
  ]; 
  get device() {
    return this.deviceService.getDeviceInfo();
  }
  constructor(public formBuilder: FormBuilder, public router: Router,
    public auth: AuthService, public _httpService: HttpService,
    public loginService: loginService, public utils: Utils,  public myPoliciesService: MyPoliciesService,
    public dialog: MatDialog, public dataService: DataService, private message: MessageService, public crypto: EncryptDecryptService,public route: ActivatedRoute, public myAccountService: MyAccountService,private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    //this.init();
    //this.startInitTimer();
    this.passwordType = 'password';
    this.loginClientForm = this.formBuilder.group(
      {
        clientID: ['', [Validators.required, Validators.pattern('^.{0,8}$')]],
        clientPassword: ['', [Validators.required, Validators.minLength(1)]],
        clientOTP: ['', [Validators.pattern('^[0-9]{6,6}$')]],
        clientSecureLogin: [false],
        clientCaptcha: ['', [Validators.required]],
        landingScreen: ['Home screen']
      },
      { validator: PassOTP }
    ),
      this.loginEmailForm = this.formBuilder.group(
        {
          emailID: ['', [Validators.required, Validators.email]],
          clientPassword: ['', [Validators.required, Validators.minLength(1)]],
          clientOTP: ['', [Validators.pattern('^[0-9]{6,6}$')]],
          clientSecureLogin: [false],
          clientCaptcha: ['', [Validators.required]],
          landingScreen: ['Home screen']
        },
        { validator: PassOTP }
      ),
      this.loginMobileForm = this.formBuilder.group(
        {
          mobileNo: ['', [Validators.required, Validators.pattern('^[0-9]{10,20}$')]],
          clientPassword: ['', [Validators.required, Validators.minLength(1)]],
          clientOTP: ['', [Validators.pattern('^[0-9]{6,6}$')]],
          clientSecureLogin: [false],
          clientCaptcha: ['', [Validators.required]],
          landingScreen: ['Home screen']
        },
        { validator: PassOTP }
      );
    this.medium = this.route.snapshot.queryParamMap.get('medium');
    this.loginClientForm.controls.clientID.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if(value){
        this.loginClientForm.controls.clientID.patchValue(value.toUpperCase())
      }
    })
  }


  get clientvalidation() { return this.loginClientForm.controls; }
  get emailvalidation() { return this.loginEmailForm.controls; }
  get mobilevalidation() { return this.loginMobileForm.controls; }

  clientFocusOut(event: any) {
    this.loginClientFocusOut = true;
  }
  errorclear() {
    this.statusMessage = "";
  }
  passwordFocusOut(event: any) {
    this.loginPasswordFocusOut = true;
  }
  showPswd() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.reveal = true;
    } else {
      this.passwordType = 'password';
      this.reveal = false;
    }
  }

  clearRequired () {
    this.loginClientForm.get('clientPassword').clearValidators();
    this.loginClientForm.get('clientPassword').updateValueAndValidity();
    this.loginEmailForm.get('clientPassword').clearValidators();
    this.loginEmailForm.get('clientPassword').updateValueAndValidity();
    this.loginMobileForm.get('clientPassword').clearValidators();
    this.loginMobileForm.get('clientPassword').updateValueAndValidity();
  }
  SetRequired () {
    this.loginClientForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginClientForm.get('clientPassword').updateValueAndValidity();
    this.loginEmailForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginEmailForm.get('clientPassword').updateValueAndValidity();
    this.loginMobileForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginMobileForm.get('clientPassword').updateValueAndValidity();
  }
  submitLoginForm(loginData, loginType) {
    if (this.isCaptchaValid) {
      this.login(loginData, loginType);
    } else if (loginType == 'client') {
      this.loginClientForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    } else if (loginType == 'email') {
      this.loginEmailForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    } else {
      this.loginMobileForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    }
  }
  login(loginData, loginType) {
    let loginbody: loginReqBody = {
      "requesttype": "CUST_LOGIN",
      "cp_login_mode": '',
      "optradio": '',
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": '',
      "trans_id": "",
      "login_source":"Customer Portal",
      "medium":this.medium,
      "landingScreen": "",
      "device_type":this.device.deviceType,
      "browser":`${this.device.browser} - ${this.device.browser_version}`,
      "os_type":this.device.os,
    }
    if (loginType === 'client') {
      loginbody.optradio = 'clientID';
      loginbody.cp_loginid = loginData.clientID;
      loginbody.landingScreen = loginData.landingScreen;
      if (loginData.clientSecureLogin) {
        loginbody.cp_login_mode = 'CO';
        loginbody.otpEntered = loginData.clientOTP
        loginbody.trans_id = this.genOtpResp.trans_id
      } else {
        loginbody.cp_login_mode = 'CP';
        loginbody.cp_password = loginData.clientPassword
      }
    } else if (loginType === 'email') {
      loginbody.optradio = 'emailID';
      loginbody.cp_loginid = loginData.emailID.toLowerCase();
      loginbody.landingScreen = loginData.landingScreen;
      if (loginData.clientSecureLogin) {
        loginbody.cp_login_mode = 'EO';
        loginbody.otpEntered = loginData.clientOTP
        loginbody.trans_id = this.genOtpResp.trans_id
      } else {
        loginbody.cp_login_mode = 'EP';
        loginbody.cp_password = loginData.clientPassword
      }
    } else {
      loginbody.optradio = 'mobileNo';
      loginbody.cp_loginid = loginData.mobileNo;
      loginbody.landingScreen = loginData.landingScreen;
      if (loginData.clientSecureLogin) {
        loginbody.cp_login_mode = 'MO';
        loginbody.otpEntered = loginData.clientOTP
        loginbody.trans_id = this.genOtpResp.trans_id
      } else {
        loginbody.cp_login_mode = 'MP';
        loginbody.cp_password = loginData.clientPassword
      }
    }
    return this.init().subscribe((response) => {
      this._httpService.initResp = response;
    return this.loginService.getLoginDetails(loginbody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          this.dataService.setLoginResp(resp.body);
          this.utils.setLocalData('loggedIn', 'true', false);
          this.utils.setLocalData("id", resp.body.CLIENT_ID, false);
          if (resp.body.PWD_EXPIRED == "Y") {
            this.router.navigate(['/login', 'change-password']);
          }
          else {
            if(loginData.landingScreen === 'Home screen') {
              this.router.navigate(['/my-policies']);
            } else if(loginData.landingScreen === 'Download & Statement') {
              this.router.navigate(['/my-downloads/policy-document']);
            } else if(loginData.landingScreen === 'Update Bank Details') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            } else if(loginData.landingScreen === 'Update Communication Details') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            } else if(loginData.landingScreen === 'Update PAN / KYC') {
              this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            } else if(loginData.landingScreen === 'Track Service Requests') {
              this.router.navigate(['/customer-service/service-requests']);
            } else {
              this.router.navigate(['/my-policies']);
            }
            
            // if (loginType === 'client') {
            //   if(loginData.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(loginData.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(loginData.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(loginData.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   }
            // } else if (loginType === 'email') {
            //   if(loginData.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(loginData.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(loginData.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(loginData.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   }
            // } else {
            //   if(loginData.landingScreen === 'Home screen') {
            //     this.router.navigate(['/my-policies']);
            //   } else if(loginData.landingScreen === 'Download & Statement') {
            //     this.router.navigate(['/my-downloads/policy-document']);
            //   } else if(loginData.landingScreen === 'Update Bank Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
            //   } else if(loginData.landingScreen === 'Update Communication Details') {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
            //   } else {
            //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
            //   }
            // }
            // this.router.navigate(['/my-policies']);
            this.getToaster();
          }
        }
        else {
          this.statusMessage = resp.head.errordetails.message;
          if (this.clientCaptcha) {
            this.clientCaptcha.reloadCaptcha();
            this.loginClientForm.reset();
            this.loginClientForm.patchValue({landingScreen :'Home screen'});
            this.loginClientFocusOut = false;
            this.loginPasswordFocusOut = false;
            this.loginCaptchaFocusOut = false;
            this.OTPGenerate = false;
            this.OTPCheck = false;
          }
          if (this.emailCaptcha) {
            this.emailCaptcha.reloadCaptcha();
            this.loginEmailForm.reset();
            this.loginEmailForm.patchValue({landingScreen :'Home screen'});
            this.loginClientFocusOut = false;
            this.loginPasswordFocusOut = false;
            this.loginCaptchaFocusOut = false;
            this.OTPGenerate = false;
            this.OTPCheck = false;
          }
          if (this.mobileCaptcha) {
            this.mobileCaptcha.reloadCaptcha();
            this.loginMobileForm.reset();
            this.loginMobileForm.patchValue({landingScreen :'Home screen'});
            this.loginClientFocusOut = false;
            this.loginPasswordFocusOut = false;
            this.loginCaptchaFocusOut = false;
            this.OTPGenerate = false;
            this.OTPCheck = false;
          }
        }
      }),
      catchError((error) => throwError(error));
    }),
    catchError((error) => throwError(error));
  }
  generateOtp(loginType) {
    let genOtpReq = {
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    if (loginType === 'client') {
      genOtpReq.optradio = 'clientID';
      if (this.loginClientForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'CO';
        genOtpReq.cp_loginid = this.loginClientForm.controls.clientID.value;
      }
    } else if (loginType === 'email') {
      genOtpReq.optradio = 'emailID';
      if (this.loginEmailForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'EO';
        genOtpReq.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase();
      }
    } else {
      genOtpReq.optradio = 'mobileNo';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'MO';
        genOtpReq.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
      }
    }
    return this.loginService.genOtp(genOtpReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.OTPGenerate = true;
          this.genOtpResp = response.body;
          this.OTPTimerStart();
          this.clearRequired();
          this.showOtpField = true;
        }
        else {
          this.statusMessage = response.head.errordetails.message;
        }
      }),
      catchError((error) => throwError(error))
  }
  // createOTP(loginType) {
  //   this.OTPTimerStart();
  //   if (this.OTPCheck) {
  //     if (loginType === 'client') {
  //       this.generateOtp('client');
  //     } else if (loginType === 'email') {
  //       this.generateOtp('email');
  //     } else {
  //       this.generateOtp('mobileNo');
  //     }
  //   }
  //   this.GetLoginOTP();
  // }
  RegenOTP(loginType) {
    clearInterval(this.interval);
    this.timerSec = 180;
    if (this.OTPCheck) {
      if (loginType === 'client') {
        this.generateOtp('client');
      } else if (loginType === 'email') {
        this.generateOtp('email');
      } else {
        this.generateOtp('mobileNo');
      }
    }
  }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
      this.hours = ((this.timerSec / 60) | 0);
      // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
      if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
      // if (this.minutes == 60) {this.minutes = 0;} }
      else { this.minutes = this.timerSec; }
      this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  validateUser(loginType) {
    let validateUserReq: validateUserReqBody = {
      "requesttype": "Validateuser",
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    if (loginType === 'client') {
      validateUserReq.optradio = 'clientID';
      if (this.loginClientForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'CO';
        validateUserReq.cp_loginid = this.loginClientForm.controls.clientID.value;
      }
    } else if (loginType === 'email') {
      validateUserReq.optradio = 'emailID';
      if (this.loginEmailForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'EO';
        validateUserReq.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase();
      }
    } else {
      validateUserReq.optradio = 'mobileNo';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'MO';
        validateUserReq.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
      }
    }
    return this.loginService.validateUser(validateUserReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateUserResp = response.body;
          console.log(this.validateUserResp.maskMobileNo);
          if (this.validateUserResp.maskMobileNo.length < 10) {
            this.maskMobileNo = "not available";
          }
          else { this.maskMobileNo = this.validateUserResp.maskMobileNo }
          if (this.validateUserResp.maskEmailId.length < 5) {
            this.maskEmailId = "not available";
          }
          else { this.maskEmailId = this.validateUserResp.maskEmailId }
          if (this.maskMobileNo === this.maskEmailId) {
            this.statusMessage = "Your contact details are not registered with us. Please contact our branch/call center to update details.";
            this.NotAvailable = true;
          }
          else {
            this.statusMessage = "";
            this.NotAvailable = false;
            this.showPwdField = false;
            this.OTPCheck = true;
          }
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          this.NotAvailable = true;
          this.OTPCheck = false;
        }
      }),
      catchError((error) => throwError(error))
  }
    getToaster() {
    let toasterReqBody: toasterReqBody = {
      searchtype: 'PopupToasterRule',
      clientid: this.utils.getClientId(),
    }
    return this.myPoliciesService.getToasterPopup(toasterReqBody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          resp.body.sort(this.myPoliciesService.compareToasterObj);
          this.showToaster = this.myPoliciesService.showHideToaster(resp.body);
          this.toasterData = this.myPoliciesService.setToasterMsg(resp.body);
          this.expInfoPopupData = this.myPoliciesService.getExpInfoPopup(resp.body);
          let popupshow = false;
          for(let item of this.expInfoPopupData){
            if(item.flag  == "aps" || item.flag == "neftexp" ||  item.flag == "panexp" || item.flag == "mobemailexp"){
              popupshow = true; 
            }
        }
        if(!popupshow && this.expInfoPopupData.length > 0){
          if (this.expInfoPopupData.findIndex(item => item.flag == "permAddressFlag") >= 0) {
            this.getAddressDtls();
          }
          else if(this.expInfoPopupData.findIndex(item => item.flag == "neftFlag") >= 0){
            this.getBankDtls();
          }
          else{
            this.getContactDtls()
          }
        }
    //     if(!popupshow){
    //     for(let item of this.expInfoPopupData){
    //       if( item.flag == "neftFlag"  ){
    //         this.getBankDtls(); 
    //         break;
    //       }
    //       else{
    //         if(item.flag == "contactFlag"){
    //           this.getContactDtls();
    //         }
    //       }
    //     }
    // }
          if (this.expInfoPopupData.length == 0) {
            //this.dialog.open(PasaOfferPopupComponent, { panelClass: 'pasa-offer-dialog', backdropClass: 'pasa-offer-dialog-backdrop' });
          } else {
            if(popupshow){
              this.openExpiredPopup();
            }
          }
         
      }else {
          this._httpService.apiErrorHandler(resp);
        }
      }),
      catchError((error) => throwError(error));
  }
  openExpiredPopup() {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData
      }
    });
  }
  showKeyboard(event: any) {
    this.clientSecureLoginCheck = (event.checked);
  }
  OTPpass(event: any, loginType) {
    //this.OTPCheck = (event.checked);
    if (!event.checked) {
      clearInterval(this.interval);
      this.timerSec = 180;
      this.OTPGenerate = false;
      this.NotAvailable = false;
      this.statusMessage = "";
      this.SetRequired();
      this.clientSecureLoginCheck = false;
      this.showPwdField = true;
      this.showOtpField = false;
      this.OTPCheck = false;
      return;
    }
    this.init().subscribe((response) => {
      this._httpService.initResp = response;
    if (loginType === "client") {
      if ((this.loginClientForm.controls.clientID.valid) && event.checked) {
        this.validateUser(loginType);
      }
    }
    else if (loginType === "email") {
      if ((this.loginEmailForm.controls.emailID.valid) && event.checked) {
        this.validateUser(loginType);
      }
    }
    else if (loginType === "mobileNo") {
      if ((this.loginMobileForm.controls.mobileNo.valid) && event.checked) {
        this.validateUser(loginType);
      }
    }
  }),
  catchError((error) => throwError(error));
  }
  captchaFocusOut(event: any) {
    this.loginCaptchaFocusOut = true;
  }

  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
      this.loginClientForm.controls.clientCaptcha.updateValueAndValidity();
      this.loginEmailForm.controls.clientCaptcha.updateValueAndValidity();
      this.loginMobileForm.controls.clientCaptcha.updateValueAndValidity();
    }, 10);
  }
  clearCheckbox(changeEvent: MatTabChangeEvent) {
    this.loginClientForm.reset();
    this.loginClientForm.patchValue({landingScreen :'Home screen'});
    this.loginEmailForm.reset();
    this.loginEmailForm.patchValue({landingScreen :'Home screen'});
    this.loginMobileForm.reset();
    this.loginMobileForm.patchValue({landingScreen :'Home screen'});
    this.timerSec = 180;
    this.OTPGenerate = false;
    this.NotAvailable = false;
    this.statusMessage = "";
    this.SetRequired();
    clearInterval(this.interval);
    this.clientSecureLoginCheck = false;
    this.OTPCheck = false;
    this.showPwdField = true;
    this.showOtpField = false;
  }
  goToNewUser() {
    this.router.navigate(['/login', 'user-registration']);
  }
  goToForgotPwd() {
    this.router.navigate(['/login', 'forgot-password']);
  }
  clearClientCaptcha() {
    this.clientvalidation.clientCaptcha.setValue('');
  }
  clearEmailCaptcha() {
    this.emailvalidation.clientCaptcha.setValue('');
  }
  clearMobileCaptcha() {
    this.mobilevalidation.clientCaptcha.setValue('');
  }
  TemplateRef: any;
  forgotPopupOpen() {
    this.dialog.open(ForgotClientIdComponent, { panelClass: 'forgot-id-popup', backdropClass: 'forgot-id-backdrop' , disableClose: true });
  }
  loginIssuesFaq() {
    this.dialog.open(FacingLoginIssuesPopupComponent, { panelClass: 'login-issue-faq-popup', backdropClass: 'login-issue-faq-backdrop' , disableClose: true})
  }  
  downloadCovidPdf(){
    let downloadLink = document.createElement("a");
    downloadLink.download = "Customer_FAQ_For_BCP_Scenario_VF";
    downloadLink.href = "assets/pdf/Customer_FAQ_For_BCP_Scenario_VF.pdf";
    downloadLink.click();
  }
  init() {
    return this._httpService.init(this.httpHeaders);
  }
  startInitTimer() {
    let initTimeoutDuration = 15 * 60;
    this.initInterval = setInterval(() => {
      if (initTimeoutDuration > 0) {
        initTimeoutDuration--;
      } else {
        this.init();
        initTimeoutDuration = 15 * 60;
      }
    }, 1000);
  }
  ngOnDestroy() {
    clearInterval(this.initInterval);
  }
  openConfirmPopup(flag: string, dtls: any) {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData,
        confirmPopup: flag,
        displayDtls: dtls
      }
    });
  }
  getBankDtls() {
    let reqBody = {
      clientid: this.utils.getClientId(),
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(reqBody).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            let bankDtls = response.body[0];
            if(bankDtls.ACC_NUM && bankDtls.ACCNAME){
            for (let key of Object.keys(bankDtls)) {
              bankDtls[key] = bankDtls[key] ? bankDtls[key] : "NA";
            }
            this.openConfirmPopup("BANK", bankDtls);
          }
        }
        } else {          
          //this._httpService.apiErrorHandler(response);
        }

      }),
      catchError((error) => throwError(error));
  }
  getContactDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getCContactDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let contactDetail = response.body[0];
          if(contactDetail.CONTACT_CELL_PH_NUMBR && contactDetail.CONTACT_EMAIL_ADDRESS){
          for (let key of Object.keys(contactDetail)) {
            contactDetail[key] = contactDetail[key] ? contactDetail[key] : "NA";
          }
          this.openConfirmPopup("CONTACT", contactDetail);
        }
        } 
      } else {
        //this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }
  
  getAddressDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getClientAddressDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let address = response.body[0];
          if(address.HOUSENUMBER && address.STREET){
          for (let key of Object.keys(address)) {
            address[key] = address[key] ? address[key] : "NA";
          }
          this.openConfirmPopup("ADDRESS", address);
        }
        } 
      } else {
        this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }

  onBtnClick() {
    window.open(serviceURL.sidebannerLink, "_blank");
  }
}
