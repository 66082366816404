import { NgControl } from '@angular/forms';
import { HostListener, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'input[decimalNumbersOnly]'
})
export class onlyDecimalNumberDirective{
/**
 * This helps in injecting the desired services.
 * @param _el this is the elemnet reference ex: input
 */
  constructor(private _el: ElementRef, private control: NgControl){ }
  /**
   * It will remove all non-digit characters, EXCEPT the digital period.
   * @example (0.2,4,5) = 0.245
   */
  @HostListener('input',['$event']) onInputChange(event){
      const initalValve = this._el.nativeElement.value;
      let value = initalValve.replace(/[^0-9]*/g, '');
      this._el.nativeElement.value = initalValve.replace(/[^0-9]*/g, '');
      // controls valve was not getting updated, UI was updated with replace valve
      if( this.control &&  this.control.control){
          this.control.control.patchValue(value);
      }
      if( initalValve !== this._el.nativeElement.value) {
          event.stopPropagation();
      }
  }
}
