<div class="login-issues-faq">
        <div mat-dialog-actions>
                <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    Close
                    <img src="assets/close.svg">
                </div>  
            </div>
    <div class="login-issues-faq-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
        Facing Login Issues?
    </div>
    <div class="login-issues-faq-content popup-content-height">
            <div class="login-issues-faq-div" *ngFor="let item of loginIssuesFaq">
                    <div class="faq-ques">{{item.question}}</div>         
                    <div class="faq-ans" [innerHTML]="item.answer" (click)="downloadUserManual(item.isUserManual)"></div>  
 
            </div>

                     
         
    </div>
</div>
