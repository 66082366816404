import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/services/message/message.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { fundAllocationReqBody } from '../../my-policies/my-policies-data-modal';
import { Utils } from 'src/app/utils/common-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { DataService } from 'src/app/services/data/data.service';
import { Router } from '@angular/router';
import { serviceURL } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-surrender',
  templateUrl: './surrender.component.html',
  styleUrls: ['./surrender.component.css']
})
export class SurrenderComponent implements OnInit {
  isLinear = true;
  @ViewChild('select') select: MatSelect;
  allSelected: boolean = false;
  payout: boolean = false;
  payeeDtls: boolean = true;
  quoteBtn: boolean = false;
  ButtonVisible: boolean = false;
  disclaimerChecked: boolean = false;
  surrenderBtn: boolean = true;
  displayNote: boolean = false;
  quoteAmount: any;
  quoteGeneratedDate: any;
  policyDtls: Array<any> = [];
  policyCardDtls: any = {};
  policyNo: any;
  productName: any;
  productType: any;
  productCode: any;
  maturityClaim: FormGroup;
  claimNo: any;
  clientId: any;
  clientName: any ="";
  payementMode: any;
  accountValue: any;
  payModeArray = [
    { "label": "Savings/Current - Resident",
      "value": "Savings" },
    { "label": "Non - Resident Ordinary Account (NRO)",
      "value": "NRO" },
    { "label": "Non - Resident External Account (NRE)",
      "value": "NRE" },
    { "label": "NRO & NRE",
      "value": "NRO & NRE" }
  ];
  surrenderPayout = [
    { "label": "0",
      "value": "0" },
    { "label": "1/3",
      "value": "1/3" },
    { "label": "Others",
      "value": "Others" }
  ];
  surReasonArray: any = [];
  surReason: any = {};
  SelectedReason: any = [];
  selectAction: any = [];
  selectActionList: any = [];
  noPremiumDueTypes = ['single premium', 'fully paid'];
  isCustomLink: boolean = false;
  tabIndex: number = 0;
  isTabActive: boolean = false;
  NavDate: any;
  surrClientId: any;
  role: any;
  allocatePercent: any;
  firstName: any;
  middleName: any;
  lastName: any;
  pensionFlag: boolean = false;
  payoutView: boolean = false;
  linkId: any;
  parentSr: any;
  surrenderFlag: boolean = false;
  srCheck: boolean = false;
  surrenderReason: String;
  quoteFlag: boolean = false;
  existingRole: any;
  nrePremPaid: any;
  totalPremium: any;
  customLinkDtls: any;
  @ViewChild('policyBasedPopup') policyBasedPopup : TemplateRef<any>;
  surrenderBtnclick: boolean = false;
  annuityMessage: any;
  disableSurrender: boolean = false;
  uploadFlag: boolean = false;
  SRDtlsArray: any;
  SRTypeArray: any;
  serviceRequest: boolean;
  proceed: boolean = false;
  savingsFlag: boolean = false;

  constructor(public message: MessageService, public dialog: MatDialog, public myPoliciesService: MyPoliciesService, public utils: Utils, public _httpService: HttpService,
    public dataService: DataService, public router: Router, public fb: FormBuilder, public createSrService: CreateSRService, public auth: AuthService,) { 

      this.dataService.loginResp$.subscribe(valueChanged =>{
        if(valueChanged){
          if(this.createSrService.getSrDtlsResp() && this.createSrService.getSRTypeResp()){
            this.SRDtlsArray = this.createSrService.getSrDtlsResp();
            this.SRTypeArray = this.createSrService.getSRTypeResp();
            this.customLinkDtls = this.SRDtlsArray.POL_NUM
          } else{
            this.customLinkDtls = this.dataService.SurrPolicyNum
          }
          // this.customLinkDtls = this.dataService.SurrPolicyNum;
  
          this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
            if (policyCardDtls && policyCardDtls.length > 0) {
              let policyIndex = policyCardDtls.findIndex((item) => item.POLICYNO.replace(/\s+/g, '') == this.customLinkDtls);
              if (policyIndex >= 0) {
                this.dataService.setCarouselPosition(policyIndex);
              }
          this.clientId = this.utils.getClientId();
          this.policyDtls = policyCardDtls;
          this.policyCardDtls = policyCardDtls[policyIndex];
          this.policyNo = this.policyCardDtls.POLICYNO.replace(/\s+/g, '');
          this.details[0].maintext = this.policyNo
          this.productName = this.policyCardDtls.PRDNAME;
          this.details[1].maintext = this.productName
          this.productType = this.policyCardDtls.PRODUCT_TYPE;
          this.details[2].maintext = this.productType;
          }
          this.getNavDate();
          let policyDtls = {
            clientID: this.utils.getClientId(),
            policyno: this.policyNo,
          }
          this.myPoliciesService.getPolicyDtls(policyDtls).subscribe((response) => {
            if(this._httpService.isSuccessResponse(response)) {
            if(this.policyCardDtls && this.policyCardDtls.POLICYNO) {
              this.productCode = response.body.policydetails.PRODUCT_CODE;
            }
            this.surrender();
            }
          });
        });
        }
  
      })
    }

  ngOnInit(): void {
    this.maturityClaim = this.fb.group({
      ExistingPremium: ['',[Validators.required]],
      requestType: ['', [Validators.required]],
      totalPremium: ['', [Validators.required]],
      surrenderPayoutPercent: ['',[CustomValidators.surrenderPayoutPercent]],
      payoutValue: ['', [Validators.required]],
      surReason:['',[Validators.required]],
    })
    if(this.createSrService.getSrDtlsResp() && this.createSrService.getSRTypeResp()){
      this.SRDtlsArray = this.createSrService.getSrDtlsResp();
      this.SRTypeArray = this.createSrService.getSRTypeResp();
      this.customLinkDtls = this.SRDtlsArray.POL_NUM
    } else if(!this.dataService.SurrPolicyNum){
      this.router.navigate(['/customer-service', 'service-requests']);
    } else{
      this.customLinkDtls = this.dataService.SurrPolicyNum
    }
    if(this.dataService.getLoginResp()){
    this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
      if (policyCardDtls && policyCardDtls.length > 0) {
        let policyIndex = policyCardDtls.findIndex((item) => item.POLICYNO.replace(/\s+/g, '') == this.customLinkDtls);
        if (policyIndex >= 0) {
          this.dataService.setCarouselPosition(policyIndex);
        }
    this.clientId = this.utils.getClientId();
    this.policyDtls = policyCardDtls;
    this.policyCardDtls = policyCardDtls[policyIndex];
    this.policyNo = this.policyCardDtls.POLICYNO.replace(/\s+/g, '');
    this.details[0].maintext = this.policyNo
    this.productName = this.policyCardDtls.PRDNAME;
    this.details[1].maintext = this.productName
    this.productType = this.policyCardDtls.PRODUCT_TYPE;
    this.details[2].maintext = this.productType;
    }
    this.getNavDate();
    let policyDtls = {
      clientID: this.utils.getClientId(),
      policyno: this.policyNo,
    }
    this.myPoliciesService.getPolicyDtls(policyDtls).subscribe((response) => {
      if(this._httpService.isSuccessResponse(response)) {
      if(this.policyCardDtls && this.policyCardDtls.POLICYNO) {
        this.productCode = response.body.policydetails.PRODUCT_CODE;
      }
      this.surrender();
      }
    });
  });
}
    this.getsurrReasonMasterData();
    this.createActionList(); 
  }
  getNavDate(){
    let reqBody = {
      "policyno": this.policyNo 
    }
    return this.myPoliciesService.getNAVDate(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe(resp => {
      if(this._httpService.isSuccessResponse(resp)) {
        this.NavDate = resp.body.navdate
      } else {
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
        this.surrenderBtn = true;
      }
    })
  }
  surrenderAccept(){
    this.quoteFlag = true;
    if(this.srCheck){
      this.tabIndex = 3;
      this.payout = false;
      this.payeeDtls = true;
      this.getExistingPayeeDtls();
    } else{
      this.surrenderBtnclick = true
      this.tabIndex = 1;
      this.surrender();
    }
  }
  surrender(){
    this.payout = true;
    this.payeeDtls = false;
      let reqBody = {
        policyno: this.policyNo,
        subactivity: 'SURR_PROC',
        subactivitytype: "SURRENDER",
        productType: this.productType,
        productName: this.productName,
        productCode: this.productCode
      }
      return this.createSrService.validateServiceReq(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success"  && (!this.surrenderBtnclick && this.quoteFlag != true)) {
          this.getQuote();
          this.surrenderFlag = false;
          this.surrenderBtn = true; 
        } else if((this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") && resp.body.popupflag == "Y" && this.surrenderBtnclick){ 
          this.annuityMessage = resp.body.annuityMessage;
          this.dialog.open(this.policyBasedPopup, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
          this.surrenderFlag = true;
          this.surrenderBtn = false;
        } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
          this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
          this.surrenderFlag = false;
        } else if ((this._httpService.isSuccessResponse(resp) && (resp.body.status.toLowerCase() === "success")) && this.surrenderBtnclick) {
          this.surrenderFlag = true;
        } else {
          if(resp.head.errordetails && ((resp.head.errordetails.message.includes("Surrender Process SR cannot be initiated as Surrender Process service request")) && (resp.head.errordetails.message.includes("is pending.")))){
            this.parentSr = resp.head.errordetails.message.replace("Surrender Process SR cannot be initiated as Surrender Process service request",'') .replace("is pending.",'').trim();
            // this.srCheck =  true;
            // this.surrenderFlag = true;
            // this.surrenderBtn = false;
            this.tabIndex = 3;
            this.payout = true;
            this.payeeDtls = true;
            this.srCheck =  true;
            this.getExistingPayeeDtls();
            this.loadExistingQuote();
          } else if (resp.status == 500){
            this.errorMsg();
            this.surrenderFlag = true;
            this.surrenderBtn = true;
          } else{
            this.message.openSnackBar(resp.head.errordetails.message, 'xy',['error-snackbar']);
            this.srCheck =  true;
            this.surrenderFlag = true;
            this.surrenderBtn = true;
          }
        }
      });
  }
  loadExistingQuote(){
    let reqBody = {
      "srnum": this.parentSr
    }
    return this.myPoliciesService.getExistingQuoteDtls(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe((response) => {
      if(this._httpService.isSuccessResponse(response)){
        this.quoteAmount = parseInt(response.body[0].QUOTE_VALUE);
        this.quoteGeneratedDate = moment(response.body[0].QUOTE_GENERATED_DATE).format("DD/MM/YYYY");
        this.details[3].maintext = this.quoteGeneratedDate;
        if(response.body[1].SUBMIT_FLAG == "Y"){
          this.uploadFlag = true;
        } else {
          this.uploadFlag = false;
        }
      }
    })
  }
  premiumDetails(){
    let reqBody = {
      "clientno": this.surrClientId,
      "srnum": this.parentSr, 
      "payoutmode": this.payementMode,
      "accountValue": this.accountValue,
      "totalprempaid": this.maturityClaim.controls.ExistingPremium.value,
      "nreprempaid": this.maturityClaim.controls.totalPremium.value,
      "bankname":  "" // this.editPremiumForm.controls.PremBankName.value
    }
    return this.myPoliciesService.updatePremiumDtls(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)){
      } else {
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
      }
    });
  }
  // proceedSurrender(){
  //   this.payout = false;
  //   this.payeeDtls = true;
  //   this.tabIndex = 2;
  //   this.getSurrenderPayeeDtls();
  // }
  proceedSurrender(templateRef: TemplateRef<any>){
    this.payout = false;
    this.payeeDtls = true;
    this.proceed = true;
    this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true, data:{srNum: this.parentSr }});
  }
  investedBtn(templateRef: TemplateRef<any>){
    this.proceed = false;
    // this.payeeDtls = true;
    this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true});
  }
  surrenderPopup(){
    this.payout = false;
    this.payeeDtls = true;
    this.tabIndex = 2;
    this.getSurrenderPayeeDtls();
    this.dialog.closeAll();
  }
  getExistingPayeeDtls(){
    let reqBody = {
      "srnum": this.parentSr
    }
    return this.myPoliciesService.getExistingPayeeDtls(reqBody).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)){
        this.payementMode = resp.body[0].PAYMENT_MODE;
        this.accountValue = resp.body[0].ACCOUNT_TYPE;
        this.firstName = resp.body[0].FIRSTNAME;
        this.lastName = resp.body[0].LASTNAME;
        this.nrePremPaid = resp.body[0].PREMIUM_NRE_AMOUNT;
        this.role = resp.body[0].CLIENTROLE;
        this.surrClientId =  resp.body[0].CLIENTNO;
        if(this.accountValue == "NRE" || this.accountValue == "NRE and NRO"){
          this.nrePremDetails();
        } else{
          this.existingSrDtls();
        }
      }
    })
  }
  backBtn(){
    this.auth.logout('A');
  }
  srBackBtn(){
    if(this.srCheck){
      this.tabIndex = 0;
    } else{
      this.tabIndex = 1;
    }
  }
  getQuote(){
    const reqBody = {
      "clientid": this.utils.getClientId(),
      "policyno": this.policyNo,
      "productCode": this.productCode,
      "productType": this.productType
    };
    return this.myPoliciesService.getSurrenderQuote(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.quoteAmount = parseInt(resp.body.quoteAmnt);
        this.quoteGeneratedDate =  resp.body.quotegenerateddate
        this.details[3].maintext = this.quoteGeneratedDate
        this.surrenderBtn = false;
        this.quoteFlag = true;
        this.getSurrenderQuery();
      }
      else {
        this.errorMsg();
        this.surrenderBtn = true;
      }
      this.displayNote = true;
    });
  }
  getSurrenderQuery(){
    const reqBody = {
      "clientid": this.utils.getClientId(),
      "role": "Owner",
      "subactivity": "SURR_QUOTE",
      "action": "CREATE",
      "policyno": this.policyNo,
      "subactivitytype": "SURRENDER",
      "producttype": this.productType,
      "productname": this.productName,
      "productcode": this.productCode,
      "surrQuoteGenDt": this.quoteGeneratedDate,
      "surrVal": this.quoteAmount
    };
    return this.myPoliciesService.getSurrenderQuery(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe(resp => {
    });
  }
  getSurrenderPayeeDtls(){
    const reqBody = {
      "prodCode": this.productCode,
      "policyno": this.policyNo,
      "clientid": this.utils.getClientId()
    }
    return this.myPoliciesService.getSurrenderPayeeDtls(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.surrClientId = resp.body.payeeDetails[0].clientno;
        this.firstName = resp.body.payeeDetails[0].firstname;
        this.middleName = resp.body.payeeDetails[0].middlename;
        this.lastName = resp.body.payeeDetails[0].lastname;
        this.role = resp.body.payeeDetails[0].clientrole;
        this.allocatePercent = resp.body.payeeDetails[0].percentage != "" ? resp.body.payeeDetails[0].percentage : "100";
        if(resp.body.pensionProductCd == "yes"){
          this.pensionFlag = true;
          this.maturityClaim.controls.payoutValue.setValidators([Validators.required]);
          this.maturityClaim.controls.surrenderPayoutPercent.setValidators([CustomValidators.surrenderPayoutPercent])
        } else {
          this.pensionFlag = false;
          this.maturityClaim.controls.payoutValue.clearValidators();
          this.maturityClaim.controls.payoutValue.setErrors(null);
          this.maturityClaim.controls.surrenderPayoutPercent.clearValidators();
          this.maturityClaim.controls.surrenderPayoutPercent.setErrors(null)
        }
        if((this.firstName && this.lastName)) {
          this.clientName = this.firstName + " " +  this.lastName
        }
        else if((this.firstName)) {
          this.clientName = this.firstName
        }
        else if(this.lastName) {
          this.clientName = this.lastName
        }
      } else {
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
        this.ButtonVisible = false;
      }
    });
  }
  errorMsg(){
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
        this.ButtonVisible = false;
  }
  getSurrender(){
    this.payout = true;
    this.payeeDtls = false;
  }
  cancelRequest(){
    this.tabIndex = 0;
  }
  getsurrReasonMasterData() {
    const policyProductCode = 'CP_SURR_REASON';
    return this.myPoliciesService.getSurrReasonMasterData(policyProductCode).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        for (let key of Object.keys(resp.body[policyProductCode])) {
          this.surReasonArray.push({ code: key, name: resp.body[policyProductCode][key][1] });
        }
      } else {
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
        this.surrenderBtn = true;
      }
    }),
      catchError((error) => throwError(error));
  }
  selectPayment(event) {
    if (this.maturityClaim.controls.requestType.value.label == 'Savings/Current - Resident' || this.maturityClaim.controls.requestType.value.value == 'NRO') {
      this.accountValue = "Savings";
      this.savingsFlag = true;
      this.maturityClaim.get('totalPremium').clearValidators();
      this.maturityClaim.get('totalPremium').setErrors(null);
      this.maturityClaim.get('ExistingPremium').clearValidators();
      this.maturityClaim.get('ExistingPremium').setErrors(null);
      this.payementMode = "NEFT"
    } else if (this.maturityClaim.controls.requestType.value.value == 'NRE') {
      this.savingsFlag = false;
      this.accountValue = "NRE";
      this.payementMode = "NEFT"
      this.maturityClaim.get('totalPremium').setValidators([Validators.required, CustomValidators.totalPremiumPaid]);
      this.maturityClaim.get('ExistingPremium').setValidators([Validators.required]);
    } else {
      this.savingsFlag = false;
      this.accountValue = "NRE and NRO";
      this.payementMode = "NEFT & Cheque both"
      this.maturityClaim.get('totalPremium').setValidators([Validators.required, CustomValidators.totalPremiumPaid]);
      this.maturityClaim.get('ExistingPremium').setValidators([Validators.required]);
    }
    if(this.maturityClaim.controls.requestType.value.value == 'NRE' || this.payementMode == "NEFT & Cheque both"){
      this.nrePremDetails();
  }
  }
  selectSurrender(){
    let payoutPercent:any = "";
      if(this.maturityClaim.controls.payoutValue.value == "0"){
        payoutPercent = "0";
        this.maturityClaim.get('surrenderPayoutPercent').setValue(payoutPercent);
        this.disableSurrender = true;
      } else if(this.maturityClaim.controls.payoutValue.value == "1/3"){
        payoutPercent = "33.333";
        this.maturityClaim.get('surrenderPayoutPercent').setValue(payoutPercent);
        this.disableSurrender = true;
      } else{
        this.disableSurrender = false;
        this.maturityClaim.get('surrenderPayoutPercent').setValue(null);
      }
  }
  nrePremDetails(){
    let NREAccountReq = {
      "policyno": this.policyNo,
      "clientID": this.surrClientId 
    }
    this.myPoliciesService.getNREPayDtls(NREAccountReq).subscribe(resp => { 
      if(resp.body.NREPaymentDetails.NREPremDetails){
      this.maturityClaim.get('ExistingPremium').setValue(resp.body.NREPaymentDetails.NREPremDetails.TOTAL_PREMIUM_PAID)
      this.totalPremium = resp.body.NREPaymentDetails.NREPremDetails.TOTAL_PREMIUM_PAID;
      this.maturityClaim.get('totalPremium').setValue(resp.body.NREPaymentDetails.NREPremDetails.TOTAL_PREMIUM_PAID)
      }
      if(this.srCheck){
      this.existingSrDtls();
      }
    })
  }

  IsChecked(event) {
    this.ButtonVisible = (event.checked);
  }
  IsDisclaimerChecked(event){
    this.disclaimerChecked = (event.checked)
  }
  IsReasonChecked(){
    if(this.allSelected) {
      this.select.options.forEach((item: MatOption) => {
      item.select()});
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick(){
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if(!item.selected){
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  goToPayee(templateRef: TemplateRef<any>, type:string) {
    let surrArray = this.maturityClaim.value.surReason
    this.surrenderReason = surrArray.toString();
    let reqBody={
      "subactivity": "SURR_PROC",
      "subactivitytype": "SURRENDER",
      "action": "CREATE",
      "policyno": this.policyNo,
      "clientid": this.clientId,
      "role": "Owner",
      "prmpaidnre": this.maturityClaim.controls.totalPremium.value,
      "srno": this.claimNo,
      "paymentMode": this.payementMode,
      "accountValue": this.accountValue,
        "payeeclientno": this.surrClientId,
        "payeefirstname": this.firstName,
        "payeelastname": this.lastName,
        "payeeclientrole": this.role,
        "surrReason": this.surrenderReason,
        "productType": this.productType,
        "productName": this.productName,
        "prodCode": this.productCode,
        "surrQuoteGenDt": this.quoteGeneratedDate,
        "surrVal": this.quoteAmount,
        "totPremPaid": this.totalPremium,
        "surrPensioncSelect": this.maturityClaim.value.payoutValue,
        "surrenderPensionPerc": this.maturityClaim.value.surrenderPayoutPercent
  }
  if(this.srCheck == false){
    this.myPoliciesService.createSurrenderSr(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe(srResp => { 
      if(this._httpService.isSuccessResponse(srResp)){
        this.parentSr = srResp.body.srinfo[0].srbasicinfo.srno;
        this.linkId = srResp.body.srinfo[0].srbasicinfo.linkid;
        if (this.maturityClaim.valid) {
          this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true, data:{srNum: this.parentSr }});
        } else {
          this.maturityClaim.markAllAsTouched();
        }
      } else {
        this.errorMsg();
      }
    });
  } else{
    if (this.maturityClaim.valid) {
      this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      this.payout = false;
      this.payeeDtls = false;
    } else {
      this.maturityClaim.markAllAsTouched();
    }
  }
  window.scrollTo(0, 0);
  }
  continuePopup(){
    this.premiumDetails();
    this.existingSrDtls();
    this.dialog.closeAll()
    this.tabIndex = 3;
  }
  closePopup(){
    this.tabIndex = 0;
    this.dialog.closeAll();
  }
  yesBtn(){
    this.tabIndex = 1;
    this.dialog.closeAll();
  }
  existingSrDtls(){
    let reqBody = {
      "clientid": this.clientId,
      "srno": this.parentSr,  
      "policyno": this.policyNo
    }
    return this.myPoliciesService.getExistingSrDtls(reqBody).pipe(catchError(this.errorMsg.bind(this))).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)){
        this.clientId = resp.body.loadPage[0].X_SR_LOGIN_ID;
        this.linkId = resp.body.loadPage[0].X_SR_LINK_ID;
        this.existingRole = resp.body.loadPage[0].TT_TYPE_CD;
      } else {
        let text = "service@hdfclife.com";
        let link = text.link("mailto:text")
        this.message.openSnackBar(`Please try again after some time or you can contact us on 022-68446530 (charges apply) or write to us on mail to ${link} with the error screen shot.`, 'xy', ['error-snackbar'], 'top');
      }
      let payoutDetails = {
        X_SR_LINK_ID : this.linkId
      }
      this.myPoliciesService.setPayeedetails(payoutDetails)
      let OwnerClientId;
      if(this.role == "Beneficiary"){
        OwnerClientId = this.clientId
      } else{
        OwnerClientId = this.surrClientId
      }
      let payeeDetails = {
        id: "surrender",
        clientNo : OwnerClientId,
        claimClientId : this.surrClientId,
        FIRSTNAME: this.firstName,
        LASTNAME: this.lastName,
        PERCENTAGE: this.allocatePercent,
        ACCOUNT_TYPE: this.accountValue,
        SRNUM: this.parentSr,
        CLIENTROLE: this.role,
        paymentMode: this.payementMode,
        policyNo: this.policyNo    
      }
      this.myPoliciesService.setPayoutData(payeeDetails);
      let payout = {
        surrValue : this.quoteAmount,
        quoteGenDate : this.quoteGeneratedDate,
        navDate : this.NavDate,
        surReason : this.surrenderReason ? this.surrenderReason : " ",
        prodCode : this.productCode,
        prodType : this.productType,
        accountValue: this.accountValue,
        paymentMode: this.payementMode,
        nrePaid: this.maturityClaim.controls.totalPremium.value ? this.maturityClaim.controls.totalPremium.value : this.nrePremPaid,
        totalPremium: this.totalPremium
      }
      this.myPoliciesService.setPayoutdetails(payout)
      this.payoutView = true; 
    });
  }
  // help(){
  //   let downloadLink = document.createElement("a");
  //   downloadLink.download = "Surrender_Journey_step_Banner";
  //   downloadLink.href = "/assets/pdf/Surrender_Journey_step_Banner.pdf";
  //   downloadLink.click();
  // }
  details:any = [
    {'icon':'/assets/uin_number.svg','label':'Policy Number','maintext':''},
    {'icon':'/assets/card.svg','label':'Product Name','maintext':''},
    {'icon':'/assets/policy-blue.svg','label':'Product Type','maintext':'' },
    {'icon':'/assets/calender.svg','label':'Quote Generated Date','maintext':''},
  ]

  createActionList() {
    this.selectActionList = [
      {'image':'/assets/location-red.svg','action':'Branch Location','displayFlag': true, 'type':'Maturity', 'isMaturity': true},  
      {'image':'/assets/claim.svg','action':'View Surrender Process','displayFlag': true, 'type':'Maturity', 'isMaturity': true},    
    ];  
    for (let item of this.selectActionList) {
      this.selectAction.push(item) 
    }   
  }   
  selectActionRedirect(action){
    if(action == "Branch Location"){
      window.open(serviceURL.branchLocator,'_blank','noopener noreferrer');
    } else if(action == 'View Surrender Process'){
      window.open("/assets/pdf/Surrender_Journey_step_Banner.pdf",'_blank','noopener noreferrer')
    }
  } 
}
