<div class="sr-track">
        <div mat-dialog-actions (click)="closePopup()">
                <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    Close
                    <img src="assets/close.svg">
                </div>  
            </div>
    <div class="sr-track-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
        Track Service Request
    </div>
    <div class="sr-track-content popup-content-height">
<div class="greetings">Dear {{userName}} 
    <div>Greetings from <span class="hdfc-color">HDFC</span> <span class="life-color">Life</span></div></div>
<div class="sr-track-text" *ngFor="let item of srTrackDetails">We are in receipt of your service request for {{item.srType}} for HDFC Life Policy No. {{item.policyNum}},
    Service Request No. {{item.srNum}}.
</div>
    <div class="req-date">Request Date: {{requestedDate}}</div>
<div class="progress-scroll">
    <div class="progress-bar-tracks">
<div>
    <div class="status">Service Request Received</div>
  <div class="status">Initial Verification</div>  
    <div class="status">Awaiting Requirements</div>
        <div class="status">In Process</div> 
        <div class="status">Service Request Closed</div>
</div>
<div>
        <div class="balls" [ngClass]="{'hide-tick': firstBall}"></div>
        <div class="balls" [ngClass]="{'hide-tick': secondBall}"></div>
        <div class="balls" [ngClass]="{'hide-tick': thirdBall}"></div>
        <div class="balls" [ngClass]="{'hide-tick': fourthBall}"></div>
        <div class="balls" [ngClass]="{'hide-tick': fifthBall}"></div>
</div>
<mat-progress-bar class="sr-progress" mode = "determinate" [value] = "trackPercentage"></mat-progress-bar>
</div>
</div>
<div class="current-status">Current Status : {{trackStatus}}</div>
<div class="submit-buttons flex-center" *ngIf="(srTrackResp && trackStatus.includes('Pending Requirements'))">
    <button class="close-button" (click)="getSrDocUploadLink('UPLOAD_NOW')">Upload Document Now</button>
    <span class="fill-remaining-space"></span>
    <button type="submit"
        class="save-button" (click)="getSrDocUploadLink('UPLOAD_LATER')">Upload Document Later</button>
</div>
        </div>
    </div>