import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvestigatorFrScreenComponent } from './investigator-fr-screen/investigator-fr-screen.component';
import { InvestigatorLandingComponent } from './investigator-landing/investigator-landing.component';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';

const routes: Routes = [
    { path: '', component: InvestigatorLandingComponent},
    { path: 'investigator-fr', component:InvestigatorFrScreenComponent},
    { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvestigatorRoutingModule { }