import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyDownloadsService } from 'src/app/services/my-downloads/my-downloads.service';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'src/app/services/message/message.service';
import { stringify } from '@angular/compiler/src/util';
import { Router } from '@angular/router';



@Component({
  selector: 'app-weekly-subscription',
  templateUrl: './weekly-subscription.component.html',
  styleUrls: ['./weekly-subscription.component.css']
})
export class WeeklySubscriptionComponent implements OnInit {
  isHNIUSer: boolean = false;
  userDetails: any = {};
  weeklySubscriptionForm: FormGroup;
  eventChecked: any;
  displayMessage: string;
  subscribeResp: any;
  frequencyValue: any;
  subscriptionPreferenceArray : Array<any>=[];
  // [{"code":"8443","value":"Weekly"},{"code":"8444","value":"Monthly"},{"code":"8445","value":"Quarterly"}]
  subscription: Array<any>=[];
  status: any;
  displayStatus: boolean = true;
  action: string;
  selectedFrequency: any;
  frequencyChange: boolean;
  unsubscribe: boolean = false;
  getUnitSubscriptionMaster: Array<any>=[];
  preferenceChange : boolean = false;
  emailAvailable : boolean = false;

  constructor(public utils: Utils,
    public dataService: DataService,
    private _formBuilder: FormBuilder,
    public myDownloadsService: MyDownloadsService,
    public _httpService: HttpService,
    public dialog: MatDialog,
    public message: MessageService,
    public router: Router) { }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.weeklySubscriptionForm = this._formBuilder.group({
      subscriptionPreference: ['', Validators.required],
      frequency: ['Weekly',Validators.required],
    });
    this.getsubscribeUnitMaster();
    this.getsubscribeValue();
    this.emailAvailable = this.userDetails.EMAIL_ID.length !== 0 ? true : false;
    
    // let subscriptionArray = Object.values(this.subscriptionPreferenceArray)
    // for(let item of subscriptionArray) {
    //   this.subscription.push(item);
    // }
  }
  displayValue(e) {
    this.preferenceChange = !this.preferenceChange;
    this.eventChecked = e.checked;
    if (e.checked) {
      this.displayMessage = "Subscribe";
      this.eventChecked = true;
      this.unsubscribe = false;
    }
    else {
      this.displayMessage = "Unsubscribe";
      this.eventChecked = false;
      this.unsubscribe = true
      this.unsubscribeValue();
    }
  }
  unsubscribeValue() {
    for (let i of this.subscribeResp) {
    if(this.eventChecked == false) {
      this.unsubscribe = true
      this.weeklySubscriptionForm.value.frequency = JSON.stringify(i.MODE_TYPE)
    } 
  }
    this.frequencyValue = this.weeklySubscriptionForm.value.frequency;
  }
  selectFrequency(e) {
    this.frequencyChange = true;
    this.selectedFrequency = e.value;
  }
  getsubscribeValue() {
    let reqBody = {
      clientID: this.utils.getClientId(),
    }
    return this.myDownloadsService.getSubscribeValue(reqBody)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.subscribeResp = response.body.jsondata;
          this.status = response.body.Status;
          if(this.status == "Failure") {
            this.displayStatus = false;
          }
          else {
            this.displayStatus = true;
          }
          if(this.subscribeResp.length == 0) {
            this.eventChecked = true;
            this.displayMessage = "Subscribe"
            this.action="INSERT"
            this.preferenceChange = true;
          }
          else {
            this.action="UPDATE"
          }
          for (let i of this.subscribeResp) {
            this.eventChecked = i.EMAIL_NOTIFICATION == 'Y' ? true : false;
            this.displayMessage = i.EMAIL_NOTIFICATION == 'Y' ? 'Subscribe' : 'Unsubscribe';
            this.unsubscribe = this.displayMessage =="Unsubscribe"?true:false;
            this.weeklySubscriptionForm.value.frequency = 'Weekly';
            // if(i.MODE_TYPE == "8443") {
            //   this.weeklySubscriptionForm.value.frequency = JSON.stringify(i.MODE_TYPE);
            // } 
            // else if(i.MODE_TYPE == "8444") {
            //   this.weeklySubscriptionForm.value.frequency = "8444"
            // }
            // else if(i.MODE_TYPE == "8445") {
            //   this.weeklySubscriptionForm.value.frequency = "8445"
            // }
            this.frequencyValue = this.weeklySubscriptionForm.value.frequency;
          }
        }
        else {
          this._httpService.apiErrorHandler(response);
        }
      }),
      catchError((error) => throwError(error));
  }
  updateSubscription() {
    let reqBody = {
        clientID: this.utils.getClientId(),
        optradio: this.eventChecked == true ? 'S':'U',
        cp_unit_mode: " ",
        subscribeMode: 8554,//this.frequencyChange == true?this.selectedFrequency:this.frequencyValue,
        action_type: this.action
    }
    return this.myDownloadsService.subscribeForUnitStatement(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        //this.message.openSnackBar(resp.body.message, 'xy', ['success-snackbar']);
        if(this.eventChecked == true) {
          this.message.openSnackBar('Successfully subscribed for unit statements. You will get the unit statements once every week', 'xy', ['success-snackbar']);
        }
        else {
          this.message.openSnackBar('Successfully unsubscribed for unit statements', 'xy', ['success-snackbar']);
        }
        this.dialog.closeAll();
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
        this.dialog.closeAll();
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  closePopup() {
    this.dialog.closeAll();
  }
  getsubscribeUnitMaster() {
    return this.myDownloadsService.getSubscribeUnitMaster().subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        for (let key of Object.values(resp.body["CP_SUBSCRIBE_UNIT"])) {
          this.getUnitSubscriptionMaster.push({code:key[0],value:key[1]});
        }
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    })
  }
  redirectFormD02(){
    this.myDownloadsService.setFormD02RedirectFlag(true);
    this.router.navigate(['/my-downloads','form-d02']);
    this.dialog.closeAll();
  }
  redirectContact(){
    this.dialog.closeAll();
    this.router.navigate(['/my-account'])
  }
}
