import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { DataService } from 'src/app/services/data/data.service';
import { Utils } from 'src/app/utils/common-functions';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MyPoliciesDialogComponent } from 'src/app/modules/my-policies/my-policies-dialog/my-policies-dialog.component';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';

@Component({
  selector: 'app-sso-redirect',
  templateUrl: './sso-redirect.component.html',
  styleUrls: ['./sso-redirect.component.css']
})
export class SsoRedirectComponent implements OnInit {
  showToaster: boolean = false;
  toasterData: any;
  expInfoPopupData = [];

  constructor(public httpService: HttpService, public dataService: DataService, public utils: Utils, public router: Router, public dialog: MatDialog, public myPoliciesService: MyPoliciesService, public myAccountService: MyAccountService) { }

  ngOnInit(): void {
    localStorage.clear();
    this.utils.setLocalData('app', 'CA', false);
    this.httpService.reinit().subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.dataService.setLoginResp(resp.body);
        this.utils.setLocalData('loggedIn', 'true', false);
        this.utils.setLocalData("id", resp.body.CLIENT_ID, false);
        this.getToaster();
        this.router.navigate(['/my-policies']);
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    })
  }

  getToaster() {
    let toasterReqBody = {
      searchtype: 'PopupToasterRule',
      clientid: this.utils.getClientId(),
    }
    return this.myPoliciesService.getToasterPopup(toasterReqBody)
      .subscribe((resp) => {
        if (this.httpService.isSuccessResponse(resp)) {
          resp.body.sort(this.myPoliciesService.compareToasterObj);
          this.showToaster = this.myPoliciesService.showHideToaster(resp.body);
          this.toasterData = this.myPoliciesService.setToasterMsg(resp.body);
          this.expInfoPopupData = this.myPoliciesService.getExpInfoPopup(resp.body);
          let popupshow = false
          for (let item of this.expInfoPopupData) {
            if (item.flag == "neftexp" || item.flag == "panexp" || item.flag == "mobemailexp") {
              popupshow = true;
            }
          }
          if (!popupshow) {
            for (let item of this.expInfoPopupData) {
              if (item.flag == "neftFlag") {
                this.getBankDtls();
                break;
              }
              else {
                if (item.flag == "contactFlag") {
                  this.getContactDtls();
                }
              }
            }
          }

          if (this.expInfoPopupData.length == 0) {
            //this.dialog.open(PasaOfferPopupComponent, { panelClass: 'pasa-offer-dialog', backdropClass: 'pasa-offer-dialog-backdrop' });
          } else {
            if (popupshow) {
              this.openExpiredPopup();
            }
          }
        } else {
          this.httpService.apiErrorHandler(resp);
        }
      }),
      catchError((error) => throwError(error));
  }
  openExpiredPopup() {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData
      }
    });
  }
  openConfirmPopup(flag: string, dtls: any) {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData,
        confirmPopup: flag,
        displayDtls: dtls
      }
    });
  }
  getBankDtls() {
    let reqBody = {
      clientid: this.utils.getClientId(),
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(reqBody).subscribe((response) => {
      if (this.httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let bankDtls = response.body[0];
          for (let key of Object.keys(bankDtls)) {
            bankDtls[key] = bankDtls[key] ? bankDtls[key] : "NA";
          }
          this.openConfirmPopup("BANK", bankDtls);
        }
      } else {
        //this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }
  getContactDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getCContactDetails(getDetails).subscribe((response) => {
      if (this.httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let contactDetail = response.body[0];
          for (let key of Object.keys(contactDetail)) {
            contactDetail[key] = contactDetail[key] ? contactDetail[key] : "NA";
          }
          this.openConfirmPopup("CONTACT", contactDetail);
        }
      } else {
        //this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }

}
