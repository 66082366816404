import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocListPopupComponent } from '../../shared/components/doc-list-popup/doc-list-popup.component';
import { DataService } from 'src/app/services/data/data.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CameraComponent } from '../../shared/components/camera/camera.component';
import { Utils } from 'src/app/utils/common-functions';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-custom-link-doc-upload',
  templateUrl: './custom-link-doc-upload.component.html',
  styleUrls: ['./custom-link-doc-upload.component.css']
})
export class CustomLinkDocUploadComponent implements OnInit {
  isDigitalConsent: boolean = false;
  isUpload: boolean = false;
  uploadDialogRef: MatDialogRef<DocListPopupComponent> = null;
  srno: string = '';
  userDtls: any = {};

  requirementsResp: Array<any> = [];
  requirementDtls: any = {};
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'gif', 'bmp'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 1;

  title: string;

  constructor(public route: ActivatedRoute, public router: Router, public dialog: MatDialog, public dataService: DataService, public createSrService: CreateSRService, public httpService: HttpService, public utils: Utils, public message: MessageService) { }

  ngOnInit(): void {
    this.isUpload = this.router.url.includes('upload');
    this.isDigitalConsent = this.router.url.includes('digital-consent');
    this.userDtls = this.dataService.getLoginResp();
    this.dataService.loginResp$.subscribe(isChanged => {
       if (isChanged) {
        this.userDtls = this.dataService.getLoginResp();
        this.srno = this.userDtls.srno;
        if (this.isDigitalConsent) {
          this.openUploadPopup();
          //this.getRequirementDetails();
        } else if (this.isUpload) {
          this.openUploadPopup();
        }       
      }
    }); 
    this.srno = this.userDtls && this.userDtls.srno ? this.userDtls.srno : '';
    if (this.isDigitalConsent) {
      this.title = "Digital Consent"
      this.openUploadPopup();
      //this.getRequirementDetails();
    } else if (this.isUpload) {
      this.title = "Document Upload"
      this.openUploadPopup();
    }  
  }
  openUploadPopup() {
    if (this.srno) {
      this.uploadDialogRef = this.dialog.open(DocListPopupComponent, {
        panelClass: 'doc-list-dialog',
        backdropClass: 'doc-list-dialog-backdrop',
        disableClose: true,
        data: {
          srno: this.srno,
          //hideClose: true,
          disableClose: true,
          // disableSubmitSR: true,
          source: this.isDigitalConsent ? "DIGITAL_CONSENT" : "CLICK2UPLOAD",
        }
      });
      /* this.uploadDialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/my-link'], {queryParamsHandling: 'preserve'});
      }) */
    } 
  }
  /* getRequirementDetails() {    
    let reqBody = {
      "srno": this.srno
    }    
    return this.createSrService.getRequirementDetails(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.getrequirementdetails) {          
            this.requirementsResp = resp.body.getrequirementdetails;
        } 
        this.requirementDtls = this.requirementsResp.find(item => item.reqcd == "CSPS_REQ_001");
        if (this.requirementDtls && this.requirementDtls.reqcd) {
          this.openCamera();     
        } else {
          this.message.openSnackBar(`Unable to find digital consent requirement for the SR No. ${this.srno}`, 'xy', ['error-snackbar']);
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }      
    }),
     catchError((error) => throwError(error));
  } 
  openCamera() {
    this.cameraDialogRef = this.dialog.open(CameraComponent, { panelClass: 'camera-dialog', backdropClass: 'camera-dialog-backdrop', disableClose: true,
    data: { isDigiConsent: true, maxFiles: 1 }, 
  });
    this.cameraDialogRef.afterClosed().subscribe(imageArray => {
      if (imageArray && imageArray.length > 0) {
        this.onDocumentLoad(imageArray);
      }
    });
  }
  onDocumentLoad(files) {    
    if (this.validateFiles(files)) {      
      this.byteArrayGenerator(files).then(result => {
        this.uploadDocument(result[0]);          
      });     
    }   
  }
  validateFiles(files) {
    let filesExceeded: boolean;    
      filesExceeded = files.length > this.maxNumOfFiles;      
    if (filesExceeded) {
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar']);
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name;
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
        return false;
      } else if (filesize > this.maxFileSize) {
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], 'top');
        return false;
      }
    }
    return true;
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i]);
      bytearray.push({
        name: files[i].name,
        bytestream: base64string,
      });
    }
    return bytearray;
  }
  uploadDocument(file) {
    const reqBody = {
      osvflag: "Received through email or letter.",
      policyno: this.requirementDtls.policyno,
      srno: this.requirementDtls.srno,
      clientid: this.requirementDtls.clientid,
      bytearray: file.bytestream,
      reqcd: this.requirementDtls.reqcd,
      reqtranskey: this.requirementDtls.reqtranskey,
      reqstatuscd: this.requirementDtls.reqstatuscd,
      filenetfldr: this.requirementDtls.filenetfldr,
      doccd: "DOC_589",
      doctype: file.name.split('.').pop(),
      docname: file.name ? file.name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : file.name,
      docattributelist: [],
    };
    return this.createSrService.documentUpload(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {                
          this.httpService.showSuccessMsg(`Digital consent submitted successfully for SR number: ${this.requirementDtls.srno}`, true);
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  } */

}
