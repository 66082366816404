import { Component, OnInit, Input } from '@angular/core';
import { serviceURL } from 'src/environments/environment';

@Component({
  selector: 'app-recommended-plans',
  templateUrl: './recommended-plans.component.html',
  styleUrls: ['./recommended-plans.component.css']
})
export class RecommendedPlansComponent implements OnInit {
  @Input() crossSell: any;
  constructor() { }

  ngOnInit(): void {
  }
  recommendedPlans: any  = [
    {'planname': 'Click 2 Protect Super', 'planimg':'assets/policy_icon1.svg','url': serviceURL.recommendPlanOne},
    {'planname': 'Smart Protect', 'planimg':'assets/policy_icon5.svg','url': serviceURL.recommendPlanTwo},
    {'planname': 'Sanchay Plus', 'planimg':'assets/policy_icon2.svg','url': serviceURL.recommendPlanThree}
  ];

  selectedPlan(item){
    for(let i of this.crossSell){
      if(item == i.label){
        window.open(i.link,"noopener noreferrer");
      }
    }    
  }
}
