<div class="login-page-padding">
  <!-- <div class="login-marquee-text">Our new and improved customer service portal is now live with easier and simpler navigation options</div> -->
  <div class="login-page flex">
    <div class="login-left-div">
      <mat-card class="login-left-section">
        <mat-card-title class="login-title">Login</mat-card-title>
        <mat-tab-group animationDuration="0ms" class="login-tabs" [disableRipple]=true
          (selectedTabChange)="clearCheckbox($event)">
          <mat-tab label="Client ID">
            <ng-template matTabContent>

              <form [formGroup]="loginClientForm" (ngSubmit)="submitLoginForm(loginClientForm.value,'client')">
                <div class="forget-details" (click)="forgotPopupOpen(clientId)">Forgot Client ID?</div>
                <div class="form-label">Client ID<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                  <mat-placeholder>Please enter your Client ID</mat-placeholder>
                  <input formControlName="clientID" type="text" (keypress)="errorclear()" matInput
                    (focusout)="clientFocusOut($event)" maxlength="8">
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && clientvalidation.clientID.errors?.required)">
                    Enter client ID
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && clientvalidation.clientID.errors?.pattern)">
                    Please enter valid client ID
                  </mat-error>
                </mat-form-field>
                <div class="login-check">
                  <mat-checkbox (change)="OTPpass($event,'client')" class="client-login-checkbox"
                    formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                  </mat-checkbox>
                  <div class="otp-timer-div" *ngIf="(OTPGenerate && OTPCheck) && (statusMessage.length <1)">
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                      {{maskMobileNo}}
                    </div>
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}}
                    </div>
                    <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered mobile number {{maskMobileNo}}
                    </div>
                    Your OTP will be valid for <span
                      class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                    <a (click)="RegenOTP('client')"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                  </div>
                </div>
                <div
                  *ngIf="(this.loginClientForm.controls.clientID.valid) && OTPCheck && !OTPGenerate && !NotAvailable && !showPwdField">
                  Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                <div class="OTP-generation"
                  *ngIf="(OTPCheck && !clientvalidation.clientID.errors?.required && !clientvalidation.clientID.errors?.pattern && !showPwdField)"
                  (click)="generateOtp('client')">
                  <button *ngIf="!OTPGenerate && !NotAvailable">Send OTP</button>

                </div>
                <div *ngIf="showPwdField" class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>
                <div *ngIf="showPwdField" class="form-label">Password<sup>*</sup></div>
                <div *ngIf="showOtpField" class="form-label">Enter the OTP received<sup>*</sup></div>
                <mat-form-field *ngIf="showPwdField || showOtpField" floatLabel="never" class="client-login-field password-margin-mobile"
                  [ngClass]="{'error-field': (loginPasswordFocusOut && clientvalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && clientvalidation.clientPassword.errors?.pattern)}">
                  <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>

                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30"/>
                  <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                  <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                    class="no-mobile" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                  
                  <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" formControlName="clientPassword"
                    (keypress)="errorclear()" type="password" autocomplete="new-password" matInput (focusout)="passwordFocusOut($event)"
                    maxlength="30">
                  <input class="mobile" *ngIf="(reveal && !OTPCheck)" formControlName="clientPassword"
                    (keypress)="errorclear()" type="text" matInput (focusout)="passwordFocusOut($event)" maxlength="30"> -->

                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput maxlength="6" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal) && OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal)&& OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                  <input *ngIf="((clientSecureLoginCheck && reveal)&& OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />

                  <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" formControlName="clientOTP"
                    (keypress)="errorclear()" type="password" autocomplete="new-password" matInput maxlength="6">
                  <input class="mobile" *ngIf="(reveal && OTPCheck)" formControlName="clientOTP"
                    (keypress)="errorclear()" type="text" matInput maxlength="6"> -->

                  <div class="cursor-point" (click)="showPswd()">
                    <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                      *ngIf="reveal">
                    <img formcontrolName="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                      *ngIf="!reveal">
                  </div>
                  <mat-error class="error-message"
                    *ngIf="((loginPasswordFocusOut && clientvalidation.clientPassword.errors?.required))">
                    Enter your Password
                  </mat-error>
                </mat-form-field>

                <div class="form-label-dropdown">Start your journey at</div>
                <mat-form-field class="client-login-field login-field">
                  <mat-select formControlName="landingScreen">
                    <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                      {{opt.label}}
                      <img [src]="opt.src">
                    </mat-option>
                  </mat-select>
                 </mat-form-field>

                <!-- <div *ngIf="showPwdField || showOtpField" class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->

                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" (keypress)="errorclear()" type="tel" [decimalNumbersOnly] matInput
                    (focusout)="captchaFocusOut($event)">
                  <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearClientCaptcha()"
                    [enteredCaptcha]="clientvalidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.captchaIncorrect && !(clientvalidation.clientCaptcha.errors?.pattern) && !(clientvalidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" class="status-message"></div>
                <button type="submit" class="login-button" mat-flat-button
                  [disabled]="!(loginClientForm.valid)">Login</button>
              </form>
            </ng-template>
          </mat-tab>
          <mat-tab label="Email ID">
            <ng-template matTabContent>
              <form [formGroup]="loginEmailForm" (ngSubmit)="submitLoginForm(loginEmailForm.value,'email')">
                <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div>
                <div class="form-label">Email ID<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && emailvalidation.emailID.errors?.required)}">
                  <mat-placeholder>Please enter your Email ID</mat-placeholder>
                  <input formControlName="emailID" (keypress)="errorclear()" type="text" matInput
                    (focusout)="clientFocusOut($event)">
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && emailvalidation.emailID.errors?.required)">
                    Enter email ID.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && emailvalidation.emailID.errors?.email)">
                    Please enter valid email ID.
                  </mat-error>
                </mat-form-field>
                <div class="login-check">
                  <mat-checkbox (change)="OTPpass($event,'email')" class="client-login-checkbox"
                    formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                  </mat-checkbox>
                  <div class="otp-timer-div" *ngIf="(OTPGenerate && OTPCheck) && (statusMessage.length <1)">                    
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                      {{maskMobileNo}}
                    </div>
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}}
                    </div>
                    <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered mobile number {{maskMobileNo}}
                    </div>
                    Your OTP will be valid for <span
                      class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                    <a (click)="RegenOTP('email')"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                  </div>
                </div>
                <div
                  *ngIf="((this.loginEmailForm.controls.emailID.valid) && OTPCheck && !OTPGenerate  && !NotAvailable && !showPwdField)">
                  Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                <div class="OTP-generation"
                  *ngIf="(OTPCheck && !emailvalidation.emailID.errors?.required && !emailvalidation.emailID.errors?.email && !showPwdField)"
                  (click)="generateOtp('email')">
                  <button *ngIf="!OTPGenerate  && !NotAvailable"> Send OTP</button>
                </div>
                <div *ngIf="showPwdField" class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>
                <div *ngIf="showPwdField" class="form-label">Password<sup>*</sup></div>
                <div *ngIf="showOtpField" class="form-label">Enter the OTP received<sup>*</sup></div>
                <mat-form-field *ngIf="showPwdField || showOtpField" floatLabel="never" class="client-login-field password-margin-mobile"
                  [ngClass]="{'error-field': (loginPasswordFocusOut && emailvalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && emailvalidation.clientPassword.errors?.pattern)}">
                  <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
                  
                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)" (keypress)="errorclear()"
                     formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                     formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" (keypress)="errorclear()"
                    class="no-mobile" formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                  <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                    class="no-mobile" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />

                  <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" (keypress)="errorclear()"
                    formControlName="clientPassword" type="password" autocomplete="new-password" matInput (focusout)="passwordFocusOut($event)"
                    maxlength="30">
                  <input class="mobile" *ngIf="(reveal && !OTPCheck)" (keypress)="errorclear()"
                    formControlName="clientPassword" type="text" matInput (focusout)="passwordFocusOut($event)"
                    maxlength="30"> -->

                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && OTPCheck)" (keypress)="errorclear()"
                   formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput maxlength="6" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal)&& OTPCheck)" (keypress)="errorclear()"
                    class="no-mobile" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal)&& OTPCheck)" (keypress)="errorclear()"
                    formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                  <input *ngIf="((clientSecureLoginCheck && reveal)&& OTPCheck)" (keypress)="errorclear()"
                    class="no-mobile" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />

                  <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" (keypress)="errorclear()"
                    formControlName="clientOTP" type="password" autocomplete="new-password" matInput maxlength="6">
                  <input class="mobile" *ngIf="(reveal && OTPCheck)" (keypress)="errorclear()"
                    formControlName="clientOTP" type="text" matInput maxlength="6"> -->

                  <div class="cursor-point" (click)="showPswd()">
                    <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                      *ngIf="reveal">
                    <img formcontrolName="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                      *ngIf="!reveal">
                  </div>
                  <mat-error class="error-message"
                    *ngIf="((loginPasswordFocusOut && emailvalidation.clientPassword.errors?.required))">
                    Enter your Password
                  </mat-error>
                </mat-form-field>

                <div class="form-label-dropdown">Start your journey at</div>
                <mat-form-field class="client-login-field login-field">
                  <mat-select formControlName="landingScreen">
                    <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                      {{opt.label}}
                      <img [src]="opt.src">
                    </mat-option>
                  </mat-select>
                 </mat-form-field>

                <!-- <div *ngIf="showPwdField || showOtpField" class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->
                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" type="tel" (keypress)="errorclear()" matInput
                    (focusout)="captchaFocusOut($event)">
                  <app-captcha #emailCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearEmailCaptcha()"
                    [enteredCaptcha]="emailvalidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.captchaIncorrect && !(emailvalidation.clientCaptcha.errors?.pattern) && !(emailvalidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" class="status-message"></div>
                <button type="submit" value="login" class="login-button" mat-flat-button
                  [disabled]="!(loginEmailForm.valid)">Login</button>
              </form>
            </ng-template>
          </mat-tab>
          <mat-tab label="Mobile No">
            <ng-template matTabContent>
              <form [formGroup]="loginMobileForm" (ngSubmit)="submitLoginForm(loginMobileForm.value,'mobileNo')">
                <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div>
                <div class="form-label">Mobile No<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && mobilevalidation.mobileNo.errors?.required)}">
                  <mat-placeholder>Please enter your Mobile No.</mat-placeholder>
                  <input formControlName="mobileNo" [decimalNumbersOnly] type="tel" (keypress)="errorclear()" matInput
                    (focusout)="clientFocusOut($event)" maxlength="20">
                    <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && mobilevalidation.mobileNo.errors?.required)">
                    Enter mobile number
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && mobilevalidation.mobileNo.errors?.pattern)">
                    Please enter valid mobile number
                  </mat-error>
              </mat-form-field>
              <div class="unit-sr" ><span class="unit-new-sr">NRI Customers: </span>  In case of an international number, please enter mobile number without the country code.
              </div>
                <div class="login-check">
                  <mat-checkbox (change)="OTPpass($event,'mobileNo')" class="client-login-checkbox"
                    formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                  </mat-checkbox>
                  <div class="otp-timer-div" *ngIf="(OTPGenerate && OTPCheck) && (statusMessage.length <1)">                    
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                      {{maskMobileNo}}
                    </div>
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}}
                    </div>
                    <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered mobile number {{maskMobileNo}}
                    </div>
                    Your OTP will be valid for <span
                      class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                    <a (click)="RegenOTP('mobileNo')"><img src="assets/refresh.png" class="refresh-img">Regenerate
                      OTP</a>
                  </div>
                </div>
                <div
                  *ngIf="(this.loginMobileForm.controls.mobileNo.valid) && OTPCheck && !OTPGenerate  && !NotAvailable && !showPwdField">
                  Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                <div class="OTP-generation"
                  *ngIf="(OTPCheck && !mobilevalidation.mobileNo.errors?.required && !mobilevalidation.mobileNo.errors?.pattern && !showPwdField)"
                  (click)="generateOtp('mobileNo')">
                  <button *ngIf="!OTPGenerate  && !NotAvailable">Send OTP</button>
                </div>
                <div *ngIf="showPwdField" class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>
                <div *ngIf="showPwdField" class="form-label">Password<sup>*</sup></div>
                <div *ngIf="showOtpField" class="form-label">Enter the OTP received<sup>*</sup></div>
                <mat-form-field *ngIf="showPwdField || showOtpField" floatLabel="never" class="client-login-field password-margin-mobile"
                  [ngClass]="{'error-field': (loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.pattern)}">
                  <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientPassword" type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                  <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />

                  <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" formControlName="clientPassword"
                    (keypress)="errorclear()" type="password" autocomplete="new-password" matInput (focusout)="passwordFocusOut($event)"
                    maxlength="30">
                  <input class="mobile" *ngIf="(reveal && !OTPCheck)" formControlName="clientPassword"
                    (keypress)="errorclear()" type="text" matInput (focusout)="passwordFocusOut($event)" maxlength="30"> -->

                  <input *ngIf="((!clientSecureLoginCheck && !reveal) && OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput maxlength="6" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal)&& OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />

                  <input *ngIf="((clientSecureLoginCheck && !reveal)&& OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="password" autocomplete="new-password" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                  <input *ngIf="((clientSecureLoginCheck && reveal)&& OTPCheck)" class="no-mobile"
                    (keypress)="errorclear()" formControlName="clientOTP" type="tel" matInput
                    (focusout)="passwordFocusOut($event)" [matKeyboard] [decimalNumbersOnly]  maxlength="6" />

                  <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" formControlName="clientOTP"
                    (keypress)="errorclear()" type="password" autocomplete="new-password" matInput maxlength="6">
                  <input class="mobile" *ngIf="(reveal && OTPCheck)" formControlName="clientOTP"
                    (keypress)="errorclear()" type="text" matInput maxlength="6"> -->

                  <div class="cursor-point" (click)="showPswd()">
                    <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                      *ngIf="reveal">
                    <img formcontrolame="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                      *ngIf="!reveal">
                  </div>
                  <mat-error class="error-message"
                    *ngIf="((loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.required))">
                    Enter your Password
                  </mat-error>
                </mat-form-field>

                <div class="form-label-dropdown">Start your journey at</div>
                <mat-form-field class="client-login-field login-field">
                  <mat-select formControlName="landingScreen">
                    <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                      {{opt.label}}
                      <img [src]="opt.src">
                    </mat-option>
                  </mat-select>
                 </mat-form-field>
                 
                <!-- <div *ngIf="showPwdField || showOtpField" class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->
                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" type="tel" [decimalNumbersOnly] (keypress)="errorclear()" matInput
                    (focusout)="captchaFocusOut($event)">
                  <app-captcha #mobileCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearMobileCaptcha()"
                    [enteredCaptcha]="mobilevalidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.captchaIncorrect && !(mobilevalidation.clientCaptcha.errors?.pattern) && !(mobilevalidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" class="status-message"></div>
                <button type="submit" class="login-button" mat-flat-button
                  [disabled]="!(loginMobileForm.valid)">Login</button>
              </form>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <!-- <div class="orlogin-text">Or Login with</div>
        <div class="login-other-options flex-center">
          <div class="login-other-img">
            <img src="assets/facebook_circle.svg">
          </div>
          <div class="login-other-img">
            <img src="assets/google.svg">
          </div>
        </div> -->
        <div class="register-login-btns flex-center">
          <div class="register-login-btn" (click)="goToNewUser()">
            <div class="register-login-title">Register</div>
            <div class="register-login-text">First time user?</div>
          </div>
         
        </div>
      </mat-card>
    </div>
    <div class="login-right-div">
      <mat-card class="login-right-section">
        <div class="connect-head">
          Now chat with our official
          <span>WhatsApp bot</span> Etty in your preferred language!
        </div>
        <div class="language-list">
          <span class="language-title">Languages available are</span>
          <ul class="languages flex-center">
            <li>
              <a>English</a>
            </li>
            <li>
              <a>Hindi</a>
            </li>
            <li>
              <a>Bengali</a>
            </li>
            <li>
              <a>Telugu</a>
            </li>
            <li>
              <a>Tamil</a>
            </li>
            <li>
              <a>Marathi</a>
            </li>
            <li>
              <a>Gujarati</a>
            </li>
            <li>
              <a>Punjabi</a>
            </li>
            <li>
              <a>Malayalam</a>
            </li>
            <li>
              <a>Kannada</a>
            </li>
          </ul>
        </div>
        <div class="whatsapp-info flex-center">
          <div class="no-mobile">
            <div class="query-icon">
              <img src="assets/customer_care.svg" alt="Customer Care">
            </div>
          </div>
          <div class="query-details">
            <div class="query-details-text">
              WhatsApp your policy queries by
              simply texting a <span>"Hi"</span> on
            </div>
            <div class="whatsapp-number flex" (click)="onBtnClick()">
              <a>+91 82918 90569</a>
              <img src="assets/whatsapp_white.svg">
            </div>
          </div>
          <img class="qr-code" src="assets/QR_code.png" (click)="onBtnClick()">
        </div>

        <div class="useful-links">
          <div class="flex useful-title-div">
            <div class="useful-links-title">USEFUL LINKS</div>
            <img class="useful-link-img" src="assets/link.svg">
          </div>
          <div class="useful-links-list-div">
            <div class="useful-links-list" *ngFor="let item of redirectLinks">
              <div class="link-text" *ngFor="let subitem of item.links"><a href={{subitem.link}}
                  target="_blank" rel="noopener noreferrer">{{subitem.label}}</a></div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

  </div>

  <!-- <mat-card class="login-bottom flex">
    <div class="safety-details flex-center">
      <div class="safety-image">
        <img src="assets/safety_image.png">
      </div>
      <div class="safety-content">
        <div class='safety-span'><span class="strong-red">YOUR SAFETY</span> IS OUR FIRST PRIORITY</div>
        <div class="safety-span-two">Access our digital self-serve options 24/7 at your convenience</div>
        <a href="assets/static-cp-cms/go-digital-today.html" target="_blank" rel="noopener noreferrer">
          <button matButton class="knowmore-button">Know More<img class="knowmore-button-image"
              src="assets/angle_down_red_sml.svg"></button></a>
      </div>
    </div>
    <span class="fill-remaining-space"></span>
    <div class="faq-details">
      <div class="faq-content">
        FAQs for HDFC Life Customers during the COVID-19 pandemic
      </div>
        <button matButton class="knowmore-button" (click)="downloadCovidPdf()">Download &#8250;</button>
    </div> 
  </mat-card> -->
  <ng-template #clientId>
    <mat-dialog-content>
      <div class="forgot-id-popup">
        <div mat-dialog-action class="popup-close">
          <div mat-dialog-close class="popup-close-dialog no-mobile">Close<img src='/assets/close.svg'></div>
        </div>
        <div class="flex-center popup-header">
          <span>To know your Client ID</span>
          <div mat-dialog-close class="popup-close-dialog mobile"></div>
        </div>
        <div class="popup-content">
          <div class="instructions">
            <div class="method-one">
              You can connect on our Digital Platform WhatsApp<span class="bold-text"> "Etty"</span> or save
              the contact and message us on <span class="bold-text">8291890569</span> to know your clientID
            </div>
            <div class="method-two">
              You can <span class="click-here">click here</span> and chat with our Virtual Assistant <span
                class="bold-text">Elle</span> to
              know your clientID
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>
</div>