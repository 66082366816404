<div class="header-section">
        <mat-toolbar class="header-toolbar" [ngClass]="{'header-fixed': !((router.url =='/login') || (router.url =='/surrender-aversion')) }">
            <mat-toolbar-row>
                <div class="main-header-logo" [ngClass]="{'hni-header-logo': !(router.url =='/login' || router.url =='/surrender-aversion') && isHNIUSer}"
                    (click)="redirectToMyPolicies()">
                    <img   class="header-logo" *ngIf="!isHNIUSer" src="assets/logo.svg">
                    <!-- <img *ngIf="(router.url =='/my-policies') && !isHNIUSer" src="assets/logo.png"> -->
                    <img   class="header-logo" *ngIf="isHNIUSer" src="assets/hni_logo.svg">
                </div>
                <span *ngIf="isCustomLink" class="fill-remaining-space"></span>
                <div *ngIf="(isCustomLink && router.url.includes('/my-link/surrender'))" class="helpline-content">For Any Assistance Call – 02268446530</div>
                    <div class="end-header flex-center" *ngIf="(isCustomLink) && !(router.url.includes('/surrender-aversion')) && !(router.url.includes('/login')) && !(router.url.includes('/investigator')) && !(router.url.includes('/ppr-web')) && !(router.url.includes('/my-link?')) && !(router.url.includes('/my-atnc')) && !(router.url.includes('/my-loan-tnc'))">
                    <div class="customer-header"
                        [ngClass]="{'menu': csRedirect,'hni-customer-header': isHNIUSer ,'hni-active-customer':csRedirect && isHNIUSer}">
                        <div class="logout-logo flex" [matMenuTriggerFor]="logoutMenu" #logoutTrigger="matMenuTrigger"
                            (menuOpened)="logoutMenuOpen()">
                            <img src="assets/profile_icon.svg">
                            <span class="customer-service logout-span"></span>
                        </div>
                        <mat-menu #logoutMenu="matMenu" class="logout-content menu-content" backdropClass="customer-menu-backdrop">
                            <span>
                                <li mat-menu-item (click)="customLinkLogout()">Logout</li>
                            </span>
                        </mat-menu>
                    </div>
                </div>
                <span *ngIf="router.url =='/investigator/investigator-fr'" class="fill-remaining-space"></span>
                <div class="customlink-logout-logo" (click)="investigatorLogout()" *ngIf="router.url =='/investigator/investigator-fr'">
                    <img src="assets/logout.svg">
                </div>
                <span *ngIf="router.url.includes('my-atnc')" class="fill-remaining-space"></span>
                <div class="customer-header" *ngIf = "router.url.includes('my-atnc')"
                    [ngClass]="{'menu': csRedirect,'hni-customer-header': isHNIUSer ,'hni-active-customer':csRedirect && isHNIUSer}">
                    <form [formGroup]="languageForm">
                        <mat-form-field class="language-field"> 
                        <mat-label class="language-label">Language</mat-label> 
                        <mat-select disableRipple="true" formControlName="selectedLanguage" (selectionChange)="changeLanguage($event)" class="mat-language" panelClass="language-panel">
                            <mat-option *ngFor="let language of languages" class="option-item" [value]="language.value">
                                    {{ language.languageName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </form>
                </div>
                <div *ngIf="(isLoggedIn() || (router.url.includes('/my-link/surrender?')) && !(router.url.includes('/surrender-aversion')) && !(router.url.includes('/my-link')) && !(router.url.includes('/investigator'))) && !(router.url.includes('/my-atnc')) && !(router.url.includes('/my-loan-tnc'))" class="wrapper flex">
                    <ul *ngIf = "!(router.url.includes('/my-link')) && !(router.url.includes('my-atnc')) && !(router.url.includes('my-loan-tnc'))" class="header-menus flex-center mobile-header-menus">
                        <li class="policy-menu" (click)="redirectToMyPolicies()"
                            [ngClass]="{'active-menu': redirectMyPolicyClicked,'hni-fund-title': isHNIUSer,'hni-active-menu':redirectMyPolicyClicked && isHNIUSer }">
                            <a>
                                <div class="menu-icon"></div><span class="my no-mobile">My <span>Policies</span></span><span class="my mobile">My <span>Policies</span></span>
                            </a>
                        </li>
                        <div class="notify-border"></div>
                        <li class="account-menu" (click)="redirectToMyAccount()"
                            [ngClass]="{'active-menu': redirectMyAccountClicked,'hni-fund-title': isHNIUSer,'hni-active-menu':redirectMyAccountClicked && isHNIUSer }">
                            <a>
                                <div class="menu-icon"></div><span class="my no-mobile">My <span>Account</span></span><span class="my mobile">My <span>Account</span></span>
                            </a>
                        </li>
                        <div class="notify-border"></div>
                        <li class="downloads-menu" (click)="redirectToMyDownloads()"
                            [ngClass]="{'active-menu': redirectMyDownloadsClicked,'hni-fund-title': isHNIUSer,'hni-active-menu':redirectMyDownloadsClicked && isHNIUSer }">
                            <a>
                                <div class="menu-icon"></div> <span class="downloadText">Receipts & Statements</span>
                            </a>
                        </li>
                        <div class="notify-border"></div>
                        <li class="sr-menu" (click)="redirectToSR()"
                        [ngClass]="{'menu': srRedirect,'hni-customer-header': isHNIUSer,'hni-active-customer':srRedirect && isHNIUSer,'active-menu': srRedirect}">
                        <a>
                            <div class="menu-icon"></div><span class="my no-mobile">My Service <span>Requests</span></span><span class="my mobile">My Service <span>Requests</span></span>
                        </a>
                        </li>
                    </ul>
                    <span class="fill-remaining-space"></span>
                    <div *ngIf="(router.url.includes('/my-link/surrender'))" class="helpline-content">For Any Assistance Call – 02268446530</div>
                    <div class="end-header flex-center">
                        <div class="search-img-div" (click)="openSearch()">
                            <img src="assets/search.svg" class="search-img">
                        </div>
                        <div class="search-field no-mobile" (click)="openSearch()">
                            <div class="flex">
                                <img src="assets/search.svg" class="search-img">
                                <div>What are you looking for?</div>  
                            </div>                                              
                        </div>
                        <div class="customer-header" *ngIf = "!(router.url.includes('/my-link')) && !(router.url.includes('my-atnc')) && !(router.url.includes('my-loan-tnc'))"
                            [ngClass]="{'menu': csRedirect,'hni-customer-header': isHNIUSer ,'hni-active-customer':csRedirect && isHNIUSer}">
                            <a class="flex-center customer-text" [matMenuTriggerFor]="customerMenu" #customerTrigger="matMenuTrigger" (menuOpened)="customerMenuOpen()">
                                <img class="headphone-icon" src="assets/headphone.svg" (click)="utils.trackUserActions({'eventName': 'Customer Service - logo'})">
                                <span class="customer-service  no-mobile-customer-service-tab" (click)="utils.trackUserActions({'eventName': 'Customer Service - word'})">Customer Service</span>
                            </a>
                            <mat-menu #customerMenu="matMenu" class="menu-content" backdropClass="customer-menu-backdrop">
                                <span>
                                    <li class="mat-menu-item" mat-menu-item (click)="redirectToFAQ();utils.trackUserActions({'eventName': 'Customer Service - FAQ'})">
                                        <span>FAQ</span>
                                    </li>
                                    <li class="mat-menu-item" mat-menu-item (click)="redirectToServiceForms();utils.trackUserActions({'eventName': 'Customer Service - Service forms'})">
                                       <span>Service Forms</span>
                                    </li>
                                    <li class="mat-menu-item" mat-menu-item (click)="redirectToContactUs();utils.trackUserActions({'eventName': 'Customer Service - Contact us'})">
                                        <span>Contact Us</span>
                                    </li>
                                    <li class="mat-menu-item" [ngClass]="{'inactive-menu' : isCPAdmin}" mat-menu-item (click)="redirectPasswordOptions()" *ngIf="isSpecialLife">
                                        <span>Change Password</span>
                                    </li>
                                    <li class="mat-menu-item" [ngClass]="{'inactive-menu' : isCPAdmin}" mat-menu-item (click)="setSecretQuestions()" *ngIf="isSpecialLife">
                                        <span>Change Secret Questions</span>
                                    </li>
                                    <!-- <li class="mat-menu-item" [ngClass]="{'inactive-menu' : isCPAdmin}" mat-menu-item (click)="setUpSocialMediaID()">
                                        <span>Set up Social Media ID</span>
                                    </li> -->
                                    <li class="mat-menu-item" mat-menu-item (click)="redirectTowellnessVoucher();utils.trackUserActions({'eventName': 'Customer Service - Wellness vouchers'})">
                                        <span>Wellness Voucher</span>
                                    </li>
                                    <li class="mat-menu-item" [ngClass]="{'inactive-menu' : isCPAdmin}" mat-menu-item (click)="redirectToWeeklySubscription();utils.trackUserActions({'eventName': 'Customer Service - Subscribe for unit statements'})">
                                        <span>Subscribe for Unit Statement</span>
                                    </li>
                                </span>
                            </mat-menu>
                        </div>
                        <div class="notification-logo" [matMenuTriggerFor]="notificationMenu" #notificationTrigger="matMenuTrigger" (menuOpened)="notificationMenuOpen()"  (menuClosed)="notificationMenuClose()">
                            <mat-icon svgIcon="bell-icon" [matBadge]="notificationsBadge" matBadgeSize="small" class="bell-icon" [ngClass]="{'no-badge':notificationsBadge<1, 'infinite-pulse':notificationsBadge>=1}"></mat-icon>
                                <!-- <img class="pulse" src="assets/notification.svg"> -->
                        </div>
                        <!-- <div class="notify-border"></div>
                        <div class="notification-logo" (click)="redirectToSR()"
                            [ngClass]="{'menu': srRedirect,'hni-customer-header': isHNIUSer,'hni-active-customer':srRedirect && isHNIUSer}">
                            <img src="assets/notification.svg">
                        </div> -->
                        <!-- <div class="notify-border" *ngIf="!(router.url.includes('my-atnc'))"></div> -->
                       <!--  <div class="logout-logo" [matMenuTriggerFor]="logoutMenu" (click)="logout()" #logoutTrigger="matMenuTrigger" *ngIf="!isMultiple">
                            <img src="assets/logout.svg">
                        </div> -->
                        <div class="customer-header logout" *ngIf="isMultiple && !(router.url.includes('my-atnc'))  && !(router.url.includes('my-loan-tnc'))"
                        [ngClass]="{'menu': csRedirect,'hni-customer-header': isHNIUSer ,'hni-active-customer':csRedirect && isHNIUSer}">
                      
                                <div class="logout-logo flex" [matMenuTriggerFor]="logoutMenu" #logoutTrigger="matMenuTrigger" (menuOpened)="logoutMenuOpen()">
                                        <div class="progress-circle">
                                                <circle-progress
                                                    [percent]="profileCompletionPercent"
                                                    [radius]="24"
                                                    [outerStrokeWidth]="3"                                
                                                    [outerStrokeColor]="'#125a9e'"
                                                    [showTitle]="false"
                                                    [showSubtitle]="false"
                                                    [showInnerStroke]="false"
                                                    [showUnits]="false"
                                                    [renderOnClick]="false"
                                                ></circle-progress>
                                        </div>
                                    <img src="assets/profile_icon.svg" (click)="utils.trackUserActions({'eventName': 'Logout - logo'})">
                                    <div class="fill-remaining-space logout-space" (click)="utils.trackUserActions({'eventName': 'Logout - space'})"></div>
                                    <!-- <img src="assets/angle_down.svg" class="logout-arrow" (click)="utils.trackUserActions({'eventName': 'Logout - arrow'})"> -->
                                </div>
                        <mat-menu #logoutMenu="matMenu"  class="logout-content menu-content" backdropClass="customer-menu-backdrop">
                                <div *ngIf="profileCompletionPercent<100" class="profile-completion">
                                        <div class="title">Complete Your Profile</div>
                                        <div class="progress-wrapper">
                                            <div class="progress-semi-circle">
                                                <circle-progress
                                                    [percent]="profileCompletionPercent/2"
                                                    [radius]="40"
                                                    [outerStrokeWidth]="3"                                
                                                    [outerStrokeColor]="'#43b45a'"
                                                    [showTitle]="false"
                                                    [showSubtitle]="false"
                                                    [showInnerStroke]="false"
                                                    [showUnits]="false"
                                                    [renderOnClick]="false"
                                                ></circle-progress>
                                            </div>
                                            <div class="percent-wrapper">
                                                <span class="percent">{{profileCompletionPercent | number:'1.0-0':'en-IN'}}</span>
                                                <span class="symbol">%</span>
                                            </div>                                
                                        </div>                            
                                        <div class="links-wrapper">
                                            <ng-container *ngFor="let item of topTwoProfileCompletenessLinks">                                    
                                                <div class="link" (click)="onProfileLinkClick(item.searchId)">{{item.label}}</div>                                  
                                            </ng-container>
                                            <div class="link view-more" (click)="openDialog(profileComplete)">View more</div>
                                        </div>
                                    </div>        
                            <span>
                                <li mat-menu-item (click)="setlogoutMode();utils.trackUserActions({'eventName': 'Logout - word'})">Logout</li>
                                <li mat-menu-item (click)="switchPolicyGroup();utils.trackUserActions({'eventName': 'switch client id'})"> Switch Client ID</li>
                            </span>
                        </mat-menu>
                        </div>
                        <div class="customer-header logout" *ngIf="!isMultiple && !(router.url.includes('my-atnc')) &&  !(router.url.includes('my-loan-tnc'))"
                        [ngClass]="{'menu': csRedirect,'hni-customer-header': isHNIUSer ,'hni-active-customer':csRedirect && isHNIUSer}">                 
                                <div class="logout-logo flex" [matMenuTriggerFor]="logoutMenu" #logoutTrigger="matMenuTrigger" (menuOpened)="logoutMenuOpen()">
                                        <div class="progress-circle">
                                                <circle-progress
                                                    [percent]="profileCompletionPercent"
                                                    [radius]="24"
                                                    [outerStrokeWidth]="3"                                
                                                    [outerStrokeColor]="'#125a9e'"
                                                    [showTitle]="false"
                                                    [showSubtitle]="false"
                                                    [showInnerStroke]="false"
                                                    [showUnits]="false"
                                                    [renderOnClick]="false"
                                                ></circle-progress>
                                            </div>                                        
                                    <img src="assets/profile_icon.svg" (click)="utils.trackUserActions({'eventName': 'Logout - logo'})">
                                    <div class="fill-remaining-space logout-space" (click)="utils.trackUserActions({'eventName': 'Logout - space'})"></div>
                                    <!-- <img src="assets/angle_down.svg" class="logout-arrow" (click)="utils.trackUserActions({'eventName': 'Logout - arrow'})"> -->
                                </div>
                        <mat-menu #logoutMenu="matMenu"  class="logout-content menu-content" backdropClass="customer-menu-backdrop">
                                <div *ngIf="profileCompletionPercent<100" class="profile-completion">
                                        <div class="title">Complete Your Profile</div>
                                        <div class="progress-wrapper">
                                            <div class="progress-semi-circle">
                                                <circle-progress
                                                    [percent]="profileCompletionPercent/2"
                                                    [radius]="40"
                                                    [outerStrokeWidth]="3"                                
                                                    [outerStrokeColor]="'#43b45a'"
                                                    [showTitle]="false"
                                                    [showSubtitle]="false"
                                                    [showInnerStroke]="false"
                                                    [showUnits]="false"
                                                    [renderOnClick]="false"
                                                ></circle-progress>
                                            </div>
                                            <div class="percent-wrapper">
                                                <span class="percent">{{profileCompletionPercent | number:'1.0-0':'en-IN'}}</span>
                                                <span class="symbol">%</span>
                                            </div>                                
                                        </div>                            
                                        <div class="links-wrapper">
                                            <ng-container *ngFor="let item of topTwoProfileCompletenessLinks">                                    
                                                <div class="link" (click)="onProfileLinkClick(item.searchId)">{{item.label}}</div>                                  
                                            </ng-container>
                                            <div class="link view-more" (click)="openDialog(profileComplete)">View more</div>
                                        </div>
                                    </div>                                    
                            <span>
                                <li mat-menu-item (click)="setlogoutMode();utils.trackUserActions({'eventName': 'Logout - word'})">Logout</li>
                            </span>
                        </mat-menu>
                        </div>
                        <mat-menu #notificationMenu="matMenu" class="notification-menu" backdropClass="customer-menu-backdrop">
                                <mat-tab-group class="notification-tabs" animationDuration="300ms" [disableRipple]="true" [selectedIndex]="(isNeedAttnMapEmpty && openFRsNotifications.length<=0) && fyiNotifications.length>0 ? 1 : 0" (click)="$event.stopPropagation()" (selectedTabChange)="tabChange($event)">
                                    <mat-tab label="needattention">
                                        <ng-template mat-tab-label>Needs Attention</ng-template>                                
                                            <div>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['PreMaturity'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked == true}" (click)="onNeedAttnClick(item, 'MAT_CLAIM',i,'PreMaturity')">
                                                    You are nearing the maturity of your policy no. {{item.POLICYNO}} on {{item.MATURITYDATE}}. <span class="click-here">Click here</span> to submit your documents for seamless payout
                                                </mat-card>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['PostMaturity'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked == true}" (click)="onNeedAttnClick(item, 'MAT_CLAIM',i,'PostMaturity')">
                                                    Your policy no. {{item.POLICYNO}} has matured. <span class="click-here">Click here</span> to submit your documents for easy payout
                                                </mat-card>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['MoneyBack'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked==true}" (click)="onNeedAttnClick(item, 'MON_BK_CLAIM',i,'MoneyBack')">
                                                    Money Back claim is initiated for your policy no. {{item.POLICYNO}}. <span class="click-here">Click here</span> to submit your documents for seamless payout
                                                </mat-card>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['Premium'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked==true}" (click)="onNeedAttnClick(item, 'Premium' , i,'Premium')">
                                                    Your policy no. {{item.POLICYNO}} with premium amount Rs. {{item.PREMIUM}} is due on {{item.PREMIUMDUEDT}}. <span class="click-here">Click here</span> to pay now
                                                </mat-card>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['Revival'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked==true}" (click)="onNeedAttnClick(item, 'REVIVAL',i,'Revival')">
                                                    Policy no. {{item.POLICYNO}} has lapsed. Revive your policy to secure your family's future. <span class="click-here">Click here</span> to revive now
                                                </mat-card>
                                                <mat-card *ngFor="let item of needAttnNotificationsMap['SI'];let i = index" class="notification-card" [ngClass]="{'read':item.itemClicked==true}" (click)="onNeedAttnClick(item, 'SI',i,'SI')">
                                                    Missing out on premium dues? <span class="click-here">Click here</span> to update your SI/ECS details for hassle free payments for policy no. {{item.POLICYNO}}
                                                </mat-card>
                                                <ng-container *ngFor="let item of needAttnNotificationsMap['openFr'];let i = index">
                                                    <mat-card class="notification-card" (click)="onNeedAttnClick(item, 'SR',i,'openFr')"[ngClass]="{'read':item.itemClicked==true}" *ngIf="item.SR_SUB_STAT_ID != 'Pending Mandate Registration'">
                                                        To process your {{item.SUBACTIVITY}} request (SR No. {{item.SR_NUM}}), we would need 
                                                        <span *ngFor="let desc of item.req_descriptions;let idx=index">
                                                            {{desc}}<span *ngIf="idx!=item.req_descriptions.length-1">, </span>
                                                        </span>. <span class="click-here">Click here</span> to submit now 
                                                    </mat-card>
                                                    <mat-card class="notification-card" (click)="onNeedAttnClick(item, 'SR',i,'openFr')" [ngClass]="{'read':item.itemClicked==true}" *ngIf="item.SR_SUB_STAT_ID == 'Pending Mandate Registration'">
                                                        Your request for {{item.SUBACTIVITY}} with request (SR No. {{item.SR_NUM}}), is {{item.SR_SUB_STAT_ID}}. <span class="click-here">Click here</span> to submit now 
                                                    </mat-card>
                                                </ng-container>
                                                <ng-container *ngFor="let item of needAttnNotificationsMap['Riders'];let i = index">
                                                    <mat-card *ngFor="let riderDtls of item.adcatRiderDtls.riderData.riderArr;let j = index" class="notification-card" [ngClass]="{'read':riderDtls.itemClicked==true}" (click)="onRiderClick(item, riderDtls.riderName,i,item.POLICYNO,j)">
                                                            <!--  -->
                                                        Grab opportunity to add pre-approved {{riderDtls.riderName}} rider at just Rs. {{riderDtls.riderPremiumIncTax}} without any medicals or documentation. <span class="click-here">Click here</span> to buy now against policy no. {{item.POLICYNO}}
                                                    </mat-card>
                                                </ng-container>
                                                <!-- <ng-container *ngFor="let item of needAttnNotificationsMap['Loans'];let i = index">
                                                    <mat-card class="notification-card" [ngClass]="{'read':item.itemClicked==true}" (click)="redirectToAvailLoan(availLoan, item,i)">
                                                        Avail pre-approved loan upto Rs. {{item.loanDtls.MaxLoanAmount}} with no/minimal documentation. <span class="click-here">Click here</span> to avail now for policy no. {{item.POLICYNO}}                                                
                                                    </mat-card>
                                                </ng-container> -->
                                                <ng-container *ngIf="pasaNudge == 'Y'">
                                                    <mat-card class="notification-card" [ngClass]="{'inactive-menu' : isCPAdmin,'read':pasaRead}" (click)="redirectToPasa()">
                                                        Protect your future with up to <span class="amount-text">&#8377; {{pasaSumAssured | number}}</span> in term plan coverage!<a><span class="click-text">Click here</span></a> to get insured now.                                                
                                                    </mat-card>
                                                </ng-container>
                                                <!-- <ng-container *ngIf="npsNudge == 'Y'">
                                                    <mat-card class="notification-card" [ngClass]="{'inactive-menu' : isCPAdmin,'read':npsRead}" (click)="redirectToNps()">
                                                        Start your retirement today, by investing in NPS.<a><span class="click-text">Click here</span></a> to buy now.                                                
                                                    </mat-card>
                                                </ng-container> -->
                                                <ng-container *ngIf="isNeedAttnMapEmpty && openFRsNotifications.length <= 0 && pasaNudge != 'Y' && npsNudge != 'Y'">
                                                    <mat-card class="notification-card">
                                                        No records found   
                                                    </mat-card>
                                                </ng-container>    
                    </div>
                </mat-tab>
                <mat-tab label="fyi">
                    <ng-template mat-tab-label>For Your Information</ng-template>                             
                        <div>
                            <ng-container *ngFor="let item of fyiNotifications">
                                <mat-card class="notification-card" [ngClass]="{'read':item.READ_STATUS=='Y'}"><!--(click)="onFYIClick(item)-->
                                    <ng-container [ngTemplateOutlet]="fyiText" [ngTemplateOutletContext]="{item:item}">
                                    </ng-container>
                                    <div class="sr-date">{{item.RECORD_CREATED_DT}}</div>
                                </mat-card>
                            </ng-container>
                            <mat-card *ngIf="fyiNotifications.length<=0" class="notification-card">
                                <div>No records found</div>
                            </mat-card>                                        
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-menu>
    </div>
    </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <ng-template #profileComplete>
                <div class="profile-complete">
                    <div mat-dialog-actions (click)="closePopup()">
                        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                        Close
                            <img src="assets/close.svg">
                        </div>
                    </div>
                    <div class="profile-complete-header">
                        Complete Your Profile
                    </div>
                    <div class="content-wrapper popup-content-height">
                        <ng-container *ngFor="let item of profileCompletenessLinks">
                            <div class="link" *ngIf="profileCompletionDtls[item['tag']]=='N'" (click)="onProfileLinkClick(item.searchId)">{{item.label}}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
            <ng-template #whatsapp>
                <div class="profile-complete">
                    <div mat-dialog-actions (click)="closePopup()">
                        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                        Close
                            <img src="assets/close.svg">
                        </div>
                    </div>
                    <div class="profile-complete-header">
                        Note
                    </div>
                    <div class="content-wrapper popup-content-height">
                        <div class="text">To opt-in for Whatsapp, we require your mobile number. Since your contact details are not updated, kindly request you to <span (click)="onProfileLinkClick('13')">click here</span> to update the same.</div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-item="item" #fyiText>
                <div *ngIf="item.SR_STATUS=='System Closed'"><!--; else showAutoClosedText-->
                    We have successfully updated details against your request for {{item.SUB_ACTIVITY}} with SR no. {{item.SR_NO}}
                </div>
                <div *ngIf="item.SR_STATUS=='Closed'"><!--showAutoClosedText-->
                    Your {{item.SUB_ACTIVITY}} request (SR No. {{item.SR_NO}}) had to be closed since we did not receive requested documents/details
                </div>
            </ng-template>
            <!-- <ng-template #availLoan>
                    <div class="sum-assured">
                        <div mat-dialog-actions class="mat-dialog-actions">
                            <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                                Close
                                <img src="assets/close.svg">
                            </div>
                        </div>
                        <div class="sum-assured-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Avail Loan
                        </div>
                
                        <div class="sum-assure popup-content-height">
                            <div class="card-content">
                                <div class="loan-container flex">
                                    <img src="/assets/avail-loan-qr.jpg" alt="" width="150" height="150" />
                                    <span class="loan-qr-content">Scan this QR code to continue the loan journey on mobile.</span>
                                </div>
                                <div class="loan-popover-content-card">
                                    <span class="loan-popover-content-span-one">Or
                                        <span class="loan-popover-content-span-two" (click)="availLoanLink()">Click here </span>
                                        to continue the journey on same device.
                                    </span>
                                </div>
                            </div>
                
                            <div class="button-div flex">
                                <button matButton class="close-button" (click)="closePopup()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </ng-template> -->
            
        
    </div>