import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent implements OnInit {
  msg: string;
  isSuccess: boolean;
  showAsText: boolean = false;

  constructor(private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data) { }

  ngOnInit(): void {
    this.msg = this.data.msg;
    this.isSuccess = this.data.snackbarType == "success-snackbar" ? true : false;
    if (this.msg && this.msg.includes('\n')) {
      this.showAsText = true;
    }
  }
  close() {
    this.snackBarRef.dismiss();
  }

}
