import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

/**
 * EncryptDecryptService
 *
 * Encryption standard:  AES(Advanced Encryption Standard)
 * Mode: CBC ( Cipher Block Chaining )
 *
 * @see {@link https://cryptojs.gitbook.io/docs/ | CryptoJS}
 *
 */

@Injectable({
    providedIn: 'root',
})
export class EncryptDecryptService {
    key: string = null;
    iv: string = null;

    constructor() {}

    /**
     * @function encryptJson Encrypts JSON using AES encryption standard in CBC mode
     *
     * @param { string } value JSON to be encrypted
     * @param { string } ikey Initialisation key
     * @param { string } ivKey Initialisation Vector
     */

    encryptJson(value: string, ikey: string = this.key, ivKey: string = this.iv): string {
        const key = CryptoJS.enc.Utf8.parse(ikey);
        const iv = CryptoJS.enc.Utf8.parse(ivKey);
        const text = CryptoJS.enc.Utf8.parse(value.toString());
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            keySize: 128 / 8,
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }

    /**
     *
     * @function decryptJson Decrypts JSON using AES encryption standard in CBC mode
     *
     * @param { string } value text to be decrypted
     * @param { string } ikey Initialisation key
     * @param { string } ivKey Initialisation Vector
     *
     */
    decryptJson(value: string, ikey: string = this.key, ivKey: string = this.iv): any {
        try {
            const key = CryptoJS.enc.Utf8.parse(ikey);
            const iv = CryptoJS.enc.Utf8.parse(ivKey);
            const decrypted = CryptoJS.AES.decrypt(value.toString(), key, {
                keySize: 128 / 8,
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedStr);
        } catch (error) {
            console.error('Error while decrypting ' + value, error);
        }
    }

    /**
     *
     * @function decryptJson Decrypts string using AES encryption standard in CBC mode
     *
     * @param { string } value text to be decrypted
     * @param { string } ikey Initialisation key
     * @param { string } ivKey Initialisation Vector
     *
     */
    decryptString(value: string, ikey: string = this.key, ivKey: string = this.iv): any {
        try {
            const key = CryptoJS.enc.Utf8.parse(ikey);
            const iv = CryptoJS.enc.Utf8.parse(ivKey);
            const decrypted = CryptoJS.AES.decrypt(value.toString(), key, {
                keySize: 128 / 8,
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
            return decryptedStr;
        } catch (error) {
            console.error('Error while decrypting ' + value, error);
        }
    }
    
}
