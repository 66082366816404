<div class="hospital-list-page">
    <div class="custom-link-page-padding">
        <div class="custom-link-page-split flex">
            <div class="custom-link-left-div">
                <mat-card class="custom-link-left-section">
                    <div *ngIf="!showHospitalDetails">
                        <mat-card-title class="login-title">Network Hospital</mat-card-title>
                        <form class="form-section" [formGroup]="networkHospitalForm" (ngSubmit)="submitForm()" autocomplete="off">
                            <div class="form-field-section flex">
                                <div class="form-field-div">
                                    <mat-form-field class="mailing-form-field">
                                        <label class="form-field-label">State <span class="error-two">*</span></label>
                                        <input type="text" matInput formControlName="state"
                                            [matAutocomplete]="selectState">
                                        <mat-autocomplete #selectState="matAutocomplete" [displayWith]="displayValue"
                                            (optionSelected)="stateSelected($event)">
                                            <mat-option *ngFor="let item of filteredState | async" [value]="item">
                                                {{item}}</mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="networkHospitalForm.controls.state.errors?.errorMsg" class="error-message">
                                            {{networkHospitalForm.controls.state.errors?.errorMsg}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-field-div">
                                    <mat-form-field class="mailing-form-field">
                                        <label class="form-field-label">City <span class="error-two">*</span></label>
                                        <input type="text" matInput formControlName="city"
                                            [matAutocomplete]="selectCity">
                                        <mat-autocomplete #selectCity="matAutocomplete" [displayWith]="displayValue">
                                            <mat-option *ngFor="let item of filteredCity | async" [value]="item">
                                                {{item}}</mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="networkHospitalForm.controls.city.errors?.errorMsg" class="error-message">
                                            {{networkHospitalForm.controls.city.errors?.errorMsg}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="button-div flex">
                                <button mat-button class="submit-button" type="submit">Search</button>
                            </div>
                        </form>
                        <div *ngIf="hospitalList.length > 0 && showTable" class="table-section">
                            <div class="sub-title">Network Hospital List</div>
                            <div class="table-wrapper">
                                <table mat-table matSort [dataSource]="dataSource" class="hospital-table">
                                    <ng-container matColumnDef="PROVIDER_NO">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider No.</th>
                                        <td mat-cell *matCellDef="let element">{{element.PROVIDER_NO}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ROHINI">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ROHINI Id</th>
                                        <td mat-cell *matCellDef="let element">{{element.ROHINI}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="HOSPITAL_NAME">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hospital Name</th>
                                        <td mat-cell *matCellDef="let element" (click)="showHospitalDtls(element)">
                                            {{element.HOSPITAL_NAME}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ADDRESS">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                                        <td mat-cell *matCellDef="let element">{{element.ADDRESS}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="CITY">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                                        <td mat-cell *matCellDef="let element">{{element.CITY}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="STATE">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                                        <td mat-cell *matCellDef="let element">{{element.STATE}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    <div *ngIf="hospitalData && showHospitalDetails">
                        <div class="back-click" (click)="goBackSRPage()">Back</div>
                        <div class="sub-title">{{hospitalData.HOSPITAL_NAME}}</div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Medical Infrastructure of the hospital
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText">
                                    <ng-container class="sr-detail-title sr-detail-text"
                                        *ngIf="field.category === 'infrastructure'">
                                        <div class="sr-details-field">
                                            <ng-container [ngTemplateOutlet]="inputIterator"
                                                [ngTemplateOutletContext]="{field: field}">
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="sr-details-options">
                            <div class="sr-details-header">
                                Parameters of the hospital
                            </div>
                            <div class="sr-details-content flex">
                                <ng-container *ngFor="let field of staticFieldText">
                                    <ng-container class="sr-detail-title sr-detail-text"
                                        *ngIf="field.category === 'Parameters'">
                                        <div class="sr-details-field ">
                                            <ng-container [ngTemplateOutlet]="inputIterator"
                                                [ngTemplateOutletContext]="{field: field}">
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<ng-template let-field="field" #inputIterator>
    <div class="sr-detail-title">
        {{field.label}}
    </div>
    <div class="sr-detail-text" *ngIf="hospitalData[field.id] !== null && hospitalData[field.id] !== ''; else showNA">
        {{hospitalData[field.id]}}
    </div>
    <ng-template #showNA>
        NA
    </ng-template>
</ng-template>