<div class="declaration-page">
    <div class="custom-link-page-padding">
        <div class="custom-link-page-split flex">
            <div class="custom-link-left-div">
                <mat-card class="custom-link-left-section">
                    <mat-card-title class="login-title">Declaration by the Policy holder</mat-card-title>
                    <div class="instruction-section">
                        <div class="declaration-text">
                            In case of any payout request, if the Company (HDFC Life) has not received your policy
                            document
                            and the request has been processed with your consent,
                            following are the declarations accepted by you:
                        </div>
                        <div class="list-item flex" *ngFor="let item of declarationList">
                            <img src="assets/li-style.svg" class="list-item-img">
                            <span>{{item}}</span>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>