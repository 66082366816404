import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-toaster-popup',
  templateUrl: './toaster-popup.component.html',
  styleUrls: ['./toaster-popup.component.css']
})
export class ToasterPopupComponent implements OnInit {
  isHidden: boolean = false;
  isCPAdminPortal: boolean = false;
  @Input() data: any;
  mesg: { passexp: { msg: string; label: string; target: { path: string[]; }; }; };
  toasterMesg: any;
  toasterLabel: any;
  toasterTarget: { path: string[]; };
  userDetails: any;
  constructor(public myPoliciesService: MyPoliciesService,public router: Router, public utils: Utils, public dataService: DataService,public _httpService: HttpService,) {
  }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    this.getExpPasswordDtls();
    this.isCPAdminPortal = this.dataService.checkCPadmin();
  }
  hideToaster() {
    this.isHidden = true;
  }
  onUpdateBtnClick(targetPath: any) {
    if(targetPath.tab == 'APS'){
      this.apsToasterRedirect(targetPath);
    }
    else{
      this.router.navigate(targetPath.path, {queryParams: {tab: targetPath.tab}});
    }
  }
  apsToasterRedirect(targetPath) {
    let reqBody = {
     "clientid": this.utils.getClientId(),
     "policyno":"",
     "functionality": "APS_BANNER",
   };
   this.myPoliciesService.nudgeRedirectUpdate(reqBody).subscribe((resp) => {
     if (this._httpService.isSuccessResponse(resp)) {
      this.router.navigate(targetPath.path, {queryParams: {tab: targetPath.tab}});
     } else {
       this._httpService.apiErrorHandler(resp);
     }
   }),
     catchError((error) => throwError(error)); 
}

  getExpPasswordDtls() {
    if (this.utils.getRemainingDays()==0 && this.userDetails.DATEOFBIRTH == "01/01/9999"){
      const expPassDtls = {
        passexp: {
          msg: "Your Password is Due for expiry today" + " " + this.utils.getExpiryDtls(),
          label: "Reset Password",
          target: {
            path: ["/login/change-password"]
          }
        }
      }
      this.mesg= expPassDtls;
      this.toasterMesg = this.mesg.passexp.msg;
      this.toasterLabel = this.mesg.passexp.label;
      this.toasterTarget = this.mesg.passexp.target;
    }
    else if (this.utils.getRemainingDays()==1 && this.userDetails.DATEOFBIRTH == "01/01/9999"){
      const expPassDtls = {
        passexp: {
          msg: "Your Password is Due for expiry tommorow" + " " + this.utils.getExpiryDtls(),
          label: "Reset Password",
          target: {
            path: ["/login/change-password"]
          }
        }
      }
      this.mesg= expPassDtls;
      this.toasterMesg = this.mesg.passexp.msg;
      this.toasterLabel = this.mesg.passexp.label;
      this.toasterTarget = this.mesg.passexp.target;
    }
    else if (this.utils.getRemainingDays()<0 && this.userDetails.DATEOFBIRTH == "01/01/9999"){
      const expPassDtls = {
        passexp: {
          msg: "Your Password has expired on" + " " + this.utils.getExpiryDtls(),
          label: "Reset Password",
          target: {
            path: ["/login/change-password"]
          }
        }
      }
      this.mesg= expPassDtls;
      this.toasterMesg = this.mesg.passexp.msg;
      this.toasterLabel = this.mesg.passexp.label;
      this.toasterTarget = this.mesg.passexp.target;
    }
    else if(this.utils.getRemainingDays()<7 &&this.utils.getRemainingDays()>1 && this.userDetails.DATEOFBIRTH == "01/01/9999") {
      const expPassDtls = {
        passexp: {
          msg: "Your Password will expire on" + " " + this.utils.getExpiryDtls(),
          label: "Reset Password",
          target: {
            path: ["/login/change-password"]
          }
        }
      }
      this.mesg= expPassDtls;
      this.toasterMesg = this.mesg.passexp.msg;
      this.toasterLabel = this.mesg.passexp.label;
      this.toasterTarget = this.mesg.passexp.target;
    }
    else {
      this.toasterMesg = this.data.msg;
      this.toasterLabel = this.data.label;
      this.toasterTarget = this.data.target;
    }
  }
}
