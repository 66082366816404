<ng-container *ngIf="showlogoutTimer;else logoutCnfm">
    <div  #logoutTimer>
        <div class="my-policies-popup">
            <div mat-dialog-actions (click)="keepworking()">
                <div mat-dialog-close class="close-dialog">
                    
                </div>
            </div>
        <div class="confirm-content-wrapper contact-div">
                <mat-card-title class="title-text">Session will expire soon!</mat-card-title>
                <div >
                    <p class="logout-popup-content">You will be automatically logged out<br/>for security reasons in<span class="logout-popup-timer">{{min|number:'2.0-0'}}:{{seconds|number:'2.0-0'}}</span></p>
                    <p class="logout-popup-content">Would you like to keep working?</p>
                </div>
                <div class="button-div flex address-div">
                    <button mat-button class="session-logout" (click)="setlogoutMode()">Logout</button>
                    <button mat-button class="submit-btn" (click)="keepworking()">Keep working</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #logoutCnfm>
    <div class="my-policies-popup">
        <div class="confirm-content-wrapper contact-div">
            <mat-card-title class="title-text">You've Been Logged Out</mat-card-title>
            <div >
                <p class="logout-popup-content">Please log back in.</p>
            </div>
            <div class="button-div flex logout-cnfrm">
                <button mat-button class="session-logout logout-ok" (click)="dialogClose()">OK</button>
            </div>
        </div>
    </div>
</ng-template>