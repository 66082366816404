<div class="camera-popup">
        <div *ngIf="!data.isDigiConsent" mat-dialog-actions>
                <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                        <div class="no-mobile">
                                Close<img src="assets/close.svg" class="close-icon">
                        </div>
                        <img src="assets/cross_circle_blue.svg" class="mobile">
                </div>
        </div>
        <div class="camera-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}">{{title}}</div>
        <div class="camera-popup-content popup-content-height">
                <div *ngIf="nextSnapshot" class="camera-div">
                        <div>Do you want to take another photo?</div>
                        <div class="button-div flex">
                                <button class="submit-button" (click)="closeCamera()">No</button>
                                <button class="submit-button" (click)="showWebCam()">Yes</button>
                        </div>
                </div>
                <div class="camera-div" *ngIf="isCameraExist">
                        <webcam *ngIf="showWebcam && !nextSnapshot"
                                [width]="300" [height]="300"
                                [trigger]="triggerObservable"                                
                                [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions"
                                (imageCapture)="handleImage($event)"
                                (initError)="handleInitError($event)">
                        </webcam>
                        <img *ngIf="(!showWebcam && webCamImage && !nextSnapshot)" [src]="webCamImage.imageAsDataUrl">
                </div>
                <div *ngIf="!isCameraExist" class="camera-div">Camera Device not available</div>
                <div *ngIf="!showWebcam && webCamImage && !nextSnapshot && !showConsent" class="button-div flex">
                        <button class="submit-button" (click)="acceptSnapshot()">Proceed</button>
                        <button class="submit-button" (click)="rejectSnapshot()">Retake</button>
                </div>
                <div *ngIf="isCameraExist && !nextSnapshot" class="button-div flex">
                        <button *ngIf="showWebcam" class="submit-button" (click)="takeSnapshot()">Take Photo</button>
                        <button *ngIf="showWebcam && isMultipleCameras" class="submit-button" (click)="changeWebCam(true)">Toggle Camera</button>
                </div>  
                <div *ngIf="showConsent" class="camera-div">
                        <div class="consent-checkbox-wrapper">
                                By clicking the Agree button, I agree to provide my consent
                                <!-- <mat-checkbox #consentCheckbox class="consent-checkbox" [disableRipple]="true">
                                        By clicking on Agree button, you provide your consent
                                </mat-checkbox> -->
                        </div>
                        <div class="button-div flex">
                                <button class="submit-button" type="button" (click)="onConsentSubmit()">Agree</button>
                        </div>
                </div>              
        </div>
</div>
