import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { startWith, debounceTime, map, catchError } from 'rxjs/operators';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OptionValue } from '../../my-account/my-account-data-modal';
import { HttpService } from 'src/app/services/http/http.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { hospitalDtlsFields } from 'src/app/utils/app-constants';

@Component({
  selector: 'app-hospital-list',
  templateUrl: './hospital-list.component.html',
  styleUrls: ['./hospital-list.component.css']
})
export class HospitalListComponent implements OnInit {
  networkHospitalForm: FormGroup;
  filteredState: Observable<string[]>;
  filteredCity: Observable<string[]>;
  states: Array<string> = [];
  cities: Array<string> = [];
  displayedColumns: Array<string> = ["PROVIDER_NO", "ROHINI", "HOSPITAL_NAME", "ADDRESS", "CITY", "STATE"];
  hospitalList: Array<any> = [];
  showTable: boolean = false;
  showHospitalDetails:boolean;
  staticFieldText = hospitalDtlsFields;
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  hospitalData: any;

  constructor(public fb: FormBuilder, public customLinkService: CustomLinkService, public httpService: HttpService) { }

  ngOnInit(): void {
    this.networkHospitalForm = this.fb.group({
      "state": ['', this.hospitalStateValidator.bind(this)],
      "city": ['', this.hospitalCityValidator.bind(this)]
    });
    this.httpService.init({}).subscribe(resp => {
      this.httpService.initResp = resp;
      this.getStates();
    }),
    catchError((error) => throwError(error)); 
  }
  private getFilteredList(value: string, data: Array<any>): Array<any> {
    return data.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
  }
  displayValue(selectedValue): string {
    return selectedValue ? selectedValue : '';
  }
  getStates() {
    let reqBody = {
      "State": "irda_hospitalstate"
    }
    return this.customLinkService.getHospitalStates(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.states = resp.body.States;
        this.filteredState = this.networkHospitalForm.get('state').valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          map((item) => item ? this.getFilteredList(item, this.states) : this.states.slice()
          )
        );
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  getCities(state) {
    let reqBody = {
      "State": state
    }
    return this.customLinkService.getHospitalCityByState(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.cities = resp.body.Cities
        this.filteredCity = this.networkHospitalForm.get('city').valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          map((item) => item ? this.getFilteredList(item, this.cities) : this.cities.slice()
          )
        );
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  stateSelected(event: MatAutocompleteSelectedEvent) {
    const selectedState: OptionValue = event.option.value;
    this.networkHospitalForm.get('city').reset();
    this.cities = [];
    this.getCities(selectedState);
  }
  getHospitalList() {
    let reqBody = {
      "State": this.networkHospitalForm.controls.state.value.toLowerCase(),
      "City": this.networkHospitalForm.controls.city.value.toLowerCase(),
    }
    this.showTable = false;
    return this.customLinkService.getHospitalList(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.hospitalList = resp.body.HospitalList
        this.dataSource = new MatTableDataSource(this.hospitalList);
        this.showTable = true;
        this.addPaginationAndSorting();
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  showHospitalDtls(element) {
    this.hospitalData = element;
    this.showHospitalDetails = true;
    window.scroll(0, 0);
  }
  goBackSRPage(){
    this.showHospitalDetails = false;
    this.addPaginationAndSorting();
  }
  addPaginationAndSorting() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }
  submitForm() {
    if (this.networkHospitalForm.valid) {
      this.getHospitalList();
    } else {
      this.networkHospitalForm.markAllAsTouched();
    }
  }
  hospitalStateValidator(control: AbstractControl) {
    if (control.value == null || control.value.length === 0 || control.value == undefined) {
        return { errorMsg: 'Please select state' };
    } else if (this.states && !this.states.includes(control.value)) {
        return { errorMsg: 'Please select state from the list' };
    }
  }
  hospitalCityValidator(control: AbstractControl) {
    if (control.value == null || control.value.length === 0 || control.value == undefined) {
        return { errorMsg: 'Please select city' };
    } else if (this.cities && !this.cities.includes(control.value)) {
        return { errorMsg: 'Please select city from the list' };
    }
  }

}
