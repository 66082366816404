import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-forgot-client-id',
  templateUrl: './forgot-client-id.component.html',
  styleUrls: ['./forgot-client-id.component.css']
})
export class ForgotClientIdComponent implements OnInit {
  isHNIUSer:boolean = false;
  userDetails: any = {};

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
       this.isHNIUSer = true;
     }
  }

}
