<div class="my-account-page" [ngClass]="{customlink: 'dataService.isCustomLink'}">
    <div class="flex my-account-split">
        <div class="my-account-left">
            <div class="my-account-top-spacing"></div>
            <div *ngIf="!dataService.isDeepLink" class="flex-center select-policy-mob">
                <span class="fill-remaining-space"></span>
                <button mat-buton (click)='switchClientId()' *ngIf="!isHNIUSer && isMultiple" class="policy-select switch-select" [appDecorate]>Switch Client ID</button>
                <button mat-buton (click)='switchClientId()' *ngIf="isHNIUSer && isMultiple" class="policy-select" [ngClass]="{'hni-top-btns':isHNIUSer,'my-policy-height':isHNIUSer}" [appDecorate]>Switch Client ID</button>
            </div>
            <mat-tab-group class="my-account-tabs" animationDuration="0ms" [disableRipple]=true
                (selectedTabChange)="myAccountTabs($event)" [(selectedIndex)]="tabIndex" *ngIf="!dataService.isDeepLink">
                <mat-tab label="contact" *ngIf="!isDeepLink">
                    <ng-template mat-tab-label><img class="call-icon" src="assets/call-icon.svg"> <span
                            class="border-bottom">Contact <span class="details-mobile">Details</span></span>
                    </ng-template>
                    <mat-card class="dnd-options-page contact-details">
                        <div class="dnd-options contact-details-options">
                            <div class="dnd-options-header flex">
                                Communication Details
                               <!--  <span class="fill-remaining-space"></span> -->
                                <img src="assets/edit_btn.svg" class="communicate-edit-imgs" [appDecorate] searchId="13"
                                    (click)="editCommunicationDetails();trackUserActions({ 'eventName' : 'Edit communication details' })" *ngIf="!(isCPAdminPortal)">
                            </div>
                            <div class="content flex">
                                <div 
                                class="communication-field communication-field-border flex communicate-align communicate-left">
                                    <img class="no-mobile" src="assets/email.svg">
                                    <div class="com-field-div">
                                        <div class="email-id"> Email ID </div>
                                        <div class="email-id-span-two">{{ContactDetail.CONTACT_EMAIL_ADDRESS}}</div>
                                        <div class="alternate-email-id-span"><span
                                                class="alternate-special-span">Alternate Email ID: 
                                            </span>{{ContactDetail.CONTACT_X_ALT_EMAIL}}</div>
                                    </div>
                                </div>
                                <div class="communication-field flex communicate-align communicate-right">
                                    <img class="no-mobile" src="assets/mobile.svg">
                                    <div class="com-field-div">
                                        <div class="email-id"> Mobile Number </div>
                                        <div class="email-id-span-two">
                                            <span *ngIf="ContactDetail.CONTACT_COUNTRY_CD && ContactDetail.CONTACT_COUNTRY_CD != 'NA'">+{{ContactDetail.CONTACT_COUNTRY_CD}}&nbsp;</span>
                                            {{ContactDetail.CONTACT_CELL_PH_NUMBR}}</div>
                                        <div class="alternate-email-id-span">
                                            <span class="alternate-special-span">Alternate Number: </span>
                                            <span *ngIf="ContactDetail.CONTACT_ALT_COUNTRY_CD && ContactDetail.CONTACT_ALT_COUNTRY_CD != 'NA'">+{{ContactDetail.CONTACT_ALT_COUNTRY_CD}}&nbsp;</span>
                                            {{ContactDetail.CONTACT_ALT_PH_NUM}}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="communication-field communication-field-border flex communicate-align communicate-left communicate-bottom-fields">
                                    <img class="no-mobile" src="assets/call-blue.svg">
                                    <div class="com-field-div">
                                        <div class="email-id"> Home Telephone Number </div>
                                        <div class="email-id-span-two">
                                            <span *ngIf="ContactDetail.CONTACT_HOME_STD && ContactDetail.CONTACT_HOME_STD != 'NA'">{{ContactDetail.CONTACT_HOME_STD}}&nbsp;</span>
                                            {{ContactDetail.CONTACT_HOME_PH_NUMBR}}</div>
                                    </div>
                                </div>
                                <div
                                    class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                                    <img class="no-mobile" src="assets/call-blue.svg">
                                    <div class="com-field-div">
                                        <div class="email-id">Office Telephone Number </div>
                                        <div class="email-id-span-two">
                                            <span *ngIf="ContactDetail.CONTACT_WORK_STD && ContactDetail.CONTACT_WORK_STD != 'NA'">{{ContactDetail.CONTACT_WORK_STD}}&nbsp;</span>
                                            {{ContactDetail.CONTACT_WORK_PH_NUMBR}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dnd-options contact-details-options">
                            <div class="dnd-options-header">
                                Address
                            </div>
                            <div class="content">
                                <div class="flex bottom-communicate-div">
                                    <div
                                        class="communication-field communication-field-border flex communicate-align communicate-left">
                                        <img class="no-mobile" src="assets/location.svg">
                                        <div class="com-field-div">
                                            <div class="email-id flex"> Mailing Address <img class="mail-edit-icon" [appDecorate] [searchId]="['14','114']"
                                                    src="assets/edit_btn.svg" (click)="updateMailingAddress('Mailing');trackUserActions({ 'eventName' : 'Edit mailing address' })" *ngIf="!(isCPAdminPortal)"> </div>
                                            <div class="email-id-span-two span-two-margin">
                                                <p *ngIf="hideMailingAddress">NA</p>
                                                <p>
                                                    <span *ngIf="CommAddress.HOUSE_NO">{{CommAddress.HOUSE_NO}}, &nbsp;</span>
                                                    <span *ngIf="CommAddress.STREET_ADDRESS">{{CommAddress.STREET_ADDRESS}}, &nbsp;</span>
                                                    <span *ngIf="CommAddress.LANDMARK">{{CommAddress.LANDMARK}}, &nbsp;</span>
                                                    <span *ngIf="CommAddress.CITY">{{CommAddress.CITY}}, &nbsp;</span>
                                                </p>
                                                <p *ngIf="CommAddress.PINCODE">{{CommAddress.PINCODE}}</p>
                                                <p *ngIf="CommAddress.STATE">{{CommAddress.STATE}}</p>
                                                <p *ngIf="CommAddress.COUNTRY">{{CommAddress.COUNTRY}}</p>
                                            </div>
                                            <div class="mailing-address-span-three">All Communication will be sent to
                                                this address</div>
                                        </div>
                                    </div>
                                    <div class="communication-field flex communicate-align communicate-right-padding">
                                        <img class="no-mobile" src="assets/location.svg">
                                        <div class="com-field-div">
                                            <div class="email-id flex"> Permanent Address <img class="mail-edit-icon" [appDecorate] [searchId]="['14','115']"
                                                    src="assets/edit_btn.svg" (click)="updateMailingAddress('Permanent');trackUserActions({ 'eventName' : 'Edit permanent address' })" *ngIf="!(isCPAdminPortal)"> </div>
                                            <div class="email-id-span-two span-two-margin">
                                                <p *ngIf="hidePermanentAddress">NA</p>
                                                <p>
                                                    <span *ngIf="ClientAddress.HOUSENUMBER">{{ClientAddress.HOUSENUMBER}}, &nbsp;</span>
                                                    <span *ngIf="ClientAddress.STREET">{{ClientAddress.STREET}}, &nbsp;</span>
                                                    <span *ngIf="ClientAddress.LANDMARK">{{ClientAddress.LANDMARK}}, &nbsp;</span>
                                                    <span *ngIf="ClientAddress.CITY">{{ClientAddress.CITY}}, &nbsp;</span>
                                                </p>
                                                <p *ngIf="ClientAddress.PINCODE">{{ClientAddress.PINCODE}}</p>
                                                <p *ngIf="ClientAddress.STATE">{{ClientAddress.STATE}}</p>
                                                <p *ngIf="ClientAddress.COUNTRY">{{ClientAddress.COUNTRY}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="add-button-div" *ngIf="!(isCPAdminPortal)">
                                    <button matButton class="add-mailing-address-button"
                                        (click)="updateMailingAddress('Mailing');trackUserActions({ 'eventName' : 'Add mailing address' })">Add Mailing Address <img class="plus-image"
                                            src="assets/plus-red.svg" > </button>
                                </div> -->
                            </div>
                        </div>
                    </mat-card>
                </mat-tab>
                <mat-tab label="bank" *ngIf="!isDeepLink">
                    <ng-template mat-tab-label> <img src="assets/bank.svg" class="call-icon"><span
                            class="border-bottom">Bank <span class="details-mobile">Details</span></span></ng-template>
                    <mat-card class="dnd-options-page bank-details">
                        <div class="bank-details-height no-mobile">
                            <mat-tab-group animationDuration="0ms" [disableRipple]=true class="bank-details-tabs">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <span class=label-padding>
                                            NEFT Details
                                            <div class="left-border"></div>
                                        </span>
                                    </ng-template>
                                    <div class="neft-details-page">
                                        <div class="neft-details">
                                            <div class="neft-details-header">
                                                NEFT Details
                                                <img src="assets/pen-red.svg" class="account-edit" (click)="updateNeftDetails('NEFT')" [appDecorate] searchId="15">
                                                <div class="header-content">Registered bank details for all transactions
                                                </div>
                                            </div>
                                            <div class="account-div">
                                                <div class="account-number-div">
                                                    <div class="account-number-title flex">Account Number <span
                                                            class="fill-remaining-space"></span> </div>
                                                    <div class="account-number" id="account-number">{{NEFTDetail.ACC_NUM}}</div>
                                                </div>
                                                <div class="account-details-content">
                                                    <div class="account-details-left border-right">
                                                        <div class="details-div flex">
                                                            <img src="assets/bank-blue.svg" class="details-image">
                                                           <div class="account-details-text">
                                                                Bank Name
                                                                <div class="account-details-sub-text">
                                                                    {{NEFTDetail.BANKNAME}}</div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="details-div flex">
                                                            <img src="assets/person.svg">
                                                            <div class="account-details-text">
                                                                Account Holder Name
                                                                <div class="account-details-sub-text">
                                                                    {{NEFTDetail.ACCNAME}}
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        <div class="details-div flex">
                                                           <img src="assets/acc-type.svg">
                                                            <div class="account-details-text">
                                                                Account Type
                                                                <div class="account-details-sub-text">
                                                                   {{NEFTDetail.NEFTACCOUNTTYPE}} 
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="account-details-right">
                                                        <div class="details-div flex">
                                                            <img src="assets/ifsc.svg">
                                                            <div class="account-details-text">
                                                                IFSC Code
                                                                <div class="account-details-sub-text">
                                                                    {{NEFTDetail.IFSCCODE}}
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        <div class="details-div flex">
                                                            <img src="assets/branch name.svg">
                                                            <div class="account-details-text">
                                                                Branch Name
                                                                <div class="account-details-sub-text">
                                                                    {{NEFTDetail.BRANCHNAME}}
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                                <!-- <mat-tab>
                                    <ng-template mat-tab-label>
                                        <span class=label-padding (click)="getECSDetails()">
                                            ECS Details
                                            <div class="left-border"></div>
                                        </span>
                                    </ng-template>
                                    <div class="neft-details-page">
                                        <div class="neft-details">
                                            <div class="neft-details-header">
                                                ECS Details
                                                <div class="header-content">
                                                    Get the ECS details for the corresponding policy.
                                                </div>
                                            </div>
                                            <div  *ngIf="(ECSDetail.status === 'NACH Not Tagged')">NACH Not Tagged</div>
                                            <div  *ngIf="!(ECSDetail.status === 'NACH Not Tagged')">
                                                    <div class="account-div">
                                                        <div class="account-number-div">
                                                            <div class="account-number-title flex">
                                                                Account Number
                                                                <span class="fill-remaining-space"></span>
                                                                <img src="assets/pen-red.svg" class="account-edit"
                                                                    (click)="getredirectActivateSI()" *ngIf="!(isCPAdminPortal)">
                                                            </div>
                                                            <div class="account-number" *ngIf="ECSDetail.accno">
                                                                {{ECSDetail.accno}}
                                                            </div>
                                                            <div class="account-number" *ngIf="!(ECSDetail.accno)">
                                                               NA
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="account-details-content">
                                                        <div class="account-details-left border-right">
                                                            <div class="details-div flex">
                                                                <img src="assets/bank-blue.svg">
                                                                <div class="account-details-text">
                                                                    Bank Name
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.bankname">
                                                                        {{ECSDetail.bankname}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.bankname)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/person.svg">
                                                                <div class="account-details-text">
                                                                    Account Holder Name
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.accname">
                                                                        {{ECSDetail.accname}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.accname)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/acc-type.svg">
                                                                <div class="account-details-text">
                                                                    Account Type
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.acctype">
                                                                        {{ECSDetail.acctype}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.acctype)">
                                                                       NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/calender.svg">
                                                                <div class="account-details-text">
                                                                    From Date
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.fromDate">
                                                                        {{ECSDetail.fromDate}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.fromDate)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/ifsc.svg">
                                                                <div class="account-details-text">
                                                                    Amount (INR)
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.amount">
                                                                        {{ECSDetail.amount}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.amount)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="account-details-right">
                                                            <div class="details-div flex">
                                                                <img src="assets/ifsc.svg">
                                                                <div class="account-details-text">
                                                                    IFSC Code
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.ifsccode">
                                                                        {{ECSDetail.ifsccode}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.ifsccode)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/branch name.svg">
                                                                <div class="account-details-text">
                                                                    Branch Name
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.branchname">
                                                                        {{ECSDetail.branchname}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.branchname)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/ifsc.svg">
                                                                <div class="account-details-text">
                                                                    MICR Code
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.ifsccode">
                                                                        230010153
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.ifsccode)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                            <div class="details-div flex">
                                                                <img src="assets/ifsc.svg">
                                                                <div class="account-details-text">
                                                                    Incremental Premium for Nach
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.incpremiumamt">
                                                                       {{ECSDetail.incpremiumamt}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.incpremiumamt)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details-div flex">
                                                                <img src="assets/calender.svg">
                                                                <div class="account-details-text">
                                                                    Preferred Billing Date
                                                                    <div class="account-details-sub-text" *ngIf="ECSDetail.billingDate">
                                                                        {{ECSDetail.billingDate}}
                                                                    </div>
                                                                    <div class="account-details-sub-text" *ngIf="!(ECSDetail.billingDate)">
                                                                        NA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    </div></div>
                                </mat-tab> -->
                            </mat-tab-group>
                        </div>
                        <div class="mobile">
                            <mat-accordion class="bank-details-accordion">
                                <mat-expansion-panel hideToggle="true" [expanded]="true" (opened)="bankAccordionOpen= true"
                                    (closed)="bankAccordionOpen = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>NEFT Details</mat-panel-title>
                                        <span class="white-chevron"
                                            [ngClass]="{'white-chevron-open': bankAccordionOpen }"></span>
                                    </mat-expansion-panel-header>
                                    <div class="neft-details-header">
                                        NEFT Details
                                        <span class="fill-remaining-space"></span>
                                        <img src="assets/pen-red.svg" class="account-edit" (click)="updateNeftDetails('NEFT')" [appDecorate] searchId="15">
                                        <div class="header-content">Registered bank details for all transactions</div>
                                    </div>
                                    <div class="account-number-div">
                                        <div class="account-number-title">Account Number <span
                                                class="fill-remaining-space"></span> </div>
                                        <div class="account-number" id="account-number">{{NEFTDetail.ACC_NUM}}</div>
                                    </div>
                                    <div class="account-details-content-mobile">
                                        <div class="details-div flex">
                                            <img src="assets/bank-blue.svg" class="details-image">
                                            <div class="account-details-text">
                                                Bank Name
                                                <div class="account-details-sub-text">
                                                    {{NEFTDetail.BANKNAME}}</div>                                                
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/person.svg">
                                            <div class="account-details-text">
                                                Account Holder Name
                                                <div class="account-details-sub-text">
                                                    {{NEFTDetail.ACCNAME}}
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/acc-type.svg">
                                            <div class="account-details-text">
                                                Account Type
                                                <div class="account-details-sub-text">
                                                    {{NEFTDetail.NEFTACCOUNTTYPE}}
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/ifsc.svg">
                                            <div class="account-details-text">
                                                IFSC Code
                                                <div class="account-details-sub-text">
                                                    {{NEFTDetail.IFSCCODE}}
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/branch name.svg">
                                            <div class="account-details-text">
                                                Branch Name
                                                <div class="account-details-sub-text">
                                                    {{NEFTDetail.BRANCHNAME}}
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <!-- <mat-expansion-panel hideToggle="true" (opened)="bankAccordionOpen= true"
                                    (closed)="bankAccordionOpen = false">
                                    <mat-expansion-panel-header (click)="getECSDetails()">
                                        <mat-panel-title>ECS Details</mat-panel-title>
                                        <img src="assets/angle_down_blue.svg" *ngIf="!bankAccordionOpen">
                                        <img class="white-chevron"
                                            [ngClass]="{'white-chevron-open': bankAccordionOpen }"
                                            src="assets/arrow_down_white_large.svg" *ngIf="bankAccordionOpen">
                                    </mat-expansion-panel-header>
                                    <div class="neft-details-header">
                                        ECS Details
                                        <div class="header-content">Get the ECS details for the corresponding policy.
                                        </div>
                                    </div>
                                    <div class="neft-details-header" *ngIf="(ECSDetail.status === 'NACH Not Tagged')">NACH Not Tagged</div>
                                    <div  *ngIf="!(ECSDetail.status === 'NACH Not Tagged')">
                                    <div class="account-number-div">
                                        <div class="account-number-title">Account Number <span
                                                class="fill-remaining-space"></span> <img src="assets/pen-red.svg"
                                                class="account-edit" (click)="updateNeftDetails('ECS')" *ngIf="!(isCPAdminPortal)"></div>
                                                <div class="account-number" *ngIf="ECSDetail.accno">
                                                    {{ECSDetail.accno}}
                                                </div>
                                                <div class="account-number" *ngIf="!(ECSDetail.accno)">
                                                    NA
                                                </div>
                                    </div>
                                    <div class="account-details-content-mobile">
                                        <div class="details-div flex">
                                            <img src="assets/bank-blue.svg" class="details-image">
                                            <div class="account-details-text">
                                                Bank Name
                                                <div class="account-details-sub-text" *ngIf="ECSDetail.bankname">
                                                    {{ECSDetail.bankname}}
                                                </div>
                                                <div class="account-details-sub-text" *ngIf="!(ECSDetail.bankname)">
                                                    NA
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/person.svg">
                                            <div class="account-details-text">
                                                Account Holder Name
                                                <div class="account-details-sub-text" *ngIf="ECSDetail.accname">
                                                    {{ECSDetail.accname}}
                                                </div>
                                                <div class="account-details-sub-text" *ngIf="!(ECSDetail.accname)">
                                                    NA
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/acc-type.svg">
                                            <div class="account-details-text">
                                                Account Type
                                                <div class="account-details-sub-text" *ngIf="ECSDetail.acctype">
                                                    {{ECSDetail.acctype}}
                                                </div>
                                                <div class="account-details-sub-text" *ngIf="!(ECSDetail.acctype)">
                                                    NA
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/ifsc.svg">
                                            <div class="account-details-text">
                                                IFSC Code
                                                <div class="account-details-sub-text" *ngIf="ECSDetail.ifsccode">
                                                    {{ECSDetail.ifsccode}}
                                                </div>
                                                <div class="account-details-sub-text" *ngIf="!(ECSDetail.ifsccode)">
                                                    NA
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-div flex">
                                            <img src="assets/branch name.svg">
                                            <div class="account-details-text">
                                                Branch Name
                                                <div class="account-details-sub-text" *ngIf="ECSDetail.branchname">
                                                    {{ECSDetail.branchname}}
                                                </div>
                                                <div class="account-details-sub-text" *ngIf="!(ECSDetail.branchname)">
                                                    NA
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></mat-expansion-panel> -->
                            </mat-accordion>
                        </div>
                    </mat-card>
                </mat-tab>
                <mat-tab label="personal" *ngIf="!isDeepLink">
                    <ng-template mat-tab-label> <img src="assets/personal-icon.svg" class="call-icon"> <span
                            class="border-bottom">Personal <span class="details-mobile">Details</span></span>
                    </ng-template>
                    <mat-card class="dnd-options-page personal-details">
                        <div class="dnd-options">
                            <div class="dnd-options-header">
                                Your Information
                            </div>
                            <div class="personal-details-section">
                                <div class="client-id">
                                    <div class="client-id-section">Client ID</div>
                                    <div class="client-id-number">{{client_id}}</div>
                                </div>
                                <div class="personal-details-content flex">
                                    <div class="left-content">
                                        <div class="name-div flex">
                                            <img src="assets/person.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text"> Name <img class="edit-button" src="assets/edit_btn.svg" [appDecorate] searchId="29"
                                                    (click)="updateMailingAddressPersonal();trackUserActions({ 'eventName' : 'Edit Name' })" *ngIf="!(isCPAdminPortal)"></div>
                                                <span class="flex name-span">
                                                    <span *ngIf="personal_detail.FST_NAME == 'NA' && personal_detail.LAST_NAME == 'NA'">NA</span>
                                                    <span *ngIf="personal_detail.FST_NAME != 'NA'">{{personal_detail.FST_NAME}}&nbsp;</span>
                                                    <span *ngIf="personal_detail.LAST_NAME != 'NA'">{{personal_detail.LAST_NAME}}</span> 
                                                </span>
                                            </div>
                                        </div>
                                        <div class="name-div flex">
                                            <img src="assets/gender.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">Gender </div>
                                                <div class="name-span">{{personal_detail.GENDER}}</div>
                                            </div>
                                        </div>
                                        <div class="name-div flex">
                                            <img src="assets/save.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">KYC Details <img
                                                    class="edit-button" src="assets/edit_btn.svg" [appDecorate] searchId="16"
                                                    (click)="updateKycDetails();trackUserActions({ 'eventName' : 'Edit KYC' })" *ngIf="!(isCPAdminPortal)"></div>
                                                <div class="name-span">Status : {{isKycMessage}}</div>
                                                <div class="name-span">{{kycExpiry}}</div>
                                                <div class="name-span ckycNumber" *ngIf="ckycNumber">{{ckycNumber}}</div>
                                            </div>
                                        </div>
                                        <div class="name-div flex" *ngIf="personal_detail.EIANo && personal_detail.EIANo != 'NA'">
                                            <img src="assets/save.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">E-Insurance Account number <div>(EIA number) <img src="assets/info_active.svg" class="tooltip-popover-eia info-img edit-button"></div></div>
                                                <div class="name-span">{{personal_detail.EIANo}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right-content">
                                        <div class="name-div flex">
                                            <img src="assets/calender.svg" class=" person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">Date of Birth</div>
                                                <div class="name-span">{{personal_detail.DOB}}</div>
                                            </div>
                                        </div>
                                        <div class="name-div flex">
                                            <img src="assets/martial.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">Marital Status</div>
                                                <div class="name-span" >{{personal_detail.MARITAL_STS}}</div>
                                            </div>
                                        </div>
                                        <div class="name-div flex">
                                            <img src="assets/save.svg" class="person-image no-mobile">
                                            <div class="name-text-div">
                                                <div class="name-text">PAN <img
                                                    class="edit-button" src="assets/edit_btn.svg" [appDecorate] searchId="17"
                                                    (click)="updatePanDetails();trackUserActions({ 'eventName' : 'Edit PAN' })" *ngIf="!(isCPAdminPortal) && !isPanVerified">
                                                    <img class="verified" src="assets/green-tick.svg" *ngIf="isPanVerified">
                                                </div>
                                                <div class="name-span" id="pan">{{personal_detail.PAN_NUMBER}}</div>
                                                <!-- <div class="name-span">PAN Aadhaar Linked: {{panAadharLink}}</div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="personal-line" *ngIf="isKycMessage"></div>
                                <div class="upload-kyc-div flex-center" *ngIf="isKycMessage">
                                    <img src="assets/exclamation.svg" class="exclamation-image">
                                    {{isKycMessage}}
                                    <button class="upload-kyc-button" (click)="updateKycDetails()">Upload</button>
                                </div> -->
                            </div>
                        </div>
                    </mat-card>
                </mat-tab>
                <span class="fill-remaining-space"></span>
                <mat-tab label="dnd" *ngIf="!isDeepLink">
                    <ng-template mat-tab-label>
                        <span class="filter-fade-icon" [ngClass]="{'filter-active-icon': dndTabActive }"></span>
                    </ng-template>
                    <mat-card class="dnd-options-page">
                        <div class="dnd-options">
                            <div class="dnd-options-header">
                                DND Options
                            </div>
                            <div class="dnd-options-section flex">
                                <div class="left-side">
                                    <div class="image-div flex">
                                        <img src="assets/call-me.svg" class="call-image">
                                        <div class="call-me">
                                            <div class="span-one">Call Me</div>
                                            <div class="span-div">
                                                <div class="span-two">For updates/promotions</div>
                                                <div class="flex">
                                                <mat-slide-toggle [disabled]="isCPAdminPortal" class="dnd-slide-toggle" [(ngModel)]="isCallMeChecked"
                                                    (change)="onCallMeChange($event)">
                                                </mat-slide-toggle>
                                                <div class="dnd-slide-toggle dnd-slide-toggle-text">{{isCallMeChecked ? 'ON' : 'OFF'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mobile hidden-divider"></div>
                                <div class="left-side">
                                    <div class="image-div flex">
                                        <img src="assets/whatsapp.svg" class="call-image">
                                        <div class="call-me">
                                            <div class="span-one">Whatsapp Me</div>
                                            <div class="span-div">
                                                <div class="span-two">For updates/promotions</div>
                                                <div class="flex">
                                                <mat-slide-toggle [disabled]="isCPAdminPortal" class="dnd-slide-toggle" [(ngModel)]="isWhatsappChecked"
                                                    (change)="onWhatsappChange($event)">
                                                </mat-slide-toggle>
                                                <div class="dnd-slide-toggle dnd-slide-toggle-text">{{isWhatsappChecked ? 'ON' : 'OFF'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="inner-card">
                                <div class="inner-card-content-one">
                                    <ul>
                                    <li>To ensure that you do not receive promotional calls/SMS from HDFC Life, please register for Do Not Disturb here</li>
                                    <li>Your registration will apply only on the phone number provided below and not on any other number that you may have registered with us</li>
                                    <li>Further, despite DND registration here, you will continue to receive service/transactional communication related to your existing insurance
                                        policy, e.g. fund performance or premium reminders calls/email/SMS, etc.</li>
                                    <li>This DND registration is not applicable for products and services of other HDFC group companies or other institutions</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </mat-tab>
            </mat-tab-group>
            <ng-container *ngIf="!dataService.isDeepLink">
                <app-recommended-plans></app-recommended-plans>
            </ng-container>
        </div>
        <span class="fill-remaining-space"></span>
        <div *ngIf="!dataService.isDeepLink" class="my-account-right">
            <div class="my-account-top-spacing"></div>
            <div class="sidebanner-spacing"></div>
            <app-sidebanner></app-sidebanner>
        </div>
    </div>
</div>
<ng-template #dndDialog>
    <div class="pan-card-details">
        <div *ngIf="!isDeepLink" mat-dialog-actions>
             <div mat-dialog-close class="close-dialog no-mobile" (click)="dndCancel()" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                 <img src="assets/close.svg">
             </div>
         </div>
         <div class="pan-card-details-header">
             Confirmation
         </div>
         <div class="pan-card popup-content-height">
             <div class="pan-card-content">You might miss out some important updates.</div>
             <div class="pan-card-content">Are you sure you want to stop receiving WhatsApp communications?</div>
             <div class="button-div flex">
                 <button matButton class="close-button" (click)="updateWhatsappDND()">Turn Off</button>
                 <button matButton class="save-button whatsapp-btn" (click)="dndCancel()">Cancel</button>
             </div>
         </div>
    </div>
</ng-template>

<ng-template #panDialog>
    <div class="pan-card-details">
        <div *ngIf="!isDeepLink" mat-dialog-actions (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="pan-card-details-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">
            Are you sure?
        </div>
        <div class="pan-card popup-content-height">
            <div class="pan-card-content">Your PAN details are valid and verified.
            </div>
            <div class="pan-card-confirmation">
                Do you wish to proceed?
            </div>
            <div class="button-div flex">
                <button matButton
                    class="close-button update-pan-btn update-pan-mobbtn" type="button" (click)="closeDialog();trackUserActions({'eventName': 'Edit PAN - cancel'})">Cancel</button>
                <button matButton class="save-button update-pan-btn update-pan-mobbtn"
                    (click)="editPanDetails(editPan);trackUserActions({'eventName': 'Edit PAN - yes'})" *ngIf="!(isCPAdminPortal)">Yes</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #editPan>
    <div class="pan-card-details">
        <div *ngIf="!isDeepLink" mat-dialog-actions (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="pan-card-details-header flex"
        [ngClass]="{'hni-popups-header':isHNIUSer}">Edit PAN</div>
        <div class="pan-card popup-content-height">
            <div class="mandatory-field"><span class="red-star">*</span>Mandatory Field
            </div>
            <form [formGroup]="editPanForm" (ngSubmit)="submitEditPanForm()" autocomplete="off">
            <div class="form-section flex">
                <div class="fields">
                    <div class="pan-card-input">
                        <div class="form-label">PAN <sup class="red-star">*</sup></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>Please enter your PAN Number</mat-placeholder>
                            <input formControlName="panNo" autocomplete="off" class ="panText" type="text" matInput maxlength="10" id="pan">
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panNo.errors?.errorMsg">{{editPanForm.controls.panNo.errors?.errorMsg}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pan-card-input" *ngIf="!personal_detail.DOB || personal_detail.DOB == 'NA'">
                        <div class="form-label">DOB as per PAN <sup class="red-star">*</sup><img src="assets/info_active.svg" class="tooltip-popover-pan-dob info-img"></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                            <input formControlName="panDOB" type="text" autocomplete="off" matInput [max]="today" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panDOB.errors?.errorMsg">{{editPanForm.controls.panDOB.errors?.errorMsg}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pan-card-input">
                        <div class="form-label">Name as per PAN card <sup class="red-star">*</sup></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>Please enter name as per PAN</mat-placeholder>
                            <input formControlName="panName" autocomplete="off" type="text" matInput maxlength="85">
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panName.errors?.errorMsg">{{editPanForm.controls.panName.errors?.errorMsg}}</mat-error>
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panName.errors?.required">Name is required</mat-error>
                        </mat-form-field>
                    </div>                        
                </div>
                <div class="pan-div">
                    <img src="/assets/pan.png" class="pan-img">
                </div>                    
            </div>
            <div *ngIf="editPanErrMsg" [innerHTML]="editPanErrMsg" class="pan-update-inner-html red-text"></div>
            <div *ngIf="editPanSuccMsg" [innerHTML]="editPanSuccMsg" class="pan-update-inner-html"></div>
            <div *ngIf="panAadharLinkErrMsg" [innerHTML]="panAadharLinkErrMsg" class="pan-update-inner-html"></div>
            <div *ngIf="panAadharLinkSuccMsg" [innerHTML]="panAadharLinkSuccMsg" class="pan-update-inner-html"></div>
            <div *ngIf="panAadharLinkCheckMsg" [innerHTML]="panAadharLinkCheckMsg" class="pan-update-inner-html"></div>
            <div class="button-div flex">
                <button mat-button class="close-button update-pan-btn" type="button" (click)="closeDialog();trackUserActions({'eventName': 'Edit PAN - cancel'})">Cancel</button>
                <button *ngIf="!isPanValid" mat-button class="save-button" type="submit" [disabled]="!editPanForm.valid">Verify PAN</button>
                <!-- <button *ngIf="isPanValid" mat-button class="save-button" type="button" (click)="createUpdatePanSR();trackUserActions({'eventName': 'Edit PAN - continue'})">Continue</button> -->
            </div>
        </form>
        </div>
    </div>
</ng-template>

<ng-template #srSubmitpopup>
    <div class="pan-card-details">
        <div *ngIf="!isDeepLink" mat-dialog-actions (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="pan-card-details-header flex"
        [ngClass]="{'hni-popups-header':isHNIUSer}">Message</div>
        <div class="pan-card popup-content-height">
                <div class="pan-card-content flex">
                        <div class="green-tick-div flex">
                                <img src="assets/green-tick.svg">
                        </div>Service Request No. {{srNo}} has been created successfully. To track it, please visit the service request section of my account portal</div>
                <div class="pan-card-confirmation flex">
                        <div class="info-active flex">
                                <img src="assets/info_active.svg">
                        </div>Would you also like to </div>
            <div class="button-div-update flex">
                <button *ngIf="!KycBtn"  class="update-btn"  (click)="updateKycDetails();trackUserActions({'eventName': 'Edit KYC'})">Update KYC</button>
                <button *ngIf="!NeftBtn"  class="update-btn"  (click)="updateNeftDetails('NEFT');trackUserActions({'eventName': 'Edit NEFT'})">Update NEFT</button>
            </div>
        </div>
    </div>
</ng-template>
<!-- <ng-template #neftDialog>
    <div class="pan-card-details">
        <div mat-dialog-actions (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="pan-card-details-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">
            Edit Neft-Details
        </div>
        <div class="pan-card popup-content-height" *ngIf="!editNeftProceed">
            <div class="pan-card-confirmation">
                Are you sure you want to edit this NEFT details?
            </div>
            <div class="button-div flex">
                <button matButton class="save-button update-pan-btn update-pan-mobbtn"
                    (click)="editNeftDtls()">Yes</button>
                <button matButton
                    class="close-button update-pan-btn update-pan-mobbtn" type="button" (click)="closeDialog()">No</button>
            </div>
        </div>
        <div class="neft-acc-type" *ngIf="editNeftProceed">
        <div class="neft-form-field-div" *ngIf="editNeftProceed">
                <mat-form-field class="mailing-form-field">
                    <label class="form-field-label">Select Source of NEFT<span class="error-two">*</span></label>
                    <mat-select class="select-field" disableRipple disableOptionCentering (selectionChange)="valueChanged($event)"
                        floatLabel="never">
                        <mat-option value="Select">Select</mat-option>
                        <mat-option value="Add New">Add New</mat-option>
                        <mat-option value="Existing Account">Existing Account</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="noExistingNeft">No Existing Accounts Found</div>
            </div>
        <div class="neft-form-field-div" *ngIf="editNeftProceed && existingNeftDtlsFlag">
                    <mat-form-field class="mailing-form-field">
                        <label class="form-field-label">Select Account No.<span class="error-two">*</span></label>
                        <mat-select class="select-field" disableRipple disableOptionCentering (selectionChange)="valueChangedAccountNo($event)"
                            floatLabel="never">
                            <mat-option value="Select">Select</mat-option>
                            <mat-option  *ngFor="let item of neftACCNo" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
            </div>
        </div>
    </div>
</ng-template> -->