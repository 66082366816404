<div class="forgot-pwd-page">
    <div class="forgot-wrapper" [ngClass]="{'forgot-reset-wrapper': showResetPassword}">
        <mat-card class="forgot-pwd-card" *ngIf="!showResetPassword">
            <!-- <div class="popup-close">
                <div class="popup-close-dialog no-mobile">Close<img src='/assets/close.svg'></div>
            </div> -->
            <div class="card-heading">
                <span *ngIf="!isLoggedIn">Forgot Password</span>
                <span *ngIf="isLoggedIn">Change Password</span>
            </div>
          <!--   <div *ngIf="!showOtpSection && !showScrtQtns">
                <mat-tab-group class="forgot-pwd-tabs" (selectedTabChange)="onTabChange($event)" [disableRipple]="true"
                    animationDuration="0ms" *ngIf="!isLoggedIn">
                    <mat-tab label="Client ID">
                        <ng-template matTabContent>
                            <form [formGroup]="clientIDForm" (ngSubmit)="submitClientIDForm()"
                                *ngIf="!showOtpSection && !showScrtQtns">
                                <div class="label-wrapper flex-center">
                                    <div class="form-label">Client ID<sup>*</sup></div>
                                    <div class="forget-details" (click)="forgotPopupOpen(clientId)">Forgot client ID?
                                    </div>
                                </div>
                                <mat-form-field floatLabel="never" class="form-field"
                                    [ngClass]="{'error-field': (clientFocusOut && clientValidation.client.errors?.required) || (clientFocusOut && clientValidation.client.errors?.pattern)}">
                                    <mat-placeholder>Please enter your Client ID</mat-placeholder>
                                    <input formControlName="client" type="text" maxlength="8" (keypress)="clearError()"
                                        (click)="resetValidation()" matInput (focusout)="focusOut($event)">
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && clientValidation.client.errors?.required)">
                                        Enter Client ID
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && clientValidation.client.errors?.pattern)">
                                        Enter valid Client ID
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        (click)="this.validateUser('client')"
                                        [disabled]="!clientValidation.client.valid">Validate User</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div class="mob-email" *ngIf="validateID">
                                    Your registered Mobile Number is {{maskMobileNo}} and email id is {{maskEmailId}}
                                </div>
                                <div class="radio-div" *ngIf="validateID">
                                    Reset your Password using &nbsp;
                                    <div class="radio-btn-wrapper">
                                        <mat-radio-group formControlName="mode">
                                            <mat-radio-button value="1" class="radio-btn" *ngIf="!NotAvailable">OTP
                                            </mat-radio-button>
                                            <mat-radio-button value="2" class="radio-btn">Secret Question
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        [disabled]="!clientIDForm.valid">Proceed</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div [innerHTML]="statusMessage" class="status-message" *ngIf="!isLoggedIn"></div>
                            </form>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Email ID">
                        <ng-template matTabContent>
                            <form [formGroup]="emailIDForm" (ngSubmit)="submitEmailForm()"
                                *ngIf="!showOtpSection && !showScrtQtns">
                                <div class="label-wrapper flex-center">
                                    <div class="form-label">Email ID<sup>*</sup></div>
                                    <div class="forget-details" (click)="loginIssuesFaq()">Facing Login issues?</div>
                                </div>
                                <mat-form-field floatLabel="never" class="form-field"
                                    [ngClass]="{'error-field': (clientFocusOut && emailValidation.email.errors?.required) || (clientFocusOut && emailValidation.email.errors?.email)}">
                                    <mat-placeholder>Please enter your Email ID</mat-placeholder>
                                    <input formControlName="email" type="text" (keypress)="clearError()"
                                        (click)="resetValidation()" matInput (focusout)="focusOut($event)">
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && emailValidation.email.errors?.required)">
                                        Enter Email ID
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && emailValidation.email.errors?.email)">
                                        Enter valid Email ID
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        (click)="this.validateUser('email')"
                                        [disabled]="!emailValidation.email.valid">Validate User</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div class="mob-email" *ngIf="validateID">
                                    Your registered Mobile Number is {{maskMobileNo}} and email id is {{maskEmailId}}
                                </div>
                                <div class="radio-div" *ngIf="validateID">
                                    Reset your Password using &nbsp;
                                    <div class="radio-btn-wrapper">
                                        <mat-radio-group formControlName="mode">
                                            <mat-radio-button value="1" class="radio-btn">OTP</mat-radio-button>
                                            <mat-radio-button value="2" class="radio-btn">Secret Question
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        [disabled]="!emailIDForm.valid">Proceed</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div [innerHTML]="statusMessage" class="status-message" *ngIf="!isLoggedIn"></div>
                            </form>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Mobile No">
                        <ng-template matTabContent>
                            <form [formGroup]="mobileForm" (ngSubmit)="submitMobileForm()"
                                *ngIf="!showOtpSection && !showScrtQtns">
                                <div class="label-wrapper flex-center">
                                    <div class="form-label">Mobile No<sup>*</sup></div>
                                    <div class="forget-details" (click)="loginIssuesFaq()">Facing Login issues?</div>
                                </div>
                                <mat-form-field floatLabel="never" class="form-field"
                                    [ngClass]="{'error-field': (clientFocusOut && mobileValidation.mobile.errors?.required) || (clientFocusOut && mobileValidation.mobile.errors?.pattern)}">
                                    <mat-placeholder>Please enter your Mobile Number</mat-placeholder>
                                    <input formControlName="mobile" [decimalNumbersOnly] type="tel" maxlength="20"
                                        (click)="resetValidation()" matInput (focusout)="focusOut($event)">
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && mobileValidation.mobile.errors?.required)">
                                        Enter valid Mobile Number
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(clientFocusOut && mobileValidation.mobile.errors?.pattern)">
                                        Enter valid Mobile Number
                                    </mat-error>
                                </mat-form-field>
                                <div class="unit-sr" ><span class="unit-new-sr">NRI Customers: </span>  In case of an international number, please enter mobile number without the country code.
                                </div>
                                <div *ngIf="!validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        (click)="this.validateUser('mobile')"
                                        [disabled]="!mobileValidation.mobile.valid">Validate User</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div class="mob-email" *ngIf="validateID">
                                    Your registered Mobile Number is {{maskMobileNo}} and email id is {{maskEmailId}}
                                </div>
                                <div class="radio-div" *ngIf="validateID">
                                    Reset your Password using &nbsp;
                                    <div class="radio-btn-wrapper">
                                        <mat-radio-group formControlName="mode">
                                            <mat-radio-button value="1" class="radio-btn">OTP</mat-radio-button>
                                            <mat-radio-button value="2" class="radio-btn">Secret Question
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="validateID">
                                    <button type="submit" class="forgot-pwd-btn" mat-flat-button
                                        [disabled]="!mobileForm.valid">Proceed</button>
                                    <button mat-flat-button class="forgot-pwd-btn"
                                        (click)="redirectToLogin()">Cancel</button>
                                </div>
                                <div [innerHTML]="statusMessage" class="status-message" *ngIf="!isLoggedIn"></div>
                            </form>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
                <form [formGroup]="passwordOptionsForm" *ngIf="isLoggedIn" (ngSubmit)="submitPasswordOption()">
                    <mat-radio-group formControlName="passwordRadio" class="pwd-opt-radio">
                      <mat-radio-button value="secretQuestion" *ngIf="!isLoggedIn">Secret Question</mat-radio-button>
                        <span class="mob-email" *ngIf="isLoggedIn">Change your Password using</span>
                        <div class="mob-email flex" *ngIf="isLoggedIn">
                            <mat-radio-button value="currentPassword" *ngIf="isLoggedIn">Current Password
                            </mat-radio-button>
                            <mat-radio-button value="otp" *ngIf="!NotAvailable" (click)="clearError()">OTP</mat-radio-button>
                        </div>
                    </mat-radio-group>
                    <div class="submit-form">
                        <button type="submit" class="password-opt-btn" mat-flat-button
                            [disabled]="!passwordOptionsForm.valid">Submit</button>
                        <button mat-flat-button class="forgot-pwd-btn" (click)="redirectToPolicy()">Cancel</button>
                    </div>
                </form>
            </div> -->
        <!--     <div *ngIf="showOtpSection">
                <div class="otp-sent-msg">
                    <span *ngIf="!isLoggedIn && maskMobileNo != 'not available' && maskEmailId != 'not available'">OTP sent to your Registered Mobile Number {{maskMobileNo}} and Email ID
                        {{maskEmailId}}</span>
                    <span *ngIf="!isLoggedIn && maskMobileNo == 'not available' && maskEmailId != 'not available'">OTP sent to your Registered Email ID
                        {{maskEmailId}}</span>
                    <span *ngIf="!isLoggedIn && maskMobileNo != 'not available' && maskEmailId == 'not available'">OTP sent to your Registered Mobile Number {{maskMobileNo}}</span>                   
                    
                    <span *ngIf="isLoggedIn && mobileNo && emailID">OTP sent to your Registered Mobile Number {{mobileNo}} and Email ID
                        {{emailID}}</span>
                    <span *ngIf="isLoggedIn && !mobileNo && emailID">OTP sent to your Registered Email ID {{emailID}}</span>
                    <span *ngIf="isLoggedIn && mobileNo && !emailID">OTP sent to your Registered Mobile Number {{mobileNo}}</span>
                </div>
                <div class="otp-timer-div">
                    Your OTP will be valid for <span
                        class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                    <a (click)="RegenPassOTP()"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                </div>
                <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
                    <div class="form-label otp-label">OTP</div>
                    <mat-form-field floatLabel="never" class="form-field"
                        [ngClass]="{'error-field': (otpFocus && otpValidation.otp.errors?.required) || (otpFocus && otpValidation.otp.errors?.pattern)}">
                        <mat-placeholder>Enter the OTP received</mat-placeholder>
                        <input formControlName="otp" [decimalNumbersOnly] type="tel" (keypress)="clearError()" matInput
                            (focusout)="otpFocusOut($event)">
                        <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.required)">
                            OTP is required.
                        </mat-error>
                        <mat-error class="error-message" *ngIf="(otpFocus && otpValidation.otp.errors?.pattern)">
                            Must be 6 digits.
                        </mat-error>
                    </mat-form-field>
                    <button type="submit" class="forgot-pwd-btn" (click)="validateOtp()">Verify</button>
                    <button mat-flat-button class="forgot-pwd-btn" (click)="redirectToPolicy()">Cancel</button>
                    <div [innerHTML]="statusMessage" class="status-message"></div>
                </form>
            </div> -->

            <div class="secret-fields" *ngIf="showScrtQtns">
                <form [formGroup]="qtnForm" (ngSubmit)="submitQtnForm()">
                    <div class="form-label">{{getFirstQuestion}}</div>
                    <mat-form-field floatLabel="never" class="form-field first-qtn"
                        [ngClass]="{'password-field': isFirstMasked,'error-field': (firstQtnFocus && qtnValidation.first.errors?.required) || (firstQtnFocus && qtnValidation.first.errors?.maxlength)}">
                        <mat-placeholder>Enter your Answer here</mat-placeholder>
                        <input formControlName="first" type="password" autocomplete="new-password" (keypress)="clearError()" #first matInput
                            (focusout)="firstQtnFocusOut($event)">
                        <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #img
                            (click)="toggleFirstInputType(img, first)">
                        <mat-error class="error-message"
                            *ngIf="(firstQtnFocus && qtnValidation.first.errors?.required)">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                    <div class="form-label">{{getSecondQuestion}}</div>
                    <mat-form-field floatLabel="never" class="form-field"
                        [ngClass]="{'password-field': isSecondMasked,'error-field': (secondQtnFocus && qtnValidation.second.errors?.required) || (secondQtnFocus && qtnValidation.second.errors?.maxlength)}">
                        <mat-placeholder>Enter your Answer here</mat-placeholder>
                        <input formControlName="second" type="password" autocomplete="new-password" (keypress)="clearError()" #second matInput
                            (focusout)="secondQtnFocusOut($event)">
                        <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #image
                            (click)="toggleSecInputType(image, second)">
                        <mat-error class="error-message"
                            *ngIf="(secondQtnFocus && qtnValidation.second.errors?.required)">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                    <div>
                        <button type="submit" class="forgot-pwd-btn" (click)="validatescreatquestion()">Verify</button>
                        <button mat-flat-button class="forgot-pwd-btn" (click)="redirectToLogin()">Cancel</button>
                    </div>
                    <!-- <div [innerHTML]="statusMessage" class="status-message" *ngIf="!isLoggedIn"></div> -->
                </form>
            </div>
            <div [innerHTML]="statusMessage" class="status-message" *ngIf="!isLoggedIn"></div>
            <div [innerHTML]="statusMessage" *ngIf="isLoggedIn && !showOtpSection" class="status-message"></div>
        </mat-card>
        <div *ngIf="showResetPassword">
            <app-reset-password [currentPasswordFlag]="showCurrentPassword"></app-reset-password>
        </div>
    </div>
</div>