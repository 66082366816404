import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { Subscription, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isHNIUSer:boolean = false;
  isVirtualBranch: boolean = false;
  @ViewChild('banner') banner : ElementRef;
  @ViewChild('imgInfo') imgInfo : ElementRef;
  displayIcon: boolean = true;
  displayBanner: boolean;
  intervalId;
  time : any;
  getDay: number;
  getHours: number;
  timerSubscription: Subscription;
  minutes: number;
  currentTime: any;
  todayDate: string;
  currentDate: any;
  action: string;
  showChatBot: boolean = false;

  constructor(public router: Router, public dataService: DataService, public route: ActivatedRoute) {
    this.dataService.isHNIUser.subscribe(isHni => {
      this.isHNIUSer = isHni;
    });
    this.dataService.isVirtualBranch$.subscribe(isVirtualBranch => {
      this.isVirtualBranch = isVirtualBranch;
    });
    this.dataService.loginResp$.subscribe((isChanged) => {
      if(isChanged) {
        let dtls = this.dataService.getLoginResp();
        this.currentTime = dtls.Time;
        var dateTime = this.currentTime.replace("IST"," ");
        var currDate = moment(dateTime , 'ddd MMM DD hh:mm:ss YYYY');
        this.currentDate =  currDate;
        this.time = new Date(this.currentDate);
        this.timerSubscription = timer(0,1000).pipe(
          map(()=> {
            this.time.setSeconds(this.time.getSeconds()+1);
            return this.time
          }),
          share()).subscribe(time=>{
            this.getDay = time.getDay();
            this.getHours = time.getHours();
            //this.minutes = time.getMinutes();
            if( this.getDay != 0 && (this.getHours >= 10 && this.getHours < 19)) {
              this.displayBanner = false;
            }
            else {
              this.displayBanner = false;
            }
          });
      }
    })
   }
   showBanner= false;

  ngOnInit(): void {
    setTimeout(()=> {
      this.action = this.route.snapshot.queryParamMap.get('action');
      this.showChatbot();
    },1000)   
  }
  showChatbot(){
    if(this.action == "cp_SurrenderPage"){
      this.showChatBot = true;
    } else{
      this.showChatBot = false;
    }
  }
  openBanner() {
    this.showBanner = !this.showBanner;
  }
  closeBanner() {
    this.timerSubscription.unsubscribe();
    this.displayBanner = false; // on close, the banner disappears and opens only on refresh
    //this.showBanner = false; // on close, the banner appears again.
  }
  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }

}
