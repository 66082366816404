import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { serviceURL } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-cheque-pickup',
  templateUrl: './cheque-pickup.component.html',
  styleUrls: ['./cheque-pickup.component.css']
})
export class ChequePickupComponent implements OnInit {
  chequeForm: FormGroup;
  filteredPincode: Observable<string[]>;
  pincodes: any = [];
  timeList: any = ["11:00AM - 01:00PM", "01:00PM - 03:00PM", "03:00PM - 06:00PM"]
  startDate: Date;
  endDate: Date;
  pincodeResp: any[];
  isPinValid: boolean = true;
  userDetails: any;
  clientDetails: any;
  appointmentData: any = {}
  holidayList: any;
  idleTimer: any;
  startTimer: boolean= true;
  pincode: unknown[];
  masterVersion:any = 10;
  cityReadOnly: boolean;

  constructor(public formBuilder: FormBuilder, public customLinkService: CustomLinkService,
    public dialog: MatDialog, public dataService: DataService, public router: Router,
    public httpService: HttpService, public message: MessageService,private bnIdle: BnNgIdleService) { }

  ngOnInit(): void {
    this.chequeForm = this.formBuilder.group({
      //clientName: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      pickupdate: ['', [Validators.required]],
      pickuptime: ['', [Validators.required]],
      houseNo: ['',  [Validators.required]],
      address1: ['', [Validators.required]],
      address2: ['', [Validators.required]],
      city: ['', [Validators.required]],
      landMark: ['', [Validators.required]]
    });
    // let policyNo = userDetails.Policyno
    if (this.dataService.getLoginResp()) {
      this.userDetails = this.dataService.getLoginResp();
      this.getClientDtls();
    }
    else {
      this.dataService.loginResp$.subscribe((respChanged) => {
        if (respChanged) {
          this.userDetails = this.dataService.getLoginResp();
          this.getClientDtls();
        }
      });
    }
    this.getDate(new Date());
    this.startIdleTimer()
    // setTimeout(() => {
    //  // console.log( this.userDetails.Policyno);
    //   if(this.userDetails == undefined){
    //     this.message.openSnackBar('Invalid User', 'xy', ['error-snackbar'])
    //     //this.router.navigate(['/my-link?action=cp_unsecuredChequepickUpPagen']);
    //   }      
    // }, 20);
  }
  //   private _pincodeFilter(value: string, data: any[]): any[]
  //   {
  //    const filterValue: string = value;
  //    return data.filter((item) => item.toLowerCase().includes(filterValue));
  //  }

  holidays = (d: Date): boolean => {
    d = new Date(d);
    const time = d.getTime()
    const day = d.getDay()
    if (day == 0) {
      return day !== 0
    } else {
      return !this.holidayList.find((x) => x.getTime() == time)
    }
  }


  getClientDtls() {
    const policyNo = { "policyno": this.userDetails.Policyno }

    this.customLinkService.ClientDtls(policyNo).subscribe((resp) => {
      this.clientDetails = resp.body['clientdetails']
      this.holidayList = []
      this.pincodes = []
      this.pincodeResp = []
      // for (let item of resp.body['pincodelist']) {
      //   this.pincodes.push(item["PINCODE"])
      //   this.pincodeResp.push(item["PINCODE"])
      // }
      for (let data of resp.body['holiday']) { 
        this.holidayList.push(new Date(moment( data.HOLIDAYS).format('YYYY/MM/DD')))
      }
      //     this.filteredPincode = this.chequeForm.get('pincode').valueChanges.pipe(startWith(''),
      // map((pincodes)=> this._pincodeFilter(pincodes, this.pincodes)
      // ))
    })
  }
  getDate(sDate) {
    let year = sDate.getFullYear();
    let month = sDate.getMonth();
    let date = sDate.getDate()
    this.startDate = new Date(year, month, date + 2);
    this.endDate = new Date(year, month + 6, date);
    console.log(this.startDate, this.endDate);
  }
  savedata() {
    if (this.chequeForm.invalid) {
      return
    }
    this.appointmentData = {
      "policyno": this.clientDetails.POLICY_NUM,
      "pincode": this.chequeForm.value.pincode,
      "address": (this.chequeForm.value.houseNo + ' ' + this.chequeForm.value.address1 + ' ' + this.chequeForm.value.address2 + ' ' + this.chequeForm.value.city + ' ' + this.chequeForm.value.landMark),
      "date": moment(this.chequeForm.value.pickupdate).format('DD/MM/YYYY'),
      "clientid": this.clientDetails.CLIENTID,
      "mobile": this.clientDetails.MOBILENO,
      "time": this.chequeForm.value.pickuptime,
      "source": this.userDetails.USER_SOURCE? this.userDetails.USER_SOURCE:'HDFC',
    }
    this.customLinkService.bookappointment(this.appointmentData).subscribe((resp) => {
      if (resp.body.jsondata.flag == 'Y') {
        this.appointmentData.title = this.clientDetails.CICLAIMINTSALUTION
        this.appointmentData.firstname = this.clientDetails.CIFIRSTNAME
        this.appointmentData.lastname = this.clientDetails.CILASTNAME

        this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Success",
            mainMessageHtml: "Appointment has been confirmed.",
            appointmentData: this.appointmentData,
            submitBtnLabel: "OK",
            submitAction: 'ok'
          }
        });
      }
      else if(resp.body.jsondata.flag == 'N'){
       const dialogReference = this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Alert Message",
            mainMessageHtml: "Pincode is not valid.",
            subMessage:"Sorry, but the pincode entered by you is currently Unserviceable or is Invalid. Please pay online, visit nearest branch or schedule pickup from a different location",
            branchLocator: "Branch Locator",
            reEnter: 'Re-enter PIN CODE',
            onlinePayment:'Online Payment',
           // branchLocatorURL : serviceURL.branchLocator
          }
        });
        dialogReference.afterClosed().subscribe(result =>{
         if(result){
            this.clearFields();
          }
        })
      }
      else if(resp.body.jsondata.flag == 'D'){
        this.message.openSnackBar('Invalid Date', 'xy', ['error-snackbar'])
      }
    })
  }
  clearFields() {
    this.chequeForm.reset();
  }
  startIdleTimer() {
    if (this.startTimer) {
      this.idleTimer = this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
         this.customLinkLogout()
          this.bnIdle.stopTimer();
        }
    })
    }
}
customLinkLogout() {
  this.userDetails = this.dataService.getLoginResp();
  localStorage.clear();
  this.dataService.deletePolicyCardResp();
  if (this.userDetails && this.userDetails.action) {
    this.router.navigate(['/my-link'], {queryParams: {action: this.userDetails.action}, queryParamsHandling: "preserve"} );
  } else {
    this.router.navigate(["/login"]);
  }
  this.dataService.setLoginResp(null);
}
ngOnDestroy() {
  this.idleTimer.unsubscribe();
}
getPincodeMasterData() {
    const masterDataType = "mstr_com_chequepickup_pincode";
    const filterBy = this.chequeForm.value.pincode;
    this.customLinkService.getPincodeMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp)=> {
      if(resp.body !== null) {
        const stateCode = resp.body[filterBy][1];
        const cityCode = resp.body[filterBy][0];
        this.chequeForm.get('city').setValue(cityCode);
        this.cityReadOnly = true;
      } else {
        const dialogReference = this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Alert Message",
            mainMessageHtml: "Pincode is not valid.",
            subMessage:"Sorry, but the pincode entered by you is currently Unserviceable or is Invalid. Please pay online, visit nearest branch or schedule pickup from a different location",
            branchLocator: "Branch Locator",
            reEnter: 'Re-enter PIN CODE',
            onlinePayment:'Online Payment',
           // branchLocatorURL : serviceURL.branchLocator
          }
        });
        dialogReference.afterClosed().subscribe(result =>{
         if(result){
            this.clearFields();
          }
        })
      }
      
    })
}
pincodeEntered(value) {
  const pincode: string = value;
  if(pincode.length === 6) {
    if(this.chequeForm.get('pincode').value) {
      this.getPincodeMasterData();
    }
  }
}

}
