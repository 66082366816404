import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError , of, forkJoin } from 'rxjs';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  states = {};
  cities = {};
 country = {};
 private languageSelection = new Subject<string>();
 language = this.languageSelection.asObservable();
 initialLanguage : string;

  constructor(public _httpService: HttpService, public utils: Utils) { }
  getNEFTDetails(reqBody) {
    return this._httpService.post(serviceURL.NEFTdtls_url, this.utils.createRequest(this.utils.getClientId(), 'getExistingAccntDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getCContactDetails(reqBody) {
    return this._httpService.post(serviceURL.Contactdtls_url, this.utils.createRequest(this.utils.getClientId(), 'getClientContactDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getClientAddressDetails(reqBody) {
    return this._httpService.post(serviceURL.ClientAddressdtls_url, this.utils.createRequest(this.utils.getClientId(), 'getClientAddressDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getCommAddressDetails(reqBody) {
    return this._httpService.post(serviceURL.CommAddressdtls_url, this.utils.createRequest(this.utils.getClientId(), 'getCommAddressDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getECSDetails(reqBody) {
    return this._httpService.post(serviceURL.ECSdtls_url, this.utils.createRequest(this.utils.getClientId(), 'NACHTaggingSearch', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getPersonalDtls(reqBody) {
    return this._httpService.post(serviceURL.personalDtls_url, this.utils.createRequest(this.utils.getClientId(), 'getPersonalDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getDNDstatus(reqBody) {
    return this._httpService.post(serviceURL.getDNDstatus_url, this.utils.createRequest(this.utils.getClientId(), 'getDNDstatus', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getKycExpiryDtls(reqBody) {
    return this._httpService.post(serviceURL.getKycExpiryDtls_url, this.utils.createRequest(this.utils.getClientId(), "getExistingAccntDtls", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getCountryCodeMasterData() {
    return this._httpService.get(serviceURL.getCountryCode_url)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getAccountTypeMasterData() {
    return this._httpService.get(serviceURL.getAccountType_url)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getIfscMasterData(ifscCode: string) {
    return this._httpService.get(`${serviceURL.getIfscDtls_url}${ifscCode}`)
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getWhatsappDetails(reqBody) {
    return this._httpService.post(serviceURL.whatsappMe_url, this.utils.createRequest(this.utils.getClientId(), "whatsappCheck", reqBody, "MyAccount"))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return of({});
        })
      );
  }
  getWhatsappUpdate(reqBody) {
    return this._httpService.post(serviceURL.whatsappMe_url, this.utils.createRequest(this.utils.getClientId(), "whatsappUpdate", reqBody, "MyAccount"))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getMasterData(masterDataType, filterBy, masterVersion?: string | number) {
    const url = `${serviceURL.getAddressMasterData_url}/${masterDataType}/${filterBy}`;
    return this._httpService.get(url)
      .pipe(
        map((resp) => {
          return resp
        }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
    }
    getBranchDetails(reqBody) {
      return this._httpService.post(serviceURL.getMICRdts_url, this.utils.createRequest(this.utils.getClientId(), "searchBankDetail", reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }

    setLanguage(lang : string){
      this.languageSelection.next(lang);
      this.initialLanguage = lang;
    }

    getAadhaarConsentOTP(reqBody){
      return this._httpService.post(serviceURL.aadhaarConsentSendOTP_url,this.utils.createAadhaarConsentRequest("aadhaarConsentSendOTP",reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
    }

    validateAadhaarConsentOTP(reqBody){
      return this._httpService.post(serviceURL.aadhaarConsentValidateOTP_url,this.utils.createAadhaarConsentRequest("aadhaarConsentSendOTP",reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
    }

    getAadhaarConsentFlag(reqBody){
      return this._httpService.post(serviceURL.getAadhaarConsentFlag,this.utils.createRequest(this.utils.getClientId(),"getAadhaarConsentFlag",reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
    }
    
    sendAadhaarConsentLink(reqBody){
      return this._httpService.post(serviceURL.sendAadhaarConsentLink_url,this.utils.createRequest(this.utils.getClientId(),"sendAadhaarConsentLink",reqBody))
      .pipe(
        map((resp) => {
          return resp
        }), 
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
    }
    getNextBestAction(reqBody){
      return this._httpService.post(serviceURL.getNextBestAction_url,this.utils.createRequest(this.utils.getClientId(),"getNextBestAction",reqBody))
      .pipe(
        map((resp) => {
          return resp
        }), 
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
    }
    getProfileCompleteness(reqBody) {
      return this._httpService.post(serviceURL.profileCompleteness_url, this.utils.createRequest(this.utils.getClientId(), "getProfileCompleteness", reqBody))
        .pipe(
          map((resp) => {
            return resp
          }),
          catchError((error) => {
            console.log(error);
            return of({});
          })
        )
    }
  
    getProfileCompletenessDtls(reqBody) {
      return forkJoin([this.getProfileCompleteness(reqBody), this.getWhatsappDetails(reqBody)])
        .pipe(
          map(([profileResp, whatsappResp]) => {
            if (this._httpService.isSuccessResponse(profileResp) && profileResp.body
              && whatsappResp && whatsappResp.head && whatsappResp.head.status && whatsappResp.head.status.toLowerCase() === "success") {
              profileResp.body['whatsappExists'] = whatsappResp.body.method == 'OPTIN' ? 'Y' : 'N';
              return profileResp;
            } else if (this._httpService.isSuccessResponse(profileResp) && profileResp.body
              && !(whatsappResp && whatsappResp.head && whatsappResp.head.status && whatsappResp.head.status.toLowerCase() === "success")) {
              profileResp.body['whatsappExists'] = "";
              return profileResp;
            } else {
              return profileResp;
            }
          })
        )
    } 
}