import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { serviceURL } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { validateSRReqBody } from 'src/app/utils/create-sr-data-modal';
import { Utils } from 'src/app/utils/common-functions';
import { Router } from '@angular/router';
import { TOUCHPOINT } from 'src/app/utils/app-constants';
import { DataService } from '../data/data.service';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';
import { MessagePopupComponent } from 'src/app/modules/shared/components/message-popup/message-popup.component';
import { MessageService } from '../message/message.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class CreateSRService {
  private srTypeArray: any = null;
  private srDtlsArray: any = null;
  private srSubactivityArray: any = null;
  private srPolicyStatus: any = null;
  private exideFormResp: any = null;

  constructor(public _httpService: HttpService, public utils: Utils, public router: Router, public dataService: DataService, public crypto: EncryptDecryptService, public dialog: MatDialog, public message: MessageService) {  }

  validateServiceReq(reqBody) {    
    return this._httpService.post(serviceURL.validateSR_url, this.utils.createRequest(this.utils.getClientId(), 'validationAPI', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  createSubmitSR(reqBody) {
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.createSubmitSR_url, this.utils.createRequest(this.utils.getClientId(), 'createSRAPI', reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getRequirementDetails(reqBody) {
    return this._httpService.post(serviceURL.getRequirementDtls_url, this.utils.createRequest(this.utils.getClientId(), "requirement", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getDocumentListMasterData(reqmntCode: string, subissueCode: string, lobCode: string, clientRole: string, typeOfChange) {
    let url: string;
    if (typeOfChange) {
      url = serviceURL.getDocList_url + `${reqmntCode}@@${subissueCode}@@${lobCode}@@${clientRole}@@${typeOfChange}`;
    } else {
      url = serviceURL.getDocList_url + `${reqmntCode}@@${subissueCode}@@${lobCode}@@${clientRole}@@`;
    }
    return this._httpService.get(url)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getSurrDocumentListMasterData(reqmntCode: string, subissueCode: string, lobCode: string, typeOfChange) {
    let url: string;
    if (typeOfChange) {
      url = serviceURL.getSurrenderDocList_url + `${reqmntCode}@@${subissueCode}@@${lobCode}@@${typeOfChange}`;
    } else {
      url = serviceURL.getSurrenderDocList_url + `${reqmntCode}@@${subissueCode}@@${lobCode}@@`;
    }
    return this._httpService.get(url)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getAttributesMasterData(reqmntCode: string, docCode: string) {
    return this._httpService.get(serviceURL.getAttrList_url + `${reqmntCode}@@${docCode}`)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  documentUpload(reqBody, touchpoint = TOUCHPOINT) {
    return this._httpService.post(serviceURL.docUpload_url, this.utils.createRequest(this.utils.getClientId(), "docupload", reqBody, touchpoint))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getSrListDtls(reqBody) {
    return this._httpService.post(serviceURL.srListDtls_url, this.utils.createRequest(this.utils.getClientId(), "getSrlist", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  setSRTypeResp(SRParams) {
    this.srTypeArray = SRParams;
  }
  setSrDtlsResp(srParams){
    this.srDtlsArray = srParams;
  }
  getSrDtlsResp() {
    return this.srDtlsArray;
  }
  getSRTypeResp() {
    return this.srTypeArray;
  }
  setSRsubactivity(value) {
    this.srSubactivityArray = value;
  }
  getSRsubactivity(){
    return this.srSubactivityArray;
  }
  setSrPolicyStatus(srPolicystatus){
    this.srPolicyStatus = srPolicystatus;
  }
  getSrPolicyStatus() {
    return this.srPolicyStatus;
  }
  getExideFormResp() {
    return this.exideFormResp;
   }
   setExideFormResp(resp) {
    this.exideFormResp = resp;
   }
  panValidation(reqBody, apiName: string) {
    reqBody.userid = this.crypto.encryptJson(reqBody.userid);
    reqBody.pan = this.crypto.encryptJson(reqBody.pan);
    reqBody.uname = this.crypto.encryptJson(reqBody.uname);
    reqBody.udob = this.crypto.encryptJson(reqBody.udob);
    return this._httpService.post(serviceURL.panValidation_url, this.utils.createRequest(this.utils.getClientId(), apiName, reqBody))
      .pipe(
        map((resp) => {
          if (resp && resp.body && resp.body.isValid) {
            resp.body.isValid = this.crypto.decryptString(resp.body.isValid);
          }
          if (resp && resp.body && resp.body.aadharseedingstatus) {
            resp.body.aadharseedingstatus = this.crypto.decryptString(resp.body.aadharseedingstatus);
          }
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  createSRDND(reqBody) {
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.createSRDND_url, this.utils.createRequest(this.utils.getClientId(), 'createSRDND', reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getTopUpPayment(reqBody) {
    return this._httpService.post(serviceURL.getTopUpPayment_url, this.utils.createRequest(this.utils.getClientId(), "getPayDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  ocrDocUpload(reqBody) {
    return this._httpService.post(serviceURL.ocrDocUpload_url, this.utils.createRequest(this.utils.getClientId(), "ocrdocumentupload", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getSRDetails(reqBody) {
    return this._httpService.post(serviceURL.getSRDetails_url, this.utils.createRequest(this.utils.getClientId(), "getSRDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  fulfillReqmnt(reqBody) {
    return this._httpService.post(serviceURL.fulfillRequirement_url, this.utils.createRequest(this.utils.getClientId(), "requirementfullfill", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  getTrackPercentage(reqBody) {
    return this._httpService.post(serviceURL.getTrackPercentage_url, this.utils.createRequest(this.utils.getClientId(), "getTrackPercentage", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getWellnessVoucherDtls(reqBody) {
    return this._httpService.post(serviceURL.wellnessVoucher_url, this.utils.createRequest(this.utils.getClientId(), "VoucherDetails", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  generateVoucherPdf(reqBody) {
    return this._httpService.post(serviceURL.generateVoucher_pdf, this.utils.createRequest(this.utils.getClientId(), "VoucherDetails", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getPennyDropCount(reqBody) {
    return this._httpService.post(serviceURL.getPennyDropCount_url, this.utils.createRequest(this.utils.getClientId(), "getPennyDropCount", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getPennyDropOTP(reqBody) {
    return this._httpService.post(serviceURL.getPennyDropOTP_url, this.utils.createRequest(this.utils.getClientId(), "getPennyDropOTP", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validatePennyDropOTP(reqBody) {
    return this._httpService.post(serviceURL.validatePennyDropOTP_url, this.utils.createRequest(this.utils.getClientId(), "validatePennyDropOTP", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  genPennyDropSR(reqBody) {
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.genPennyDropSR_url, this.utils.createRequest(this.utils.getClientId(), "createPennyDropSRAPI", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getOfflineAadharData(reqBody) {
    return this._httpService.post(serviceURL.getOfflineAadhar_url, this.utils.createRequest(this.utils.getClientId(), "getOfflineAadharURL", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getOfflineAadharAddrDtls(reqBody) {
    return this._httpService.post(serviceURL.getOfflineAadharAddrDtls_url, this.utils.createRequest(this.utils.getClientId(), "getAadharPopulatedDtls", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        this.router.navigate(["/my-account"]);
        return throwError(error);
      })
    );
  }
  getChecklist(reqBody, touchpoint) {
    return this._httpService.post(serviceURL.getChecklist_url, this.utils.createRequest(this.utils.getClientId(), "getCheckList", reqBody, touchpoint))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  genericDocUpload(reqBody, touchpoint) {
    reqBody.srNo = this.crypto.encryptJson(reqBody.srNo);
    return this._httpService.post(serviceURL.genericDocUpload_url, this.utils.createRequest(this.utils.getClientId(), "genericDocUpload", reqBody, touchpoint))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getSrCheckList(reqBody, touchpoint){
    return this._httpService.post(serviceURL.getSrChecklist_url,this.utils.createRequest('',"getSrCheckList", reqBody, touchpoint))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return throwError(err)
      })
    )
  }
  createSuspenseAmtSR(reqBody) {
    return this._httpService.post(serviceURL.suspenseAmtCreateSR_url, this.utils.createRequest(this.utils.getClientId(), "SuspenseAmntCreateSubmitSR", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  suspenseAmtDocUpload(reqBody) {
    return this._httpService.post(serviceURL.suspenseAmtDocUpload_url, this.utils.createRequest(this.utils.getClientId(), "SuspenseAmountDocUpload", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  showUnclaimedAmtFinalMsg() {
    let msg = "We have received your request and payout will be initiated in 4-5 working days in case there is no further requirement";
    if (!this.dataService.getIsCustomLink()) {
      this.dialog.open(MessagePopupComponent,      
        {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true, autoFocus: false,
          data: {
            mainMessage: msg,
            title: "Success",
            cancelBtnLabel: "Close",
            closeIcon: true,
          }        
        }
      );
    } else {
      this._httpService.showSuccessMsg(msg, true);
    }    
  }
  getBFHLSrDtls(reqBody) {
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    return this._httpService.post(serviceURL.bfhlsrDtls_url, this.utils.createRequest(this.utils.getClientId(), "getBFHLSrDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  redirectToRenewalPage(item) {
    let reqBody = {
      "policyno": item.POLICYNO.replace(/\s+/g,''),
      "premiumType": "Renewal",
      "srNo": '',
      "reqCd": ""
    };
    this.getTopUpPayment(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.token) {
          window.open(resp.body.token, '_self');
        }
      } else {
       let trackMsgObj ={"flowType": 'Nudges on policy card',"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  createSalutationMaritalSR(reqBody) {
    return this._httpService.post(serviceURL.createSalutationMaritalSr_url, this.utils.createRequest(this.utils.getClientId(), "createSalutationMaritalSr", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
}
