import { Directive, ElementRef, Input, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

@Directive({
  selector: '[appDecorate]'
})
export class DecorateDirective {
  @Input() appDecorate: any;
  @Input() searchId: string | Array<string> = '';
  @HostBinding('class.myacc-app-highlight') highlight;
  @HostBinding('class.myacc-app-pulse') pulse;

  constructor(private el: ElementRef, public dataService: DataService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataService.searchSubj.subscribe(data => {
      if (data && data.func_rk && this.searchId && 
         ((data.func_rk === this.searchId) || 
         (Array.isArray(this.searchId) && this.searchId.includes(data.func_rk)))
      ) {
        this.applyDecorations(data);
      } else {
        this.removeDecorations();
      }      
    });
  }

  scroll() {      
    this.el.nativeElement.scrollIntoView({'behavior': 'smooth', 'block': 'center', 'inline': 'nearest' });   
  }

  applyDecorations(data) {
    this.highlight = (data.decoration === 'HIGHLIGHT');
    this.pulse = (data.decoration === 'PULSE');    
    setTimeout(() => this.scroll(), 1000);
    setTimeout(() => this.removeDecorations(), 6000);
  }

  removeDecorations() {
    this.highlight = false;
    this.pulse = false;
  }

}
