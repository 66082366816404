/* Deep link route mapping */
export const deepLinkRouteMap = {
    "IRDA_hospital_listPage": { 
        path: ['/my-link', 'hospitals'], 
        loginRequired: false, 
        loginSource: 'Hospital Page' 
    },
    "DeclarationPage": { 
        path: ['/my-link', 'declaration'], 
        loginRequired: false, 
        loginSource: 'Declaration Page' 
    },
    "MAT_CLAIM": { 
        path: ["/my-policies", "policy-details"], 
        loginRequired: true, 
        loginSource: 'Maturity Deeplink',
        reqSubmittedBy: "Deeplink",
    },
    "cp_emailVOCPage": { 
        path: ["/my-link", 'voc'], 
        loginRequired: false, 
        loginSource: 'Voc Page' 
    },
    "STND_NEFT": { 
        path: ["/my-link", 'update-bank-details'], 
        loginRequired: true, 
        loginSource: 'Update Neft Deeplink',
        reqSubmittedBy: "Deeplink",
    },
    "cp_SurrenderPage": { 
        path: ["/my-link", "surrender"], 
        loginRequired: true,
        loginSource: 'Surrender Link', 
        linkValidation: true, 
        canExpire: true,
        shortLinkCode: 'sl',  
    },
    "SRTracking": { 
        path: ["/my-link", "sr-track"], 
        loginRequired: true, 
        loginSource: 'SR Track',
        shortLinkCode: 'sr', 
    },
    "cp_vservPage": { 
        path: ["/my-link", 'vserv'], 
        loginRequired: false, 
        loginSource: 'Vserv page' 
    },
    "cp_voucherPageUSL": { 
        path: ["/my-link", 'wb-voucher'], 
        loginRequired: false, 
        loginSource: 'WB Voucher' 
    },
    "cp_unsecuredocUploadPage": { 
        path: ["/my-link", 'upload'], 
        loginRequired: true, 
        loginSource: 'Click2Upload Link', 
        linkValidation: true, 
        canExpire: false,
        shortLinkCode: 'gu', 
    },
    "consent": { 
        path: ["/my-link", 'digital-consent'], 
        loginRequired: true, 
        loginSource: 'Digital Consent Link', 
        linkValidation: true, 
        canExpire: true,
        shortLinkCode: 'my-consent', 
    },
    "cp_frAgentDocUploadPage" : {
        path: ["/my-link", 'agent-doc-upload'], 
        loginRequired: false, 
        loginSource: 'Agent Doc Upload',
        shortLinkCode: 'AgentDocUp',
    },
    "cp_unsecuredChequePickUpPage": { 
        path: ["/my-link", "cheque-pickup"], 
        loginRequired: true ,
        loginSource: 'cheque-pickup',        
    },
    "COVID": { 
        path: ["/my-policies", "covid"], 
        loginRequired: true ,
        loginSource: 'COVID Form',
        shortLinkCode: 'dc', 
    },
    "PHS": { 
        path: ["/my-policies", "phs"], 
        loginRequired: true ,
        loginSource: 'PHS Form',
        shortLinkCode: 'dp', 
    },
    "CHNG_CON_NUM": { 
        path: ["/my-account"], 
        loginRequired: true,
        loginSource: 'Update Contact Deeplink',
        reqSubmittedBy: "Deeplink",        
    },
    "PAN_CARD_UPD": { 
        path: ["/my-account"], 
        loginRequired: true,
        loginSource: 'Update PAN Deeplink',
        reqSubmittedBy: "Deeplink",      
    },
    "UPD_KYC": { 
        path: ["/my-account"], 
        loginRequired: true,
        loginSource: 'Update KYC Deeplink',
        reqSubmittedBy: "Deeplink",       
    },
    "STMNT": { 
        path: ["/my-downloads"], 
        loginRequired: true,
        loginSource: 'Statement Deeplink',
        reqSubmittedBy: "Deeplink",       
    },
    /* "DeathClaimForm": { 
        path: ["/my-link", 'death-claim'], 
        loginRequired: true, 
        loginSource: 'Death Claim Form', 
        linkValidation: true, 
        canExpire: true,
        shortLinkCode: 'my-dc', 
    },
    "DeathClaimPDF": { 
        path: ["/my-link", 'death-claim-pdf'], 
        loginRequired: false, 
        loginSource: '', 
        linkValidation: false, 
        canExpire: false,
        shortLinkCode: 'my-dcf', 
    }, */
    "UNCLAIM_AMT": { 
        path: ["/my-policies", "policy-details"], 
        loginRequired: true,
        loginSource: 'Unclaimed Account Deeplink',
        reqSubmittedBy: "Deeplink",        
    },
    "SUSPENSE_AMT": { 
        path: ["/my-policies", "policy-details"], 
        loginRequired: true,
        loginSource: 'Suspense Account Deeplink',
        reqSubmittedBy: "Deeplink",        
    },
    "nri_vserv": { 
        path: ["/my-link", 'vserv'], 
        loginRequired: false, 
        loginSource: 'Vserv page' 
    },
};