<div class="investigator-landing-page">
    <div class="investigator-landing-padding"></div>
    <div class="investigator-landing-page flex">
        <div class="investigator-landing-left-div">
            <mat-card class="login-left-section">
                <mat-card-title>Login</mat-card-title>
                <mat-tab-group animationDuration="0ms" class="login-tabs" [disableRipple]=true
                    (selectedTabChange)="clearCheckbox($event)">
                    <mat-tab label="Mobile Number">
                        <ng-template matTabContent>
                            <form [formGroup]="investigatorMobileForm" (ngSubmit)="submitMobileInvestigatorForm()">
                                <div class="investigator-form-label">Your Policy Number<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your Policy Number</mat-placeholder>
                                    <input formControlName="policyNo" type="tel" decimalNumbersOnly matInput
                                        (focusout)="policyFocusOut()" [readonly]="otpFlag" maxlength="8">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">

                                    <mat-error class="error-message"
                                        *ngIf="(policyFocus && mobilevalidation.policyNo.errors?.required)">
                                        Enter Policy Number
                                    </mat-error>
                                </mat-form-field>

                                <div class="investigator-form-label">Your Mobile Number<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your Mobile Number</mat-placeholder>
                                    <input formControlName="mobileNum" type="tel" decimalNumbersOnly matInput
                                        (focusout)="mobileNumFocusOut()" [readonly]="otpFlag" maxlength="10">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">
                                    <mat-error class="error-message"
                                        *ngIf="(mobileNumFocus && mobilevalidation.mobileNum?.errors?.required)">
                                        Enter Mobile Number
                                    </mat-error>
                                </mat-form-field>

                                <div class="investigator-form-label">Your PAN Number<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your PAN Number</mat-placeholder>
                                    <input formControlName="panNum" type="text" matInput
                                        (focusout)="panNumFocusOut()" [readonly]="otpFlag">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">
                                    <mat-error class="error-message"
                                        *ngIf="(panNumFocus && mobilevalidation.panNum?.errors?.required)">
                                        Enter PAN Number
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(panNumFocus && mobilevalidation.panNum?.errors?.pattern)">
                                        Enter a Valid PAN Number
                                    </mat-error>
                                </mat-form-field>

                                <div class="investigator-form-label">Captcha<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter captcha</mat-placeholder>
                                    <input formControlName="investigatorCaptcha" (keypress)="errorclear()" type="text"
                                        matInput (focusout)="captchaFocusOut($event)" [readonly]="otpFlag">
                                    <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)"
                                        (reloadEvent)="clearClientCaptcha()"
                                        [enteredCaptcha]="mobilevalidation.investigatorCaptcha.value">
                                    </app-captcha>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && mobilevalidation.investigatorCaptcha.errors?.required)">
                                        Please enter captcha.
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && mobilevalidation.investigatorCaptcha.errors?.pattern)">
                                        Invalid Captcha.
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && mobilevalidation.investigatorCaptcha.errors?.captchaIncorrect && !(mobilevalidation.investigatorCaptcha.errors?.pattern) && !(mobilevalidation.investigatorCaptcha.errors?.required))">
                                        Incorrect Captcha.
                                    </mat-error>
                                </mat-form-field>
                                <div class="button-div">
                                    <button *ngIf="!otpFlag" type="submit" value="login" class="login-button"
                                         [disabled]="!investigatorMobileForm.valid"
                                        mat-flat-button>Send
                                        OTP</button>
                                        <!-- <button *ngIf="!otpFlag" type="submit" value="login" class="login-button"
                                        (click)="getUserDetails('mobile')"
                                        mat-flat-button>Send
                                        OTP</button> -->
                                    <!-- <button *ngIf="!otpFlag" class="reset-button" (click)="resetForm('mobile')" mat-flat-button>Reset</button> -->
                                </div>
                                <!-- <button type="submit" class="login-button login-button-padding" mat-flat-button>Proceed</button> -->
                            </form>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Email ID">
                        <ng-template matTabContent>
                            <form [formGroup]="investigatorEmailForm" (ngSubmit)="submitEmailInvestigatorForm()">
                                <div class="investigator-form-label">Your Policy Number<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your Policy Number</mat-placeholder>
                                    <input formControlName="policyNo" type="tel" decimalNumbersOnly matInput
                                        (focusout)="policyFocusOut()" [readonly]="otpFlag" maxlength="8">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">

                                    <mat-error class="error-messasge"
                                        *ngIf="(policyFocus && emailvalidation.policyNo.errors?.required)">
                                        Enter Policy Number
                                    </mat-error>
                                </mat-form-field>
                                <div class="investigator-form-label">Email ID<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your Email ID</mat-placeholder>
                                    <input formControlName="email" (keypress)="errorclear()" type="text" matInput
                                        (focusout)="emailFocusOut($event)">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">
                                    <mat-error class="error-message"
                                        *ngIf="(emailFocus && emailvalidation.email.errors?.required)">
                                        Enter Email ID
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(emailFocus && emailvalidation.email.errors?.email)">
                                        Please enter valid email ID.
                                    </mat-error>
                                </mat-form-field>

                                <div class="investigator-form-label">Your PAN Number<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter your PAN Number</mat-placeholder>
                                    <input formControlName="panNum" type="text" matInput
                                        (focusout)="panNumFocusOut()" [readonly]="otpFlag">
                                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs">
                                    <mat-error class="error-message"
                                        *ngIf="(panNumFocus && emailvalidation.panNum?.errors?.required)">
                                        Enter PAN Number
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(panNumFocus && emailvalidation.panNum?.errors?.pattern)">
                                        Enter a Valid PAN Number
                                    </mat-error>
                                </mat-form-field>

                                <div class="investigator-form-label">Captcha<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Please enter captcha</mat-placeholder>
                                    <input formControlName="investigatorCaptcha" (keypress)="errorclear()" type="text"
                                        matInput (focusout)="captchaFocusOut($event)" [readonly]="otpFlag">
                                    <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)"
                                        (reloadEvent)="clearClientCaptcha()"
                                        [enteredCaptcha]="emailvalidation.investigatorCaptcha.value">
                                    </app-captcha>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && emailvalidation.investigatorCaptcha.errors?.required)">
                                        Please enter captcha.
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && emailvalidation.investigatorCaptcha.errors?.pattern)">
                                        Invalid Captcha.
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="(loginCaptchaFocusOut && emailvalidation.investigatorCaptcha.errors?.captchaIncorrect && !(emailvalidation.investigatorCaptcha.errors?.pattern) && !(emailvalidation.investigatorCaptcha.errors?.required))">
                                        Incorrect Captcha.
                                    </mat-error>
                                </mat-form-field>
                                <!-- <div [innerHTML]="statusMessage" class="status-message"></div> -->

                                <!-- <div [innerHTML]="statusMessage" class="status-message"></div> -->
                                <div class="button-div">
                                    <button *ngIf="!otpFlag" type="submit" value="login" class="login-button"
                                         [disabled]="!investigatorEmailForm.valid"
                                        mat-flat-button>Send
                                        OTP</button>
                                    <!-- <button *ngIf="!otpFlag" class="reset-button" (click)="resetForm('email')" mat-flat-button>Reset</button> -->
                                </div>
                                <!-- <button type="submit" class="login-button login-button-padding" mat-flat-button>Proceed</button> -->
                            </form>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
                <!-- <app-policy-based-login></app-policy-based-login> -->
                <div class="otp-div" *ngIf="otpFlag">
                    <div *ngIf="maskEmail && !maskMobileNumber && emailChoice" class="otp-sent-msg">
                      <img src="assets/green-tick.svg">
                      OTP has been successfully sent to your registered email id {{maskEmail}}
                    </div>
                    <div *ngIf="!maskEmail && maskMobileNumber && mobileChoice" class="otp-sent-msg">
                      <img src="assets/green-tick.svg">
                      OTP has been successfully sent to your registered mobile no {{maskMobileNumber}}
                    </div>
                    <div class="otp-timer-div">
                      Your OTP will be valid for <span class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                      <a (click)="RegenOTP()" *ngIf="(timerSec==0)"><img src="assets/refresh.png" class="refresh-img">Regenerate
                        OTP</a>
                    </div>
                    <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
                      <div class="otp-field">
                        <div class="form-label otp-label">OTP<sup>*</sup></div>
                        <mat-form-field floatLabel="never" class="client-login-field"
                          [ngClass]="{'error-field': (otpFocus && otpForm.controls.otp.errors?.required) || (otpFocus && otpForm.controls.otp.errors?.pattern)}">
                          <mat-placeholder>Enter the OTP received</mat-placeholder>
                          <input formControlName="otp" type="{{isOtpMasked ? 'password' : 'tel'}}" decimalNumbersOnly  matInput (focusout)="otpFocusOut()"
                            maxlength="6">
                            <div class="mat-suffix-wrapper" (click)="toggleMasking()">
                                    <img class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                                      *ngIf="!isOtpMasked">
                                    <img class="iris-open" mat-suffix src="assets/eye.png"
                                      *ngIf="isOtpMasked">
                                  </div>
                          <mat-error class="error-message"
                            *ngIf="((buttonClick || otpFocus) && otpForm.controls.otp.errors?.required)">
                            Enter valid OTP
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <button type="submit" class="login-button"  mat-flat-button
                        [disabled]="!otpForm.valid">Proceed</button>
                      <!-- <button class="primary-btn" (click)="redirectToLogin()">Cancel</button> -->
                    </form>
                  </div>
            </mat-card>
        </div>
    </div>
</div>