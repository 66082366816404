<div class="secret-password-page">
        <div class="secret-wrapper">
            <mat-card class="secret-card">
                <div class="card-heading">
                        <span>Change of Secret Questions and Answers</span>
                </div>
                <div class="secret-content-wrapper flex">
                    <div class="left-section">
                        <form [formGroup]="secretPwdForm" (ngSubmit)="submitSecretPwdForm()">
                                <div class="form-label">Secret Question 1</div>   
                                <mat-form-field floatLabel = "never" class="form-field first-field" [ngClass]="{'error-field': (firstQuesFocus && validation.firstSecretQues.errors?.required)}">
                                    <mat-placeholder>Choose your secret question here</mat-placeholder>
                                    <mat-select class="secret-ques" formControlName="firstSecretQues" (selectionChange)="populateSecondQtn($event)" (focusout)="firstQuesFocusOut($event)">
                                        <mat-option *ngFor="let item of secretQues" [value]="item">
                                            {{item}}
                                            </mat-option>
                                    </mat-select>
                                    <mat-error class="error-message" *ngIf="(firstQuesFocus && validation.firstSecretQues.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                    
                                </mat-form-field>

                            <div class="form-label">Answer</div>   
                            <mat-form-field floatLabel = "never" class="form-field first-field" [ngClass]="{'password-field': isFirstAnswerMasked,'error-field': (firstAnswerFocus && validation.first.errors?.required) || (firstAnswerFocus && validation.first.errors?.minlength) || (firstAnswerFocus && validation.first.errors?.maxlength) || (firstAnswerFocus &&  validation.first.errors?.pattern) }">
                                <mat-placeholder>Enter your answer here</mat-placeholder>
                                <input formControlName="first" maxlength="25" type="password" autocomplete="new-password" #first matInput (focusout)="firstAnswerFocusOut($event)">
                                <img mat-suffix src="assets/eye_closed.svg" class="eye-img cursor-point" #img (click)="toggleFirstInputType(img, first)">
                                <mat-error class="error-message" *ngIf="(firstAnswerFocus && validation.first.errors?.required)">
                                    This field is required.
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(firstAnswerFocus && (validation.first.errors?.minlength) || (validation.first.errors?.maxlength))">
                                    Must be from 3 to 25 characters long
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(firstAnswerFocus && validation.first.errors?.pattern&& !(validation.first.errors?.minlength) && !(validation.first.errors?.maxlength))">
                                    Space is not allowed at the beginning or at the end
                                </mat-error>
                            </mat-form-field>
                            <div class="bottom-padding"></div>
                            <div class="form-label">Secret Question 2</div>   
                            <mat-form-field floatLabel = "never" class="form-field first-field" [ngClass]="{'error-field': (secondQuesFocus && validation.secondSecretQues.errors?.required)}">
                                <mat-placeholder>Choose your secret question here</mat-placeholder>
                                <mat-select class="secret-ques" formControlName="secondSecretQues" (focusout)="secondQuesFocusOut($event)">
                                        <mat-option *ngFor="let item of secretQues2" [value]="item">
                                                {{item}}
                                                </mat-option>
                                    </mat-select>
                                <mat-error class="error-message" *ngIf="(secondQuesFocus && validation.secondSecretQues.errors?.required)">
                                    This field is required.
                                </mat-error>
                                
                                
                            </mat-form-field>

                            <div class="form-label">Answer</div>   
                            <mat-form-field floatLabel = "never" class="form-field" [ngClass]="{'password-field': isSecondAnswerMasked,'error-field': (secondAnswerFocus && validation.second.errors?.required)|| (secondAnswerFocus && validation.second.errors?.minlength) || (secondAnswerFocus && validation.second.errors?.maxlength) || (secondAnswerFocus && validation.second.errors?.pattern)}">
                                <mat-placeholder>Enter your answer here</mat-placeholder>
                                <input formControlName="second" maxlength="25" type="password" autocomplete="new-password" #second matInput (focusout)="secondAnswerFocusOut($event)">
                                <img mat-suffix src="assets/eye_closed.svg" class="eye-img cursor-point" #image (click)="toggleSecInputType(image, second)">
                                <mat-error class="error-message" *ngIf="(secondAnswerFocus && validation.second.errors?.required)">
                                    This field is required.
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(secondAnswerFocus && (validation.second.errors?.minlength) || (validation.second.errors?.maxlength))">
                                    Must be from 3 to 25 characters long
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(secondAnswerFocus && (validation.second.errors?.pattern) && !(validation.second.errors?.minlength) && !(validation.second.errors?.maxlength))">
                                    Space is not allowed at the beginning or at the ends
                                </mat-error>
                            </mat-form-field>
                                <button type="submit" class="secret-btn" [disabled]="!secretPwdForm.valid">Submit</button>
                                <button class="secret-btn" (click)="redirectToLogin()">Cancel</button>
                        </form>
                    </div>
                    <div class="right-section">
                        <div class="list-heading">Instructions</div>
                        <div class="list-item flex" *ngFor ="let item of secretQuestionsInstructions">
                            <img src="assets/li-style.svg" class="list-item-img">
                            <span>{{item.instruction}}</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    
    