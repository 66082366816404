<div class="agent-doc-upload-page">
    <div class="agent-doc-upload-padding">
        <mat-card class="upload-form-card">
            <mat-card-title class="title-text">
                SR Requirement Submission
            </mat-card-title>
            <mat-card-subtitle class="details">Please provide details below to validate your information.
            </mat-card-subtitle>
            <form class="form-section" [formGroup]="agentForm" autocomplete="off">
                <div class="form-field-section">
                    <div class="form-field-div">
                        <label class="form-field-label">Agent Id <span class="error-two">*</span></label>
                        <mat-form-field class="mailing-form-field agent-id-field" floatLabel="never">
                            <!-- <mat-placeholder>Agent Id</mat-placeholder> -->
                            <input type="text" matInput formControlName="agentId" maxlength="8"
                                [readonly]="readOnlyField">
                            <mat-error *ngIf="agentForm.controls.agentId.errors?.required" class="error-message">
                                Agent Id is required
                            </mat-error>
                            <mat-error *ngIf="agentForm.controls.agentId.errors?.pattern" class="error-message">
                                Enter valid agentId
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <label class="trigger-message"><span class="note">Note: </span>OTP will be triggered to registered
                        mobile number</label>
                    <div class="form-field-div captcha-field" *ngIf="!sendOtpField && !otpFieldEnable">
                        <label class="form-field-label">Captcha <span class="error-two">*</span></label>
                        <mat-form-field class="mailing-form-field" floatLabel="never">
                            <!-- <mat-placeholder>Captcha</mat-placeholder> -->
                            <input type="text" matInput formControlName="captcha">
                            <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)"
                                (reloadEvent)="clearClientCaptcha()"
                                [enteredCaptcha]="agentForm.controls.captcha.value">
                            </app-captcha>
                            <mat-error *ngIf="agentForm.controls.captcha.errors?.required" class="error-message">
                                Captcha is required
                            </mat-error>
                            <mat-error class="error-message" *ngIf="agentForm.controls.captcha.errors?.pattern">
                                Invalid Captcha.
                            </mat-error>
                            <mat-error class="error-message"
                                *ngIf="agentForm.controls.captcha.errors?.captchaIncorrect">
                                Incorrect Captcha.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="registered-number-message" *ngIf="sendOtpField && !otpFieldEnable"><img class="info-img"
                            src="/assets/info_active.svg">Your registered mobile number is {{maskMobileNo}}</div>
                    <div class="otp-sent-message" *ngIf="otpFieldEnable && !sendOtpField">The OTP has been sent to your
                        registered mobile number {{maskMobileNo}}
                    </div>
                    <div class="otp-timer-div" *ngIf="otpFieldEnable && !sendOtpField"> Your OTP will be valid for <span
                            class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                        <a (click)="RegenOTP()"><img src="assets/refresh.png" class="refresh-img">Regenerate
                            OTP</a>
                    </div>
                    <div class="form-field-div otp-field" *ngIf="otpFieldEnable && !sendOtpField">
                        <label class="form-field-label">Enter the OTP received: <span class="error-two">*</span></label>
                        <mat-form-field class="mailing-form-field" floatLabel="never">
                            <!-- <mat-placeholder>Agent Id</mat-placeholder> -->
                            <input type="text" matInput formControlName="otp" maxlength="6" [readonly]="sendOtpField" type="{{isOtpMasked ? 'password' : 'tel'}}">
                            <div class="mat-suffix-wrapper" (click)="toggleMasking()">
                                <img class="iris-closed" mat-suffix src="assets/eye_closed.svg" *ngIf="!isOtpMasked">
                                <img class="iris-open" mat-suffix src="assets/eye.png" *ngIf="isOtpMasked">
                            </div>
                            <mat-error *ngIf="agentForm.controls.otp.errors?.required" class="error-message">
                                Otp is required
                            </mat-error>
                            <mat-error *ngIf="agentForm.controls.otp.errors?.pattern" class="error-message">
                                Enter valid Otp
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="button-div flex">
                        <button mat-button class="submit-button" type="submit" [disabled]="!(agentForm.valid)"
                            [class.disable]="!(agentForm.valid)" (click)="enableOtp()"
                            *ngIf="!sendOtpField && !otpFieldEnable">Validate</button>
                        <button mat-button class="clear-button" (click)="resetForm()"
                            *ngIf="!sendOtpField && !otpFieldEnable">Clear</button>
                        <button mat-button class="submit-button otp-button" (click)="sendOtp()"
                            *ngIf="sendOtpField && !otpFieldEnable">Send OTP</button>
                        <button mat-button class="submit-button otp-button" (click)="submit()"
                            *ngIf="otpFieldEnable && !sendOtpField">Submit</button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>