import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { catchError, map } from 'rxjs/operators';
import { throwError, of, Subject } from 'rxjs';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';
import { policyDtlsReqBody } from 'src/app/modules/my-policies/my-policies-data-modal';
import { DataService } from '../data/data.service';
import { inactivePolicyStatus } from 'src/app/utils/app-constants';
import * as moment from 'moment';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';
import { MyPoliciesDialogComponent } from 'src/app/modules/my-policies/my-policies-dialog/my-policies-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PdfViewerDialogComponent } from 'src/app/modules/my-downloads/pdf-viewer-dialog/pdf-viewer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MyPoliciesService {
  payoutDetails:Array<any>=[];
  payeeDetails:Array<any>=[];
  payout:Array<any>=[];
  claimDetails: Array<any>=[];
  private kycDataSubject = new Subject<any>();
  kycDataObs = this.kycDataSubject.asObservable();
  kycData: Array<any>=[];
  userDetails: any;
  constructor(public _httpService: HttpService, public utils: Utils, public dataService: DataService, public crypto: EncryptDecryptService, public dialog: MatDialog, public router: Router) { }
  getToasterPopup(reqBody) {
    return this._httpService.post(serviceURL.toaster_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  // getConfirmationNudges(reqBody) {
  //   return this._httpService.post(serviceURL.confirmNudges_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
  //     .pipe(
  //       map((resp) => {
  //         return resp
  //       }),
  //       catchError((error) => {
  //         console.log(error);
  //         return throwError(error);
  //       })
  //     );
  // }
  neftConfirmation(reqBody){
    return this._httpService.post(serviceURL.confirmNeft_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );

  }
  contactConfirmation(reqBody){
    return this._httpService.post(serviceURL.confirmContact_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  addressConfirmation(reqBody){
    return this._httpService.post(serviceURL.confirmAddress_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
}
  showHideToaster(res) {
    for (let obj of res) {
      if (obj['val'] == "Y" && (obj['flag'] == "aps" || obj['flag'] == "neftexp" || obj['flag'] == "panexp" || obj['flag'] == "mobemailexp")) {
        return true;
      }
    }
    return false;
  }
  getExpInfoDtls() {
    const expInfoDtls = {
      neftexp: {
        // msg: "Please update your NEFT details.",
        msg: "Update your <b> bank account </b> details to ensure timely payouts when due.",
        // label: "Update NEFT",
        label: "Update bank details",
        target: {
          path: ["/my-account"],
          tab: "bank"
        }
      },
      panexp: {
        msg: "Update your <b> PAN </b> to avoid higher tax deduction at payouts.",
        label: "Update PAN details",
        target: {
          path: ["/my-account"],
          tab: "personal"
        }
      },
      mobemailexp: {
        msg: "Update your latest <b> phone number/email </b> for timely alerts on your policy.",
        label: "Update contact details",
        target: {
          path: ["/my-account"],
          tab: "contact"
        }
      },
      kycexp: {
        msg: "Please update your <b> KYC </b> details.",
        label: "Update KYC",
        target: {
          path: ["/my-account"],
          tab: "personal"
        }
      },
      aps: {
        msg: "Looking for the<b> Annual Premium Statement </b> for Tax planning?",
        label: "Download from here",
        target: {
          path: ["/my-downloads","annual-premium-statements"],
          tab: "APS"
      }
      },
    }
    return expInfoDtls;
  }
  compareToasterObj(a, b) {
    if (a.priority < b.priority) { return -1; }
    if (a.priority > b.priority) { return 1; }
    return 0;
  }
  setToasterMsg(res) {
    const toasterMsg = this.getExpInfoDtls();
    for (let obj of res) {
      if (obj['val'] === "Y" && (obj['flag'] == "neftexp" || obj['flag'] == "panexp" || obj['flag'] == "mobemailexp" || obj['flag'] == "aps")) {
        return toasterMsg[obj['flag']];
      }
    }
  }
  getExpInfoPopup(res) {
    let expDtls = [];
    for (let obj of res) {
      if (obj['val'] === "Y") {
        expDtls.push(obj);
      }
    }
    return expDtls;
  }
  getPolicyCardDtls(reqBody) {
    return this._httpService.post(serviceURL.policyCard_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getPolicyDetails() {
    if (this.dataService.getPolicyCardResp() == null) {
      let reqBody: policyDtlsReqBody = {
        searchtype: "policyCard",
        clientid: this.utils.getClientId()
      }
      return this.getPolicyCardDtls(reqBody)
        .pipe(
          map((resp) => {
            if (this._httpService.isSuccessResponse(resp)) {
              for(let item of resp.body) {
                this.userDetails = this.dataService.getLoginResp();
                if(item['MaturityCheck'] == 'Y') {
                  var dateTime = this.userDetails.Time.replace("IST"," ");
                  var currDate = moment(dateTime , 'ddd MMM DD hh:mm:ss YYYY');
                  let todayDate = currDate.format('DD/MM/YYYY');
                  let currentDate =  moment(todayDate,'DD/MM/YYYY');
                  let maturityDate = moment(item['MATURITYDATE'],'DD/MM/YYYY');
                  item['maturityDisclaimerFlag'] = (maturityDate).isAfter(currentDate)?'Y':'N';
                }
                else {
                  item['maturityDisclaimerFlag'] = 'N';
                }
                if(item['ACTIVEFLAG'] == 'Y') {
                  item['status'] = "Active"
                }
                else {
                  item['status'] = "Inactive"
                }
             }
              let policyCardResp = this.utils.formatDtls(resp.body, "POLICYNO", "MATURITYDATE", "D MMM, YYYY");
              policyCardResp = this.utils.formatDtls(resp.body, "POLICYNO", "PREMIUMDUEDT", "D MMM, YYYY");
              policyCardResp = this.utils.formatDtls(resp.body, "POLICYNO", "ANNUITYNEXTPAYDATE", "D MMM, YYYY");
              this.dataService.setPolicyCardResp(policyCardResp);
              // this.utils.trackUserActions({ "eventName" : "Policy Card" });
              return this.dataService.getPolicyCardResp();
            } else {
              this._httpService.apiErrorHandler(resp, this.dataService.isDeepLink);
            }
          }),
          catchError((error) => throwError(error))
        );
    } else {
      this.dataService.policyCardSubject.next(this.dataService.getPolicyCardResp())
      return of(this.dataService.getPolicyCardResp());
    }
  }
  getPolicyDtls(reqBody) {
    return this._httpService.post(serviceURL.policydetails_url, this.utils.createRequest(this.utils.getClientId(),'policy_detail',reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getPaymentHistoryDetails(reqBody) {
    return this._httpService.post(serviceURL.paymentHistoryDetails_url,this.utils.createRequest(this.utils.getClientId(),'getPaymentHistoryDetails',reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getNomineeDetails(reqBody) {
    return this._httpService.post(serviceURL.nomineeDetails_url,this.utils.createRequest(this.utils.getClientId(),'getNomineeDetails',reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getLifeInsuredDetails(reqBody) {
    return this._httpService.post(serviceURL.lifeInsuredDetails_url,this.utils.createRequest(this.utils.getClientId(),'getNomineeDetails',reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getRiderDtls(reqBody) {
    return this._httpService.post(serviceURL.getRiderDtls_url, this.utils.createRequest(this.utils.getClientId(), "getRiderDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getNomineeList(reqBody) {
    return this._httpService.post(serviceURL.getNomineeList_url, this.utils.createRequest(this.utils.getClientId(), "policy_detail", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getExistingFundAllocation(reqBody) {
    return this._httpService.post(serviceURL.existingfundAllocDtls_url, this.utils.createRequest(this.utils.getClientId(), "fund", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getSurrenderQuote(reqBody) {
    return this._httpService.post(serviceURL.getSurrenderQuote_url, this.utils.createRequest(this.utils.getClientId(),'getSurrenderQuote',reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  addnomineeSR(reqBody) {
    return this._httpService.post(serviceURL.createSubmitSR_url, this.utils.createRequest(this.utils.getClientId(), "createSubmitSR", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getintouchDtls(reqBody) {
    return this._httpService.post(serviceURL.getintouch_url, this.utils.createRequest(this.utils.getClientId(), "dashboardAPI", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    ); 
  }
  redirectActivateSI(reqBody) {
    return this._httpService.post(serviceURL.activateSI_url, this.utils.createRequest(this.utils.getClientId(), "getRedirectSI", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    ); 
  }
  sendAlertsFundValue(reqBody) {
    return this._httpService.post(serviceURL.sendAlerts_url, this.utils.createRequest(this.utils.getClientId(), "sendAlerts", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    ); 
  }
  calculateNewPremiumValue(reqBody) {
    return this._httpService.post(serviceURL.calcNewPremium_url, this.utils.createRequest(this.utils.getClientId(), "calculateNewPremium", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    ); 
  }
  createSubmitFSPRTopUpSR(reqBody) {
    return this._httpService.post(serviceURL.createSubmitFSPRTopUpSR_url, this.utils.createRequest(this.utils.getClientId(), "createSubmitFSPRTopUpSR", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getChangeFreqValidation(productCode: string) {
    return this._httpService.get(serviceURL.changeFreqValidation_url + productCode)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getTopUpDtls(reqBody) {
    return this._httpService.post(serviceURL.getTopUpDtls_url, this.utils.createRequest(this.utils.getClientId(), "getPolicyTopUpDtls", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getRevivalStatus(reqBody) {
    return this._httpService.post(serviceURL.getPolicyRevivalStatus_url, this.utils.createRequest(this.utils.getClientId(), "RevivalQuote", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  createSubmitRevivalSR(reqBody) {
    return this._httpService.post(serviceURL.createSubmitRevivalSR_url, this.utils.createRequest(this.utils.getClientId(), "RevivalQuote", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getDeactivateSI(reqBody) {
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.deactivateSI_url, this.utils.createRequest(this.utils.getClientId(), "getDeactivateSI", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  autoDebitReport(reqBody, apiname) {
    return this._httpService.post(serviceURL.autoDebitReport_url, this.utils.createRequest(this.utils.getClientId(), apiname, reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  lifeProcessSubmit(reqBody) {
    return this._httpService.post(serviceURL.lifeProcessSubmit_url, this.utils.createRequest(this.utils.getClientId(), "lifeProcessSubmit", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getCovidQuestionnaire(reqBody) {
    return this._httpService.post(serviceURL.FrQuestionnaire_url, this.utils.createRequest(this.utils.getClientId(), "getFrQuestionnaire", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }

  getRevivalQuote(reqBody) {
    return this._httpService.post(serviceURL.getRevivalQuote_url, this.utils.createRequest(this.utils.getClientId(), "RevivalQuote", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPhsMasterData(masterDataType) {
    const url = `${serviceURL.getPhsMasterData_url}/${masterDataType}`;
    return this._httpService.get(url)
      .pipe(
        map((resp) => {
          return resp
        }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
    }
    getSmqMasterData(masterDataType) {
      const url = `${serviceURL.getSmqMasterData_url}/${masterDataType}`;
      return this._httpService.get(url)
        .pipe(
          map((resp) => {
            return resp
          }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
      }
      getCovidMasterData(masterDataType) {
        const url = `${serviceURL.getCovidMasterData_url}/${masterDataType}`;
        return this._httpService.get(url)
          .pipe(
            map((resp) => {
              return resp
            }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
        }
  searchDigiPay(reqBody) {
    return this._httpService.post(serviceURL.getTransactionDtls_url, this.utils.createRequest(this.utils.getClientId(), "searchDigiPay", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    ); 
  }
  getAmsOnloadDetails(reqBody){
    return this._httpService.post(serviceURL.AmsOnloadDtls_url, this.utils.createRequest(this.utils.getClientId(), "getSrlist", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }

  getIllustrativeDetails(reqBody){
    return this._httpService.post(serviceURL.IllustrativeDtls_url, this.utils.createRequest(this.utils.getClientId(), "getIllustrativeDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }

  getIllustrativeMail(reqBody){
    return this._httpService.post(serviceURL.IllustrativeMail_url, this.utils.createRequest(this.utils.getClientId(), "getIllustrativeMail", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getMaturityClaim(reqBody){
    return this._httpService.post(serviceURL.getMaturityClaim_url, this.utils.createRequest(this.utils.getClientId(), "maturityClaimOnClick", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeeDtls(reqBody){
    return this._httpService.post(serviceURL.getMaturityClaim_url, this.utils.createRequest(this.utils.getClientId(), "maturityClaimBenefitDetailOnLoad", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getClaimDtls(reqBody){
    return this._httpService.post(serviceURL.getClaimDtls_url, this.utils.createRequest(this.utils.getClientId(), "claimPayoutDetailOnClick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getNREAccDtls(reqBody){
    return this._httpService.post(serviceURL.getNREAccDtls_url, this.utils.createRequest(this.utils.getClientId(), "payoutGetNREAccountDetails", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getNREPayDtls(reqBody){
    return this._httpService.post(serviceURL.getNREAccDtls_url, this.utils.createRequest(this.utils.getClientId(), "payoutGetNREPaymentDetails", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getJointeeNomineeDetails(reqBody){
    return this._httpService.post(serviceURL.JointeeNomineeDetailAms_url, this.utils.createRequest(this.utils.getClientId(), "getSrlist", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getAmsCreateSR(reqBody){
    return this._httpService.post(serviceURL.amsCreateSr_url, this.utils.createRequest(this.utils.getClientId(), "getAmsCreateSR", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getAnnuityPayoutDetails(reqBody,apiName){
    return this._httpService.post(serviceURL.amsPayoutOnload_url, this.utils.createRequest(this.utils.getClientId(), apiName, reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  createSubmitMaturitySR(reqBody){
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.createsubmitMaturitySR_url, this.utils.createRequest(this.utils.getClientId(), "claimReqDocGenerateSubmit", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayoutDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeeDtlsOnload_url, this.utils.createRequest(this.utils.getClientId(), "payeeDetailsClaimsOnLoad", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeeDtlsClaim(reqBody){
    return this._httpService.post(serviceURL.getPayeeDtlsClaim_url, this.utils.createRequest(this.utils.getClientId(), "payeeDetailsClaims", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayoutNEFT(reqBody){
    return this._httpService.post(serviceURL.getPayoutNEFT_url, this.utils.createRequest(this.utils.getClientId(), "payoutGetNEFTAccountDetails", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeePersonalDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeepersonalOnclick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeedetails() {
    return this.payeeDetails;
  }
  setPayeedetails(reqBody) {
    this.payeeDetails=reqBody;
  }
  getPayoutdetails() {
    return this.payout;
  }
  setPayoutdetails(reqBody) {
    this.payout=reqBody;
  }
  getPayoutData() {
    return this.payoutDetails;
  }
  setPayoutData(reqBody) {
    this.payoutDetails=reqBody;
  }
  setClaimValues(reqBody){
    this.claimDetails = reqBody
  }
  getClaimValues() {
    return this.claimDetails;
  }
  getPayeeAddressDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeeaddressOnclick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeeContactDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeecontactOlick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPayeeKycDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeeckycdetailsOnclick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPanDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeepandetailsOnclick", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPALDtls(reqBody){
    return this._httpService.post(serviceURL.getPayeePersonalDtls_url, this.utils.createRequest(this.utils.getClientId(), "payeePALflag", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getKycPayoutSrCheck(reqBody){
    return this._httpService.post(serviceURL.getkycPayoutSrCheck_url, this.utils.createRequest(this.utils.getClientId(), "kycpayoutsrCheck", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getPANreq(reqBody){
    return this._httpService.post(serviceURL.getPANreq_url, this.utils.createRequest(this.utils.getClientId(), "payeeGeneratePanReq", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  maturitySubmitSR(reqBody){
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.createsubmitMaturitySR_url, this.utils.createRequest(this.utils.getClientId(), "claimReqDocGenerateSubmit", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  surrenderSubmitSR(reqBody){
    reqBody = { ...reqBody, "reqsubmittedby": this.dataService.reqSubmittedBy };
    return this._httpService.post(serviceURL.submitSurrenderSr_url, this.utils.createRequest(this.utils.getClientId(), "surrenderProcessSubmit", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      ); 
  }
  getMoneyBackDtls(reqBody){
    return this._httpService.post(serviceURL.getMoneyBack_url, this.utils.createRequest(this.utils.getClientId(), "moneyBackClaimOnLoad", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getMoneyBenefitDtls(reqBody){
    return this._httpService.post(serviceURL.getMoneyBack_url, this.utils.createRequest(this.utils.getClientId(), "moneyBackClaimBenefitDetailOnLoad", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  updatePremiumDtls(reqBody){
    return this._httpService.post(serviceURL.updatePremium_url, this.utils.createRequest(this.utils.getClientId(), "premDetails", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );   
  }
  getSurrReasonMasterData(masterDataType) {
    const url = `${serviceURL.getSurrReasonMasterData_url}/${masterDataType}`;
    return this._httpService.get(url)
      .pipe(
        map((resp) => {
          return resp
        }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
    }
    getNAVDate(reqBody) {
      return this._httpService.post(serviceURL.getNavDate_url, this.utils.createRequest(this.utils.getClientId(), "getNavDate", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    } 
    getSurrenderPayeeDtls(reqBody) {
      return this._httpService.post(serviceURL.getSurrenderPayeeDtlsOnload_url, this.utils.createRequest(this.utils.getClientId(), "surrenderpayeedetailsonload", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    }
    createSurrenderSr(reqBody) {
      return this._httpService.post(serviceURL.getSurrenderSr_url, this.utils.createRequest(this.utils.getClientId(), "surrenderProcessSubmit", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    }
    getExistingSrDtls(reqBody){
      return this._httpService.post(serviceURL.getExistingSRDetails, this.utils.createRequest(this.utils.getClientId(), "loadExistingSRPage", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );   
    }
    getPendignSrDtls(reqBody){
      return this._httpService.post(serviceURL.getPendingSRDetails, this.utils.createRequest(this.utils.getClientId(), "validatePendingSRTrack", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );   
    }
    getExistingQuoteDtls(reqBody){
      return this._httpService.post(serviceURL.getExistingQuotePayee, this.utils.createRequest(this.utils.getClientId(), "getSRQuotesValue", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );   
    }
    getExistingPayeeDtls(reqBody){
      return this._httpService.post(serviceURL.getExistingQuotePayee, this.utils.createRequest(this.utils.getClientId(), "getPayeeRedirect", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );   
    }
    setKycdata(reqBody) {
      this.kycData.push(reqBody)
      this.kycDataSubject.next(reqBody);
    }
    getKycData(){
      return this.kycData;
    }
    getSurrenderQuery(reqBody) {
      return this._httpService.post(serviceURL.getSurrenderQuery_Url, this.utils.createRequest(this.utils.getClientId(),'createSurrenderQuery',reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    getAdcatRiders(reqBody) {
      if (this.dataService.adcatRiderDtls == null) {
        reqBody.clientId = this.crypto.encryptJson(reqBody.clientId);
        return this._httpService.post(serviceURL.getAdcatRiderDtls_url, this.utils.createRequest(this.utils.getClientId(), "getClientRiderDetails", reqBody))
          .pipe(
            map((resp) => {
              if (this._httpService.isSuccessResponse(resp) && resp.body) {
                this.dataService.adcatRiderDtls = resp.body;                           
              }
              return this.dataService.adcatRiderDtls;
            }),          
            catchError((error) => {
              console.log(error);
              return of(null);
            })
          );
      } else {
        return of(this.dataService.adcatRiderDtls);
      }
      
    }
    updateRiderClicked(reqBody) {
      reqBody.policyNo = this.crypto.encryptJson(reqBody.policyNo);
      reqBody.riderName = this.crypto.encryptJson(reqBody.riderName);
      reqBody.clientId = this.crypto.encryptJson(reqBody.clientId);
      return this._httpService.post(serviceURL.updateAdcatRiderMis_url, this.utils.createRequest(this.utils.getClientId(), "getClientRiderDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    openRiderLink(link, policyNo, clientId, riderName) {
      this.updateRiderClicked({
        policyNo: policyNo.replace(/\s+/g,''),
        clientId: clientId,
        riderName: riderName
      }).subscribe();
      this.utils.openNewTab(link);
    }
    getPolicyAndRiderData(policyCardResp, riderResp) {    
      if (policyCardResp && policyCardResp.length) {
        policyCardResp.forEach(policyItem => {
          // policyItem['action'] = 'NA'
          // policyItem['actionText'] = 'NA'
          if (riderResp && riderResp.length) {
            policyItem['adcatRiderDtls'] = riderResp.find(riderItem => policyItem.POLICYNO.replace(/\s+/g,'') == riderItem.policyNo);
            policyItem['riderAvailable'] = policyItem.adcatRiderDtls && policyItem.adcatRiderDtls.riderStatus == '200' && policyItem.adcatRiderDtls.riderData && policyItem.adcatRiderDtls.riderData.riderArr && policyItem.adcatRiderDtls.riderData.riderArr.length > 0;
            policyItem['showRiderNudge'] = policyItem['riderAvailable'] && (!policyItem.action || policyItem.action == 'NA');
          } else {
            policyItem['riderAvailable'] = false;
            policyItem['showRiderNudge'] = false;
          }
          // if (loanEnqResp && loanEnqResp.length) {
          //   policyItem['loanDtls'] = loanEnqResp.find(item => policyItem.POLICYNO.replace(/\s+/g,'') == item.policyNo);
          //   policyItem['loanAvailable'] = policyItem.loanDtls && policyItem.loanDtls.LoanEligibleFlag == 'Y';
          //   policyItem['showLoanNudge'] = policyItem['loanAvailable'];
          // } else {
          //   policyItem['loanAvailable'] = false;
          //   policyItem['showLoanNudge'] = false;
          // }     
          policyItem['singleNudge'] = (policyItem.action && policyItem.action != 'NA' && !policyItem['riderAvailable'] && !policyItem['loanAvailable'])
          || (policyItem['riderAvailable'] && (!policyItem.action || policyItem.action == 'NA') && !policyItem['loanAvailable'] && policyItem.adcatRiderDtls.riderData.riderArr.length == 1)
          || (policyItem['loanAvailable'] && (!policyItem.action || policyItem.action == 'NA') && !policyItem['riderAvailable']);
        });
      }
      this.dataService.policyRiderLoanSubj.next(policyCardResp);
    }
    showAdcatRiderAmt(amt) {
      return Number(amt);
    }
    quickPaymentDtls(reqBody){
      return this._httpService.post(serviceURL.quickPaymentEnach_url, this.utils.createRequest(this.utils.getClientId(), "getenachQuickpay", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    enachSubmitcallPayment(reqBody){
      return this._httpService.post(serviceURL.enachSubmitcallPayment_url, this.utils.createRequest(this.utils.getClientId(), "enachSubmitcall", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    getBankListData(reqBody) {
      return this._httpService.post(serviceURL.getBankListData_url, this.utils.createRequest(this.utils.getClientId(), "fetchEnachBanks", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    }
    nudgeRedirectUpdate(reqBody){
      return this._httpService.post(serviceURL.nudgeRedirectUpadte_url, this.utils.createRequest(this.utils.getClientId(), 'dashboardAPI', reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    getCovidQuestionPdf(reqBody) {
      return this._httpService.post(serviceURL.covidPDF_url, this.utils.createRequest(this.utils.getClientId(), "covidphschubcall", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
    aadhaarConsent(reqBody){
      return this._httpService.post(serviceURL.captureAadhaarConsent_url, this.utils.createRequest('', 'captureAadhaarConsent', reqBody, "CUSTOMLINK"))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    getSearchMasterData() {
      return this._httpService.get(serviceURL.searchMaster_url)
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }  
    getTrendingSearches(reqBody) {
      return this._httpService.post(serviceURL.searchTrending_url, this.utils.createRequest(this.utils.getClientId(), 'searchTrending', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }  
    createSearchHistory(reqBody) {
      if (!this.dataService.checkCPadmin()) {
        return this._httpService.post(serviceURL.searchHistory_url, this.utils.createRequest(this.utils.getClientId(), 'searchHistory', reqBody))
        .pipe(
          map((resp) => {
            return resp
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    } else {
      return of({});
    }
  }
  getInvestDtls(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    return this._httpService.post(serviceURL.getInvestDtls, this.utils.createRequest(this.utils.getClientId(), "getPremiumDetails", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getPayoutsHistoryDtls(reqBody) {
    return this._httpService.post(serviceURL.getPayoutsHistoryDtls_url, this.utils.createRequest(this.utils.getClientId(), "getPayOutDtls", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getUnclaimedSuspenseAmtFlags(reqBody) {
    return this._httpService.post(serviceURL.getUnclaimedSuspenseAmtFlags_url, this.utils.createRequest(this.utils.getClientId(), "Validateclaimsr", reqBody, "TOUCHPOINT_CUSTOMERPORTAL"))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getClientMergerPANFlags(reqBody) {
    return this._httpService.post(serviceURL.getPANFlagsForMerger_url, this.utils.createRequest(this.utils.getClientId(), "getPanValidation", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  onAddPolicyClick() {
    this.getClientMergerPANFlags({ 'clientID': this.utils.getClientId() }).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp && resp.body && resp.body.pan_available === 'Y' && resp.body.pan_verified === 'Y') {
          this.router.navigate(['/my-policies', 'add-policy']);
        } else if (resp && resp.body && resp.body.pan_available && resp.body.pan_verified) {
          this.openMergerPANPrompt(resp.body);
        }
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    })
  }
  openMergerPANPrompt(flags: any) {
    let opts = {};
    if (flags.pan_available === 'N') {
      opts = {
        'message': "Kindly update your PAN number to proceed further",
        'btnLabel': "Click here to Update PAN"
      }
    } else {
      opts = {
        'message': "Kindly verify your PAN number to proceed further",
        'btnLabel': "Click here to Verify PAN"
      }
    }
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        'updatePANFlag': true,
        ...opts
      }
    });
  }
  getClientMergerList(reqBody) {
    return this._httpService.post(serviceURL.getClientMergerList_url, this.utils.createRequest(this.utils.getClientId(), "getClientMergerlist", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  createClientMerger(reqBody) {
    return this._httpService.post(serviceURL.createClientMerger_url, this.utils.createRequest(this.utils.getClientId(), "clientmerge", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  advancePayment(reqBody) {
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.action = this.crypto.encryptJson(reqBody.action);
    reqBody.popupShown = this.crypto.encryptJson(reqBody.popupShown);
    return this._httpService.post(serviceURL.advance_payment_url, this.utils.createRequest(this.utils.getClientId(), "ADVANCE_PAYMENT_CLICK", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getNotifications(reqBody) {
    return this._httpService.post(serviceURL.notifications_url, this.utils.createRequest(this.utils.getClientId(), "getNotifications", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  updateNotificationStatus(reqBody) {
    return this._httpService.post(serviceURL.updateNotificationStatus_url, this.utils.createRequest(this.utils.getClientId(), "getNotifications", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  redirectToActivateSIPage(item) {
    const activateSIreqBody = {
      "clientid": this.utils.getClientId(),
      "policyno": item.POLICYNO.replace(/\s+/g, ''),
    };
    return this.redirectActivateSI(activateSIreqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        let redirectSIurl = resp.body.url + resp.body.data;
        // window.open(redirectSIurl,'_blank');
        const popup = window.open(redirectSIurl, 'noopener noreferrer');
        if (!popup || popup == null || typeof popup.closed == 'undefined') {
          this.dialog.open(PdfViewerDialogComponent, {
            panelClass: 'pdf-view-dialog', disableClose: true,
            data: {
              // dtls: this.expInfoPopupData,
              confirmPopup: "pfdPopup",
              displayDtls: redirectSIurl
            }
          });
          //this.pdfView(redirectSIurl);
        } else {
          popup.focus();
        }
      } else {
        let trackMsgObj = { "flowType": 'Nudges on policy card', "error_code": "UEE" }
        this._httpService.apiErrorHandler(resp, null, trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getPasaDtls(reqBody) {
    reqBody.clientId = this.crypto.encryptJson(reqBody.clientId);
    return this._httpService.post(serviceURL.getPasaDtls_url, this.utils.createRequest(this.utils.getClientId(), "getPasaDtls", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  updatePasaClk(reqBody) {
    reqBody.clientId = this.crypto.encryptJson(reqBody.clientId);
    return this._httpService.post(serviceURL.updatePasaDtls_url, this.utils.createRequest(this.utils.getClientId(), "getPasaDtls", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
}
