<div class="weekly-subscription">
    <div class="weekly-subscription-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
        Subscribe for Unit Statement
        <div mat-dialog-action class="popup-close">
            <div mat-dialog-close class="popup-close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close<img src="/assets/close.svg"></div>
            <div mat-dialog-close class="popup-close-dialog mobile"></div>
        </div>
    </div>
    <div class="weekly-subscription-body" *ngIf="displayStatus">
        <form [formGroup]="weeklySubscriptionForm">
            <div class="subscription">
                <div class="toggle flex">
                    <mat-slide-toggle class="dnd-slide-toggle" formControlName="subscriptionPreference"
                        [(ngModel)]="eventChecked" (change)="displayValue($event)" [disabled]="action=='INSERT' || !emailAvailable">
                    </mat-slide-toggle>
                    <div class="dnd-slide-toggle dnd-slide-toggle-text">{{displayMessage}}</div>
                </div>
                <div class="subscription-frequency">
                    <label class="form-field-label premium-label">Mode</label>
                    <mat-form-field class="frequency-form-field" floatLabel="never">
                        <mat-placeholder>Please select a frequency</mat-placeholder>
                        <!-- <mat-select disableRipple disableOptionCentering formControlName="frequency"
                            [(ngModel)]="frequencyValue" (selectionChange)="selectFrequency($event)"
                            [disabled]="unsubscribe">
                            <mat-option  class="option" *ngFor="let item of getUnitSubscriptionMaster" value={{item.code}}>
                                {{item.value}}</mat-option>
                        </mat-select> -->
                        <input matInput readonly type="text" formControlName="frequency"
                         >
                    </mat-form-field>
                </div>
            </div>
            <div class="email-id" *ngIf="!emailAvailable">To subscribe for weekly unit statement, we require your email id. Kindly <span class="clickHere" (click)="redirectContact()">click here</span> to update the same.</div>
            <div class="flex edit-payment-btn ">
                <button class="cancel-btn" (click)="closePopup()">Cancel</button>
                <button class="submit-btn" (click)="updateSubscription()"
                    [class.disable]="!emailAvailable || !weeklySubscriptionForm.valid || !preferenceChange"
                    [disabled]="!emailAvailable || !weeklySubscriptionForm.valid || !preferenceChange">Submit</button>
            </div>
        </form>
    </div>
    <div class="weekly-subscription-body" *ngIf="!displayStatus">
        <div class="message">There is no active unit linked policy with your client ID.</div>
        <div class="flex edit-payment-btn ">
            <button class="submit-btn ok-button" (click)="closePopup()">OK</button>
        </div>
    </div>
    <div class="weekly-subscription-body align" >
            <div class="message-blue" (click)="redirectFormD02()">Request for Form D02?</div>
    </div>
    <!-- <div class="weekly-subscription-body align" *ngIf="!displayStatus">
            <div class="message-blue-error" >D02 is applicable only for In Force policies. </div>
        </div> -->
</div>