<div class="vserv-page">
    <div class="main-text">
        <mat-card class="vserv-card">
            <div class="img-div">
                <mat-card-title class="title-text">Connect with us</mat-card-title>
            </div>
            <div class="main-content img-div">
                <div class="heading-text" *ngIf="!nriVservFlag">
                    Please provide your name and policy number to receive an OTP (One Time Password) on the mobile
                    number registered with us to start the interaction
                </div>
                <div class="heading-text" *ngIf="nriVservFlag">
                    Please provide your name and policy number to receive an OTP (One Time Password) on the mobile
                    number and email id registered with us to start the interaction
                </div>
                <div class="vserv-form">
                    <form [formGroup]="vservForm" autocomplete="off">
                        <div class="form-fields">
                            <div class="form-field-group">
                                <div class="form-field">
                                    <div class="form-label">Name<sup>*</sup></div>
                                    <mat-form-field floatLabel="never" class="client-login-field login-field">
                                        <mat-placeholder>Name</mat-placeholder>
                                        <input formControlName="clientName" type="text" (keypress)="errorclear()"
                                            matInput (focusout)="clientFocusOut($event)" [readonly]="otpFlag">
                                        <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.clientName.errors?.required)">
                                      Enter client name
                                    </mat-error> 
                                     <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.clientName.errors?.pattern)">
                                      Please enter valid client ID
                                    </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-field-group">
                                <div class="form-label">Policy No<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Policy Number</mat-placeholder>
                                    <input formControlName="policyNo" type="text" (keypress)="errorclear()" matInput
                                        (focusout)="clientFocusOut($event)" maxlength="8" [readonly]="otpFlag">
                                        <mat-error class="error-message"
                                        *ngIf="(this.vservForm.controls.policyNo.errors?.required)">
                                        Enter policy number
                                      </mat-error> 
                                       <mat-error class="error-message"
                                        *ngIf="(this.vservForm.controls.policyNo.errors?.pattern)">
                                        Please enter valid policy number
                                      </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-group dob-group">
                                <div class="form-label">Date of birth<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>DOB (dd/mm/yyyy)</mat-placeholder>
                                    <input matInput type="text" [max]="today" [matDatepicker]="picker"
                                        formControlName="dob" [readonly]="otpFlag">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.dob.errors?.required)">
                                      Enter DOB
                                    </mat-error> 
                                     <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.dob.errors?.pattern)">
                                      Please enter valid DOB
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-group">
                                <div class="form-label">Language<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Language</mat-placeholder>
                                    <mat-select disableRipple disableOptionCentering class="sum-assured-option" formControlName="language">
                                        <!--(selectionChange)="valueChanged($event)"-->
                                        <mat-option *ngFor="let item of languages" [value]="item.DD_NAME">{{item.DD_NAME}}</mat-option>
                                    </mat-select>
                                    <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.language.errors?.required)">
                                      Select a language
                                    </mat-error> 
                                     <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.language.errors?.pattern)">
                                      Please select valid language
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-group" *ngIf="!otpFlag">
                                <div class="form-label captcha-label">Verify Captcha<sup>*</sup></div>
                                <mat-form-field floatLabel="never" class="client-login-field login-field">
                                    <mat-placeholder>Captcha</mat-placeholder>
                                    <input formControlName="captcha" type="text" (keypress)="errorclear()" matInput
                                        (focusout)="clientFocusOut($event)">
                                    <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)"
                                        (reloadEvent)="clearClientCaptcha()" [enteredCaptcha]="this.vservForm.controls.captcha.value">
                                    </app-captcha>
                                    <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.captcha.errors?.required)">
                                      Enter Captcha
                                    </mat-error> 
                                     <mat-error class="error-message"
                                      *ngIf="(this.vservForm.controls.captcha.errors?.pattern)">
                                      Please enter valid captcha
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="this.vservForm.controls.captcha.errors?.captchaIncorrect">
                                        Incorrect Captcha.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-group" *ngIf="otpFlag">
                                    <div class="form-label">Enter OTP<sup>*</sup> <a (click)="RegenOTP()"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a></div>
                                    <div class="otp-timer-div" *ngIf="otpFlag">
                                            Your OTP will be valid for : <span
                                            class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                                    </div>
                                    <mat-form-field floatLabel="never" class="client-login-field login-field">
                                        <mat-placeholder>Enter OTP</mat-placeholder>
                                        <input formControlName="otp" type="{{isOtpMasked ? 'password' : 'tel'}}" (keypress)="errorclear()" matInput
                                            (focusout)="clientFocusOut($event)" maxlength="6">
                                            <div class="mat-suffix-wrapper" (click)="toggleMasking()">
                                                    <img class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                                                      *ngIf="!isOtpMasked">
                                                    <img class="iris-open" mat-suffix src="assets/eye.png"
                                                      *ngIf="isOtpMasked">
                                                  </div>
                                            <mat-error class="error-message"
                                            *ngIf="(this.vservForm.controls.otp.errors?.required)">
                                            Enter OTP
                                          </mat-error> 
                                           <mat-error class="error-message"
                                            *ngIf="(this.vservForm.controls.otp.errors?.pattern)">
                                            Please enter valid OTP
                                          </mat-error>
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="footer-text">
                            This interaction /call may be monitored or recorded for quality assurances purposes. Your
                            participation in this interaction serves as express consent to be monitored or recorded.
                            Please click on agree box below to capture your photograph to help us serve you better.
                        </div>
                        <div class="agreement">
                            <mat-checkbox class="confirmation-checkbox" (change)="isChecked($event)"><span>I Agree</span></mat-checkbox>
                        </div>
                        <div class="submit-btn">
                            <button class="submit" (click)="submitForm()" *ngIf="!otpFlag" [disabled]="!(this.vservForm.valid && this.checked)" [class.disable]="!(this.vservForm.valid && this.checked)">Proceed</button>
                            <button class="submit" (click)="SubmitValidateOtp()" *ngIf="otpFlag" [disabled]="!(this.vservForm.valid && this.checked)" [class.disable]="!(this.vservForm.valid && this.checked)">Submit</button>
                        </div>
                    </form>
                    <div class="warning-text">
                        Please do not close or refresh this browser or the call may get disconnected
                    </div>
                    <div class="alternative-methods">
                        <div class="first-method">You can also explore our other digital avenues by clicking on the
                            link: <a
                                href="assets/static-cp-cms/go-digital-today.html"
                                target="_blank"
                                rel="noopener noreferrer">https://myaccount.hdfclife.com/assets/static-cp-cms/go-digital-today.html</a>
                        </div>
                        <!-- href="assets/static-cp-cms/go-digital-today.html" -->
                        <div class="first-method">You can also find the branch nearest to you: <a
                                [href]="branchLocatorUrl" target="_blank"
                                rel="noopener noreferrer">{{branchLocatorUrl}}</a></div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<ng-template #panDialog>
    <div class="vserv-popup">
        <div *ngIf="closeIcon" mat-dialog-actions (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close<img src="assets/close.svg">
            </div>
        </div>
        <div>
        <div class="vserv-popup-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">Message</div>
        <div class="popup-content popup-content-height">
            
            <div class="main-message">Dear Customer</div>
            <!-- <div *ngIf="mainMessageHtml" class="main-message" [ngClass]="{'nominee-popup-message' : proceedBtn}" [innerHTML]="mainMessageHtml"></div> -->
            <div  class="sub-message" *ngIf="!validateNriMsg">Thank you for contacting HDFC Life. Our Servicing facility is available from 10am to 6pm IST, Monday to Friday.</div>
            <div *ngIf="msgShowOnload">
                <div class="sub-message name" [innerHtml]="validateMessage"></div>
             </div>
             <!-- <div *ngIf="!msgShowOnload">
                 <div class="sub-message name">We apologize for the inconvenience, but we are currently unable to assist you. Please use our digital solution, available 24/7, for prompt policy servicing.</div>
             </div> -->
             <div class="button-div flex">
                <div class="img-text"><img src="assets/nri_qr_code.png"></div>
            </div>
            <!-- <div class="button-div flex">
                <button  matButton class="close-button newBtn" (click)="redirect()">Okay</button>
            </div> -->
        </div>
    </div>
    <div>
    
    </div>
    </div>
</ng-template>