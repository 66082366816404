import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AddnomineeService {
  private firstnameSubject = new Subject<any>();
  firstnameObs = this.firstnameSubject.asObservable();
  loginDetail: Array<any> = [];
  flagStatus: any;
  count: any;

  constructor() { }
  addnomineereqbody:Array<any>=[];
  deleteNomineeDetail:Array<any>=[];
  appointeeDetail: Array<any>=[];
  getAddNomineeReq() {
    return this.addnomineereqbody;
  }
  setAddNomineeReq(reqBody) {
    this.addnomineereqbody.push(reqBody);
    this.firstnameSubject.next(reqBody);
  }
  getDeleteNomineeDtls() {
    return this.deleteNomineeDetail;
  }
  setDeleteNomineeDtls(reqBody) {
    this.deleteNomineeDetail=reqBody;
  }
  OnDestroy() {
    this.deleteNomineeDetail = [];
  }
  getLoginDetail() {
    return this.loginDetail;
  }
  setLoginDetail(reqBody) {
    this.loginDetail = reqBody;
  }
  getFlag() {
    return this.flagStatus;
  }
  setFlag(reqBody) {
    this.flagStatus = reqBody;
  }
  setNomineeAllowed(req) {
    this.count = req
  }
  getNomineeAllowed() {
    return this.count
  }
  setAppointeeDtls(reqBody) {
    this.appointeeDetail=reqBody;
  }
  getAppointeeDtls() {
    return this.appointeeDetail;
  }
}
