import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from 'src/app/services/message/message.service';
import { Utils } from 'src/app/utils/common-functions';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http/http.service';
import { DataService } from 'src/app/services/data/data.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { InvestigatorService } from 'src/app/services/investigator/investigator.service';
import { parseTwoDigitYear } from 'moment';

@Component({
  selector: 'app-investigator-fr-screen',
  templateUrl: './investigator-fr-screen.component.html',
  styleUrls: ['./investigator-fr-screen.component.css']
})
export class InvestigatorFrScreenComponent implements OnInit {

  invtOutcomes = ['Positive', 'Negative', 'Suspicious'];
  reportSubTypes = ['Interim', 'Final'];
  claimantResponses = ['Co-operative', 'Non-Supportive', '3rd Party Intervention'];

  requirementsData: any = [];

  investigatorDetails: any;
  investigatorColumns: Array<any> = ['S.No.', 'FR Description', 'Requirement Status', 'Action', 'Uploaded Document', 'Assessor FR Comments', 'Investigator Comments'];
  rcdValue: Date;
  clientName: string;
  sumAssured: any;
  productName: any;
  eventCause: any;
  deathDate: Date;
  policyNumber: string;
  srNumber: string;
  allocDate: Date;
  covidValue: any;
  investigationType: any;
  files: any;
  fileDropped: any;
  uploadedFileList: Array<any> = [];
  byteArray: Array<any> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'gif', 'bmp'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 1;
  userDetails: any = {};
  isHNIUSer: boolean = false;
  docTypeDropdown: FormControl;
  docTypeFocusOut: boolean = false;
  docRequirementlist: Array<any> = [];
  docList: Array<any> = [];
  document: Array<any> = [];
  reqDocDesc: any;
  investigatorComment: FormControl;
  frDtlsForm: FormGroup;
  srDtlsForm: FormGroup;
  assessorComments: string;
  primaryQstnForm: FormGroup;
  indexValue: string;
  investIncome: FormControl;
  finalSubmitBtn: boolean = false;
  investCommentResp: any;
  respSucess: boolean = false;


  constructor(private fb: FormBuilder, private invetigatorService: InvestigatorService, public createSrService: CreateSRService, public httpService: HttpService, public dataService: DataService, public dialog: MatDialog, public message: MessageService, public utils: Utils) { }

  ngOnInit(): void {
    // this.getSrDtls();
    // this.getFrDtls();
    this.userDetails = this.dataService.getLoginResp();
    setTimeout(() => {
      if (this.dataService.getLoginResp()) {
        this.setIsHNIUser();
      }
      else {
        this.dataService.loginResp$.subscribe((respChanged) => {
          if (respChanged) {
            this.setIsHNIUser();
          }
        });
      }
    }, 10);
    this.frDtlsForm = this.fb.group({
      ioutcome: ['', [Validators.required]],
      // acomment: ['', [Validators.required]],
      reportType: ['', [Validators.required]],
      claimResp: ['', [Validators.required]],
    });
    // this.srDtlsForm = this.fb.group({
    //   icomment: ['', [Validators.required]],
    // });    
    this.investIncome = new FormControl('', [Validators.required]);
    this.investigatorComment = new FormControl('', [Validators.required]);
    this.docTypeDropdown = new FormControl('', [Validators.required]);
  }
  setIsHNIUser() {
    this.userDetails = this.dataService.getLoginResp();
    this.getSrDtls();
    this.getFrDtls();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
  }
  OnDocTypeFocusOut() {
    this.docTypeFocusOut = true;
  }
  closeDialog() {
    this.dialog.closeAll();
    this.byteArray = [];
    this.uploadedFileList = [];
  }
  onUploadClick(index, templateRef: TemplateRef<any>) {
    this.indexValue = '';
    this.docTypeDropdown.setValue('');
    this.investigatorComment.setValue('');
    this.indexValue = index;
    this.dialog.open(templateRef, { width: '500px', panelClass: 'doclist-popup-container', backdropClass: 'doclist-popup-backdrop', disableClose: true });
  }
  onDocumentLoad(files) {
    //this.docArray = filesArr;
    //let files = this.docArray.concat(this.imageArray);
    if (this.validateFiles(files)) {
      this.uploadedFileList = files;
      this.byteArrayGenerator(this.uploadedFileList).then(result => {
        this.byteArray = result;
      });
    }
  }
  documentSubmit() {
    this.uploadDocument(this.byteArray[0]);
    this.dialog.closeAll();
    this.byteArray = [];
    this.uploadedFileList = [];
    // this.investigatorComment.setValue('');
  }
  cancelUploadDoc(templateRef: TemplateRef<any>) {
    this.byteArray = [];
    this.uploadedFileList = [];
    this.dialog.closeAll();
    this.dialog.open(templateRef, { width: '500px', panelClass: 'doclist-popup-container', backdropClass: 'doclist-popup-backdrop', disableClose: true });
  }
  validateFiles(files) {
    let filesExceeded: boolean;
      filesExceeded = files.length > this.maxNumOfFiles;
    if (filesExceeded) {
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar']);
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name;
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
        return false;
      } else if (filesize > this.maxFileSize) {
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], 'top');
        return false;
      }
    }
    return true;
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i]);
      bytearray.push({
        name: files[i].name,
        bytestream: base64string,
      });
    }
    this.byteArray = [];
    return bytearray;
  }
  getDocListMasterData(requirementObj: any) {
    return this.createSrService.getDocumentListMasterData(requirementObj.REQUIREMENT_CD, requirementObj.SUB_ISSUE_CD, requirementObj.LOBCD, requirementObj.CLIENT_ROLE_CD, requirementObj.TYPE_OF_CHANGE_CD).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        const docListKey: string = requirementObj.TYPE_OF_CHANGE_CD ? `REQ_DOC--${requirementObj.REQUIREMENT_CD}@@${requirementObj.SUB_ISSUE_CD}@@${requirementObj.LOBCD}@@${requirementObj.CLIENT_ROLE_CD}@@${requirementObj.TYPE_OF_CHANGE_CD}` : `REQ_DOC--${requirementObj.REQUIREMENT_CD}@@${requirementObj.SUB_ISSUE_CD}@@${requirementObj.LOBCD}@@${requirementObj.CLIENT_ROLE_CD}@@`;
        if (resp.body && resp.body[docListKey]) {
          this.docRequirementlist = Object.values(resp.body[docListKey]);
          this.document = []
          for (let i of this.docRequirementlist) {
            this.document.push(i)
          }
          let arr = [];
          this.docList = [];
          for (let j of this.document) {
            if (!arr.includes(j[0])) {
              this.docList.push(j)
              arr.push(j[0]);
            }
          }
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  getSrDtls() {
    let reqData = {
      srno: this.userDetails.srno,
      investigation_id: this.userDetails.Investigation_id,
      activity_no: this.userDetails.Invest_activity_no

      // srno: "CL-91529059580",
      // investigation_id: "1-CA76A6EA0",
      // activity_no: "1-91795718321"
    }
    return this.invetigatorService.getInvestigatorSRDtls(reqData).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
       this.requirementsData = resp.body.response; 
    this.formFormation();
    // for(let item of this.requirementsData){
    //   if(item.REQUIREMENT_STATUS === "Requirement Received" && item.UPLOADED_DOCUMENT_STATUS === "ACTIVE" ){
    //     this.finalSubmitBtn = true;
    //   }
    // }
  }
  else {
      this.httpService.apiErrorHandler(resp);
    }
    }),
    catchError((error) => throwError(error));
  }
  formFormation() {
    const n = this.requirementsData.map(d => {
      return this.fb.group({
        fr_desc: new FormControl(d.REQUIREMENT_DESC),
        fr_status: new FormControl(d.REQUIREMENT_STATUS),
        action: new FormControl(d.UPLOADED_DOCUMENT_STATUS, [Validators.required]),
        upload_doc: new FormControl(d.UPLOADED_DOCUMENT),
        asses_comm: new FormControl(d.ASSESORCOMMENT),
        req_cd: new FormControl(d.REQUIREMENT_CD),
        invest_comm: new FormControl(d.INVESTIGATORCOMMENT),
        // invest_comm: new FormControl(d.DMS_ID, [Validators.required]),
      })
    });
    this.primaryQstnForm = this.fb.group({
      qaArray: this.fb.array(n),
    });
  }
  getFrDtls() {
    let reqData = {
      srno:this.userDetails.srno,
      activity_no: this.userDetails.Invest_activity_no,
    //  srno: "CL-91529059580",
    //  activity_no: "1-91795718321"

    }
    return this.invetigatorService.getInvestigatorFRDtls(reqData).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
      this.investigatorDetails = resp.body;
      for (let key of Object.keys(this.investigatorDetails)) {
        this.investigatorDetails[key] = this.investigatorDetails[key] ? this.investigatorDetails[key] : "NA";
      }
      this.rcdValue = resp.body.RCD;
      this.clientName = this.investigatorDetails.INSUREDFIRSTNAME + " " + this.investigatorDetails.INSUREDLASTNAME;
      this.sumAssured = this.investigatorDetails.SUMASSURED;
      this.productName = this.investigatorDetails.PRODUCTNAME;
      this.eventCause = this.investigatorDetails.CAUSEOFEVENT;
      this.deathDate = resp.body.DATE_OF_DEATH;
      this.policyNumber = this.investigatorDetails.POLICY;
      this.srNumber = this.userDetails.srno;
      this.allocDate = resp.body.ALLOCATION_DATE;
      this.covidValue = this.investigatorDetails.COVID;
      this.investigationType = this.investigatorDetails.INVESTIGATION_TYPE;
      this.assessorComments = 'Assessor FR Comments';
      this.respSucess = true;
      this.investigatorDetails.CLAIMNT_RESPONSE? this.frDtlsForm.get('claimResp').setValue(this.investigatorDetails.CLAIMNT_RESPONSE): this.frDtlsForm.get('claimResp').setValue(null ); 
      this.investigatorDetails.TYPE_OF_SUBMIT? this.frDtlsForm.get('reportType').setValue(this.investigatorDetails.TYPE_OF_SUBMIT): this.frDtlsForm.get('reportType').setValue(null ); 
      this.investigatorDetails.INVEST_OUTCOME? this.frDtlsForm.get('ioutcome').setValue(this.investigatorDetails.INVEST_OUTCOME): this.frDtlsForm.get('ioutcome').setValue(null ); 
    }
    else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
    catchError((error) => throwError(error));

  }
  getSubmitResp() {
    let reqData = {      
      // sr_num: "CL-91569887385",
      // policy: "24445207",
      // investigation_outcome: "Positive feedback",
      // submission_type: "final",
      // claimantresponse: "non supportive",
      // activityno: "1-91789423635"

      sr_num: this.userDetails.srno,
      claimantresponse: this.frDtlsForm.controls.claimResp.value,
      policy: this.userDetails.Policyno,
      investigation_outcome: this.frDtlsForm.controls.ioutcome.value,
      submission_type: this.frDtlsForm.controls.reportType.value,
      activityno: this.userDetails.Invest_activity_no
    }
    return this.invetigatorService.getInvestigatorSubmit(reqData).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body.Reportsubmit === 'Y') {
          this.message.openSnackBar('Sucesses Fully Submitted', 'xy', ['success-snackbar']);
        }
        else {
          this.message.openSnackBar('Something went wrong. Please try after sometime.', 'xy', ['error-snackbar'], 'top');
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  uploadDocument(file) {
    const reqBody = {
      calltype: "internal",
      policyno: this.requirementsData[this.indexValue].POLICY_NUMBER,
      srno: this.requirementsData[this.indexValue].SR_NO,
      clientid: this.requirementsData[this.indexValue].CLIENT_ID,
      bytearray: file ? file.bytestream : '',
      reqcd: this.requirementsData[this.indexValue].REQUIREMENT_CD,
      reqtranskey: this.requirementsData[this.indexValue].REQ_INVEST_TRANS_RK,
      reqstatuscd: this.requirementsData[this.indexValue].REQUIREMENT_STATUS_CD,
      rqmntsubctgycd: "REQ_STATUS_1",
      filenetfldr: this.requirementsData[this.indexValue].FILENET_FOLDER,
      doccd: this.docTypeDropdown.value,
      doctype: file ? file.name.split('.').pop() : '',
      docname: file ? file.name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : '',
      comments: this.investigatorComment.value ? this.investigatorComment.value : '',
      creationdate: parseTwoDigitYear,
    };
    return this.invetigatorService.getInvestigatorDocUpload(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body) {
          this.message.openSnackBar('Document(s) uploaded successfully', 'xy', ['success-snackbar']);
          this.primaryQstnForm.controls.qaArray['controls'][this.indexValue].controls.action.value = 'ACTIVE';
          this.primaryQstnForm.controls.qaArray['controls'][this.indexValue].controls.upload_doc.value = file ? file.name.split('.').pop() : '';
          this.primaryQstnForm.controls.qaArray['controls'][this.indexValue].controls.invest_comm.value = this.investigatorComment.value ? this.investigatorComment.value : '';      
          this.getSrDtls();
          this.finalSubmitBtn = true;
          if(this.frDtlsForm.controls.reportType.value === 'Final' &&  (this.primaryQstnForm.status === "INVALID")){
              this.finalSubmitBtn = false;
          }
          // for(let item of this.requirementsData){
          //   if(item.REQUIREMENT_STATUS === "Requirement Received" && item.UPLOADED_DOCUMENT_STATUS === "ACTIVE" ){
          //     this.finalSubmitBtn = true;
          //   }
          // }
        }
        else {
          this.message.openSnackBar('Something went wrong. Please try after sometime.', 'xy', ['error-snackbar'], 'top');
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  uploadDocuPopup( templateRef: TemplateRef<any>){
    this.dialog.open(templateRef, { width: '500px', panelClass: 'doclist-popup-container', backdropClass: 'doclist-popup-backdrop', disableClose: true });
    this.reqDocDesc = this.requirementsData[this.indexValue].REQUIREMENT_DESC;
    this.docList = [];
    this.getDocListMasterData(this.requirementsData[this.indexValue]);
  }
  commentsPopup( templateRef: TemplateRef<any>){
    this.dialog.open(templateRef, { width: '500px', panelClass: 'doclist-popup-container', backdropClass: 'doclist-popup-backdrop', disableClose: true });
}
  // onInvestIncomeClick(index,templateRef: TemplateRef<any>){
  //   this.indexValue = '';
  //   this.dialog.open(templateRef, { width: '500px', panelClass: 'doclist-popup-container', backdropClass: 'doclist-popup-backdrop', disableClose: true });
  //   this.indexValue = index;
  // }
  investIncomeSubmit(){
    let reqBody = {
      sr_num: this.userDetails.srno,
      policy: this.userDetails.Policyno,
      annualincome: this.investigatorComment.value ? this.investigatorComment.value : '',
      transkey: this.requirementsData[this.indexValue].REQ_INVEST_TRANS_RK,
      activityno: this.userDetails.Invest_activity_no

      // sr_num: "CL-91569807385",
      // policy: "24445207",
      // annualincome: "Rs"+"998877",
      // transkey: "328028",
      // activity_no: "1-91789423635"
    }
    return this.invetigatorService.getInvestIncomeDtls(reqBody).subscribe((resp) => {
       this.investCommentResp = resp.body.STATUS; 
       if(this.investCommentResp === 'SUCCESS'){
       this.message.openSnackBar('Investigator comments received successfully', 'xy', ['success-snackbar']);
       }
       this.dialog.closeAll();
       if(this.frDtlsForm.controls.reportType.value === 'Final' &&  (this.primaryQstnForm.status === "INVALID")){
        this.finalSubmitBtn = false;
       }
       this.getSrDtls();
      }),
    catchError((error) => throwError(error));
  }
  dropDownChangeFn(event){
    // this.finalSubmitBtn = true;
    if(event.value === 'Final' &&  (this.primaryQstnForm.status === "INVALID")){
     this.finalSubmitBtn = false;
    }
    else if(event.value !== 'Final'){
    this.finalSubmitBtn = true;
    }
    else if(event.value === 'Final' && this.primaryQstnForm.controls.valid){
    this.finalSubmitBtn = true;
    }
    if(this.frDtlsForm.controls.reportType.value === 'Final' && (this.primaryQstnForm.status === "INVALID")){
      this.finalSubmitBtn = false;
    }
  }
}
