import { Component, OnInit } from '@angular/core';
import { DocListPopupComponent } from '../doc-list-popup/doc-list-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpOptions } from 'src/app/utils/app-data.model';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { loginService } from 'src/app/services/login/login.service';
import { serviceURL } from 'src/environments/environment';
import { GenericDocUploadComponent } from '../generic-doc-upload/generic-doc-upload.component';

@Component({
  selector: 'app-doc-upload-redirect',
  templateUrl: './doc-upload-redirect.component.html',
  styleUrls: ['./doc-upload-redirect.component.css']
})
export class DocUploadRedirectComponent implements OnInit {
  options = { headers : new HttpHeaders({'X-csps-xrfkey': 'CSPS-HDFC_001'}) }
  token: string;
  clientid: string;

  constructor(public dialog: MatDialog, public route: ActivatedRoute, public httpService: HttpService, public utils: Utils, public dataService: DataService, public loginService: loginService, private http: HttpClient, public router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.token = this.route.snapshot.queryParamMap.get('accesstoken');
      this.clientid = this.route.snapshot.queryParamMap.get('clientid');  
      //this.init();

      this.utils.setLocalData("source", "IS", false);
      setTimeout(() => this.reinit(), 100);

    }, 0);
  }
  openDocUploadPopup() {
    let srno: string = this.route.snapshot.queryParamMap.get('srno');
    if (srno) {
      this.dialog.open(DocListPopupComponent, {
        panelClass: 'doc-list-dialog',
        backdropClass: 'doc-list-dialog-backdrop',
        disableClose: true,
        data: {
          srno: srno,
          disableClose: true,
          disableSubmitSR: true,
          source: "INSTA_SERV",
          useAgentDocsMaster: true,
        }
      });
    }    
  }


  init() {
    return this.httpService.init({}).subscribe((resp) => {
      this.httpService.initResp = resp;
      this.validateToken(this.token, this.clientid);
    }),
      catchError((error) => throwError(error));
  }
  validateToken(accesstoken, clientid) {
    let req = {
      "head": {
        "apiname": "validateToken",
        "source": "INSTASERV",
        "xrfkey": "CSPS-HDFC_001"
      },
      "body": {
        "clientId": clientid,
        "accessToken": accesstoken
      }
    }
    return this.httpService.post(serviceURL.ssoValidateToken_url, req, { headers : new HttpHeaders({'x-csps-xrfkey': 'CSPS-HDFC_001'}), observe: 'response' as const }).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {        
        this.utils.setLocalData("id", this.route.snapshot.queryParamMap.get('clientid'), false);
        this.utils.setLocalData("source", "IS", false);
        this.openDocUploadPopup();
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }

  reinit() {
    return this.httpService.reinit().subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body) {
          this.dataService.setLoginResp({...resp.body, "action": this.utils.getLocalData("action", false) });
          if (this.router.url.includes('/my-generic-upload')) {
            this.openGenericDocUploadPopup(resp.body.srno);
          } else {
            this.openDocUploadPopup();  
          }
        }        
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  openGenericDocUploadPopup(srno) {
    // let srno: string = this.route.snapshot.queryParamMap.get('srno');
     if (srno) {
      this.dialog.open(GenericDocUploadComponent, {
        panelClass: 'generic-doc-upload-dialog',
        backdropClass: 'generic-doc-upload-dialog-backdrop',
        disableClose: true,
        data: {
          srno: srno,
          disableClose: true,
          disableSubmitSR: true,
          source: "INSTA_SERV",
          useAgentDocsMaster: true,
        }
      });
    }    
  }
  
}
