import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.css']
})
export class DeclarationComponent implements OnInit {
  declarationList: Array<string> = [
    'I agree that the policy will be treated as cancelled hereafter. Neither I/my legal heir/beneficial owners nor any third party will present the policy document/claim for this policy in the future for payments or entitlements.',
    'I declare that the Company is discharged off all its liabilities mentioned in the policy document and I relinquish any further claim on the Company once the request is processed.',
    'I agree to indemnify/defend and hold harmless the Company and its officers, directors, employees, representatives, agents,against all claims, demands, actions, suits, proceedings, losses, damages, liabilities, costs, charges, expenses (including legal expenses) or obligations, which may be brought or commenced against the Company, relating to the policy document.',
    'I shall not hold the Company accountable for any loss incurred by me/us due to processing of my/our request by the Company.',
    'I hereby confirm that, I have read and understood all the policy provisions before submitting the request.',
    'I am aware of the financial implications if I surrender my policy now.'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
