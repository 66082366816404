import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-suspense-doc-upload',
  templateUrl: './suspense-doc-upload.component.html',
  styleUrls: ['./suspense-doc-upload.component.css']
})
export class SuspenseDocUploadComponent implements OnInit {
  isSubmitSuccess: Array<boolean> = [];
  uploadedFileList: Array<any> = [];
  loadedFiles: Array<any> = [];
  byteArray: Array<any> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'bmp', 'heic'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 2;
  isHNIUSer: boolean = false;  
  userDetails: any = {};
  isUploadSuccess: Array<boolean> = [];
  showRequirementList: boolean = true;
  srno: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    public createSrService: CreateSRService,
    public dialog: MatDialog, 
    public httpService: HttpService, 
    public message: MessageService, 
    public utils: Utils, 
    public dialogRef: MatDialogRef<SuspenseDocUploadComponent>, 
    public dataService: DataService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.setIsHNIUser();
  }

  setIsHNIUser() {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
  }

  onUploadClick() {
    this.loadedFiles = [];
    this.showRequirementList = false;
  }
  onDocumentLoad(files) {    
    if (this.validateFiles(this.uploadedFileList.concat(...files))) {
      this.uploadedFileList.push(...files);    
    }
  }
  validateFiles(files) {
    if (files.length > this.maxNumOfFiles) {
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar']);
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name ? files[i].name : '';
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : filename.split('.').pop() ? filename.split('.').pop().toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (filename.split('.').length > 2) {
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
        return false;
      } else if (!this.fileFormats.includes(filetype)) {
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
        return false;
      } else if (filesize > this.maxFileSize) {
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], 'top');
        return false;
      }
    }
    return true;
  }
  onSubmit() {
    if (this.validateFiles(this.uploadedFileList)) {
      if (!this.srno) {
        this.createSuspenseAmtSR();    
      } else if (this.srno && this.uploadedFileList.length > 0) {
        this.suspenseDocUpload(this.srno);
      } else {
        this.dialogRef.close(this.srno);
      }
    }    
  }  
  createSuspenseAmtSR() {
    let reqBody = {
      policyno: this.data.policyNo,
      clientid: this.utils.getClientId(),
      action: "CREATE",
      subactivity: "EXCESS_PREM_REF",
      role: "Owner",
      reqsubmittedby: this.dataService.reqSubmittedBy,
    }
    return this.createSrService.createSuspenseAmtSR(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.srno = resp.body && resp.body.srinfo && resp.body.srinfo[0] && resp.body.srinfo[0].srbasicinfo && resp.body.srinfo[0].srbasicinfo.srno ? resp.body.srinfo[0].srbasicinfo.srno : ""
        if (this.uploadedFileList.length > 0) {
          this.suspenseDocUpload(this.srno);
        } else {
          this.dialogRef.close(this.srno);
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    })
  }
  async suspenseDocUpload(srno) {
    let reqBody = {
      policy: this.data.policyNo,
      clientid: this.utils.getClientId(),
      upload: 'Y',
      srno: srno,
      image: await this.byteArrayGenerator(this.uploadedFileList)
    }
    //reqBody["image"] = await this.byteArrayGenerator(this.uploadedFileList);
    return this.createSrService.suspenseAmtDocUpload(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.dialogRef.close(srno);
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    })
  }
  deleteFile(idx) {
    if (idx < this.uploadedFileList.length) {
      let files = [];
      for (let i = 0; i < this.uploadedFileList.length; i+=1) {
        if (i == idx) {
          continue;
        }
        files.push(this.uploadedFileList[i]);
      }
      this.uploadedFileList = files;
    }
  }
  onBack() {
    if (!this.data.disableClose && this.showRequirementList) {
      this.dialogRef.close();
    } else if (!this.showRequirementList) {
      this.showRequirementList = true;
    } else if (this.data.disableClose && this.dataService.isCustomLink) {
      this.customLinkLogout();
    }    
  }
  onNext() {    
    this.showRequirementList = true;
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i]);
      bytearray.push({
        doctype: files[i].name.split('.').pop(),
        base64image: base64string,
      });
    }
    return bytearray;
  }
  customLinkLogout() {
    this.auth.logout('M');
  }


}
