import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ServiceRequestTrackComponent } from '../../customer-service/service-request-track/service-request-track.component';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { DataService } from 'src/app/services/data/data.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/utils/common-functions';

@Component({
  selector: 'app-sr-track',
  templateUrl: './sr-track.component.html',
  styleUrls: ['./sr-track.component.css']
})
export class SrTrackComponent implements OnInit {
  srTrackForm: FormGroup;
  today = new Date();
  isCaptchaValid: boolean;
  srList: Array<any> = [];
  dataSource: any;
  displayedColumns: Array<string> = ["SR_Number", "Policy_No", "Date_Logged", "Request_type"];
  showTable: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  srData: any;
  srTypeArray: { "srNum": any; "srType": any; }[];
  encodedSRNumber: any;
  clientId: any;
  srNum: any;
  policyNum: any;
  policyReadOnly: boolean;
  clientID: any;
  sr_num: any;
  displayMessage: string;
  customLinkDtls: any;

  constructor(public fb: FormBuilder, public customLinkService: CustomLinkService,
    public httpService: HttpService, public dialog: MatDialog, public createSrService: CreateSRService,
    public route: ActivatedRoute,public dataService: DataService,public router: Router,public auth: AuthService,public utils: Utils ) { 
      this.srNum = this.route.snapshot.queryParamMap.get('sr_num');
      this.dataService.loginResp$.subscribe(isChanged => {
        if(isChanged && !this.srNum) {
          this.customLinkDtls = this.dataService.getLoginResp();
          let reqBody = {
            //policyNo: this.customLinkDtls.Policyno,//this.srTrackForm.controls.policyNo.value,
            //dateOfBirth: this.customLinkDtls.DATEOFBIRTH,//moment(this.srTrackForm.controls.dob.value).format('DD/MM/YYYY'),
          }
          this.showTable = false;
          //this.startTimer = true;
          //this.startIdleTimer();
          return this.customLinkService.fetchSRDtls(reqBody).subscribe((resp) => {
            if (this.httpService.isSuccessResponse(resp)) {
                this.srList = resp.body[0].srtracklist;
                this.clientId = resp.body[0].ClientId;
                if (this.srList.length == 0) {
                  this.showTable = false;
                  this.displayMessage = "No Records Found"
                }
                else {
                  this.dataSource = new MatTableDataSource(this.srList);
                  this.showTable = true;
                  this.addPaginationAndSorting();
                }
            } else {
              this.httpService.apiErrorHandler(resp);
            }
        });  
        }
    }); 
    }

  ngOnInit(): void {
    // this.srTrackForm = this.fb.group({
    //   "policyNo": ['', [Validators.required, Validators.pattern('^[0-9]{8,8}$')]],
    //   "dob": ['', [Validators.required, CustomValidators.noFutureDates]],
    //   "captcha": ['', [Validators.required]],
    // });
    this.srNum = this.route.snapshot.queryParamMap.get('sr_num');
    if (this.srNum) {
      // this.httpService.init({}).subscribe(resp => {
      //   this.httpService.initResp = resp;
        this.goToSrTracks();
      // });
    }
    if(this.dataService.getLoginResp() && !this.srNum) {
      this.customLinkDtls = this.dataService.getLoginResp();
      this.fetchSrDetails();
    }

  }
  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
      this.srTrackForm.controls.captcha.updateValueAndValidity();
    }, 10);
  }
  clearClientCaptcha() {
    this.srTrackForm.get('captcha').setValue('');
  }
  fetchSrDetails() {
    let reqBody = {
      //policyNo: this.srTrackForm.controls.policyNo.value,
      //dateOfBirth: moment(this.srTrackForm.controls.dob.value).format('DD/MM/YYYY'),
    }
    this.showTable = false;
    //this.httpService.init({}).subscribe(resp => {
    //this.httpService.initResp = resp;
    return this.customLinkService.fetchSRDtls(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        //if (this.isCaptchaValid) {
          this.srList = resp.body[0].srtracklist;
          //this.clientId = resp.body[0].ClientId;
          if (this.srList.length == 0) {
            this.showTable = false;
            this.displayMessage = "No Records Found"
          }
          else {
            this.dataSource = new MatTableDataSource(this.srList);
            this.showTable = true;
            this.addPaginationAndSorting();
          }
        //}
        // else {
        //   this.srTrackForm.get('captcha').setErrors({ captchaIncorrect: true })
        // }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    //});
  });
  }
  addPaginationAndSorting() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }
  showSRDtls(element) {
    this.srData = element;
    this.encodedSRNumber = element.ENCODEDSR;
    this.goToSrTracks();
  }
  goToSrTracks() {
    this.dialog.open(ServiceRequestTrackComponent, {
      disableClose: true,
      panelClass: 'sr-track-popup',
      backdropClass: 'sr-track-popup-backdrop',
      data: { source: 'SRTrackCustomLink', srnumber: this.srNum ? this.srNum : this.encodedSRNumber, clientId: this.clientId }
    });
  }
  fetchPolicy() {
    let reqBody = {
      srnum: this.srNum,
    }
    return this.customLinkService.fetchPolicy(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body.length != 0) {
          this.policyNum = resp.body[0].POLICY_NUM;
          this.sr_num = resp.body[0].SR_NUM;
          this.srTrackForm.controls.policyNo.setValue(this.policyNum);
          this.policyReadOnly = true;
        }
        else if (resp.body.length == 0) {
          this.dialog.open(MessagePopupComponent, {
            width: '500px',
            panelClass: 'message-popup-panel',
            backdropClass: 'email-voc-popup', disableClose: true,
            data: {
              closeIcon: false,
              title: "Error",
              mainMessageHtml: "The link is invalid. For any further assistance, please contact us on 1860 267 9999 or email us at service@hdfclife.com.",
              cancelBtnLabel: "OK",
              source: 'voc'
            }
          });
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  validateSRPolicyDob() {
    let reqBody = {
      policyNo: this.customLinkDtls.Policyno,//this.srTrackForm.controls.policyNo.value,
      dateOfBirth: this.customLinkDtls.DATEOFBIRTH,//moment(this.srTrackForm.controls.dob.value).format('DD/MM/YYYY'),
      srnum: this.srNum,
    }
    return this.customLinkService.validateSRPolicyDob(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        //if (this.isCaptchaValid) {
          this.clientId = resp.body[0].LA_CLIENT_ID;
          if (this.clientId) {
            this.goToSrTracks();
          }
          /* this.srTrackForm.reset(); */
          /* this.srTrackForm.markAsUntouched(); */
          this.srTrackForm.controls.policyNo.setValue(null);
          this.policyReadOnly = false;
          this.srTrackForm.controls.dob.setValue(null);
          this.srTrackForm.controls.captcha.setValue(null);
          this.srTrackForm.controls.policyNo.setErrors(null);
          this.srTrackForm.controls.dob.setErrors(null);
          this.srTrackForm.controls.captcha.setErrors(null);
        //}
        // else {
        //   this.srTrackForm.get('captcha').setErrors({ captchaIncorrect: true })
        // }
      } 
      else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  clearFields() {
    this.srTrackForm.reset();
    this.showTable = false;
    this.srList = [];
  }
}
