export const searchBarMap = {
    "1": {
        "id": "myacc-decorate-highlight-autodebit",
        "desc": "Manage auto debit",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "2": {
        "id": "myacc-decorate-highlight-premfreq",
        "desc": "Edit premium payment frequency",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "3": {
        "id": "myacc-decorate-highlight-surrquote",
        "desc": "Surrender Quote",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "4": {
        "id": "myacc-decorate-highlight-servreq",
        "desc": "Service request",
        "path": ['/customer-service', 'service-requests'],
        "page": "SERVICE_REQUESTS",
        "decoration": "HIGHLIGHT",
    },
    "5": {
        "id": "myacc-decorate-highlight-rcptsandstmnts",
        "desc": "Receipts and statement",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "6": {
        "id": "myacc-decorate-highlight-dnd",
        "desc": "DND Activation",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "DND",
        "decoration": "HIGHLIGHT",
    },
    "7": {
        "id": "myacc-decorate-highlight-paypremium",
        "desc": "Pay premium",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "8": {
        "id": "myacc-decorate-highlight-revival",
        "desc": "Revive policy",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "9": {
        "id": "myacc-decorate-highlight-paymenthistory",
        "desc": "Payment history",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "PAY_HISTORY",
        "decoration": "HIGHLIGHT",
    },
    "10": {
        "id": "myacc-decorate-highlight-topup",
        "desc": "Top up",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "FUND_ALLOC",
        "decoration": "HIGHLIGHT",
    },
    "11": {
        "id": "myacc-decorate-highlight-swtfunds",
        "desc": "Switch Funds",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "FUND_ALLOC",
        "decoration": "HIGHLIGHT",
    },
    "12": {
        "id": "myacc-decorate-highlight-fundalerts",
        "desc": "Set alerts for change in fund value",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "13": {
        "id": "myacc-decorate-pulse-chngcon",
        "desc": "Update contact details",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "CONTACT",
        "decoration": "PULSE",
    },
    "14": {
        "id": "myacc-decorate-pulse-chngaddress",
        "desc": "Change in address",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "CONTACT",
        "decoration": "PULSE",
    },
    "15": {
        "id": "myacc-decorate-pulse-chngneft",
        "desc": "Update NEFT Details",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "BANK",
        "decoration": "PULSE",
    },
    "16": {
        "id": "myacc-decorate-pulse-chngkyc",
        "desc": "Update KYC",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "PERSONAL",
        "decoration": "PULSE",
    },
    "17": {
        "id": "myacc-decorate-pulse-chngpan",
        "desc": "Update PAN",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "PERSONAL",
        "decoration": "PULSE",
    },
    "18": {
        "id": "myacc-decorate-highlight-editnominee",
        "desc": "Edit Nominee",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "BENEFICIARY",
        "decoration": "HIGHLIGHT",
    },
    "19": {
        "id": "myacc-decorate-highlight-addnominee",
        "desc": "Add Nominee / Beneficiary",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "BENEFICIARY",
        "decoration": "HIGHLIGHT",
    },
    "20": {
        "id": "myacc-decorate-highlight-editalloc",
        "desc": "Edit Allocation",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "BENEFICIARY",
        "decoration": "HIGHLIGHT",
    },
    "21": {
        "id": "myacc-decorate-highlight-editla",
        "desc": "Edit/Update life assured details",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "BENEFICIARY",
        "decoration": "HIGHLIGHT",
    },
    "22": {
        "id": "myacc-decorate-highlight-policydoc",
        "desc": "Policy document ",
        "path": ['/my-downloads', 'policy-document'],
        "page": "MY_DOWNLOADS",
        "tab": "CURRENT",
        "decoration": "NONE",
    },
    "23": {
        "id": "myacc-decorate-highlight-unitstmnt",
        "desc": "Unit Statement ",
        "path": ['/my-downloads', 'unit-statements'],
        "page": "MY_DOWNLOADS",
        "tab": "CURRENT",
        "decoration": "NONE",
    },
    "24": {
        "id": "myacc-decorate-highlight-tdscert",
        "desc": "TDS Certificate",
        "path": ['/my-downloads', 'tds-certificate'],
        "page": "MY_DOWNLOADS",
        "tab": "CURRENT",
        "decoration": "NONE",
    },
    "25": {
        "id": "myacc-decorate-highlight-aps",
        "desc": "Annual Premium Statement",
        "path": ['/my-downloads', 'annual-premium-statements'],
        "page": "MY_DOWNLOADS",
        "tab": "CURRENT",
        "decoration": "NONE",
    },
    "26": {
        "id": "myacc-decorate-highlight-bonstmnt",
        "desc": "Bonus Statement",
        "path": ['/my-downloads', 'bonus-statements'],
        "page": "MY_DOWNLOADS",
        "tab": "CURRENT",
        "decoration": "NONE",
    },
    "27": {
        "id": "myacc-decorate-highlight-medreport",
        "desc": "Medical reports",
        "path": ['/my-downloads', 'medical-reports'],
        "page": "MY_DOWNLOADS",
        "tab": "MEDICAL",
        "decoration": "NONE",
    },
    "28": {
        "id": "myacc-decorate-highlight-selectpol",
        "desc": "Select a Policy",
        "path": ['/my-policies'],
        "page": "MY_POLICIES",
        "decoration": "HIGHLIGHT",
    },
    "29": {
        "id": "myacc-decorate-pulse-chngname",
        "desc": "Change in Name ",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "PERSONAL",
        "decoration": "PULSE",
    },
    "30": {
        "id": "myacc-decorate-highlight-poldtls",
        "desc": "Policy details",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "31": {
        "id": "myacc-decorate-highlight-faq",
        "desc": "FAQ",
        "path": ['/customer-service', 'faq'],
        "decoration": "HIGHLIGHT",
    },
    "32": {
        "id": "myacc-decorate-highlight-contactus",
        "desc": "Contact us",
        "path": ['/customer-service', 'contact-us'],
        "decoration": "HIGHLIGHT",
    },
    "33": {
        "id": "myacc-decorate-highlight-subsunitstmnt",
        "desc": "Subscribe unit statement",
        "path": ['/my-policies'],
        "decoration": "HIGHLIGHT",
    },
    "34": {
        "id": "myacc-decorate-highlight-wellvouch",
        "desc": "Wellness voucher",
        "path": ['/customer-service', 'wellness-voucher'],
        "decoration": "HIGHLIGHT",
    },
    "35": {
        "id": "myacc-decorate-highlight-userman",
        "desc": "User manual",
        "path": ['/my-policies'],
        "decoration": "HIGHLIGHT",
    },
    "36": {
        "id": "myacc-decorate-highlight-annuopts",
        "desc": "Change of Annuity Options",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "POL_DTLS",
        "decoration": "HIGHLIGHT",
    },
    "37": {
        "id": "myacc-decorate-highlight-swtclientid",
        "desc": "Switch client ID",
        "path": ['/my-policies'],
        "decoration": "HIGHLIGHT",
    },
    "38": {
        "id": "myacc-decorate-highlight-premred",
        "desc": "Premium redirection",
        "path": ['/my-policies', 'policy-details'],
        "page": "POLICY_DETAILS",
        "tab": "FUND_ALLOC",
        "decoration": "HIGHLIGHT",
    },   
    "114": {
        "id": "myacc-decorate-pulse-chngmailaddress",
        "desc": "Change in mailing address",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "CONTACT",
        "decoration": "PULSE",
    },
    "115": {
        "id": "myacc-decorate-pulse-chngpermaddress",
        "desc": "Change in permanent address",
        "path": ['/my-account'],
        "page": "MY_ACCOUNT",
        "tab": "CONTACT",
        "decoration": "PULSE",
    }, 
}