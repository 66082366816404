<div class="login-page-padding">
  <!-- <div class="login-marquee-text">Our new and improved customer service portal is now live with easier and simpler navigation options</div> -->
  <div class="login-page flex">
    <div class="login-left-div">
      <mat-card class="login-left-section">
        <mat-card-title class="login-title">Login</mat-card-title>
        <mat-tab-group animationDuration="0ms" class="login-tabs" [disableRipple]=true
          (selectedTabChange)="clearCheckbox($event)">
          <mat-tab label="Mobile No">
            <ng-template matTabContent>
              <form [formGroup]="loginMobileForm" (ngSubmit)="submitLoginForm(loginMobileForm.value,'mobileNo');utils.trackUserActions({'eventName': 'My Account - Mobile No - Proceed'})" autocomplete="off">
                  <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div>
                <div class="mobileno-code-container">
                    <div class="mobileno-code-wrapper flex">
                        <div class="country-code-field">
                            <div class="form-label">Country Code<sup>*</sup></div>
                            <mat-form-field floatLabel="never" class="client-login-field login-field"
                              [ngClass]="{'error-client-field': (loginClientFocusOut && mobilevalidation.countryCode.errors?.errorMsg)}">
                              <div matPrefix>+</div>
                              <input formControlName="countryCode" type="tel" (keypress)="errorclear()" matInput autocomplete="off"
                                (focusout)="clientFocusOut($event)" maxlength="6" [readonly]="hideFlagMobile" [matAutocomplete]="country">                                  
                              <mat-error class="error-message"
                                *ngIf="(loginClientFocusOut && mobilevalidation.countryCode.errors?.errorMsg)">
                                {{loginMobileForm.controls.countryCode.errors?.errorMsg}}
                              </mat-error>   
                              <mat-autocomplete #country="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option[0]">
                                    <div class="alignment">
                                        <div class="country_img" [id]="option[2]"></div>
                                        <div class="name_list">{{option[1]}} +{{option[0]}}</div>
                                    </div>
                                </mat-option>        
                            </mat-autocomplete>                                                
                            </mat-form-field>
                        </div>
                        <div class="mobileno-field">
                          <div class="form-label">Mobile No<sup>*</sup></div>
                          <mat-form-field floatLabel="never" class="client-login-field login-field"
                            [ngClass]="{'error-client-field': (loginClientFocusOut && mobilevalidation.mobileNo.errors?.required)}">
                            <mat-placeholder>Enter your Mobile No.</mat-placeholder>
                            <input formControlName="mobileNo" [decimalNumbersOnly] type="tel" (keypress)="errorclear();mobileNoKeyPress($event)" matInput
                              (focusout)="clientFocusOut($event)" maxlength="20" [readonly]="hideFlagMobile">
                              <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagMobile" (click)= "editClient('mobileNo')">
                            <mat-error class="error-message"
                              *ngIf="(loginClientFocusOut && mobilevalidation.mobileNo.errors?.required)">
                              Enter mobile number
                            </mat-error>
                            <mat-error class="error-message"
                              *ngIf="(loginClientFocusOut && mobilevalidation.mobileNo.errors?.pattern)">
                              Please enter valid mobile number
                            </mat-error>
                            <mat-error class="error-message"
                              *ngIf="(loginClientFocusOut && mobilevalidation.mobileNo.errors?.errorMsg)">
                              {{mobilevalidation.mobileNo.errors?.errorMsg}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                </div>

                  <div class="form-label dob-margin">Date of Birth<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field login-field"
                    [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                    <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                    <input formControlName="dob" type="tel" (keypress)="errorclear()" matInput [max]="today" [matDatepicker]="picker" (focusout)="dobFocusOut()"
                     [readonly]="hideFlagMobile"  (input)="onInputChanges($event)">
                     <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!hideFlagMobile">
                              <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="hideFlagMobile"></mat-datepicker> 
                     <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagMobile" (click)= "editClient('mobileNo')">
                     <mat-error class="error-message" *ngIf="loginMobileForm.controls.dob.errors?.errorMsg">{{loginMobileForm.controls.dob.errors?.errorMsg}}</mat-error>
                    </mat-form-field>
                  <!--<div class="login-check">
                    <mat-checkbox (change)="OTPpass($event,'mobileNo')" class="client-login-checkbox"
                      formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                    </mat-checkbox>-->
                    <div class="login-group login-check" *ngIf="hideFlagMobile && !displayMessage">

                      <div class="form-label-dropdown">Start your journey at</div>
                      <mat-form-field class="client-login-field login-field">
                        <mat-select formControlName="landingScreen">
                          <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                            {{opt.label}}
                            <img [src]="opt.src">
                          </mat-option>
                      </mat-select>
                    </mat-form-field>

                  <div class="otp-timer-div" *ngIf="(OTPGenerate)">
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                      {{maskMobileNo}}
                    </div>
                    <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered email id {{maskEmailId}}
                    </div>
                    <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                      The OTP has been sent to your registered mobile number {{maskMobileNo}}
                    </div>
                    Your OTP will be valid for <span
                      class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                    <a (click)="RegenOTP('mobileNo')"><img src="assets/refresh.png" class="refresh-img">Regenerate
                      OTP</a>
                  </div>
                <!--</div>-->
                <div class="otp-text"
                  *ngIf="(this.loginMobileForm.controls.mobileNo.valid) && !OTPGenerate  && !NotAvailable && displayFlag">
                  Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                <!-- <div class="OTP-generation"
                  *ngIf="(!mobilevalidation.mobileNo.errors?.required && !mobilevalidation.mobileNo.errors?.pattern) && displayFlag"
                  (click)="generateOtp('mobileNo')">
                  <button *ngIf="!OTPGenerate  && !NotAvailable">Send OTP</button>
                </div> -->
              </div>
                <!--<div class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>-->
                <div *ngIf="displayFieldsMobile">
                <div class="form-label">Enter the OTP Received<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field password-margin-mobile"
                  [ngClass]="{'error-field': (loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.pattern)}">
                  <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
                  <!-- <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />
                  <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)"
                    (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                    (focusout)="passwordFocusOut($event)" maxlength="30" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                    <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" /> -->
  
                    <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="password" matInput (focusout)="passwordFocusOut($event)"
                      maxlength="30">
                    <input class="mobile" *ngIf="(reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="text" matInput (focusout)="passwordFocusOut($event)" maxlength="30"> -->
  
                    <input *ngIf="((!clientSecureLoginCheck && !reveal))" class="mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal))"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal))" class="no-mobile mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                    <input *ngIf="((clientSecureLoginCheck && reveal))" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientOTP" type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] [decimalNumbersOnly]  maxlength="6" />
  
                    <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="password" matInput maxlength="6">
                    <input class="mobile" *ngIf="(reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="text" matInput maxlength="6"> -->
  
                    <div class="cursor-point" (click)="showPswd()">
                      <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                        *ngIf="reveal">
                      <img formcontrolame="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                        *ngIf="!reveal">
                    </div>
                    <mat-error class="error-message"
                      *ngIf="((loginPasswordFocusOut && mobilevalidation.clientPassword.errors?.required))">
                      Enter your Password
                    </mat-error>
                  </mat-form-field>
                <div [innerHTML]="statusMessage" *ngIf="errorMessage && otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
                <!-- <div class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->
                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" type="tel" (keypress)="errorclear()" matInput
                      (focusout)="captchaFocusOut($event)"  [decimalNumbersOnly]>
                  <app-captcha #mobileCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearMobileCaptcha()"
                    [enteredCaptcha]="mobilevalidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && mobilevalidation.clientCaptcha.errors?.captchaIncorrect && !(mobilevalidation.clientCaptcha.errors?.pattern) && !(mobilevalidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                  [disabled]="!(loginMobileForm.valid)" *ngIf="hideFlagMobile">Login</button>
                </div>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                [disabled]="!(this.loginMobileForm.controls.mobileNo.valid && this.loginMobileForm.controls.dob.valid && this.loginMobileForm.controls.countryCode.valid)" *ngIf="!hideFlagMobile" (click)="enableLogin('mobileNo')">Proceed</button>
              </form>
              <div [innerHTML]="statusMessage" *ngIf="errorMessage && !otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Email ID">
            <ng-template matTabContent>
              <form [formGroup]="loginEmailForm" (ngSubmit)="submitLoginForm(loginEmailForm.value,'email');utils.trackUserActions({'eventName': 'My Account - Email ID - Proceed'})" autocomplete="off">
                <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div> 
                <div class="form-label">Email ID<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && emailvalidation.emailID.errors?.required)}">
                  <mat-placeholder>Please enter your Email ID</mat-placeholder>
                  <input formControlName="emailID" (keypress)="errorclear()" type="text" matInput
                      (focusout)="clientFocusOut($event)" [readonly]="hideFlagEmail">
                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagEmail" (click)= "editClient('email')">
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && emailvalidation.emailID.errors?.required)">
                    Enter email ID.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && emailvalidation.emailID.errors?.email)">
                    Please enter valid email ID.
                  </mat-error>
                  </mat-form-field>
                  <div class="form-label dob-margin">Date of Birth<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field login-field"
                    [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                    <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                    <input formControlName="dob" type="tel" (keypress)="errorclear()" matInput [max]="today" [matDatepicker]="picker" (focusout)="dobFocusOut()"
                     [readonly]="hideFlagEmail" (input)="onInputChanges($event)">
                     <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!hideFlagEmail">
                              <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="hideFlagEmail"></mat-datepicker> 
                          <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagEmail" (click)= "editClient('email')">
                          <mat-error class="error-message" *ngIf="loginEmailForm.controls.dob.errors?.errorMsg">{{loginEmailForm.controls.dob.errors?.errorMsg}}</mat-error>
                        </mat-form-field>
                  <!--<div class="login-check">
                    <mat-checkbox (change)="OTPpass($event,'email')" class="client-login-checkbox"
                      formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                    </mat-checkbox>-->
                    <div class="login-group login-check" *ngIf="hideFlagEmail && !displayMessage">

                      <div class="form-label-dropdown">Start your journey at</div>
                      <mat-form-field class="client-login-field login-field">
                        <mat-select formControlName="landingScreen">
                          <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                            {{opt.label}}
                            <img [src]="opt.src">
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    <div class="otp-timer-div" *ngIf="(OTPGenerate)">
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                        {{maskMobileNo}}
                      </div>
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}}
                      </div>
                      <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered mobile number {{maskMobileNo}}
                      </div>
                      Your OTP will be valid for <span
                        class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                      <a (click)="RegenOTP('email')"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                    </div>
                  <!--</div>-->
                  <div class="otp-text"
                    *ngIf="((this.loginEmailForm.controls.emailID.valid) && !OTPGenerate  && !NotAvailable) && displayFlag">
                    Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                  <!-- <div class="OTP-generation"
                    *ngIf="( !emailvalidation.emailID.errors?.required && !emailvalidation.emailID.errors?.email) && displayFlag"
                    (click)="generateOtp('email')">
                    <button *ngIf="!OTPGenerate  && !NotAvailable"> Send OTP</button>
                  </div> -->
                </div>
                <div *ngIf="displayFieldsEmail">
                  <!--<div class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>-->
                  <div class="form-label">Enter the OTP Received<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field password-margin-mobile"
                    [ngClass]="{'error-field': (loginPasswordFocusOut && emailvalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && emailvalidation.clientPassword.errors?.pattern)}">
                    <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
                    
                    <!-- <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)" (keypress)="errorclear()"
                       formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" maxlength="30" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                       formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" (keypress)="errorclear()"
                      class="no-mobile" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                    <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                      class="no-mobile" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" /> -->
  
                    <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" (keypress)="errorclear()"
                      formControlName="clientPassword" type="password" matInput (focusout)="passwordFocusOut($event)"
                      maxlength="30">
                    <input class="mobile" *ngIf="(reveal && !OTPCheck)" (keypress)="errorclear()"
                      formControlName="clientPassword" type="text" matInput (focusout)="passwordFocusOut($event)"
                      maxlength="30"> -->
  
                    <input *ngIf="((!clientSecureLoginCheck && !reveal))" (keypress)="errorclear()" class="mask-pwd"
                     formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal))" (keypress)="errorclear()"
                      class="no-mobile" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal))" (keypress)="errorclear()" class="no-mobile mask-pwd"
                      formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                    <input *ngIf="((clientSecureLoginCheck && reveal))" (keypress)="errorclear()"
                      class="no-mobile" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
  
                    <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" (keypress)="errorclear()"
                      formControlName="clientOTP" type="password" matInput maxlength="6">
                    <input class="mobile" *ngIf="(reveal && OTPCheck)" (keypress)="errorclear()"
                      formControlName="clientOTP" type="text" matInput maxlength="6"> -->
  
                  <div class="cursor-point" (click)="showPswd()">
                    <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                      *ngIf="reveal">
                    <img formcontrolName="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                      *ngIf="!reveal">
                  </div>
                  <mat-error class="error-message"
                    *ngIf="((loginPasswordFocusOut && emailvalidation.clientPassword.errors?.required))">
                    Enter your Password
                  </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" *ngIf="errorMessage && otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
                <!-- <div class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->
                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" type="tel" (keypress)="errorclear()" matInput
                      (focusout)="captchaFocusOut($event)" [decimalNumbersOnly]>
                  <app-captcha #emailCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearEmailCaptcha()"
                    [enteredCaptcha]="emailvalidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && emailvalidation.clientCaptcha.errors?.captchaIncorrect && !(emailvalidation.clientCaptcha.errors?.pattern) && !(emailvalidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <button type="submit" value="login" class="login-button login-button-padding" mat-flat-button
                  [disabled]="!(loginEmailForm.valid)" *ngIf="hideFlagEmail">Login</button>
                </div>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                [disabled]="!(this.loginEmailForm.controls.emailID.valid && this.loginEmailForm.controls.dob.valid)" *ngIf="!hideFlagEmail" (click)="enableLogin('email')">Proceed</button>
              </form>
              <div class="unit-sr" *ngIf="displayFieldsEmail"><span class="unit-new-sr">Note: </span>If you are facing issue while receiving OTP, then please login with Mobile number</div>
              <div [innerHTML]="statusMessage" *ngIf="errorMessage && !otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Policy Id">
            <ng-template matTabContent>
              <form [formGroup]="loginPolicyForm" (ngSubmit)="submitLoginForm(loginPolicyForm.value,'policyNo');utils.trackUserActions({'eventName': 'My Account - Policy id - Proceed'})" autocomplete="off">
                <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div>
                <div class="form-label">Policy Id<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && policyValidation.policyNo.errors?.required)}">
                  <mat-placeholder>Please enter your Policy Id.</mat-placeholder>
                  <input formControlName="policyNo" [decimalNumbersOnly] type="tel" (keypress)="errorclear()" matInput
                      (focusout)="clientFocusOut($event)" maxlength="8" [readonly]="hideFlagPolicy">
                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagPolicy" (click)= "editClient('policyNo')">
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && policyValidation.policyNo.errors?.required)">
                    Enter policy number
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && policyValidation.policyNo.errors?.pattern)">
                    Please enter valid policy number
                  </mat-error>
                </mat-form-field>
                <div class="form-label dob-margin">Date of Birth<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                  <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                  <input formControlName="dob" type="tel" (keypress)="errorclear()" matInput [matDatepicker]="picker" (focusout)="dobFocusOut()"
                     [readonly]="hideFlagPolicy" (input)="onInputChanges($event)">
                     <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!hideFlagPolicy">
                              <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="hideFlagPolicy"></mat-datepicker> 
                     <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagPolicy" (click)= "editClient('policyNo')">
                     <mat-error class="error-message" *ngIf="loginPolicyForm.controls.dob.errors?.errorMsg">{{loginPolicyForm.controls.dob.errors?.errorMsg}}</mat-error>
                    </mat-form-field>
                  <!--<div class="login-check">
                    <mat-checkbox (change)="OTPpass($event,'mobileNo')" class="client-login-checkbox"
                      formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                    </mat-checkbox>-->
                    <div class="login-group login-check" *ngIf="(hideFlagPolicy && !specialCase) && !displayMessage">

                      <div class="form-label-dropdown">Start your journey at</div>
                      <mat-form-field class="client-login-field login-field">
                        <mat-select formControlName="landingScreen">
                          <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                            {{opt.label}}
                            <img [src]="opt.src">
                          </mat-option>
                        </mat-select>
                       </mat-form-field>

                    <div class="otp-timer-div" *ngIf="(OTPGenerate)">
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                        {{maskMobileNo}}
                      </div>
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}}
                      </div>
                      <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered mobile number {{maskMobileNo}}
                      </div>
                      Your OTP will be valid for <span
                        class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                      <a (click)="RegenOTP('policyNo')"><img src="assets/refresh.png" class="refresh-img">Regenerate
                        OTP</a>
                    </div>
                  <!--</div>-->
                  <div class="otp-text"
                    *ngIf="(this.loginPolicyForm.controls.policyNo.valid) && !OTPGenerate  && !NotAvailable && displayFlag">
                    Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                  <!-- <div class="OTP-generation"
                    *ngIf="(!policyValidation.policyNo.errors?.required && !policyValidation.policyNo.errors?.pattern) && displayFlag"
                    (click)="generateOtp('policyNo')">
                    <button *ngIf="!OTPGenerate  && !NotAvailable">Send OTP</button>
                  </div> -->
                </div>
                  <div *ngIf="displayFieldsPolicy" [ngClass]="{'password-space':specialCase}">

                    <div class="form-label-dropdown" *ngIf="specialCase">Start your journey at</div>
                    <mat-form-field class="client-login-field login-field" *ngIf="specialCase">
                      <mat-select formControlName="landingScreen">
                        <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                          {{opt.label}}
                          <img [src]="opt.src">
                        </mat-option>
                      </mat-select>
                     </mat-form-field>

                    <div class="forget-details forget-pwd" *ngIf="specialCase" (click)="goToForgotPwd()">Forgot Password?</div>
                  <div class="form-label" *ngIf="!specialCase">Enter the OTP Received<sup>*</sup></div>
                  <div class="form-label" *ngIf="specialCase">Password<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field password-margin-mobile"
                    [ngClass]="{'error-field': (loginPasswordFocusOut && policyValidation.clientPassword.errors?.required) || (loginPasswordFocusOut && policyValidation.clientPassword.errors?.pattern)}">
                    <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
                    <div *ngIf="specialCase">
                    <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)"
                      (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" maxlength="30" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)"
                      (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" maxlength="30" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                    <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                    </div>
  
                    <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="password" matInput (focusout)="passwordFocusOut($event)"
                      maxlength="30">
                    <input class="mobile" *ngIf="(reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="text" matInput (focusout)="passwordFocusOut($event)" maxlength="30"> -->
  
                    <input *ngIf="((!clientSecureLoginCheck && !reveal))" class="mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal))"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal))" class="no-mobile mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                    <input *ngIf="((clientSecureLoginCheck && reveal))" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientOTP" type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] [decimalNumbersOnly]  maxlength="6" />
  
                    <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="password" matInput maxlength="6">
                    <input class="mobile" *ngIf="(reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="text" matInput maxlength="6"> -->
  
                    <div class="cursor-point" (click)="showPswd()">
                      <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                        *ngIf="reveal">
                      <img formcontrolame="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                        *ngIf="!reveal">
                    </div>
                    <mat-error class="error-message"
                      *ngIf="((loginPasswordFocusOut && policyValidation.clientPassword.errors?.required))">
                      Enter your Password
                    </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" *ngIf="errorMessage && otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
                <!-- <div class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                </div> -->
                <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field">
                  <mat-placeholder>Please enter captcha</mat-placeholder>
                  <input formControlName="clientCaptcha" type="tel" (keypress)="errorclear()" matInput
                      (focusout)="captchaFocusOut($event)" [decimalNumbersOnly]>
                  <app-captcha #policyCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearMobileCaptcha()"
                    [enteredCaptcha]="policyValidation.clientCaptcha.value">
                  </app-captcha>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && policyValidation.clientCaptcha.errors?.required)">
                    Please enter captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && policyValidation.clientCaptcha.errors?.pattern)">
                    Invalid Captcha.
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginCaptchaFocusOut && policyValidation.clientCaptcha.errors?.captchaIncorrect && !(policyValidation.clientCaptcha.errors?.pattern) && !(policyValidation.clientCaptcha.errors?.required))">
                    Incorrect Captcha.
                  </mat-error>
                </mat-form-field>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                  [disabled]="!(loginPolicyForm.valid)" *ngIf="hideFlagPolicy">Login</button>
                </div>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                [disabled]="!(this.loginPolicyForm.controls.policyNo.valid && this.loginPolicyForm.controls.dob.valid)" *ngIf="!hideFlagPolicy" (click)="enableLogin('policyNo')">Proceed</button>
              </form>
              <div class="unit-sr" *ngIf="displayFieldsPolicy"><span class="unit-new-sr">Note: </span>If you are facing issue while receiving OTP, then please login with Mobile number</div>
              <div [innerHTML]="statusMessage" *ngIf="errorMessage  && !otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Client ID">
            <ng-template matTabContent>
              <form [formGroup]="loginClientForm" (ngSubmit)="submitLoginForm(loginClientForm.value,'client');utils.trackUserActions({'eventName': 'My Account - Client id - Proceed'})" autocomplete="off">
                <!-- <div class="forget-details" (click)="forgotPopupOpen(clientId)">Forgot Client ID?</div> -->
                <div class="forget-details" (click)="loginIssuesFaq()">Facing login Issues?</div>
                <div class="form-label">Client ID<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                  <mat-placeholder>Please enter your Client ID</mat-placeholder>
                  <input formControlName="clientID" type="text" (keypress)="errorclear()" matInput
                      (focusout)="clientFocusOut($event)" maxlength="8" [readonly]="hideFlagClientID">
                    <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagClientID" (click)= "editClient('client')">
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && clientvalidation.clientID.errors?.required)">
                    Enter valid Client ID
                  </mat-error>
                  <mat-error class="error-message"
                    *ngIf="(loginClientFocusOut && clientvalidation.clientID.errors?.pattern)">
                    Enter valid Client ID
                  </mat-error>
                </mat-form-field>
                <div class="form-label dob-margin">Date of Birth<sup>*</sup></div>
                <mat-form-field floatLabel="never" class="client-login-field login-field"
                  [ngClass]="{'error-client-field': (loginClientFocusOut && clientvalidation.clientID.errors?.required)}">
                    <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                    <input formControlName="dob" type="tel" (keypress)="errorclear()" matInput [max]="today" [matDatepicker]="picker" (focusOut)="dobFocusOut($event)"
                     [readonly]="hideFlagClientID" (input)="onInputChanges($event)">
                     <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!hideFlagClientID">
                              <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                          </mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="hideFlagClientID"></mat-datepicker> 
                     <img src="assets/edit_btn.svg" class="communicate-edit-imgs" *ngIf="hideFlagClientID" (click)= "editClient('client')">
                     <mat-error class="error-message" *ngIf="loginClientForm.controls.dob.errors?.errorMsg">{{loginClientForm.controls.dob.errors?.errorMsg}}</mat-error>
                  </mat-form-field>
                  <!--<div class="login-check">
                    <mat-checkbox (change)="OTPpass($event,'client')" class="client-login-checkbox"
                      formControlName="clientSecureLogin" [disableRipple]=true>Use OTP as password
                    </mat-checkbox>-->
                    <div class="login-group login-check" *ngIf="hideFlagClientID && !displayMessage">

                      <div class="form-label-dropdown">Start your journey at</div>
                      <mat-form-field class="client-login-field login-field">
                        <mat-select formControlName="landingScreen">
                          <mat-option class="mat-dropdownclass" *ngFor = "let opt of dropdownOptions" [value]="opt.value">
                            {{opt.label}}
                            <img [src]="opt.src">
                          </mat-option>
                        </mat-select>
                       </mat-form-field>

                    <div class="otp-timer-div" *ngIf="(OTPGenerate)">
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}} and mobile number
                        {{maskMobileNo}}
                      </div>
                      <div *ngIf="maskEmailId != 'not available' && maskMobileNo == 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered email id {{maskEmailId}}
                      </div>
                      <div *ngIf="maskEmailId == 'not available' && maskMobileNo != 'not available'" class="OTP-success"> <img src="assets/green-tick.svg">
                        The OTP has been sent to your registered mobile number {{maskMobileNo}}
                      </div>
                      Your OTP will be valid for <span
                        class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                      <a (click)="RegenOTP('client')"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                    </div>
                  <!-- </div> -->
                  <div class="otp-text"
                    *ngIf="(this.loginClientForm.controls.clientID.valid) && !OTPGenerate && !NotAvailable && displayFlag">
                    Your registered mobile number is {{maskMobileNo}} and registered email id is {{maskEmailId}}</div>
                  <!-- <div class="OTP-generation"
                    *ngIf="(!clientvalidation.clientID.errors?.required && !clientvalidation.clientID.errors?.pattern) && displayFlag"
                    (click)="generateOtp('client')">
                    <button *ngIf="!OTPGenerate && !NotAvailable">Send OTP</button>
                  </div> -->
                </div>
                <div *ngIf="displayFieldsClientID">
  <!--                 <div class="forget-details forget-pwd" (click)="goToForgotPwd()">Forgot Password?</div>
   -->                <div class="form-label">Enter the OTP Received<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field password-margin-mobile"
                    [ngClass]="{'error-field': (loginPasswordFocusOut && clientvalidation.clientPassword.errors?.required) || (loginPasswordFocusOut && clientvalidation.clientPassword.errors?.pattern)}">
                    <mat-placeholder *ngIf="!OTPCheck">*******</mat-placeholder>
  
                   <!--  <input *ngIf="((!clientSecureLoginCheck && !reveal) && !OTPCheck)"
                      (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" maxlength="30"/>
                    <input *ngIf="((!clientSecureLoginCheck && reveal) && !OTPCheck)"
                      (keypress)="errorclear()" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" maxlength="30" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal) && !OTPCheck)" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientPassword" type="password" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" />
                    <input *ngIf="((clientSecureLoginCheck && reveal) && !OTPCheck)" (keypress)="errorclear()"
                      class="no-mobile" formControlName="clientPassword" type="text" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="30" /> -->
                    
                    <!-- <input class="mobile" *ngIf="(!reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="password" matInput (focusout)="passwordFocusOut($event)"
                      maxlength="30">
                    <input class="mobile" *ngIf="(reveal && !OTPCheck)" formControlName="clientPassword"
                      (keypress)="errorclear()" type="text" matInput (focusout)="passwordFocusOut($event)" maxlength="30"> -->
  
                    <input *ngIf="((!clientSecureLoginCheck && !reveal))" class="mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
                    <input *ngIf="((!clientSecureLoginCheck && reveal))"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput maxlength="6" />
  
                    <input *ngIf="((clientSecureLoginCheck && !reveal))" class="no-mobile mask-pwd"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
                    <input *ngIf="((clientSecureLoginCheck && reveal))" class="no-mobile"
                      (keypress)="errorclear()" formControlName="clientOTP" [decimalNumbersOnly] type="tel" matInput
                      (focusout)="passwordFocusOut($event)" [matKeyboard] maxlength="6" />
  
                    <!-- <input class="mobile" *ngIf="(!reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="password" matInput maxlength="6">
                    <input class="mobile" *ngIf="(reveal && OTPCheck)" formControlName="clientOTP"
                      (keypress)="errorclear()" type="text" matInput maxlength="6"> -->
  
                    <div class="cursor-point" (click)="showPswd()">
                      <img formcontrolName="clientPassword" class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                        *ngIf="reveal">
                      <img formcontrolName="clientPassword" class="iris-open" mat-suffix src="assets/eye.png"
                        *ngIf="!reveal">
                    </div>
                    <mat-error class="error-message"
                    *ngIf="((loginPasswordFocusOut && clientvalidation.clientPassword.errors?.required))">
                    Enter your Password
                  </mat-error>
                </mat-form-field>
                <div [innerHTML]="statusMessage" *ngIf="errorMessage && otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
                <!-- <div class="login-check-secure">
                  <mat-checkbox (change)="showKeyboard($event)" [checked]="isChecked" class="client-login-checkbox no-mobile" [disableRipple]=true>For
                    secure login, use virtual keyboard</mat-checkbox>
                  </div> -->
  
                  <div class="form-label captcha-label">Captcha<sup>*</sup></div>
                  <mat-form-field floatLabel="never" class="client-login-field login-field">
                    <mat-placeholder>Please enter captcha</mat-placeholder>
                    <input formControlName="clientCaptcha" (keypress)="errorclear()" type="tel" matInput
                      (focusout)="captchaFocusOut($event)" [decimalNumbersOnly]>
                    <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearClientCaptcha()"
                      [enteredCaptcha]="clientvalidation.clientCaptcha.value">
                    </app-captcha>
                    <mat-error class="error-message"
                      *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.required)">
                      Please enter captcha.
                    </mat-error>
                    <mat-error class="error-message"
                      *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.pattern)">
                      Invalid Captcha.
                    </mat-error>
                    <mat-error class="error-message"
                      *ngIf="(loginCaptchaFocusOut && clientvalidation.clientCaptcha.errors?.captchaIncorrect && !(clientvalidation.clientCaptcha.errors?.pattern) && !(clientvalidation.clientCaptcha.errors?.required))">
                      Incorrect Captcha.
                    </mat-error>
                  </mat-form-field>
                 <button type="submit" class="login-button login-button-padding" mat-flat-button
                    [disabled]="!(loginClientForm.valid)" *ngIf="hideFlagClientID">Login</button>
                  </div>
                <button type="submit" class="login-button login-button-padding" mat-flat-button
                  [disabled]="!(this.loginClientForm.controls.clientID.valid && this.loginClientForm.controls.dob.valid)" *ngIf="!hideFlagClientID" (click)="enableLogin('client')">Proceed</button>
              </form>
              <div class="unit-sr" *ngIf="displayFieldsClientID"><span class="unit-new-sr">Note: </span>If you are facing issue while receiving OTP, then please login with Mobile number</div>
              <div [innerHTML]="statusMessage" *ngIf="errorMessage && !otpValidateErrorMsg" [ngClass]="{'user-message': errorMessage , 'status-message': !errorMessage || validationMessage , 'otp-error-message':otpErrorMessage ,'display-message': displayMessage}"></div>
            </ng-template>
          </mat-tab>
  
        </mat-tab-group>
        <!-- <div class="orlogin-text">Or Login with</div>
        <div class="login-other-options flex-center">
          <div class="login-other-img">
            <img src="assets/facebook_circle.svg">
          </div>
          <div class="login-other-img">
            <img src="assets/google.svg">
          </div>
        </div> -->
     <!--  <div class="register-login-btns flex-center" *ngIf="displayFieldsClientID || displayFieldsEmail || displayFieldsMobile || displayFieldsPolicy">
           <div class="register-login-btn" (click)="goToNewUser()">
            <div class="register-login-title">Register</div>
            <div class="register-login-text">First time user?</div>
          </div>  -->
        <!--   <div class="register-login-btn" >
            <a [href]="groupCustromer" target="_blank" rel="noopener noreferrer">
              <div class="register-login-title">Login</div>
              <div class="register-login-text">Group customers</div>
            </a>
          </div> 
        </div>  -->
      </mat-card>
    </div>
    <div class="login-right-div">
      <mat-card class="login-right-section">
        <div class="connect-head">
          Now chat with our official
          <span>WhatsApp bot</span> Etty in your preferred language!
        </div>
        <div class="language-list">
          <span class="language-title">Languages available are</span>
          <ul class="languages flex-center">
            <li>
              <a>English</a>
            </li>
            <li>
              <a>Hindi</a>
            </li>
            <li>
              <a>Bengali</a>
            </li>
            <li>
              <a>Telugu</a>
            </li>
            <li>
              <a>Tamil</a>
            </li>
            <li>
              <a>Marathi</a>
            </li>
            <li>
              <a>Gujarati</a>
            </li>
            <li>
              <a>Punjabi</a>
            </li>
            <li>
              <a>Malayalam</a>
            </li>
            <li>
              <a>Kannada</a>
            </li>
          </ul>
        </div>
        <div class="whatsapp-info flex-center">
          <div class="no-mobile">
            <div class="query-icon">
              <img src="assets/customer_care.svg" alt="Customer Care">
            </div>
          </div>
          <div class="query-details">
            <div class="query-details-text">
              WhatsApp your policy queries by
              simply texting a <span>"Hi"</span> on
            </div>
            <div class="whatsapp-number flex" (click)="onBtnClick()">
              <a>+91 82918 90569</a>
              <img src="assets/whatsapp_white.svg">
            </div>
          </div>
            <img class="qr-code" src="assets/QR_code.png" (click)="onBtnClick()">
          </div>
  
          <div class="useful-links">
            <div class="flex useful-title-div">
              <div class="useful-links-title">USEFUL LINKS</div>
              <img class="useful-link-img" src="assets/link.svg">
            </div>
            <div class="useful-links-list-div">
              <div class="useful-links-list" *ngFor="let item of redirectLinks">
                <div class="link-text" *ngFor="let subitem of item.links" (click)="utils.trackUserActions({'eventName': 'My Account - '+subitem.label})"><a href={{subitem.link}}
                    target="_blank" rel="noopener noreferrer">{{subitem.label}}</a></div>
              </div>
            </div>
          </div>
          <div class="query-text">
            For any query regarding policy payment you can write us at <span><a href="mailto:service@hdfclife.com" target="_blank" rel="noopener noreferrer" class="contact-value">service@hdfclife.com</a></span>
          </div>
        </mat-card>
      </div>
  
    </div>
  
    <!-- <mat-card class="login-bottom flex">
      <div class="safety-details flex-center">
        <div class="safety-image">
          <img src="assets/safety_image.png">
        </div>
        <div class="safety-content">
          <div class='safety-span'><span class="strong-red">YOUR SAFETY</span> IS OUR FIRST PRIORITY</div>
          <div class="safety-span-two">Access our digital self-serve options 24/7 at your convenience</div>
          <a href="assets/static-cp-cms/go-digital-today.html" target="_blank" rel="noopener noreferrer">
            <button matButton class="knowmore-button">Know More<img class="knowmore-button-image"
                src="assets/angle_down_red_sml.svg"></button></a>
        </div>
      </div>
     <span class="fill-remaining-space"></span>
    <div class="faq-details">
        <div class="faq-content">
          FAQs for HDFC Life Customers during the COVID-19 pandemic
        </div>
          <button matButton class="knowmore-button" (click)="downloadCovidPdf()">Download &#8250;</button>
      </div> 
    </mat-card> -->
    <ng-template #clientId>
      <mat-dialog-content>
        <div class="forgot-id-popup">
          <div mat-dialog-action class="popup-close">
            <div mat-dialog-close class="popup-close-dialog no-mobile">Close<img src='/assets/close.svg'></div>
          </div>
          <div class="flex-center popup-header">
            <span>To know your Client ID</span>
            <div mat-dialog-close class="popup-close-dialog mobile"></div>
          </div>
          <div class="popup-content">
            <div class="instructions">
              <div class="method-one">
                You can connect on our Digital Platform WhatsApp<span class="bold-text"> "Etty"</span> or save
                the contact and message us on <span class="bold-text">8291890569</span> to know your clientID
              </div>
              <div class="method-two">
                You can <span class="click-here">click here</span> and chat with our Virtual Assistant <span
                  class="bold-text">Elle</span> to
                know your clientID
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
    </ng-template>
  </div>
