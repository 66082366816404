import { Component, OnInit,Output, HostListener, EventEmitter, HostBinding, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.css']
})
export class DragDropComponent implements OnInit {
  files: any[] = [];
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('dragover',['$event']) ondragover(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver=true;
  }
  @HostListener('dragleave',['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }
  @HostListener('drop',['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver= false;
    this.files = evt.dataTransfer.files;
    if(this.files.length > 0 ) {
      this.fileDropped.emit(this.files);
    }
  }
  onFileDropped($event) {
    for(const item of $event) {
      this.files.push(item);
    }
  }
  onFileChanged(event) {
    this.files = event.target.files;
    if(this.files.length > 0 ) {
      this.fileDropped.emit(this.files);
    }
  }
}
