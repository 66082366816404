import { Component, OnInit } from '@angular/core';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { DataService } from 'src/app/services/data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-offline-aadhar-redirect',
  templateUrl: './offline-aadhar-redirect.component.html',
  styleUrls: ['./offline-aadhar-redirect.component.css']
})
export class OfflineAadharRedirectComponent implements OnInit {

  constructor(public createSrService: CreateSRService, public httpService: HttpService, public dataService: DataService, public router: Router, public route: ActivatedRoute, public utils: Utils, public message: MessageService) { }

  ngOnInit(): void {
    this.getOfflineAadharQueryParams();
  }
  getOfflineAadharQueryParams() {
    let reqid = this.route.snapshot.queryParamMap.get('reqid');
    let status = this.route.snapshot.queryParamMap.get('status');
    let ocrDocRk = this.route.snapshot.queryParamMap.get('ocrdocrk');
    let ekycName = this.route.snapshot.queryParamMap.get('ekycName');
    let ekycDob = this.route.snapshot.queryParamMap.get('ekycDob');
    if (status == 'success' && reqid && ocrDocRk) {      
      setTimeout(() => {
        let reqBody = {
          clientid: this.utils.getClientId(),
          reqid: reqid
        };
        this.getOfflineAadharDtls(reqBody, ocrDocRk);
      }, 1000);
    } else if (status && status != 'success' && ekycName == 'N') {
      this.message.openSnackBar("Aadhaar verification failed. Name does not match with system details", 'xy', ['error-snackbar']);
      this.router.navigate(["/my-account"]);
    } else if (status && status != 'success' && ekycDob == 'N') {
      this.message.openSnackBar("Aadhaar verification failed. Date of Birth does not match with system details", 'xy', ['error-snackbar']);
      this.router.navigate(["/my-account"]);
    } else {
      this.message.openSnackBar('Something went wrong. Please try again after sometime', 'xy', ['error-snackbar']);
      this.router.navigate(["/my-account"]);
    }
  }
  getOfflineAadharDtls(reqBody, ocrDocRk) {
    return this.createSrService.getOfflineAadharAddrDtls(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body) {
          let addressDtls = {
            "ocrdocrk": ocrDocRk,
            "eKYC_editRequestPanelID": resp.body.eKYC_editRequestPanelID,
            "communicationaddflg": resp.body.communication_addr_flag,
            "permanentaddflg": resp.body.permanent_addr_flag,
            "houseNo": resp.body.house,
            "street": resp.body.street,
            "area": resp.body.postoffice,
            "pin": resp.body.pincode,
            "city": resp.body.subdistrict,
            "state": resp.body.state,
            "country": resp.body.country ? resp.body.country : "India",
          };
          if (addressDtls.houseNo && addressDtls.houseNo.includes(':')) {
            addressDtls.houseNo = addressDtls.houseNo.replace(/:+/g,'').trim();
          }
          if (addressDtls.street && addressDtls.street.includes(':')) {
            addressDtls.street = addressDtls.street.replace(/:+/g,'').trim();
          }
          if (addressDtls.area && addressDtls.area.includes(':')) {
            addressDtls.area = addressDtls.area.replace(/:+/g,'').trim();
          }
          this.dataService.setOfflineAadharAddrDtls(addressDtls);
          this.router.navigate(["/my-account"], { queryParams: { redirect: 'ekyc' } });
        }
      } else {
        this.httpService.apiErrorHandler(resp);
        this.router.navigate(["/my-account"]);
      }
    });
  }

}
