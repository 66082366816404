import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class InvestigatorService {

  constructor(public _httpService: HttpService, public utils: Utils,public crypto: EncryptDecryptService) { }

  getInvestigatorDetails(reqBody) {
    let policyno = reqBody.policyno;
    reqBody.invt_loginid = this.crypto.encryptJson(reqBody.invt_loginid);
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.PAN = this.crypto.encryptJson(reqBody.PAN);
    return this._httpService.post(serviceURL.investigatorotp_url, this.utils.createRequest(policyno, 'InvestigatorSendOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  validateInvestigatorOtp(reqBody) {
    let policyno = reqBody.POLICYNO;
    reqBody.otpEntered = this.crypto.encryptJson(reqBody.otpEntered);
    reqBody.trans_id = this.crypto.encryptJson(reqBody.trans_id);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    reqBody.POLICYNO = this.crypto.encryptJson(reqBody.POLICYNO);    
    return this._httpService.post(serviceURL.investigatorvalotp_url, this.utils.createRequest(policyno, 'InvestigatorValidateOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  getInvestigatorFRDetails(reqBody) {
    return this._httpService.post(serviceURL.investigatorFr_url, this.utils.createRequest(reqBody.policyno, 'getfrdetails', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  getInvestigatorSRDetails(reqBody) {
    return this._httpService.post(serviceURL.investigatorSr_url, this.utils.createRequest(reqBody.policyno, 'getsrdetails', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getInvestigatorSRDtls(reqBody) {
    return this._httpService.post(serviceURL.investigatorSrDtls_url, this.utils.createRequest(reqBody.policyno, 'getSRDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getInvestigatorFRDtls(reqBody) {
    return this._httpService.post(serviceURL.investigatorFrDtls_url, this.utils.createRequest(reqBody.policyno, 'getFRDtls', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getInvestigatorSubmit(reqBody) {
    return this._httpService.post(serviceURL.investigatorSumbit_url, this.utils.createRequest(reqBody.policyno, 'investigationSubmit', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getInvestigatorDocUpload(reqBody) {
    return this._httpService.post(serviceURL.investigatorDocUpload_url, this.utils.createRequest(reqBody.policyno, 'InvestigatorDocUpload', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getInvestIncomeDtls(reqBody) {
    return this._httpService.post(serviceURL.investIncome_url, this.utils.createRequest(reqBody.policyno, 'insertInvestincome', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
}
