<div class="new-user-page">
    <div class="new-user-wrapper">
        <mat-card class="new-user-card">
            <div class="card-heading">New User Registration</div>
            <mat-horizontal-stepper [@.disabled] = "true" linear labelPosition="bottom"  #stepper>
                <mat-step [stepControl]="policyNoForm" label="Enter Policy Number" state="1">
                    <div class="step-headings">Enter Policy Number and date of birth</div>
                    <form [formGroup]="policyNoForm" (ngSubmit)="submitPolicyNoForm()">
                        <div class="form-fields-wrapper flex">
                            <div class="single-field">
                                <div class="form-label">Enter Policy number</div>
                                <mat-form-field floatLabel="never" class="form-field first-field"
                                    [ngClass]="{'error-field': (policyFocus && policyValidation.policyNo.errors?.required) || (policyFocus && policyValidation.policyNo.errors?.pattern)}">
                                    <mat-placeholder>Please enter your Policy Number</mat-placeholder>
                                    <input formControlName="policyNo" decimalNumbersOnly (keypress)="clearError()" type="tel" matInput  (focusout)="policyFocusOut()" maxlength="8">
                                    <mat-error class="error-message" *ngIf="(policyFocus && policyValidation.policyNo.errors?.required)">
                                        Enter Policy Number
                                    </mat-error>
                                    <mat-error class="error-message" *ngIf="(policyFocus && policyValidation.policyNo.errors?.pattern)">
                                        Invalid Policy Number.
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                            <div class="single-field">
                                <div class="form-label">Date of Birth (DD/MM/YYYY)</div>
                                <mat-form-field floatLabel="never" class="form-field date-field"
                                    [ngClass]="{'error-field': (dobFocus && policyValidation.dob.errors?.required)}">
                                    <mat-label>DD/MM/YYYY</mat-label>
                                    <input matInput formControlName="dob" type="text" [matDatepicker]="picker" [max]="today" (focus)="picker.open()" (focusout)="dobFocusOut()">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error class="error-message" *ngIf="(dobFocus && policyValidation.dob.errors?.required)">
                                        Enter Date of Birth
                                    </mat-error>                                    
                                </mat-form-field>
                            </div>
                        </div>
                        <div [innerHTML]="statusMessage" class="status-message"></div> 
                        <button mat-flat-button class="primary-btn" (click)="getCreateuserDetails(stepper)" type="submit" [disabled]="!policyNoForm.valid">Submit</button>
                        <button mat-flat-button class="primary-btn" (click)="redirectToLogin()">Cancel</button>
                    </form>
                </mat-step>
                <mat-step [stepControl]="otpForm" label="Enter OTP" state="1">
                    <div class="otp-sent-msg" *ngIf="OtpFlag">
                        <img src="assets/green-tick.svg">
                        OTP has been sent to your registered {{MaskmobileNumber}} {{Maskemail}}
                    </div>            
                    <div class="otp-timer-div" *ngIf="OtpFlag">
                        Your OTP will be valid for <span class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>                  
                        <a (click)="RegenOTP()" *ngIf="(timerSec==0)"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                    </div>
                    <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
                        <div class="otp-field">
                            <div class="form-label otp-label">OTP<sup>*</sup></div>
                            <mat-form-field floatLabel="never" class="form-field"
                                [ngClass]="{'error-field': (otpFocus && otpValidation.otp.errors?.required) || (otpFocus && otpValidation.otp.errors?.pattern)}">
                                <mat-placeholder>Enter the OTP received</mat-placeholder>
                                <input formControlName="otp" type="tel" decimalNumbersOnly (keypress)="clearError()" matInput (focusout)="otpFocusOut()" maxlength="6">
                                <mat-error class="error-message" *ngIf="((buttonClick || otpFocus) && otpValidation.otp.errors?.required)">
                                    Enter valid OTP
                                </mat-error>
                            </mat-form-field> 
                        </div>                   
                        <div [innerHTML]="statusMessage" class="status-message"></div> 
                        <button class="primary-btn" (click)="validateOtp(stepper)">Submit </button>
                        <button class="primary-btn" (click)="redirectToLogin()">Cancel</button>
                    </form>
                </mat-step>
                <mat-step [stepControl]="scrtQtnsForm" label="Set Secret Question" state="1">
                    <div class="step-wrapper pad-top-15 flex">
                        <div class="left-section">
                            <div class="step-headings">Please set your secret question</div>
                            <form [formGroup]="scrtQtnsForm" (ngSubmit)="submitScrtQtnsForm()">
                                <mat-form-field appearance="standard" class="form-field select-menu first-select" [ngClass]="{'error-field': (firstQuesFocus && qtnValidation.firstQtn.errors?.required)}">
                                    <mat-select formControlName="firstQtn" (selectionChange)="populateSecondQtn($event)" disableRipple class="select-qtn" panelClass="new-user-dropdown">
                                        <mat-option *ngFor="let item of secretQues" [value]="item" (focusout)="firstQtnFocusOut()">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                    <img mat-suffix src="assets/angle_down.svg">
                                    <mat-error class="error-message" *ngIf="(firstQuesFocus && qtnValidation.firstQtn.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                </mat-form-field>
                                <div class="form-label">Your Answer</div>   
                                <mat-form-field floatLabel = "never" class="form-field" [ngClass]="{'password-field': isFirstAnsMasked,'error-field': (firstAnsFocus && qtnValidation.firstAns.errors?.required) || (firstAnsFocus && qtnValidation.firstAns.errors?.minlength) || (firstAnsFocus && qtnValidation.firstAns.errors?.maxlength)}">
                                    <mat-placeholder>Please enter your Answer</mat-placeholder>
                                    <input formControlName="firstAns" type="password" autocomplete="new-password" #firstAns matInput (focusout)="firstAnsFocusOut()">
                                    <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #ansImgFirst (click)="toggleFirstAns(ansImgFirst, firstAns)">
                                    <mat-error class="error-message" *ngIf="(firstAnsFocus && qtnValidation.firstAns.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                    <mat-error class="error-message" *ngIf="(firstAnsFocus && ((qtnValidation.firstAns.errors?.minlength) || (qtnValidation.firstAns.errors?.maxlength)))">
                                        Must be from 3 to 25 characters long.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="standard" class="form-field select-menu" [ngClass]="{'error-field': (secondQuesFocus && qtnValidation.secondQtn.errors?.required)}">
                                    <mat-select formControlName="secondQtn" disableRipple class="select-qtn" panelClass="new-user-dropdown">
                                        <mat-option *ngFor="let item of secretQues2" [value]="item" (focusout)="secondQtnFocusOut()">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                    <img mat-suffix src="assets/angle_down.svg">
                                    <mat-error class="error-message" *ngIf="(secondQuesFocus && qtnValidation.secondQtn.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                </mat-form-field>
                                <div class="form-label">Your Answer</div>   
                                <mat-form-field floatLabel = "never" class="form-field" [ngClass]="{'password-field': isSecondAnsMasked,'error-field': (secondAnsFocus && qtnValidation.secondAns.errors?.required) || (secondAnsFocus && qtnValidation.secondAns.errors?.minlength) || (secondAnsFocus && qtnValidation.secondAns.errors?.maxlength)}">
                                    <mat-placeholder>Please enter your Answer</mat-placeholder>
                                    <input formControlName="secondAns" type="password" autocomplete="new-password" #secondAns matInput (focusout)="secondAnsFocusOut()">
                                    <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #ansImgSecond (click)="toggleSecInputType(ansImgSecond, secondAns)">
                                    <mat-error class="error-message" *ngIf="(secondAnsFocus && qtnValidation.secondAns.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                    <mat-error class="error-message" *ngIf="(secondAnsFocus && (qtnValidation.secondAns.errors?.minlength) || (qtnValidation.secondAns.errors?.maxlength))">
                                        Must be from 3 to 25 characters long.
                                    </mat-error>
                                </mat-form-field>
                                <div [innerHTML]="statusMessage" class="status-message"></div> 
                                <button class="primary-btn" type="submit" matStepperNext [disabled]="!scrtQtnsForm.valid" (click)="submitSecretQForm(stepper)">Submit</button>
                                <button class="primary-btn" (click)="redirectToLogin()">Cancel</button>
                            </form>
                        </div>
                        <div class="right-section">
                            <div class="list-heading">Instructions</div>
                            <div class="list-item flex" *ngFor="let item of instruction.stepThree">
                                <img src="assets/li-style.svg" class="list-item-img">
                                <span>{{item}}</span>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step [stepControl]="createPwdForm" label="Create Password" state="1">
                    <div class="step-wrapper flex">
                        <div class="left-section">
                            <div class="step-headings">Create New Password</div>
                            <form [formGroup]="createPwdForm" (ngSubmit)="submitPwdForm()">
                                <div class="form-label pad-top-15">Password</div>   
                                <mat-form-field floatLabel = "never" class="form-field pwd-first-field" [ngClass]="{'password-field': isFirstMasked,'error-field': (firstFocus && createPwdValidation.pwd.errors?.required) || (firstFocus && createPwdValidation.pwd.errors?.errorMsg)}">
                                    <mat-placeholder>Please enter your Password</mat-placeholder>
                                    <input formControlName="pwd" *ngIf="!clientSecureLoginCheck" type="{{newPasswordType}}" (keypress)="clearError()" #first matInput (focusout)="firstFocusOut()" (paste)="blockPaste($event)">
                                    <input formControlName="pwd"  *ngIf="clientSecureLoginCheck" type="{{newPasswordType}}" (keypress)="clearError()" #first matInput  matKeyboard (focusout)="firstFocusOut()" (paste)="blockPaste($event)">
                                    <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #pwdImgFirst (click)="toggleFirstInputAnsType(pwdImgFirst)">
                                    <mat-error class="error-message" *ngIf="(firstFocus && createPwdValidation.pwd.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                    <mat-error class="error-message" *ngIf="(firstFocus && createPwdValidation.pwd.errors?.errorMsg)">
                                        {{createPwdValidation.pwd.errors?.errorMsg}}
                                    </mat-error>
                                </mat-form-field>
                                <div class="form-label pad-top-15">Confirm Password</div>   
                                <mat-form-field floatLabel = "never" class="form-field" [ngClass]="{'password-field': isSecondMasked,'error-field': (secondFocus && createPwdValidation.confirmPwd.errors?.required) || (secondFocus && createPwdValidation.confirmPwd.errors?.errorMsg)}">
                                    <mat-placeholder>Please enter your Password</mat-placeholder>
                                    <input formControlName="confirmPwd" *ngIf="!clientSecureLoginCheck" type="{{confirmPasswordType}}" (keypress)="clearError()" #second matInput (focusout)="secondFocusOut()" (paste)="blockPaste($event)">
                                    <input formControlName="confirmPwd" *ngIf="clientSecureLoginCheck" type="{{confirmPasswordType}}" (keypress)="clearError()" #second matInput matKeyboard (focusout)="secondFocusOut()" (paste)="blockPaste($event)">
                                    <img mat-suffix src="assets/eye_closed.svg" class="eye-img" #pwdImgSecond (click)="toggleSecInputAnsType(pwdImgSecond)">
                                    <mat-error class="error-message" *ngIf="(secondFocus && createPwdValidation.confirmPwd.errors?.required)">
                                        This field is required.
                                    </mat-error>
                                    <mat-error class="error-message" *ngIf="(secondFocus && createPwdValidation.confirmPwd.errors?.errorMsg)">
                                        {{createPwdValidation.confirmPwd.errors?.errorMsg}}
                                    </mat-error>
                                </mat-form-field>
                                <div class="checkbox-virtual-div">
                                    <mat-checkbox (change)="showKeyboard($event)" class="checkbox-virtual no-mobile">Use virtual keyboard</mat-checkbox>
                                </div>                
                                <div [innerHTML]="statusMessage" class="status-message"></div>                         
                                <button class="primary-btn" type="submit" matStepperNext [disabled]="!createPwdForm.valid" (click)="submitCreatePwdForm()">Submit</button> 
                                <button class="primary-btn" (click)="redirectToLogin()">Cancel</button>                                        
                            </form>
                            <div class="orlogin-text">Or Login with</div>
                            <div class="login-other-options flex-center">
                                <div class="login-other-img">
                                    <img src="assets/facebook_circle.svg">
                                </div>
                                <div class="login-other-img second-img">
                                    <img src="assets/google.svg">
                                </div>
                            </div>
                            <div class="step-headings disclaimer">Disclaimer</div>
                            <div class="checkbox-virtual-div pad-top-15">
                                <mat-checkbox class="checkbox-virtual checkbox-disclaimer">I will not hold HDFC Life responsible for any risk associated with login via my Facebook/Twitter/Google id.</mat-checkbox>
                            </div>  
                        </div>
                        <div class="right-section">
                            <div class="list-heading">Instructions</div>
                            <div class="list-item flex" *ngFor="let item of instruction.stepFour">
                                <img src="assets/li-style.svg" class="list-item-img">
                                <span>{{item}}</span>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <ng-template matStepperIcon="1"><img src="assets/cross_white.svg" class="stepper-icon"></ng-template>                
            </mat-horizontal-stepper>
        </mat-card>
    </div>
</div>