import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from 'src/app/utils/common-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CameraComponent } from '../../shared/components/camera/camera.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agent-doc-upload',
  templateUrl: './agent-doc-upload.component.html',
  styleUrls: ['./agent-doc-upload.component.css']
})
export class AgentDocUploadComponent implements OnInit {
  agentForm: FormGroup
  isCaptchaValid: boolean;
  sendOtpField: boolean;
  otpFieldEnable: boolean;
  timerSec: number = 180;
  interval: any;
  maskMobileNo: string;
  maskEmailId: string;
  hours: number = 0;
  minutes: number = 0;
  readOnlyField: boolean;
  validateAgentResponse: any;
  otpResponse: any;
  isOtpMasked: boolean = true;
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  constructor(public fb: FormBuilder, public utils: Utils, public httpService: HttpService,
    public customLinkService: CustomLinkService,public dialogRef: MatDialog,private router: Router ) { }

  ngOnInit(): void {
    this.agentForm = this.fb.group({
      "agentId": ['', [Validators.required]],
      "captcha": ['', [Validators.required]],
      "otp": [''],
    });
  }
  clearClientCaptcha() {
    this.agentForm.get('captcha').setValue('');
  }
  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
      this.agentForm.controls.captcha.updateValueAndValidity();
    }, 10);
  }
  resetForm() {
    this.agentForm.reset();
  }
  enableOtp() {
    if (this.isCaptchaValid) {
      let reqBody = {
        agentId: this.agentForm.controls.agentId.value,
      }
      this.httpService.init({}).subscribe(resp => {
        this.httpService.initResp = resp;
        this.utils.setLocalData("source", "CL", false);
        return this.customLinkService.validateAgent(reqBody).subscribe((resp) => {
          if (this.httpService.isSuccessResponse(resp)) {
            if (resp.body.response && resp.body.response.toUpperCase() == "VALID") {
              this.sendOtpField = true;
              this.readOnlyField = true;
              this.maskMobileNo = resp.body.maskedMobileNo;
              this.validateAgentResponse = resp.body;
            }
          } else {
            this.httpService.apiErrorHandler(resp);
          }
        });
      });
    }
    else {
      this.agentForm.get('captcha').setErrors({ captchaIncorrect: true })
    }
  }
  sendOtp() {
    let reqBody = {
      //country_code: this.validateAgentResponse.country_code,
      agentId: this.agentForm.controls.agentId.value,
      //mobile_num: this.validateAgentResponse.mobile_num,
    }
    return this.customLinkService.generateAgentOtp(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body.response && resp.body.response.toUpperCase() == "SUCCESS") {
          this.otpResponse = resp.body
          this.otpFieldEnable = true;
          this.sendOtpField = false;
          this.agentForm.get('otp').setValidators([Validators.required]);
          this.agentForm.updateValueAndValidity();
          this.OTPTimerStart();
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  RegenOTP() {
    clearInterval(this.interval);
    this.agentForm.get('otp').reset();
    this.timerSec = 180;
    this.sendOtp();
  }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  submit() {
    let reqBody = {
      agentId: this.agentForm.controls.agentId.value,
      trans_id: this.otpResponse.trans_id,
      otpEntered: this.agentForm.controls.otp.value
    }
    return this.customLinkService.validateAgentOtp(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body.otp_flag && resp.body.otp_flag == true) {
          this.router.navigate(['/my-link','photo-upload'])
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  toggleMasking() {
    this.isOtpMasked = !this.isOtpMasked;
  }
}
