<div class="login-page">
  <div class="login-left-div">
    <div class="login-left-section">
      <!-- <mat-card-title class="login-title">Login</mat-card-title> -->
      <form [formGroup]="policyNoForm" (ngSubmit)="submitPolicyNoForm()">
        <div class="form-label">Your Policy Number<sup>*</sup></div>
        <mat-form-field floatLabel="never" class="client-login-field login-field">
          <mat-placeholder *ngIf="!readOnly">Please enter your Policy Number</mat-placeholder>
          <input formControlName="policyNo" autocomplete="off" type="tel" decimalNumbersOnly matInput (focusout)="policyFocusOut()" [readonly] = "otpFlag"
            maxlength="8">
          <!-- <img src="assets/edit_btn.svg" class="communicate-edit-imgs"> -->
          <!-- <mat-error class="error-message"
                  *ngIf="(loginClientFocusOut && emailvalidation.emailID.errors?.required)">
                  Enter valid Policy Number.
                </mat-error> -->
          <mat-error class="error-message" *ngIf="(policyFocus && policyNoForm.controls.policyNo.errors?.required)">
            Enter Policy Number
          </mat-error>
        </mat-form-field>

        <div *ngIf="policyNoForm.controls.srno.value">
          <div class="form-label dob-margin">SR Number<sup>*</sup></div>
          <mat-form-field floatLabel="never" class="client-login-field login-field">
            <mat-placeholder *ngIf="!readOnly">Please enter your SR number</mat-placeholder>
            <input formControlName="srno" autocomplete="off" type="text" matInput maxlength="13">       
          </mat-form-field>
        </div>

        <div class="form-label dob-margin">Date of Birth<sup>*</sup></div>
        <mat-form-field floatLabel="never" class="client-login-field login-field">
          <mat-placeholder>dd/mm/yyyy</mat-placeholder>
          <input formControlName="dob" type="text" autocomplete="off" matInput [max]="today" [matDatepicker]="picker"  [readonly] = "otpFlag" 
            (focusout)="dobFocusOut()">
          <mat-datepicker-toggle matSuffix [for]="picker" [disabled] ="otpFlag">
            <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <!-- <img src="assets/edit_btn.svg" class="communicate-edit-imgs"> -->
          <!-- <mat-error class="error-message" *ngIf="loginClientForm.get('dob').errors?.required">DOB is required</mat-error> -->
          <mat-error class="error-message" *ngIf="(dobFocus && policyNoForm.controls.dob.errors?.required)">
            Enter Date of Birth
          </mat-error>
        </mat-form-field>
        <div class="form-label dob-margin">Captcha<sup>*</sup></div>
        <mat-form-field floatLabel="never" class="client-login-field login-field">
          <mat-placeholder>Please enter captcha</mat-placeholder>
          <input formControlName="captcha" type="text" autocomplete="off" matInput [max]="today" (keypress)="errorclear()" 
          (focusout)="captchaFocusOut($event)" [readonly] = "otpFlag">
            <app-captcha #myLinkCaptcha (validateCaptchaEvent)="checkCaptcha($event)" (reloadEvent)="clearCaptcha()"
              [enteredCaptcha]="this.policyNoForm.controls.captcha.value">
            </app-captcha>
            <mat-error class="error-message"
            *ngIf="notcaptchaFocused && this.policyNoForm.controls.captcha.errors?.required">
            Please enter captcha.
          </mat-error>
          <mat-error class="error-message"
            *ngIf="notcaptchaFocused && this.policyNoForm.controls.captcha.errors?.pattern">
            Invalid Captcha.
          </mat-error>
          <mat-error class="error-message"
            *ngIf="(this.policyNoForm.controls.captcha.errors?.captchaIncorrect && !(this.policyNoForm.controls.captcha.errors?.pattern) && !(this.policyNoForm.controls.captcha.errors?.required))">
            Incorrect Captcha.
          </mat-error>
        </mat-form-field>
        <!-- <div [innerHTML]="statusMessage" class="status-message"></div> -->
        <button *ngIf="!otpFlag" type="submit" value="login" class="login-button" [disabled]="!policyNoForm.valid" mat-flat-button>Send OTP</button>
        <div *ngIf="!otpFlag && action=='cp_SurrenderPage'" class="helpline-content">For Any Assistance Call – 02268446530</div>
        <!-- <button type="submit" class="login-button login-button-padding" mat-flat-button>Proceed</button> -->
        <div [innerHTML]="statusMessage" class="status-message" *ngIf="action=='cp_unsecuredChequePickUpPage' && errorMessage"></div>
      </form>
      <div class="otp-div" *ngIf="otpFlag">
        <div *ngIf="maskEmail && maskMobileNumber" class="otp-sent-msg">
          <img src="assets/green-tick.svg">
          OTP has been successfully sent to your registered email id {{maskEmail}} and mobile no {{maskMobileNumber}} 
        </div>
        <div *ngIf="maskEmail && !maskMobileNumber" class="otp-sent-msg">
          <img src="assets/green-tick.svg">
          OTP has been successfully sent to your registered email id {{maskEmail}}
        </div>
        <div *ngIf="!maskEmail && maskMobileNumber" class="otp-sent-msg">
          <img src="assets/green-tick.svg">
          OTP has been successfully sent to your registered mobile no {{maskMobileNumber}} 
        </div>
        <div class="otp-timer-div">
          Your OTP will be valid for <span class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
          <a (click)="RegenOTP()" *ngIf="(timerSec==0)"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
        </div>
        <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
          <div class="otp-field">
              <div class="form-label otp-label">OTP<sup>*</sup></div>
              <mat-form-field floatLabel="never"class="client-login-field"
                  [ngClass]="{'error-field': (otpFocus && otpForm.controls.otp.errors?.required) || (otpFocus && otpForm.controls.otp.errors?.pattern)}">
                  <mat-placeholder>Enter the OTP received</mat-placeholder>
                  <input formControlName="otp" type="{{isOtpMasked ? 'password' : 'tel'}}" decimalNumbersOnly matInput (focusout)="otpFocusOut()" maxlength="6">
                  <div class="mat-suffix-wrapper" (click)="toggleMasking()">
                      <img class="iris-closed" mat-suffix src="assets/eye_closed.svg"
                        *ngIf="!isOtpMasked">
                      <img class="iris-open" mat-suffix src="assets/eye.png"
                        *ngIf="isOtpMasked">
                    </div>
                  <mat-error class="error-message" *ngIf="((buttonClick || otpFocus) && otpForm.controls.otp.errors?.required)">
                      Enter valid OTP
                  </mat-error>
              </mat-form-field> 
          </div>                   
          <!-- <div [innerHTML]="statusMessage" class="status-message"></div>  -->
          <!-- <button class="primary-btn" (click)="validateOtp()">Submit </button> -->
          <button type="submit" class="login-button" (click)="validateOtp()" mat-flat-button [disabled] ="!otpForm.valid">Proceed</button>
          <div *ngIf="action=='cp_SurrenderPage'" class="helpline-content">For Any Assistance Call – 02268446530</div>
          <!-- <button class="primary-btn" (click)="redirectToLogin()">Cancel</button> -->
      </form>
      </div>
    </div>
  </div>
</div>