import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SurrenderAversionRoutingModule } from './surrender-aversion-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SurrenderVideoComponent } from './surrender-video/surrender-video.component';


@NgModule({
    declarations: [
        LandingPageComponent,
        SurrenderVideoComponent
    ],
    imports:[
        SurrenderAversionRoutingModule,
        CommonModule,
        SharedModule,
    ],
    exports:[],
})

export class SurrenderAversionModule { } 
