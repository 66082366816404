<div class="recommended">
        <div class="recommended-title"> Recommended plans for you</div>
        <div class="recommended-plans">
            <div class="recommended-card flex">
                <mat-card class="policy-list" *ngFor="let item of crossSell" [href]="item.link" target="_blank"
                    rel="noopener noreferrer" (click)="selectedPlan(item.label)">
                    <div class="img-sec">
                        <img src="{{item.planimg}}">
                    </div>
                    <div class="recommended-plan">
                        <div class="plan-list">
                            {{item.label}}
                        </div>
                    </div>
                </mat-card>
            </div>
    
        </div>
    </div>