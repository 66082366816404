<div class="neft-deeplink-page">
    <div class="custom-link-page-padding">
        <div class="custom-link-page-split flex">
            <div class="custom-link-left-div">
                <mat-card class="custom-link-left-section">
                    <app-bank-details                        
                        context="DEEP_LINK">
                    </app-bank-details>
                </mat-card>
            </div>
        </div>
    </div>
</div>
