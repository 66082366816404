import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { CustomValidators } from 'src/app/utils/custom-validators';

@Component({
  selector: 'app-email-voc',
  templateUrl: './email-voc.component.html',
  styleUrls: ['./email-voc.component.css']
})
export class EmailVocComponent implements OnInit {
  ratedval: string;
  interactionid: string;
  actionid: string;
  questions: any[];
  answer: any;
  value: number = 1;
  questionResp: any;
  vocQuestions: boolean = false;
  submitResponse: any;
  answerArray: any[];
  ratingArray: Array<any> = ['5', '4', '3', '2', '1'];
  successMessage: string;
  heading: string;
  vocAllowed: any;
  isFiveActive: boolean;
  isFourActive: boolean;
  isThreeActive: boolean;
  isTwoActive: boolean;
  isOneActive: boolean;
  isSubmit: boolean;
  notValid: boolean;
  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  noRecords: boolean;
  questionAvailable: boolean;

  constructor(private fb: FormBuilder,
    public route: ActivatedRoute,
    public customLinkService: CustomLinkService,
    public httpService: HttpService,
    public message: MessageService,
    public dialog: MatDialog,
    public router: Router) { }

  emailVocForm: FormGroup;
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: any) => {
      let paramValue = { ...params }
      if (paramValue != undefined && paramValue != null && paramValue != {}) {
        this.interactionid = this.route.snapshot.queryParamMap.get('interactionid');
        this.actionid = this.route.snapshot.queryParamMap.get('agentid');
      }
      if (this.interactionid && this.actionid) {
        return this.init().subscribe((response) => {
          this.httpService.initResp = response;
          this.getQuestionsOnLoad();
        });
      }
      else {
        this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Error",
            mainMessageHtml: "The link is invalid. For any further assistance, please contact us on 1860 267 9999 or email us at service@hdfclife.com.",
            cancelBtnLabel: "OK",
            source: 'voc'
          }
        });
      }
    })
  }
  generateForm() {
    const secQues = this.questionResp.map(control => {
      return this.fb.group({
        questns: new FormControl(control.QUESTION_DESC),
        answer_type: new FormControl(control.ANSWER_TYPE),
        quesrk: new FormControl(control.QUESTION_RK),
        quescd: new FormControl(control.QUESTION_CD),
        qusans: new FormControl('', [Validators.required, Validators.pattern((/^[a-zA-Z0-9\s]{0,500}$/))])
      })
    });
    this.emailVocForm = this.fb.group({
      qaArray: this.fb.array(secQues),
    });
    this.questionAvailable = true;
    return secQues;
  }
  rating(event) {
    this.emailVocForm.controls.qaArray['controls'].forEach(data => {
      //data.status = "VALID"
      if (data.controls.answer_type.value == 'RATING' && event == '5') {
        data.controls.qusans.value = "5";
        data.get('qusans').setErrors(null);
        data.get('qusans').setValidators([]);
        this.isFiveActive = true;
        this.isFourActive = false;
        this.isThreeActive = false;
        this.isTwoActive = false;
        this.isOneActive = false;
      }
      else if (data.controls.answer_type.value == 'RATING' && event == '4') {
        data.controls.qusans.value = "4";
        data.get('qusans').setErrors(null);
        data.get('qusans').setValidators([]);
        this.isFiveActive = false;
        this.isFourActive = true;
        this.isThreeActive = false;
        this.isTwoActive = false;
        this.isOneActive = false;
      }
      else if (data.controls.answer_type.value == 'RATING' && event == '3') {
        data.controls.qusans.value = "3";
        data.get('qusans').setErrors(null);
        data.get('qusans').setValidators([]);
        this.isFiveActive = false;
        this.isFourActive = false;
        this.isThreeActive = true;
        this.isTwoActive = false;
        this.isOneActive = false;
      }
      else if (data.controls.answer_type.value == 'RATING' && event == '2') {
        data.controls.qusans.value = "2";
        data.get('qusans').setErrors(null);
        data.get('qusans').setValidators([]);
        this.isFiveActive = false;
        this.isFourActive = false;
        this.isThreeActive = false;
        this.isTwoActive = true;
        this.isOneActive = false;
      }
      else if (data.controls.answer_type.value == 'RATING' && event == '1') {
        data.controls.qusans.value = "1";
        data.get('qusans').setErrors(null);
        data.get('qusans').setValidators([]);
        this.isFiveActive = false;
        this.isFourActive = false;
        this.isThreeActive = false;
        this.isTwoActive = false;
        this.isOneActive = true;
      }
    })
  }
  getControls() {
    return (this.emailVocForm.get('qaArray') as FormGroup).controls;
  }
  getQuestionsOnLoad() {
    let reqBody = {
      clientID: this.interactionid,
    }
    return this.customLinkService.getEmailVocQuestions(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.questionResp = resp.body.emailVocdtls;
        this.vocAllowed = resp.body.proceedWithVocFeedback;
        if (this.vocAllowed == 'N') {
          this.dialog.open(MessagePopupComponent, {
            width: '500px',
            panelClass: 'message-popup-panel',
            backdropClass: 'email-voc-popup', disableClose: true,
            data: {
              closeIcon: false,
              title: "Error",
              mainMessageHtml: "Feedback is already submitted.",
              cancelBtnLabel: "OK",
              source: 'voc'
            }
          });
        }
        else {
          if (this.questionResp) {
            this.generateForm();
            this.getControls();
            this.noRecords = false;
          }
        }
      } else {
        this.httpService.apiErrorHandler(resp);
        this.noRecords = true;
      }
    });
  }
  onSubmit() {
    this.isSubmit = true;
    this.answerArray = [];
    this.emailVocForm.controls.qaArray['controls'].forEach(data => {
      let ansarray = {
        qusans: data.controls.qusans.value,
        quesrk: data.controls.quesrk.value,
      }
      this.answerArray.push(ansarray)
      if (data.controls.qusans.dirty == true) {
        data.controls.qusans.status = "VALID";
        data.get('qusans').setErrors(null);
        this.notValid = false;
      }
      else {
        data.controls.qusans.status = "INVALID";
        data.get('qusans').setErrors({ errors: "Required", errorMsg: "Mandatory" });
      }
    })
    if (this.emailVocForm.status == "VALID") {
      if (this.answerArray && this.vocAllowed == 'Y') {
        this.submitEmailVocForm();
      }
    }
    if (this.emailVocForm.status == "VALID" && this.vocAllowed == 'N') {
      this.dialog.open(MessagePopupComponent, {
        width: '500px',
        panelClass: 'message-popup-panel',
        backdropClass: 'email-voc-popup', disableClose: true,
        data: {
          closeIcon: false,
          title: "Error",
          mainMessageHtml: "Feedback is already submitted.",
          cancelBtnLabel: "OK",
          source: 'voc'
        }
      });
    }
  }
  submitEmailVocForm() {
    let reqBody = {
      clientID: this.interactionid,
      agentId: this.actionid,
      userAgent: "",
      ansdetls: this.answerArray,
    }
    return this.customLinkService.submitEmailVocQuestions(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.submitResponse = resp.body;
        //this.message.openSnackBar('Thank you for your valuable feedback', 'xy', ['success-snackbar']);
        this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Success",
            mainMessageHtml: "Thank you for your valuable feedback.",
            cancelBtnLabel: "OK",
            source: 'voc'
          }
        });
      }
      else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  init() {
    return this.httpService.init(this.httpHeaders);
  }
}
