import { Component, OnInit, Input, Inject,  } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { Utils } from "../../../utils/common-functions";
import { useraddressReqBody, userbankdtlsReqbody } from '../my-account-data-modal';
import { DataService } from 'src/app/services/data/data.service';
import { throwError , Observable  } from 'rxjs';
import { catchError, map, startWith, debounceTime} from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, MinLengthValidator, FormControl } from '@angular/forms';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {OptionValue} from 'src/app/modules/my-account/my-account-data-modal';
import { DocListPopupComponent } from '../../shared/components/doc-list-popup/doc-list-popup.component';
import { MessageService } from 'src/app/services/message/message.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { loginService } from 'src/app/services/login/login.service';
import { ThisReceiver } from '@angular/compiler';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';


@Component({
  selector: 'app-update-mailing-address-manual',
  templateUrl: './update-mailing-address-manual.component.html',
  styleUrls: ['./update-mailing-address-manual.component.css']
})
export class UpdateMailingAddressManualComponent implements OnInit {
  benificiary:Array<any>=[];
  createSrResBody: any;
  queryParams;
  bankdtlbody: userbankdtlsReqbody;
  addressbody: useraddressReqBody;
  ClientAddress: any;
  CommAddress: any; 
  isHNIUSer:boolean = false;
  userDetails: any = {};
  changeAddress: FormGroup;
  filteredCountry: Observable<string[]>;
  filteredState: Observable<string[]>;
  filteredCity: Observable<string[]>;
  filteredPincode: Observable<string[]>;
  states: any = [];
  cities: any = [];
  pincodes: any = [];
  country; any = [];
  masterVersion: 10;
  countries: OptionValue[] = [{label: 'India', value: 'CNTRY_IND'}];
  inputFlag: boolean = true;
  showDiv: boolean = false;
  proceedFlag: boolean = true;
  uploadedFilesURL: Array<string> = [];
  showOcrUpdate: boolean = false;
  isOcrUpdate: boolean = false;
  showPdf: boolean = false;
  maxLength: any;
  
  requirementDtlsList: any = [];
  isSubmitSuccess: Array<boolean> = [];
  isUploadSuccess: Array<boolean> = [];
  requirementDtls: any = {};
  byteArray: any[] = [];

  
  beneficiaryOtpForm : FormGroup = null;
  otpFlag: boolean;
  interval: any;
  timerSec: number = 180;
  hours: number;
  minutes: number;
  showOtpSection: boolean = false;
  showOtpButton : boolean = false;
  OTPGenerate: boolean;
  otpResp: any;
  statusMessage: any;
  otpErrorMessage: boolean;
  otp : FormControl;

  terms : FormGroup;

  aadhaarConsentFlag : any;
  accepted: any;
  otpFocus: boolean = false;
  passwordType: string;
  reveal: boolean = false;
  edited: boolean = false;
  permMailSame = false;

  get otpValidation() { return this.beneficiaryOtpForm.controls; }

  constructor(public fb: FormBuilder,
    public dialogRef: MatDialog,
    public editAddressDialog: MatDialogRef<UpdateMailingAddressManualComponent>,
    private activatedRoute: ActivatedRoute, 
    public router: Router, 
    public _httpService: HttpService,
    public myAccountService: MyAccountService, 
    public utils: Utils,
    @Inject(MAT_DIALOG_DATA) public data, 
    public dataService: DataService, 
    public createSrService: CreateSRService,
    public message: MessageService,
    public myPoliciesService: MyPoliciesService,
    private loader: LoaderService,
    public loginService : loginService,
    public crypto : EncryptDecryptService
  ) { }

  get getAccept(){
    return this.terms.get('accept').value
  }

  ngOnInit(): void {
    this.passwordType = "password";
    this.addressdetails(this.data.type);
    this.changeAddress = this.fb.group({
      updateCountry: [ { label: this.addressbody.country }, [CustomValidators.selectCountryValue]],
      updateHouseNumber: [this.addressbody.houseno, [CustomValidators.houseNumber]],
      updateAddressOne: [this.addressbody.street, [CustomValidators.addressLine]],
      updateLandmark: [this.addressbody.landmark, [CustomValidators.landmark]],
      updatePincode: [this.addressbody.pincode, [CustomValidators.pincode]],
      updateState: [this.addressbody.state, [CustomValidators.selectStateValue]],
      updateCity: [this.addressbody.city, [CustomValidators.selectCityValue]]
    });

    this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
       this.isHNIUSer = true;
     }    
    this.setOcrFlags();
    this.bankdetails(this.data.type);
    this.activatedRoute.queryParams.subscribe(values=> {
      this.queryParams =  values['src'];
    })  
  
    this.getCountry();

    this.filteredCountry = this.changeAddress.get('updateCountry').valueChanges.pipe(startWith(''),debounceTime(300),
    map((selectCountry) => typeof selectCountry === 'string' ? selectCountry : selectCountry?.label),
    map((label) => label ? this._filter(label, this.country): this.country.slice()
    )
    );
   

    this.filteredState = this.changeAddress.get('updateState').valueChanges.pipe(startWith(''),debounceTime(300),
    map((selectedState) => typeof selectedState === 'string' ? selectedState : selectedState?.label),
    map((label) => label ? this._filter(label, this.states): this.states.slice()
    )
    );

    this.filteredCity = this.changeAddress.get('updateCity').valueChanges.pipe(startWith(''),debounceTime(300),
    map((selectedCity) => typeof selectedCity === 'string' ? selectedCity : selectedCity?.label),
    map((label) => label ? this._filter(label, this.cities): this.cities.slice()
    )
    );

    this.filteredPincode = this.changeAddress.get('updatePincode').valueChanges.pipe(startWith(''),
    map((pincodes)=> this._pincodeFilter(pincodes, this.pincodes)
    )
    );

    
    this.beneficiaryOtpForm = this.fb.group({
      'otp' : ['', [Validators.required,Validators.pattern('^[0-9]{6,6}$')]]
    })

    if(this.data.ocrDocCode == "DOC_018" && this.data.id == "beneficiary" && (this.data.role == "Beneficiary" || this.data.role == "Life Assured" || this.data.role == "Appointee") && (this.userDetails.CLIENT_ID != this.data.CliAdd.CLIENTID)){
      this.showOtpButton = true;
    }

    if(this.data.offlineadhaarConsent == "Y"){
      this.aadhaarConsentFlag = "Y"
    }

    this.terms = this.fb.group({
      accept : [false]
    })
  }

  private _filter(value: string, data: any[]): any[]
   {
    const filterValue: string = value.toLowerCase();
    return data.filter((item) => item[1].toLowerCase().includes(filterValue));
  }
  private _pincodeFilter(value: string, data: any[]): any[]
   {
    const filterValue: string = value;
    return data.filter((item) => item.toLowerCase().includes(filterValue));
  }
  compareFn(optionOne: OptionValue, optionTwo: OptionValue) {
    return optionOne.value === optionTwo.value;
  }
  displayValue(selectedValue): string {
    return selectedValue && selectedValue.label ? selectedValue.label: '';
  }
  countrySelected(event: MatAutocompleteSelectedEvent) {
    console.log(event);
    const selectCountry: OptionValue = event.option.value;
    this.changeAddress.get('updateState').reset();
    this.changeAddress.get('updateCity').reset();
    this.changeAddress.get('updatePincode').reset();
    if(selectCountry.label === 'India') {
    this.getStates(selectCountry.label)
    .then((success) => console.log('Success'))
    .catch((error)=> console.log('error'));
    this.inputFlag = true;
    console.log(this.inputFlag);
    this.maxLength='6';
    }
    else {
      this.inputFlag =false;
      this.maxLength="10";
    }
  }
  stateSelected(event: MatAutocompleteSelectedEvent) {
    console.log('state ====>', event.option.value);
    const selectedState: OptionValue = event.option.value;
    this.changeAddress.get('updatePincode').reset();
    this.changeAddress.get('updateCity').reset();
    this.getCities(selectedState.value)
      .then((success) => console.log('Success'))
      .catch((error)=> console.log('Failure'));
  }
  citySelected(event: MatAutocompleteSelectedEvent) {
    const selectedCity: OptionValue = event.option.value;
    this.changeAddress.get('updatePincode').reset();
    this.getPincodes(selectedCity.value);
  }
  pincodeEntered(value) {
    console.log('state ====>', value);
    const pincode: string = value;
    if(pincode.length === 6) {
      if(
        this.changeAddress.get('updateState').value && 
        this.changeAddress.get('updateCity').value
      )
      {
        if(this.pincodes)
        {
          this.pincodes.includes(pincode) ? this.changeAddress.get('updatePincode').setErrors(null)
          : this.changeAddress.get('updateState').reset(),
          this.changeAddress.get('updateCity').reset();
          this.getCityStateFromPincode(pincode);
        }
      } 
      else
        {
          this.changeAddress.get('updateState').reset();
          this.changeAddress.get('updateCity').reset();
          this.getCityStateFromPincode(pincode);
        }
    }
  }

  getStates(countryCode) {
    let statesData: any;
    const masterDataType = "mstr_com_addr";
    const filterBy = `STATE_BY_CNT--${countryCode}`;
    return new Promise((resolve,reject) => {
      if(this.myAccountService.states[filterBy]) {
        this.states = Object.values(this.myAccountService.states[filterBy]);
        statesData = this.myAccountService.states[filterBy];
        resolve(statesData);
        console.log(statesData);
      } else {
        this.myAccountService.getMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp) => {
          console.log('states ====>', resp);
          this.states = Object.values(resp.body[filterBy]);
          console.log((this.changeAddress.get('updateCountry').value as OptionValue).label);
          this.myAccountService.states[filterBy] = resp.body[filterBy];
          statesData = resp.body[filterBy];
          resolve(statesData);
        },
        (error)=> reject(error));
      }
    });
  }
  getCities(stateCode) {
    let citiesData: any;
    const masterDataType = "mstr_com_addr";
    const filterBy = `CITY_BY_ST--${stateCode}`;
    return new Promise((resolve,reject) => {
      if(this.myAccountService.cities[filterBy]) {
        this.cities = Object.values(this.myAccountService.cities[filterBy]);
        citiesData = this.myAccountService.cities[filterBy];
        resolve(citiesData);
      } else {
        this.myAccountService.getMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp) => {
          console.log('cities ====>', resp);
          this.cities = Object.values(resp.body[filterBy]);
          this.myAccountService.cities[filterBy] = resp.body[filterBy];
          citiesData = resp.body[filterBy];
          resolve(citiesData);
        },
        (error)=> reject(error));
      }
    });
  }
  getPincodes(cityCode) {
    const masterDataType = "mstr_com_addr";
    const filterBy = `AREA_BY_CT--${cityCode}`;
    this.myAccountService.getMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp)=> {
      console.log("pincodes ====> ", Object.keys(resp.body[filterBy]));
      this.pincodes = Object.keys(resp.body[filterBy]);
    })
  }
  getCityStateFromPincode(pinCode) {
    const masterDataType = "mstr_com_area";
    const filterBy = `GCOM_AREA/${pinCode}`;
    this.myAccountService.getMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp)=>
    {
      console.log('city and state of pincode ====>', resp);
      const country: OptionValue = this.changeAddress.get('updateCountry').value;
      const stateCode = resp.body[pinCode][4][0];
      const cityCode = resp.body[pinCode][4][1];
      this.getStates(country.label).then((states)=> {
        const stateValue: OptionValue = {
          label: states[stateCode][1],
          value: states[stateCode][1]
        };
        this.changeAddress.get('updateState').setValue(stateValue);
        if (this.data.offlineAadhar && this.data.ocrResp) {
          this.data.ocrResp.state = stateValue.label
        }
      });
      this.getCities(stateCode).then((cities) =>  {
        console.log('state ====>', cities[cityCode]);
        const cityValue: OptionValue = {
          label: cities[cityCode][1],
          value: cities[cityCode][1]
        };
        this.changeAddress.get('updateCity').setValue(cityValue);
        if (this.data.offlineAadhar && this.data.ocrResp) {
          this.data.ocrResp.city = cityValue.label
        }
      });
    });
  }
  get validation() {return this.changeAddress.controls; }

  addressdetails(addressType) {

    if (addressType === 'Permanent') {
    this.addressbody= {
      "header": "Add Permanent Address",
      "dropdown": "Update Permanent Address",
      "houseno": this.data.CliAdd.HOUSENUMBER,
      "street": this.data.CliAdd.STREET,
      "landmark": this.data.CliAdd.LANDMARK,
      "city": this.data.CliAdd.CITY,
      "state": this.data.CliAdd.STATE,
      "country": this.data.CliAdd.COUNTRY,
      "pincode": this.data.CliAdd.PINCODE.toUpperCase(),  }
    }
    else if (addressType === 'Mailing') {
    this.addressbody={
      "header": "Add Mailing Address",
      "dropdown": "Update Mailing Address",
      "houseno": this.data.id =="beneficiary"? this.data.ComAdd.BEN_HOUSENOR:this.data.ComAdd.HOUSE_NO,
      "street": this.data.id =="beneficiary"? this.data.ComAdd.BEN_STREETADDR:this.data.ComAdd.STREET_ADDRESS,
      "landmark": this.data.id =="beneficiary"? this.data.ComAdd.BEN_AREA:this.data.ComAdd.LANDMARK,
      "city": this.data.id =="beneficiary"? this.data.ComAdd.CITY:this.data.ComAdd.CITY,
      "state": this.data.id =="beneficiary"? this.data.ComAdd.STATE:this.data.ComAdd.STATE,
      "country": this.data.id =="beneficiary"? this.data.ComAdd.COUNTRY:this.data.ComAdd.COUNTRY,
      "pincode": this.data.id =="beneficiary"? this.data.ComAdd.BEN_PINCODE?this.data.ComAdd.BEN_PINCODE.toUpperCase():"":this.data.ComAdd.PINCODE?this.data.ComAdd.PINCODE.toUpperCase():""      }
    }
  }
  bankdetails(accoType) { 

    if (accoType === 'NEFT') {
      this.bankdtlbody={
        "header": "Add NEFT Details",
        "dropdown": "Update NEFT Details",
        "accouno": this.data.NEFTup.ACC_NUM,
        "bankname": this.data.NEFTup.BANKNAME,
        "clientname": this.data.NEFTup.ACCNAME,
        "ifsccode": this.data.NEFTup.IFSCCODE,
        "acctype": this.data.NEFTup.ACCTYPE,
        "branchname": this.data.NEFTup.BRANCHNAME }
    }
    else if (accoType === 'ECS') {
      this.bankdtlbody={
        "header": "Add ECS Details",
        "dropdown": "Update ECS Details",
        "accouno": this.data.ECSup.accno,
        "bankname": this.data.ECSup.bankname,
        "clientname": this.data.ECSup.accname,
        "ifsccode": this.data.ECSup.ifsccode,
        "acctype": this.data.ECSup.acctype,
        "branchname": this.data.ECSup.branchname
      }
    }
  }
  countryCode: any[] = [
    {label: 'India'},
    {label: 'USA'},
    {label: 'United Arab Emirates'}
  ];
  telephoneCode: any[] = [
    {phone: 'Delhi'},
    {phone: 'Tamil Nadu'},
    {phone: 'Maharashtra'},
  ];
  createAddressSR() {
    let type;
    let clientId;
    let acc_tag;
    let parentSR;
    let benId;

    if(this.accepted){
      this.aadhaarConsentFlag = "Y";
    } else
      this.aadhaarConsentFlag = undefined;

    if(this.data.viewDtls == "benfiAndNominee"){
      type = "CON_AMS_NNY"
      clientId = this.data.ComAdd.CLIENTID
      benId = this.data.ComAdd.CLIENTID
    } else if(this.data.viewDtls == "lifeAssured"){
      type = "CON_ADMN_NYN"
      clientId = this.data.ComAdd.CLIENTID
      benId = this.data.ComAdd.CLIENTID
    } else if((this.data.id == "maturity")||(this.data.id == "moneyBack")){
      acc_tag = "Y"
      parentSR = this.data.parentSr
      clientId = this.data.clientId
      benId = ""
    } else{
      acc_tag = "";
      parentSR = ""
      type =""
      benId = ""
      clientId = this.utils.getClientId()
    }
    const ocrData = this.data.ocrResp && this.showOcrUpdate && this.isOcrUpdate
      ? { ocrflag: 'Y', ocrdocrk: this.data.ocrResp.ocrdocrk }
      : this.data.offlineAadhar && this.data.ocrResp.ocrdocrk ? { ocrflag: 'N', eKYCFlag: 'Y', ocrdocrk: this.data.ocrResp.ocrdocrk } : { ocrflag: 'N' };
    let reqBody = {
      ...ocrData,
      policyno: this.data.policyNo,
      clientid: clientId,
      benId : benId,
      role: this.data.role,
      subactivity: 'CHNG_ADDRESS',
      subactivitytype: type,
      action: "CREATE",
      payout_acc_tag: acc_tag,
      parentsrno: parentSR, 
      srinput: {
        addressdtls: {
          housenm: this.changeAddress.controls.updateHouseNumber.value,
          street: this.changeAddress.controls.updateAddressOne.value,
          landmark: this.changeAddress.controls.updateLandmark.value,
          city: this.changeAddress.get('updateCity').value && this.changeAddress.get('updateCity').value.label? this.changeAddress.get('updateCity').value.label : this.changeAddress.get('updateCity').value ? this.changeAddress.get('updateCity').value : '',
          state: this.changeAddress.get('updateState').value && this.changeAddress.get('updateState').value.label? this.changeAddress.get('updateState').value.label : this.changeAddress.get('updateState').value ? this.changeAddress.get('updateState').value : '',
          country: this.changeAddress.get('updateCountry').value && this.changeAddress.get('updateCountry').value.label && this.changeAddress.get('updateCountry').value.value? this.changeAddress.get('updateCountry').value.value : this.changeAddress.get('updateCountry').value.label ? this.changeAddress.get('updateCountry').value.label : '',
          pincode: this.changeAddress.controls.updatePincode.value.toUpperCase(),
          iscountrychanged: this.addressbody.country ? this.addressbody.country.toLowerCase() === this.changeAddress.controls.updateCountry.value.label.toLowerCase() ? "N" : "Y" : "N",         
          permanentaddflg: (this.data.type === "Permanent" || this.permMailSame) ? "Y" : "N",
          communicationaddflg: this.data.type === "Mailing" ? "Y" : "N",
        },
        contactdtls: {
          isemailchanged: "N"
        }
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
      },
      aadhaarConsentFlag : this.aadhaarConsentFlag == "Y"? this.crypto.encryptJson(this.aadhaarConsentFlag) : undefined
    };
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && (resp.body && ((resp.body.ocrflag === 'N' && (!this.byteArray || this.byteArray.length == 0)) && resp.body.eKYCFlag == 'N'))) {
        this.dialogRef.closeAll();
        const srno: string = resp.body.srbasicinfo.srno;
        this.dialogRef.open(DocListPopupComponent, {
          panelClass: this.data.disableClose ? 'doc-list-dialog-iframe' : 'doc-list-dialog',
          backdropClass: 'doc-list-dialog-backdrop',
          disableClose: true,
          data: {
            srno: srno,
            subactivity: reqBody.subactivity,
            createSrResp: resp.body,
            disableClose: this.data.disableClose,
            addressType: this.data.type,
          }
        });
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}       
        this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && (resp.body.ocrflag === 'Y' && resp.body.eKYCFlag == 'Y')) {
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
        } else {
          this.trackUserActionsMsg({ 'eventName': `message - Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`,'error_code':'SM' });
          this._httpService.showSuccessMsg(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`);
        }
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && (resp.body.ocrflag === 'N' && resp.body.eKYCFlag == 'Y') || (resp.body && resp.body.ocrflag === 'Y' && resp.body.eKYCFlag == 'N')) {
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
        } else {
          this._httpService.showSuccessMsg(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`);
          this.trackUserActionsMsg({ 'eventName': `message - Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`,'error_code':'SM' });
        }
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N' && this.byteArray && this.byteArray.length != 0) {
        this.getRequirementDetails(resp.body.srbasicinfo.srno);
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,this.data.disableClose,trackMsgObj);
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
        }
      }
    }),
      catchError((error) => throwError(error));
  }
  submitAddress() {
    if (this.changeAddress.valid) {
      this.isOcrUpdate = !this.isOcrFormValueChanged();
      this.createAddressSR();
    } else {
      this.changeAddress.markAllAsTouched();
    }
    if (this.showOcrUpdate && !this.proceedFlag) {
      this.trackUserActions('save changes');
    } else {
      this.trackUserActions('submit');
  }
  }
  cancelManualUpdate() {
    this.dialogRef.closeAll();
  }
  getCountry() {
    let countryData: any;
    const masterDataType = "mstr_perm_addr";
    const filterBy = `GPERM_COUNTRY`;
    return new Promise((resolve,reject) => {
      if(this.myAccountService.country[filterBy]) {
      this.country = Object.values(this.myAccountService.country[filterBy]);
        countryData = this.myAccountService.country[filterBy];
        resolve(countryData);
        console.log(countryData);
      } else {
        this.myAccountService.getMasterData(masterDataType, filterBy, this.masterVersion).subscribe((resp) => {
          console.log('states ====>', resp);
          this.country = Object.values(resp.body[filterBy]);
          this.myAccountService.country[filterBy] = resp.body[filterBy];
          countryData = resp.body[filterBy];
          resolve(countryData);
          console.log(countryData);
        },
        (error)=> reject(error));
      }
    });
  }  
  displayAddress() {
    this.showDiv = true;
  }
  hideDiv() {
    this.showDiv = false;
  }
  hideDivProceed() {
    this.showDiv = false;
    this.proceedFlag = false;
        
    if(this.data.ocrDocCode == "DOC_018" && this.data.id == "beneficiary" && (this.data.role == "Beneficiary" || this.data.role == "Life Assured" || this.data.role == "Appointee") && (this.userDetails.CLIENT_ID != this.data.CliAdd.CLIENTID))
    this.edited = true;
    else
    this.edited = false;
  }
  setOcrFormValue() {
    this.changeAddress.patchValue({
      "updateCountry": this.data.ocrResp && this.data.ocrResp.country ? { label: this.data.ocrResp.country } : '',
      "updateHouseNumber": this.data.ocrResp && this.data.ocrResp.houseNo ? this.data.ocrResp.houseNo : '',
      "updateAddressOne": this.data.ocrResp && this.data.ocrResp.street ? this.data.ocrResp.street : '',
      "updateLandmark": this.data.ocrResp && this.data.ocrResp.area ? this.data.ocrResp.area : '',
      "updatePincode": this.data.ocrResp && this.data.ocrResp.pin ? this.data.ocrResp.pin : '',      
    });
    if (this.inputFlag) {
      this.changeAddress.patchValue({
        "updateState": this.data.ocrResp && this.data.ocrResp.state ? { label: this.data.ocrResp.state } : '',
        "updateCity": this.data.ocrResp && this.data.ocrResp.city ? { label: this.data.ocrResp.city } : ''
      });
    } else {
      this.changeAddress.patchValue({
        "updateState": this.data.ocrResp && this.data.ocrResp.state ? this.data.ocrResp.state : '',
        "updateCity": this.data.ocrResp && this.data.ocrResp.city ? this.data.ocrResp.city : ''
      });
    }
    if (this.data.offlineAadhar && this.data.ocrResp && this.data.ocrResp.pin) {
      this.getCityStateFromPincode(this.data.ocrResp.pin);
    }
  }
  onBackBtnClick() {
    this.editAddressDialog.close();    
  }
  onCancelBtnClick() {
    this.proceedFlag = true;
    this.isOcrUpdate = true;
    this.setOcrFormValue();
  }
  setOcrFlags() {
    this.showOcrUpdate = this.data.showOcrUpdate ? true : false;
    this.isOcrUpdate = this.showOcrUpdate;
    this.showPdf = this.data.uploadedFileType == "application/pdf" ? true : false;
    this.uploadedFilesURL = this.data.uploadedFilesURL;
    if (this.isOcrUpdate && this.data.ocrResp) {
      this.setOcrFormValue();
    }
    this.byteArray = this.data.byteArray;
  }
  isOcrFormValueChanged() {
    if (this.isOcrUpdate && this.data.ocrResp) {
      return !((this.changeAddress.get('updateHouseNumber').value == this.data.ocrResp.houseNo)
        && (this.changeAddress.get('updateAddressOne').value == this.data.ocrResp.street)
        && (this.changeAddress.get('updateLandmark').value == this.data.ocrResp.area)
        && (this.changeAddress.get('updatePincode').value == this.data.ocrResp.pin)
        && (this.changeAddress.get('updateCountry').value.label == this.data.ocrResp.country)
        && (this.inputFlag ? this.changeAddress.get('updateState').value.label == this.data.ocrResp.state
          : this.changeAddress.get('updateState').value == this.data.ocrResp.state)
        && (this.inputFlag ? this.changeAddress.get('updateCity').value.label == this.data.ocrResp.city
          : this.changeAddress.get('updateCity').value == this.data.ocrResp.city))
    } else {
      return true;
    }
  }
  getRequirementDetails(srno) {
    let reqBody = {
      "srno": srno
    }
    return this.createSrService.getRequirementDetails(reqBody).subscribe(async resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.getrequirementdetails) {
          this.requirementDtlsList = resp.body.getrequirementdetails;
          this.requirementDtlsList.forEach((item) => this.isSubmitSuccess.push(false));
          for (let reqmntIndex = 0; reqmntIndex < this.requirementDtlsList.length; reqmntIndex++) {
            if (["CSPS_REQ_002", "CSPS_REQ_004"].includes(this.requirementDtlsList[reqmntIndex].reqcd)) {
              if (this.data.ocrDocCode != "DOC_006" && this.requirementDtlsList[reqmntIndex].reqcd == "CSPS_REQ_004") {
                continue;
              }
              this.isUploadSuccess = [];
              for (let i = 0; i < this.byteArray.length; i++) {
                this.isUploadSuccess.push(false);
              }
              for (let docIndex = 0; docIndex < this.byteArray.length; docIndex++) {
                await new Promise(resolve => {
                  this.uploadDocument(this.requirementDtlsList[reqmntIndex], docIndex).subscribe(resp => {
                  if (this._httpService.isSuccessResponse(resp)) {
                    this.isUploadSuccess[docIndex] = true;
                    if (!this.isUploadSuccess.includes(false)) {
                      this.isSubmitSuccess[reqmntIndex] = true;
                    }
                  } else {
                    let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
                    this._httpService.apiErrorHandler(resp,null,trackMsgObj);
                  }
                  resolve(true);
                }, () => resolve(false));
              });
              }
            }
          }
          this.loader.show();
          setTimeout(() => {
            this.loader.hide();
            if (!this.isSubmitSuccess.includes(false)) {
              this.submitSR(this.requirementDtlsList[0]);
            } else {
              this.dialogRef.closeAll();
              this.dialogRef.open(DocListPopupComponent, {
                panelClass: this.data.disableClose ? 'doc-list-dialog-iframe' : 'doc-list-dialog',
                backdropClass: 'doc-list-dialog-backdrop',
                disableClose: true,
                data: {
                  srno: srno,
                  disableClose: this.data.disableClose
                }
              });
            }
          }, 2000);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,this.data.disableClose,trackMsgObj);
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
        }
      }
    });
  }
  uploadDocument(requirementDtls, docIndex) {
    const reqBody = {
      aadhaarConsentFlag : this.aadhaarConsentFlag == "Y" ? this.crypto.encryptJson(this.aadhaarConsentFlag) : undefined,
      osvflag: "Received through email or letter.",
      policyno: requirementDtls.policyno,
      srno: requirementDtls.srno,
      clientid: requirementDtls.clientid,
      bytearray: this.byteArray[docIndex].pdfbyte,
      reqcd: requirementDtls.reqcd,
      reqtranskey: requirementDtls.reqtranskey,
      reqstatuscd: requirementDtls.reqstatuscd,
      filenetfldr: requirementDtls.filenetfldr,
      doccd: this.data.ocrDocCode,
      doctype: this.byteArray[docIndex].name.split('.').pop(),
      docname: this.byteArray[docIndex].name ? this.byteArray[docIndex].name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : this.byteArray[docIndex].name,
      docattributelist: [],
      clientrolecd : requirementDtls.clientrolecd
    };
    return this.createSrService.documentUpload(reqBody);
  }
  submitSR(requirementDtls) {
    let reqBody = {
      policyno: requirementDtls.policyno,
      clientid: requirementDtls.clientid,
      subactivity: requirementDtls.subissuecd,
      action: "SUBMIT",
      srno: requirementDtls.srno,
      role: requirementDtls.clientrolecd,
      subactivityType: '',
      nachsi: '',
    };
    this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (!this.data.disableClose) {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(this.utils.getSubmitSRSuccessMsg(requirementDtls.srno, requirementDtls.subissuecd), 'xy', ['success-snackbar'],trackMsgObj);
        } else {
          this._httpService.showSuccessMsg(`Document(s) uploaded successfully for SR number: ${requirementDtls.srno}`);
          this.trackUserActionsMsg({ 'eventName': `message - ${this.utils.getSubmitSRSuccessMsg(requirementDtls.srno, requirementDtls.subissuecd)}`,"error_code":"SM" });
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,this.data.disableClose,trackMsgObj);
      }
      if (!this.data.disableClose) {
        this.dialogRef.closeAll();
      }
    });
  }

  readTnC(event:any){
    this.accepted = event.checked;
  }

  showPswd() {
    if (this.passwordType == 'password') {
      this.passwordType = 'tel';
      this.reveal = true;
    } else {
      this.passwordType = 'password';
      this.reveal = false;
    }
  }

  otpFocusOut(event : any){
    this.otpFocus = true;
  }

  generateOtp() {
    let genOtpReqBody = {
      "userId" :  this.crypto.encryptJson(this.utils.getClientId()),
      "clientId" : this.crypto.encryptJson(this.data.CliAdd.CLIENTID),
      "srNo" : this.crypto.encryptJson(this.data.CliAdd.CLIENTID),
      "policyNo" : this.crypto.encryptJson(this.data.policyNo)
    }
    return this.myAccountService.getAadhaarConsentOTP(genOtpReqBody)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.showOtpSection = true;
          this.otpResp = response.body;
          this.OTPTimerStart();
          this.showOtpButton = false;
          this.edited = false;
          this.proceedFlag = false;
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
           this.message.openSnackBar('OTP sent successfully', 'xy', ['success-snackbar'],trackMsgObj);     
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          if(this.statusMessage) {
            let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
            this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar'],trackMsgObj);   
            this.editAddressDialog.close();
          }
        }
      }),
      catchError((error) => throwError(error))
  }

  RegenOTP() {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.generateOtp();
  }

  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        // if (this.minutes == 60) {this.minutes = 0;} }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }

  trackUserActions(action: string) {
    let type = this.data.type ? this.data.type : 'Mailing';
    if (this.data && this.showOcrUpdate && (type === 'Mailing' || type === 'Permanent')) {
      this.utils.trackUserActions({ 'eventName': `Edit ${type} address - ${action}`, 'flowType': this.trackUserFlowType() });
    } else if (this.data && !this.showOcrUpdate && (type === 'Mailing' || type === 'Permanent')) {
      this.utils.trackUserActions({ 'eventName': `Edit ${type} address - manual - ${action}`, 'flowType': this.trackUserFlowType() });
    }    
  }

  trackUserActionsMsg(payload) {
    this.utils.trackUserActions({ 'flowType': this.trackUserFlowType(), ...payload });
  }
  trackUserFlowType() {
    let flowType = 'my account';
    if (this.data && this.data.role == 'Life Assured') {
      flowType = 'Beneficiaries - LA'
    }
    if (this.data && this.data.role == 'Beneficiary') {
      flowType = 'Beneficiaries'
    }
    return flowType;
  }
  
  validateOTP(){
    if(this.timerSec > 0){
      let reqBody = {
        "trans_id" : this.otpResp.trans_id,
        "mobileNo" : this.otpResp.trans_id,
        "clientid" : this.crypto.encryptJson(this.data.CliAdd.CLIENTID),
        "otp" : this.crypto.encryptJson(this.beneficiaryOtpForm.get('otp').value),
        "srNo" : this.crypto.encryptJson(this.data.CliAdd.CLIENTID)
      }
      this.myAccountService.validateAadhaarConsentOTP(reqBody)
            .subscribe((response) => {
          if (this._httpService.isSuccessResponse(response)) {
            clearInterval(this.interval);
            this.submitAddress();
          }
          else {
            if(response.head.errordetails && response.head.errordetails.message.includes('incorrect OTP 0 attempts')){
              let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
                this.message.openSnackBar("You have Exceeded Max Attempt", 'xy', ['error-snackbar'],trackMsgObj);   
                this.editAddressDialog.close()
            }
            else if(response.head.errordetails && response.head.errordetails.message){
                this.statusMessage = response.head.errordetails.message;
            }
          }
          }
        ),
        catchError((error) => throwError(error))
    }
    else{
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
      this.message.openSnackBar("Time exceed to use OTP please generate-OTP",'xy', ['error-snackbar'],trackMsgObj);
    }
  }
  isSame(event : any){
    this.permMailSame = event.checked;
  }
  
}
