<div class="email-voc" *ngIf="questionAvailable">
    <div class="email-voc-padding">
        <div class="email-voc-heading">
            FEEDBACK FORM
        </div>
        <div class="email-voc-body">
            <mat-card class="voc-card">
                <form [formGroup]="emailVocForm" (ngSubmit)="onSubmit(message)" *ngIf="!noRecords">
                    <div class="form-block">
                        <div class="first-block" formArrayName="qaArray" *ngFor="let item of getControls();index as i">
                            <div [formGroupName]="i">
                                <div class="question-one logout-content-wrapper"><span>{{value + i}}.</span>
                                    {{item.get('questns').value}}<sup>*</sup></div>
                                <div class="answer-one" *ngIf="item.get('answer_type').value=='RATING'">
                                    <mat-button-toggle-group class="rating flex" formControlName="qusans"
                                        [disableRipple]="true">
                                        <!-- <span class="option-five" *ngFor="let item of ratingArray">
                                            <mat-button-toggle (click)="rating(item)" [disableRipple]="true"
                                                [ngClass]="{'option-five': (item == '5'),'option-four': (item == '4'),'option-three': (item == '3'),'option-two': (item == '2'),'option-one': (item == '1'),'five-border':  isFiveActive, 'four-border': (isFourActive),'three-border': (isThreeActive),'two-border': (isTwoActive),'one-border': (isOneActive)}">
                                                {{item}}
                                            </mat-button-toggle> -->
                                        <mat-button-toggle class="option-five" (click)="rating('5')"
                                            [ngClass]="{'button-border': isFiveActive}">
                                            5
                                        </mat-button-toggle>
                                        <mat-button-toggle class="option-four" (click)="rating('4')"
                                            [ngClass]="{'button-border': isFourActive}">
                                            4
                                        </mat-button-toggle>
                                        <mat-button-toggle class="option-three" (click)="rating('3')"
                                            [ngClass]="{'button-border': isThreeActive}">
                                            3
                                        </mat-button-toggle>
                                        <mat-button-toggle class="option-two" (click)="rating('2')"
                                            [ngClass]="{'button-border': isTwoActive}">
                                            2
                                        </mat-button-toggle>
                                        <mat-button-toggle class="option-one" (click)="rating('1')"
                                            [ngClass]="{'button-border': isOneActive}">
                                            1
                                        </mat-button-toggle>
                                        <!--[ngClass]="{'option-five': (item == '5'),'option-four': (item == '4'),'option-three': (item == '3'),'option-two': (item == '2'),'option-one': (item == '1'),'five-border': (isFiveActive),'four-border': (isFourActive),'three-border': (isThreeActive),'two-border': (isTwoActive),'one-border': (isOneActive)}"-->
                                        <!-- </span> -->
                                    </mat-button-toggle-group>
                                </div>
                                <div class="answer-one" *ngIf="item.get('answer_type').value=='RADIO'">
                                    <mat-radio-group formControlName="qusans" class="radio-btn-group radio-sub"
                                        color="primary">
                                        <mat-radio-button class="radio-btn-one" value="Yes" [disableRipple]=true>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button class="radio-btn-one" value="No" [disableRipple]=true>
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="answer-one" *ngIf="item.get('answer_type').value=='COMMENTS'">
                                    <mat-form-field floatLabel="never" class="frequency-form-field ">
                                        <textarea type='text' maxlength="500" rows="3"
                                            placeholder="(Enter your comments here)" formControlName="qusans"
                                            matInput></textarea>
                                        <mat-error class="error-message" *ngIf="item.controls.qusans.errors?.required">
                                            Mandatory</mat-error>
                                        <mat-error class="error-message" *ngIf="item.controls.qusans.errors?.pattern">
                                            Special characters not allowed in Comments section</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-error class="error-message invalid-message" *ngIf="!(emailVocForm.valid) && isSubmit">
                        Please complete the feedback</mat-error>
                    <div class="submit-btn">
                        <button class="submit" [disabled]="!(emailVocForm.valid)">Submit</button>
                        <!--[disabled]="!(emailVocForm.valid)"-->
                    </div>
                </form>
                <div class="display-message" *ngIf="noRecords">
                    No Records found
                </div>
            </mat-card>
        </div>
    </div>
</div>