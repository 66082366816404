import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policy-based-header',
  templateUrl: './policy-based-header.component.html',
  styleUrls: ['./policy-based-header.component.css']
})
export class PolicyBasedHeaderComponent implements OnInit {

  constructor(public router: Router,) { }

  ngOnInit(): void {
  }

}
