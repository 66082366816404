<div class="policy-no-dropdown">
    <div class="dropdown-wrapper" *ngIf="policyNoArray.length != 0">
        <mat-form-field appearance="standard" floatLabel="never" class="form-field select-menu" [ngClass]="{'error-field':selectedPolicy.hasError('required')}" >
            <mat-label>Select a policy</mat-label>
            <mat-select disableRipple disableOptionCentering class="policy-select" [formControl]="selectedPolicy" required [disabled]="readOnly">
                <mat-option *ngFor="let item of policyNoArray" (click)="onPolicySelect(item.val)" [value]="item.val">{{item.label}}</mat-option>
            </mat-select>
            <img mat-suffix src="assets/angle_down.svg" *ngIf="!readOnly">
            <mat-error *ngIf="selectedPolicy.hasError('required')" class="error-message">This field is required.</mat-error>
        </mat-form-field>
    </div>
</div>
