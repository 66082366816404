<div class="toaster" *ngIf="!(isCPAdminPortal)">
    <div class="toaster-wrapper flex-center" [ngClass]="{'toaster-hide': isHidden}">
        <div class="msg-wrapper flex-center">
            <img src="assets/info_circle_red.svg">
            <div class="msg" [innerHtml]="toasterMesg "> </div>
        </div>    
        <button class="toaster-btn" (click)="onUpdateBtnClick(toasterTarget)">{{toasterLabel}} &#8250;</button>
        <img src="assets/cross.svg" class="close-btn" (click)="hideToaster()">
    </div>    
</div>
