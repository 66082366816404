import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { LoginRoutingModule } from "./login-routing.module";
import {MatButtonModule} from "@angular/material/button";
import { MatKeyboardModule } from 'angular-onscreen-material-keyboard';
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FacingLoginIssuesPopupComponent } from './facing-login-issues-popup/facing-login-issues-popup.component';
import { ChangeSecretQuestionsComponent } from './change-secret-questions/change-secret-questions.component';
import { ForgotClientIdComponent } from './forgot-client-id/forgot-client-id.component';
import { SetUpSocialMediaIdComponent } from './set-up-social-media-id/set-up-social-media-id.component';
import { NewLoginComponent } from './new-login/new-login.component';
import { SessionDialogComponent } from './session-dialog/session-dialog.component';

@NgModule({
  declarations: [LoginComponent, ResetPasswordComponent, ForgotPasswordComponent, UserRegistrationComponent, FacingLoginIssuesPopupComponent, ChangeSecretQuestionsComponent, ForgotClientIdComponent, SetUpSocialMediaIdComponent,    NewLoginComponent, SessionDialogComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTabsModule,
    LoginRoutingModule,
    MatButtonModule,
    MatKeyboardModule,
    SharedModule,
    MatRadioModule,
    MatIconModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
  ]
})
export class LoginModule { }
