// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const serviceURL = {
  initURL: 'portal/config/auth/v1/init',
  login_url: "portal/authenticate/auth/v1/custlogin",
  NEFTdtls_url: "portal/servicerequests/api/v1/getExistingAccntDtls",
  Contactdtls_url: "portal/servicerequests/api/v1/getClientContactDtls",
  ClientAddressdtls_url:"portal/servicerequests/api/v1/getClientAddressDtls",
  CommAddressdtls_url:"portal/servicerequests/api/v1/getCommAddressDtls",
  estmntDtls_url: "portal/estatement/api/v1/getEstatementDtls",
  paymntReceiptDtls_url: "portal/estatement/api/v1/getReceiptDetail",
  tdsDtls_url: "portal/estatement/api/v1/getTDSSearchDtls",
  tdsMailPdf_url: "portal/estatement/api/v1/getTDSPDFMailDtls",
  toaster_url: "portal/dashboard/api/v1/getClientPtDetail",
  confirmContact_url: "portal/dashboard/api/v1/addContactDtlConfirmation",
  confirmNudges_url: "portal/dashboard/api/v1/getNeftContactConfirmation",
  confirmAddress_url : "portal/dashboard/api/v1/addAddressDtlConfirmation",
  confirmNeft_url: "portal/dashboard/api/v1/addNeftConfirmation",
  policyCard_url: "portal/dashboard/api/v1/getPolicyCard",
  policypdfDtls_url: "portal/estatement/api/v1/getPolicypdfDtls",
  formD02_url: "portal/estatement/api/v1/insertD02Dtls",
  createUser_url:"portal/authenticate/api/v1/CreateUser",
  genOtp_url: "portal/authenticate/auth/v1/generateotp",
  validateOtp_url: "portal/authenticate/auth/v1/validateotp",
  ECSdtls_url: "portal/servicerequests/api/v1/getEnachTaggingDetails",
  personalDtls_url:"portal/servicerequests/api/v1/getClientPersonalDtls",
  EstmntPdfMail_url:"portal/estatement/api/v1/getEstmntPDFMail",
  mailpolicyDocview_url:"portal/estatement/api/v1/getPolicyDocumentS3",
  mailPolicyDocdownload_url:"portal/estatement/api/v1/mailPolicyPDF",
  ReceiptpdfMail_url:"portal/estatement/api/v1/getReceiptPdfMail",
  validateUser_url:"portal/authenticate/auth/v1/validateUser",
  policydetails_url:"portal/search/api/v1/getPolicyDetail",
  paymentHistoryDetails_url:"portal/search/api/v1/getPaymentHistoryDetails",
  nomineeDetails_url:"portal/search/api/v1/getNomineeDetails",
  validateSR_url: "portal/servicerequests/api/v1/getSRValidationResult",
  getAttrList_url: "portal/masterdata/api/v1/mstr_doc_req/REQ_DOC_ATTRIBUTE--",
  docUpload_url: "portal/servicerequests/api/v1/docUpload",
  getRiderDtls_url: "portal/search/api/v1/getRiderDetails",
  existingfundAllocDtls_url: "portal/servicerequests/api/v1/getExistingFundAllocation",
  lifeInsuredDetails_url:"portal/search/api/v1/getLifeInsuredDetails",
  srListDtls_url:"portal/servicerequests/api/v1/getSrList",
  masterSecretQues: "portal/authenticate/auth/v1/getSecretQues",
  submitSecretQues: "portal/authenticate/auth/v1/submitscreatquestion",
  getDNDstatus_url:"portal/servicerequests/api/v1/getDNDstatus",
  getRequirementDtls_url: "portal/servicerequests/api/v1/getRequirementDetails",
  getDocList_url: "portal/masterdata/api/v1/mstr_doc_req/REQ_DOC--",
  getSurrenderDocList_url: "portal/masterdata/api/v1/mstr_doc_req/REQ_DOC_ALT--",
  panValidation_url: "portal/servicerequests/api/v1/validatepan",
  getintouch_url:"portal/dashboard/api/v1/getGetInTouch",
  getMedicalPdfMail:"portal/estatement/api/v1/getMedicalPdfMail",
  getAppNo: "portal/estatement/api/v1/getAppNo",
  validateSecretQ_url: "portal/authenticate/auth/v1/validatescreatquestion",
  changePassword_url: "portal/authenticate/api/v1/changePassword",
  setPassword_url: "portal/authenticate/api/v1/SetPassword",
  getSecretQ_url: "portal/authenticate/auth/v1/getscreatquestion",
  getD02form_url:"portal/estatement/api/v1/getD02FormDtls",
  submitvocfeedback_url:"portal/authenticate/auth/v1/submitvocfeedback",
  getNomineeList_url: "portal/search/api/v1/getNomineeList",
  getKycExpiryDtls_url: "portal/servicerequests/api/v1/getKycDtls",
  getCountryCode_url: "portal/masterdata/api/v1/mstr_contact/GCOM_COUNTRY_CODE",
  getAccountType_url: "portal/masterdata/api/v1/mstr_com_accounttype/GCOM_ACCOUNT_TYPE",
  getSurrenderQuote_url: "portal/servicerequests/api/v1/getSurrenderQuote",
  getIfscDtls_url: "portal/masterdata/api/v1/mstr_com_branch/BRANCH_BY_IFSC--",
  activateSI_url:"portal/search/api/v1/getRedirectSI",
  sendAlerts_url: "portal/estatement/api/v1/sendAlerts",
  createSubmitFSPRTopUpSR_url: "portal/servicerequests/api/v1/createSubmitFSPRTopUpSR",
  getAddressMasterData_url: "portal/masterdata/api/v1",
  whatsappMe_url: "portal/servicerequests/api/v1/getWhatsappStatus",
  createSRDND_url:"portal/servicerequests/api/v1/createSRDND",
  getTopUpDtls_url: "portal/servicerequests/api/v1/getPolicyTopUpDtls",
  getPolicyRevivalStatus_url:"portal/servicerequests/api/v1/validatePolicyRevivalStatus",
  getRevivalQuote_url:"portal/servicerequests/api/v1/getRevivalQuoteDtls",
  getTopUpPayment_url: "portal/servicerequests/api/v1/getDigitalPayment",
  createSubmitRevivalSR_url:"portal/servicerequests/api/v1/createSubmitRevivalSR",
  deactivateSI_url: "portal/search/api/v1/getDeactivateSI",
  lifeProcessSubmit_url: "portal/servicerequests/api/v1/lifeProcessSubmit",
  getMICRdts_url:"portal/masterdata/api/v1/searchBankDetail",
  ocrDocUpload_url: "portal/servicerequests/api/v1/ocrdocumentupload",
  estatementCreateSr_url:"portal/estatement/api/v1/createNewEstmtReq",
  FrQuestionnaire_url: "portal/servicerequests/api/v1/getFrQuestionnaire",
  getSRDetails_url: "portal/servicerequests/api/v1/getSrDetails",
  fulfillRequirement_url: "portal/servicerequests/api/v1/requirementfullfill",
  getPhsMasterData_url: "portal/masterdata/api/v1/mstr_cp_revival",
  getSmqMasterData_url: "portal/masterdata/api/v1/mstr_cp_health_smq",
  getCovidMasterData_url: "portal/masterdata/api/v1/mstr_cp_claimsintimation",
  getTrackPercentage_url: "portal/servicerequests/api/v1/getTrackPercentage",
  getSubscriptionOnLoad_url: "portal/estatement/api/v1/getsubscribeunitonload",
  subscribeUnitStatement_url: "portal/estatement/api/v1/subscribeunitsubmit",
  getSubscribeUnitMaster_url: "/portal/masterdata/api/v1/mstr_com_cp_unitsubscribe/CP_SUBSCRIBE_UNIT",
  wellnessVoucher_url: "portal/dashboard/api/v1/getWellnessVoucherdetails",
  generateVoucher_pdf: "portal/dashboard/api/v1/voucherGeneratepdf",
  getPennyDropCount_url: "portal/servicerequests/api/v1/getPennyDropFlag",
  getPennyDropOTP_url: "portal/servicerequests/api/v1/sendPennyDropOTP",
  validatePennyDropOTP_url: "portal/servicerequests/api/v1/validatePennyDropOTP",
  genPennyDropSR_url: "portal/servicerequests/api/v1/genPennyDropSR",
  AmsOnloadDtls_url: "portal/claims/api/v1/getAmsOnloadDetails",
  IllustrativeDtls_url: "portal/claims/api/v1/getIllustrativeDetails",
  IllustrativeMail_url: "portal/claims/api/v1/getIllustrativeMailPdf",
  getOfflineAadhar_url: "portal/servicerequests/api/v1/ekycPageLoad",
  getMaturityClaim_url: "portal/claims/api/v1/getMaturityOnload",
  getClaimDtls_url: "portal/claims/api/v1/getClaimOnclickDtls",
  JointeeNomineeDetailAms_url: "portal/claims/api/v1/getJointeeNomineeDetails",
  amsCreateSr_url: "portal/claims/api/v1/amsCreateSR",
  amsPayoutOnload_url:"portal/claims/api/v1/getAnnuityPayoutOnload",
  getNREAccDtls_url: "portal/claims/api/v1/getNREAccDetails",
  createsubmitMaturitySR_url: "portal/claims/api/v1/createSubmitClaimSR",
  getPayeeDtlsOnload_url: "portal/claims/api/v1/getPayeeDtlsOnload",
  getPayeeDtlsClaim_url: "portal/claims/api/v1/getPayeeDetailsClaims",
  getPayoutNEFT_url: "portal/claims/api/v1/getNREAccDetails",
  getPayeePersonalDtls_url: "portal/claims/api/v1/userPayeeDetailsOnload",
  getkycPayoutSrCheck_url: "portal/claims/api/v1/payeeVerfdDtls",
  getPANreq_url: "portal/claims/api/v1/tagPayeeDetailsForSR",
  getMoneyBack_url: "portal/claims/api/v1/getMoneyBackOnload",
  getOfflineAadharAddrDtls_url: "portal/servicerequests/api/v1/getAadharPopulatedDtls",
  redirectCombiPolicy_url: "portal/estatement/api/v1/getErgoRedirect",
  reinitURL: 'portal/dashboard/api/v1/reinit',
  updatePremium_url: "portal/claims/api/v1/updatePremDetails",
  getVocQues_url: "portal/authenticate/auth/v1/loadvocfeedback",
  getChecklist_url: "portal/servicerequests/api/v1/getCheckList",
  medicalDocDtlsStatement_url: "portal/estatement/api/v1/getMedicalDocDtls",
  autoDebitReport_url: "portal/search/api/v1/AutoDebitAudit",
  genericDocUpload_url: "portal/servicerequests/api/v1/genericDocUpload",
  nudgeRedirectUpadte_url: "portal/dashboard/api/v1/insertClickTracking",
  getNextBestAction_url:"portal/dashboard/api/v1/getNextBestAction",
  getMatBiData_url: "portal/estatement/api/v1/getMatBPolicies",
  getQformsDtls_url: "portal/customlinksservice/api/v1/validateQformToken",
  getQformsRedirectUrlDtls_url: "portal/customlinksservice/api/v1/redirectUrlToken",
  createSalutationMaritalSr_url:"portal/servicerequests/api/v1/createSalutationMaritalSr",

  //hospital urls
  getHospitalStates_url: "portal/customlinksservice/api/v1/getHospitalStates",
  getHospitalCityByState_url: "portal/customlinksservice/api/v1/getHospitalCities",
  getHospitalList_url: "portal/customlinksservice/api/v1/getHospitalList",

  // email voc
  emailVocOnLoad_url: "portal/customlinksservice/api/v1/emailVocQnsOnload",
  emailvocSubmit_url: "portal/customlinksservice/api/v1/emailVocAnsSubmit",
  
  //Vserv url's
  vservOnload_url:"portal/customlinksservice/api/v1/getVserveOnlaod",
  vservOtp_url:"portal/customlinksservice/api/v1/sendVserveOTP",
  vservValidateOtp_url:"portal/customlinksservice/api/v1/validateVserveOTP", 
  validateNriVserv_url:"portal/customlinksservice/api/v1/validateNriVserv", 

  //SR- Track URL's
  fetchSRDetails_url: "portal/customlinksservice/auth/v1/fetchsrdetails",
  fetchSRTrackDtls_url: "portal/customlinksservice/auth/v1/tracksrdetails",
  validateSrPolicyDob_url : "portal/customlinksservice/auth/v1/validatesrpolicydob",
  fetchPolicy_url: "portal/customlinksservice/api/v1/fetchPolicy",
  fetchDocUpload_url: "portal/customlinksservice/auth/v1/tracksrdocupload",

  //cheque pickup
  ClientDtls_url: "portal/customlinksservice/auth/v1/getClientDetails",
  bookappintment_url:"portal/customlinksservice/auth/v1/validatePincode",
  exideGenerateOtp_url:"portal/authenticate/auth/v1/CustomlinkGenerateOTP",
  exideValidateOtp_url:"portal/authenticate/auth/v1/CustomlinkCheckOTP",
  pincodeMasterData_url:"portal/masterdata/api/v1/mstr_com_chequepickup_pincode/CP_CHEQUEPICKUP_PINCODE",

    //wellness voucher
    getVoucherDetails_url: "portal/customlinksservice/auth/v1/Voucherdetails",
    customVoucherPdf_url: "portal/customlinksservice/auth/v1/voucherGeneratepdf",
    getPolicyVoucher_url: "portal/customlinksservice/api/v1/getPolicyVoucher",

  getToken_url: "portal/authenticate/server/gettoken",
  validateToken_url: "portal/authenticate/server/validatetoken",

  ssoGetToken_url: "portal/config/auth/v1/getToken",
  ssoValidateToken_url: "portal/config/auth/v1/validateToken",

  customLinkValidateToken_url: "portal/customlinksservice/api/v1/validateCustomLinkToken",
  getUrlRkDtls_url: "portal/customlinksservice/api/v1/frDocUploadOnload",

  //surrender URL's
  getSurrReasonMasterData_url: "portal/masterdata/api/v1/mstr_cp_surr",
  getNavDate_url: 'portal/claims/api/v1/getNavDate',
  getSurrenderPayeeDtlsOnload_url: "portal/claims/api/v1/getSurrenderPayeeDtlsOnload",
  getSurrenderSr_url: "portal/claims/api/v1/createSurrenderProcessSR",
  submitSurrenderSr_url: "portal/claims/api/v1/submitSurrenderProcessSR",
  getExistingSRDetails: "portal/claims/api/v1/loadExistingSRPage",
  getPendingSRDetails: "portal/claims/api/v1/validatePendingSRTrack",
  getExistingQuotePayee: "portal/claims/api/v1/loadExistingQuotePayee",
  getSurrenderQuery_Url: "portal/servicerequests/api/v1/createSurrenderQuery",

  //exide URL's
  custLogin_url:"portal/authenticate/auth/v1/customerlogin",
  sendOTP_url:"portal/authenticate/auth/v1/sendOTP",
  userValidate_url:"portal/authenticate/auth/v1/checkUser",
  validateOtpLogin_url: "portal/authenticate/auth/v1/checkOTP",
  calcCheckSum_url: "portal/authenticate/auth/v1/generateCheckSum",
  exideDashboardURL: 'https://gateway2.exidelife.in/selfServiceApp/redirectDashboard',
  exideURL : 'https://gateway2.exidelife.in/selfServiceApp/',
  dedupe_url : "portal/authenticate/auth/v1/getDeduperesponse",
  specialLifeConfig : "ON",

  //one pager logout voc url
  vocSubmit_url: "portal/authenticate/api/v1/VocSubmit",
  vocFeedbackSubmit_url: "portal/authenticate/api/v1/VocFeedbackSubmit",
  //logout transaction url:
  logoutmode_url: "portal/authenticate/auth/v1/logout",

    //frAgentDocUpload url's
    validateAgent_url: "portal/authenticate/api/v1/validateAgent",
    generateAgentOtp_url: "portal/authenticate/api/v1/ganerateAgentOtp",
    validateAgentOtp_url: "portal/authenticate/api/v1/validateAgentOtp",
    agentSrDtls_url: "portal/servicerequests/api/v1/agentSrDetails",
    getAgentDocList_url:"portal/servicerequests/api/v1/onloadAgentDocUpload",
    saveAgentImage_url : "portal/servicerequests/api/v1/saveAgentImage",

  //surrender aversion URLs
  sendotp_url: "portal/authenticate/auth/v1/CustomlinkSendOTP",
  valotp_url: "portal/authenticate/auth/v1/CustomlinkValidateOTP",
  surrenderVideo_url: "portal/customlinksservice/api/v1/getVideoLink",
  surrenderReason_url: "portal/customlinksservice/api/v1/getAversionOption",
  surrenderVideoCall_url: "portal/customlinksservice/api/v1/getVideoCallLink ",
  updateAversionDtls_url: "portal/customlinksservice/api/v1/getUpdateAversionDtls",
  validateSurrender_url: "portal/customlinksservice/api/v1/getsurrendervalidate",
  policySummarySurrender_url: "portal/customlinksservice/api/v1/getPolicySummary",

    //investigator flow URLs

    investigatorotp_url: "portal/authenticate/auth/v1/InvestigatorSendOTP",
    investigatorvalotp_url: "portal/authenticate/auth/v1/InvestigatorValidateOTP",
    investigatorFr_url: "portal/customlinksservice/auth/v1/getfrdetails",
    investigatorSr_url: "portal/customlinksservice/auth/v1/getsrdetails",
    investigatorSrDtls_url: "portal/customlinksservice/auth/v1/getSRDtls",
    investigatorFrDtls_url: "portal/customlinksservice/auth/v1/getFRDtls", 
    investigatorSumbit_url: "portal/customlinksservice/auth/v1/investigationSubmit",
    investigatorDocUpload_url: "portal/customlinksservice/auth/v1/docUpload",
    investIncome_url: "portal/customlinksservice/auth/v1/insertInvestincome",

  //adcat rider
  getAdcatRiderDtls_url: "portal/search/api/v1/getAdcatRiderDetails",
  updateAdcatRiderMis_url: "portal/search/api/v1/updateAdCatRiderMis",

  //death claim url's
  getDeathClaimForm_url: "portal/customlinksservice/api/v1/getDigitalClaimForm",
  deathClaimStatusUpdate_url: "portal/customlinksservice/api/v1/DcfStatusUpdate",
  deathClaimPdf_url: "portal/customlinksservice/api/v1/getDCFpdf",

  //search bar
  searchMaster_url: "portal/masterdata/api/v1/mstr_search_bar/SEARCH_KEYWORD",
  searchTrending_url: "portal/search/api/v1/getTrendingSearch",
  searchHistory_url: "portal/search/api/v1/searchHistory",
  
  getInvestDtls: "portal/search/api/v1/fetchPremiumDetails",

  getPANFlagsForMerger_url: "portal/search/api/v1/getPanValidation",
  getClientMergerList_url: "portal/search/api/v1/getClientMergerlist",
  createClientMerger_url: "portal/search/api/v1/clientmerge",

  //Exide covid and phs
  validateExidePhs_url: "portal/servicerequests/api/v1/validateExideRevival",
  submitExideForm_url: "portal/servicerequests/api/v1/submitExideRevival",
  exideCovidPDF_url: "portal/servicerequests/api/v1/chubExideRevival",

  //excessRefund
  getPayoutsHistoryDtls_url: "portal/search/api/v1/PayOutDtls",
  getUnclaimedSuspenseAmtFlags_url: "portal/claims/api/v1/Validateclaimsr",
  suspenseAmtCreateSR_url: "portal/claims/api/v1/SuspenseAmntCreateSubmitSR",
  suspenseAmtDocUpload_url: "portal/claims/api/v1/SuspenseAmountDocUpload",

   //Enach urls
  createSubmitSR_url: "portal/servicerequests/api/v1/createSubmitSR",
  changeFreqValidation_url: "portal/masterdata/api/v1/mstr_com_mode/CHNG_FREQ_VALIDATION--",
  quickPaymentEnach_url: "portal/servicerequests/api/v1/getenachQuickpay",
  enachSubmitcallPayment_url: "portal/servicerequests/api/v1/enachSubmitcall",
  calcNewPremium_url: "portal/servicerequests/api/v1/calculateNewPremium",
  getBankListData_url: "portal/servicerequests/api/v1/fetchEnachBanks",
  getTransactionDtls_url: "portal/servicerequests/api/v1/searchDigitalPayment",

    // covid and phs
  validatePhs_url: "portal/customlinksservice/auth/v1/",
  covidPDF_url: "portal/customlinksservice/auth/v1/covidphschubcall",
  countryCode : "portal/masterdata/api/v1/mstr_country_code/COUNTRY_CODE",
  getFYPayments : "portal/estatement/api/v1/getFYPaymentDtls",

  //BFHL
  bfhlsrDtls_url:"portal/servicerequests/api/v1/getBFHLSrDetails",
  
  //block advance payment url
  advance_payment_url: 'portal/dashboard/api/v1/advPaymtClckTrck',

  //genz
  notifications_url: "portal/search/api/v1/getNotifications",
  updateNotificationStatus_url: "portal/search/api/v1/updateNotificationStatus",
  profileCompleteness_url: "portal/servicerequests/api/v1/getProfileCompleteness",

  //pasa url's
  getPasaDtls_url:'portal/search/api/v1/getPasaDtls',
  updatePasaDtls_url:'portal/search/api/v1/updatePasaClk',

  //nps
  nps_link:"https://cra.kfintech.com/poponline/popverification?AuthID=26745DFD703F8325B79A36D4CB53744E89944C1D4920C3AD7DC5A83196306BBB0DFF1C72A0590DA64BF1D2A953A7DFBB ",
  getNps_url:'portal/search/api/v1/getNPSDetails',
  updateNps_url:'portal/search/api/v1/updateNPSClckTrck',
  
  //loans
  getRepaymentForLoanByPolicyNumber_url: "portal/search/api/v1/getRepaymentForLoanByPolicyNumber",
  getLoanEnquiry_url: "portal/search/api/v1/getLoanEnquiry",
  updateloanEligibleMis_url: "portal/search/api/v1/updateloanEligibleMis",
  getLoanStatementDetails_url: "portal/search/api/v1/getLoanStatmentDetails",
  getLoanStmtEmail_url: "portal/search/api/v1/getLoanStatement",

  //crossSell Links
  savingsplan_url : 'https://onlineinsurance.hdfclife.com/buy-online-savings-plans/click-2-achieve/basic-details?source=MyAcc_Rec_C2A&agentcode=00399206&language=en',
  healthplan_url: 'https://www.hdfclife.com/health-insurance-plans',
  termplan_url:'https://onlineinsurance.hdfclife.com/buy-online-term-insurance-plans/click-2-protect-super/basic-details?source=MyAcc_Rec_C2PS&agentcode=00399206&language=en',
  ulipplan_url:'https://onlineinsurance.hdfclife.com/buy-online-investment-plans/click-2-invest-ulip-plan/start-investing?source=MyAcc_Rec_C2I&agentcode=00399206&language=en',
  childplan_url:'https://onlineinsurance.hdfclife.com/buy-online-savings-plans/click-2-achieve/basic-details?source=MyAcc_Rec_C2AC&agentcode=00399206&language=en',
  retirementplan_url:'https://onlineinsurance.hdfclife.com/buy-online-retirement-and-pension-plans/smart-pension-plan/basic-details?source=MyAcc_Rec_SPP&agentcode=00399206&language=en',

  lifeai: "https://lifeai-uat.api-hdfclife.com/widget/v1/js?apiKey=557aba71-3102-4c40-8851-47a4478502e2",
  offlineAadharLink: "https://aadhaar-ekyc-u.hdfclife.com/EcsOkycWebGateway/ProcessRequest.jsp",
  onlinePayment: "https://digitalpayments-uat.hdfclife.com/HLifeWeb-QP/hlife/quick_mandate_registration.jsp",
  applozicChatBot: "https://cdn.applozic.com/applozic/applozic.aes.js",
  applozicChat:"https://cdn.applozic.com/applozic/applozic.chat-5.9.min.js",
  incomeTaxAadhar: "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar",
  unClaim: "https://www.hdfclife.com/customer-service/claims/unclaimed-policyholder-payment-dues-amount-disclosure",
  policyLoans: "https://loans-journey-uat.apps-hdfclife.com/?source=myacc_ta",
  payPremium: "https://www.hdfclife.com/customer-service/pay-premium",
  applicationTracker:"https://onlineinsurance.hdfclife.com/portal.do?_pageid=ops_resappPage&_portalid=ops_resumeApp&agentcode=00399206",
  makeaClaim: "https://www.hdfclife.com/customer-service/claims",
  branchLocator: "https://www.hdfclife.com/contact-us#BranchLocator",
  policyServicing: "https://www.hdfclife.com/customer-service",
  buyTermInsurancePlanOnline: "https://onlineinsurance.hdfclife.com/buy-online-term-insurance-plans/click-2-protect-plus/basic-details?source=CP_C2P_Redirect&utm_medium=InternalWebsites&utm_source=CustomerPortal&utm_campaign=C2P-Redirect&agentcode=00399206",
  escalations: "https://www.hdfclife.com/customer-service/grievance-redressal",
  contactUsChat: "https://www.hdfclife.com/customer-service/contact-us",
  unitPrices: "https://www.hdfclife.com/fund-performance",
  submitLifeCertificate: "https://lifecertificate.hdfclife.com/",
  groupCustomer: "https://hslcp.hdfclife.com/CPWeb/login.aspx?GP_F=GP_Login",
  forgotPwdPopup: "https://chat.apps-hdfclife.com/",
  newPolicy: "https://www.hdfclife.com",
  sidebannerLink: "https://api.whatsapp.com/send?phone=918291890569&text=Hi",
  recommendPlanOne : "https://www.hdfclife.com/term-insurance-plans/click-2-protect-super?source=MyAcc_Rec_C2PS&agentcode=00399206&language=en",
  recommendPlanTwo : "https://www.hdfclife.com/ulip-plans/smart-protect-plan?source=MyAcc_Rec_SProtectPlan&agentcode=00399206&language=en",
  recommendPlanThree : "https://www.hdfclife.com/savings-plans/sanchay-plus?source=MyAcc_Rec_SANCHAYP&agentcode=00399206&language=en", 
  marketOutlook: "https://www.hdfclife.com/content/dam/hdfclifeinsurancecompany/fund-performance/pdf/Market-Outlook.pdf",
  speakToAnAdvisor: "https://crm.hdfcleads.com/?name=&mobile=&email=&pid=101&pname=Website_MyAcc&cname=C2P3D_Campaign_May_2018&datetime=24052018_HHMMSS&ccid=0001",
  maturityPayout: "https://myaccountnew-uat.hdfclife.com/my-link?action=MAT_CLAIM",
  onlinePaymentcheque:"https://onlinepayments.hdfclife.com/HLifeWeb-QP/hlife/quick_pay.jsp",
  // UAT
  trackingScript: "https://assets.adobedtm.com/13917eaed364/3703fef3a2c4/launch-6edde690cd7d-development.min.js",
  // PROD
  // trackingScript:"https://assets.adobedtm.com/13917eaed364/3703fef3a2c4/launch-df79273a80f5.min.js",
    //to capture aadhaar consent
  captureAadhaarConsent_url : "portal/customlinksservice/api/v1/captureAadhaarConsent",
  //to send and validate otp for aadhaar consent
  aadhaarConsentSendOTP_url : "portal/authenticate/auth/v1/aadhaarConsentSendOTP",
  aadhaarConsentValidateOTP_url : "portal/authenticate/auth/v1/aadhaarConsentValidateOTP",
  sendAadhaarConsentLink_url : "portal/servicerequests/api/v1/sendAadhaarConsentLink",
  getSrChecklist_url : "portal/servicerequests/api/v1/getSrCheckList",

  getAadhaarConsentFlag : "portal/servicerequests/api/v1/getAadhaarConsentFlag",
  incomeProofDocuments : "https://www.hdfclife.com/customer-service/document-advisor",
  coBrowser: "https://hdfclife-uat.allincall.in/static/EasyAssistApp/js/easy-assist-v6.js?key=e894086c-fbf2-414f-b102-e9fb27442b88",
  quickPay_url_success: "https://payroute-u.hdfclife.com/Checkout/QuickPaySuccess",
  loansMandateReg: "https://digitalpayments-uat.hdfclife.com/HLifeWeb-QP/hlife/lms_mandate.jsp",
  loansEMIPayment: "https://digitalpayments-uat.hdfclife.com/HLifeWeb-QP/hlife/lmsPage.jsp",
  availLoanLink: "https://wa.me/8291890569?text=loan_against_policy",
};
export const enc_dec_key = {
  key: "1a4fc400b06d45a3",
  iv: "808ae4abb1feb27b"
};

export const serviceTimeout = 30000

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
