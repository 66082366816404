import { Component, OnInit, Input, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { loginService } from 'src/app/services/login/login.service';
import { HttpService } from '../../../services/http/http.service';
import { Observable, throwError, from } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { DataService } from 'src/app/services/data/data.service';
import { importType } from '@angular/compiler/src/output/output_ast';
import { validateUserReqBody } from "./forgot-password-data-modal";
import { EventEmitter } from 'events';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog } from '@angular/material/dialog';
import { FacingLoginIssuesPopupComponent } from '../facing-login-issues-popup/facing-login-issues-popup.component';
import { ForgotClientIdComponent } from '../forgot-client-id/forgot-client-id.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AddnomineeService } from '../../my-policies/addnominee-service/addnominee.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {


  timer: number = 180;
  showCurrentPassword: boolean = false;
  clientIDForm: FormGroup;
  emailIDForm: FormGroup;
  mobileForm: FormGroup;
  passwordOptionsForm: FormGroup;
  clientFocusOut: boolean = false;

  otpForm: FormGroup;
  otpFocus: boolean = false;
  interval: any;

  qtnForm: FormGroup;
  firstQtnFocus: boolean = false;
  secondQtnFocus: boolean = false;

  showOtpSection: boolean = false;
  showScrtQtns: boolean = false;

  isFirstMasked: boolean = true;
  isSecondMasked: boolean = true;

  isLoggedIn: boolean = false;
  showResetPassword: boolean = false;
  Valmethod: any;
  statusMessage: string;
  LoginData = this.dataService.getLoginResp();

  //Responses :
  validateUserResp: any = {};
  getFirstQuestion: any;
  getSecondQuestion: any;
  validateSecretQuestionResp: any;
  validateID: boolean;
  genOtpResp: any;
  ov_loginid: any;
  validateOtpResp: any;
  getFirstQuestionRK: any;
  getSecondQuestionRK: any;
  maskMobileNo: any;
  maskEmailId: any;
  NotAvailable: boolean = false;
  hours: number = 0;
  minutes: number = 0;
  mobileNo: any;
  emailID: any;


  constructor(private fb: FormBuilder, private message: MessageService, public auth: AuthService, public dialog: MatDialog, private router: Router, public utils: Utils, public loginService: loginService, public _httpService: HttpService,
    public dataService: DataService,public addnomineeService: AddnomineeService) { }

  ngOnInit(): void {
    if (this.utils.getExpiredFlag() =="Y") {
      this.statusMessage = "Your password has expired , Kindly change your password";
    }
    if (this.utils.getLocalData('loggedIn', false)) {
      this.isLoggedIn = true;
      this.emailID = this.LoginData.EMAIL_ID;
      this.mobileNo = this.LoginData.MOBILE_NUM;
      if (this.emailID == this.mobileNo) {
        this.NotAvailable = true;
      }
    }
    this.passwordOptionsForm = this.fb.group({
      passwordRadio: ['', [Validators.required]],
    });

    this.clientIDForm = this.fb.group({
      client: ['', [Validators.required, Validators.pattern('^.{0,8}$')]],
      mode: ['', [Validators.required]],
    });
    this.emailIDForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      mode: ['', [Validators.required]],
    });
    this.mobileForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10,20}$')]],
      mode: ['', [Validators.required]],
    })
    this.otpForm = this.fb.group(
      {
        otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
      }
    );
    this.qtnForm = this.fb.group({
      first: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      second: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]]
    });
    if(this.utils.getLocalData('loggedIn', false)) {
      this.passwordOptionsForm.get('passwordRadio').setValue('currentPassword');
        this.showCurrentPassword = true;
        this.showResetPassword = true;
        this.showOtpSection = false;
        this.showScrtQtns = false;
    }
    else {
      this.validateUserResp = this.addnomineeService.getDeleteNomineeDtls();
      this.passwordOptionsForm.get('passwordRadio').setValue('secretQuestion');
      this.showScrtQtns = true;
      this.getscreatquestion();
      }
    if(this.validateUserResp.length == 0) {
      this.router.navigate(['/']);
    }
    /* if (this.router.url.includes('forgot-password')) {
      this._httpService.init({}).subscribe((resp) => {
        this._httpService.initResp = resp;
      }),
      catchError((error) => throwError(error));
    } */
  }

  get clientValidation() { return this.clientIDForm.controls; }
  get emailValidation() { return this.emailIDForm.controls; }
  get mobileValidation() { return this.mobileForm.controls; }
  get otpValidation() { return this.otpForm.controls; }
  get qtnValidation() { return this.qtnForm.controls; }

  focusOut(event: any) {
    this.clientFocusOut = true;
  }
  otpFocusOut(event: any) {
    this.otpFocus = true;
  }
  firstQtnFocusOut(event: any) {
    this.firstQtnFocus = true;
  }
  secondQtnFocusOut(event: any) {
    this.secondQtnFocus = true;
  }

  submitClientIDForm() {
    if (this.clientValidation.mode.value === '1') {
      this.genOtp();
    }
    else if (this.clientValidation.mode.value === '2') {
      this.getscreatquestion();
      this.showSecretQtns();
    }
  }
  submitEmailForm() {
    if (this.emailValidation.mode.value === '1') {
      this.genOtp();
    }
    else if (this.emailValidation.mode.value === '2') {
      this.showSecretQtns();
      this.getscreatquestion();
    }
  }
  submitMobileForm() {
    if (this.mobileValidation.mode.value === '1') {
      this.genOtp();
    }
    else if (this.mobileValidation.mode.value === '2') {
      this.showSecretQtns();
      this.getscreatquestion();
    }
  }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.hours = ((this.timer / 60) | 0);
        if (this.timer >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timer) }
        else { this.minutes = this.timer; }
        this.timer--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  RegenPassOTP() {
    clearInterval(this.interval);
    this.timer = 180;
    this.genOtp()
  }
  submitOtpForm() {
  }
  submitQtnForm() {
  }

  showOTP() {
    this.showOtpSection = true;
    this.showScrtQtns = false;
  }
  showSecretQtns() {
    this.showOtpSection = false;
    this.showScrtQtns = true;
  }

  get passwordOptionsValidation() { return this.passwordOptionsForm.controls; }

  submitPasswordOption() {
    if (this.passwordOptionsForm.valid) {
      if (this.passwordOptionsValidation.passwordRadio.value === 'currentPassword') {
        this.showCurrentPassword = true;
        this.showResetPassword = true;
        this.showOtpSection = false;
        this.showScrtQtns = false;
      } else if (this.passwordOptionsValidation.passwordRadio.value === 'otp') {
        this.genOtp();
      } else if (this.passwordOptionsValidation.passwordRadio.value === 'secretQuestion') {
        this.showScrtQtns = true;
        this.getscreatquestion();
      }
    }
  }
  onTabChange(event: any) {
    this.clientIDForm.reset();
    this.emailIDForm.reset();
    this.mobileForm.reset();
    this.validateID = false;
    this.statusMessage = "";
  }
  forgotPopupOpen() {
    this.dialog.open(ForgotClientIdComponent, { panelClass: 'forgot-id-popup', backdropClass: 'forgot-id-backdrop', disableClose: true });
  }
  loginIssuesFaq() {
    this.dialog.open(FacingLoginIssuesPopupComponent, { panelClass: 'login-issue-faq-popup', backdropClass: 'login-issue-faq-backdrop' , disableClose: true })
  }
  toggleFirstInputType(img, input) {
    this.isFirstMasked = !this.isFirstMasked;
    if (this.isFirstMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecInputType(img, input) {
    this.isSecondMasked = !this.isSecondMasked;
    if (this.isSecondMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  //Services :
  getscreatquestion() {
    let secretqnsReq = {
      clientId: this.validateUserResp.CLIENT_ID
    }
    return this.loginService.getscreatquestion(secretqnsReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          if (response && response.body) {
            this.getFirstQuestionRK = response.body.SECRET_QUS1_RK;
            this.getSecondQuestionRK = response.body.SECRET_QUS2_RK;
            this.getFirstQuestion = response.body.QUESTION1_DESC;
            this.getSecondQuestion = response.body.QUESTION2_DESC;
          }
          else {
            this.statusMessage = "No secret questions set";
            this.showScrtQtns = false;
          }
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          this.showScrtQtns = false;
        }
      }),
      catchError((error) => throwError(error));
  }
  validatescreatquestion() {
    let secretqnsReq = {
      user_rk: this.validateUserResp.USER_RK,
      firstQuesRK: this.getFirstQuestionRK,
      cpfirstans: this.qtnForm.controls.first.value,
      secondQuesRK: this.getSecondQuestionRK,
      cpsecondans: this.qtnForm.controls.second.value,
      clientId: this.validateUserResp.CLIENT_ID,
    }
    return this.loginService.validatescreatquestion(secretqnsReq)
      .subscribe((response) => {
        if (response && response.head.status === "success") {
          this.validateSecretQuestionResp = response;
          this.router.navigate(['/login', 'reset-password']);
        }
        else {
          this.statusMessage = response.body;
        }
      }),
      catchError((error) => throwError(error));
  }
  genOtp() {
    let loginDtlResp = this.dataService.getLoginResp();
    let genOtpReq = {
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    if ((this.Valmethod === 'client') || (this.isLoggedIn == true)) {
      genOtpReq.optradio = 'clientID';
      if (this.clientIDForm.controls) {
        genOtpReq.cp_login_mode = 'CO';
        genOtpReq.cp_loginid = this.clientIDForm.controls.client.value || loginDtlResp.CLIENT_ID;
        this.Valmethod = 'client';
      }
    } else if (this.Valmethod === 'email') {
      genOtpReq.optradio = 'emailID';
      if (this.emailIDForm.controls) {
        genOtpReq.cp_login_mode = 'EO';
        genOtpReq.cp_loginid = this.emailIDForm.controls.email.value;
      }
    } else {
      genOtpReq.optradio = 'mobileNo';
      if (this.mobileForm.controls) {
        genOtpReq.cp_login_mode = 'MO';
        genOtpReq.cp_loginid = this.mobileForm.controls.mobile.value;
      }
    }
    return this.loginService.genOtpfgt(genOtpReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.showOtpSection = true;
          this.genOtpResp = response.body;
          this.showOTP();
          this.OTPTimerStart();
        }
        else {
          this.showOtpSection = false;
          this.statusMessage = response.head.errordetails.message
          this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
        }
      }),
      catchError((error) => throwError(error))
  }
  validateUser(loginType) {
    let validateUserReq: validateUserReqBody = {
      "requesttype": "Validateuser",
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
    }
    if (loginType === 'client') {
      validateUserReq.optradio = 'clientID';
      if (this.clientIDForm.controls) {
        validateUserReq.cp_login_mode = 'CO';
        validateUserReq.cp_loginid = this.clientIDForm.controls.client.value;
        this.Valmethod = 'client';
      }
    } else if (loginType === 'email') {
      validateUserReq.optradio = 'emailID';
      if (this.emailIDForm.controls) {
        validateUserReq.cp_login_mode = 'EO';
        validateUserReq.cp_loginid = this.emailIDForm.controls.email.value;
        this.Valmethod = 'email';
      }
    } else {
      validateUserReq.optradio = 'mobileNo';
      if (this.mobileForm.controls) {
        validateUserReq.cp_login_mode = 'MO';
        validateUserReq.cp_loginid = this.mobileForm.controls.mobile.value;
        this.Valmethod = 'mobile';
      }
    }
    return this.loginService.validateUserfgt(validateUserReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateUserResp = response.body;
          this.validateID = true;
          if (this.validateUserResp.maskMobileNo.length < 10) {
            this.maskMobileNo = "not available";
          }
          else { this.maskMobileNo = this.validateUserResp.maskMobileNo }
          if (this.validateUserResp.maskEmailId.length < 5) {
            this.maskEmailId = "not available";
          }
          else { this.maskEmailId = this.validateUserResp.maskEmailId }
          this.dataService.setValidateUserResp(response.body);
          if (this.maskMobileNo === this.maskEmailId) {
            this.statusMessage = "Your contact details are not registered with us. Please contact our branch/call center to update details.";
            this.NotAvailable = true;
          }
          else {
            this.statusMessage = "";
            this.NotAvailable = false;
          }
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          this.NotAvailable = true;
        }
      }),
      catchError((error) => throwError(error))
  }
  validateOtp() {
    let validateOtpReq = {
      optradio: "",
      trans_id: this.genOtpResp.trans_id,
      otpEntered: this.otpForm.controls.otp.value
    }
    if (this.Valmethod === 'client') {
      validateOtpReq.optradio = 'clientID';
      if (this.clientIDForm.controls) {
        this.ov_loginid = this.clientIDForm.controls.client.value;
      }
    } else if (this.Valmethod === 'email') {
      validateOtpReq.optradio = 'emailID';
      if (this.emailIDForm.controls) {
        this.ov_loginid = this.emailIDForm.controls.email.value;
      }
    } else {
      validateOtpReq.optradio = 'mobileNo';
      if (this.mobileForm.controls) {
        this.ov_loginid = this.mobileForm.controls.mobile.value;
      }
    }
    return this.loginService.validateOtpfgt(validateOtpReq, this.ov_loginid)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateOtpResp = response;
          this.router.navigate(['/login', 'reset-password']);
        }
        else {
          this.statusMessage = response.head.errordetails.message;
        }
      }),
      catchError((error) => throwError(error))
  }
  redirectToLogin() {
    this.router.navigate(['/']);
  }
  redirectToPolicy() {
    if (this.utils.getExpiredFlag() =="Y") {
      this.auth.logout();
    }
    else {
      this.router.navigate(['/my-policies']);
    }
  }
  clearError() {
    this.statusMessage = "";
  }
  resetValidation() {
    this.clientIDForm.reset();
    this.emailIDForm.reset();
    this.mobileForm.reset();
    this.validateID = false;
    this.passwordOptionsForm.reset();
    this.statusMessage = "";

  }

}