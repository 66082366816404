<div class="logout-dialog-popup" [ngClass]="{'overflow':secondForm}">
    <!--popup-content-height-->
    <div mat-dialog-actions>
        <div mat-dialog-close class="close-dialog" (click)="closePopupDialog()" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close
            <img src="assets/close.svg">
        </div>
    </div>
    <div class="logout-dialog-popup-content flex-center"
        [ngClass]="{'second-form-padding': secondForm || suggestionFlag}">
        <div class="logout-content-wrapper logout-padding" *ngIf="queryQuestion">
            <div class="logout-text">Were you able to complete your Query/Request satisfactorily?</div>
            <div class="submit-buttons">
                <button mat-raised-button color="warn" class="close-button"
                    (click)="noButton('supportMessage')">No</button>
                <span class="fill-remaining-space"></span>
                <button mat-raised-button color="warn" type="submit" class="save-button"
                    (click)="viewForms('logoutForms')">Yes</button>
            </div>
        </div>
        <div class="logout-content-wrapper logout-padding" *ngIf="agentConnect">
            <div class="logout-text">Sorry about that. We can connect you to a live agent who can assist you. Would you
                like to be connected now?</div>
            <div class="submit-buttons">
                <button mat-raised-button color="warn" class="close-button"
                    (click)="noButton('suggestionMessage')">No</button>
                <span class="fill-remaining-space"></span>
                <button mat-raised-button color="warn" type="submit" class="save-button"
                    (click)="viewForms('portalSupport')">Yes</button>
            </div>
        </div>
        <div class="logout-content-wrapper logout-padding" *ngIf="portalSupport">
            <div class="logout-text">Please type "Portal Support" in the 'Chat with us' window below, and we will
                connect you with a live agent.</div>
            <div class="submit-buttons">
                <button mat-raised-button color="warn" class="save-button" (click)="close()">OK</button>
            </div>
        </div>
        <div class="logout-content-wrapper" *ngIf="suggestionFlag" [formGroup]="feedBackForm">
            <div class="logout-text">Can you tell me what went wrong so that I can serve you better next time.</div>
            <textarea formControlName="suggestions" type="text" matInput class="comments" maxlength="500"></textarea>
            <div class="input-fields">
                <div class="policy-number">
                    <div class="title-text">Policy No<sup>*</sup>: </div>
                    <mat-form-field floatLabel="never">
                        <mat-placeholder>Policy number</mat-placeholder>
                        <mat-select formControlName="policyNo">
                            <mat-option *ngFor="let item of policies" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="policy-number date">
                    <div class="title-text">Date<sup>*</sup>: </div>
                    <mat-form-field floatLabel="never">
                        <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                        <input type="text" matInput formControlName="date" [max]="today" (focus)="picker.open()"
                            matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="policy-number time">
                    <div class="title-text">Time<sup>*</sup>: </div>
                    <mat-form-field floatLabel="never">
                        <mat-placeholder>hh:mm</mat-placeholder>
                        <input type="time" matInput formControlName="time" placeholder="hh:mm">
                    </mat-form-field>
                </div>
            </div>
            <div class="doc-upload-wrapper logout-wrapper">
                <app-drag-drop (fileDropped)="onDocumentLoad($event)"></app-drag-drop>
                <div class="no-mobile">
                    <div class="upload-file-info flex">
                        <span class="file-types">Accepted File types: .jpeg, .png, .pdf, .gif, .bmp</span>
                        <span>Maximum File Size: {{maxFileSize}} MB</span>
                    </div>
                </div>
                <div class="mobile">
                    <div class="upload-file-info flex">
                        <span>File types: .jpeg, .png, .pdf, .gif, .bmp</span>
                        <span>Max. File Size: {{maxFileSize}} MB</span>
                    </div>
                </div>
                <div *ngIf="uploadedFileList && uploadedFileList.length > 0" class="uploaded-file-name">
                    <div *ngFor="let item of uploadedFileList">Files Uploaded : {{item.name}}</div>
                </div>
            </div>
            <div class="submit-buttons">
                <button mat-raised-button color="warn" class="save-button" (click)="viewForms('submit')"
                    [disabled]="!(feedBackForm.valid)">Submit</button>
            </div>
        </div>
        <div class="logout-content-wrapper" *ngIf="finalMessage">
            <div class="logout-text">Thank you for your inputs. For further assistance, you can call us on 1860 267 9999
                ( Mon to Sat, from 10am to 7pm IST ) or write to us at <a href="mailto:service@hdfclife.com" target="_blank" rel="noopener noreferrer" class="contact-value">service@hdfclife.com</a></div>
            <div class="submit-buttons">
                <button mat-raised-button color="warn" class="save-button" (click)="close()">OK</button>
            </div>
        </div>
        <div class="logout-content-wrapper" *ngIf="firstForm">
            <form [formGroup]="MyaccfeedbackForm">
                <div class="question-one">{{feedBackQues}}</div>
                <mat-button-toggle-group class="rating first-rating flex" formControlName="rating" [ngClass]="{'rating-width':MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2}">
                    <div class="rating-block">
                    <mat-button-toggle class="star" (click)="onestar('1')"
                        [ngClass]="{'star-active': (MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2 || MyaccfeedbackForm.value.rating==3 || MyaccfeedbackForm.value.rating==4 || MyaccfeedbackForm.value.rating==5 )}">
                    </mat-button-toggle>
                    <div class="rating-desc double-word">Very Poor</div>
                    </div>
                    <div class="rating-block">
                    <mat-button-toggle class="star" (click)="twostars('2')"
                        [ngClass]="{'star-active': (MyaccfeedbackForm.value.rating==2 || MyaccfeedbackForm.value.rating==3 || MyaccfeedbackForm.value.rating==4 || MyaccfeedbackForm.value.rating==5)}">
                    </mat-button-toggle>
                    <div class="rating-desc">Poor</div>
                    </div>
                    <div class="rating-block">
                    <mat-button-toggle class="star" (click)="threestars('3')"
                        [ngClass]="{'star-active': (MyaccfeedbackForm.value.rating==3) || MyaccfeedbackForm.value.rating==4 || MyaccfeedbackForm.value.rating==5}">
                    </mat-button-toggle>
                    <div class="rating-desc">Fair</div>
                    </div>
                    <div class="rating-block">
                    <mat-button-toggle class="star" (click)="fourstars('4')"
                        [ngClass]="{'star-active': (MyaccfeedbackForm.value.rating==4 || MyaccfeedbackForm.value.rating==5)}">
                    </mat-button-toggle>
                    <div class="rating-desc double-word">Very Good</div>
                    </div>
                    <div class="rating-block">
                    <mat-button-toggle class="star" (click)="fivestars('5')"
                        [ngClass]="{'star-active': (MyaccfeedbackForm.value.rating==5)}">
                    </mat-button-toggle>
                    <div class="rating-desc">Excellent</div>
                    </div>
                </mat-button-toggle-group>
                <!-- <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div> -->
                 <!-- <span class="legend flex-center mobile">(1- Very Poor , 2- Poor ,  3- Fair , 4- Very Good , 5- Excellent)</span> -->
                 <!--                 <button mat-raised-button color="primary" type="submit"
                    [disabled]="(!disableButton && ratedval.length==0)" class="save-button">Continue</button>
 -->
            </form>
        </div>
        <div class="second-group">
            <div class="logout-content-wrapper" *ngIf="secondForm">
                <form [formGroup]="CommentsForm" (ngSubmit)="submitSecondField()">
                    <span class="question-one flex-center">You have rated us '{{ratedval}}' on your interaction. </span>
                    <span class="question-one flex-center">Kindly share reasons for the rating you have given in the
                        question above.</span>
                    <textarea formControlName="comments" type="text" matInput class="comments"
                        maxlength="500"></textarea>
                    <div class="submit-buttons flex-center">
                        <!-- <button mat-raised-button color="warn" class="close-button" (click)="firstField()">Back</button>
                    <span class="fill-remaining-space"></span>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!CommentsForm.valid"
                        class="save-button">Continue</button> -->
                    </div>
                </form>
            </div>
            <div class="logout-content-wrapper"
                *ngIf="((MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2) && poorRatingform)">
                <form [formGroup]="ImprovementForm" (ngSubmit)="submitPoorRatingField()">
                    <span class="question-one flex-center">{{aspects}}
                    </span>
                    <div class="second-line"> (You can select multiple options from the list given below.)</div>
                    <div class="checkbox-section">
                        <div class="flex checkbox" *ngFor="let item of questions">
                            <mat-checkbox color="primary" [value]="item" (change)="queChecked($event)"></mat-checkbox>
                            <div class="text">{{item}}</div>
                        </div>
                        <div class="flex">
                            <mat-checkbox color="primary" [checked]="othersChecked" (change)="otherChecked($event)">
                            </mat-checkbox>
                            <div class="text">{{others}}</div>
                        </div>
                    </div>
                    <div *ngIf="othersChecked">
                        <span>Please specify which other aspect influenced
                            your rating</span>
                        <textarea type="text" matInput formControlName="others" class="comments"
                            maxlength="500"></textarea>
                    </div>
                    <div class="submit-buttons flex-center">
                        <!-- <button mat-raised-button color="warn" class="close-button" (click)="secondField()">Back</button>
                    <span class="fill-remaining-space"></span>
                    <button mat-raised-button color="primary" [disabled]="!quesChecked || !ImprovementForm.valid"
                        type="submit" class="save-button">Continue</button> -->
                    </div>
                </form>
            </div>
            <div class="logout-content-wrapper" *ngIf="secondForm">
                <form [formGroup]="RecommendForm" (ngSubmit)="submitForm()">
                    <span class="question-one">{{ratingQues}}</span>
                    <div class="second-rating-div"
                        [ngClass]="{'rating-div':(MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2)}">
                        <mat-button-toggle-group class="rating flex-center second-rating" formControlName="ratings">
                            <mat-button-toggle class="option-one" (click)="noneoften()"
                                [ngClass]="{'button-border': (noneoften && RecommendForm.value.rating==0)}">0
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="oneoften()"
                                [ngClass]="{'button-border': (oneoften && RecommendForm.value.rating==1)}">1
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="twooften()"
                                [ngClass]="{'button-border': (twooften && RecommendForm.value.rating==2)}">2
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="threeoften()"
                                [ngClass]="{'button-border': (threeoften && RecommendForm.value.rating==3)}">3
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="fouroften()"
                                [ngClass]="{'button-border': (fouroften && RecommendForm.value.rating==4)}">4
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="fiveoften()"
                                [ngClass]="{'button-border': (fiveoften && RecommendForm.value.rating==5)}">5
                            </mat-button-toggle>
                            <mat-button-toggle class="option-one" (click)="sixoften()"
                                [ngClass]="{'button-border': (sixoften && RecommendForm.value.rating==6)}">6
                            </mat-button-toggle>
                            <mat-button-toggle class="option-three" (click)="sevenoften()"
                                [ngClass]="{'button-border': (sevenoften && RecommendForm.value.rating==7)}">7
                            </mat-button-toggle>
                            <mat-button-toggle class="option-three" (click)="eightoften()"
                                [ngClass]="{'button-border': (eightoften && RecommendForm.value.rating==8)}">8
                            </mat-button-toggle>
                            <mat-button-toggle class="option-five" (click)="nineoften()"
                                [ngClass]="{'button-border': (nineoften && RecommendForm.value.rating==9)}">9
                            </mat-button-toggle>
                            <mat-button-toggle class="option-five" (click)="tenoften()"
                                [ngClass]="{'button-border': (tenoften && RecommendForm.value.rating==10)}">10
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <div class="rating-progress flex-center">
                            <span>Not at all likely</span>
                            <span>May be</span>
                            <span>Exteremly likely</span>
                        </div>
                    </div>
                </form>
                <div class="submit-buttons flex-center">
                    <!-- <button mat-raised-button color="warn" class="close-button" (click)="secondField()">Back</button>
                    <span class="fill-remaining-space"></span>-->
                    <button mat-raised-button color="warn" type="submit"
                        *ngIf="!((MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2) && poorRatingform)"
                        class="save-button"
                        (click)="submitVocFeedback()">Submit</button>
                    <!--[disabled]="!(MyaccfeedbackForm.valid && CommentsForm.valid && RecommendForm.value.rating)"-->
                    <button mat-raised-button color="warn" type="submit"
                        *ngIf="((MyaccfeedbackForm.value.rating==1 || MyaccfeedbackForm.value.rating==2) && poorRatingform)"
                        class="save-button"
                        (click)="submitVocFeedback()">Submit</button>
                    <!--[disabled]="!(MyaccfeedbackForm.valid && CommentsForm.valid && RecommendForm.value.rating && ImprovementForm.valid)"-->
                </div>
            </div>
        </div>
    </div>
</div>