import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval, Subject, Subscription } from 'rxjs';
import { SessionDialogComponent } from 'src/app/modules/login/session-dialog/session-dialog.component';
import { Utils } from 'src/app/utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class SessionTimerService {

  private timerSub : Subscription;
  private subscriptionlist = [];
  private duration = 890000;
//   private duration = 180000;
  private endTime : number;
  public timeLeft$: Subject<number> = new Subject<number>();
  private timeLeft : number;
  private sessionTimerDialog : boolean;
  public sessionDialog : MatDialogRef<SessionDialogComponent>;


  public setSessionEndTime(){
      this.endTime = Date.now() + this.duration;
  }

  constructor(public utils: Utils, public dialog : MatDialog){
      this.timeLeft$.next(this.duration);
  }

  startTimer(){
      // console.log('Session Timer started')
      if(this.endTime > 0)
        this.runTimer();
      this.sessionTimerDialog = false;
  }

  resumeTimer(){
      const storedEndTime = this.endTime;
      if(storedEndTime > 0) {
          const currentTime = Date.now();
          this.timeLeft$.next(this.endTime - currentTime);
          this.timeLeft = this.endTime - currentTime;
          // console.log(this.convert(this.timeLeft))
          if (this.timeLeft < 1000) {
              this.closeSessionDialog();
              this.openSessionDialog('logoutCnfm')    //logout popup
              this.clearInterval();
              this.timerSub = null;
              this.endTime = -1;
          } 
          else {
              this.runTimer();
          }
      } else { 
          //show you have been logout popup because time expired
          this.closeSessionDialog();
          if(this.sessionTimerDialog){
              this.openSessionDialog('logoutCnfm');
              this.sessionTimerDialog = false;
          }
          this.clearInterval();
      }
  }

  runTimer(){
      this.clearInterval()
      this.timerSub = interval(1000).subscribe((v) => {
          const currentTime = Date.now();
          this.timeLeft = this.endTime - currentTime;
          this.timeLeft$.next(this.timeLeft);
          if(this.timeLeft > 1000 && this.timeLeft < 122000){
              if(!this.sessionTimerDialog){
                  this.openSessionDialog('logoutTimer');
              }
          }
          else if(this.timeLeft < 1000){
              //to show again if page keep working is selected.
              this.clearInterval();
              this.closeSessionDialog();
              if(!this.sessionTimerDialog){
                  this.openSessionDialog('logoutCnfm');
                  this.sessionTimerDialog = false;
              }
              this.endTime = -1;
          }
      })
      this.subscriptionlist.push(this.timerSub);
      // console.log(this.subscriptionlist)
  }

  convert(ms : any){
      let seconds = Math.floor(ms/1000);
      let minutes = Math.floor(seconds/ 60);
      seconds = seconds % 60;
      return { minutes : minutes, seconds : seconds }
  }

  openSessionDialog(dialogRef){
      if(this.utils.isUserAuthenticated()){
          this.sessionDialog = this.dialog.open(SessionDialogComponent,{
              panelClass: 'my-policies-dialog',
              disableClose: true,
              hasBackdrop : true, 
              position : { top :"20px"},
              backdropClass : 'logout-backdrop',
              data : { dialogRef : dialogRef}
          })
      }
      this.sessionTimerDialog = true; //to prevent dialog from opening when switching tab.
  }

  closeSessionDialog(){
      if(this.sessionTimerDialog){
          this.sessionDialog.close();
          this.sessionTimerDialog = false;
      }
  }

  //clear any intervals run.
  clearInterval(){
    if(this.subscriptionlist.length > 0){
      this.subscriptionlist.forEach((subscription) => subscription.unsubscribe())
      this.subscriptionlist.pop();
    }
  }
}
