import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private loginResponse: any = null;
  private carouselPosition: number = null;
  private isHNIUserSubject = new Subject<boolean>();
  private isCPAdminSubject = new Subject<boolean>();
  isHNIUser = this.isHNIUserSubject.asObservable();
  isCPAdmin = this.isCPAdminSubject.asObservable();
  private isCPAdminPortal: boolean = false;
  private validateduserResponse: any = null;
  private policyDetails = null;
  private fsprSubactivityType: string = null;
  private fsprReqBody: any = null;
  private offlineAadharAddrDtls = null;
  isVirtualBranch: boolean = null;
  private isVirtualBranchSubject = new Subject<boolean>();
  isVirtualBranch$ = this.isVirtualBranchSubject.asObservable();
  private loginRespSubject = new Subject<any>();
  loginResp$ = this.loginRespSubject.asObservable();
  policyCardSubject = new Subject<any>();
  policyCard$ = this.policyCardSubject.asObservable();
  private pasaClickedSubject = new Subject<any>();
  pasaClickedObs$ = this.pasaClickedSubject.asObservable();
  private pasaSubject = new Subject<any>();
  pasaObs$ = this.pasaSubject.asObservable();
  private virtualBranchDtls = {
    action: null,
    policyNo: null,
    source: null
  };
  private dedupeSubject = new Subject<any>();
  private landingScreenSubject = new Subject<any>();
  dedupeResp$ = this.dedupeSubject.asObservable();
  landingScreenResp$ = this.landingScreenSubject.asObservable();
  dedupeRespone: any;
  premiumDtls: any = null;
  isCustomLink: boolean = null;
  dedupeData: any;
  isCsApp: boolean = null;
  reqSubmittedBy: string = '';
  SurrPolicyNum: any
  policyDtls: any = null;
  adcatRiderDtls = null;
  landingScreenDetails : any;
  isDeepLink: boolean = null;   // either custom link or virtual branch
  docType: string = null;
  searchSubj = new BehaviorSubject<any>(null);
  selectedSwitchClientIdDetail : any = null;
  policyNo: any;

  policyRiderLoanSubj = new BehaviorSubject<any>(null);
  profileCompletionDtls: any = {};
  encryptionKey: any;
  encryptionIv: any;
  pasaClicked: any;
  npsCheck: any;
  private npsSubject = new Subject<any>();
  npsResp$ = this.npsSubject.asObservable();
  loansIndexPosition: any = null;
  loanRepaymentData = null;
  loanEnqData = null;
  loanPaymentHistoryData = null;


  constructor() { }

  setLoginResp(loginDtls) {
    this.loginResponse = loginDtls;
    if (this.loginResponse) {
      this.loginResponse.HNI_FLG = "N"; //disabling HNI user flag
      if (this.loginResponse.SOURCE === "CUSTOMLINK") {
        this.isCustomLink = true;
        this.isDeepLink = true;
        this.SurrPolicyNum = this.loginResponse.Policyno;
        this.reqSubmittedBy = "Deeplink";
      } else if (this.loginResponse.SOURCE === "CS_APP") {
        this.reqSubmittedBy = "Customer App";
        this.isCsApp = true;
        this.docType = this.loginResponse.DOC_TYPE;
      } else {
        this.isCustomLink = false;
        this.isDeepLink = false;
        this.isCsApp = false;
        this.reqSubmittedBy = "";
        this.docType = null;
      }
      this.loginRespSubject.next(true);
    }
    if (this.loginResponse && this.loginResponse.HNI_FLG === "Y") {
      this.isHNIUserSubject.next(true);
    } else {
      this.isHNIUserSubject.next(false);
    }
    if (this.loginResponse && this.loginResponse.SOURCE === "cpadmin") {
      this.isCPAdminSubject.next(true);
    }
    else {
      this.isCPAdminSubject.next(false);
    }    
  }
  getLoginResp() {
    return this.loginResponse;
  }
  checkCPadmin() {
    if (this.loginResponse && this.loginResponse.SOURCE === "cpadmin") {
      return true;
    }
    else {
      return false;
    }
  }
  setValidateUserResp(validateuserDtls) {
    this.validateduserResponse = validateuserDtls;
  }
  getValidateUserResp() {
    return this.validateduserResponse;
  }
  setCarouselPosition(index: number) {
    if (index >= 0) {
      this.carouselPosition = index;
    }
  }
  getCarouselPosition() {
    return this.carouselPosition;
  }
  deletePolicyCardResp() {
    this.policyDetails = null;
  }
  setPolicyCardResp(policyCardDtls) {
    this.policyDetails = policyCardDtls;
    if (policyCardDtls) {
      this.policyCardSubject.next(policyCardDtls)
    }
  }
  getPolicyCardResp() {
    return this.policyDetails;
  }
  setFsprSubactivityType(type: string) {
    this.fsprSubactivityType = type;
  }
  getFsprSubactivityType() {
    return this.fsprSubactivityType;
  }
  setFsprReqBody(reqBody: any) {
    this.fsprReqBody = reqBody;
  }
  getFsprReqBody() {
    return this.fsprReqBody;
  }
  getOfflineAadharAddrDtls() {
    return this.offlineAadharAddrDtls;
  }
  setOfflineAadharAddrDtls(address) {
    this.offlineAadharAddrDtls = address;
  }
  getIsVirtualBranch() {
    return this.isVirtualBranch;
  }
  setIsVirtualBranch(isVirtualBranch) {
    this.isVirtualBranch = isVirtualBranch;
    this.isVirtualBranchSubject.next(isVirtualBranch);
  }
  getVirtualBranchDtls() {
    return this.virtualBranchDtls;
  }
  setVirtualBranchDtls(data) {
    this.virtualBranchDtls = data;
  }
  setDedupeResp(dtls) {
    this.dedupeRespone = dtls;
    if(this.dedupeRespone && this.dedupeRespone.length > 1) {
      this.dedupeSubject.next(true);
    }
    else {
      this.dedupeSubject.next(false);
    }
  }
  getIsCustomLink() {
    return this.isCustomLink;
  }
  setIsCustomLink(flag: boolean) {
    this.isCustomLink = flag;
  }
  setDedupeData(dtls) {
    this.dedupeData = dtls
  }
  getDedupeData() {
    return this.dedupeData;
  }
  clearCache() {
    this.loginResponse = null;
    this.policyDetails = null;
    this.adcatRiderDtls = null;

    this.dedupeData = null;
    this.dedupeRespone = null;
    this.selectedSwitchClientIdDetail = null;
  }
  setLandingScreenDetails(dtls) {
    this.landingScreenDetails = dtls;
    if(this.landingScreenDetails) {
      this.landingScreenSubject.next(this.landingScreenDetails);
    }
    else {
      this.landingScreenSubject.next(this.landingScreenDetails);
    }
  }
  getLandingScreenDetails(){
    return this.landingScreenDetails;
  }
  setSelectedSwitchClientIdDtls(data){
    this.selectedSwitchClientIdDetail = data;
  }
  getSelectedSwitchClientIdDtls(){
    return this.selectedSwitchClientIdDetail;
  }
  setRedirectValue(policyNum){
    this.policyNo = policyNum;
  }
  getRedirectValue(){
    return this.policyNo;
  }
  setPasaCheck(val) {
    this.pasaSubject.next(val);
  }
  setEncryptionKey(val) {
    this.encryptionKey = val;
  }
  getEncryptionKey() {
    return this.encryptionKey;
  }
  setEncryptionIv(val) {
    this.encryptionIv = val;
  }
  getEncryptionIv() {
    return this.encryptionIv;
  }
  setPasaClicked(value) {
    this.pasaClickedSubject.next(value);
  }
  getNpsCheck() {
    return this.npsCheck;
  }
  setNpsCheck(val) {
    this.npsSubject.next(val);
    this.npsCheck = val;
  }
  setLoansIndex(data: any) {
    if (data.index >= 0) {
      this.loansIndexPosition = data;
    }
  }
  getLoansIndexPosition() {
    return this.loansIndexPosition;
  }
}
