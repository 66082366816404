<div class="custom-snackbar flex">
    <div class="snackbar-wrapper">
        <div class="snackbar-content" [ngClass]="{'error': !isSuccess}">
            <span *ngIf="!showAsText" class="msg" [innerHTML]="msg"></span>
            <span *ngIf="showAsText" class="msg" [innerText]="msg"></span>
        </div>
    </div>
    <div class="btn">
        <button class="close-btn" (click)="close()"></button>
    </div>
</div>