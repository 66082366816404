import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CustomLinkRoutingModule } from './custom-link-routing.module';
import { CustomLinkComponent } from './custom-link/custom-link.component';
import { EmailVocComponent } from './email-voc/email-voc.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { HospitalListComponent } from './hospital-list/hospital-list.component';
import { EditNeftDeepLinkComponent } from './edit-neft-deep-link/edit-neft-deep-link.component';
import { VservPageComponent } from './vserv-page/vserv-page.component';
import { SrTrackComponent } from './sr-track/sr-track.component';
import { WellnessVoucherCustomlinkComponent } from './wellness-voucher-customlink/wellness-voucher-customlink.component';
import { SurrenderComponent } from './surrender/surrender.component';
import { CustomLinkDocUploadComponent } from './custom-link-doc-upload/custom-link-doc-upload.component';
import { AgentDocUploadComponent } from './agent-doc-upload/agent-doc-upload.component';
import { AgentSrScreenComponent } from './agent-sr-screen/agent-sr-screen.component';
import { ChequePickupComponent } from './cheque-pickup/cheque-pickup.component';


@NgModule({
  declarations: [
    CustomLinkComponent,
    EmailVocComponent,
    DeclarationComponent,
    HospitalListComponent,
    EditNeftDeepLinkComponent,
    VservPageComponent,
    SrTrackComponent,
    WellnessVoucherCustomlinkComponent,
    SurrenderComponent,
    CustomLinkDocUploadComponent,
    AgentDocUploadComponent,
    AgentSrScreenComponent,
    ChequePickupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CustomLinkRoutingModule,
  ]
})
export class CustomLinkModule { }
