<div class="terms-and-conditions" *ngIf="showPage">
    <div class="header">Terms & Conditions for Loan against Policy</div>
    
    <mat-card class="main">  
        <ol class="orderLanguage">
            <p class="first-item">{{reference}}</p>
            <ng-container *ngFor="let terms of items">                        
                    <li class="terms-and-condition points">{{terms}}</li> 
            </ng-container>  
        </ol>   
    </mat-card>
</div>

