<div class="pdf-block-popup">
    <!-- <div mat-dialog-actions>
        <div mat-dialog-close class="close-dialog">
            Close
        </div>
    </div> -->
    <div class="pdf-viewer-popup-header flex">
        <span>Alert</span>
    </div>
    <div class="pdf-viewer-popup-content popup-content-height">
        <div class="main-text">Pop-up blocker is enabled. Please disable or add this site to your exception list.</div>
        <div class="pdf-viewer-popup-btns flex">
            <a mat-button class="submit-btn" mat-dialog-close [href]="pdfpath" target="_blank" rel="noopener noreferrer" (click)="windowClose()">Click here to view</a>

        </div>
    </div>
</div>