<div class="investigator-fr-screen">
    <mat-card class="inner-card">
        <div class="menu-header flex-center">
            <span class="sr-space">Investigation Document Upload</span>
        </div>
        <div>
            <form [formGroup]="frDtlsForm" class="investigator-sr-details">
                <div class="custom-grid-tile">
                    <div class="field-name">Policy Number:</div>
                    <div class="field-value">{{policyNumber}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Claim SR Number:</div>
                    <div class="field-value">{{srNumber}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">RCD:</div>
                    <div class="field-value">{{rcdValue}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Allocation Date:</div>
                    <div class="field-value">{{allocDate}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Name of the Life Assured:</div>
                    <div class="field-value">{{clientName}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Sum Assured:</div>
                    <div class="field-value" *ngIf="sumAssured !== 'NA'">INR {{sumAssured  | number:'1.0-2':'en-IN'}}
                    </div>
                    <div class="field-value" *ngIf="sumAssured === 'NA'">INR
                        {{sumAssured}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Covid:</div>
                    <div class="field-value">{{covidValue}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Product Name:</div>
                    <div class="field-value">{{productName}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Type of Investigation:</div>
                    <div class="field-value">{{investigationType}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Date of death:</div>
                    <div class="field-value">{{deathDate}}</div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Cause of event:</div>
                    <div class="field-value">{{eventCause}}</div>
                </div>

                <!-- <div class="custom-grid-tile">
                    <div class="field-name">Assessor comment:</div>
                    <div class="field-value">
                        <mat-form-field floatLabel="never" class="frequency-form-field padding">
                            <textarea class="accoment" matInput formControlName="acomment" readonly></textarea>
                        </mat-form-field>
                    </div>
                </div> -->

                <div class="custom-grid-tile">
                    <div class="field-name ">Investigation Outcome:</div>
                    <div class="field-value">
                        <mat-form-field floatLabel="never" class="form-field date-field">
                            <mat-placeholder>Select</mat-placeholder>
                            <mat-select [disableRipple]=true disableOptionCentering formControlName="ioutcome" (selectionChange)="dropDownChangeFn($event)">
                                <mat-option *ngFor="let invtOutcome of invtOutcomes" [value]="invtOutcome">
                                    {{invtOutcome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name">Type of report submission:</div>
                    <div class="field-value">
                        <mat-form-field floatLabel="never" class="form-field date-field">
                            <mat-placeholder>Select</mat-placeholder>
                            <mat-select [disableRipple]=true disableOptionCentering formControlName="reportType" (selectionChange)="dropDownChangeFn($event)">
                                <mat-option *ngFor="let reportSubType of reportSubTypes" [value]="reportSubType">
                                    {{reportSubType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="custom-grid-tile">
                    <div class="field-name width">Claimant response to Investigator:</div>
                    <div class="field-value">
                        <mat-form-field floatLabel="never" class="form-field date-field">
                            <mat-placeholder>Select</mat-placeholder>
                            <mat-select [disableRipple]=true disableOptionCentering formControlName="claimResp" (selectionChange)="dropDownChangeFn($event)">
                                <mat-option *ngFor="let claimantResponse of claimantResponses"
                                    [value]="claimantResponse">
                                    {{claimantResponse}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <!-- 
        <div class="investigation-table">
        <table mat-table [dataSource]="requirementsData" class="investigator-requirement-table">
            <ng-container matColumnDef="S.No.">
                <th mat-header-cell *matHeaderCellDef>S.No.</th>
                    <td mat-cell *matCellDef="let element;index as i">
                     {{i+1}}
                   </td>
            </ng-container>

            <ng-container matColumnDef="FR Description">
                <th mat-header-cell *matHeaderCellDef>FR Description</th>
                <td mat-cell *matCellDef="let element">
                    {{element.REQUIREMENT_DESC}}
                </td>
            </ng-container>

             <ng-container matColumnDef="DOCUMENT_DESC">
                <th mat-header-cell *matHeaderCellDef>Document Selected</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.DOCUMENT_CODE}}
                   </td>
            </ng-container>  

            <ng-container matColumnDef="REQUIREMENT_STATUS">
                <th mat-header-cell *matHeaderCellDef>FR Status</th>
                <td mat-cell *matCellDef="let element">
                    {{element.REQUIREMENT_STATUS}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.UPLOADED_DOCUMENT_STATUS !==  'ACTIVE'" class="upload-button" (click)="onUploadClick(element,doclistPopup)">Upload
                        <input class="drag-drop-input" type="file" #fileDropRef multiple="multiple"
                            (change)="onFileChanged($event)" id="fileDropRef">
                    </button>
                    <div *ngIf="uploadedFileList !== []">
                    <span *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</span></div>
                    <span *ngIf="element.UPLOADED_DOCUMENT_STATUS === 'ACTIVE'" >Active</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="Uploaded Document">
                <th mat-header-cell *matHeaderCellDef>Uploaded Document</th>
                <td mat-cell *matCellDef="let element">
                    {{element.UPLOADED_DOCUMENT}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Assessor FR Comments">
                <th mat-header-cell *matHeaderCellDef>Assessor FR Comments</th>
                <td mat-cell *matCellDef="let element">
                    <textarea matInput ></textarea>
                </td>
            </ng-container>

            <ng-container matColumnDef="Investigator Comments">
                <th mat-header-cell *matHeaderCellDef>Investigator Comments</th>
                <td mat-cell *matCellDef="let element">
                    <textarea matInput ></textarea>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="investigatorColumns"></tr>
            <tr mat-row *matRowDef="let row;columns:investigatorColumns;"></tr>
        </table></div> -->
        <div class="investigation-table" *ngIf="respSucess">
            <form [formGroup]="primaryQstnForm">
                <table class="investigator-requirement-table">
                    <tbody formArrayName="qaArray" class="table-cell-body">
                        <tr >
                            <th *ngFor="let column of investigatorColumns" class="table-cell-row-head" >
                                {{column}}
                            </th>
                        </tr>
                        <tr *ngFor="let a of this.primaryQstnForm.get('qaArray')['controls']; let i=index;"
                            class="table-cell-row">
                            <td [formGroupName]="i" class="table-cell">
                                {{i+1}}
                            </td>
                            <td [formGroupName]="i" class="table-cell">
                                <div>{{a.get('fr_desc').value}}
                                </div>
                            </td>
                            <td [formGroupName]="i" class="table-cell">
                                <div>
                                    {{a.get('fr_status').value}}
                                </div>
                            </td>
                            <!-- <td [formGroupName]="i">
                        <div>
                            <input formControlName="fr_status">
                            <p *ngIf="a.hasError('required','fr_status')">Required!</p>
                        </div>
                </td> -->
                            <td [formGroupName]="i" class="table-cell">
                                <div *ngIf="a.get('action').value ===  'ACTIVE'">{{a.get('action').value}}</div>
                                <div *ngIf="a.get('action').value !==  'ACTIVE'">
                                    <input formControlName="action" readonly placeholder="Upload" type="text"
                                        multiple="multiple" class="upload-button"
                                        (click)="onUploadClick(i,uploadPopup)">
                                    <p *ngIf="a.hasError('required','action')" class="error-message">Please upload
                                        documents</p>
                                </div>
                                <!-- <div *ngIf="a.get('action').value !==  'ACTIVE' && a.get('req_cd').value ===  'CSPS_REQ_633'">
                                        <input formControlName="action" readonly placeholder="Invest" type="text" multiple="multiple"
                                            class="upload-button" (click)="onInvestIncomeClick(i,doclistPopup)">
                                        <p *ngIf="a.hasError('required','action')" class="error-message">Please provide amount</p>
                                    </div> -->
                            </td>
                            <td [formGroupName]="i" class="table-cell">
                                <div>{{a.get('upload_doc').value}}</div>
                            </td>
                            <td [formGroupName]="i" class="table-cell">
                                <div>
                                    {{a.get('asses_comm').value}}
                                </div>
                            </td>
                            <td [formGroupName]="i" class="table-cell">
                                <div *ngIf="a.get('action').value">
                                    {{a.get('invest_comm').value}}
                                </div>
                                <div *ngIf="!a.get('action').value">
                                    <textarea matInput formControlName="invest_comm" readonly class="text-area"></textarea>
                                    <p *ngIf="a.hasError('required','invest_comm')" class="error-message">Please enter
                                        investigator comments</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
        <div class="button-div">
            <!-- <button mat-button class="okay-button" (click)="getSubmitResp()">Submit</button> -->
            <button mat-button class="okay-button" [disabled]="!frDtlsForm.valid || !finalSubmitBtn"
                (click)="getSubmitResp()">Submit</button>
        </div>
    </mat-card>
</div>
<ng-template #uploadPopup>
        <div class="doclist-popup">
            <div mat-dialog-actions>
                <div mat-dialog-close class="close-dialog" (click)="closeDialog()"
                    [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    <div class="no-mobile">
                        Close<img src="assets/close.svg" class="close-icon">
                    </div>
                    <img src="assets/cross_circle_blue.svg" class="mobile">
                </div>
            </div>
            <!-- <div class="doclist-popup-header">Investigator Comments</div> -->
            <div class="doclist-popup-content popup-content-height">
                <div class="overflow-container">
                        <div class="top-section">
                            <div class="custom-grid-tile">
                                    <div class="proof-type-text">Are are you sure want to upload document, Please click upload to proceed</div>
                                    <div class="proof-type-text">No documents available to upload, Please click comments to give investigator comments</div>        
                            </div>
                        </div>
                    </div>
                <div class="button-section flex">
                    <button mat-button class="cancel-button" type="button" (click)="commentsPopup(InvestCommentsPopup)">Comments</button>
                        <button mat-button class="submit-button" type="button" (click)="uploadDocuPopup(doclistPopup)">Upload</button>
                </div>
            </div>
        </div>
</ng-template>
<ng-template #doclistPopup>
    <div class="doclist-popup">
        <div mat-dialog-actions>
            <div mat-dialog-close class="close-dialog" (click)="closeDialog()"
                [ngClass]="{'hni-close-dialog':isHNIUSer}">
                <div class="no-mobile">
                    Close<img src="assets/close.svg" class="close-icon">
                </div>
                <img src="assets/cross_circle_blue.svg" class="mobile">
            </div>
        </div>
        <div class="doclist-popup-header">Upload Document</div>
        <div class="doclist-popup-content popup-content-height">
            <div class="overflow-container">
                <div class="top-section">
                    <div class="proof-type">{{reqDocDesc}}</div>
                    <div class="doc-type-select-wrapper">
                        <div class="form-label">Document Type<span class="mandatory">*</span></div>
                        <mat-form-field floatLabel="never" class="doc-list-form-field select-field"
                            [ngClass]="{'error-field': docTypeFocusOut && docTypeDropdown.hasError('required')}">
                            <mat-placeholder>Please select a document</mat-placeholder>
                            <mat-select disableRipple disableOptionCentering class="doc-type-select"
                                (selectionChange)="OnDocTypeFocusOut()" [formControl]="docTypeDropdown">
                                <mat-option *ngFor="let item of docList" [value]="item[0]">
                                    {{item[1]}}</mat-option>
                            </mat-select>
                            <img mat-suffix src="assets/angle_down.svg">
                            <mat-error *ngIf="docTypeFocusOut && docTypeDropdown.hasError('required')"
                                class="error-message">
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="top-section">
                    <div class="custom-grid-tile">
                        <div class="proof-type">Investigator comment:<span class="mandatory">*</span></div>
                        <div class="field-value">
                            <mat-form-field floatLabel="never" class="comments-form-field">
                                <textarea class="accoment" [formControl]="investigatorComment" matInput></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="doc-upload-flex flex" *ngIf="docTypeFocusOut">
                    <div class="doc-upload-wrapper">
                        <app-drag-drop (fileDropped)="onDocumentLoad($event)"></app-drag-drop>
                        <div class="no-mobile">
                            <div class="upload-file-info flex">
                                <span>Accepted File types: .jpeg, .png, .pdf, .gif, .bmp</span>
                                <span>Maximum File Size: 5 MB</span>
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="upload-file-info">
                                <div>File types: .jpeg, .png, .pdf, .gif, .bmp</div>
                                <div>Max. File Size: 5 MB</div>
                            </div>
                        </div>
                        <div *ngIf="uploadedFileList && uploadedFileList.length > 0 " class="uploaded-file-name">
                            <div *ngFor="let item of uploadedFileList">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-section flex">
                <button mat-button class="cancel-button" type="button" (click)="cancelUploadDoc(uploadPopup)">Cancel</button>
                <button mat-button class="submit-button" type="button"
                    [disabled]="(!docTypeDropdown.valid) || !(uploadedFileList.length > 0 && investigatorComment.valid )"
                    (click)="documentSubmit()">Submit</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #InvestCommentsPopup>
    <div class="doclist-popup">
        <div mat-dialog-actions>
            <div mat-dialog-close class="close-dialog" (click)="closeDialog()"
                [ngClass]="{'hni-close-dialog':isHNIUSer}">
                <div class="no-mobile">
                    Close<img src="assets/close.svg" class="close-icon">
                </div>
                <img src="assets/cross_circle_blue.svg" class="mobile">
            </div>
        </div>
        <div class="doclist-popup-header">Investigator Comments</div>
        <div class="doclist-popup-content popup-content-height">
            <div class="overflow-container">
                <div class="top-section">
                    <div class="custom-grid-tile">
                        <div class="proof-type">Investigator comment:<span class="mandatory">*</span></div>
                        <div class="field-value">
                            <mat-form-field floatLabel="never" class="comments-form-field">
                                <textarea class="accoment" [formControl]="investigatorComment" matInput></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-section flex">
                <button mat-button class="cancel-button" type="button" (click)="cancelUploadDoc(uploadPopup)">Cancel</button>
                <button mat-button class="submit-button" type="button" [disabled]="!investigatorComment.valid"
                    (click)="investIncomeSubmit()">Submit</button>
            </div>
        </div>
    </div>
</ng-template>