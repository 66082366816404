import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestigatorLandingComponent } from './investigator-landing/investigator-landing.component';
import { InvestigatorFrScreenComponent } from './investigator-fr-screen/investigator-fr-screen.component';
import { InvestigatorRoutingModule } from './invetigator-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        InvestigatorLandingComponent,
        InvestigatorFrScreenComponent,
    ],
    imports:[
        CommonModule,
        InvestigatorRoutingModule,
        SharedModule,
    ],
    exports:[],
})

export class InvestigatorModule { }