import { Component, OnInit ,Input,Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sr-popup',
  templateUrl: './sr-popup.component.html',
  styleUrls: ['./sr-popup.component.css']
})
export class SrPopupComponent implements OnInit {
  srMessage :string;
  isSuccessMsg: boolean = false;
  showClose: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data, public auth: AuthService, public dialog: MatDialog, public router: Router) { }

  ngOnInit(): void {
    if (this.data) {
      this.srMessage = this.data.inputText;
      this.isSuccessMsg = this.data.successMsg ? this.data.successMsg : false;
      this.showClose = this.data.showClose;
    }
  }
  onCloseClick() {
    this.auth.logout('M');
    setTimeout(() => {
      this.router.navigate(['/login']);
      this.dialog.closeAll();
    }, 10);
  }

}
