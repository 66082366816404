<div class="edit-communication-details" [ngClass]="{'virtual-branch': dataService.isVirtualBranch}">
        <div mat-dialog-actions *ngIf ="!queryparam && !data.disableClose">
                <div mat-dialog-close class="close-dialog" (click)="closePopup();trackUserActions({ 'eventName' : 'Edit communication details - close' })" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    Close
                    <img src="assets/close.svg">
                </div>  
            </div>
    <div class="edit-communication-details-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
        Edit Communication Details
    </div>
    <div class="edit-communication-details-content popup-content-height">
        <form *ngIf="formInitialized" [formGroup]="editCommDtlsForm" (ngSubmit)="submitEditCommDtlsForm();trackUserActions({ 'eventName' : 'Edit communication details - save changes' })">
                <div class="email-address-content">
                        <div class="email-address-div flex">
                                Email Address <span class="fill-remaining-space"></span> <div class="mandatory">*<span class="mandatory-div">Mandatory field</span></div></div>
                               <div class="flex communicate-flex" formGroupName="email">
                                <div class="form-field-div">
                                    <mat-form-field class="communication-form-field no-country-code-field">
                                        <label class="form-field-label primary-label">Primary <span class="mandatory">*</span></label>
                                        <input type="text" maxlength="170" matInput formControlName="primary">
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('email.primary').errors?.errorMsg">{{editCommDtlsForm.get('email.primary').errors?.errorMsg}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <span class="fill-remaining-space"></span>
                                <div class="form-field-div">
                                    <mat-form-field class="communication-form-field no-country-code-field">
                                        <label class="form-field-label">Alternate</label>
                                        <input type="text" maxlength="170" matInput formControlName="alternate">
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('email.alternate').errors?.errorMsg">{{editCommDtlsForm.get('email.alternate').errors?.errorMsg}}</mat-error>
                                    </mat-form-field>
                                </div>
                                </div>
                            </div>
                <div class="email-address-content">
                        <div class="email-address-div margin-top">Mobile Number</div>
                        <div class="flex communicate-flex">
                                <div class="form-field-div flex-center" formGroupName="primaryMob">
                                    <mat-form-field class="communication-form-field country-code-width">
                                        <label class="form-field-label">Country code <span class="mandatory">*</span></label>
                                        <input type="tel" maxlength="6" matInput [matAutocomplete]="primarycountry" formControlName="countryCode">
                                        <!-- <mat-autocomplete #primary="matAutocomplete" panelClass="communicate-country-code" class="country-code">
                                            <mat-option *ngFor="let item of filteredCountryCodes | async" [value]="item">{{item}}</mat-option>
                                        </mat-autocomplete> -->
                                        <mat-autocomplete #primarycountry="matAutocomplete">
                                            <mat-option *ngFor="let option of primaryOptions | async" [value]="option[0]">
                                                <div class="alignment">
                                                    <div class="country_img" [id]="option[2]"></div>
                                                    <div class="name_list">{{option[1]}} +{{option[0]}}</div>
                                                </div>
                                            </mat-option>        
                                        </mat-autocomplete>
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('primaryMob.countryCode').errors?.errorMsg">{{editCommDtlsForm.get('primaryMob.countryCode').errors?.errorMsg}}</mat-error>
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('primaryMob.countryCode').errors?.required">Invalid input</mat-error>
                                    </mat-form-field>
                                    <span class="fill-remaining-space"></span>
                                    <mat-form-field class="communication-form-field country-parallel-width">
                                        <label class="form-field-label primary-label">Primary <span class="mandatory">*</span></label>
                                        <input type="tel" decimalNumbersOnly matInput formControlName="mobileNo" (keypress)="mobileNoKeyPress($event, 'primaryMob')" maxlength="10">
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('primaryMob.mobileNo').errors?.errorMsg">{{editCommDtlsForm.get('primaryMob.mobileNo').errors?.errorMsg}}</mat-error> 
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('primaryMob.mobileNo').errors?.required">Please enter mobile number</mat-error>                                                                               
                                    </mat-form-field>                                    
                                </div>
                                <div class="form-field-div flex-center" formGroupName="alternateMob">
                                    <mat-form-field class="communication-form-field country-code-width">
                                        <label class="form-field-label">Country code</label>
                                        <input type="tel" maxlength="6" matInput [matAutocomplete]="altcountry" formControlName="countryCode">
                                        <!-- <mat-autocomplete #auto="matAutocomplete" panelClass="communicate-country-code" class="country-code">
                                        <mat-option  *ngFor="let item of filteredAltCountryCodes | async" [value]="item">{{item}}</mat-option>
                                        </mat-autocomplete> -->
                                        <mat-autocomplete #altcountry="matAutocomplete">
                                            <mat-option *ngFor="let option of alternateOptions | async" [value]="option[0]">
                                                <div class="alignment">
                                                    <div class="country_img" [id]="option[2]"></div>
                                                    <div class="name_list">{{option[1]}} +{{option[0]}}</div>
                                                </div>
                                            </mat-option>        
                                        </mat-autocomplete>
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('alternateMob.countryCode').errors?.errorMsg">{{editCommDtlsForm.get('alternateMob.countryCode').errors?.errorMsg}}</mat-error>
                                    </mat-form-field>
                                    <span class="fill-remaining-space"></span>
                                    <mat-form-field class="communication-form-field country-parallel-width">
                                        <label class="form-field-label">Alternate</label>
                                        <input type="tel" decimalNumbersOnly matInput formControlName="mobileNo" (keypress)="mobileNoKeyPress($event, 'alternateMob')" maxlength="10" autocomplete="off">
                                        <mat-error class="error-message" *ngIf="editCommDtlsForm.get('alternateMob.mobileNo').errors?.errorMsg">{{editCommDtlsForm.get('alternateMob.mobileNo').errors?.errorMsg}}</mat-error>
                                    </mat-form-field>
                                </div>
                                </div>
                    </div>
                    <div class="email-address-content">
                            <div class="email-address-div margin-top">Telephone Number</div>
                            <div class="flex communicate-flex">
                                    <div class="form-field-div flex-center" formGroupName="homeTel">
                                        <mat-form-field class="communication-form-field country-code-width">
                                            <label class="form-field-label">Code</label>
                                            <input type="tel" maxlength="5" decimalNumbersOnly class="country-code" matInput formControlName="countryCode" autocomplete="off">
                                            <mat-error class="error-message" *ngIf="editCommDtlsForm.get('homeTel.countryCode').errors?.errorMsg">{{editCommDtlsForm.get('homeTel.countryCode').errors?.errorMsg}}</mat-error>
                                        </mat-form-field>
                                        <span class="fill-remaining-space"></span>
                                        <mat-form-field class="communication-form-field country-parallel-width">
                                            <label class="form-field-label">Home</label>
                                            <input type="tel" maxlength="10" decimalNumbersOnly matInput formControlName="telNo">
                                            <mat-error class="error-message" *ngIf="editCommDtlsForm.get('homeTel.telNo').errors?.errorMsg">{{editCommDtlsForm.get('homeTel.telNo').errors?.errorMsg}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-field-div flex-center" formGroupName="officeTel">
                                        <mat-form-field class="communication-form-field country-code-width" floatLabel="never">
                                            <label class="form-field-label">Code</label>
                                            <input type="tel" maxlength="5" class="country-code" matInput formControlName="countryCode" autocomplete="off" decimalNumbersOnly>
                                            <mat-error class="error-message" *ngIf="editCommDtlsForm.get('officeTel.countryCode').errors?.errorMsg">{{editCommDtlsForm.get('officeTel.countryCode').errors?.errorMsg}}</mat-error>
                                        </mat-form-field>
                                        <span class="fill-remaining-space"></span>
                                        <mat-form-field class="communication-form-field country-parallel-width">
                                            <label class="form-field-label">Office</label>
                                            <input type="tel" maxlength="10" decimalNumbersOnly matInput formControlName="telNo" autocomplete="off">
                                            <mat-error class="error-message" *ngIf="editCommDtlsForm.get('officeTel.telNo').errors?.errorMsg">{{editCommDtlsForm.get('officeTel.telNo').errors?.errorMsg}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    </div>
                        </div>
            <div class="button-div flex">
                <button mat-button class="close-button" type="button" (click)="closeDialog();trackUserActions({ 'eventName' : 'Edit communication details - cancel' })">Cancel</button>
                <button mat-button class="save-button" type="submit">Save Changes</button>
            </div>
        </form>
    </div>
</div>
