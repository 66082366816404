        <div class="neft-popup">
        <div class="pan-card-details">
            <div *ngIf="!data.disableClose" mat-dialog-actions (click)="closeDialog()">
                <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    Close
                    <img src="assets/close.svg">
                </div>
            </div>
            <div class="pan-card-details-header flex" *ngIf="!chequeFlag" [ngClass]="{'hni-popups-header':isHNIUSer}">
                Edit Neft-Details
            </div>
            <div class="pan-card-details-header flex" *ngIf="chequeFlag" [ngClass]="{'hni-popups-header':isHNIUSer}">
                Edit NRE Details
            </div>
            <div class="pan-card popup-content-height" *ngIf="!editNeftProceed">
                <div class="pan-card-confirmation" *ngIf="!chequeFlag">
                    Are you sure you want to edit this NEFT details?
                </div>
                <div class="pan-card-confirmation" *ngIf="chequeFlag">
                    Are you sure you want to edit this NRE details?
                </div>
                <div class="button-div flex">
                    <button matButton class="save-button update-pan-btn update-pan-mobbtn"
                        (click)="editNeftDtls();trackUserActions('yes')">Yes</button>
                    <button matButton
                        class="close-button update-pan-btn update-pan-mobbtn" type="button" (click)="closeDialog();trackUserActions('no')">No</button>
                </div>
            </div>
            <div class="neft-acc-type" *ngIf="editNeftProceed">
            <div class="neft-form-field-div" *ngIf="editNeftProceed">
                    <mat-form-field class="mailing-form-field">
                        <label class="form-field-label" *ngIf="!chequeFlag">Select Source of NEFT<span class="error-two">*</span></label>
                        <label class="form-field-label" *ngIf="chequeFlag">Select Source of NRE<span class="error-two">*</span></label>
                        <mat-select class="select-field" disableRipple disableOptionCentering (selectionChange)="valueChanged($event)"
                            floatLabel="never">
                            <mat-option value="Select">Select</mat-option>
                            <mat-option value="Add New">Add New</mat-option>
                            <mat-option value="Existing Account">Existing Account</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="noExistingNeft" class="emptyNeftDtls">No Existing Accounts Found</div>
                </div>
            <div class="neft-form-field-div" *ngIf="editNeftProceed && existingNeftDtlsFlag">
                        <mat-form-field class="mailing-form-field">
                            <label class="form-field-label">Select Account No.<span class="error-two">*</span></label>
                            <mat-select class="select-field" disableRipple disableOptionCentering (selectionChange)="valueChangedAccountNo($event)"
                                floatLabel="never">
                                <mat-option value="Select">Select</mat-option>
                                <mat-option class="select-field-option" *ngFor="let item of neftACCNo" [value]="item.acc_no">{{ item.acc_no }} - {{ item.bank_name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                </div>
            </div>
        </div>
    </div>
