import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { InvestigatorService } from 'src/app/services/investigator/investigator.service';
import { throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-investigator-landing',
  templateUrl: './investigator-landing.component.html',
  styleUrls: ['./investigator-landing.component.css']
})
export class InvestigatorLandingComponent implements OnInit {

  investigatorMobileForm: FormGroup;
  investigatorEmailForm: FormGroup;
  policyFocus: boolean = false;
  mobileNumFocus: boolean = false;
  emailFocus: boolean = false;
  panNumFocus:boolean = false;
  loginCaptchaFocusOut: boolean = false;
  isCaptchaValid:boolean = false;
  statusMessage: string;
  loginEvent:any;
  mobileChoice:boolean = false;
  emailChoice:boolean = false;
  mobileNumber: any = '9884120857';
  email:any = 'abcdefg@gmail.com';
  maskMobileNumber: any;
  maskEmail:any;
  otpForm: FormGroup;
  today = new Date();
  otpFocus: boolean = false;
  otpFlag: boolean = false;
  interval: any;
  hours: number = 0;
  minutes: number = 0;
  timerSec: number = 180;
  buttonClick: boolean = false;
  transID: any;
  clientid: string;
  action: string;
  policynum: string;
  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  isOtpMasked: boolean = true;
  mode: any;

  constructor(public message: MessageService,public utils: Utils, private fb: FormBuilder,public router: Router,public _httpService: HttpService, public investigatorService: InvestigatorService,public dataService: DataService) { }

  ngOnInit(): void {
    this.investigatorMobileForm = this.fb.group({
      policyNo: ['', Validators.required],
      mobileNum: ['',Validators.required],
      panNum: ['', [Validators.required,Validators.pattern(/^([A-Z]{5})([0-9]{4})([A-Z]{1})$/)]],
      investigatorCaptcha: ['', Validators.required]
    });
    this.investigatorEmailForm = this.fb.group({
      policyNo: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      panNum: ['', [Validators.required,Validators.pattern(/^([A-Z]{5})([0-9]{4})([A-Z]{1})$/)]],
      investigatorCaptcha: ['', Validators.required]
    });
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
    });
  }

  submitMobileInvestigatorForm() {
    if(this.isCaptchaValid) {
    this.init().subscribe((resp) => {
      this._httpService.initResp = resp;
      this.getUserDetails('mobile');
    }),
      catchError((error) => throwError(error)); 
   }
    else {
      this.investigatorMobileForm.get('investigatorCaptcha').setErrors({ captchaIncorrect: true });
    }
  }

  submitEmailInvestigatorForm() { 
    if(this.isCaptchaValid) {
    this.init().subscribe((resp) => {
      this._httpService.initResp = resp;
      this.getUserDetails('email');
    }),
      catchError((error) => throwError(error));
    }
    else {
      this.investigatorEmailForm.get('investigatorCaptcha').setErrors({ captchaIncorrect: true });
    }
  }
  init() {
    return this._httpService.init(this.httpHeaders);
  }
  policyFocusOut() {
    this.policyFocus = true;
  }

  mobileNumFocusOut() {
    this.mobileNumFocus = true;
  }

  emailFocusOut() {
    this.emailFocus = true;
  }

  panNumFocusOut() {
    this.panNumFocus = true;
  }

  captchaFocusOut(event: any) {
    this.loginCaptchaFocusOut = true;
  }

  errorclear() {
    this.statusMessage = "";
  }

  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
     this.investigatorMobileForm.controls.investigatorCaptcha.updateValueAndValidity();
     this.investigatorEmailForm.controls.investigatorCaptcha.updateValueAndValidity();
    }, 10);
  }

  clearClientCaptcha() {
    this.investigatorMobileForm.controls.investigatorCaptcha.setValue('');
    this.investigatorEmailForm.controls.investigatorCaptcha.setValue('');
  }

  clearCheckbox(changeEvent: MatTabChangeEvent) {
    //clear forms
    this.investigatorMobileForm.reset();
    this.investigatorEmailForm.reset();
  }

  otpFocusOut() {
    this.otpFocus = true;
  }
  toggleMasking() {
    this.isOtpMasked = !this.isOtpMasked;
  }

  resetForm(event) {
    if(event === 'mobile') {
      this.investigatorMobileForm.reset();
    }
    else if(event === 'email') {
      this.investigatorEmailForm.reset();
    }
  }

  getUserDetails(event) {
    this.mode = event;
    let userBody = {
      "MODE": "",
      "policyno": "",
      "invt_loginid": "",
      "PAN": "",
    };
    if(event === 'mobile') {
      this.mobileChoice = true;
      this.emailChoice = false;

      userBody.invt_loginid = this.investigatorMobileForm.controls.mobileNum.value;
      userBody.MODE= "MO";
      userBody.policyno= this.investigatorMobileForm.controls.policyNo.value;
      userBody.PAN = this.investigatorMobileForm.controls.panNum.value;
      this.policynum = this.investigatorMobileForm.controls.policyNo.value;
      
      return this.investigatorService.getInvestigatorDetails(userBody).subscribe((resp) => {
        if(this._httpService.isSuccessResponse(resp)) {
          if(resp.body.status !== 'Failed'){
          this.maskMobileNumber = resp.body.mobilenum;
          this.transID = resp.body.trans_id;
          this.clientid = resp.body.clientid;
          this.utils.setLocalData("id", this.clientid, false);
          this.genOtp();
          }
          else {
            this.message.openSnackBar('Something went wrong. Please try after sometime.', 'xy', ['error-snackbar'], 'top');
          }
        }
        else {
          this._httpService.apiErrorHandler(resp); 
        }
      }),
      catchError((error) => throwError(error));
    }
    else if(event === 'email') {
      this.emailChoice = true;
      this.mobileChoice = false;

      userBody.invt_loginid = this.investigatorEmailForm.controls.email.value;
      userBody.MODE= "EO";
      userBody.policyno= this.investigatorEmailForm.controls.policyNo.value;
      userBody.PAN = this.investigatorEmailForm.controls.panNum.value;
      this.policynum = this.investigatorEmailForm.controls.policyNo.value;

      return this.investigatorService.getInvestigatorDetails(userBody).subscribe((resp) => {
        if(this._httpService.isSuccessResponse(resp)) {
          if(resp.body.status !== 'Failed'){
          this.maskEmail = resp.body.Emailid;
          this.transID = resp.body.trans_id;
          this.clientid = resp.body.clientid;
          this.utils.setLocalData("id", this.clientid, false);
          this.genOtp();
          }
          else {
            this.message.openSnackBar('Something went wrong. Please try after sometime.', 'xy', ['error-snackbar'], 'top');
          }
        }
        else {
          this._httpService.apiErrorHandler(resp); 
        }
      }),
      catchError((error) => throwError(error));
    }
  }

  genOtp() {
    this.otpFlag = true;
    this.OTPTimerStart();
  }

  RegenOTP() {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.getUserDetails( this.mode);
  }

  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        // if (this.minutes == 60) {this.minutes = 0;} }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  submitOtpForm(){
    this.validateOtp();
  }

  validateOtp() {
    this.buttonClick = true;
    if(this.otpForm.valid) {
      this.verifyOtp();
    }
   }

   verifyOtp() {
     let reqBody = {
       "otpEntered": "",
       "trans_id": "",
       "POLICYNO": "",
       "clientid": this.utils.getClientId()
       }

     reqBody.otpEntered = this.otpForm.controls.otp.value;
     reqBody.trans_id = this.transID;

     if(this.mobileChoice) {
      reqBody.POLICYNO = this.investigatorMobileForm.controls.policyNo.value;
     }
     else if(this.emailChoice) {
       reqBody.POLICYNO = this.investigatorEmailForm.controls.policyNo.value;
     }
     return this.investigatorService.validateInvestigatorOtp(reqBody).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)) {
        if(resp.body.OTPVALIDATION === 'Y'){
        this.utils.setLocalData("action", 'SUR_INVEST', false);
        this.utils.setLocalData("source", "CL", false);
        this.reinit();
        }
        else{
          this.message.openSnackBar('Something went wrong. Please try after sometime.', 'xy', ['error-snackbar'], 'top');
        } 
      }
      else {
        this._httpService.apiErrorHandler(resp);
      }
     }),
     catchError((error) => throwError(error));
   }

  get emailvalidation() { return this.investigatorEmailForm.controls; }
  get mobilevalidation() { return this.investigatorMobileForm.controls; }
  reinit() {
    return this._httpService.reinit().subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        this.action = 'SUR_INVEST';
        if (response.body && this.clientid && this.policynum && this.action) {
          this.router.navigate(['/investigator/investigator-fr']);   
          this.dataService.setLoginResp({ ...response.body, "action": this.action });
        }
      } else {
        this._httpService.apiErrorHandler(response);
        this.resetAllForm();
      }
    }),
      catchError((error) => this.httpErrorHandler.bind(this, error));
  }
  resetAllForm() {
    this.investigatorMobileForm.reset();
    this.investigatorEmailForm.reset();
    this.otpFlag = false;
    this.dataService.setIsCustomLink(null);
    clearInterval(this.interval);
    this.timerSec = 180;
    localStorage.clear();
  }
  httpErrorHandler(error) {
    this.resetAllForm();
    throwError(error);
  }
}
