<div class="switch-policy-grp-div">
    <div mat-dialog-actions>
            <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}" >
                Close
                <img src="assets/close.svg">
            </div>  
        </div>
<div class="switch-policy-grp-header" [ngClass]="{'hni-popups-header':isHNIUSer}">
    Select client ID
</div>
<div class="switch-policy-grp-content popup-content-height">
 <div class="flex heading-div"><div class="heading">Client Id</div>
 <div class="heading">Policy Number</div></div>
 <form>
     <div class="content-div">
 <mat-radio-group class="pwd-opt-radio">
            <mat-radio-button [value]="item" (change)="selectClientID($event)" *ngFor = "let item of displayDetails" [checked]="chosenClientId == item.customer_id">   
                  <div class="flex">
                    <div class="policy-text">
                        {{item.customer_id}}
                    </div>
                       <div class="policy-col">  
                       <div class="policy-text policy-text-second flex" *ngFor = "let i of item.policy_details">
                           <div>{{i.policy_id}}</div>
                           <div class="status" [ngClass]="{'inactive-status':i.status == 'Inactive','active-status':i.status == 'Active'}">
                            <span> {{i.status}} </span>
                            </div>
                        </div>
                    </div>
             </div> 
            </mat-radio-button>
    </mat-radio-group>
</div>
    </form>
 <!-- <div class="flex button-gap">
    <button type="submit" class="forgot-pwd-btn"
(click)="submitForm()">Proceed</button> 
</div>  -->
</div>
<form #exideForm [action]="exideURL" method="POST" target="_blank">
    <input type="hidden" name="policyNo" [value]="policyId">
    <input type="hidden" name="mobileNo" value="">
    <input type="hidden" name="dob" id="dob" [value]="dob">
    <input type="hidden" name="functionType" [value]="funcType">
    <input type="hidden" name="source" [value]="source"> 
    <input type="hidden" name="checkSum" [value]="checkSumResp">
</form>
<!-- <input type="hidden" name="policyNo" [value]="policyId">
<input type="hidden" name="mobileNo" value="">
<input type="hidden" name="dob" id="dob" [value]="dob">
<input type="hidden" name="functionType" [value]="funcType">
<input type="hidden" name="source" [value]="source"> 
<input type="hidden" name="checkSum" [value]="checkSumResp"> -->
<div class="disclaimer-text">
    <span>Disclaimer:</span> Select a client ID to view and transact on all it's associated policies.
    <!-- <span>Disclaimer:</span> All the policies under the selected Client id will be visible post selection. For selecting another client ID, click on "Switch Client ID" option on my policies section. If you have more than 4 policies under single client ID then use "Select Policy" option to view the other policies. -->
 </div>
 <div class="disclaimer-text" *ngIf="showAddPolicyMsg">
    To view all your policies under single client ID, please click on 'Add Policy' button available on home screen under My Policies section
</div>
</div>

