<div class="my-policies-popup">
    <div *ngIf="!confirmAddress && !confirmBank && !confirmContact && !data.disableClose" mat-dialog-actions [ngClass]="{'hnilanding-close-dialog':isHNIUSer}">
        <div mat-dialog-close class="close-dialog">
            Close
        </div>
    </div>
    <div *ngIf="!confirmAddress && !confirmBank && !confirmContact" class="dialog-content-wrapper">
        <div class="policy-status-alert flex-center" *ngFor="let obj of policyPopupData">
            <div class="alert-msg" [innerHtml]="obj.msg"></div>
            <button mat-button class="primary-btn" (click)="onUpdateBtnClick(obj.target);utils.trackUserActions({'eventName': 'Confirmatory nudges - '+obj.label})">{{obj.label}} &#8250;</button>
        </div>
    </div>
    <div *ngIf="confirmBank" class="confirm-content-wrapper contact-div">
        <mat-card-title class="title-text ">Confirm NEFT Details</mat-card-title>
        <div class="confirm-div">'Confirm' if you wish to proceed with this bank details for all payout transactions (as
            applicable)</div>
            <!-- {{data.displayDtls.BANKNAME}} {{data.displayDtls.ACC_NUM}} -->
        <div class="confirm-div"><span><b>Bank Name:</b> {{data.displayDtls.BANKNAME}} </span></div>
        <div class="confirm-div"><span><b>Account Number:</b> {{data.displayDtls.ACC_NUM}}</span> </div>
        <div class="button-div flex">
            <button mat-button class="cancel-btn" (click)="confirmedNeft();utils.trackUserActions({'eventName': 'Confirm NEFT details - Confirm'})">Confirm</button>
            <button mat-button class="submit-btn" (click)="updateBank();utils.trackUserActions({'eventName': 'Confirm NEFT details - Update Bank Details'})">Update Bank Details</button>
            <button mat-button class="cancel-btn" (click)="closePopup();utils.trackUserActions({'eventName': 'Confirm NEFT details - Update Later'})">Update Later</button>
        </div>
    </div>
    <div *ngIf="confirmContact" class="confirm-content-wrapper contact-div">
        <mat-card-title class="title-text ">Confirm Contact Details</mat-card-title>
        <div class="confirm-div">
            <div class="msg-div"><b>Mobile Number:</b>  {{data.displayDtls.CONTACT_CELL_PH_NUMBR}} </div>
            <div class="msg-div"><b>Alternate Mobile Number:</b> {{data.displayDtls.CONTACT_ALT_PH_NUM}} </div>
        </div>
        <!-- {{data.displayDtls.CONTACT_CELL_PH_NUMBR}} {{data.displayDtls.CONTACT_ALT_PH_NUM}}{{data.displayDtls.CONTACT_EMAIL_ADDRESS}} {{data.displayDtls.CONTACT_X_ALT_EMAIL}}-->

        <div class="confirm-div">
            <div class="msg-div"><b>Email Id:</b> {{data.displayDtls.CONTACT_EMAIL_ADDRESS}}</div>
            <div class="msg-div"><b>Alternate Email Id:</b> {{data.displayDtls.CONTACT_X_ALT_EMAIL}} </div>
        </div>
        <div class="button-div flex">
            <button mat-button class="cancel-btn" (click)="confirmedContact();utils.trackUserActions({'eventName': 'Confirm contact details - Confirm'})">Confirm</button>
            <button mat-button class="submit-btn" (click)="updateContact();utils.trackUserActions({'eventName': 'Confirm contact details - Update Contact Details'})">Update Contact Details</button>
            <button mat-button class="cancel-btn" (click)="closePopup();utils.trackUserActions({'eventName': 'Confirm contact details - Update Later'})">Update Later</button>
        </div>
    </div>
    <div *ngIf="confirmAddress" class="confirm-content-wrapper contact-div">
        <mat-card-title class="title-text">Confirm Address Details</mat-card-title>
        <div class="confirm-div">
            <div class="msg-div"><b>Permanent Address</b></div>
            <div class="msg-div">{{ClientAddress.HOUSENUMBER}}</div>
            <div class="msg-div">{{ClientAddress.STREET}}</div>
            <div class="msg-div"><b>Pincode : </b>{{ClientAddress.PINCODE}}</div>
            <div class="msg-div"><b>City : </b>{{ClientAddress.CITY}}<span class="permAddress-state"><b>State : </b>{{ClientAddress.STATE}}</span></div>
        </div>
        <div class="button-div flex address-div">
            <button mat-button class="cancel-btn" (click)="confirmedAddress()">Confirm</button>
            <button mat-button class="submit-btn" (click)="updateAddress()">Update Address Details</button>
            <!-- <button mat-button class="cancel-btn" (click)="closePopup()">Update Later</button> -->
        </div>
    </div>
    <div *ngIf="data.unclaimedAmtFlags && data.unclaimedAmtFlags.KYC_ACTIVE=='N'" class="dialog-content-wrapper">
        <div class="policy-status-alert flex-center">
            <div class="alert-msg">Please update your KYC details</div>
            <button mat-button class="primary-btn" (click)="onUnclaimedAmtKYCUpdate()">Update KYC &#8250;</button>
        </div>
    </div>
    <div *ngIf="data.unclaimedAmtFlags && data.unclaimedAmtFlags.neft_exist=='N'" class="dialog-content-wrapper">
            <div class="policy-status-alert flex-center">
                <div class="alert-msg">Please update your Bank Account details</div>
                <button mat-button class="primary-btn" (click)="onUnclaimedAmtNEFTUpdate()">Update Bank Details &#8250;</button>
            </div>
        </div>
    <div *ngIf="data.updatePANFlag" class="dialog-content-wrapper">
        <div class="policy-status-alert flex-center">
            <div class="alert-msg">{{data.message}}</div>
            <button mat-button class="primary-btn" (click)="startPANJourney()">{{data.btnLabel}} &#8250;</button>
        </div>
    </div>
</div>
