<div>
    <div class="claim-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Payee Details</div>
    <mat-card class="payee-details-card">
            <div class="payee-details">
                    <div class="payee-details-content flex">
                            <div class="client-icon no-mobile">
                                    <img src='/assets/Icon style 1.svg'>
                                </div>
                        <div class="payee-details-field">
                            <div class="payee-details-title">
                                Client Id
                            </div>
                            <div class="payee-details-text">
                                {{clientId}}
                            </div>
                        </div>
                        <div class="client-icon no-mobile">
                                <img src='/assets/name.svg'>
                            </div>
                        <div class="payee-details-field">
                            <div class="payee-details-title">
                                Name
                            </div>
                            <div class="payee-details-text">
                                {{payeeName}}
                            </div>
                        </div>
                        <div class="client-icon no-mobile">
                                <img src='/assets/role.svg'>
                            </div>
                        <div class="payee-details-field">
                            <div class="payee-details-title">
                                Role
                            </div>
                            <div class="payee-details-text">
                                {{payeeRole}}
                            </div>
                        </div>
                        <div class="client-icon no-mobile">
                                <img src='/assets/allocation.svg'>
                            </div>
                        <div class="payee-details-field">
                            <div class="payee-details-title">
                                Allocation in %
                            </div>
                            <div class="payee-details-text">
                                {{percentage}}
                            </div>
                        </div>
                    </div>
            </div>
        </mat-card>
<mat-card class="payout-details-page personal-details">
    <div *ngIf="!annuity && !surrenderFlag" class="dnd-options contact-details">
        <div class="payout-details-header">
            Personal Details
        </div>
        <div class="personal-details-section">
            <div class="client-id">
                <div class="client-id-section">Client ID</div>
                <div class="client-id-number">{{clientId}}</div>
            </div>
            <div class="personal-details-content flex">
                <div class="left-content">
                    <div class="name-div flex">
                        <img src="assets/person.svg" class="person-image no-mobile">
                        <div class="name-text-div">
                            <div class="name-text"> Name </div>
                                <!-- <img class="edit-button" src="assets/edit_btn.svg" (click)="updateMailingAddressPersonal()"> -->
                            <span class="flex name-span">
                                <span>{{pdtlsFirstName}}</span>&nbsp;<span>{{pdtlsLastName}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="name-div flex">
                        <img src="assets/gender.svg" class="person-image no-mobile">
                        <div class="name-text-div">
                            <div class="name-text">Gender </div>
                            <div class="name-span">{{pdtlsGender}}</div>
                        </div>
                    </div>
                </div>
                <div class="right-content">
                    <div class="name-div flex">
                        <img src="assets/calender.svg" class=" person-image no-mobile">
                        <div class="name-text-div">
                            <div class="name-text">Date of Birth</div>
                            <div class="name-span">{{pdtlsDob}}</div>
                        </div>
                    </div>
                    <div class="name-div flex">
                        <img src="assets/martial.svg" class="person-image no-mobile">
                        <div class="name-text-div">
                            <div class="name-text">Marital Status</div>
                            <div class="name-span">{{pdtlsMaritalStatus}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!annuity && !surrenderFlag" class="contact-details">
        <div class="dnd-options contact-details-options">
            <div class="payout-details-header flex">
                Address
                <span class="fill-remaining-space"></span>
            </div>
            <div class="content">
                <div class="flex bottom-communicate-div">
                    <div class="communication-field flex communicate-align communicate-left">
                        <img class="no-mobile" src="assets/location.svg">
                        <div class="com-field-div">
                            <div class="email-id-span-two span-two-margin">
                                <p><span>{{houseNo}}, &nbsp;</span>
                                <span>{{street}}, &nbsp;</span>
                                <span>{{landmark}}, &nbsp;</span>
                                <span>{{city}}, &nbsp;</span></p>
                                <p>{{state}} </p>
                                <p>{{pincode}}</p>
                                <p>{{country}}</p>
                            </div>
                            <div class="mailing-address-span-three">All Communication will be sent to this address
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!annuity && !surrenderFlag" class="contact-details">
        <div class="dnd-options contact-details-options">
            <div class="payout-details-header flex">
                Contact Details
                <span class="fill-remaining-space"></span>
            </div>
            <div class="content flex">
                <div class="communication-field communication-field-border flex communicate-align kyc-left">
                    <img class="no-mobile" src="assets/email.svg">
                    <div class="com-field-div">
                        <div class="email-id"> Email ID </div>
                        <div class="email-id-span-two">{{email}}</div>
                        <div class="alternate-email-id-span"><span class="alternate-special-span">Alternate Email ID
                            </span>{{altEmail}}</div>

                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right">
                    <img class="no-mobile" src="assets/mobile.svg">
                    <div class="com-field-div">
                        <div class="email-id"> Mobile Number </div>
                        <div class="email-id-span-two"><span>{{countryCode}}&nbsp;</span>{{mobile}}</div>
                        <div class="alternate-email-id-span"><span class="alternate-special-span">Alternate
                                Number:</span><span>{{altMobCode}}&nbsp;</span>{{altMobNum}}</div>
                    </div>
                </div>
                <div
                    class="communication-field communication-field-border flex communicate-align kyc-left communicate-bottom-fields">
                    <img class="no-mobile" src="assets/call-blue.svg">
                    <div class="com-field-div">
                        <div class="email-id"> Home Telephone Number </div>
                        <div class="email-id-span-two"><span>{{phoneCode}}&nbsp;</span>{{phoneNum}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img class="no-mobile" src="assets/call-blue.svg">
                    <div class="com-field-div">
                        <div class="email-id">Office Telephone Number </div>
                        <div class="email-id-span-two"><span>{{workPhoneCode}}&nbsp;</span>{{workPhoneNum}}</div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <div *ngIf="!annuity" class="contact-details">
        <div class="dnd-options contact-details-options">
            <div class="payout-details-header flex">
                KYC Details of {{ payeeName }}
            </div>
            <div class="card-bottom" *ngIf="pendingKyc">
                <div class="alert flex" >
                    <div class="alert-message">Your existing request for KYC update (SR-{{pendingKycSr}}) is pending for action. </div>
                    <button class="revive-button">
                        <div class="button-text" (click)="uploadDoc('KYC')">Upload &#8250;</div>
                    </button>
                </div>
                </div>
            <div class="content flex">
                <div class="communication-field communication-field-border flex communicate-align kyc-left">
                    <div class="com-field-div">
                        <div class="email-id">KYC status</div>
                        <div class="email-id-span-two">{{isKycMessage}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right">
                    <div class="com-field-div">
                        <div class="email-id">KYC expiry date </div>
                        <div class="email-id-span-two">{{kycExpiry}}</div>
                    </div>
                </div>
                <div class="communication-field communication-field-border flex communicate-align kyc-left communicate-bottom-fields">
                    <div class="com-field-div"> <button class="delete-btn" [disabled]="updateKYC" (click)="updateKycDetails()">Update KYC</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!annuity" class="contact-details">
        <div class="dnd-options contact-details-options">
            <div class="payout-details-header flex">
                PAN Details of {{ payeeName }}
                <img src="assets/edit_btn.svg" *ngIf="!pendingPan && panVerified == 'No'" class="edit-button" (click)="updatePanDetails(panDialog)">
            </div>
            <div class="card-bottom" *ngIf="pendingPan">
                <div class="alert flex" >
                    <div class="alert-message">Your existing request for PAN update (SR-{{pendingPanSr}}) is pending for action. </div>
                    <button class="revive-button">
                        <div class="button-text" (click)="uploadDoc('PAN')">Upload &#8250;</div>
                    </button>
                </div>
                </div>
            <div class="content flex">
                <div class="communication-field communication-field-border flex communicate-align communicate-left">
                    <img class="no-mobile" src="assets/save.svg">
                    <div class="com-field-div">
                        <div class="email-id">PAN<img class="verified" src="assets/green-tick.svg" *ngIf="panVerified != 'No'"></div>
                        <div class="email-id-span-two" id = "pan">{{panNum}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-details">
        <div class="dnd-options contact-details-options">
            <div class="payout-details-header flex">
                Payout Details of {{ payeeName }}
            </div>
            <div class="payout-content flex">
                <div class="communication-field communication-field-border flex communicate-align communicate-left">
                    <img src="assets/person.svg" class="details-image">
                    <div class="com-field-div">
                        <div class="email-id">Payee Account</div>
                        <div class="email-id-span-two">{{payeeAccount}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right">
                        <img src="assets/card.svg">
                    <div class="com-field-div">
                        <div class="email-id">Payment Mode </div>
                        <div class="email-id-span-two">{{paymentMode}}</div>
                    </div>
                </div>
            </div>
            <div class="payout-details-header-Neft flex">
                NEFT Bank Account Details:
                <img src="assets/edit_btn.svg" *ngIf="!pendingNeft" class="edit-button" (click)="updateNeftDetails('NEFT')">
            </div>
            <div class="card-bottom" *ngIf="pendingNeft">
            <div class="alert flex" >
                <div class="alert-message">Your existing request for NEFT update (SR-{{pendingNeftSr}}) is pending for action. </div>
                <button class="revive-button">
                    <div class="button-text" (click)="uploadDoc('NEFT')">Upload &#8250;</div>
                </button>
            </div>
            </div>
            <div class="Neft-content flex" *ngIf ="payeeRole != 'Beneficiary'" [ngClass]="{'neft-content-border' : displayNeft , 'neft-content-padding' : pendingNeft}">
                <div
                    class="communication-field communication-field-border flex communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/account_number.svg" class="details-image">
                    <div class="account-details-text">
                        <div class="email-id"> Account Number </div>
                        <div class="email-id-span-two" id = "account-number">{{NEFTDetail.ACC_NUM}}</div>
                    </div>
                </div>
                <div class="communication-field communication-field-border flex communicate-align communicate-center communicate-bottom-fields">
                    <img src="assets/bank-blue.svg" class="details-image">
                    <div class="account-details-text">
                        <div class="email-id">Bank Name </div>
                        <div class="email-id-span-two">{{NEFTDetail.BANKNAME}}</div>
                    </div>
                </div>
                <div
                    class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img src="assets/name.svg">
                    <div class="account-details-text">
                        <div class="email-id">Account Holder Name </div>
                        <div class="email-id-span-two">{{NEFTDetail.ACCNAME}}</div>
                    </div>
                </div>
                <div class="communication-field flex communication-field-border communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/account_type.svg">
                    <div class="account-details-text">
                        <div class="email-id">Account Type </div>
                        <div class="email-id-span-two">{{NEFTDetail.NEFTACCOUNTTYPE}}</div>
                    </div>
                </div>
                <div
                    class="communication-field communication-field-border flex communicate-align communicate-center communicate-bottom-fields">
                    <img src="assets/ifsc_code.svg">
                    <div class="account-details-text">
                        <div class="email-id">IFSC Code</div>
                        <div class="email-id-span-two">{{NEFTDetail.IFSCCODE}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img src="assets/branch name.svg">
                    <div class="account-details-text">
                        <div class="email-id">Branch Name</div>
                        <div class="email-id-span-two">{{NEFTDetail.BRANCHNAME}}</div>
                    </div>
                </div>
            </div>
            <div class="Neft-content flex" *ngIf ="payeeRole == 'Beneficiary'" [ngClass]="{'neft-content-border' : displayNeft , 'neft-content-padding' : pendingNeft}">
                <div
                    class="communication-field communication-field-border flex communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/account_number.svg" class="details-image">
                    <div class="account-details-text">
                        <div class="email-id"> Account Number </div>
                        <div class="email-id-span-two" id = "account-number">{{NEFTDetail.BEN_ACCNUMBER}}</div>
                    </div>
                </div>
                <div class="communication-field communication-field-border flex communicate-align communicate-center communicate-bottom-fields">
                    <img src="assets/bank-blue.svg" class="details-image">
                    <div class="account-details-text">
                        <div class="email-id">Bank Name </div>
                        <div class="email-id-span-two">{{NEFTDetail.BEN_BANKNAME}}</div>
                    </div>
                </div>
                <div
                    class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img src="assets/name.svg">
                    <div class="account-details-text">
                        <div class="email-id">Account Holder Name </div>
                        <div class="email-id-span-two">{{NEFTDetail.BEN_ACCNAME}}</div>
                    </div>
                </div>
                <div class="communication-field flex communication-field-border communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/account_type.svg">
                    <div class="account-details-text">
                        <div class="email-id">Account Type </div>
                        <div class="email-id-span-two">{{NEFTDetail.BEN_ACCTYPE}}</div>
                    </div>
                </div>
                <div
                    class="communication-field communication-field-border flex communicate-align communicate-center communicate-bottom-fields">
                    <img src="assets/ifsc_code.svg">
                    <div class="account-details-text">
                        <div class="email-id">IFSC Code</div>
                        <div class="email-id-span-two">{{NEFTDetail.BEN_IFSCCODE}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img src="assets/branch name.svg">
                    <div class="account-details-text">
                        <div class="email-id">Branch Name</div>
                        <div class="email-id-span-two">{{NEFTDetail.BEN_BRANCHNAME}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf = "displayPremium">
            <div class="payout-details-header-Neft flex">
                Premium Payment Details
                <img src="assets/edit_btn.svg" class="edit-button" (click)="premiumDtls()">
            </div>
            <div class="premium-content flex" [ngClass]="{'neft-content-border' : isCheque}">
                <div class="communication-field communication-field-border  communicate-align communicate-left">
                        <form [formGroup]="editPremiumForm" class>
                                <img src="assets/bank-blue.svg" class="details-image">
                            <mat-form-field class="com-field-div">
                                <label class="email-id">Bank Name </label>
                                <input matInput type="text" formControlName="PremBankName" (focusout)="editBankName()" [disabled]="editFlag" [readonly]="editFlag">
                            </mat-form-field>
                </form>
                </div>
                <div class="communication-field communication-field-border  flex communicate-align communicate-center">
                        <img src="assets/Total Premium Paid Through NRE.svg">
                    <div class="com-field-div">
                        <div class="email-id">Total Premium Paid through NRE A/c. </div>
                        <div class="email-id-span-two">{{premiumPaidByNRE}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right">
                        <img src="assets/Total Premium Paid Through NRE.svg">
                    <div class="com-field-div">
                        <div class="email-id">Total Premium Paid </div>
                        <div class="email-id-span-two">{{premiumPaid}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf = "displayCheque">
            <div class="payout-details-header-Neft flex">
                Cheque Bank Details:
                <img src="assets/edit_btn.svg" class="edit-button" (click)="updateChequeDetails('CHEQUE')">
            </div>
            <div class="card-bottom" *ngIf="pendingCheque">
                <div class="alert flex" >
                    <div class="alert-message">Your existing request for CHEQUE update (SR-{{pendingChequeSr}}) is pending for action. </div>
                    <button class="revive-button">
                        <div class="button-text" (click)="uploadDoc('CHEQUE')">Upload &#8250;</div>
                    </button>
                </div>
                </div>
            <div class="cheque-content flex"  [ngClass]="{'neft-content-border' : displayCheque}">
                <div
                    class="communication-field communication-field-border flex communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/name.svg">
                    <div class="account-details-text">
                        <div class="email-id"> Account Holder Name </div>
                        <div class="email-id-span-two">{{accHolderName}}</div>
                    </div>
                </div>
                <div class="communication-field communication-field-border flex communicate-align communicate-center communicate-bottom-fields">
                        <img src="assets/account_number.svg" class="details-image">
                    <div class="account-details-text">
                        <div class="email-id">Account Number </div>
                        <div class="email-id-span-two" id = "account-number">{{accountNo}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-right communicate-bottom-fields">
                    <img src="assets/account_type.svg">
                    <div class="account-details-text">
                        <div class="email-id">Account Type </div>
                        <div class="email-id-span-two">{{accountType}}</div>
                    </div>
                </div>
                <div class="communication-field flex communicate-align communicate-left communicate-bottom-fields">
                    <img src="assets/ifsc_code.svg">
                    <div class="account-details-text">
                        <div class="email-id">IFSC Code</div>
                        <div class="email-id-span-two">{{ifscCode}}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="flex edit-payment-btn ">
            <button class="cancel-btn" type="button" (click)="backBtn()">Back</button>
            <button class="delete-btn" (click)="goToPayee(VerifyPayee)">Continue</button>
        </div>
</mat-card>
</div>

<ng-template #panDialog>
<div class="pan-card-details">
    <div mat-dialog-actions (click)="closeDialog()">
        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close
            <img src="assets/close.svg">
        </div>
    </div>
    <div class="pan-card-details-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">
        Are you sure?
    </div>
    <div class="pan-card popup-content-height">
        <div class="pan-card-content">Your PAN details are valid and verified.
        </div>
        <div class="pan-card-confirmation">
            Do you wish to proceed?
        </div>
        <div class="button-div flex">
            <button matButton class="close-button update-pan-btn update-pan-mobbtn" type="button"
                (click)="closeDialog()">Cancel</button>
            <button matButton class="save-button update-pan-btn update-pan-mobbtn"
                (click)="editPanDetails(editPan)">Yes</button>
        </div>
    </div>
</div>
</ng-template>
<ng-template #editPan>
<div class="pan-card-details">
    <div mat-dialog-actions (click)="closeDialog()">
        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close
            <img src="assets/close.svg">
        </div>
    </div>
    <div class="pan-card-details-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">Edit PAN</div>
    <div class="pan-card popup-content-height">
        <div class="mandatory-field"><span class="red-star">*</span>Mandatory Field
        </div>
        <form [formGroup]="editPanForm" (ngSubmit)="submitEditPanForm()">
        <div class="form-section flex">
                <div class="fields">
                    <div class="pan-card-input">
                        <div class="form-label">PAN <sup class="red-star">*</sup></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>Please enter your PAN Number</mat-placeholder>
                            <input formControlName="panNo" autocomplete="off" type="text" matInput maxlength="10">
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panNo.errors?.errorMsg">{{editPanForm.controls.panNo.errors?.errorMsg}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pan-card-input" *ngIf="!pdtlsDob || pdtlsDob == 'NA'">
                        <div class="form-label">DOB as per PAN <sup class="red-star">*</sup><img src="assets/info_active.svg" class="tooltip-popover-pan-dob info-img"></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                            <input formControlName="panDOB" type="text" autocomplete="off" matInput [max]="today" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panDOB.errors?.errorMsg">{{editPanForm.controls.panDOB.errors?.errorMsg}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="pan-card-input">
                        <div class="form-label">Name as per PAN card <sup class="red-star">*</sup></div>
                        <mat-form-field floatLabel="never" class="form-field text-field">
                            <mat-placeholder>Please enter name as per PAN</mat-placeholder>
                            <input formControlName="panName" autocomplete="off" type="text" matInput maxlength="85">
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panName.errors?.errorMsg">{{editPanForm.controls.panName.errors?.errorMsg}}</mat-error>
                            <mat-error class="error-message" *ngIf="editPanForm.controls.panName.errors?.required">Name is required</mat-error>
                        </mat-form-field>
                    </div>                    
                </div>
                <div class="pan-div">
                    <img src="/assets/pan.png" class="pan-img">
                </div>                    
            </div>
            <div *ngIf="editPanErrMsg" [innerHTML]="editPanErrMsg" class="pan-update-inner-html red-text"></div>
            <div *ngIf="editPanSuccMsg" [innerHTML]="editPanSuccMsg" class="pan-update-inner-html"></div>
            <div *ngIf="panAadharLinkErrMsg" [innerHTML]="panAadharLinkErrMsg" class="pan-update-inner-html"></div>
            <div *ngIf="panAadharLinkSuccMsg" [innerHTML]="panAadharLinkSuccMsg" class="pan-update-inner-html">
            </div>
            <div *ngIf="panAadharLinkCheckMsg" [innerHTML]="panAadharLinkCheckMsg" class="pan-update-inner-html">
            </div>
            <div class="pan-noteContent" *ngIf="editPanErrMsg">
                <div><span class="pan-note-heading">NOTE: </span> <span class="pan-note">Click on continue to generate a PAN document verification against the SR.</span></div>
            </div>
            <div class="button-div flex">
                <button mat-button class="close-button update-pan-btn" type="button"
                    (click)="closeDialog()">Cancel</button>
                <button *ngIf="!isPanValid && !editPanErrMsg" mat-button class="save-button" type="submit" [disabled]="!editPanForm.valid">Verify PAN</button>
                <button *ngIf="editPanErrMsg" mat-button class="save-button" type="button"
                    (click)="PANredirect('editPAN')">Continue</button>
                <button *ngIf="isPanValid" mat-button class="save-button" type="button"
                    (click)="createUpdatePanSR()">Continue</button>
            </div>
        </form>
    </div>
</div>
</ng-template>
<ng-template #VerifyPayee>
    <div class="maturity-claim-details">
        <div mat-dialog-actions class="mat-dialog-actions" (click)="closeDialog()">
            <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                Close
                <img src="assets/close.svg">
            </div>
        </div>
        <div class="maturity-claim-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}" *ngIf="!neftVerify">Important</div>
        <div class="maturity-claim-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}" *ngIf="neftVerify">Verify Account Details</div>
        <div class="pan-card popup-content-height">
            <div class="maturity-claim-popup-content" *ngIf="!neftVerify">{{popupMsg}}</div>
            <div class="maturity-claim-popup-content" *ngIf="neftVerify">Now you are required to verify account number {{maskedAccNumber}}</div>
            <div class="maturity-claim-popup-content" *ngIf="neftVerify">Do you want to continue?</div>
            <div class="maturity-claim-popup-content" *ngIf = "popupMsgSecond && id !='surrender'"><p>Please note: Kindly upload the necessary documents(KYC, PAN, Payout/NEFT) after this step. Also, please do not forget to click on the <b>FINISH</b> button after uploading the documents to submit your request</p></div>
            <div class="maturity-claim-popup-content" *ngIf = "popupMsgSecond && id =='surrender'"><p>After completing this step, please upload the required documents (KYC, PAN, Payout/NEFT). Do not forget to submit your request by clicking <b>FINISH</b></p></div>
            <div class="maturity-claim-popup-content" *ngIf = "popupMsgPanNeft"><p>Please note: Kindly upload the necessary documents(PAN, Payout/NEFT) after this step. Also, please do not forget to click on the <b>FINISH</b> button after uploading the documents to submit your request</p></div>
            <div class="maturity-claim-popup-content" *ngIf = "popupMsgSecondSurr"><p>Please upload the required documents (PAN). Do not forget to submit your request by clicking <b>FINISH</b></p></div>
            <div class="maturity-claim-popup-content" *ngIf = "annuityMsgSecond"><p>Please note: Kindly upload the necessary documents(Payout/NEFT) after this step. Also, please do not forget to click on the <b>FINISH</b> button after uploading the documents to submit your request</p></div>
                <div class="maturity-claim-popup-content" *ngIf = "popupPanMsg">
                    <p>PAN is not verified for the Client ID {{clientId}}.</p>
                    <p>Please note: If PAN is not verified/provided then additional TDS will be applicable on the payout.</p>
                    <p>Do you want to verify the PAN before proceeding?</p></div>
            <div class="button-div flex" *ngIf="!neftVerify">
                <button *ngIf="!panPopup" matButton class="okay-button" (click)="verify()">Okay</button>
                <button *ngIf="panPopup" matButton class="okay-button" (click)="close()">Yes</button>
                <button *ngIf="panPopup" matButton class="okay-button" (click)="PANredirect('continueBtnClick')">No</button>
            </div>
            <div class="button-div flex" *ngIf="neftVerify">
                <button matButton class="cancel-btn" (click)="close()">No</button>
                <button matButton class="okay-button" (click)="showExistingNeft('NEFT')">Yes</button>
            </div>
        </div>
    </div>
</ng-template>
