import { Component, OnInit, HostListener,ViewChild,TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { loginService } from 'src/app/services/login/login.service';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { WeeklySubscriptionComponent } from 'src/app/modules/customer-service/weekly-subscription/weekly-subscription.component';
import { filter } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { SwitchPolicyGroupPopupComponent } from '../switch-policy-group-popup/switch-policy-group-popup.component';
import { HttpService } from 'src/app/services/http/http.service';
import { AddnomineeService } from 'src/app/modules/my-policies/addnominee-service/addnominee.service';
import { throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormGroup,FormControl, FormBuilder } from '@angular/forms';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { availableLanguages } from 'src/app/utils/app-constants';
import { SearchComponent } from '../search/search.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { searchBarMap } from 'src/app/utils/search-bar-map';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { serviceURL } from 'src/environments/environment.prod';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  width:number = window.innerWidth;
  isMobileDetect = false;
  isHNIUSer:boolean = false;
  isCPAdmin: boolean = false;
  userDetails: any;
  logoutDialogRef: MatDialogRef<LogoutDialogComponent> = null;

  isCustomLink: boolean = false;

  @HostListener('window:resize', ['$event'])
  @ViewChild('customerTrigger') customerTrigger: MatMenuTrigger;
  @ViewChild('logoutTrigger') logoutTrigger: MatMenuTrigger;
  @ViewChild('notificationTrigger') notificationTrigger: MatMenuTrigger;
  dedupeReq: any;
  dedupeResponse: any;
  dedupeResp: any;
  isDedupe: any;
  isMultiple: any;
  isSpecialLife: boolean;
  onResize(event) {
    this.width = event.target.innerWidth;
  }
  logoutModeResp: any;
  languageForm : FormGroup
  languages : any = availableLanguages;
  pasaNudge: any;
  @ViewChild('profileComplete') profileComplete: TemplateRef<any>;
  @ViewChild('whatsapp') whatsapp: TemplateRef<any>;

  fyiNotifications: Array<any> = [];
  needAttnNotificationsMap: any = {
    'PreMaturity': [],
    'PostMaturity': [],
    'MoneyBack': [],
    'Premium': [],
    'Revival': [],
    'SI': [],
    'Riders': [],
    'Loans': [],
  };
  openFRsNotifications: Array<any> = [];
  notificationsBadge = 0;
  policyDtls: any = [];
  profileCompletionDtls: any = {
    /* "permAddrExists": "N",
        "mobileExists": "Y",
        "neftExists": "Y",
        "commAddrExists": "Y",
        "kycExists": "Y",
        "emailExists": "N" */
  };
  profileCompletenessLinks = [
    {
      'tag': 'mobileExists',
      'searchId': '13',
      'label': 'Update Mobile No.',
    },
    {
      'tag': 'emailExists',
      'searchId': '13',
      'label': 'Update Email ID',
    },
    {
      'tag': 'neftExists',
      'searchId': '15',
      'label': 'Update Bank Details',
    },
    {
      'tag': 'nomineeExists',
      'searchId': '19',
      'label': 'Update Nominee Details',
    },
    {
      'tag': 'kycExists',
      'searchId': '16',
      'label': 'Update KYC',
    },
    {
      'tag': 'commAddrExists',
      'searchId': '114',
      'label': 'Update Communication Address',
    },
    {
      'tag': 'permAddrExists',
      'searchId': '115',
      'label': 'Update Permanent Address',
    },
    {
      'tag': 'whatsappExists',
      'searchId': '6',
      'label': 'WhatsApp Opt-In',
    },
  ];
  profileCompletionPercent = 100;
  topTwoProfileCompletenessLinks = [];
  isNeedAttnMapEmpty: boolean;
  needAttentionCopy: Array<any> = [];
  needAttentionArray: Array<any> = [];
  subActivity: any;
  objIdx: Array<any> = [];
  pasaSumAssured: any;
  pasaUrl: any;
  pasaRead: boolean;
  fyiCount: number = 0;
  npsNudge: any;
  npsSubs: any;
  npsRead: boolean;
  npsUrl = serviceURL.nps_link;
  isViewMore: boolean = false;

  constructor(public router: Router, public loginService: loginService,public addnomineeService: AddnomineeService, public utils: Utils,public _httpService: HttpService, public dialog: MatDialog, public dataService: DataService, public auth: AuthService, private overlayContainer:OverlayContainer, private formBuilder : FormBuilder, private myaccountService : MyAccountService, public iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer, public myPoliciesService: MyPoliciesService, public createSrService: CreateSRService, private crypto: EncryptDecryptService) {
    this.iconRegistry.addSvgIcon('bell-icon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/notification.svg'));
    this.dataService.isHNIUser.subscribe(isHni => {
      this.isHNIUSer = isHni;
    });
    this.dataService.isCPAdmin.subscribe(isCPA => {
      this.isCPAdmin = isCPA;
    });
    this.dataService.loginResp$.subscribe(isChanged => {
      if(isChanged) {
        this.userDetails = this.dataService.getLoginResp();
        //if(this.userDetails.DATEOFBIRTH == "01/01/9999" && this.userDetails.LOGINMODE == "SP")
        if(this.userDetails.DATEOFBIRTH == "01/01/9999") {
          this.isSpecialLife = true
        }
        else {
          this.isSpecialLife = false
        }
        setTimeout(() => {
          if (this.isLoggedIn() && !this.dataService.getIsCustomLink()) {
            this.getNotifications();
          }          
        }, 500);
      }
      this.isCustomLink = this.dataService.getIsCustomLink();
    })
    this.npsSubs = this.dataService.npsResp$.subscribe(val => {
      this.npsNudge = val;
      let read = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
      if (read['NPS'] == true) {
        this.npsRead = true;
      }
      else {
        this.npsRead = false;
      }
      this.readStorage();
      this.readRider();
      this.updateNotificationsBadge();
    })
    this.dataService.policyRiderLoanSubj.subscribe(data => {
      console.log("policy details from header", data)
      if (data) {
        this.policyDtls = JSON.parse(JSON.stringify(data));
        if (!this.dataService.getIsCustomLink()) {
          setTimeout(() => {
            this.createNeedAttnNotificationsMap(this.policyDtls);
          }, 500);
        this.getProfileCompletionDtls();
        }        
      }      
    })
    this.dataService.dedupeResp$.subscribe(isDedupe => {
      //this.getDedupeData();
      this.isDedupe = isDedupe;
      if(isDedupe) {
        this.isMultiple = true
      }
      else {
        this.isMultiple = false
      }
    })
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (event.url.includes('/my-account')) {
          this.highlightMyAccount();
        } else if (event.url.includes('/my-policies')) {
          this.highlightMyPolicies();
        } else if (event.url.includes('/my-downloads')) {
          this.highlightMyDownloads();
        } else if (event.url.includes('/customer-service/service-requests')) {
          this.highlightServiceReq();
        } else if (event.url.includes('/customer-service')) {
          this.highlightCustomerService();
        }
    });
    this.dataService.searchSubj.subscribe(data => {
      if (data && data.func_rk == '33') {
        setTimeout (() => this.redirectToWeeklySubscription(), 500);
      }
    });
    this.dataService.pasaObs$.subscribe(resp => {
      let encryptKey = this.dataService.getEncryptionKey();
      let encryptIv = this.dataService.getEncryptionIv();
      this.pasaSumAssured = this.crypto.decryptString(resp.sumassured, encryptKey, encryptIv);
      this.pasaUrl = this.crypto.decryptString(resp.url, encryptKey, encryptIv);
      if (resp.PasaNudge == 'Y' && (parseInt(this.pasaSumAssured) > 0)) {
        this.pasaNudge = 'Y';
      }
      else {
        this.pasaNudge = 'N';
      }
      let read = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
      // if(read['pasa'] == true) {
      //   this.pasaRead = true;
      //   this.pasaNudge = 'N';
      // }
      // else if(!read['pasa'] && resp.pasaNudge == 'Y') {
      //   this.pasaRead = false;
      //   this.pasaNudge = 'Y';
      // }
      if (read['pasa'] == true) {
        this.pasaRead = true;
      }
      else if (this.pasaNudge == 'Y') {
        this.pasaRead = false;
      }
      this.readStorage();
      this.readRider();
      this.updateNotificationsBadge();
    })
    this.dataService.pasaClickedObs$.subscribe(flag => {
      this.pasaNudge = flag;
      let read = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
      if (read['pasa'] == true) {
        this.pasaRead = true;
      }
      else {
        this.pasaRead = false;
      }
      this.readStorage();
      this.readRider();
      this.updateNotificationsBadge();
    })
  }
   

  ngOnInit(): void {
    // this.isCPAdmin = this.dataService.checkCPadmin();
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.dedupeReq = this.addnomineeService.getLoginDetail();
    this.languageForm = this.formBuilder.group({
      selectedLanguage : new FormControl(""),
    })
    //to show default value as english in terms and conditions component
    this.myaccountService.setLanguage("english");
    this.languageForm.controls.selectedLanguage.setValue('english');
  }
redirectMyPolicyClicked = true;
redirectMyAccountClicked = false;
redirectMyDownloadsClicked = false;
srRedirect = false;
csRedirect = false;

  redirectToMyPolicies() {
    this.highlightMyPolicies();
    this.utils.trackUserActions({ "eventName" : "My Policies" });
    this.router.navigate(['/my-policies']);
  }
  redirectToServiceForms() {
    window.open ("https://www.hdfclife.com/customer-service/forms-and-download");
  }
  redirectToMyAccount() {
    this.highlightMyAccount();
    this.utils.trackUserActions({ "eventName" : "My Account" });
    this.router.navigate(['/my-account']);
  }

  redirectToMyDownloads() {
    this.highlightMyDownloads();
    this.utils.trackUserActions({ "eventName" : "My Downloads" });
    this.router.navigate(['/my-downloads']);  
  }
  redirectToSR() {
    this.highlightServiceReq();
    this.utils.trackUserActions({ "eventName" : "My Service Request" });
    this.router.navigate(['/customer-service', 'service-requests']);    
  }
  redirectToFAQ() {
    this.highlightCustomerService();
    this.router.navigate(['/customer-service', 'faq'])    
  }
  redirectToContactUs() {
    this.highlightCustomerService();
    this.router.navigate(['/customer-service', 'contact-us']);  
  }
  redirectPasswordOptions() {
    if (this.isCPAdmin == false) { this.router.navigate(['/login', 'change-password']); }
  }
  setSecretQuestions() {
    if (this.isCPAdmin == false) { this.router.navigate(['/login', 'secret-questions']);} 
  }
  // setUpSocialMediaID() {
  //   if (this.isCPAdmin == false) { this.router.navigate(['/login', 'social-logins']); }
  // }
  redirectTowellnessVoucher() {
    this.router.navigate(['/customer-service','wellness-voucher']);
  }
  redirectToWeeklySubscription() {
    if (this.isCPAdmin == false) { this.dialog.open(WeeklySubscriptionComponent, { width: '500px',panelClass: 'weekly-subscription', backdropClass: 'weekly-subscription-backdrop', disableClose: true }) }
  }
  logout() {
    this.userDetails = this.dataService.getLoginResp();
    if(this.userDetails && this.userDetails.PROCEED_WITH_VOC === "Y" && !this.isCPAdmin) {
      let policyCardResp = this.dataService.getPolicyCardResp();
      let clientid = this.utils.getClientId();
      if (!this.dataService.isCsApp) {
        this.auth.logout('M');
      }
      this.logoutDialogRef = this.dialog.open(LogoutDialogComponent, { panelClass: 'logout-feedback', backdropClass: 'logout-feedback-backdrop', disableClose: true,
        data: {
          userDtls: this.userDetails,
          policyCardResp: policyCardResp,
          clientid: clientid
        } 
      },
      );
      this.logoutDialogRef.afterClosed().subscribe(() => this.auth.logout('M'));
    } else {
      this.auth.logout('M');
    }
  }
  isLoggedIn(): boolean {
    if (this.utils.getExpiredFlag() != "Y") {
      return this.utils.getLocalData("loggedIn", false) === 'true' && (!this.userDetails || (this.userDetails && this.userDetails.SOURCE && !(["CUSTOMLINK", "INSTASERV", "VIRTUAL_BRANCH"].includes(this.userDetails.SOURCE))));
    }
  }
  @HostListener('window:storage')
  onStorageChange() {
    if (this.utils.getLocalData("loggedIn", false) === 'true' && this.userDetails && this.userDetails.SOURCE && this.userDetails.SOURCE == "CUSTOMLINK") {
      if (this.router.url.includes('/surrender-aversion')) {
        this.router.navigate(['/surrender-aversion']);
        //this.userDetails = null;
      } else if (this.router.url.includes('/investigator')) {
        this.router.navigate(['/investigator']);
        //this.userDetails = null;
      } else {
        this.router.navigate(['/my-link'], {queryParams: {action: this.userDetails.action}, queryParamsHandling: "preserve"} );
        //this.userDetails = null;
      }
    }
    if (!this.utils.getLocalData("loggedIn", false) && this.userDetails && this.userDetails.SOURCE && this.userDetails.SOURCE == "CUSTOMERPORTAL") {
      this.router.navigate(['/login']);
      //this.userDetails = null;
    }
  }
  highlightMyPolicies() {
    this.redirectMyPolicyClicked = true;
    this.redirectMyAccountClicked = false;
    this.redirectMyDownloadsClicked = false;
    this.srRedirect = false;
    this.csRedirect = false;
  }
  highlightMyAccount() {
    this.redirectMyAccountClicked = true;
    this.redirectMyPolicyClicked = false;
    this.redirectMyDownloadsClicked = false;
    this.srRedirect = false;
    this.csRedirect = false;
  }
  highlightMyDownloads() {
    this.redirectMyDownloadsClicked = true;
    this.redirectMyPolicyClicked = false;
    this.redirectMyAccountClicked = false;
    this.srRedirect = false;
    this.csRedirect = false;
  }
  highlightServiceReq() {
    this.redirectMyPolicyClicked = false;
    this.redirectMyAccountClicked = false;
    this.redirectMyDownloadsClicked = false;
    this.srRedirect = true;
    this.csRedirect = false;
  }
  highlightCustomerService() {
    this.redirectMyPolicyClicked = false;
    this.redirectMyAccountClicked = false;
    this.redirectMyDownloadsClicked = false;
    this.srRedirect = false;
    this.csRedirect = true;
  }
  switchPolicyGroup() {
    this.utils.trackUserActions({ "eventName": "Switch client ID" });
    this.dialog.open(SwitchPolicyGroupPopupComponent, { width: '400px', panelClass: 'switch-policy-grp', backdropClass: 'switch-policy-grp-backdrop', disableClose: true })
    //data: { landingScreen : this.landingScreenDetail.landingScreen } 
  }
  customerMenuOpen() {
    this.logoutTrigger.closeMenu();
    this.notificationTrigger.closeMenu();
  }
  logoutMenuOpen() {
    this.customerTrigger.closeMenu();
    this.notificationTrigger.closeMenu();
  }
  notificationMenuOpen() {
    this.logoutTrigger.closeMenu();
    this.customerTrigger.closeMenu();
  }
  getDedupeData() {
    let dedupeReq = {
      "cp_login_mode":this.dedupeReq.cp_login_mode?this.dedupeReq.cp_login_mode:this.userDetails.LOGINMODE,
      "cp_loginid":this.dedupeReq.cp_loginid?this.dedupeReq.cp_loginid:this.userDetails.LOGINID,
      "dateofbirth":this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
      "fields": "policy,profile"
    }
   /* if(dedupeReq.cp_login_mode == "CO") {
      this.loginId = this.userDetails.CLIENT_ID;
    }
    else if(dedupeReq.cp_login_mode == "MO") {
      this.loginId = this.userDetails.MOBILE_NUM
    }
    else if(dedupeReq.cp_login_mode  == "EO") {
      this.loginId = this.userDetails.EMAIL_ID
    }
    else {
      this.loginId = this.policyId
    }  */
    return this.loginService.getDedupeResp(dedupeReq)
    .subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        this.dedupeResponse = response.body.dedUpeDetails;
        this.dataService.setDedupeResp(this.dedupeResponse);
        for(let item of this.dedupeResponse) {
          this.dedupeResp = item;
        }
    }}),
    catchError((error) => throwError(error))
  }
  customLinkLogout() {
    this.auth.logout('M');
    this.isCustomLink = false;    
  }
  setlogoutMode() {
    let reqBody = {
      logoutMode: 'M',
    }
    return this.loginService.setLogoutMode(reqBody)
    .pipe(catchError(this.handleError.bind(this)))
    .subscribe((response) => {
      if ((this._httpService.isSuccessResponse(response))) {
        this.logoutModeResp = response.body.Status;
        if (this.logoutModeResp.toUpperCase() == "SUCCESS") {
          this.logout();
        }
      }
      else {
        //this.auth.logout('M');
        this.logout();
      }
    }),
    catchError((error) => throwError(error))
  }
  handleError() {
    this.auth.logout('M');
  }
  investigatorLogout(){
    this.userDetails = this.dataService.getLoginResp();
    localStorage.clear();
    this.router.navigate(['/investigator']);
    this.dataService.setLoginResp(null);
  }
  changeLanguage(event : any){
    this.myaccountService.setLanguage(this.languageForm.value.selectedLanguage);
  }
  openSearch() {
    this.dialog.open(SearchComponent, {
      panelClass: 'search-popup-panel',
      backdropClass: 'search-popup-backdrop',
      disableClose: false,
      autoFocus: false,
    });
  }
  onProfileLinkClick(searchId: string) {
    if (searchId == '6' && this.profileCompletionDtls['mobileExists'] == 'N') {
      this.openDialog(this.whatsapp);
      return;
    }
    let action = { 'func_rk': searchId, ...searchBarMap[searchId] };
    if (searchId == '19' && this.profileCompletionDtls.policiesWithoutNominee) {
      let policy = this.profileCompletionDtls.policiesWithoutNominee[0].POLICYNO;
      let idx = this.policyDtls.findIndex(item => item.POLICYNO.replace(/\s/, '') == policy.replace(/\s/, ''))
      this.dataService.setCarouselPosition(idx);
      action["policyCarouselPos"] = idx;
      action["selectedPolicyType"] = this.policyDtls[idx]['PRODUCT_TYPE'];
    }        
    if (action.page === 'MY_DOWNLOADS' || action.page === 'POLICY_DETAILS') {
      this.router.navigate(['/my-search'], { queryParams: { id: action.func_rk } });
    } else {
      this.router.navigate(action.path);
    }
    this.dataService.searchSubj.next(action);
    this.dialog.closeAll();
  }
  openDialog(template: TemplateRef<any>) {
    this.dialog.open(template, {
      panelClass: 'popup-dialog-container',
      backdropClass: 'popup-backdrop',
      disableClose: false,
      autoFocus: false,
    });
  }

  getNotifications() {
    let reqBody = {
      "clientID": this.utils.getClientId(),
    }
    return this.myPoliciesService.getNotifications(reqBody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.fyi && resp.body.fyi.length > 0) {
          this.fyiNotifications = resp.body.fyi;
          this.updateNotificationsBadge();
        }
        if (resp.body && resp.body.openFRs && resp.body.openFRs.length > 0) {
          this.openFRsNotifications = resp.body.openFRs;
          this.openFRsNotifications = this.openFRsNotifications.filter(m => m.req_descriptions && m.req_descriptions.length > 0)
        }
      }
    })
  }
  // onFYIClick(item) {
  //   if (item.READ_STATUS != 'Y') {
  //     this.updateNotificationStatus(item);
  //   }
  // }
  updateNotificationStatus() {
    if(!this.isCPAdmin){
    let reqBody = {
      //"notification_rk": item.rk ? item.rk.toString() : '',
      "clientID": this.utils.getClientId(),
    }
    return this.myPoliciesService.updateNotificationStatus(reqBody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.fyiNotifications.forEach(item => {
          if (this.fyiCount >= 1) {
            item.READ_STATUS = 'Y';
          }
          // else {
          //   item.READ_STATUS = 'N';
          // }
        });
        this.updateNotificationsBadge();
      }
    })
    }
  }
  updateNotificationsBadge() {
    this.notificationsBadge = this.fyiNotifications.filter(i => i.READ_STATUS != 'Y').length;
    let unread = 0;
    for (let key of Object.keys(this.needAttnNotificationsMap)) {
      if (key == 'Riders') {
        this.needAttnNotificationsMap['Riders'].forEach(element => {
          unread += element['adcatRiderDtls']['riderData']['riderArr'].filter(i => !i.itemClicked).length;
        });
      }
      else {
        if (this.needAttnNotificationsMap[key].length > 0) {
          unread += this.needAttnNotificationsMap[key].filter(i => !i.itemClicked).length;
        }
      }
    }
    this.notificationsBadge += unread;
    if (this.npsNudge == 'Y' && this.npsRead == false) {
      this.notificationsBadge += 1;
    }
    if (this.pasaNudge == 'Y' && this.pasaRead == false) {
      this.notificationsBadge += 1;
    }
  }
  createNeedAttnNotificationsMap(policyDtls) {
    for (let key of Object.keys(this.needAttnNotificationsMap)) {
      this.needAttnNotificationsMap[key] = policyDtls.filter(p => p['applicableNudges'] && p['applicableNudges'].length > 0 && p['applicableNudges'].includes(key))
    }
    this.needAttnNotificationsMap['Riders'] = policyDtls.filter(p => p['riderAvailable']);
    this.needAttnNotificationsMap['Loans'] = policyDtls.filter(p => p['loanAvailable']);
    this.needAttnNotificationsMap['openFr'] = this.openFRsNotifications;
    this.needAttnNotificationsMap = JSON.parse(JSON.stringify(this.needAttnNotificationsMap))
    let isNeedAttnMapEmpty = true;
    let id = 0;
    for (let key of Object.keys(this.needAttnNotificationsMap)) {
      if (isNeedAttnMapEmpty && this.needAttnNotificationsMap[key].length > 0) {
        isNeedAttnMapEmpty = false;
      }
    }
    this.isNeedAttnMapEmpty = isNeedAttnMapEmpty;
    this.readStorage();
    this.readRider();
    this.updateNotificationsBadge();
  }
  onNeedAttnClick(policyItem, subactivity, idx, activity) {
    this.needAttnNotificationsMap[activity][idx]['itemClicked'] = true;
    this.store(activity, idx);
    this.updateNotificationsBadge();
    if (["MAT_CLAIM", "MONY_BK_CLAIM", "REVIVAL"].includes(subactivity)) {
      let policyIndex = this.policyDtls.findIndex((item) => item.POLICYNO.replace(/\s+/g, '') == policyItem.POLICYNO.replace(/\s+/g, ''));
      if (policyIndex >= 0) {
        this.dataService.setCarouselPosition(policyIndex);
        this.router.navigate(["/my-policies", "policy-details"], { queryParams: { redirect: subactivity } });
      }
    } else if (subactivity == "Premium") {
      this.createSrService.redirectToRenewalPage(policyItem); 
    } else if (subactivity == "SI") {
      this.myPoliciesService.redirectToActivateSIPage(policyItem);
    } else if (subactivity == "SR") {
      this.createSrService.setSRTypeResp([ {srNum: policyItem.SR_NUM, srType: policyItem.SUBACTIVITY , src: 'notification'} ]);
      this.router.navigate(['/customer-service', 'service-request-details']);
    }
    if (this.notificationTrigger) { this.notificationTrigger.closeMenu(); }
  }
  onRiderClick(item, name , index, policyno, index1) { 
    this.storeRiders(policyno, index1);
    this.needAttnNotificationsMap['Riders'][index]['adcatRiderDtls']['riderData']['riderArr'][index1]['itemClicked'] = true;
    this.updateNotificationsBadge();   
    this.myPoliciesService.openRiderLink(item.adcatRiderDtls.riderData.riderJourneyLink, item.POLICYNO, this.utils.getClientId(), name)
  }

  getProfileCompletionDtls() {
    let reqBody = {
      "policy": this.policyDtls && this.policyDtls[0] && this.policyDtls[0].POLICYNO ? this.policyDtls[0].POLICYNO.replace(/\s+/g, '') : '',
      "clientid": this.utils.getClientId()
    }
    return this.myaccountService.getProfileCompletenessDtls(reqBody).subscribe((resp: any) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.profileCompletionDtls = resp.body;
        this.profileCompletionDtls['nomineeExists'] = "";
        this.profileCompletionDtls['policiesWithoutNominee'] = [];
        for (let item of this.policyDtls) {
          if (item['nomineeExists'] == 'N') {
            this.profileCompletionDtls['nomineeExists'] = "N";
            this.profileCompletionDtls['policiesWithoutNominee'].push(
              {
                'POLICYNO': item.POLICYNO,
                'PRDNAME': item.PRDNAME,
                'PRODUCTPLAN': item.PRODUCTPLAN,
              }
            )
          }
        }
        let vmlength = this.profileCompletenessLinks.filter(e => this.profileCompletionDtls[e['tag']] == 'N');
        if(vmlength.length > 2){
           this.isViewMore = true;
        }
        this.topTwoProfileCompletenessLinks = this.profileCompletenessLinks.filter(e => this.profileCompletionDtls[e['tag']] == 'N').slice(0, 2);
        this.dataService.profileCompletionDtls = this.profileCompletionDtls;
        let totalItems = 7 + this.policyDtls.length;
        let completedItems = 0;
        let tags = this.profileCompletenessLinks.map(e => e.tag).filter(e => e != 'nomineeExists');
        for (let tag of tags) {
          completedItems = this.profileCompletionDtls[tag] != 'N' ? completedItems + 1 : completedItems
        }
        completedItems = completedItems + (this.policyDtls.length - this.profileCompletionDtls['policiesWithoutNominee'].length);
        /* if (this.profileCompletionDtls['nomineeExists'] != 'N') {
          completedItems = completedItems + this.profileCompletionDtls['policiesWithoutNominee'].length;
        } else {
          completedItems += 1;
        } */       
        this.profileCompletionPercent = (completedItems / totalItems) * 100;
        console.log("profile completeness", resp)        
      }
    })
  }
  redirectToPasa() {
    this.utils.trackUserActions({ "eventName": "Click on Click here to get insured now" });
    let reqBody = {
      clientId: this.utils.getClientId(),
    }
    return this.myPoliciesService.updatePasaClk(reqBody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        let notiArray = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
        notiArray['pasa'] = true;
        this.utils.setLocalData('notifications', JSON.stringify(notiArray),false);
        this.dataService.setPasaClicked('N');
        this.pasaRead = true;
        this.updateNotificationsBadge();
        this.pasaNudge = 'N';
        window.open(this.pasaUrl, '_blank', 'noopener noreferrer');
      } else {
        this._httpService.apiErrorHandler(response);
      }
    });
  }

  store(activity, idx) {
    let notiArray = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
    if (notiArray[activity] && notiArray[activity].length > 0) {
      notiArray[activity].push(idx)
    } else {
      notiArray[activity] = [idx]
    }
    this.utils.setLocalData('notifications', JSON.stringify(notiArray),false);
  }
  storeRiders(policyNo, index) {
    let riderArray = JSON.parse(localStorage.getItem('riderNotification')) ? JSON.parse(localStorage.getItem('riderNotification')) : {};
    if (riderArray[policyNo] && riderArray[policyNo].length > 0) {
      riderArray[policyNo].push(index)
    } else {
      riderArray[policyNo] = [index]
    }
    this.utils.setLocalData('riderNotification', JSON.stringify(riderArray), false);
  }
  readRider() {
    let rider = JSON.parse(localStorage.getItem('riderNotification')) ? JSON.parse(localStorage.getItem('riderNotification')) : {};
    for (let key of Object.keys(this.needAttnNotificationsMap['Riders'])) {
      for (let k of Object.keys(rider)) {
        let policyNumber = this.needAttnNotificationsMap['Riders'].findIndex(pol => pol.POLICYNO.replace(/\s/, '') == k.replace(/\s/, ''))
        if (k) {
          for (let idx of rider[k]) {
            this.needAttnNotificationsMap['Riders'][policyNumber]['adcatRiderDtls']['riderData']['riderArr'][idx]['itemClicked'] = true;
          }
        }
      }
    }
  }
  readStorage() {
    let read = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
    if (read['NPS'] == true) {
      this.npsRead = true;
    }
    if (read['pasa'] == true) {
      this.pasaRead = true;
    }
    for (let k of Object.keys(read)) {
      if (k && k != 'NPS' && k != 'pasa') {
        for (let idx of read[k]) {
          this.needAttnNotificationsMap[k][idx]['itemClicked'] = true;
        }
      }
    }
  }
  redirectToAvailLoan(templateRefLoan: TemplateRef<any>, item, idx) {
    this.needAttnNotificationsMap['Loans'][idx]['itemClicked'] = true;
    this.store('Loans', idx);
    this.updateNotificationsBadge();
    this.myPoliciesService.updateLoanEligiblePolicy(item.POLICYNO.replace(/\s+/g, ''), item.CLIENTID, "LoanEligibleNudge");
    // this.utils.trackUserActions({ 'eventName': 'Nudges on policy card - Loan nudge', 'policyNo': item.POLICYNO });
    this.dialog.open(templateRefLoan, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true })
  }

  availLoanLink() {
    window.open(serviceURL.availLoanLink, '_blank', 'noopener noreferrer');
  }
  closePopup() {
    this.dialog.closeAll();
  }
  tabChange(e: any) {
    console.log(e);
    if (e.tab.textLabel == 'fyi') {
      this.fyiCount += 1;
    }
    if (e.tab.textLabel == 'needattention') {
      this.updateNotificationStatus();
    }
    this.updateNotificationsBadge();
  }
  notificationMenuClose() {
    this.updateNotificationStatus();
  }
  redirectToNps() {
    if (!this.isCPAdmin) {
      let reqBody = {
        clientid: this.utils.getClientId(),
      }
      this.utils.trackUserActions({ "eventName": "Needs attention - click on NPS nudge click here", "flowType": 'NPS' });
      return this.myPoliciesService.updateNps(reqBody).subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          let notiArray = JSON.parse(localStorage.getItem('notifications')) ? JSON.parse(localStorage.getItem('notifications')) : {};
          notiArray['NPS'] = true;
          this.utils.setLocalData('notifications', JSON.stringify(notiArray), false);
          this.npsRead = true;
          this.updateNotificationsBadge();
          if (response.body && (response.body.msg).toLowerCase() == 'success') {
            window.open(serviceURL.nps_link, "noopener noreferrer");
          }
        } else {
          this._httpService.apiErrorHandler(response);
        }
      });
    }
  }
  ngOnDestroy() {
    this.npsSubs.unsubscribe();
  }
}
