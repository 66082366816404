<div class="forgot-id-popup">
    <div mat-dialog-action class="popup-close">
        <div mat-dialog-close class="popup-close-dialog no-mobile">Close<img src='/assets/close.svg'></div>
    </div>
    <div class="flex-center popup-header">
        <span>To know your Client ID</span>
        <div mat-dialog-close class="popup-close-dialog mobile"></div>
    </div>
    <div class="popup-content">
        <div class="instructions">
            <div class="method-one">
                You can connect on our Digital Platform WhatsApp<span class="bold-text"> "Etty"</span> or save
                the contact and message us on <span class="bold-text">8291890569</span> to know your clientID
            </div>
            <div class="method-two">
                You can <a class="click-here" href='https://chat.apps-hdfclife.com/'>click here</a> and chat with our Virtual Assistant <span
                    class="bold-text">Elle</span> to
                know your clientID
            </div>
        </div>
    </div>
</div>