import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './modules/login/login.module';
import { SharedModule } from './modules/shared/shared.module';
import { MatNativeDateModule, MAT_DATE_LOCALE,MAT_DATE_FORMATS,DateAdapter } from '@angular/material/core';
import { LOCALE_ID } from '@angular/common/locales/global/en-IN';
import { LoaderInterceptorService } from './services/loader-interceptor/loader-interceptor.service';
import { SurrenderAversionModule } from './modules/surrender-aversion/surrender-aversion.module';
import { CustomLinkModule } from './modules/custom-link/custom-link.module';
import { MatMomentDateModule,MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CustomLinkProxyComponent } from './modules/custom-link/custom-link-proxy/custom-link-proxy.component';
//import { BnNgIdleService } from 'bn-ng-idle'; 
import { InvestigatorModule } from './modules/investigator/investigator.module';
import { SearchProxyComponent } from './modules/shared/components/search-proxy/search-proxy.component';

export const MY_FORMATS = {
  parse:{
    dateInput:'DD/MM/YYYY',
  },
  display:{
    dateInput:'DD/MM/YYYY',
    monthYearLabel:'MMM YYYY',
  }
};
@NgModule({
  declarations: [
    AppComponent,
    CustomLinkProxyComponent,
    SearchProxyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    LoginModule,
    SharedModule,
    HttpClientModule,
    SurrenderAversionModule,
    CustomLinkModule,
    MatMomentDateModule, 
    InvestigatorModule   
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: LOCALE_ID, useValue: 'en-IN'},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
    {provide: DateAdapter,useClass: MomentDateAdapter,deps:[MAT_DATE_LOCALE,MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS,useValue: MY_FORMATS},
    //BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
