import { TOUCHPOINT, TRANSID_PREFIX, VERSION } from './app-constants';
import { RequestSchema } from './app-data.model';
import * as moment from 'moment';
import { EncryptDecryptService } from '../services/encrypt-decrypt/encrypt-decrypt.service';
import { enc_dec_key, serviceURL } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { DataService } from '../services/data/data.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../modules/my-downloads/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isAndroid, sendToReactNative } from './mobile-app-interface';
import { sendTrackingData } from './external-interface';

@Injectable({
    providedIn: 'root'
})
export class Utils {
    constructor(private crypto: EncryptDecryptService, public dialog: MatDialog, public dataService: DataService, private overlayContainer:OverlayContainer, public compressService: NgxImageCompressService) { }
    /**
     *
     * log function is a wrapper around built-in console.log function
     * Checks the environment variables and conditionally logs to console.
     *
     * @exports
     * @param message
     * @param optionalParams
     */

    log(message?: any, ...optionalParams: any[]) {
        const env = window.location.hostname.split('.')[0];
        //if (environment[env].debugMode) {
        // if (environment[env]?.debugMode) {
        // tslint:disable-next-line: no-console
        console.log(message, ...optionalParams);
        // }
    }

    createRequest(
        userId: string,
        apiname: string,
        body: any,
        source: string = TOUCHPOINT
    ): RequestSchema {
        let request: RequestSchema = {
            head: {
                apiname,
                source: source,
                txnid: this.generateTransId(userId),
                version: VERSION
            },
            body,
        };
        return request;
    }

    generateTransId(userId: string) {
        return `${TRANSID_PREFIX}${userId ? userId.replace(/[@._-]/g,'') : userId}${new Date().valueOf()}`;
    }

    /**
     * @exports
     * @param file
     */
    getBase64(file, compress = false): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            let base64String: string;
            const filetype = file.type ? file.type.split('/')[1].toLowerCase() : '';
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                if (fileReader.result instanceof ArrayBuffer) {
                    base64String = String.fromCharCode.apply(
                        null,
                        new Uint16Array(fileReader.result)
                    );
                } else {
                    base64String = fileReader.result;
                }

                if (['jpeg', 'jpg', 'png', 'bmp'].includes(filetype) && compress) {
                    let size = this.compressService.byteCount(base64String);
                    let ratio = size > 2_000_000 ? 50 : 100
                    let quality = size > 2_000_000 ? 50 : 90
                    this.compressService.compressFile(base64String, 1, ratio, quality).then((img) => {
                        resolve(img.split(',')[1]);
                    })
                } else {
                    //this.log(base64String);
                    resolve(base64String.split(',')[1]);
                }

            };
            fileReader.onerror = (error) => reject(error);
        });
    }
    formatDtls(res: Array<any>, policyNoKey: string, dateKey: string, dateFormat: string) {
        for (let obj of res) {
            let midIndex = obj[policyNoKey].length / 2;
            if (obj[policyNoKey] && obj[policyNoKey].indexOf(" ") < 0) {
                obj[policyNoKey] = [obj[policyNoKey].slice(0, midIndex), " ", obj[policyNoKey].slice(midIndex)].join('');
            }
            if (obj[dateKey] && obj[dateKey]!="NA") {
                obj[dateKey] = moment(obj[dateKey], ["DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.SSSZZ"], true).format(dateFormat);
            }
        }
        return res;
    }
    setLocalData(key: string, val: any, isEncrypt: boolean) {
        if (isEncrypt) {
            val = this.crypto.encryptJson(val, enc_dec_key.key, enc_dec_key.iv);
        }
        localStorage.setItem(key, val);
    }
    getLocalData(key: string, isDecrypt: boolean) {
        let val = localStorage.getItem(key);
        if (isDecrypt && val) {
            return this.crypto.decryptString(val, enc_dec_key.key, enc_dec_key.iv);
        }
        return val;
    }
    setSessionData(key: string, val: string, isEncrypt: boolean) {
        if (isEncrypt) {
            val = this.crypto.encryptJson(val, enc_dec_key.key, enc_dec_key.iv);
        }
        sessionStorage.setItem(key, val);
    }
    getSessionData(key: string, isDecrypt: boolean) {
        let val = sessionStorage.getItem(key);
        if (isDecrypt && val) {
            return this.crypto.decryptString(val, enc_dec_key.key, enc_dec_key.iv);
        }
        return val;
    }
    getClientId() {
        const id = this.getLocalData("id", false);
        if (id) {
            return id;
        }
    }
    getExpiryDtls() {
        const userDetails = this.dataService.getLoginResp();
        if (userDetails) {
            var dateTime = userDetails.PASSWORD_EXPIRY_DT.split(" ");
            return dateTime[0];
        }
    }
    getRemainingDays() {
        const userDetails = this.dataService.getLoginResp();
        if (userDetails && userDetails.PASSWORD_EXPIRY_DT) {
            var today = new Date();
            var dateTime = userDetails.PASSWORD_EXPIRY_DT.split(" ");
            var date = dateTime[0].split("/")
            var newdate = date[2] + '/' +date[1] +'/' + date[0];
            var newDateTime = newdate + " " +dateTime[1]
            var expiry = new Date(newDateTime);
            var Diff = expiry.getTime() - today.getTime() 
            var DiffDays = Math.floor ( Diff / (3600*24000) )
            return DiffDays;
        }
    }
    getExpiredFlag() {
        const userDetails = this.dataService.getLoginResp();
        if (userDetails) {
            return userDetails.PWD_EXPIRED ;
        }
    }
    pdfView( dtls: any) {
        this.dialog.open(PdfViewerDialogComponent, {
          panelClass: 'pdf-view-dialog', disableClose: true,
          data: {
           // dtls: this.expInfoPopupData,
            confirmPopup: "pfdPopup",
            displayDtls: dtls
          }
        });
      }
    base64ToPDF(base64String: string, fileName: string, action: string) {      
        const byteArray = new Uint8Array(
            atob(base64String)
                .split('')
                .map(char => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "application/pdf" });

        let fileURL = ''
        if (this.dataService.isCsApp) {
            if (this.dataService.docType && this.dataService.docType === "base64") {
                fileURL = `data:application/pdf;base64,${base64String}`
            } else if (this.dataService.docType && this.dataService.docType !== "base64") {
                fileURL = URL.createObjectURL(file)
            } else if (isAndroid()) {
                fileURL = `data:application/pdf;base64,${base64String}`
            } else {
                fileURL = URL.createObjectURL(file)
            }
        } else {
            fileURL = URL.createObjectURL(file)
        }

        if (action.toLowerCase() === 'view') {
            if (this.dataService.isCsApp) {
                try { 
                    sendToReactNative({"event": "download", "base64uri": `data:application/pdf;base64,${base64String}`}) 
                } catch (e) {}
            }
            let popup = window.open();
            if (!popup || popup == null || typeof popup.closed == 'undefined') {
                this.pdfView(fileURL);
            } else {
                popup.opener = null;
                popup.location.href = fileURL;
                popup.focus();
            }
        } else if (action.toLowerCase() === 'download') {
            if (this.dataService.isCsApp) {
                try { 
                    sendToReactNative({"event": "download", "base64uri": `data:application/pdf;base64,${base64String}`}) 
                } catch (e) {}
            }
            let downloadLink = document.createElement("a");
            downloadLink.download = fileName;
            downloadLink.href = fileURL;
            downloadLink.click();
        } else if (action.toLowerCase() === 'embed') {
            return fileURL;
        }
    }
    userDetails: any = {};

    hniPopupBackdrop() {
        this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
        this.overlayContainer.getContainerElement().classList.add('hni-popups');
     } else {
            this.overlayContainer.getContainerElement().classList.remove('hni-popups');
        }
    }
    isUserAuthenticated(): boolean {
        const isAuthenticated = this.getLocalData("loggedIn", false);
        if (isAuthenticated === 'true') {
            return true;
        } else {
            return false;
        }
    }
    getValidator(attributeCode: string) {
        switch (attributeCode) {
            case 'DATE_EXPRY':
            case 'TRC_EXPIRY_DATE':
                return [CustomValidators.futureDatesOnly];
            case 'LNAME':
            case 'FNAME':
            case 'LAST_NM_PAN_ACK':
            case 'FIRST_NM_PAN_ACK':
            case 'FIRST_NM_FRM60':
            case 'LAST_NM_FRM60':
            case 'FIRST_NM_DECLARATION':
            case 'LAST_NM_DECLARATION':
                return [CustomValidators.personName];
            case 'MNAME':
            case 'MIDDLE_NM_PAN_ACK':
            case 'MIDDLE_NM_FRM60':
            case 'MIDDLE_NM_DECLARATION':
            case 'QR_Code_DC':
            case 'Header_DC':
                return [];
            case 'DATE_ISSUE':
            case 'DOB':
            case 'DATE_JOINING':
            case 'DOB_PER_DOC':
            case 'LAST_TRANSACTION_DATE':
                return [CustomValidators.dateOfIssue];
            case 'PAN_NO':
                return [CustomValidators.panNo];
            case 'AADHAAR_NO':
                return [CustomValidators.aadharNo];
            case 'IFSC_CD':
                return [CustomValidators.ifscCode];
    
            default:
                return [Validators.required];
        }
    }
    getSubmitSRSuccessMsg(srno, subactivity) {
        let transaction = this.getTransactionType(subactivity);
        if (transaction) {
            return `Your request has been submitted. SR No. ${srno} - ${transaction} will reflect on portal post 24 hours`;
        } else {
            return `Document(s) uploaded successfully for SR number: ${srno}`;
        }
    }
    getCreateSRSuccessMsg(srno, subactivity) {
        let transaction = this.getTransactionType(subactivity);
        if (transaction) {
            return `Your request has been submitted. SR No. ${srno} - ${transaction} will reflect on portal post 24 hours`;
        } else {
            return `Service Request No. ${srno} has been created successfully. To track it, please visit the service request section of my account portal.`;
        }
    }
    getTransactionType(subactivity: string) {
        if (subactivity == "CHNG_NOM_BEN_APP") {
            return "Change in Nominee/Beneficiary/Appointee";
        } else if (subactivity == "CHNG_IN_MODE") {
            return "Change in Mode";
        } else if (subactivity == "FUND_SWT_PREM_RED") {
            return "Fund Switch/Premium Redirection";
        } else {
            return null;
        }
    }
    sendToAnalytics(payload: any): void {
        try {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push(payload);
        } catch (e) { }        
    }
    openNewTab(url) {
        let popup = window.open();
        if (!popup || popup == null || typeof popup.closed == 'undefined') {
          this.pdfView(url);
        } else {
            popup.opener = null;
            popup.location.href = url;
            popup.focus();
        }
    }
    trackUserActions(payload) {    
        try {    
            let loginResp = this.dataService.getLoginResp();
            let loginSource ="";
            loginResp = loginResp ? loginResp : {};
            if (!loginResp.SOURCE || loginResp.SOURCE === "CUSTOMERPORTAL" || loginResp.SOURCE === "CUSTOMLINK" || loginResp.SOURCE === "CS_APP") {
                if(loginResp.SOURCE === "CUSTOMERPORTAL"){
                    loginSource = 'portal_myaccount';
                }
                else if(loginResp.SOURCE === "CUSTOMLINK"){
                    loginSource = 'deeplink_myaccount';
                }
                else if(loginResp.SOURCE === "CS_APP"){
                    loginSource = 'csapp_myaccount';
                }
                payload["policyNo"] = payload && payload["policyNo"] ? payload["policyNo"].replace(/\s+/g, '') : this.getPolicyForAnalytics();
                const policyNo = payload["policyNo"];
                let data = {
                   "policyNo" : policyNo,
                   "keyPolicyNo" : policyNo ? `H-${policyNo}` : "",
                   "loginTimestamp" : loginResp.CURRENT_LOGIN_TIME ? loginResp.CURRENT_LOGIN_TIME: "",
                   "actionTimestamp" : moment().format('DD/MM/YYYY hh:mm:ss A'),
                   "clientId" : this.getClientId() ? this.getClientId() : "",
                   "nri" : loginResp.NRI_FLAG ? loginResp.NRI_FLAG : "",                   
                   "userType" : "hli",
                   "source": loginSource,
                   ...payload
               };  
               Object.keys(data).forEach((k) => {
                  data[k] = String(data[k]);
                });
               sendTrackingData(data);
            }             
        } catch (e) {
        }
    }
    getPolicyForAnalytics() {
        let loginResp = this.dataService.getLoginResp();
        if (loginResp && loginResp.LOGINID && loginResp.LOGINMODE === "PO") {
            return loginResp.LOGINID.replace(/\s+/g, '');
        }
        let policies = this.dataService.getPolicyCardResp();
        let policyObj =  policies && policies.length > 0 ? policies[0] : "";
        let policy = policyObj && policyObj["POLICYNO"] ? policyObj["POLICYNO"] : "";
        return policy ? policy.replace(/\s+/g, '') : "";
    }
    loadTrackingScript() {        
        if (this.isIndianTimeZone()) {
            var head = document.head || document.getElementsByTagName("head")[0];
            let trackingScript: HTMLScriptElement = document.createElement("script");
            trackingScript.src = serviceURL.trackingScript;
            trackingScript.async = true;
            head.appendChild(trackingScript);
        } 
    }
    isIndianTimeZone(): boolean {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone && (timeZone.includes('Asia/Calcutta') || timeZone.includes('IST'));
    }
    
    createAadhaarConsentRequest(
        apiname: string,
        body: any,
        source: string = TOUCHPOINT
    ): RequestSchema {
        let request: RequestSchema = {
            head: {
                apiname,
                source: source,
                xrfkey : "CSPS-HDFC_001"
            },
            body,
        };
        return request;
    }
    coTrackingScript() {  
        // let loginResp = this.dataService.getLoginResp();
        // loginResp = loginResp ? loginResp : {};
        let script : HTMLElement = document.getElementById('coBrowsingScript');
        if (script == null) {  
            var body = document.body || document.getElementsByTagName("body")[0];
            let trackingScriptCo: HTMLScriptElement = document.createElement("script");
            trackingScriptCo.src = serviceURL.coBrowser;
            trackingScriptCo.async = true;
            trackingScriptCo.id = "coBrowsingScript";
            trackingScriptCo.remove();
            body.appendChild(trackingScriptCo);
        }
    }
    coTrackingScriptRemoval(){
        var script : HTMLElement = document.getElementById('coBrowsingScript');
        script.remove();
    }
}