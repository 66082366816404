import { Component, OnInit, Input, Injectable, EventEmitter, Output, TemplateRef, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateMailingAddressManualComponent } from '../update-mailing-address-manual/update-mailing-address-manual.component';
import { HttpService } from 'src/app/services/http/http.service';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { Utils } from "../../../utils/common-functions";
import { useraddressReqBody, userbankdtlsReqbody, BankDetailRequest, OptionValue } from '../my-account-data-modal';
import { DataService } from 'src/app/services/data/data.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from 'src/app/services/message/message.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { DocListPopupComponent } from '../../shared/components/doc-list-popup/doc-list-popup.component';
import { SERACH_TYPE } from '../my-account-data.constant';
import * as moment from 'moment';
import { CameraComponent } from '../../shared/components/camera/camera.component';
import { DragDropComponent } from '../../shared/components/drag-drop/drag-drop.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { SrPopupComponent } from '../../shared/components/sr-popup/sr-popup.component';
import { serviceURL } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { MyAccountComponent } from '../my-account/my-account.component';
import { MyPoliciesDialogComponent } from '../../my-policies/my-policies-dialog/my-policies-dialog.component';

@Component({
  selector: 'app-update-mailing-address',
  templateUrl: './update-mailing-address.component.html',
  styleUrls: ['./update-mailing-address.component.css'],
  providers: [MyAccountComponent]
})
export class UpdateMailingAddressComponent implements OnInit {
  queryParams;
  photos: any[] = [];
  public neftFlag: boolean;
  public ecsFlag: boolean;
  public chequeFlag: boolean;
  public manualDivFlag: boolean;
  public kycFlag: boolean;
  public CheckboxFlag: boolean;
  public entryFlag: boolean;
  public addPermanentAddressFlag: boolean;
  public editNameFlag: boolean;
  showDiv: boolean = false;
  proceedFlag: boolean = true;
  bankBranchFlag: boolean = false;
  neftDetails: FormGroup;
  nameDeclaration: boolean = false
  bankDetails = [];
  selectState: any[];
  selectCountry: any[];
  chooseType: any[];
  countryList: any[] = [
    { country: 'India' },
    { country: 'Pakistan' }
  ];
  stateList: any[] = [
    { state: 'Tamil Nadu' },
    { state: 'Maharashtra' }
  ];
  templateRef: any[];
  addressbody: useraddressReqBody;
  bankdtlbody: userbankdtlsReqbody;
  ClientAddress: any;
  CommAddress: any;
  isHNIUSer: boolean = false;
  userDetails: any = {};
  editNameForm: FormGroup;
  ocrEditNameForm: FormGroup;
  editNeftForm: FormGroup;
  editChequeForm: FormGroup;
  editEcsForm: FormGroup;
  searchBankBranch: FormGroup;
  bankName: string = "";
  branchName: string = "";
  accNumberFlag: boolean;
  billingDate = [];
  ocrDocCode: FormControl = new FormControl('', [Validators.required]);
  uploadedFileList: Array<any> = [];
  uploadedFilesURL: Array<string> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'gif', 'bmp'];
  maxFileSize: number = 2;
  showPdf: boolean = false;
  isOcrUpdate: boolean = false;
  ocrResp: any = {};
  ocrFailure : boolean = false;
  @ViewChild('updateFilePopup') ocrFormPopup: TemplateRef<any>;
  @ViewChild('updateNeftDetails') editNeftPopup: TemplateRef<any>;
  @ViewChild('editNamePopup') updateNamePopup: TemplateRef<any>;
  @ViewChild('exitNEFTDtls') exitNEFTPopup: TemplateRef<any>;
  @ViewChild('dragDrop') dragDropComp: DragDropComponent;
  @ViewChild('offlineAadharForm') offlineAadharForm: ElementRef;
  @ViewChild('srSubmitpopup') srSubmitpopup : TemplateRef<any>;
  offlineAadharFormData: any = "";
  offlineAadharURL: string = serviceURL.offlineAadharLink;
  displayMessage: string;
  sameNameFlag: string;
  namePopup: boolean = true;
  showOtpSection: boolean = false;
  pennyDropOtpForm: FormGroup = null;
  otpFocus: boolean = false;
  mobileNo: string = '';
  emailID: string = '';
  timer: number = 300;
  validTime: number;
  minutes: number = 0;
  seconds: number = 0;
  interval: any;
  pennyDropOTPResp: any = {};
  IsChecked: boolean = true
  fetchDetailsbtn : boolean

  requirementDtlsList: any = [];
  isSubmitSuccess: Array<boolean> = [];
  isUploadSuccess: Array<boolean> = [];
  requirementDtls: any = {};
  byteArray: any[] = [];
  payeeDtls:any;
  linkId:any;
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  readOnlyNeftDtls: boolean = false;
  acctTypeDtls: any =[];
  @ViewChild('updateChequeDetails') updateCheque: TemplateRef<any>;
  isNameSubmitDisabled: boolean = true;
  payoutDtls : any;
  nameFlag: boolean = false;
  validKycCount : boolean = false;
  hideBtn: boolean = false;
  ocrFlag: boolean = false;
  manualFlag: boolean = false;

  accepted : boolean = false;
  otpResp: any;
  statusMessage: any;
  timerSec: number = 180;
  hours: number;
  showOtpButton: boolean = false;
  showOtpSectionKYC: boolean = false;
  beneficiaryOtpForm : FormGroup = null;
  passwordType: string;
  reveal: boolean = false;
  terms: FormGroup;
  srNo: any;
  NeftBtn: boolean = false;
  PanBtn: boolean = false;
  KycBtn: boolean = false;
  bankname : boolean = false;
 fetchOcrDetails: boolean ;
  showFetch: boolean;
 disableFetchBtn: boolean;
 existNeftDetails: any;
 pennyDropCase: boolean;



  get otpValidation() { return this.pennyDropOtpForm.controls; }

  get getAccept(){
    return this.terms.get('accept').value
  }

  constructor(public dialogRef: MatDialog,public dialog: MatDialog, public dialogData: MatDialogRef<any>, private activatedRoute: ActivatedRoute, public router: Router, public _httpService: HttpService,
    public myAccountService: MyAccountService, public utils: Utils, @Inject(MAT_DIALOG_DATA) public data, public dataService: DataService,
    public message: MessageService, public createSrService: CreateSRService, public fb: FormBuilder, public myPoliciesService: MyPoliciesService, private loader: LoaderService, public auth: AuthService, public crypto : EncryptDecryptService, public myAccount: MyAccountComponent, public dialogSelfRef: MatDialogRef<UpdateMailingAddressComponent>) { }

  type: Array<any> = [];

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    if(this.data.kycId == "KYC" && this.data.validKycCount!= undefined && this.data.id == "surrender"){
      if(this.data.validKycCount == "true"){
      this.validKycCount = true;
      this.ocrFlag = true;
      this.manualFlag = false;
      } 
       else {
        this.validKycCount = false;
        this.ocrFlag = false;
        this.manualFlag = true;
      }
    } else {
      this.validKycCount = false;
      this.ocrFlag = true;
      this.manualFlag = true;
    }

    if(this.data.ocrFailure){
    this.ocrFailure = true;
   }
   if(this.data.fetchDetailsbtn){
    this.fetchDetailsbtn = true;

   }
   if(this.data.showFetch){
      this.showFetch = true;
   }
   if(this.data.bankname){
    this.bankname = true;
   }
  if (this.data){
   this.fetchOcrDetails = this.data.fetchOcrDetails;
   this.entryFlag = this.data.fetchOcrDetails ? false : true;
  }
    if (this.data && (this.data.type === 'Mailing' || this.data.type === 'Permanent')) {
      this.trackUserActions({ 'eventName': `Edit ${this.data.type} address - popup load` });
    }
    this.payeeDtls = this.myPoliciesService.getPayeedetails()
    this.linkId = this.payeeDtls.X_SR_LINK_ID
    // if(((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "surrender"))&&(this.data.kycId == "KYC")){  
    if(((this.data.id == "maturity")||(this.data.id == "moneyBack"))&&(this.data.kycId == "KYC")){  
      this.createKycSR()
    }
    this.setSubactivityFlag();
    this.getAccountType(this.data.type)
    if (this.data) {
      this.addressdetails(this.data.type);
      this.bankdetails(this.data.type);
    }
    this.selectCountry = this.countryList[0];
    this.selectState = this.stateList[0];
    this.chooseType = this.type[0];
    this.activatedRoute.queryParams.subscribe(values => {
      this.queryParams = values['src'];
    })
    this.editNameForm = this.fb.group({
      'firstName': [this.getClientFirstName(), [Validators.required, CustomValidators.clientName]],
      'lastName': [this.getClientLastName(), [Validators.required, CustomValidators.clientName]]
    });    
    this.searchBankBranch = this.fb.group({
      'bankSearch': [''],
      'branchSearch': ['']
    });
    this.pennyDropOtpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
    });
    if (this.editNameFlag) {
      setTimeout(() => this.editName(this.updateNamePopup));
    }
    this.editNameForm.valueChanges.subscribe(() => {
      this.displayMessage =""
      if (this.editNameForm.controls.firstName.value && this.editNameForm.controls.lastName.value
        //&& this.getClientFirstName() && this.getClientLastName()
        ) {
          this.isNameSubmitDisabled = ((this.editNameForm.controls.firstName.value ? this.editNameForm.controls.firstName.value.toLowerCase().trim() : '') == (this.getClientFirstName() ? this.getClientFirstName().toLowerCase().trim() : '')) &&
          ((this.editNameForm.controls.lastName.value ? this.editNameForm.controls.lastName.value.toLowerCase().trim() : '') == (this.getClientLastName() ? this.getClientLastName().toLowerCase().trim() : ''))    
          if(this.isNameSubmitDisabled && this.data.id == "beneficiary"){
            if(this.data.dtls.CLIENTROLE != "Appointee"){
              this.displayMessage = "Same Nominee/Beneficiary already present."
            } else{
              this.displayMessage = "Same Appointee already present."
            }
          } 
        }
    }); 

    this.payoutDtls = this.myPoliciesService.getPayoutdetails();

    this.beneficiaryOtpForm = this.fb.group({
      'otp' : ['', [Validators.required,Validators.pattern('^[0-9]{6,6}$')]]
    })

    this.terms = this.fb.group({
      accept : [false]
    })

    this.passwordType = "password";
    
    if(this.data.existingAccDtls){
      this.existNeftDetails = true;      
    }else{
      this.existNeftDetails = false;
    }
    if(this.data.existNeftFlg){
      this.existNeftDetails = true; 
    } else{
      this.existNeftDetails = false;
    }

    if (this.data.isPennyDrop) {
      this.pennyDropCase = true;
    } else {
      this.pennyDropCase = false;
    }
  }
  addressdetails(addressType) {
    if (addressType === 'Permanent') {
      this.addressbody = {
        "header": "Add Permanent Address",
        "dropdown": "Update Permanent Address",
        "houseno": this.data.CliAdd.HOUSENUMBER,
        "street": this.data.CliAdd.STREET,
        "landmark": this.data.CliAdd.LANDMARK,
        "city": this.data.CliAdd.CITY,
        "state": this.data.CliAdd.STATE,
        "country": this.data.CliAdd.COUNTRY,
        "pincode": this.data.CliAdd.PINCODE,
      }
    }
    else if (addressType === 'Mailing') {
      this.addressbody = {
        "header": "Add Mailing Address",
        "dropdown": "Update Mailing Address",
        "houseno": this.data.id == "beneficiary" ? this.data.ComAdd.BEN_HOUSENOR : this.data.ComAdd.HOUSE_NO,
        "street": this.data.id == "beneficiary" ? this.data.ComAdd.BEN_STREETADDR : this.data.ComAdd.STREET_ADDRESS,
        "landmark": this.data.id == "beneficiary" ? this.data.ComAdd.BEN_AREA : this.data.ComAdd.LANDMARK,
        "city": this.data.id == "beneficiary" ? this.data.ComAdd.CITY : this.data.ComAdd.CITY,
        "state": this.data.id == "beneficiary" ? this.data.ComAdd.STATE : this.data.ComAdd.STATE,
        "country": this.data.id == "beneficiary" ? this.data.ComAdd.COUNTRY : this.data.ComAdd.COUNTRY,
        "pincode": this.data.id == "beneficiary" ? this.data.ComAdd.BEN_PINCODE : this.data.ComAdd.PINCODE
      }
    }
  }
  changeName(event: { checked: boolean }) {
    if (!event.checked) {
      this.editEcsForm.patchValue({
        "accName": this.data.ECSup.accname
      })
    }
    this.nameDeclaration = event.checked;
  }
  bankdetails(accoType) {
    if (accoType === 'NEFT') {
      this.bankdtlbody = {
        "header": "Add NEFT Details",
        "dropdown": "Update NEFT Details",
        "accouno": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNUMBER : this.data.NEFTup.ACC_NUM,
        "bankname": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BANKNAME : this.data.NEFTup.BANKNAME,
        "clientname": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNAME : this.data.NEFTup.ACCNAME,
        "ifsccode": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_IFSCCODE : this.data.NEFTup.IFSCCODE,
        "acctype": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCTYPE : this.data.NEFTup.NEFTACCOUNTTYPE,
        "branchname": (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BRANCHNAME : this.data.NEFTup.BRANCHNAME
      }
    }
    else if (accoType === 'ECS') {
      this.bankdtlbody = {
        "header": "Add ECS Details",
        "dropdown": "Update ECS Details",
        "accouno": this.data.ECSup.accno,
        "bankname": this.data.ECSup.bankname,
        "clientname": this.data.ECSup.accname,
        "ifsccode": this.data.ECSup.ifsccode,
        "acctype": this.data.ECSup.acctype,
        "branchname": this.data.ECSup.branchname
      }
    } else if (accoType === 'CHEQUE') {
      this.bankdtlbody = {
        "header": "Add NRE Details",
        "dropdown": "Update NRE Details",
        "accouno": this.data.NEFTup.ACC_NUM,
        "bankname": this.data.NEFTup.BANKNAME,
        "clientname": this.data.NEFTup.ACCNAME,
        "ifsccode": this.data.NEFTup.IFSCCODE,
        "acctype": this.data.NEFTup.NEFTACCOUNTTYPE,
        "branchname": this.data.NEFTup.BRANCHNAME
      }
    }
  }
  updateFileDiv(templateRef: TemplateRef<any>) {
    this.dialogData = this.dialogRef.open(templateRef, { width: '800px', panelClass: 'app-update-file-div', backdropClass: 'edit-communication-backdrop', disableClose: true });
  }  
  editName(templateRef: TemplateRef<any>) {
    this.dialogRef.closeAll();
    this.dialogRef.open(templateRef, { width: '500px', panelClass: this.data.disableClose ? 'edit-pan-popup-iframe' : 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
  }

  updateManual() {
    this.dialogRef.closeAll();
    const dialogRef = this.dialogRef.open(UpdateMailingAddressManualComponent, { panelClass: this.data.disableClose ? 'app-update-mailing-address-manual-iframe' : 'app-update-mailing-address-manual', backdropClass: 'edit-communication-backdrop', data: { ...this.data, showOcrUpdate: false }, disableClose: true });
  }
  closeNeftDetails(templateRef: TemplateRef<any>){
    this.dialogRef.closeAll();
    this.dialogRef.open(templateRef, { width: '500px', panelClass: this.data.disableClose ? 'edit-pan-popup-iframe' : 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
  }
  editNEFTDtls(templateRef: TemplateRef<any>){
    this.dialogRef.closeAll();
    this.dialogData = this.dialogRef.open(templateRef, { width: '800px', panelClass: 'app-update-file-div', backdropClass: 'edit-communication-backdrop', disableClose: true });
  }
  cancelNeftDetails() {
    if(this.data.id == "surrender" || this.data.id == "flic"){
      this.dialog.closeAll();
      this.closeNeftDetails(this.exitNEFTPopup);
      return;
    }
    if (!this.data.disableClose) {
      this.dialog.closeAll();
    } else if (this.dataService.isCustomLink) {
      this.auth.logout('M');
    }
}
  cancelEditName() {
    this.dialogRef.closeAll();
  }
  cancelPhotoUpload() {
    this.dialogRef.closeAll();
  }
  cancelBankDetails() {
    this.bankDetails = [];
    this.searchBankBranch.reset();
    this.dialogData.close();
  }
  createEditNameSR() {
    let type;
    let clientId;
    let acc_tag;
    let parentSR;
    let benId;
    if (this.data.viewDtls == "benfiAndNominee") {
      type = "CON_AMS_NNY"
      clientId = this.data.dtls.CLIENTID
      benId = this.data.dtls.CLIENTID
    } else if (this.data.viewDtls == "lifeAssured") {
      type = "CON_ADMN_NYN"
      clientId = this.data.dtls.CLIENTID
      benId = ""
    } else if((this.data.id == "maturity")||(this.data.id == "moneyBack")){
      acc_tag = "Y"
      clientId = this.data.clientId
      parentSR = this.data.parentSr
      benId = ""
    } else {
      benId = ""
      acc_tag = "";
      parentSR = ""
      type = ""
      clientId = this.utils.getClientId()
    }
    // if((this.data.id == "maturity")||(this.data.id == "moneyBack")){
    //   acc_tag = "Y"
    //   clientId = this.data.clientId
    //   parentSR = this.data.parentSr
    // } else{
    //   acc_tag = "";
    //   parentSR = ""
    // }
    const ocrData = this.ocrResp.ocrdocrk && this.isOcrUpdate
      ? { ocrflag: 'Y', ocrdocrk: this.ocrResp.ocrdocrk }
      : { ocrflag: 'N' };
    let reqBody = {
      ...ocrData,
      "policyno": this.data.policyNo,
      "clientid": clientId,
      "benId": benId,
      "role": this.data.role,
      "subactivity": "CHNG_NAME",
      "subactivitytype": type,
      "action": "CREATE",
      "payout_acc_tag": acc_tag,
      "parentsrno": parentSR, 
      "linkid": this.linkId,
      "srinput": {
        "namedtls": {
          "lastname": this.editNameForm.controls.lastName.value,
          "firstname": this.editNameForm.controls.firstName.value
        }
      },
      "meetingdetails": {
        "meetingplace": "",
        "communicationtype": "",
        "datetime": ""
      }
    };
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (!this.data.disableClose) {
        this.dialogRef.closeAll();
      }
      if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N') {
        const srno: string = resp.body.srbasicinfo.srno;
        if((this.data.id != "maturity")&&(this.data.id != "moneyBack")){
        this.dialogRef.open(DocListPopupComponent, {
          panelClass: this.data.disableClose ? 'doc-list-dialog-iframe' : 'doc-list-dialog',
          backdropClass: 'doc-list-dialog-backdrop',
          disableClose: true,
          data: {
            srno: srno,
            subactivity: reqBody.subactivity,
            createSrResp: resp.body,
            disableClose: this.data.disableClose
          }
        });
      }
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
      this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'Y') {
        if (this.data.disableClose) {
          this._httpService.showSuccessMsg(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, true);
          this.trackUserActions({ 'eventName': `message - Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`,'error_code':'SM' });
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp, false,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getClientFirstName() {
    if((this.data.id == "beneficiary" || this.data.role == "Beneficiary") && this.data.type == "name"){
      return this.data.dtls.BEN_FIRSTNAME? this.data.dtls.BEN_FIRSTNAME : "";
    } else{
    return this.userDetails ? this.userDetails.FIRST_NM : "";
    }
  }
  getClientLastName() {
    if((this.data.id == "beneficiary" || this.data.role == "Beneficiary") && this.data.type == "name"){
      return this.data.dtls.BEN_LASTNAME? this.data.dtls.BEN_LASTNAME : "";
    } else{
    return this.userDetails ? this.userDetails.LAST_NM : "";
    }
  }
  createNeftSR() {
    let type;
    let clientId;
    let acc_tag;
    let parentSR;
    let cheque;
    let subactivity;
    let acc_name;
    let acc_type;
    let acc_no;
    let ifsc_code;
    let benId;
    let typeOfProof;
    let msg;
    if(this.data.type == "NEFT"){
      subactivity = "STND_NEFT";
      acc_name = this.editNeftForm.controls.accName.value;
      acc_no = this.editNeftForm.controls.accNumber.value;
      acc_type = this.editNeftForm.controls.accType.value;
      ifsc_code = this.editNeftForm.controls.ifscCode.value.toUpperCase();
      typeOfProof = "DIG_PROOF";
      msg = "NEFT"
    } else{
      subactivity = "ACCOUNT_TAGGING";
      acc_name = this.editChequeForm.controls.accName.value;
      acc_no = this.editChequeForm.controls.accNumber.value;
      acc_type = this.editChequeForm.controls.accType.value;
      ifsc_code = this.editChequeForm.controls.ifscCode.value.toUpperCase();
      typeOfProof = "PHY_PROOF";
      msg = "Cheque"
    }
    if(this.data.id == "beneficiary") {
      type = "CON_AMS_NNY";
      clientId = this.data.clientid
      benId = this.data.clientid
    } else if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")||(this.data.id == "surrender")){
      if (this.data.role != "Beneficiary"){
      acc_tag = "Y"
      parentSR = this.data.parentSr
      clientId = this.data.clientid
      benId = ""
    } else{
      type = "CON_AMS_NNY";
      clientId = this.data.clientid
      benId = this.data.clientid
      acc_tag = "Y"
      parentSR = this.data.parentSr
    }
    } else {
      benId = ""
      acc_tag = "";
      parentSR = ""
      type = ""
      clientId = this.utils.getClientId()
    }
    const ocrData = this.ocrResp.ocrdocrk && this.isOcrUpdate
      ? { ocrflag: 'Y', ocrdocrk: this.ocrResp.ocrdocrk }
      : { ocrflag: 'N' };
      if(ocrData.ocrflag == 'Y'){
        cheque = "Y";
      } else{
        cheque = "N";
      }
    const reqBody = {
      ...ocrData,
      policyno: this.data.policyNo,
      clientid: clientId,
      benId: benId,
      role: this.data.role,
      subactivity: subactivity,
      subactivitytype: type,
      action: "CREATE",
      payout_acc_tag: acc_tag,
      parentsrno: parentSR, 
      linkid: this.linkId,
      srinput: {
        ischequepayment: cheque,
        samenameflg: this.sameNameFlag,
        contactdtls: {
          isemailchanged: "N"
        },
        accountdtls: {
          accname: acc_name,
          acctype: acc_type,
          accno: acc_no,
          hdfcbnkflg: this.isHdfcBank() ? "Y" : "N",
          micrcode: "",
          ifsccode: ifsc_code
        },
        typeofproof: this.readOnlyNeftDtls?"EXISTING_ACCT": typeOfProof
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
      }
    };
    let successMsg = '';
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.srbasicinfo && resp.body.srbasicinfo.srno) {
        if (this.data.id == "surrender" && resp.body.ocrflag == 'N') {
          successMsg = `Service Request for ${msg} Update (SR No. ${resp.body.srbasicinfo.srno}) has been created and pending for requirement`;
        } else if (this.data.id == "surrender" && resp.body.ocrflag == 'Y') {
          successMsg = `Service Request for ${msg} Update (SR No. ${resp.body.srbasicinfo.srno}) has been created successfully`;
        } else {
          successMsg = `Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`;
        }
      }
      if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N' && (!this.byteArray || this.byteArray.length == 0)) {
        this.dialogRef.closeAll();
        const srno: string = resp.body.srbasicinfo.srno;
        let surrenderFlag;
        if(this.data.id == "surrender"){
          surrenderFlag = true;
        } else { surrenderFlag = false; }
        if((this.data.id != "maturity")&&(this.data.id != "moneyBack")&&(this.data.id != "annuityPayout")){
          if (!this.readOnlyNeftDtls) {
            this.dialogRef.open(DocListPopupComponent, {
              disableClose: true,
              panelClass: 'doc-list-dialog',
              backdropClass: 'doc-list-dialog-backdrop',
              data: {
                surrenderFlag: surrenderFlag,
                srno: srno,
                subactivity: reqBody.subactivity,
                createSrResp: resp.body,
                type: this.data.type,
                unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                //disableClose: this.dataService.getIsCustomLink()
              }
            });
            let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
            this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'],trackMsgObj);
          }
        }
        if (this.readOnlyNeftDtls) {
          if (this.dataService.getIsCustomLink() && !(this.data.id == "surrender" || this.data.id == "flic") && !this.data.unclaimedAmtFlow) {
            this._httpService.showSuccessMsg(successMsg, true);
          } else if (!this.data.unclaimedAmtFlow && !(this.data.id == "surrender" || this.data.id == "flic") && ( this.data.id != "beneficiary" || this.data.role != "Beneficiary")) {
            this.srNo = resp.body.srbasicinfo.srno;
            this.getNextBestAction(clientId,'NEFT');
          } else {
            let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
            this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'],trackMsgObj);
            if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && this.data.unclaimedAmtFlags.KYC_ACTIVE=='N') {
              this.dialog.open(MyPoliciesDialogComponent, {
                panelClass: 'my-policies-dialog', disableClose: true,
                data: {
                  policyNo: this.data.policyNo,
                  unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                  unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                  disableClose: this.dataService.getIsCustomLink(),
                }
              });
            } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
              this.createSrService.showUnclaimedAmtFinalMsg();
            }
          }
        }/*  else if (this.readOnlyNeftDtls && !(this.data.id == "surrender") && ( this.data.id != "beneficiary" || this.data.role != "Beneficiary")) {
          this.srNo = resp.body.srbasicinfo.srno;
          this.getNextBestAction(clientId,'NEFT');
        } else {
          this.message.openSnackBar(successMsg, 'xy', ['success-snackbar']);
          this.trackUserActions({ 'eventName': `message - ${successMsg}` });
        } */
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N' && this.byteArray && this.byteArray.length != 0) {
        this.getRequirementDetails(resp.body.srbasicinfo.srno);
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'Y') {
        this.dialogRef.closeAll();
        if (this.dataService.getIsCustomLink() && !(this.data.id == "surrender" || this.data.id == "flic") && !this.data.unclaimedAmtFlow) {
          this._httpService.showSuccessMsg(successMsg, true);
        } else {
           let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'],trackMsgObj);
          if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && this.data.unclaimedAmtFlags.KYC_ACTIVE=='N') {
            this.dialog.open(MyPoliciesDialogComponent, {
              panelClass: 'my-policies-dialog', disableClose: true,
              data: {
                policyNo: this.data.policyNo,
                unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                disableClose: this.dataService.getIsCustomLink(),
              }
            });
          } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
            this.createSrService.showUnclaimedAmtFinalMsg();
          }
        } 
        this.trackUserActions({ 'eventName': `message - ${successMsg}` });       
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        this.dialogRef.closeAll();
      }
    }),
      catchError((error) => throwError(error));
  }
  createNeftForm(accType) {
    this.readOnlyNeftDtls = false;
    for (let key of Object.keys(this.data.NEFTup)) {
      if (this.data.NEFTup[key] == "NA" || this.data.NEFTup[key] == null) {
        this.data.NEFTup[key] = "";
      }
    }
    let existingAccType = accType.find(item => item.label == this.data.NEFTup.NEFTACCOUNTTYPE)
    let benfiExistinfAc = accType.find(item => item.label == this.data.NEFTup.BEN_ACCTYPE)
    if (this.data.type == "NEFT") {

      if ((existingAccType !== undefined && existingAccType !== null && existingAccType !== '') || (benfiExistinfAc !== undefined && benfiExistinfAc !== null && benfiExistinfAc !== '')) {
        this.bankname = true;
      } else {
        this.bankname = false;
      }
      // if (this.data.RoleName) {
      //   this.data.role = "Beneficiary";
      // }
      if (this.pennyDropCase) {
        this.editNeftForm = this.fb.group({
          "accNumber": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNUMBER : this.data.NEFTup.ACC_NUM, [CustomValidators.accountLength]],
      //    "accName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNAME : this.data.NEFTup.ACCNAME, [CustomValidators.personName]],
          "accType": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? benfiExistinfAc ? benfiExistinfAc.code : '' : existingAccType ? existingAccType.code : '', [CustomValidators.selectAccountType]],
          "ifscCode": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_IFSCCODE : this.data.NEFTup.IFSCCODE, [CustomValidators.ifscCode]],
          "bankName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BANKNAME : this.data.NEFTup.BANKNAME],
          "branchName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BRANCHNAME : this.data.NEFTup.BRANCHNAME],
        }, { validators: [CustomValidators.accNumValid] }); 
      } else {
    this.editNeftForm = this.fb.group({
      "accNumber": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNUMBER : this.data.NEFTup.ACC_NUM, [CustomValidators.accountLength]],
      "accName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_ACCNAME : this.data.NEFTup.ACCNAME, [CustomValidators.personName]],
      "accType": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? benfiExistinfAc? benfiExistinfAc.code:'' : existingAccType? existingAccType.code:'', [CustomValidators.selectAccountType]],
      "ifscCode": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_IFSCCODE : this.data.NEFTup.IFSCCODE, [CustomValidators.ifscCode]],
      "bankName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BANKNAME : this.data.NEFTup.BANKNAME],
      "branchName": [(this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.NEFTup.BEN_BRANCHNAME : this.data.NEFTup.BRANCHNAME],
        }, { validators: [CustomValidators.accNumValid] }); 
      }    
   
    } else if(this.data.type == "CHEQUE"){
    this.editChequeForm = this.fb.group({
      "accNumber": [this.data.NEFTup.ACC_NUM, [CustomValidators.accountLength]],
      "accName": [this.data.NEFTup.ACCNAME, [CustomValidators.personName]],
      "accType": [existingAccType? existingAccType.code:'', [CustomValidators.selectAccountType]],
      "ifscCode": [this.data.NEFTup.IFSCCODE, [CustomValidators.ifscCode]],
      "checkBoxValue": ['']
    },{validators:[CustomValidators.accNumValid]});
  }
    if (this.data.isPennyDrop && !this.data.existingFlag) {
      this.updateFileDiv(this.editNeftPopup);
      this.readOnlyNeftDtls = true;
    } else if (this.data.isPennyDrop && this.data.existingAccDtls) {
      this.updateFileDiv(this.editNeftPopup);
      this.readOnlyNeftDtls = true;
      this.hideBtn = false;
    } else if (this.data.isPennyDrop && !this.data.existingAccDtls) {
      this.updateFileDiv(this.editNeftPopup);
      this.hideBtn = false;
    } else if(this.data.type == "CHEQUE"){
      this.updateFileDiv(this.updateCheque)
    } else if(!this.data.isPennyDrop && this.data.existingAccDtls){
      this.updateFileDiv(this.editNeftPopup);
    }
    if((this.data.existingAccDtls && this.data.type == "NEFT") && !(this.data.isPennyDrop)){
      this.readOnlyNeftDtls = true;
      this.hideBtn = true;
      this.updateFileDiv(this.editNeftPopup);
    } else if(this.data.existingAccDtls && this.data.type == "CHEQUE"){
      this.readOnlyNeftDtls = true;
      this.updateFileDiv(this.updateCheque)
    }
    
 let formName: FormGroup;
 if(this.data.type === "NEFT"){
   formName = this.editNeftForm
 } else if(this.data.type === "CHEQUE"){
   formName = this.editChequeForm
 }
 formName.controls.ifscCode.valueChanges.subscribe(() => {
  if (formName.controls.ifscCode.valid) {
    this.getIfscBankDtls(formName.controls.ifscCode.value.toUpperCase());
    this.bankname = true;
  } else {
    this.bankname = false;
        this.bankName = "";
        this.branchName = "";
      }
    });
  }
  getAccountType(type: string) {
    return this.myAccountService.getAccountTypeMasterData().subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp) && resp.body["GCOM_ACCOUNT_TYPE"]) {
        this.acctTypeDtls = resp.body["GCOM_ACCOUNT_TYPE"];
        for (let key of Object.keys(this.acctTypeDtls)) {
          this.type.push({
            label: this.acctTypeDtls[key][1],
            code: key
          });
        }
        if (type === "NEFT") {
          this.createNeftForm(this.type);
        } else if (type === "CHEQUE") {
          this.createNeftForm(this.type);
        }else {
          if (this.data.ECSup) { this.createEcsForm(); }
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  isHdfcBank() {
    let ifscCode: string
    if(this.data.type == "NEFT"){
      ifscCode = this.editNeftForm.controls.ifscCode.value.toUpperCase();
    } else if(this.data.type == "CHEQUE"){
      ifscCode = this.editChequeForm.controls.ifscCode.value.toUpperCase();
    }
    return ifscCode.substring(0, 4) === "HDFC";
  }
  submitEditNameForm() {
    let firstnameone;
    let firstnametwo;
    let lastnameone;
    let lastnametwo;
    if (this.editNameForm && this.editNameForm.valid) {
    if (this.data.viewDtls == "benfiAndNominee") {
      let lifeAssuredArray = [];
      for(let i of this.data.lifeAssuredResp) {
        // lifeAssuredArray.push(i.LA_FIRSTNAME) 
        firstnameone = i.LA_FIRSTNAME?i.LA_FIRSTNAME:'';  //Life Assured Firstname
        lastnameone = i.LA_LASTNAME?i.LA_LASTNAME:'';   //Life Assured Lastname
        firstnametwo = this.editNameForm.controls.firstName.value; 
        lastnametwo = this.editNameForm.controls.lastName.value;

      //   if(!lifeAssuredArray.includes(this.editNameForm.controls.firstName.value)) {
      //     this.createEditNameSR();
      //   }
      // else {
      //   if(this.data.dtls.CLIENTROLE == "Beneficiary") {
      //     this.displayMessage ="Beneficiary/Nominee name cannot be same as that of Life Assured."
      //   }
      //   else {
      //     this.displayMessage ="Appointee name cannot be same as that of Life Assured."
      //   }
      //   return !this.editNameForm.valid;
      // }
      if(firstnametwo.trim().toLowerCase() == firstnameone.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == lastnameone.trim().toLowerCase()){
        this.nameFlag = false;
        if(this.data.dtls.CLIENTROLE == "Beneficiary") {
          this.displayMessage = "Life assured and nominee/beneficiary cannot be same."
          return !this.editNameForm.valid;
        }
        else {
          this.displayMessage = "Life assured and appointee cannot be same."
          return !this.editNameForm.valid;
        }
      } else if(firstnametwo.trim().toLowerCase() == lastnameone.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == firstnameone.trim().toLowerCase()){
        this.nameFlag = false;
        if(this.data.dtls.CLIENTROLE == "Beneficiary") {
          this.displayMessage = "Life assured and nominee/beneficiary cannot be same."
          return !this.editNameForm.valid;
        }
        else {
          this.displayMessage = "Life assured and appointee cannot be same."
          return !this.editNameForm.valid;
        }
      } else if(lastnametwo.trim().toUpperCase()=== firstnameone.concat(lastnameone) || (lastnametwo.trim().toUpperCase()=== lastnameone.concat(firstnameone))|| (lastnametwo.trim().toUpperCase()=== firstnameone.concat(" ",lastnameone) || (lastnametwo.trim().toUpperCase()=== lastnameone.concat(" ",firstnameone)))){
        this.nameFlag = false;
        if(this.data.dtls.CLIENTROLE == "Beneficiary") {
          this.displayMessage = "Life assured and nominee/beneficiary cannot be same."
          return !this.editNameForm.valid;
        }
        else {
          this.displayMessage = "Life assured and appointee cannot be same."
          return !this.editNameForm.valid;
        }
      } else if(firstnametwo.trim().toUpperCase()=== firstnameone.concat(lastnameone) || (firstnametwo.trim().toUpperCase()=== lastnameone.concat(firstnameone))||firstnametwo.trim().toUpperCase()=== firstnameone.concat(" ",lastnameone) || (firstnametwo.trim().toUpperCase()=== lastnameone.concat(" ",firstnameone))){
        this.nameFlag = false;
        if(this.data.dtls.CLIENTROLE == "Beneficiary") {
          this.displayMessage = "Life assured and nominee/beneficiary cannot be same."
          return !this.editNameForm.valid;
        }
        else {
          this.displayMessage = "Life assured and appointee cannot be same."
          return !this.editNameForm.valid;
        }
      } else{
        this.nameFlag = true;
        // this.createEditNameSR();
        this.displayMessage = ""
      }
    }
      if(this.data.existingNom.length != 0) {
        this.displayMessage = "";
      for(let item of this.data.existingNom) {
        if(item.CLIENTROLE == "Beneficiary"){ //if nominee and appointee name has to be compared, comment this line
        if(item.BEN_FIRSTNAME && item.BEN_LASTNAME) {
        if((firstnametwo.trim().toLowerCase() == item.BEN_FIRSTNAME.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == item.BEN_LASTNAME.trim().toLowerCase())){
          this.nameFlag = false;
          this.displayMessage = "Same Nominee/Beneficiary already present. You can modify Nominee/Beneficiary details if required from the beneficiary section";
          break;
        } else if((firstnametwo.trim().toLowerCase() == item.BEN_LASTNAME.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == item.BEN_FIRSTNAME.trim().toLowerCase())){
          this.nameFlag = false;
          this.displayMessage = "Same Nominee/Beneficiary already present. You can modify Nominee/Beneficiary details if required from the beneficiary section";
          break;
        } else if((lastnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(lastnameone) || (lastnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(firstnameone))|| (lastnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(" ",lastnameone) || (lastnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(" ",firstnameone))))){
          this.nameFlag = false;
          this.displayMessage = "Same Nominee/Beneficiary already present. You can modify Nominee/Beneficiary details if required from the beneficiary section";
          break;
        } else if((firstnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(lastnameone) || (firstnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(firstnameone))||firstnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(" ",lastnameone) || (firstnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(" ",firstnameone)))){
          this.nameFlag = false;
          this.displayMessage = "Same Nominee/Beneficiary already present. You can modify Nominee/Beneficiary details if required from the beneficiary section";
          break;
        } else{
          this.nameFlag = true;
          // this.createEditNameSR();
          this.displayMessage = "";
        }
      }
    }
    else { //if nominee and appointee name has to be compared, comment this line
      //  if(item.form == 'guardian') {
        this.displayMessage = "";
        if(item.BEN_FIRSTNAME && item.BEN_LASTNAME){
        if((firstnametwo.trim().toLowerCase() == item.BEN_FIRSTNAME.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == item.BEN_LASTNAME.trim().toLowerCase())){
          this.nameFlag = false;
          this.displayMessage = "Same Appointee already present. You can modify Appointee details if required from beneficiary section";
          break;
        } else if((firstnametwo.trim().toLowerCase() == item.BEN_LASTNAME.trim().toLowerCase() && lastnametwo.trim().toLowerCase() == item.BEN_FIRSTNAME.trim().toLowerCase())){
          this.nameFlag = false;
          this.displayMessage = "Same Appointee already present. You can modify Appointee details if required from beneficiary section";
          break;
        } else if((lastnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(lastnameone) || (lastnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(firstnameone))|| (lastnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(" ",lastnameone) || (lastnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(" ",firstnameone))))){
          this.nameFlag = false;
          this.displayMessage = "Same Appointee already present. You can modify Appointee details if required from beneficiary section";
          break;
        } else if((firstnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(lastnameone) || (firstnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(firstnameone))||firstnametwo.trim().toUpperCase()=== item.BEN_FIRSTNAME.concat(" ",lastnameone) || (firstnametwo.trim().toUpperCase()=== item.BEN_LASTNAME.concat(" ",firstnameone)))){
          this.nameFlag = false;
          this.displayMessage = "Same Appointee already present. You can modify Appointee details if required from beneficiary section";
          break;
        } else{
          this.nameFlag = true;
          // this.createEditNameSR();
          this.displayMessage = "";
        }
      }
      }
    }
    }
    if(this.nameFlag == true){
      this.createEditNameSR();
    }
    } else{
      this.createEditNameSR();
    }
    } else {
      this.editNameForm.markAllAsTouched();
    }
  }
  nameFocusOut(event:any){
    let formGroupName
    if(this.data.type === "NEFT"){
      formGroupName = this.editNeftForm
    } else if(this.data.type === "CHEQUE"){
      formGroupName = this.editChequeForm
    }
    if(!this.readOnlyNeftDtls){
    let ownerName = this.userDetails.FIRST_NM +' '+ this.userDetails.LAST_NM
    let accholderName = formGroupName.get('accName').value
    if((accholderName != "") && (accholderName.toLowerCase() != ownerName.toLowerCase())){
      this.namePopup = false
    } else{
      this.sameNameFlag = "N"
    } }
  }

  holdNeft(){
    this.sameNameFlag = "N"
    let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
    this.message.openSnackBar(`Service request cannot be raised due to name mismatch`, 'xy', ['error-snackbar'],trackMsgObj);
    if (!this.data.disableClose) {
      this.dialog.closeAll();
    }
  }
  submitEditNeftForm() {
    if ((this.editNeftForm && this.editNeftForm.valid) || (this.editChequeForm && this.editChequeForm.valid)) {
      if (this.data.isPennyDrop) {
        this.getPennyDropOTP();
      } else {
        this.isOcrUpdate = !this.isOcrFormValueChanged();
        this.createNeftSR();
      }
    } else {
      this.editNeftForm.markAllAsTouched();
    }
  }
  getBankDetails(searchKey: string) {
    if (!this.editEcsForm.get(searchKey).valid) {
      return;
    }
    let reqBody: BankDetailRequest = {
      typeofsearch: SERACH_TYPE[searchKey].type,
    }
    reqBody[SERACH_TYPE[searchKey].request] = this.editEcsForm.get(searchKey).value

    this.myAccountService.getBranchDetails(reqBody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body['bankDetails']) {
          let bankDtsl: any = resp.body['bankDetails'][0];
          this.editEcsForm.patchValue({
            "ifscCode": bankDtsl.IFSC_CD,
            "bankName": bankDtsl.BANK_NM,
            "branchName": bankDtsl.BRANCH_NM,
            "micr": bankDtsl.MICR_NO ? bankDtsl.MICR_NO.toString() : '0',
          })
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  getIfscBankDtls(ifsc: string) {
    // const ifsc: string = this.editNeftForm.controls.ifscCode.value;
    this.myAccountService.getIfscMasterData(ifsc).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body[`BRANCH_BY_IFSC--${ifsc}`]) {
          let bankDtls: any = resp.body[`BRANCH_BY_IFSC--${ifsc}`][ifsc];
          //let bankDtlsArray: Array<string> = bankDtls[1].replace(/\s+/g, " ").trim().toUpperCase().split(' ');
          this.bankName = bankDtls[4][1];
          this.branchName = bankDtls[1].replace(/\s+/g, " ").trim().toUpperCase();
          if(this.readOnlyNeftDtls){
            this.submitEditNeftForm();
          }
        } else if(!this.readOnlyNeftDtls){
          this.editNeftForm.get("ifscCode").setErrors({errorMsg:'Please enter valid IFSC code' })
        }
        else{
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
          this.message.openSnackBar("Existing account details are not correct. Please raise NEFT update request via Add new option.", 'xy', ['error-snackbar'],trackMsgObj);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  acceptNeft() {
    let formName: FormGroup;
    if(this.data.type === "NEFT"){
      formName = this.editNeftForm
    }
    this.sameNameFlag = "Y"
    this.namePopup = true;
    formName.get('ifscCode')!.setValue('');
  }
  existingNeftFn(){
    let formGroupName: FormGroup;
    if(this.data.type === "NEFT"){
      formGroupName = this.editNeftForm
    } else if(this.data.type === "CHEQUE"){
      formGroupName = this.editChequeForm
    }
    this.getIfscBankDtls(formGroupName.controls.ifscCode.value.toUpperCase());
  }
  createEcsForm() {
    for (let key of Object.keys(this.data.ECSup)) {
      if (this.data.ECSup[key] == "NA" || this.data.ECSup[key] == null) {
        this.data.ECSup[key] = "";
      }
    }
    let acctype = this.type.filter((data) => data.label === this.data.ECSup.accType);
    this.data.ECSup.acctype = acctype.length > 0 ? this.data.ECSup.acctype : ''
    let prefBillDate = JSON.parse(this.data.ECSup['billingdate']);
    prefBillDate.forEach((date) => {
      this.billingDate.push({
        label: date,
        value: date
      })
    })

    this.editEcsForm = this.fb.group({
      "accNumber": [this.data.ECSup.accno, [CustomValidators.accountLength]],
      "reAccNumber": ['', [CustomValidators.accountLength]],
      "accName": [this.data.ECSup.accname, [CustomValidators.personName]],
      "accType": [this.data.ECSup.acctype, [CustomValidators.selectAccountType]],
      "ifscCode": [this.data.ECSup.ifsccode, [CustomValidators.ifscCode]],
      "bankName": [this.data.ECSup.bankname, [Validators.required]],
      "branchName": [this.data.ECSup.branchname, [Validators.required]],
      "micr": [this.data.ECSup.micrcode, [CustomValidators.selectMICR]],
      "fromDate": [moment(new Date()).format('DD/MM/YYYY')],
      "amount": [this.data.ECSup.amount],
      "incrPremNach": [this.data.ECSup.incpremiumamt],
      "prefBillDate": ['', [CustomValidators.billingDate]]
    }, { validator: CustomValidators.MatchAccountNumber });
  }
  submitEditEcsForm() {
    if (this.editEcsForm && this.editEcsForm.valid) {
      this.createECSSR();
    } else {
      this.editEcsForm.markAllAsTouched();
    }
  }
  searchDetails() {
    this.bankBranchFlag = true;
    let branchName = this.searchBankBranch.controls.branchSearch.value
    let bankName = this.searchBankBranch.controls.bankSearch.value
    if (branchName && bankName) {
      const reqBody = {
        typeofsearch: "bankAndBranch",
        bankname: bankName,
        branchname: branchName
      };
      this.myAccountService.getBranchDetails(reqBody).subscribe(resp => {
        if (this._httpService.isSuccessResponse(resp)) {
          if (resp.body && resp.body['bankDetails']) {
            if (resp.body && resp.body['bankDetails']) {
              this.bankDetails = resp.body['bankDetails']
            }
          }
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
          this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        }
      })
    }
  }
  selectBranch(selectedBank) {
    this.editEcsForm.patchValue({
      "ifscCode": selectedBank.IFSC_CD,
      "bankName": selectedBank.BANK_NM,
      "branchName": selectedBank.BRANCH_NM,
      "micr": selectedBank.MICR_NO ? selectedBank.MICR_NO.toString() : '0',
    })
    this.bankDetails = [];
    this.searchBankBranch.reset();
    this.dialogData.close();
  }
  createECSSR() {
    const reqBody = {
      policyno: this.data.policyNo,
      clientid: this.utils.getClientId(),
      role: this.data.role,
      subactivity: 'SI_ECS_ACTV_REQ',
      subactivityType: 'NACH_SI_CHANGE',
      action: "CREATE",
      nachsi: "NACH",
      samenameflg: this.nameDeclaration ? "Y" : "N",
      ocrflag: "N",
      srinput: {
        debitefdate: this.editEcsForm.controls.prefBillDate.value.label ? this.editEcsForm.controls.prefBillDate.value.label.toString() : '',
        accountdtls: {
          isaccactive: 'Y',
          bankname: this.editEcsForm.controls.bankName.value,
          accname: this.editEcsForm.controls.accName.value,
          acctype: this.editEcsForm.controls.accType.value.code ? this.editEcsForm.controls.accType.value.code : this.editEcsForm.controls.accType.value,
          accno: this.editEcsForm.controls.accNumber.value,
          micrcode: this.editEcsForm.controls.micr.value,
          ifsccode: this.editEcsForm.controls.ifscCode.value
        },
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
      }
    };
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      this.dialogRef.closeAll();
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        const srno: string = resp.body.srbasicinfo.srno;
        this.dialogRef.open(DocListPopupComponent, {
          disableClose: true,
          panelClass: 'doc-list-dialog',
          backdropClass: 'doc-list-dialog-backdrop',
          data: {
            srno: srno,
            subactivity: reqBody.subactivity,
            createSrResp: resp.body
          }
        });
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
        this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar'],trackMsgObj);
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  openOcr(ocrResp: any) {
    if (this.addPermanentAddressFlag) {
      this.dialogRef.open(UpdateMailingAddressManualComponent, { panelClass: this.data.disableClose ? 'app-update-mailing-address-manual-iframe' : 'app-update-mailing-address-manual', backdropClass: 'edit-communication-backdrop', data: { ...this.data, showOcrUpdate: true, ocrResp: ocrResp, uploadedFilesURL: this.uploadedFilesURL, byteArray: this.byteArray, ocrDocCode: this.ocrDocCode.value, uploadedFileType: this.uploadedFileList[0].type }, disableClose: true });
    } else {
      this.dialogRef.open(this.ocrFormPopup, { panelClass: 'ocr-update-popup-container', backdropClass: 'ocr-update-popup-backdrop', disableClose: true })
    }
  }
  displayAddress() {
    this.showDiv = true;
  }
  hideDiv() {
    this.showDiv = false;
  }
  hideDivProceed() {
    this.showDiv = false;
    this.proceedFlag = false;
  }
  openOcrPopup(files) {
    if(this.ocrDocCode.value == 'DOC_018' && (this.data.id == "beneficiary" || this.data.id == "Life Assured") && (this.data.ComAdd.BEN_MOBILENOR == null) && (this.data.ComAdd.BEN_EMAILID == null)){
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
      this.message.openSnackBar("We need OTP consent for this option. Since beneficiary's contact details not available either update contact details or choose other options",'xy',['error-snackbar'],trackMsgObj)
    }
    else if(this.ocrDocCode.value == 'DOC_018' && (this.data.id == "surrender" && (this.data.role == "Beneficiary" || this.data.role == "Life Assured" || this.data.role == "Appointee")) && (this.data.mobileNo == null) && (this.data.emailId == null)){
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
      this.message.openSnackBar("We need OTP consent for this option. Since beneficiary's contact details not available either update contact details or choose other options",'xy',['error-snackbar'],trackMsgObj);
    }
    else{
      if (this.ocrDocCode.valid) {
        this.uploadedFileList = files;
        this.uploadedFilesURL = [];
        if (this.validateFiles(this.uploadedFileList)) {
          this.byteArrayGenerator(this.uploadedFileList).then((byteArray) => {
            this.byteArray = byteArray; 
            this.ocrDocumentUpload(byteArray);
            for (let i = 0; i < this.uploadedFileList.length; i++) {
              this.uploadedFilesURL.push(`data:${this.uploadedFileList[i].type};base64,${byteArray[i].pdfbyte}`);
            }
            if (this.uploadedFileList[0].type == "application/pdf") {
              this.showPdf = true;
            } else {
              this.showPdf = false;
            }
          }); 
        }       
      } else {
        this.dragDropComp.fileDropRef.nativeElement.value = null;
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar('Please select a document', 'xy', ['error-snackbar'],trackMsgObj);
      }
    }
  }
  getOcrFormGroup() {
    if (this.editNameFlag) {
      return this.editNameForm;
    } else if (this.neftFlag) {
      return this.editNeftForm;
    } else if(this.chequeFlag){
      return this.editChequeForm;
    } else if (this.kycFlag) {
      return this.fb.group({});
    }
  }
  ocrDocumentUpload(byteArray: Array<any>) {
    let clientId;
    let payeeFlag;

    if(this.data.id == "surrender" || this.data.id == "flic"){
      payeeFlag = "Y";
    }
    if (this.data.id == "beneficiary" || this.data.role == "Beneficiary") {
      clientId = this.data.clientid;
    } else{
      clientId = this.utils.getClientId();
    }
    let reqBody = {
      clientid: clientId,
      doctype: this.getOcrDocTypeAndSubactivity() ? this.getOcrDocTypeAndSubactivity().doctype : '',
      contexttype: 'PCSPS',
      policyno: this.data.policyNo,
      docformat: this.uploadedFileList[0].name.split('.').pop(),
      subactivity: this.getOcrDocTypeAndSubactivity() ? this.getOcrDocTypeAndSubactivity().subactivity : '',
      compressionstatus: 'N',
      Status: 'Active',
      documentcode: this.ocrDocCode.value,
      bytearray: byteArray,
      parentsubactivity : this.data.id,
      payeeflag : payeeFlag
    };
    return this.createSrService.ocrDocUpload(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.ocrResp = resp.body;
        if (this.ocrResp.houseNo && this.ocrResp.houseNo.includes(':')) {
          this.ocrResp.houseNo = this.ocrResp.houseNo.replace(/:+/g,'').trim();
        }
        if (this.ocrResp.street && this.ocrResp.street.includes(':')) {
          this.ocrResp.street = this.ocrResp.street.replace(/:+/g,'').trim();
        }
        if (this.ocrResp.area && this.ocrResp.area.includes(':')) {
          this.ocrResp.area = this.ocrResp.area.replace(/:+/g,'').trim();
        }
        this.isOcrUpdate = true;
        this.openOcr(this.ocrResp);
        this.setOcrFormValue();        
      } else {
        if(this.data.kycId == 'KYC'){
          this.dialog.closeAll();  
          
        }

          if(this.showFetch && this.data.ocrFetchBtn){
          this.dialogSelfRef.close();
          this.dialogRef.open(UpdateMailingAddressComponent, {
            panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
            data: {
              NEFTup: Object.assign({}, this.data.NEFTup), type: 'NEFT', clientid : this.data.clientid, ocrFailure : true,policyNo: this.data.policyNo, role: this.data.role,id : this.data.id,RoleName : this.data.RoleName,
              neftFlag: true, entryFlag: true,parentSr: this.data.parentSr,existingFlag: true,disableClose: this.dataService.isDeepLink, bankname : false ,isPennyDrop: true,fetchDetailsbtn : true,existingAccDtls : this.existNeftDetails
            },
            disableClose: true
          });
          this.ocrFailure = true; 
          }
          setTimeout(()=>{
            let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
            this._httpService.apiErrorHandler(resp,null,trackMsgObj);
          },300)
      }
      this.dragDropComp.fileDropRef.nativeElement.value = null;
    });
  }
  getOcrDocTypeAndSubactivity() {
    if (this.editNameFlag) {
      return {
        "doctype": "CHANGE NAME PS",
        "subactivity": "CHNG_NAME"
      };
    } else if (this.neftFlag || this.chequeFlag) {
      return {
        "doctype": "NEFT-MNDT",
        "subactivity": "STND_NEFT"
      };
    } else if (this.addPermanentAddressFlag) {
      return {
        "doctype": "CHANGE NAME PS",
        "subactivity": "CHNG_ADDRESS"
      };
    } else if (this.kycFlag) {
      return {
        "doctype": "KYC_PS",
        "subactivity": "UPD_KYC"
      };
    }
  }
  validateFiles(fileList) {
    if (fileList.length > 2) {
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
      this.message.openSnackBar('Maximum of 2 files can be uploaded', 'xy', ['error-snackbar'],trackMsgObj);
      return false;
    }
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      const filetype = fileList[i].type ? fileList[i].type.split('/')[1].toLowerCase() : '';
      const filesize = fileList[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'],trackMsgObj);
        return false;
      } else if (filesize > this.maxFileSize) {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], trackMsgObj);        return false;
      } else if ((i < fileList.length - 1) && (fileList[i].type != fileList[i+1].type)) {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar("Please upload files with same extention/format", 'xy', ['error-snackbar'],trackMsgObj);
        return false;
      }
    }
    return true;    
  }
  setOcrFormValue() {
    if (this.editNameFlag) {
      this.editNameForm.patchValue({
        "firstName": this.ocrResp.fname ? this.ocrResp.fname.trim() : '',
        "lastName": this.ocrResp.lname ? this.ocrResp.lname.trim() : ''
      });
    }
    if (this.neftFlag) {
      this.editNeftForm.patchValue({
        "accNumber": this.ocrResp.accno,
        "accName": this.ocrResp.nameocr,        
        "ifscCode": this.ocrResp.ifsc,
        "bankName": this.ocrResp.bankname,
        "branchName": this.ocrResp.bankbranch
      });
    }
  }
  onBackBtnClick() {
    this.dialogRef.closeAll();
    this.dialogRef.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', data: this.data, disableClose: true });
  }
  closeDialog() {
    if (!this.data.disableClose) {
      this.dialog.closeAll();
    } else if (this.dataService.isCustomLink) {
      this.auth.logout('M');
    }
  }
  goBackBtn(){
    this.dialogRef.closeAll();
  }
  onCancelBtnClick() {
    this.proceedFlag = true;
    this.isOcrUpdate = true;
    this.setOcrFormValue();
  }
  setSubactivityFlag() {
    this.manualDivFlag = this.data.manualDivFlag ? this.data.manualDivFlag : false;
    this.entryFlag = this.data.entryFlag ? this.data.entryFlag : false;
    this.CheckboxFlag = this.data.CheckboxFlag ? this.data.CheckboxFlag : false;
    this.addPermanentAddressFlag = this.data.addPermanentAddressFlag ? this.data.addPermanentAddressFlag : false;
    this.editNameFlag = this.data.editNameFlag ? this.data.editNameFlag : false;
    this.neftFlag = this.data.neftFlag ? this.data.neftFlag : false;
    this.kycFlag = this.data.kycFlag ? this.data.kycFlag : false;
    this.chequeFlag = this.data.chequeFlag ? this.data.chequeFlag : false;
  }
  submitOcrForm() {
    if (this.editNameFlag) {
      this.submitEditNameForm();
    } else if (this.neftFlag) {
      this.submitEditNeftForm();
    } else if (this.kycFlag) {
      this.createKycSR();
    }
  }
  openCamera() {
    if (this.ocrDocCode.valid) {
      this.cameraDialogRef = this.dialogRef.open(CameraComponent, { panelClass: 'camera-dialog', backdropClass: 'camera-dialog-backdrop', data: { ...this.data, maxFiles : 2 }, disableClose: true });
      this.cameraDialogRef.afterClosed().subscribe(imageArray => {
        if (imageArray && imageArray.length > 0) {
          this.openOcrPopup(imageArray);
        }
      });
    } else {
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
      this.message.openSnackBar('Please select a document', 'xy', ['error-snackbar'],trackMsgObj);
    }
  }
  createKycSR() {
    let acc_tag;
    let parentSR;
    let clientId;
    let role;
    if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "surrender")){
      acc_tag = "Y"
      parentSR = this.data.parentSr
      clientId = this.data.clientid
      role = this.data.role
    } else{
      acc_tag = "";
      parentSR = "";
      clientId = this.utils.getClientId()
      role = "Owner"
    }
    const ocrData = this.ocrResp.ocrdocrk && this.isOcrUpdate
      ? { ocrflag: 'Y', ocrdocrk: this.ocrResp.ocrdocrk }
      : { ocrflag: 'N' };
    let reqBody = {
      ...ocrData,
      "aadhaarConsentFlag" : this.accepted ? this.crypto.encryptJson("Y") : undefined,
      "policyno": this.data.policyNo,
      "clientid": clientId,
      "role": role,
      "subactivity": "UPD_KYC",
      "action": "CREATE",
      "payout_acc_tag": acc_tag,
      "parentsrno": parentSR, 
      "linkid": this.linkId,
      "srinput": {
        "contactdtls": {
          "isemailchanged": "N"
        }
      },
      "meetingdetails": {
        "meetingplace": "",
        "communicationtype": "",
        "datetime": ""
      }
    };
    let successMsg = '';
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.srbasicinfo && resp.body.srbasicinfo.srno) {
        this.srNo = resp.body.srbasicinfo.srno;
        if (this.data.id == "surrender" && resp.body.ocrflag == 'N') {
          successMsg = `Service Request for KYC Update (SR No. ${resp.body.srbasicinfo.srno}) has been created and pending for requirement`;
        } else if (this.data.id == "surrender" && resp.body.ocrflag == 'Y') {
          successMsg = `Service Request for KYC Update (SR No. ${resp.body.srbasicinfo.srno}) has been created successfully`;
        } else {
          successMsg = `Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`;
        }
      }
      if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N' && (!this.byteArray || this.byteArray.length == 0)) {
        const srno: string = resp.body.srbasicinfo.srno;
        let surrenderFlag;
        if(this.data.id == "surrender"){
          surrenderFlag = true;
        } else { surrenderFlag = false; }
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
        }
        if((this.data.id != "maturity") && (this.data.id != "moneyBack")){   //&&(this.data.id != "surrender")
        this.dialogRef.open(DocListPopupComponent, {
          panelClass: this.dataService.isVirtualBranch ? 'doc-list-dialog-iframe' : 'doc-list-dialog',
          backdropClass: 'doc-list-dialog-backdrop',
          disableClose: true,
          data: {
            surrenderFlag : surrenderFlag,
            srno: srno,
            subactivity: reqBody.subactivity,
            createSrResp: resp.body,
            disableClose: this.data.disableClose,
            type: this.data.kycId,
            logoutOnClose: this.dataService.isCustomLink && !surrenderFlag,
            unclaimedAmtFlags: this.data.unclaimedAmtFlags,
            unclaimedAmtFlow: this.data.unclaimedAmtFlow,
          }
        });
      }
      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
      this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'],trackMsgObj);
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'N' && this.byteArray && this.byteArray.length != 0) {
        this.getRequirementDetails(resp.body.srbasicinfo.srno);
      } else if (this._httpService.isSuccessResponse(resp) && resp.body && resp.body.ocrflag === 'Y') {
        if (!this.data.disableClose || this.data.unclaimedAmtFlow) {
          this.dialogRef.closeAll();
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'],trackMsgObj);
          if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && this.data.unclaimedAmtFlags.neft_exist=='N') {
            this.dialog.open(MyPoliciesDialogComponent, {
              panelClass: 'my-policies-dialog', disableClose: true,
              data: {
                policyNo: this.data.policyNo,
                unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                disableClose: this.dataService.getIsCustomLink(),
              }
            });
          } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
            this.createSrService.showUnclaimedAmtFinalMsg();
          } else if (!this.data.unclaimedAmtFlow && (this.data.id != "surrender" ||this.data.id != "flic")) { 
              this.getNextBestAction(clientId,'KYC') 
          }
        } else {
          this._httpService.showSuccessMsg(successMsg, true);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
        }
      }
    }),
      catchError((error) => throwError(error));
  }

  getNextBestAction(clientId,type){
    let reqBody = {
      "clientid" : clientId,
      "ACTION" : type
    }
    return this.myAccountService.getNextBestAction(reqBody).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)) {
        if(resp.body.NEFT == "N" || resp.body.PAN == "N" || resp.body.KYC == "N"){
        if(resp.body.NEFT == "Y" || type == "NEFT"){
          this.NeftBtn = true;
        } else{
          this.NeftBtn = false;
        } if(resp.body.PAN == "Y" || type == "PAN"){
          this.PanBtn = true;
        } else{
          this.PanBtn = false;
        } if(resp.body.KYC == "Y" || type == "KYC"){
          this.KycBtn = true;
        } else{
          this.KycBtn = false;
        }
        this.dialog.open(this.srSubmitpopup, { width: '500px', panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true });
      }
      //  else {
      //   this.message.openSnackBar(`Service Request No. ${this.srNo} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar']);
      // }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }), catchError((error) => throwError(error))
  }

  regenPennyDropOtp() {
    clearInterval(this.interval);
    this.timer = this.validTime;
    this.getPennyDropOTP();
  }
  submitPennyDropOtpForm() {
    if (this.editNeftForm && this.editNeftForm.valid) {
      this.validatePennyDropOTP();
    } else {
      this.editNeftForm.markAllAsTouched();
    }
  }
  otpFocusOut(event: any) {
    this.otpFocus = true;
  }
  startOtpTimer() {
    this.timer = this.validTime;
    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.minutes = ((this.timer / 60) | 0);
        if (this.timer >= 59) { this.seconds = 60 - ((this.minutes + 1) * 60 - this.timer) }
        else { this.seconds = this.timer; }
        this.timer--;
      }
      else {
        this.minutes = 0;
        this.seconds = 0;
      }
    }, 1000)
  }
  showPennyDropOtpForm() {
    this.mobileNo = (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.mobileNo : this.userDetails.MOBILE_NUM ? this.userDetails.MOBILE_NUM : 'NA';
    this.emailID = (this.data.id == "beneficiary" || this.data.role == "Beneficiary") ? this.data.emailId : this.userDetails.EMAIL_ID ? this.userDetails.EMAIL_ID : 'NA';
    this.showOtpSection = true;
    this.pennyDropOtpForm.controls.otp.setValue('');
    this.pennyDropOtpForm.controls.otp.markAsUntouched();
    this.startOtpTimer();
  }
  getPennyDropOTP() {
    let reqBody = {
      clientId: this.data.clientid
    };
    return this.createSrService.getPennyDropOTP(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.pennyDropOTPResp = resp.body;
        this.validTime = Number(resp.body.validTime) ? Number(resp.body.validTime) * 60 : 300;
        this.showPennyDropOtpForm();
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
        this.message.openSnackBar("OTP sent successfully", 'xy', ['success-snackbar'],trackMsgObj);
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  validatePennyDropOTP() {
    let reqBody = {
      clientId: this.data.clientid,
      trans_id: this.pennyDropOTPResp.trans_id,
      otpEntered: this.pennyDropOtpForm.controls.otp.value
    };
    return this.createSrService.validatePennyDropOTP(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body.flag === 'Y') {
          this.createPennyDropSR();
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  createPennyDropSR() {
    let type;
    let clientId;
    let acc_tag;
    let parentSR;
    let benId;
    if (this.data.id == "beneficiary") {
      type = "CON_AMS_NNY";
      clientId = this.data.clientid
      benId = this.data.clientid
    } else if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")||(this.data.id == "surrender")){
      if (this.data.role != "Beneficiary"){
      acc_tag = "Y"
      parentSR = this.data.parentSr
      clientId = this.data.clientid
      benId = ""
    } else{
      type = "CON_AMS_NNY";
      clientId = this.data.clientid
      benId = this.data.clientid
      acc_tag = "Y"
      parentSR = this.data.parentSr
    }
    } else {
      acc_tag = "";
      parentSR = ""
      type = ""
      benId = ""
      clientId = this.utils.getClientId()
    }
    const reqBody = {
      policyno: this.data.policyNo,
      clientId: clientId,
      benId : benId,
      role: this.data.role,
      subactivity: 'STND_NEFT',
      action: "CREATE",
      payout_acc_tag: acc_tag,
      parentsrno: parentSR, 
      linkid: this.linkId,
      srinput: {
        ischequepayment: "N",
        samenameflg: this.sameNameFlag,
        contactdtls: {
          isemailchanged: "N"
        },
        accountdtls: {
         //  accname: this.editNeftForm.controls.accName.value,
          acctype: this.editNeftForm.controls.accType.value,
          accno: this.editNeftForm.controls.accNumber.value,
          hdfcbnkflg: this.isHdfcBank() ? "Y" : "N",
          micrcode: "",
          ifsccode: this.editNeftForm.controls.ifscCode.value.toUpperCase()
        },
        typeofproof: "DIG_PROOF"
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
      }
    };

    let successMsg = '';
    return this.createSrService.genPennyDropSR(reqBody).subscribe((resp) => {
      if (!(this.dataService.getIsCustomLink() && this.data.unclaimedAmtFlow)) {
        this.dialogRef.closeAll();
      } 
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.srnum) {
          this.srNo = resp.body.srnum;
          if (this.data.id == "surrender" || this.data.id == "flic") {

            successMsg = `Service Request for NEFT Update (SR No. ${resp.body.srnum}) has been created successfully`;
          } else if (!this.data.unclaimedAmtFlow && (this.data.id != "surrender" || this.data.id != "flic") && (this.data.id != "beneficiary" || this.data.role != "Beneficiary")) {
            this.getNextBestAction(clientId, 'NEFT')
            successMsg = `Service Request for NEFT Update (SR No. ${resp.body.srnum}) has been created successfully`;
          }
           else {
            successMsg = `Service Request No. ${resp.body.srnum} has been created successfully. To track it, please visit the service request section of my account portal.`;
          }
        }
        const srno: string = resp.body.srnum;
        if (this.dataService.getIsCustomLink() && !(this.data.id == "surrender" || this.data.id == "flic") && !this.data.unclaimedAmtFlow) {
          this._httpService.showSuccessMsg(successMsg, true);
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          if(this.data.id == "surrender" || this.data.id == "flic" || this.data.id == 'Beneficiary' || this.data.id == 'beneficiary'){

            this.message.openSnackBar(successMsg, 'xy', ['success-snackbar'], trackMsgObj);
          }
          if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && this.data.unclaimedAmtFlags.KYC_ACTIVE=='N') {
            this.dialog.open(MyPoliciesDialogComponent, {
              panelClass: 'my-policies-dialog', disableClose: true,
              data: {
                policyNo: this.data.policyNo,
                unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                disableClose: this.dataService.getIsCustomLink(),
              }
            });
          } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
            this.createSrService.showUnclaimedAmtFinalMsg();
          }
          //  else if (!this.data.unclaimedAmtFlow) {
          //   this.getNextBestAction(clientId,'NEFT')
          // }
        }
       } else {
        if(this.data.id == "surrender"){
          if (this.data.role =="Beneficiary" && resp.head.errordetails.message == "Account holder name is not matching with owner/beneficiary name, Kindly provide a different bank account number"){
           let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
           this.message.openSnackBar("Account holder name is not matching with beneficiary name, Kindly provide a different bank account number", 'xy', ['error-snackbar'],trackMsgObj);
         } else if(this.data.role != "Beneficiary" && resp.head.errordetails.message == "Account holder name is not matching with owner/beneficiary name, Kindly provide a different bank account number"){
           let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
           this.message.openSnackBar("Account holder name is not matching with owner name, Kindly provide a different bank account number", 'xy', ['error-snackbar'],trackMsgObj);
         } else {
           this._httpService.apiErrorHandler(resp,null);
         }
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
          this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }
      if (this.dataService.getIsCustomLink() && this.data.unclaimedAmtFlow) {
        this.dialogData.close();
        this.dialogSelfRef.close();
      } 
    });
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i]);
      bytearray.push({
        name: files[i].name ? files[i].name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : files[i].name,
        pdfbyte: base64string,
      });
    }
    this.byteArray = [];
    return bytearray;
  }
  isOcrFormValueChanged() {
    if (this.isOcrUpdate) {
      if (this.neftFlag) {
        return !((this.editNeftForm.get('accNumber').value == this.ocrResp.accno)
          && (this.editNeftForm.get('accName').value == this.ocrResp.nameocr)
          && (this.editNeftForm.get('ifscCode').value == this.ocrResp.ifsc)
          && (this.editNeftForm.get('bankName').value == this.ocrResp.bankname)
          && (this.editNeftForm.get('branchName').value == this.ocrResp.bankbranch))
      } else if (this.chequeFlag) {
        return !((this.editChequeForm.get('accNumber').value == this.ocrResp.accno)
          && (this.editChequeForm.get('accName').value == this.ocrResp.nameocr)
          && (this.editChequeForm.get('ifscCode').value == this.ocrResp.ifsc)
          && (this.editChequeForm.get('bankName').value == this.ocrResp.bankname)
          && (this.editChequeForm.get('branchName').value == this.ocrResp.bankbranch))
      }
      if (this.editNameFlag) {
        return !((this.editNameForm.get('firstName').value == this.ocrResp.fname.trim())
          && (this.editNameForm.get('lastName').value == this.ocrResp.lname.trim()))
      }
    } else {
      return true;
    }
  }
  openOfflineAadharMsgPopup() {
    let offlineAadharMsgDialog = this.dialogRef.open(MessagePopupComponent, {
      width: '500px', 
      panelClass: 'message-popup-panel', 
      backdropClass: 'message-popup-backdrop', disableClose: true,
      data: {
        closeIcon: true,
        title: "Offline Aadhaar",
        cancelBtnLabel: "No",
        submitBtnLabel: "Yes",
        mainMessage: "Do you have your Aadhaar information updated?",
        submitAction: "OKYC",
        ocrDocCode: this.ocrDocCode.value
      } 
    });
    offlineAadharMsgDialog.afterClosed().subscribe(result => {
      if (result == "OKYC") {
        this.getOfflineAadharDtls();
      }
    });
  }
  getOfflineAadharDtls() {
    let clientId;
    if(this.data.viewDtls == "benfiAndNominee"){
      clientId = this.data.ComAdd.CLIENTID
    } else if(this.data.viewDtls == "lifeAssured"){
      clientId = this.data.ComAdd.CLIENTID
    } else{
      clientId = this.utils.getClientId()
    }
    let reqBody = {
      "clientId": clientId,
      "eKYC_editRequestPanelID": this.data.type == "Mailing" ? 'communication_address_ocr' : this.data.type == "Permanent" ? 'address_details_ocr' : 'personal_details_ocr',
      "communication_addr_flag": this.data.type == "Mailing" ? 'Y' : 'N',
      "permanent_addr_flag": this.data.type == "Permanent" ? 'Y' : 'N',
    };
    this.createSrService.getOfflineAadharData(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.offlineAadharFormData = JSON.stringify(resp.body);
        setTimeout(() => {
          this.offlineAadharForm.nativeElement.submit();        
        }, 100);
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  getRequirementDetails(srno) {    
    let reqBody = {
      "srno": srno
    }
    return this.createSrService.getRequirementDetails(reqBody).subscribe(async resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.getrequirementdetails) {
          this.requirementDtlsList = resp.body.getrequirementdetails;
          this.requirementDtlsList.forEach((item) => this.isSubmitSuccess.push(false));
          for (let reqmntIndex = 0; reqmntIndex < this.requirementDtlsList.length; reqmntIndex++) {
            if (["CSPS_REQ_015", "CSPS_REQ_006", "CSPS_REQ_007"].includes(this.requirementDtlsList[reqmntIndex].reqcd)) {
              if ((this.ocrDocCode.value != "DOC_056" && this.requirementDtlsList[reqmntIndex].reqcd == "CSPS_REQ_015") || (this.ocrDocCode.value == "DOC_056" && ["CSPS_REQ_006", "CSPS_REQ_007"].includes(this.requirementDtlsList[reqmntIndex].reqcd))) {
                continue;
              }
              this.isUploadSuccess = [];
              for (let i = 0; i < this.byteArray.length; i++) {
                this.isUploadSuccess.push(false);
              }
              for (let docIndex = 0; docIndex < this.byteArray.length; docIndex++) {
                await new Promise(resolve => {
                  this.uploadDocument(this.requirementDtlsList[reqmntIndex], docIndex).subscribe((resp) => {
                    if (this._httpService.isSuccessResponse(resp)) {
                      this.isUploadSuccess[docIndex] = true;
                      if (!this.isUploadSuccess.includes(false)) {
                        this.isSubmitSuccess[reqmntIndex] = true;
                      }
                    } else {
                      let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
                      this._httpService.apiErrorHandler(resp,null,trackMsgObj);
                    }
                    resolve(true);
                  }, () => resolve(false));
                });
              }
            }
          }
          this.loader.show();
          setTimeout(() => {
            this.loader.hide();
            if (!this.isSubmitSuccess.includes(false)) {
              this.submitSR(this.requirementDtlsList[0]);
            } else {
              this.dialogRef.closeAll();
              this.dialogRef.open(DocListPopupComponent, {
                panelClass: this.dataService.isVirtualBranch ? 'doc-list-dialog-iframe' : 'doc-list-dialog',
                backdropClass: 'doc-list-dialog-backdrop',
                disableClose: true,
                data: {
                  srno: srno,
                  disableClose: this.data.disableClose,
                  logoutOnClose: this.dataService.isCustomLink && this.data.id != "surrender" && this.kycFlag,
                  unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                  unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                }
              });
            }
          }, 2000);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        if (!this.data.disableClose) {
          this.dialogRef.closeAll();
        }
      }
    });
  }
  uploadDocument(requirementDtls, docIndex) {
    const reqBody = {
      osvflag: "Received through email or letter.",
      policyno: requirementDtls.policyno,
      srno: requirementDtls.srno,
      clientid: requirementDtls.clientid,
      bytearray: this.byteArray[docIndex].pdfbyte,
      reqcd: requirementDtls.reqcd,
      reqtranskey: requirementDtls.reqtranskey,
      reqstatuscd: requirementDtls.reqstatuscd,
      filenetfldr: requirementDtls.filenetfldr,
      doccd: this.ocrDocCode.value,
      doctype: this.byteArray[docIndex].name.split('.').pop(),
      docname: this.byteArray[docIndex].name ? this.byteArray[docIndex].name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : this.byteArray[docIndex].name,
      docattributelist: [],
    };
    return this.createSrService.documentUpload(reqBody);
  }
  submitSR(requirementDtls) {
    if(this.data.id != "surrender"){
    let acc_tag;
    let parentSR;
    if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")){
      acc_tag = "Y"
      parentSR = this.data.parentSr
    } else{
      acc_tag = "";
      parentSR = ""
    }
    let reqBody = {
      policyno: requirementDtls.policyno,
      clientid: requirementDtls.clientid,
      subactivity: requirementDtls.subissuecd,
      action: "SUBMIT",
      srno: requirementDtls.srno,
      role: requirementDtls.clientrolecd,
      subactivityType: '',
      nachsi: '',
      payout_acc_tag: acc_tag,
      parentsrno: parentSR, 
      linkid: this.linkId,
    };
    this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (!this.data.disableClose) {
        this.dialogRef.closeAll();
      }
      if (this._httpService.isSuccessResponse(resp)) {
        if ((this.data.disableClose || this.dataService.getIsCustomLink()) && !this.data.unclaimedAmtFlow) {
          this._httpService.showSuccessMsg(`Document(s) uploaded successfully for SR number: ${requirementDtls.srno}`, true);
          this.trackUserActions({ 'eventName': `message - Document(s) uploaded successfully for SR number: ${requirementDtls.srno}`,'error_code':'SM' });
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${requirementDtls.srno}`, 'xy', ['success-snackbar'],trackMsgObj);
          if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && (this.data.unclaimedAmtFlags.KYC_ACTIVE=='N' || this.data.unclaimedAmtFlags.neft_exist=='N')) {
            this.dialog.open(MyPoliciesDialogComponent, {
              panelClass: 'my-policies-dialog', disableClose: true,
              data: {
                policyNo: this.data.policyNo,
                unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                disableClose: this.dataService.getIsCustomLink(),
              }
            });
          } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
            this.createSrService.showUnclaimedAmtFinalMsg();
          }
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  } else{
    let surrArray = this.payoutDtls.surReason
    let surrenderReason : String;
    surrenderReason = surrArray.toString();
    let reqBody = {
      "policyno": this.data.policyno,
        "clientid": this.data.clientid,
        "subactivity": "SURR_PROC",
        "action": "SUBMIT",
        "role": "Owner",
        "srNum": this.data.srNum,
        "payeeclientno": this.data.clientid,
        "payeefirstname": this.data.firstName,
        "payeelastname": this.data.lastName,
        "paymentMode": this.payoutDtls.paymentMode,
        "prmpaidnre": "",
        "payeeclientrole": this.data.role,
        "surrReason": surrenderReason,
        "productType": this.payoutDtls.prodType,
        "prodCode": this.payoutDtls.prodCode,
        "surrQuoteGenDt": this.payoutDtls.quoteGenDate,
        "surrVal": this.payoutDtls.surrValue,
        "accountValue": this.payoutDtls.accountValue
    };
    this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (!this.data.disableClose) {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"}
          this.message.openSnackBar(this.utils.getSubmitSRSuccessMsg(requirementDtls.srno, requirementDtls.subissuecd), 'xy', ['success-snackbar'],trackMsgObj);
        } else {
          this._httpService.showSuccessMsg(this.utils.getSubmitSRSuccessMsg(requirementDtls.srno, requirementDtls.subissuecd));
          this.trackUserActions({ 'eventName': `message - ${this.utils.getSubmitSRSuccessMsg(requirementDtls.srno, requirementDtls.subissuecd)}`,'error_code':'SM' });
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,this.data.disableClose,trackMsgObj);
      }
      if (!this.data.disableClose) {
        this.dialogRef.closeAll();
      }
    });

  }
  }

  ocrDocChange(eve) {
    let doc = '';
    if (eve && eve.value === "DOC_006") {
      doc = 'passport'
    } else if (eve && eve.value === "DOC_018") {
      doc = 'aadhaar'
      if((this.data.id == "beneficiary" || this.data.id == "Life Assured") && (this.data.ComAdd.BEN_MOBILENOR == null) && (this.data.ComAdd.BEN_EMAILID == null)){
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar("We need OTP consent for this option. Since beneficiary's contact details not available either update contact details or choose other options",'xy',['error-snackbar'],trackMsgObj);
      }
      else if(this.ocrDocCode.value == 'DOC_018' && (this.data.id == "surrender" && (this.data.role == "Beneficiary" || this.data.role == "Life Assured" || this.data.role == "Appointee")) && (this.data.mobileNo == null) && (this.data.emailId == null)){
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this.message.openSnackBar("We need OTP consent for this option. Since beneficiary's contact details not available either update contact details or choose other options",'xy',['error-snackbar'],trackMsgObj);
      }
      if(this.ocrDocCode.value == "DOC_018" && this.data.id == "surrender" && (this.data.role == "Beneficiary" || this.data.role == "Life Assured" || this.data.role == "Appointee") && (this.userDetails.CLIENT_ID != this.data.clientid)){
        this.showOtpButton = true;
      }
    } else if (eve && eve.value === "DOC_501") {
      doc = 'voter id'
    } else if (eve && eve.value === "DOC_056") {
      doc = 'cheque'
    }
    if (this.data && (this.data.type === 'Mailing' || this.data.type === 'Permanent')) {
      this.trackUserActions({ 'eventName': `Edit ${this.data.type} address - ${doc}` });
    } else if (this.data && this.data.kycFlag == true) {
      this.trackUserActions({ 'eventName': `Edit KYC - ${doc}` });
    }
  }
  onManualFlowClick() {
    if (this.data && (this.data.type === 'Mailing' || this.data.type === 'Permanent')) {
      this.trackUserActions({ 'eventName': `Edit ${this.data.type} address - manual` });
    } else if (this.data && this.data.kycFlag == true) {
      this.trackUserActions({ 'eventName': `Edit KYC - manual` });
    }
  }
  
  trackUserActions(payload: any) {
    if (payload.action) {
      if (this.data && this.data.kycFlag == true) {
        this.utils.trackUserActions({ 'eventName': `Edit KYC - ${payload.action}`, 'flowType': this.trackUserFlowType() });
      } else if (this.data && this.data.neftFlag && this.data.existingAccDtls) {
        this.utils.trackUserActions({ 'eventName': `Edit NEFT details - existing - ${payload.action}`, 'flowType': this.trackUserFlowType() });
      } else if (this.data && this.data.neftFlag && this.data.isPennyDrop) {
        this.utils.trackUserActions({ 'eventName': `Edit NEFT details - penny drop - ${payload.action}`, 'flowType': this.trackUserFlowType() });
      } else if (this.data && this.data.neftFlag && this.isOcrUpdate) {
        this.utils.trackUserActions({ 'eventName': `Edit NEFT details - ocr - ${payload.action}`, 'flowType': this.trackUserFlowType() });
      } else if (this.data && this.data.neftFlag && this.data.type == 'NEFT') {
        this.utils.trackUserActions({ 'eventName': `Edit NEFT details - manual - ${payload.action}`, 'flowType': this.trackUserFlowType() });
      }
    } else {
      this.utils.trackUserActions({ 'flowType': this.trackUserFlowType(), ...payload });
    }
  }
  trackUserFlowType() {
    let flowType = 'my account';
    if (this.data && this.data.role == 'Life Assured') {
      flowType = 'Beneficiaries - LA'
    }
    if (this.data && this.data.role == 'Beneficiary') {
      flowType = 'Beneficiaries'
    }
    return flowType;
  }
  readTnC(event : any){
    this.accepted = event.checked;
  }

  showPswd() {
    if (this.passwordType == 'password') {
      this.passwordType = 'tel';
      this.reveal = true;
    } else {
      this.passwordType = 'password';
      this.reveal = false;
    }
  }

  generateOtp() {       
    let genOtpReqBody = {
      "userId" :  this.crypto.encryptJson(this.utils.getClientId()),
      "clientId" : this.crypto.encryptJson(this.data.clientid),
      "srNo" : this.crypto.encryptJson(this.data.clientid),
      "policyNo" : this.crypto.encryptJson(this.data.policyNo)
    }
    return this.myAccountService.getAadhaarConsentOTP(genOtpReqBody)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.showOtpSectionKYC = true;
          this.otpResp = response.body;
          this.OTPTimerStart();
          this.showOtpButton = false;
           this.message.openSnackBar('OTP sent successfully', 'xy', ['success-snackbar']); 
          //  this.trackUserActionsMsg({ 'eventName': `message - OTP sent successfully` });    
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          if(this.statusMessage) {
            this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
            this.dialogData.close();
          }
        }
      }),
      catchError((error) => throwError(error))
  }

  RegenOTP() {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.generateOtp();
  }

  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        // if (this.minutes == 60) {this.minutes = 0;} }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }

  validateOTP(){

    if(this.timerSec > 0){
      let reqBody = {
        "trans_id" : this.otpResp.trans_id,
        "mobileNo" : this.otpResp.trans_id,
        "clientid" : this.crypto.encryptJson(this.data.clientid),
        "otp" : this.crypto.encryptJson(this.beneficiaryOtpForm.get('otp').value),
        "srNo" : this.crypto.encryptJson(this.data.clientid)
      }
      this.myAccountService.validateAadhaarConsentOTP(reqBody)
            .subscribe((resp) => {
              let response = resp;
          if (this._httpService.isSuccessResponse(response)) {
            clearInterval(this.interval);
            this.submitOcrForm();
          }
          else {
            if(response.head.errordetails && response.head.errordetails.message.includes('incorrect OTP 0 attempts')){
                this.message.openSnackBar("You have Exceeded Max Attempt", 'xy', ['error-snackbar']);
                this.dialogData.close()
            }
            else if(response.head.errordetails && response.head.errordetails.message){
                this.statusMessage = response.head.errordetails.message;
            }
          }
          }
        ),
        catchError((error) => throwError(error))
    } else {
      this.message.openSnackBar("Time exceed to use OTP please generate-OTP", 'xy', ['error-snackbar']);
    }
  }
  updatePAN(){
    this.dialog.closeAll();
    this.router.navigate( ['/my-account'],{ queryParams: { 'action': 'UPD_PAN' }});
    this.dialog.open(MyAccountComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop'});
}
updateKYC(){
  console.log("KYC")
    let reqBody = {
      policyno: this.requirementDtls.policyno,
      subactivity: 'UPD_KYC',
    }
   //pass response value to data;
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop',
               data: { policyNo: this.requirementDtls.policyno, kycFlag: true, entryFlag: true, CheckboxFlag: true, disableClose: this.dataService.isDeepLink} });        
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"VE"}
        this._httpService.apiErrorHandler(resp,this.data.disableClose,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
}
updateNEFT(){
  this.myAccount.ngOnInit();
  this.myAccount.updateNeftDetails('NEFT');
}
fetchDetails(){
  this.trackUserActions({ 'eventName': `Fetch Details` });
  this.dialog.open(UpdateMailingAddressComponent, {
    panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
    data: {
      NEFTup: Object.assign({}, this.data.NEFTup), type: this.data.type, policyNo: this.data.policyNo, role: this.data.role, existingFlag: true,clientid : this.data.clientid,existNeftFlg : this.data.existingAccDtls,RoleName : this.data.id,
      neftFlag: this.data.neftFlag, ocrFetchBtn :true,fetchOcrDetails:true, entryFlag: true, id:this.data.id,parentSr: this.data.parentSr,chequeFlag: this.data.chequeFlag, mobileNo: this.data.mobileNo, emailId: this.data.emailId, unclaimedAmtFlags: this.data.unclaimedAmtFlags, unclaimedAmtFlow: this.data.unclaimedAmtFlow, disableClose: this.dataService.isDeepLink,showFetch:true
    }, 
    disableClose: true
  });
}
 }

