import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { searchBarMap } from 'src/app/utils/search-bar-map';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MyDownloadsService } from 'src/app/services/my-downloads/my-downloads.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-search-proxy',
  templateUrl: './search-proxy.component.html',
  styleUrls: ['./search-proxy.component.css']
})
export class SearchProxyComponent implements OnInit {
  id: string = '';
  searchMap = {};
  policies = [];
  selectedpolicyno: Array<any> = [];
  selectedPrdtname: Array<any> = [];

  constructor(
    public router: Router, 
    public route: ActivatedRoute, 
    public myPoliciesService: MyPoliciesService,
    public myDownloadsService: MyDownloadsService,
    public dataService: DataService,
  ) {
    this.myPoliciesService.getPolicyDetails().subscribe(dtls => {
      this.policies = dtls;
    });
  }

  ngOnInit(): void {
    this.searchMap = searchBarMap;
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      let action = this.searchMap[this.id];
      if (action.tab != 'MEDICAL' && (action.page === 'MY_DOWNLOADS' || this.id === '28')) {  
        let idx = this.dataService.getCarouselPosition();    
        this.selectedPrdtname.push(this.policies[idx]['PRDNAME']);
        this.selectedpolicyno.push(this.policies[idx]['POLICYNO']);
        this.myDownloadsService.setSelectPolicyPrdtName(this.selectedPrdtname, this.selectedpolicyno, null);
      }
      setTimeout(() => this.router.navigate(action['path']), 100);
    }
  }



}
