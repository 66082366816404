<div class="message-popup">
    <div *ngIf="closeIcon" mat-dialog-actions (click)="closeDialog()">
        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            Close<img src="assets/close.svg">
        </div>
    </div>
    <div>
    <div class="message-popup-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">{{title}}</div>
    <div class="popup-content popup-content-height">
        
        <div *ngIf="mainMessage" class="main-message">{{mainMessage}}</div>
        <div *ngIf="mainMessageHtml" class="main-message" [ngClass]="{'nominee-popup-message' : proceedBtn}" [innerHTML]="mainMessageHtml"></div>
        <div *ngIf="subMessage" class="sub-message">{{subMessage}}</div>
        <div *ngIf="data.appointmentData">
            <div class="sub-message name">{{data.appointmentData.title}} {{data.appointmentData.firstname}} {{data.appointmentData.lastname}}</div>
            <div class="message-text">Your current appointment :</div>
            <div class="message-text">{{data.appointmentData.date}} {{data.appointmentData.time}}</div>
            <div class="message-text">{{data.appointmentData.address}}</div>
            
         </div>
         <div class="adhaar-consent-checkbox" *ngIf="this.data.ocrDocCode == 'DOC_018'">
            <form [formGroup]="terms">
                <mat-checkbox color = "primary" (change)="readTnC($event)" class="checkbox-text" disableRipple="true" formControlName="accept">
                        I agree to <a href="/my-atnc" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </mat-checkbox>
            </form>
        </div>
        <div class="button-div flex">
            <button *ngIf="cancelBtnLabel" matButton class="close-button" (click)="onCancel()">{{cancelBtnLabel}}</button>
            <button *ngIf="submitBtnLabel" matButton class="save-button" (click)="onSubmit()" [disabled]="this.data.ocrDocCode == 'DOC_018' && !getAccept">{{submitBtnLabel}}</button>

            <button *ngIf="proceedBtn" matButton class="save-button proceed" (click)="proceedAction()" >{{proceedBtn}}</button>
            <button *ngIf="data.onlinePayment" matButton class="close-button newBtn" (click)="redirect('onlinePayment')">{{data.onlinePayment}}</button>
            <button *ngIf="data.reEnter" matButton class="save-button newBtn" (click)="redirect('reEnter')">{{data.reEnter}}</button>
            <button *ngIf="data.branchLocator" matButton class="close-button newBtn" (click)="redirect('branchLocator')">{{data.branchLocator}}</button>

        </div>
    </div>
</div>
<div>

</div>
</div>