import { Injectable } from '@angular/core';
import { LoaderService } from '../loader/loader.service';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { DataService } from '../data/data.service';
import { SessionDialogComponent } from 'src/app/modules/login/session-dialog/session-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from 'src/app/utils/common-functions';
import { SessionTimerService } from '../session-timer/session-timer.service';

 @Injectable({
  providedIn: 'root'
}) 
export class LoaderInterceptorService implements HttpInterceptor {

static requestCount = 0;
clearTime = null;

  constructor(private loader: LoaderService, public utils: Utils, public dataService: DataService, public dialog : MatDialog, public timer$ : SessionTimerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    LoaderInterceptorService.requestCount += 1;
    this.loader.show();
    let url = req.url.split('/');
    let loginResp = this.dataService.getLoginResp();
    if(this.utils.isUserAuthenticated() && url[url.length - 1] != 'logout'  && (loginResp != null && loginResp.SOURCE != null && loginResp.SOURCE == "CUSTOMERPORTAL"))
      this.timer$.setSessionEndTime();
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          LoaderInterceptorService.requestCount -= 1;
          LoaderInterceptorService.requestCount = LoaderInterceptorService.requestCount < 0 ? 0 : LoaderInterceptorService.requestCount;
          if (LoaderInterceptorService.requestCount === 0) {
            this.loader.hide();
          }
        }
      },
      (error: any) => {
        LoaderInterceptorService.requestCount = 0;
        this.loader.hide();
      }),finalize(() => {
        if(LoaderInterceptorService.requestCount == 0){
          let loginResp = this.dataService.getLoginResp();
          if (this.utils.isUserAuthenticated() && url[url.length - 1] != 'logout' && (loginResp != null && loginResp.SOURCE != null && loginResp.SOURCE == "CUSTOMERPORTAL")) {
            this.timer$.startTimer();
          }        
        }
      })
    );
  }

  startIdleTimer(){
    this.clearTime = setTimeout(() => {
      if(this.utils.isUserAuthenticated()){
        this.dialog.open(SessionDialogComponent, {
          panelClass: 'my-policies-dialog',
          disableClose: true,
          hasBackdrop : true, 
          position : { top :"20px"},
          backdropClass : 'logout-backdrop',
        })
      }
    }, 750000);
  }
}
