import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser'



@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.css']
})
export class PdfViewerDialogComponent implements OnInit {
  
  pdfpath:any

  constructor(@Inject(MAT_DIALOG_DATA) public data,  public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
   public dialog: MatDialog,
   private sanitizer: DomSanitizer) { }
   pdfUrl(url:string):SafeUrl{
     return this.sanitizer.bypassSecurityTrustUrl(url)
   }

   
  ngOnInit(): void {
    if(this.data.confirmPopup == "pfdPopup"){
      this.pdfpath = this.pdfUrl(this.data.displayDtls);
    }
  }
 
  closePopup() {
    this.dialog.closeAll();
  }
  windowClose(){
    window.close();
  }
}
