import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { Utils } from 'src/app/utils/common-functions';
import { HttpOptions } from 'src/app/utils/app-data.model';

@Component({
  selector: 'app-virtual-branch-redirect',
  templateUrl: './virtual-branch-redirect.component.html',
  styleUrls: ['./virtual-branch-redirect.component.css']
})
export class VirtualBranchRedirectComponent implements OnInit {
  subactivity: string = '';
  policyNo: string = '';
  encryptedData: string = '';
  virtualBranchDtls = {
    action: null,
    policyNo: null,
    source: null
  };
  options: HttpOptions = {
    observe: 'response' as const
  }

  constructor(public route: ActivatedRoute, public router: Router, public myPoliciesService: MyPoliciesService, public dataService: DataService, public httpService: HttpService, public crypto: EncryptDecryptService, public utils: Utils) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.httpService.reinit().subscribe(resp => {
        if (this.httpService.isSuccessResponse(resp)) {
          this.dataService.setLoginResp(resp.body);
          this.encryptedData = this.route.snapshot.queryParamMap.get('id');
          if (this.encryptedData) {
            this.encryptedData = decodeURIComponent(this.encryptedData);
            let virtualBranchData = this.crypto.decryptString(this.encryptedData);
            if (virtualBranchData) {
              let virtualBranchDataArr = virtualBranchData.split('|');
              this.virtualBranchDtls.source = virtualBranchDataArr[0];
              this.virtualBranchDtls.action = virtualBranchDataArr[1];
              this.virtualBranchDtls.policyNo = virtualBranchDataArr[2];
            }
          }
          if (this.virtualBranchDtls.source == "VIRTUAL_BRANCH") {
            this.subactivity = this.virtualBranchDtls.action
            this.policyNo = this.virtualBranchDtls.policyNo
            this.dataService.setIsVirtualBranch(true);
            this.dataService.setVirtualBranchDtls(this.virtualBranchDtls);
            if (["UPD_KYC", "CHNG_NAME", "CHNG_CON_NUM"].includes(this.subactivity)) {
              this.router.navigate(['/my-account']);
            } else if (this.subactivity == "FUND_SWT" || this.subactivity == "PREMIUM_REDRN") {
              if (this.policyNo) {
                this.getPolicyList();
              }
            }
          }          
        } else {
          this.httpService.apiErrorHandler(resp);
        }
      })
    }, 100);
  }
  getPolicyList() {
    this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
      if (policyCardDtls && policyCardDtls.length > 0) {
        let policyIndex = policyCardDtls.findIndex((item) => item.POLICYNO.replace(/\s+/g, '') == this.policyNo);
        if (policyIndex >= 0) {
          this.dataService.setCarouselPosition(policyIndex);
          if (this.subactivity == "FUND_SWT") {
            this.router.navigate(['/my-policies', 'switch-funds']);
          } else if (this.subactivity == "PREMIUM_REDRN") {
            this.router.navigate(['/my-policies', 'premium-redirection']);
          }
        }
      }
    });
  }

}
