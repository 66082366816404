<div class="reset-pwd-page">
    <div class="reset-wrapper">
        <mat-card class="reset-pwd-card">
            <div class="card-heading">
                    <span *ngIf="!isLoggedIn">Forgot Password</span>
                    <span *ngIf="isLoggedIn">Change Password</span>
            </div>
            <div class="reset-content-wrapper flex">
                <div class="left-section">
                    <form [formGroup]="resetPwdForm" (ngSubmit)="submitResetPwdForm()">
                            <div class="form-label" *ngIf="isLoggedIn && currentPasswordFlag">Current Password</div>   
                            <mat-form-field *ngIf="isLoggedIn && currentPasswordFlag" floatLabel = "never" class="form-field first-field" [ngClass]="{'password-field': isCurrentMasked,'error-field': (currentFocus && validation.current.errors?.required) || (currentFocus && validation.current.errors?.pattern)}">
                                <mat-placeholder>Please enter your Current Password</mat-placeholder>
                                <input formControlName="current" *ngIf="!clientSecureLoginCheck" type="{{oldPasswordType}}" #current matInput (focusout)="currentFocusOut($event)" (keypress)="clearError()">
                                <input formControlName="current" *ngIf="clientSecureLoginCheck" type="{{oldPasswordType}}" matKeyboard #current matInput (focusout)="currentFocusOut($event)" (keypress)="clearError()">
                                <img mat-suffix src="assets/eye_closed.svg" class="eye-img cursor-point" #img (click)="toggleCurrentInputType(img)">
                                <mat-error class="error-message" *ngIf="(currentFocus && validation.current.errors?.required)">
                                    This field is required.
                                </mat-error>
                                <mat-error class="error-message" *ngIf="(currentFocus && validation.first.errors?.pattern)">
                                    Invalid Password.
                                </mat-error>
                            </mat-form-field>
                        <div class="form-label">New Password</div>   
                        <mat-form-field floatLabel = "never" class="form-field first-field" [ngClass]="{'password-field': isFirstMasked,'error-field': (firstFocus && validation.first.errors?.required) || (firstFocus && validation.first.errors?.errorMsg)}">
                            <mat-placeholder>Please enter your New Password</mat-placeholder>
                            <input formControlName="first" *ngIf="!clientSecureLoginCheck" type="{{currentPasswordType}}" #first matInput (focusout)="firstFocusOut($event)" (keypress)="clearError()" (paste)="blockPaste($event)">
                            <input formControlName="first"  *ngIf="clientSecureLoginCheck" type="{{currentPasswordType}}" matKeyboard #first matInput (focusout)="firstFocusOut($event)" (keypress)="clearError()" (paste)="blockPaste($event)">
                            <img mat-suffix src="assets/eye_closed.svg" class="eye-img cursor-point" #img (click)="toggleFirstInputType(img)">
                            <mat-error class="error-message" *ngIf="(firstFocus && validation.first.errors?.required)">
                                This field is required.
                            </mat-error>
                            <mat-error class="error-message" *ngIf="(firstFocus && validation.first.errors?.errorMsg)">
                                {{validation.first.errors?.errorMsg}}
                            </mat-error>
                        </mat-form-field>
                        <div class="form-label">Re-enter Password</div>   
                        <mat-form-field floatLabel = "never" class="form-field" [ngClass]="{'password-field': isSecondMasked,'error-field': (secondFocus && validation.second.errors?.required) || (secondFocus && validation.second.errors?.errorMsg)}">
                            <mat-placeholder>Please Re-enter your Password</mat-placeholder>
                            <input formControlName="second" *ngIf="!clientSecureLoginCheck" type="{{confirmPasswordType}}" #second matInput (focusout)="secondFocusOut($event)" (keypress)="clearError()" (paste)="blockPaste($event)">
                            <input formControlName="second"  *ngIf="clientSecureLoginCheck" type="{{confirmPasswordType}}" matKeyboard #second matInput (focusout)="secondFocusOut($event)" (keypress)="clearError()" (paste)="blockPaste($event)">
                            <img mat-suffix src="assets/eye_closed.svg" class="eye-img cursor-point" #image (click)="toggleSecInputType(image)">
                            <mat-error class="error-message" *ngIf="(secondFocus && validation.second.errors?.required)">
                                This field is required.
                            </mat-error>
                            <mat-error class="error-message" *ngIf="(secondFocus && validation.second.errors?.errorMsg)">
                                {{validation.second.errors?.errorMsg}}
                            </mat-error>
                        </mat-form-field>
                        <div class="checkbox-virtual-div">
                            <mat-checkbox (change)="showKeyboard($event)" class="checkbox-virtual no-mobile">Use virtual keyboard</mat-checkbox>
                        </div>
                            <div [innerHTML]="statusMessage" class="status-message"></div>
                            <button type="submit" class="reset-pwd-btn" [disabled]="!resetPwdForm.valid">Submit</button> 
                            <button class="reset-pwd-btn" (click)="redirectToLogin()">Cancel</button>
                    </form>
                </div>
                <div class="right-section">
                    <div class="list-heading">Instructions to set up New Password</div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>Must be different from user id</span>
                    </div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>must to contain 8 - 12 character, including one letter and one number</span>
                    </div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>must include atleast one upper case letter</span>
                    </div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>Must include special character except ( <>';*\" )</span>
                    </div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>Your Password is case sensitive</span>
                    </div>
                    <div class="list-item flex">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>Do not use last used three passwords</span>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

