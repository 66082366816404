<div class="sr-list-screen">
    <div class="capture-photo-padding">
        <mat-card class="capture-photo" *ngIf="photoCapture">
            <div class="photo-text">
                Photo Capture
            </div>
            <div class="button-div">
                <button mat-button class="submit-button" (click)="capturePhoto()">Capture Photo</button>
            </div>
        </mat-card>
        <mat-card class="sr-list-card" *ngIf="!photoCapture">
            <div class="table-section" *ngIf="!noRecords">
                <div class="sr-request">SR Document Upload: </div>
                <div class="table-wrapper">
                    <table mat-table matSort [dataSource]="dataSource" class="sr-table">
                        <ng-container matColumnDef="Sr_No">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SR.No</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.index}}</td>
                        </ng-container>
                        <ng-container matColumnDef="SR_Number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SR Number</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.SR_NO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Policy_No">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.POLICY_NO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Request_type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Type</th>
                                <td mat-cell *matCellDef="let element">{{element.REQ_TYPE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Date_Logged">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SR Created Date</th>
                            <td mat-cell *matCellDef="let element">{{element.REQ_DATE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Upload">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload</th>
                            <td mat-cell *matCellDef="let element"><button class="upload-button"(click)="getAgentDocList(element.SR_NO)">Upload</button></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
            <div class="no-records" *ngIf="noRecords">
                No Records Found
            </div>
        </mat-card>
    </div>
</div>