import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';


@Injectable({
  providedIn: 'root',
})
export class loginService {

  constructor(public _httpService: HttpService, public utils: Utils,public crypto: EncryptDecryptService) { }

  getLoginDetails(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.cp_password = this.crypto.encryptJson(reqBody.cp_password);
    return this._httpService.post(serviceURL.login_url, this.utils.createRequest(userid, 'CUST_LOGIN', reqBody,))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getUserLoginDetails(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.password = this.crypto.encryptJson(reqBody.password);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    if (reqBody.countryCode) {
      reqBody.countryCode = this.crypto.encryptJson(reqBody.countryCode);
    }
    return this._httpService.post(serviceURL.custLogin_url, this.utils.createRequest(userid, 'CUST_LOGIN', reqBody,))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getAdmLoginDetails(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.login_url, this.utils.createRequest(userid, 'CUST_LOGIN', reqBody, "cpadmin"))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getCreateuserDetails(reqBody) {
    return this._httpService.post(serviceURL.createUser_url, this.utils.createRequest(reqBody.policyno, 'CreateUser', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  genOtp(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.genOtp_url, this.utils.createRequest(userid, 'GenerateLoginOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  sendOTP(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    if (reqBody.countryCode) {
      reqBody.countryCode = this.crypto.encryptJson(reqBody.countryCode);
    }
    return this._httpService.post(serviceURL.sendOTP_url, this.utils.createRequest(userid, 'GenerateLoginOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  genRegOtp(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.genOtp_url, this.utils.createRequest(userid, 'generateotp_reg', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  genOtpfgt(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.genOtp_url, this.utils.createRequest(userid, 'generateotp_fpwd', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  changePassword(reqBody) {
    return this._httpService.post(serviceURL.changePassword_url, this.utils.createRequest(this.utils.getClientId(), 'changePassword', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  setPassword(reqBody) {
    return this._httpService.post(serviceURL.setPassword_url, this.utils.createRequest(reqBody.userRk.toString(), 'setPassword', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  SetNewPassword(reqBody) {
    return this._httpService.post(serviceURL.setPassword_url, this.utils.createRequest(reqBody.userRk.toString(), 'SetNewPassword', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  ResetPassword(reqBody) {
    return this._httpService.post(serviceURL.setPassword_url, this.utils.createRequest(reqBody.clientID, 'ResetPassword', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateUser(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.validateUser_url, this.utils.createRequest(userid, 'Validate_User', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  userValidate(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    if (reqBody.countryCode) {
      reqBody.countryCode = this.crypto.encryptJson(reqBody.countryCode);
    }
    return this._httpService.post(serviceURL.userValidate_url, this.utils.createRequest(userid, 'Validate_User', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateUserfgt(reqBody) {
    let userid = reqBody.cp_loginid;
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    return this._httpService.post(serviceURL.validateUser_url, this.utils.createRequest(userid, 'Validate_User_fpwd', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateRegOtp(reqBody, userID) {
    return this._httpService.post(serviceURL.validateOtp_url, this.utils.createRequest(userID, 'validateotp_reg', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateOtpfgt(reqBody, userID) {
    return this._httpService.post(serviceURL.validateOtp_url, this.utils.createRequest(userID, 'validateotp_fpwd', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateOtp(reqBody, userID) {
    return this._httpService.post(serviceURL.validateOtp_url, this.utils.createRequest(userID, 'validateotp', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getscreatquestion(reqBody) {
    return this._httpService.post(serviceURL.getSecretQ_url, this.utils.createRequest(reqBody.clientId, 'ScreatQuestion', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validatescreatquestion(reqBody) {
    return this._httpService.post(serviceURL.validateSecretQ_url, this.utils.createRequest(reqBody.clientId, 'ValidateScreatQuestion', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  masterSecretQues(reqBody) {
    return this._httpService.post(serviceURL.masterSecretQues,this.utils.createRequest('', 'getSecretQues', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  submitSecretQues(reqBody) {
    return this._httpService.post(serviceURL.submitSecretQues, this.utils.createRequest(this.utils.getClientId(), 'submitScreatQns_settings', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  submitRegSecretQues(reqBody) {
    return this._httpService.post(serviceURL.submitSecretQues, this.utils.createRequest(reqBody.user_rk.toString(), 'submitScreatQns_reg', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getVocQuestions(reqBody) {
    return this._httpService.post(serviceURL.getVocQues_url, this.utils.createRequest(reqBody.userid.toString(), 'loadvoc', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getUserFeedback(reqBody) {
    return this._httpService.post(serviceURL.submitvocfeedback_url, this.utils.createRequest(reqBody.userid.toString(), 'fund', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  validateLoginOtp(reqBody) {
    let userid = reqBody.cp_loginid
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    if (reqBody.countryCode) {
      reqBody.countryCode = this.crypto.encryptJson(reqBody.countryCode);
    }
    return this._httpService.post(serviceURL.validateOtpLogin_url,this.utils.createRequest(userid, 'otpValidation', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  generateCheckSum(reqBody) {
    let userid = reqBody.cp_loginid
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.mobileno = this.crypto.encryptJson(reqBody.mobileno)
    return this._httpService.post(serviceURL.calcCheckSum_url,this.utils.createRequest(userid, 'CalculateCheckSum', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getDedupeResp(reqBody) {
    let userid = reqBody.cp_loginid
    reqBody.cp_loginid = this.crypto.encryptJson(reqBody.cp_loginid);
    reqBody.dateofbirth = this.crypto.encryptJson(reqBody.dateofbirth);
    reqBody.cp_login_mode = this.crypto.encryptJson(reqBody.cp_login_mode);
    if (reqBody.countryCode) {
      reqBody.countryCode = this.crypto.encryptJson(reqBody.countryCode);
    }
    return this._httpService.post(serviceURL.dedupe_url,this.utils.createRequest(userid, 'getDeduperesponse', reqBody))
    .pipe(
      map((resp) => {
        return resp
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  submitVocRatingFeedback(reqBody) {
    return this._httpService.post(serviceURL.vocSubmit_url, this.utils.createRequest('', 'VocInsert', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  submitVocQuestionnaire(reqBody) {
    return this._httpService.post(serviceURL.vocSubmit_url, this.utils.createRequest('', 'VocUpdate', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  vocFeedbackSubmit(reqBody) {
    return this._httpService.post(serviceURL.vocFeedbackSubmit_url, this.utils.createRequest('', 'VocFeedbackSubmit', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  setLogoutMode(reqBody) {
    return this._httpService.post(serviceURL.logoutmode_url, this.utils.createRequest('', 'CUST_LOGIN', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getCountryCode() {
    return this._httpService.get(serviceURL.countryCode)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
}
