import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    /**
     * @private { Subject<LoaderState> } loaderSubject emits
     */
    private loaderSubject = new Subject<LoaderState>();

    loaderState = this.loaderSubject.asObservable();

    constructor() {}

    /**
     * @function show emits loader-state as true which displays spinner/loader
     */
    show() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }

    /**
     * @function hide emits loader-state as false which hides spinner/loader
     */
    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
}

/**
 * LoaderState Interface Definition
 * @typedef  { Interface } LoaderState custom data type to describe the state of the loader/spinner
 * @property { boolean } show If true loader will be displayed, otherwise loader will be hidden
 *
 */
export interface LoaderState {
    show: boolean;
}
