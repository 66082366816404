<div class="generic-doc-upload" [ngClass]="{'virtual-branch': data.disableClose}">
        <div *ngIf="!data.disableClose && !data.hideClose" mat-dialog-actions>
            <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                <div class="no-mobile">
                    Close<img src="assets/close.svg" class="close-icon">
                </div>
                <img src="assets/cross_circle_blue.svg" class="mobile">
            </div>
        </div>
        <div class="generic-doc-upload-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Upload Document</div>
        <div class="generic-doc-upload-content popup-content-height">                    
            <div class="overflow-container">
                <div class="top-section">
                    <div class="proof-type">Generic document upload</div>
                    <div class="doc-type-select-wrapper">
                        <div class="form-label">Document Type</div>
                        <mat-form-field floatLabel="never" class="doc-list-form-field select-field"
                            [ngClass]="{'error-field': docTypeFocusOut && docTypeDropdown.hasError('required')}">
                            <mat-placeholder>Please select a document</mat-placeholder>
                            <mat-select disableRipple disableOptionCentering class="doc-type-select"
                                [formControl]="docTypeDropdown" (focusout)="OnDocTypeFocusOut()">
                                <mat-option *ngFor="let item of docList" [value]="item.code" (click)="onDocSelect(item.code)">
                                    {{item.label}}</mat-option>
                            </mat-select>
                            <img mat-suffix src="assets/angle_down.svg">
                            <mat-error *ngIf="docTypeFocusOut && docTypeDropdown.hasError('required')"
                                class="error-message">
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <form (ngSubmit)="onSubmit()" autocomplete="off">                    
                    <div *ngIf="showUploadBox" class="doc-upload-flex flex">
                        <div *ngIf="!showCamera" class="doc-upload-wrapper">
                            <app-drag-drop (fileDropped)="onDocumentLoad($event)"></app-drag-drop>
                            <div class="no-mobile">
                                <div class="upload-file-info flex">
                                    <span>Accepted File types: .jpeg, .png, .pdf, .bmp</span>
                                    <span>Maximum File Size: {{maxFileSize}} MB</span>
                                </div>
                            </div>
                            <div class="mobile">
                                <div class="upload-file-info flex">
                                    <span>File types: .jpeg, .png, .pdf, .bmp</span>
                                    <span>Max. File Size: {{maxFileSize}} MB</span>
                                </div>
                            </div>
                            <div *ngIf="uploadedFileList && uploadedFileList.length > 0 && showUploadBox" class="uploaded-file-name">
                                <div *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</div>
                            </div>
                        </div>
                        <div *ngIf="showCamera" class="doc-upload-wrapper">
                            <div class="upload-file">
                                <div (click)="openCamera()" class="file-input-content flex">
                                    <span class="input-content-span-one">Take a photo</span>
                                    <img class="camera-image" src="assets/camera.svg">
                                    <div class="file-upload-mobile">Take a photo</div>
                                </div>
                            </div>                       
                            <div *ngIf="uploadedFileList && uploadedFileList.length > 0 && showUploadBox" class="uploaded-file-name">
                                <div *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="docTypeDropdown.valid && showUploadBox && isInstaServ" class="osv-checkbox-wrapper">
                        <mat-radio-group class="alloc-radio-group flex" formControlName="osvControl">
                            <mat-radio-button class="alloc-radio-sec" (change)="onOsvChange($event)"
                                *ngFor="let item of osvOpts"
                                [value]="item.code">
                                <span class="radio-sec-label">{{item.label}}</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="button-section flex">
                        <button *ngIf="!data.disableClose" mat-button class="cancel-button" type="button" (click)="onCancel()">Cancel</button>
                        <button mat-button class="submit-button" type="button" [disabled]="!docTypeDropdown.valid || (isInstaServ && (!osvControl.valid || uploadedFileList.length == 0))" (click)="onSubmit()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <ng-template #checklistPopup>
            <div class="checklist-popup">
                <!-- <div mat-dialog-actions class="mat-dialog-actions">
                    <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                        Close
                        <img src="assets/close.svg">
                    </div>
                </div> -->
                <div class="checklist-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">
                    Checklist
                </div>
                <div class="checklist-popup-content popup-content-height">
                    <div class="list-item flex" *ngFor="let item of checklist">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>{{item}}</span>
                    </div>
                    <div class="checklist-popup-btns">
                        <button class="close-button" type="button" (click)="closeChecklist()">Cancel</button>
                        <button class="save-button" type="button" (click)="submitUploadDocForm()">Confirm</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
