import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { DataService } from 'src/app/services/data/data.service';
import { Utils } from 'src/app/utils/common-functions';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ExistingNeftComponent } from '../../shared/components/existing-neft/existing-neft.component';
import { UpdateMailingAddressComponent } from '../../my-account/update-mailing-address/update-mailing-address.component';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';

@Component({
  selector: 'app-my-policies-dialog',
  templateUrl: './my-policies-dialog.component.html',
  styleUrls: ['./my-policies-dialog.component.css']
})
export class MyPoliciesDialogComponent implements OnInit {
  policyPopupData = [];
  isHNIUSer:boolean = false;
  userDetails: any = {};
  confirmBank = false;
  confirmContact = false;
  updateNudge:boolean = false
  confirmAddress = false;
  ClientAddress: any = {            
    "CITY": "",
    "COUNTRY": "",
    "HOUSENUMBER": "",
    "LANDMARK": "",
    "PINCODE": "",
    "STATE": "",
    "STREET": ""
  };
  NEFTDetail: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data, public utils: Utils, public _httpService: HttpService, public myPoliciesService: MyPoliciesService, public dataService: DataService, public router: Router, public dialogRef: MatDialogRef<MyPoliciesDialogComponent>, public dialog: MatDialog, public createSrService: CreateSRService, public myAccountService: MyAccountService) { }

   
  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    for(let item of this.data.dtls){
      if(item.flag  == "aps" || item.flag == "neftexp" || item.flag == "panexp" ||item.flag == "mobemailexp" ){
        this.updateNudge = true;
        break;
      }
      else if(!this.updateNudge && this.data.confirmPopup == "ADDRESS"){
        this.confirmAddress = this.data.confirmPopup == "ADDRESS" ? true : false;
        this.ClientAddress = this.data.displayDtls;
        break;
      }
       else if(!this.updateNudge && this.data.confirmPopup == "BANK"){
          this.confirmBank = this.data.confirmPopup == "BANK" ? true : false;
          break;
        }else{
          if(!this.updateNudge && this.data.confirmPopup == "CONTACT"){
          this.confirmContact = this.data.confirmPopup == "CONTACT" ? true : false;
          }
      }
    }
    
    // else{
    // this.setPopupData();
    // }
    if(this.updateNudge){
      this.setPopupData();
    }
  }
  setPopupData() {
    const expInfo = this.myPoliciesService.getExpInfoDtls();
    let expDtls= [];
    for (let obj of this.data.dtls) {
      if (obj['priority']) {
        expDtls.push(obj);
      }
    }
    expDtls.sort(this.myPoliciesService.compareToasterObj);
    for(let obj of expDtls) {
      // if (this.data.showKycExpired) {
      //   if (obj["flag"] == "kycexp") {
        if( obj["flag"] == "aps" || obj["flag"] == "neftexp" || obj["flag"] == "panexp" || obj["flag"] == "mobemailexp"){
           this.policyPopupData.push(expInfo[obj['flag']]);
        }
         
      //   }
      // } else {
      //   if (obj["flag"] != "kycexp") {
      //     this.policyPopupData.push(expInfo[obj['flag']]);
      //   }
      // }
    }
  }
  confirmedNeft(){
    let reqData = {
      clientid: this.utils.getClientId()
    }
    return this.myPoliciesService.neftConfirmation(reqData).subscribe((resp) =>{
         this.closePopup();
         console.log(resp);
         
      }),
      catchError((error) => throwError(error));
  }
  confirmedContact(){
    let reqData = {
      clientid: this.utils.getClientId()
    }
   return this.myPoliciesService.contactConfirmation(reqData).subscribe((resp) =>{
    this.closePopup();
    console.log(resp);
 }),
 catchError((error) => throwError(error));
  }

  onUpdateBtnClick(targetPath: any) {
  if(targetPath.tab == 'APS'){
      this.apsToasterRedirect(targetPath);
      this.closePopup();
    }
    else{
      this.closePopup();
      this.router.navigate(targetPath.path, {queryParams: {tab: targetPath.tab}});
    }
  }
  apsToasterRedirect(targetPath) {
    let reqBody = {
     "clientid": this.utils.getClientId(),
     "policyno": "",
     "functionality": "APS_NUDGE",
   };
   this.myPoliciesService.nudgeRedirectUpdate(reqBody).subscribe((resp) => {
     if (this._httpService.isSuccessResponse(resp)) {
      this.router.navigate(targetPath.path, {queryParams: {tab: targetPath.tab}});
     } else {
       this._httpService.apiErrorHandler(resp);
     }
   }),
     catchError((error) => throwError(error)); 
}
  closePopup() {
    this.dialog.closeAll();
  }
  updateBank() {
    this.router.navigate(['/my-account'], {queryParams: {tab: 'bank'}});
    this.closePopup();
  }
  updateContact() {
    this.router.navigate(['/my-account'], {queryParams: {tab: 'contact'}});
    this.closePopup();
  }
  confirmedAddress(){
    let reqData = {
      clientid: this.utils.getClientId()
    }
    return this.myPoliciesService.addressConfirmation(reqData).subscribe((resp) =>{
      this.closePopup();
      
    }),
    catchError((error) => throwError(error));
  }
  updateAddress(){
      this.router.navigate(['/my-account'], {queryParams: {tab: 'contact'}});
      this.closePopup();
  }

  onUnclaimedAmtNEFTUpdate() {
    this.validateNEFT();
  }
  onUnclaimedAmtKYCUpdate() {
    this.validateKYC();
  }
  validateNEFT() {
    let reqBody = {
      policyno: this.data.policyNo,
      subactivity: "STND_NEFT"
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.getNEFTDetails();
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"}
        this._httpService.apiErrorHandler(resp, null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getPennyDropCount() {
    let reqBody = {
      clientId: this.utils.getClientId()
    };
    return this.createSrService.getPennyDropCount(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.dialog.open(ExistingNeftComponent, {
          panelClass: 'existing-neft-popup', backdropClass: 'existing-neft-popup-backdrop',
          data: {
            NEFTup: this.NEFTDetail, ECSup: {}, type: 'NEFT', clientid: this.utils.getClientId(), policyNo: this.data.policyNo, role: "Owner",
            neftFlag: true, entryFlag: true, isPennyDrop: resp.body.pennyStatus, disableClose: this.dataService.isDeepLink, unclaimedAmtFlow: this.data.unclaimedAmtFlow,
          },
          disableClose: true
        });
      } else {
        //let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp, null);
      }
    });
  }
  startKycJourney() {
    this.dialog.open(UpdateMailingAddressComponent, { 
      panelClass: 'app-update-mailing-address', 
      backdropClass: 'edit-communication-backdrop', 
      disableClose: true,
      data: { policyNo: this.data.policyNo, kycFlag: true, entryFlag: true, CheckboxFlag: true, disableClose: this.dataService.isDeepLink, unclaimedAmtFlow: this.data.unclaimedAmtFlow,  },
    });        
  }
  validateKYC() {
    let reqBody = {
      policyno: this.data.policyNo,
      subactivity: 'UPD_KYC'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.startKycJourney();
      } else {  
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getNEFTDetails() {
    let getDetails = {
      clientid: this.utils.getClientId(),
      policyno: this.data.policyNo,
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(getDetails).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            this.NEFTDetail = response.body[0];
          }
        } else {  
          let trackMsgObj ={'flowType': 'my account',"error_code":"UEE"}                 
          this._httpService.apiErrorHandler(response,null,trackMsgObj); 
        }
        this.getPennyDropCount();
      }),
      catchError((error) => throwError(error));
  }
  startPANJourney() {
    this.dialog.closeAll();
    this.router.navigate(['/my-account'], { queryParams: { 'action': 'UPD_PAN' }});
  }

}
