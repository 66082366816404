import { Component, OnInit, Inject } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyAccountService } from 'src/app/services/my-account/my-account.service'
import { HttpService } from 'src/app/services/http/http.service';
import { throwError, Observable } from 'rxjs';
import { Utils } from "../../../utils/common-functions";
import { catchError, startWith, map, debounceTime } from 'rxjs/operators';
import { DataService } from 'src/app/services/data/data.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { createSrReqBody } from 'src/app/utils/create-sr-data-modal';
import { MessageService } from 'src/app/services/message/message.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import {SrPopupComponent} from '../../shared/components/sr-popup/sr-popup.component'
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { loginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-edit-communication-details',
  templateUrl: './edit-communication-details.component.html',
  styleUrls: ['./edit-communication-details.component.css']
})
export class EditCommunicationDetailsComponent implements OnInit {
  constructor(public router: Router, public dialog: MatDialog, public _httpService: HttpService,
    public myAccountService: MyAccountService, public utils: Utils, @Inject(MAT_DIALOG_DATA) public data, public myPoliciesService: MyPoliciesService,
    public dataService: DataService, public fb: FormBuilder, public createSrService: CreateSRService, public message: MessageService, private route: ActivatedRoute, public auth: AuthService, public loginService : loginService) { }
  isHNIUSer: boolean = false;
  userDetails: any = {};
  benificiary:Array<any>=[];
  createSrResBody: any;
  editCommDtlsForm: FormGroup = new FormGroup({});
  ContactDetail: any;
  countryCode: Array<string> = [];
  filteredAltCountryCodes: Observable<Array<string>>;
  filteredCountryCodes: Observable<Array<string>>;
  formInitialized: boolean = false;
  queryparam: boolean = false;
  primaryOptions : Observable<any>;
  alternateOptions : Observable<any>;
  country  : any;

  ngOnInit(): void {
    this.trackUserActions({ "eventName" : "Edit communication details - popup load" });
    this.initializeContactForm();
    this.getCountryCode();
    this.userDetails = this.dataService.getLoginResp();
    this.queryparam = this.route.snapshot.queryParamMap.get('operation')?true:false
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }    
    this.getCountryCode();  
  }
  closeDialog() {
    if (!this.data.disableClose) {
      this.dialog.closeAll();
    } else if (this.dataService.isCustomLink) {
      this.auth.logout('M');
    }
  }
  createCommDtlsSR() {
       let type;
       let clientId;
       let benId;
       let acc_tag;
       let parentSR;
       if(this.data.viewDtls == "benfiAndNominee"){
         type = "CON_AMS_NNY"
         clientId = this.data.dtls.CLIENTID
         benId = this.data.dtls.CLIENTID
       } else if(this.data.viewDtls == "lifeAssured"){
         type = "CON_ADMN_NYN"
         clientId = this.data.dtls.CLIENTID
         benId = ""
       }else{
        benId = ""
        type =""
        clientId = this.utils.getClientId()
        }
    // if((this.data.id == "maturity")||(this.data.id == "moneyBack")){
    //   acc_tag = "Y"
    //   parentSR = this.data.parentSr
    //   clientId = this.data.clientId
    // } else{
    //   acc_tag = "";
    //   parentSR = ""
    //   clientId = this.utils.getClientId()
    // }
    const reqBody = {
      policyno: this.data.policyNo,
      clientid: clientId,
      benId : benId,
      role: this.data.role,
      subactivity: 'CHNG_CON_NUM',
      subactivitytype: type,
      action: "CREATE",
      payout_acc_tag: acc_tag,
      parentsrno: parentSR,
      srinput: {
        contactdtls: {
          emailid: this.editCommDtlsForm.value.email.primary,
          altemailid: this.editCommDtlsForm.value.email.alternate,
          mobile: this.editCommDtlsForm.value.primaryMob.mobileNo,
          altmobile: this.editCommDtlsForm.value.alternateMob.mobileNo,
          homenum: this.editCommDtlsForm.value.homeTel.telNo,
          officenum: this.editCommDtlsForm.value.officeTel.telNo,
          countrycode: this.editCommDtlsForm.value.primaryMob.countryCode,
          altcountrycode: this.editCommDtlsForm.value.alternateMob.countryCode,
          stdcodehome: this.editCommDtlsForm.value.homeTel.countryCode,
          stdcodeoffice: this.editCommDtlsForm.value.officeTel.countryCode,
          isemailchanged: this.isEmailChanged() ? "Y" : "N",
          officecountrycode: "",
          homecountrycode: ""
        }
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
      }
    };
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.dialog.closeAll();
        if (this.dataService.isDeepLink) {
          this._httpService.showSuccessMsg(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, true);
        } else {
          let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"SM"};
          this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar'],trackMsgObj);
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserFlowType(),"error_code":"UEE"}
        this._httpService.apiErrorHandler(resp,null,trackMsgObj); 
        }
    }),
      catchError((error) => throwError(error));
  }
  isEmailChanged(): boolean {
    return (this.editCommDtlsForm.value.email.primary !== this.data.dtls.CONTACT_EMAIL_ADDRESS) || (this.editCommDtlsForm.value.email.alternate !== this.data.dtls.CONTACT_X_ALT_EMAIL) || (this.editCommDtlsForm.value.email.primary !== this.data.dtls.BEN_EMAILID) || (this.editCommDtlsForm.value.email.alternate !== this.data.dtls.BEN_ALT_EMAILADDRESS)
  }
  initializeContactForm() {
    for (let key of Object.keys(this.data.dtls)) {
      if (this.data.dtls[key] == "NA" || this.data.dtls[key] == null) {
        this.data.dtls[key] = "";
      } else if (typeof this.data.dtls[key] === "number") {
        this.data.dtls[key] = String(this.data.dtls[key]);
      }
    }
    this.editCommDtlsForm = this.fb.group({
      'email': this.fb.group({
        'primary': this.data.id =="beneficiary"? [this.data.dtls.BEN_EMAILID, [CustomValidators.email]] : [this.data.dtls.CONTACT_EMAIL_ADDRESS, [CustomValidators.email]],
        'alternate': this.data.id =="beneficiary"? [this.data.dtls.BEN_ALT_EMAILADDRESS, [CustomValidators.alternateEmail]] : [this.data.dtls.CONTACT_X_ALT_EMAIL, [CustomValidators.alternateEmail]]
      }),
      'primaryMob': this.fb.group({
        'countryCode': this.data.id =="beneficiary"? [this.data.dtls.BEN_COUNTRYCD, [CustomValidators.mobCode]] : [this.data.dtls.CONTACT_COUNTRY_CD, [Validators.required, CustomValidators.mobCode]],
        'mobileNo': this.data.id =="beneficiary"? [this.data.dtls.BEN_MOBILENOR, [Validators.required]] : [this.data.dtls.CONTACT_CELL_PH_NUMBR, [Validators.required]]
      }, {validators: [this.mobileNoValidator()]}),
      'alternateMob': this.fb.group({
        'countryCode': this.data.id =="beneficiary"? [this.data.dtls.BEN_ALT_CC, [CustomValidators.mobCode]] : [this.data.dtls.CONTACT_ALT_COUNTRY_CD, [CustomValidators.mobCode]],
        'mobileNo': this.data.id =="beneficiary"? [this.data.dtls.BEN_ALTPHONENO] : [this.data.dtls.CONTACT_ALT_PH_NUM]
      }, {validators: [this.mobileNoValidator()]}),
      'homeTel': this.fb.group({
        'countryCode': this.data.id =="beneficiary"? [this.data.dtls.BEN_HOMESTDCD, [CustomValidators.stdCode]] : [this.data.dtls.CONTACT_HOME_STD, [CustomValidators.stdCode]],
        'telNo': this.data.id =="beneficiary"? [this.data.dtls.BEN_HOMEPHONENUMBER, [CustomValidators.telephoneNo]] : [this.data.dtls.CONTACT_HOME_PH_NUMBR, [CustomValidators.telephoneNo]]
      }),
      'officeTel': this.fb.group({
        'countryCode': this.data.id =="beneficiary"? [this.data.dtls.BEN_OFFICESTDCD, [CustomValidators.stdCode]] : [this.data.dtls.CONTACT_WORK_STD, [CustomValidators.stdCode]],
        'telNo': this.data.id =="beneficiary"? [this.data.dtls.BEN_WORKPHONENUMBER, [CustomValidators.telephoneNo]] : [this.data.dtls.CONTACT_WORK_PH_NUMBR, [CustomValidators.telephoneNo]]
      })
    });
    if(this.editCommDtlsForm.get('primaryMob.countryCode').value == '' && this.editCommDtlsForm.get('primaryMob.mobileNo').value == '')
      this.editCommDtlsForm.get('primaryMob.countryCode').setValue('91');
    if(this.editCommDtlsForm.get('alternateMob.countryCode').value == '' && this.editCommDtlsForm.get('alternateMob.mobileNo').value == '')
      this.editCommDtlsForm.get('alternateMob.countryCode').setValue('91');
    this.formInitialized = true;
    this.filteredAltCountryCodes = this.filterCountryCodes(this.editCommDtlsForm.get('alternateMob.countryCode'));
    this.filteredCountryCodes = this.filterCountryCodes(this.editCommDtlsForm.get('primaryMob.countryCode'));
    this.primaryOptions = this.editCommDtlsForm.get('primaryMob.countryCode').valueChanges.pipe(
      startWith(),map(value => this._filter(value))
    )
    this.alternateOptions = this.editCommDtlsForm.get('alternateMob.countryCode').valueChanges.pipe(
      startWith(),map(value => this._filter(value))
    )
  }
  // getCountryCode() {
  //   return this.myAccountService.getCountryCodeMasterData().subscribe(resp => {
  //     if (this._httpService.isSuccessResponse(resp)) {
  //       for (let key of Object.keys(resp.body["GCOM_COUNTRY_CODE"])) {
  //         this.countryCode.push(key);
  //       }
  //     } else {
  //       let flowType = 'my account';
  //       if (this.data && this.data.role == 'Life Assured') {
  //         flowType = 'Beneficiaries - LA'
  //       }
  //       if (this.data && this.data.role == 'Beneficiary') {
  //         flowType = 'Beneficiaries'
  //       }
  //       this._httpService.apiErrorHandler(resp,null,flowType);
  //     }
  //   }),
  //     catchError((error) => throwError(error));
  // }
  filterCountryCodes(control: AbstractControl) {
    return control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map(value => this.filterHelper(value)),
    );
  }
  filterHelper(value: string): Array<string> {
    return this.countryCode.filter(code => code.includes(value));
  }
  submitEditCommDtlsForm() {
    if (this.editCommDtlsForm && this.editCommDtlsForm.valid) {
      this.createCommDtlsSR();
    } else {
      this.editCommDtlsForm.markAllAsTouched();
    }
  }
  mobileNoValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {      
      if (group) {
        if (group.controls['countryCode'].value == '91') {
          if (group.controls['mobileNo'].value == null || group.controls['mobileNo'].value.length == 0) {
            return null;
          } else if (!(group.controls['mobileNo'].value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
            group.controls['mobileNo'].setErrors({ errorMsg: 'Please enter valid number.' });
          }
        } else {
          let maxMobLength: string = `${16 - group.controls['countryCode'].value.length}`;
          if (!group.controls['mobileNo'].value.match(new RegExp('^[0-9]{0,' + maxMobLength + '}$'))) {
            group.controls['mobileNo'].setErrors({ errorMsg: 'Please enter valid number.' });
          }
        }
        return null;
      }
    }
  }
  mobileNoKeyPress(event: KeyboardEvent, formGroupName: string) {
    if (this.editCommDtlsForm.get(`${formGroupName}.countryCode`).value == '91' && this.editCommDtlsForm.get(`${formGroupName}.mobileNo`).value.length >= 10) {
      event.preventDefault();
    } else if (this.editCommDtlsForm.get(`${formGroupName}.countryCode`).value != '91' && this.editCommDtlsForm.get(`${formGroupName}.mobileNo`).value.length >= 16-Number(this.editCommDtlsForm.get(`${formGroupName}.countryCode`).value.length)) {
      event.preventDefault();
    }
  }
  trackUserActions(payload) {
    this.utils.trackUserActions({ 'flowType': this.trackUserFlowType(), ...payload });
  }
  trackUserFlowType() {
    let flowType = 'my account';
    if (this.data && this.data.role == 'Life Assured') {
      flowType = 'Beneficiaries - LA'
    }
    if (this.data && this.data.role == 'Beneficiary') {
      flowType = 'Beneficiaries'
    }
    return flowType;
  }
  getCountryCode(){
    this.loginService.getCountryCode().subscribe((res) => {
        if(this._httpService.isSuccessResponse(res)){
            if(res.body.COUNTRY_CODE != null){
              let data = res.body.COUNTRY_CODE;
              let code = [];
              let majorCntry = [];
              for(let keys of Object.keys(data)){
                  if(data[keys][5][2] !== 'us' && data[keys][5][2] !== 'ae')
                      code.push(data[keys][5])
                  else
                      majorCntry.push(data[keys][5])
              }
              code.sort((a,b) => a[1].localeCompare(b[1]))
              majorCntry.reverse();
              for(let c of majorCntry){
                  code.unshift(c);
              }
              this.country = code;
              console.log(this.country);
            }
        }
    })
  }
  private _filter(value){
    let type = Number(value);
    if(isNaN(type)){
      const fvalue = value.toLowerCase();
      return this.country.filter(option => option[1].toLowerCase().startsWith(fvalue))
    } else{
      return this.country.filter(option => option[0].includes(value))
    } 
  }
}

