export const nomineeMasterData :any = 
{
    "GCOM_TITLE_SAL_MALE": {
        "Mr.": ["Mr.",
        "Mr.",
        1,
        0,
        []],
        "Dr.(Mr.)": ["Dr.(Mr.)",
        "Dr.(Mr.)",
        1,
        1,
        []]
    },
"GCOM_TITLE_MALE": {
    "Mr": ["Mr",
    "Mr.",
    1,
    0,
    []],
},
"GCOM_TITLE_SAL_MALE_NEW": {
    "Mr": ["Mr",
    "Mr.",
    1,
    0,
    []],
    "Dr Mr": ["Dr Mr",
    "Dr.(Mr.)",
    1,
    1,
    []]
},
"GCOM_TITLE_SAL_FEMALE": {
    "Ms.": ["Ms.",
    "Ms.",
    1,
    0,
    []],
    "Mrs.": ["Mrs.",
    "Mrs.",
    1,
    1,
    []],
    "Dr.(Miss)": ["Dr.(Miss)",
    "Dr.(Miss)",
    1,
    2,
    []],
    "Dr.(Mrs.)": ["Dr.(Mrs.)",
    "Dr.(Mrs.)",
    1,
    3,
    []]
},
"GCOM_TITLE_FEMALE": {
    "Mrs": ["Mrs",
    "Mrs.",
    1,
    0,
    []],
    "Ms": ["Ms",
    "Ms.",
    1,
    1,
    []]
},
"GCOM_TITLE_SAL_FEMALE_NEW": {
    "Ms": ["Ms",
    "Ms.",
    1,
    0,
    []],
    "Mrs": ["Mrs",
    "Mrs.",
    1,
    1,
    []],
    "Dr Miss": ["Dr Miss",
    "Dr.(Miss)",
    1,
    2,
    []],
    "Dr Mrs": ["Dr Mrs",
    "Dr.(Mrs.)",
    1,
    3,
    []]
},
"GCOM_REL_NOM_LA": {
    "RNMLA_GDGTR_CD": ["RNMLA_GDGTR_CD",
    "Grand Daughter",
    1,
    0,
    ["GROUP_SAL_FEMALE"]],
    // "RNMLA_NIECE_CD": ["RNMLA_NIECE_CD",
    // "Niece",
    // 1,
    // 1,
    // ["GROUP_SAL_FEMALE"]],
    // "RNMLA_LGGRD_CD": ["RNMLA_LGGRD_CD",
    // "Legal Guardian",
    // 1,
    // 2,
    // ["GROUP_SAL_NEUTRAL"]],
    // "RNMLA_TRUSTEE_CD": ["RNMLA_TRUSTEE_CD",
    // "Trustee",
    // 1,
    // 3,
    // ["GROUP_SAL_NEUTRAL"]],
    // "RNMLA_OTH_CD": ["RNMLA_OTH_CD",
    // "Other",
    // 1,
    // 4,
    // ["GROUP_SAL_NEUTRAL"]],
    // "RNMLA_NEPH_CD": ["RNMLA_NEPH_CD",
    // "Nephew",
    // 1,
    // 5,
    // ["GROUP_SAL_MALE"]],
    "RNMLA_GRMTH_CD": ["RNMLA_GRMTH_CD",
    "Grand Mother",
    1,
    6,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_GRFTH_CD": ["RNMLA_GRFTH_CD",
    "Grand Father",
    1,
    7,
    ["GROUP_SAL_MALE"]],
    "RNMLA_DGTR_CD": ["RNMLA_DGTR_CD",
    "Daughter",
    1,
    8,
    ["GROUP_SAL_FEMALE"]],
    // "RNMLA_DGHLAW_CD": ["RNMLA_DGHLAW_CD",
    // "Daughter-in-law",
    // 1,
    // 9,
    // ["GROUP_SAL_FEMALE"]],
    // "RNMLA_SONLAW_CD": ["RNMLA_SONLAW_CD",
    // "Son-in-law",
    // 1,
    // 10,
    // ["GROUP_SAL_MALE"]],
    // "RNMLA_MTHLAW_CD": ["RNMLA_MTHLAW_CD",
    // "Mother-in-law",
    // 1,
    // 11,
    // ["GROUP_SAL_FEMALE"]],
    // "RNMLA_FTHLAW_CD": ["RNMLA_FTHLAW_CD",
    // "Father-in-law",
    // 1,
    // 12,
    // ["GROUP_SAL_MALE"]],
    "RNMLA_GSON_CD": ["RNMLA_GSON_CD",
    "Grand Son",
    1,
    13,
    ["GROUP_SAL_MALE"]],
    "RNMLA_FATH_CD": ["RNMLA_FATH_CD",
    "Father",
    1,
    14,
    ["GROUP_SAL_MALE"]],
    "RNMLA_MOTH_CD": ["RNMLA_MOTH_CD",
    "Mother",
    1,
    15,
    ["GROUP_SAL_FEMALE"]],
    // "RNMLA_SPOU_CD": ["RNMLA_SPOU_CD",
    // "Spouse",
    // 0,
    // 16,
    // ["GROUP_SAL_NEUTRAL"]],
    "RNMLA_BRTR_CD": ["RNMLA_BRTR_CD",
    "Brother",
    1,
    17,
    ["GROUP_SAL_MALE"]],
    "RNMLA_SIS_CD": ["RNMLA_SIS_CD",
    "Sister",
    1,
    18,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_SON_CD": ["RNMLA_SON_CD",
    "Son",
    1,
    19,
    ["GROUP_SAL_MALE"]],
    "RNMLA_HUSB_CD": ["RNMLA_HUSB_CD",
    "Husband",
    1,
    20,
    ["GROUP_SAL_MALE"]],
    "RNMLA_WIFE_CD": ["RNMLA_WIFE_CD",
    "Wife",
    1,
    21,
    ["GROUP_SAL_FEMALE"]]
},
"GCOM_REL_APP_NOM": {
    "RNMLA_GDGTR_CD": ["RNMLA_GDGTR_CD",
    "Grand Daughter",
    1,
    0,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_NIECE_CD": ["RNMLA_NIECE_CD",
    "Niece",
    1,
    1,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_LGGRD_CD": ["RNMLA_LGGRD_CD",
    "Legal Guardian",
    1,
    2,
    ["GROUP_SAL_NEUTRAL"]],
    "RNMLA_TRUSTEE_CD": ["RNMLA_TRUSTEE_CD",
    "Trustee",
    1,
    3,
    ["GROUP_SAL_NEUTRAL"]],
    "RNMLA_OTH_CD": ["RNMLA_OTH_CD",
    "Other",
    1,
    4,
    ["GROUP_SAL_NEUTRAL"]],
    "RNMLA_NEPH_CD": ["RNMLA_NEPH_CD",
    "Nephew",
    1,
    5,
    ["GROUP_SAL_MALE"]],
    "RNMLA_GRMTH_CD": ["RNMLA_GRMTH_CD",
    "Grand Mother",
    1,
    6,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_GRFTH_CD": ["RNMLA_GRFTH_CD",
    "Grand Father",
    1,
    7,
    ["GROUP_SAL_MALE"]],
    "RNMLA_DGTR_CD": ["RNMLA_DGTR_CD",
    "Daughter",
    1,
    8,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_DGHLAW_CD": ["RNMLA_DGHLAW_CD",
    "Daughter-in-law",
    1,
    9,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_SONLAW_CD": ["RNMLA_SONLAW_CD",
    "Son-in-law",
    1,
    10,
    ["GROUP_SAL_MALE"]],
    "RNMLA_MTHLAW_CD": ["RNMLA_MTHLAW_CD",
    "Mother-in-law",
    1,
    11,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_FTHLAW_CD": ["RNMLA_FTHLAW_CD",
    "Father-in-law",
    1,
    12,
    ["GROUP_SAL_MALE"]],
    "RNMLA_GSON_CD": ["RNMLA_GSON_CD",
    "Grand Son",
    1,
    13,
    ["GROUP_SAL_MALE"]],
    "RNMLA_FATH_CD": ["RNMLA_FATH_CD",
    "Father",
    1,
    14,
    ["GROUP_SAL_MALE"]],
    "RNMLA_MOTH_CD": ["RNMLA_MOTH_CD",
    "Mother",
    1,
    15,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_SPOU_CD": ["RNMLA_SPOU_CD",
    "Spouse",
    0,
    16,
    ["GROUP_SAL_NEUTRAL"]],
    "RNMLA_BRTR_CD": ["RNMLA_BRTR_CD",
    "Brother",
    1,
    17,
    ["GROUP_SAL_MALE"]],
    "RNMLA_SIS_CD": ["RNMLA_SIS_CD",
    "Sister",
    1,
    18,
    ["GROUP_SAL_FEMALE"]],
    "RNMLA_SON_CD": ["RNMLA_SON_CD",
    "Son",
    1,
    19,
    ["GROUP_SAL_MALE"]],
    "RNMLA_HUSB_CD": ["RNMLA_HUSB_CD",
    "Husband",
    1,
    20,
    ["GROUP_SAL_MALE"]],
    "RNMLA_WIFE_CD": ["RNMLA_WIFE_CD",
    "Wife",
    1,
    21,
    ["GROUP_SAL_FEMALE"]]
},
"GCOM_MARRITAL_STATUS": {
    "Single": ["Single",
    "Single",
    1,
    0,
    []],
    "Married": ["Married",
    "Married",
    1,
    1,
    []],
    "Divorced": ["Divorced",
    "Divorced",
    1,
    2,
    []],
    "Widow/Widower": ["Widow/Widower",
    "Widow",
    1,
    3,
    []]
    // "Separated": ["Separated",
    // "Separated",
    // 0,
    // 4,
    // []],
    // "Partner": ["Partner",
    // "Partner",
    // 0,
    // 5,
    // []]
}
}