import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from './services/loader/loader.service';
import { INg2LoadingSpinnerConfig } from 'ng2-loading-spinner';
import { Utils } from './utils/common-functions';
import { DataService } from './services/data/data.service';
import { HttpService } from './services/http/http.service';
import { throwError, fromEvent, forkJoin} from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { catchError, map, filter } from 'rxjs/operators';
import { loginService } from './services/login/login.service';
import { SessionTimerService } from './services/session-timer/session-timer.service';
import { MyPoliciesService } from './services/my-policies/my-policies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'my-account-portal';
  isHNIUSer: boolean = false;
  showLoader = false;
  loaderConfig: INg2LoadingSpinnerConfig = {
    backdropColor: "rgba(35, 50, 83, 0.9)",
    spinnerSize: "lg"
  }
  isVirtualBranch: boolean = false;
  dedupeResponse: any;
  private visibile$: any;

  constructor(public router: Router, public loader: LoaderService, public auth: AuthService, public utils: Utils, public dataService: DataService, public httpService: HttpService, public route: ActivatedRoute,public loginService: loginService, private timerService$ : SessionTimerService, public myPoliciesService: MyPoliciesService) {
    this.loader.loaderState.subscribe((state) => {
      this.showLoader = state.show;
    });
    this.dataService.isHNIUser.subscribe(isHni => {
      this.isHNIUSer = isHni;
      this.utils.hniPopupBackdrop();
    });
    this.dataService.isVirtualBranch$.subscribe(isVirtualBranch => {
      this.isVirtualBranch = isVirtualBranch;
    });
  }

  ngOnInit() {
    this.visibile$ = fromEvent(document, 'visibilitychange').pipe(
      map(() => !document.hidden),filter(() => document.hidden == false)
    ).subscribe(
      () => {
        this.timerService$.resumeTimer();
      })
    if (this.utils.isUserAuthenticated() || this.utils.getLocalData("source", false) == "CL") {
      this.reinit();
    }      
    this.utils.loadTrackingScript();
    this.dataService.loginResp$.subscribe(loggedIn => {
      let loginResp = this.dataService.getLoginResp();
      loginResp = loginResp ? loginResp : {};
      if(loggedIn && loginResp.SOURCE === "CUSTOMERPORTAL") {
        this.utils.coTrackingScript();
      }
      if(loggedIn && loginResp.SOURCE === "CUSTOMLINK" && this.utils.getLocalData("action", false)  == 'cp_SurrenderPage') {
        this.utils.coTrackingScript();
      }
    })
  }
  reinit() {
    return this.httpService.reinit().subscribe((response) => {
      if (this.httpService.isSuccessResponse(response)) {
        this.dataService.setLoginResp({...response.body, "action": this.utils.getLocalData("action", false) });
        this.getDedupeData(response.body);
        setTimeout(() => {
          if (!this.dataService.getIsCustomLink()) {
            //loan to be included in getPolicyAndRiderData
            this.myPoliciesService.getNpsDetails();
            let policyCard$ = this.myPoliciesService.getPolicyDetails();
            let adcatRiders$ = this.myPoliciesService.getAdcatRiders({
              clientId: this.utils.getClientId()
            });
            let loanEnq$ = this.myPoliciesService.getLoanEnquiryData({
              clientId: this.utils.getClientId()
              // clientId: "54332651"
            });
            forkJoin([policyCard$, adcatRiders$, loanEnq$]).subscribe(([policyCardResp, riderResp, loanEnqResp]) => {
              this.myPoliciesService.getPolicyAndRiderData(policyCardResp, riderResp, loanEnqResp);
            });
          }          
        }, 500);
      } else {
        this.httpService.apiErrorHandler(response);
        //this.auth.logout();
      }
    }),
      catchError((error) => throwError(error));
  }
  getDedupeData(resp) {
    let dedupeReq = {
      "cp_login_mode":resp.LOGINMODE?resp.LOGINMODE:"",
      "cp_loginid":resp.LOGINID?resp.LOGINID:"",
      "dateofbirth":resp.DATEOFBIRTH?resp.DATEOFBIRTH:"",
      "fields": "policy,profile"
    }
    if(dedupeReq.cp_login_mode == "CO") {
      dedupeReq.cp_loginid =resp.CLIENT_ID;
    }
    else if(dedupeReq.cp_login_mode == "MO") {
      dedupeReq.cp_loginid = resp.MOBILE_NUM
    }
    else if(dedupeReq.cp_login_mode  == "EO") {
      dedupeReq.cp_loginid = resp.EMAIL_ID
    }
    else {
      //dedupeReq.cp_loginid = this.policyId
    }  
    return this.loginService.getDedupeResp(dedupeReq)
    .subscribe((response) => {
      if (this.httpService.isSuccessResponse(response)) {
        this.dedupeResponse = response.body.dedUpeDetails;
        this.dataService.setDedupeResp(this.dedupeResponse);
        this.dataService.setDedupeData(response.body);
    }
  else {
    this.dedupeResponse=response.body.dedUpeDetails;
    this.dataService.setDedupeResp(this.dedupeResponse);
    this.dataService.setDedupeData(response.body);
  }}),
    catchError((error) => throwError(error))
  }
  
}
