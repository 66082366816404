<div class="buy-nps-popup ">
    <div class="nps-popup-content">
        <div class="nps-popup-header">
            Buy NPS
            <div mat-dialog-actions class="mat-dialog-actions"(click)="close()" >
                <div mat-dialog-close class="close-dialog no-mobile" type="button"
                    >Close<img src="assets/close.svg"></div>
                <div mat-dialog-close class="close-img mobile" (click)="close()"></div>
            </div>
        </div>
        <div class="nps-popup-body popup-content-height">
            <div class="popup-content">
                <div class="header-text">Dear Customer, start your retirement today, by investing in NPS</div>
                <div class="sub-text">Why NPS?</div>
            </div>
            <div class="main-text">
                <div class="text-block">
                    <div class="title"><img src="assets/image.png">Market-linked returns</div>
                    <div class="text">Potential for higher returns compared to traditional fixed-income options.</div>
                </div>
                <div class="text-block">
                    <div class="title"><img class="tax-icon" src="assets/policy_icon2-cropped.svg">Tax benefits</div>
                    <div class="text">Enjoy tax deductions on contributions under Section 80C and 80CCD of the Income
                        Tax Act.</div>
                </div>
                <div class="text-block">
                    <div class="title"><img src="assets/cursor-hand-click-svgrepo-com.svg">Flexibility</div>
                    <div class="text">Choose from various investment options based on your risk appetite and financial
                        goals.</div>
                </div>
                <div class="text-block">
                    <div class="title"><img src="assets/transfer-svgrepo-com.svg">Portability</div>
                    <div class="text">Easily transfer your NPS account across different employers or locations.</div>
                </div>
                <div class="text-block">
                    <div class="title"><img src="assets/bank-blue.svg">Government Backed</div>
                    <div class="text">Ensures safety and security of your investments.</div>
                </div>
                <div class="text-block">
                    <div class="title"><img src="assets/cash-machine-svgrepo-com (1).svg">Easy withdrawal options</div>
                    <div class="text">Access a portion of your corpus under specific conditions.</div>
                </div>
            </div>
            <div class="button-section">
                <button class="buy-button" (click)="redirectToNps()">Buy NPS</button>
            </div>
            <div class="popup-content fs">
                <span class="sub-text">Kindly Note: </span><span class="text"> This communication is solely intended for creating awareness on National Pension Scheme (NPS). For more information about NPS you may also visit <a href="https://www.hdfcpension.com" target="_blank" rel="noopener noreferrer">www.hdfcpension.com</a>. HDFC Pension is a wholly owned subsidiary of HDFC Life and this communication is not intended for solicitation of NPS.</span>
            </div>
        </div>
    </div>
</div>