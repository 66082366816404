import { Component, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError,interval } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { loginService } from 'src/app/services/login/login.service';
import { SessionTimerService } from 'src/app/services/session-timer/session-timer.service';
import { Utils } from 'src/app/utils/common-functions';
import { LogoutDialogComponent } from '../../shared/components/logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.css']
})
export class SessionDialogComponent implements OnInit {
  
  @ViewChild('logoutCnfm') logoutCnfm : TemplateRef<any>;
  @ViewChild('logoutTimer') logoutTimer : TemplateRef<any>;
  timerSec: number;
  isTimerOn: boolean;
  timeoutinterval: any;
  hours: number;
  minutes: number;
  ref : any;
  dialogRef : MatDialogRef<any>
  logoutModeResp : any;
  userDetails: any;
  isCPAdmin: any;
  logoutDialogRef: MatDialogRef<LogoutDialogComponent> = null;
  timerInterval$ = interval(1000);
  showDialog =  null;
  min ;
  seconds ;
  showlogoutTimer = false;
  // @Output()


  constructor(public dialog : MatDialog, public loginService: loginService, public httpService: HttpService, public dataService: DataService, public auth: AuthService, public utils: Utils, public timer$ : SessionTimerService, @Inject(MAT_DIALOG_DATA) public data) { 
    this.dataService.isCPAdmin.subscribe(isCPA => {
      this.isCPAdmin = isCPA;
    })
  }

  ngOnInit(): void {
    // this.timer$.setDuration(1 * 59 * 1000);
    // this.timer$.runTimer();
    this.showDialog = this.data.dialogRef;
    if(this.showDialog == 'logoutTimer'){
      this.showlogoutTimer = true;
    } else {
      this.showlogoutTimer = false;
      this.autoLogout();
    }
    this.timer$.timeLeft$.subscribe((v) => {
      let obj = this.convert(v)
      this.min = obj.minutes;
      this.seconds = obj.seconds
      if(this.min == 0 && this.seconds == 0){
        // this.dialogRef = this.dialog.open(this.logoutCnfm, { disableClose : true, hasBackdrop : true, position : { top :"0px"}, backdropClass : 'logout-backdrop' });
        this.timer$.clearInterval();
      }
    })
  }

  // ngOnInit(): void {
  //   // this.timerSec = 120;
  //   this.timerInterval$.pipe(take(120)).subscribe((time : number) => {
  //     if((time % 60) == 0){
  //       this.min--;
  //       this.seconds = 60;
  //     }
  //     this.seconds--;
      
  //   },
  //   (error) => console.log(error),
  //   () => {
  //       console.log('Complete');
  //       clearInterval(this.timeoutinterval);
  //       this.autoLogout();
  //       this.dialog.closeAll();
  //       this.dialogRef = this.dialog.open(this.logoutCnfm, { disableClose : true, hasBackdrop : true, position : { top :"0px"}, backdropClass : 'logout-backdrop' });
  //     })
  //   // this.startTimer();
  // }

  // startTimer(){
  //   this.timerSec = 120;
  //   this.timeoutinterval = setInterval(() => {
  //     if (this.timerSec > 0) {
  //       this.hours = ((this.timerSec / 60) | 0);
  //       // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
  //       if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
  //       // if (this.minutes == 60) {this.minutes = 0;} }
  //       else { this.minutes = this.timerSec; }
  //       this.timerSec--;
  //     }
  //     else {
  //       this.hours = 0;
  //       this.minutes = 0;
  //       clearInterval(this.timeoutinterval);
  //       this.autoLogout();
  //       this.dialog.closeAll();
  //       this.dialogRef = this.dialog.open(this.logoutCnfm, { disableClose : true, hasBackdrop : true, position : { top :"0px"}, backdropClass : 'logout-backdrop' });   
  //     }
  //   }, 1000)
  // }

  keepworking(){
    this.timer$.clearInterval();
    this.dialog.closeAll();
    this.httpService.reinit().subscribe((response) => {
      if (this.httpService.isSuccessResponse(response)) {
        // this.reinitSuccess = true;
        this.dataService.setLoginResp({...response.body, "action": this.utils.getLocalData("action", false) });
      } else {
        this.httpService.apiErrorHandler(response);
        this.auth.logout('A');
      }
    }),
      catchError((error) => throwError(error));
  }

  dialogClose(){
      this.dialog.closeAll();
      this.auth.logout('A');
  }

  handleError() {
    this.auth.logout('M');
  }

  setlogoutMode() {
    let reqBody = {
      logoutMode: 'M',
    }  
    this.logoutDialog();
    return this.loginService.setLogoutMode(reqBody)
    .pipe(catchError(this.handleError.bind(this)))
    .subscribe((response) => {
      if ((this.httpService.isSuccessResponse(response))) {
        this.logoutModeResp = response.body.Status;
        if (this.logoutModeResp.toUpperCase() == "SUCCESS") {
          clearInterval(this.timeoutinterval)
        }
      }
      else {
        //this.auth.logout('M');
        clearInterval(this.timeoutinterval)
      }
    }),
    catchError((error) => throwError(error))
  }

  // //to show VOC
  
  logoutDialog() {
    this.userDetails = this.dataService.getLoginResp();
    if(this.userDetails && this.userDetails.PROCEED_WITH_VOC === "Y" && !this.isCPAdmin) {
      let policyCardResp = this.dataService.getPolicyCardResp();
      let clientid = this.utils.getClientId();
      if (!this.dataService.isCsApp) {
        this.auth.logout('M');
      }
      this.logoutDialogRef = this.dialog.open(LogoutDialogComponent, { panelClass: 'logout-feedback', backdropClass: 'logout-feedback-backdrop', disableClose: true,
        data: {
          userDtls: this.userDetails,
          policyCardResp: policyCardResp,
          clientid: clientid
        }, 
      },
      );
    } else {
      this.auth.logout('M');
    }
  }

  autoLogout() {
    this.timer$.clearInterval();
    let reqBody = {
      logoutMode: 'A',
    }
    return this.loginService.setLogoutMode(reqBody)
    .pipe(catchError(this.handleSessionError.bind(this)))
    .subscribe((response) => {
      if(response.body)
        this.timer$.clearInterval();
    })
  }

  handleSessionError() {
    this.auth.logout('S');
  }

  convert(ms : any){
    let seconds = Math.floor(ms/1000);
    let minutes = Math.floor(seconds/ 60);
    seconds = seconds % 60;
    return { minutes : minutes, seconds : seconds }
  }

}
