import { Component, OnInit,Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SurrenderAversionService } from 'src/app/services/surrender-aversion/surrender-aversion.service';
import { HttpService } from 'src/app/services/http/http.service';
import { throwError, from } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { CaptchaComponent } from '../captcha/captcha.component';
import { loginService } from "src/app/services/login/login.service";
import { serviceTimeout } from 'src/environments/environment';
import { LoaderInterceptorService } from 'src/app/services/loader-interceptor/loader-interceptor.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../message-popup/message-popup.component';


@Component({
  selector: 'app-policy-based-login',
  templateUrl: './policy-based-login.component.html',
  styleUrls: ['./policy-based-login.component.css']
})
export class PolicyBasedLoginComponent implements OnInit {
  @Output() surrenderAversionClickEvent = new EventEmitter<any>();
  @Output() loginSuccess = new EventEmitter<any>();
  today = new Date();
  policyNoForm: FormGroup;
  otpForm: FormGroup;
  policyFocus: boolean = false;
  dobFocus: boolean = false;
  otpFocus: boolean = false;
  otpFlag: boolean = false;
  mobileNumber: any = '';
  email:any = '';
  maskMobileNumber: any;
  maskEmail:any;
  interval: any;
  hours: number = 0;
  minutes: number = 0;
  timerSec: number = 180;
  buttonClick: boolean = false;
  transID: any;
  policynum: any;
  action: string;
  clientid: string;
  @Input() isFormReset: boolean = false;
  isOtpMasked: boolean = true;
  @Input() loginSource : String;

  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  custUserDtls: any;
  medium: string;
  pageId: string;
  isCaptchaValid: boolean;
  notcaptchaFocused: boolean;
  statusMessage: string;
  @Input() policyDtls: any = {};
  @Input() readOnly: boolean = false;
  @ViewChild('myLinkCaptcha') myLinkCaptcha: CaptchaComponent;
  dedupeResponse: any;
  clientSource: any;
  errorMessage: boolean = false;
  validateOtpReq: any;
  policyStatus: any;
  source: any;
  firstName: any = "";
  lastName: any = "";
  srNum: string;

  get device() {
    return this.deviceService.getDeviceInfo();
  }

  constructor( public utils: Utils, private fb: FormBuilder,public dataService: DataService, public surrenderService: SurrenderAversionService,public _httpService: HttpService
    , private router: Router, public route: ActivatedRoute,public loginService: loginService,public loaderInterceptor: LoaderInterceptorService,private loader: LoaderService, public customLinkService: CustomLinkService,private deviceService: DeviceDetectorService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.policyNoForm = this.fb.group({
      policyNo: ['', [Validators.required, Validators.pattern('^[0-9]{8,8}$')]],
      srno: [''],
      dob: ['', Validators.required],
      captcha: ['',Validators.required]
    });
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
    });
    this.pageId = this.route.snapshot.queryParamMap.get('_pageid');
    this.action = this.route.snapshot.queryParamMap.get('action')
    this.action = this.action ? this.action : this.pageId ? this.pageId : '';
    this.medium = this.route.snapshot.queryParamMap.get('medium');
    this.srNum = this.route.snapshot.queryParamMap.get('sr_num');
    if(this.srNum) {
      this._httpService.init({}).subscribe(resp => {
      this._httpService.initResp = resp;
      this.fetchPolicy();
    });
    }
  }

  submitPolicyNoForm() {
    if(this.isCaptchaValid) {
    this.init().subscribe((resp) => {
      this._httpService.initResp = resp;
      if(this.action == 'cp_unsecuredChequePickUpPage') {
        this.getDedupeSource();
      }
      else {
      this.getUserDetails();
      }
    }),
      catchError((error) => throwError(error)); 
  }
  else {
    this.policyNoForm.get('captcha').setErrors({ captchaIncorrect: true });
  }
}

  submitOtpForm() { }

  policyFocusOut() {
    this.policyFocus = true;
  }

  dobFocusOut() {
    this.dobFocus = true;
  }

  otpFocusOut() {
    this.otpFocus = true;
  }

  getUserDetails() {
    let dateOfbirth = moment(this.policyNoForm.controls.dob.value).format("DD/MM/YYYY");
    let userBody: any = {
      policyno: this.policyNoForm.controls.policyNo.value,
      dob: dateOfbirth,
      srno: this.policyDtls && this.policyDtls.srno ? this.policyDtls.srno : '',
      action: this.action,
      activityno: ""
    }
    return this.surrenderService.getSurrenderUserDetails(userBody).subscribe((resp)=> {
      if(this._httpService.isSuccessResponse(resp)) {
        this.custUserDtls = resp.body;
        this.maskMobileNumber = resp.body.mobilenum;
        this.maskEmail = resp.body.Emailid;
        this.transID = resp.body.trans_id;
        this.policynum = resp.body.Policyno;
        this.clientid = resp.body.Clientid;
        this.genOtp();
      }
      else {
        this._httpService.apiErrorHandler(resp); 
      }
    }),
    catchError((error) => throwError(error));
  }

  genOtp() {
    this.otpFlag = true;
    this.OTPTimerStart();
  }

  RegenOTP() {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.getUserDetails();
  }

  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        // if (this.minutes == 60) {this.minutes = 0;} }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }

  validateOtp() {
    this.buttonClick = true;
    if(this.otpForm.valid && this.dedupeResponse && (this.dedupeResponse.source).toUpperCase() == "EXIDE") {
      this.validateExideOtp();
    }
    else if(this.otpForm.valid  && this.dedupeResponse && !((this.dedupeResponse?.source).toUpperCase() == "EXIDE")) {
      this.verifyOtp();
    } else {
      this.verifyOtp();
    }
   }

   verifyOtp() {
    if(this.action == 'cp_unsecuredChequePickUpPage') {
      this.validateOtpReq = {
       otpEntered: this.otpForm.controls.otp.value,
       trans_id: this.transID,
       policyno: this.policynum,
       //action: this.action,
       login_source:this.loginSource,
       medium:this.medium,
       login_mode: 'PO',
       source: this.clientSource,
      }
    }
    else {
     this.validateOtpReq = {
     otpEntered: this.otpForm.controls.otp.value,
     trans_id: this.transID,
     policyno: this.policynum,
     //action: this.action,
     login_source:this.loginSource,
     medium:this.medium,
     login_mode: 'PO',
   }
   }
   let validateOtpRequest = {
    "device_type":this.device.deviceType,
    "browser":`${this.device.browser} - ${this.device.browser_version}`,
    "os_type":this.device.os,
    ...this.validateOtpReq
  }
    return this.surrenderService.validateSurrenderOtp(validateOtpRequest).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)) {        
        this.utils.setLocalData("source", "CL", false);
        if (resp.body && this.clientid && this.policynum && this.action) {
         this.utils.setLocalData("action", this.action, false);
          this.dataService.setIsCustomLink(true);
        }        
          this.reinit(resp);                
      }
      else {
        this._httpService.apiErrorHandler(resp);
        this.resetForm(); 
      }
    }),
    catchError((error) => this.httpErrorHandler.bind(this, error));
   }
   init() {
    return this._httpService.init(this.httpHeaders);
  }
  ngOnChanges() {
    if (this.isFormReset) {
       this.resetForm();
    }
    if (this.policyNoForm && this.policyNoForm.controls) {
      if (this.readOnly) {
        this.policyNoForm.controls.policyNo.disable();
        this.policyNoForm.controls.srno.disable(); 
        this.policyNoForm.controls.dob.disable();  
        this.policyNoForm.controls.captcha.disable();  
      }
      if (this.policyDtls && this.policyDtls.policyno) {
        this.policyNoForm.controls.policyNo.setValue(this.policyDtls.policyno);
        //this.policyNoForm.controls.policyNo.disable();
        this.policyNoForm.controls.dob.enable();  
        this.policyNoForm.controls.captcha.enable();
        if (this.policyDtls.srno) {
          this.policyNoForm.controls.srno.setValue(this.policyDtls.srno);
          //this.policyNoForm.controls.srno.disable();  
        } else {
          this.policyNoForm.controls.srno.setValue('');
        }
      } else {
        //this.policyNoForm.controls.policyNo.enable();
      }
    }
  }
  toggleMasking() {
    this.isOtpMasked = !this.isOtpMasked;
  }
  reinit(resp) {
    return this._httpService.reinit().subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && this.clientid && this.policynum && this.action) {
          this.dataService.setLoginResp({ ...response.body, "action": this.action });
          this.loginSuccess.emit();
        }
      } else {
        this._httpService.apiErrorHandler(response);
        this.resetForm();
      }
    }),
      catchError((error) => this.httpErrorHandler.bind(this, error));
  }
  resetForm() {
    this.myLinkCaptcha.reloadCaptcha();
    this.policyNoForm.reset();
    this.otpForm.reset();
    this.otpFlag = false;
    clearInterval(this.interval);
    this.timerSec = 180;
    localStorage.clear();
  }
  httpErrorHandler(error) {
    this.resetForm();
    throwError(error);
  }
  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
  }
  clearCaptcha() {
    this.policyNoForm.controls.captcha.setValue('');
  }
  captchaFocusOut(event: any) {
    this.notcaptchaFocused = true;
  }
  errorclear() {
    this.statusMessage = "";
  }
  getDedupeSource() {
    let dedupeRequest = {
      "cp_login_mode": "PO",
      "cp_loginid": this.policyNoForm.controls.policyNo.value,
      "dateofbirth": moment(this.policyNoForm.controls.dob.value).format('DD/MM/YYYY'),
      "fields": "",
      "login_source": this.loginSource,
    }
    return this.loginService.getDedupeResp(dedupeRequest)
    .pipe(timeout(serviceTimeout),catchError(this.handleDedupeError.bind(this)))
    .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.dedupeResponse = response.body;
          if(response.body.source) {
          this.clientSource = response.body.source;
          }
          if(this.dedupeResponse == null) {
            this.errorMessage = false;
            this.getUserDetails();
          }
          else if((this.dedupeResponse.source).toUpperCase()== "NONE") {
            this.statusMessage = "Policy details entered are incorrect";
            this.errorMessage = true;
          } 
          else 
            if((this.dedupeResponse.source).toUpperCase() == "HDFC" || this.dedupeResponse.source == "HDFC,EXIDE") {
              this.errorMessage = false;
              this.getUserDetails();
            }
            else if((this.dedupeResponse.source).toUpperCase() == "EXIDE") {
              this.errorMessage = false;
              this.generateExideOtp();
            }
        }
      else {
        this.getUserDetails();
      }
    });
  }
  handleDedupeError() {
    LoaderInterceptorService.requestCount=0;
    this.loader.hide();
      setTimeout(() => {
        this.getUserDetails();
    }, 50);
  }
  generateExideOtp() {
    let reqBody = {
      "policyno": this.policyNoForm.controls.policyNo.value,
      "dob": moment(this.policyNoForm.controls.dob.value).format('DD/MM/YYYY'),
      "login_source": "CUSTOMLINK",
    }
    return this.customLinkService.generateOtp(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.custUserDtls = resp.body;
        this.maskMobileNumber = resp.body.mobilenum;
        this.maskEmail = resp.body.Emailid;
        this.transID = resp.body.trans_id;
        this.policynum = resp.body.Policyno;
        this.clientid = resp.body.Clientid;
        this.policyStatus = resp.body.policy_status;
        this.source = resp.body.source;
        this.firstName = resp.body.firstName;
        this.lastName = resp.body.lastName;
        this.genOtp();
      }
      else {
        this._httpService.apiErrorHandler(resp);
      }
    });
  }
  validateExideOtp() {
    let reqBody = {
        "client_id": this.clientid,
        "policyno": this.policyNoForm.controls.policyNo.value,
        "dob": moment(this.policyNoForm.controls.dob.value).format('DD/MM/YYYY'),
        "trans_id": this.transID,
        "otpEntered": this.otpForm.controls.otp.value,
        "login_source": "CUSTOMLINK",
        "medium": "",
        "source": this.source,
        "policy_status": this.policyStatus,
        "firstName": this.firstName,
        "lastName": this.lastName

    }
    return this.customLinkService.validateOtp(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.utils.setLocalData("source", "CL", false);
        if (resp.body && this.clientid && this.policynum && this.action) {
         this.utils.setLocalData("action", this.action, false);
          this.dataService.setIsCustomLink(true);
        }        
          this.reinit(resp);
      }
      else {
        this._httpService.apiErrorHandler(resp);
        this.resetForm();
      }
    });
  }
  fetchPolicy() {
    let reqBody = {
      srnum: this.srNum,
    }
    return this.customLinkService.fetchPolicy(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body.length != 0) {
          this.policyNoForm.controls.policyNo.setValue(resp.body[0].POLICY_NUM);
          this.policyNoForm.controls.policyNo.disable();
        }
        else if (resp.body.length == 0) {
          this.dialog.open(MessagePopupComponent, {
            width: '500px',
            panelClass: 'message-popup-panel',
            backdropClass: 'email-voc-popup', disableClose: true,
            data: {
              closeIcon: false,
              title: "Error",
              mainMessageHtml: "The link is invalid. For any further assistance, please contact us on 1860 267 9999 or email us at service@hdfclife.com.",
              cancelBtnLabel: "OK",
              source: 'voc'
            }
          });
        }
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    });
  }
}
