import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class SurrenderAversionService {

  constructor(public _httpService: HttpService, public utils: Utils, public crypto: EncryptDecryptService) { }

  getSurrenderUserDetails(reqBody) {
    let policyno = reqBody.policyno;
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.dob = this.crypto.encryptJson(reqBody.dob);
    reqBody.srno = reqBody.srno ? this.crypto.encryptJson(reqBody.srno) : '';
    return this._httpService.post(serviceURL.sendotp_url, this.utils.createRequest(policyno, 'CustomlinkSendOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  validateSurrenderOtp(reqBody) {
    let policyno = reqBody.policyno;
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.otpEntered = this.crypto.encryptJson(reqBody.otpEntered);
    reqBody.trans_id = this.crypto.encryptJson(reqBody.trans_id);
    return this._httpService.post(serviceURL.valotp_url, this.utils.createRequest(policyno, 'CustomlinkValidateOTP', reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  } 
}
