import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'src/app/utils/common-functions';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { HttpService } from 'src/app/services/http/http.service';
import { serviceURL } from 'src/environments/environment';


@Component({
  selector: 'app-buy-nps',
  templateUrl: './buy-nps.component.html',
  styleUrls: ['./buy-nps.component.css']
})
export class BuyNpsComponent implements OnInit {

  constructor(public dialog: MatDialog,public utils: Utils,public myPoliciesService: MyPoliciesService,public _httpService: HttpService,@Inject(MAT_DIALOG_DATA) public data) { }
  source:any;
  ngOnInit(): void {
    this.source = this.data.src;
    this.updateNpsNudge();
  }
  close() {
    this.dialog.closeAll();
    if(this.source == 'nudge') {
    this.utils.trackUserActions({ "eventName" : "Confirmatory nudge - click on close" , "flowType":'NPS' });
    }
  }
  redirectToNps() {
    this.utils.trackUserActions({ "eventName" : "Confirmatory nudge - click on BUY NPS" , "flowType":'NPS' });
    let reqBody = {
      clientid : this.utils.getClientId(),
    }
    return this.myPoliciesService.updateNps(reqBody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && (response.body.msg).toLowerCase() == 'success') {
          window.open(serviceURL.nps_link,"noopener noreferrer");
          this.dialog.closeAll();
        } 
      } else {
        this._httpService.apiErrorHandler(response);
      }
    });
  }
  updateNpsNudge() {
    let reqBody = {
      clientid : this.utils.getClientId(),
    }
    return this.myPoliciesService.updateNpsNudge(reqBody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && (response.body.msg).toLowerCase() == 'success') {
          //console.log('nps nudge displayed');
        } 
      } else {
        this._httpService.apiErrorHandler(response);
      }
    });
  }
}
