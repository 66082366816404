<div class="footer-section flex-center" [ngClass]="{'login-footer': (router.url == '/login' || router.url.includes('/my-vb-test') || (router.url.includes('/surrender-aversion')) || (router.url.includes('/investigator')) || ((router.url.includes('/my-link') || showChatBot) &&  !router.url.includes('/my-link/wb-voucher') &&  !router.url.includes('/my-link/surrender') && !router.url.includes('/my-link/update-bank-details')) || (router.url.includes('/my-login'))),'hni-footer-section': isHNIUSer}">
        <div *ngIf="!isVirtualBranch && !(router.url.includes('/my-vb-test'))" class="footer-text" [ngClass]="{'after-login' : (router.url.includes('/login')),'hni-text-white': isHNIUSer}">
                &#169; Copyright HDFC Life Ltd.
        </div>
        <div class="chat-icon" *ngIf="!(isVirtualBranch || dataService.isDeepLink || router.url.includes('/my-vb-test') || (router.url.includes('/surrender-aversion')) || (router.url.includes('/my-link')) || (router.url.includes('/voucher-link')) || (router.url.includes('/investigator')) || (router.url.includes('/my-doc-upload')))"><!--|| (router.url.includes('/email-voc'))-->
        <!-- <div class="chat-icon" *ngIf="!((router.url =='/login') || (router.url.includes('/login')) || isVirtualBranch || dataService.isDeepLink || router.url.includes('/my-vb-test') || (router.url.includes('/surrender-aversion')) || (router.url.includes('/investigator')) || (router.url.includes('/my-login')) || (router.url.includes('/my-link')) || (router.url.includes('/my-doc-upload')))"> -->
                <!-- <img *ngIf="!isHNIUSer" src="assets/chat_icon.svg">
                <img *ngIf="isHNIUSer" src="assets/hni_chat_icon.svg"> -->
                <div class="disclaimer-banner" *ngIf="displayBanner && !(router.url == '/login' || router.url == '/my-login')" [ngClass]="{'show-banner': showBanner}">
                        <button class="nav-help-btn" *ngIf="!showBanner" (click)="openBanner()" #imgInfo id="imgInfo" [ngClass]="{'display-icon':!displayIcon,'display-button':displayIcon}">Need help in navigating portal?</button>
                        <mat-card class="banner-card" *ngIf="showBanner">
                                <img src="/assets/cross_circle_blue.svg" class="nav-help-close" (click)="closeBanner()">
                                <div class="banner-text">Facing issues in navigating our new portal? Type <span>'Portal help'</span> on chat, and we will assist you</div>
                        </mat-card>
                    </div>  
                <app-chat-bot></app-chat-bot>
        </div>
        <div class="chat-icon" *ngIf="showChatBot"><!--|| (router.url.includes('/email-voc'))-->
                <app-chat-bot></app-chat-bot>
        </div>
</div>