import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CameraComponent } from '../../shared/components/camera/camera.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { Utils } from 'src/app/utils/common-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as moment from 'moment';
import { DocListPopupComponent } from '../../shared/components/doc-list-popup/doc-list-popup.component';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-agent-sr-screen',
  templateUrl: './agent-sr-screen.component.html',
  styleUrls: ['./agent-sr-screen.component.css']
})
export class AgentSrScreenComponent implements OnInit {
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  photoCapture: boolean = true;
  dataSource: any;
  displayedColumns: Array<string> = ["Sr_No","SR_Number", "Policy_No", "Request_type","Date_Logged","Upload"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  agentSrList: any;
  srList: any;
  srNum:Array<any> = [];
  imageArray: any;
  byteArray:any;
  fileFormats: Array<string> = ['jpeg', 'jpg', 'png'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 1;
  noRecords: boolean = false;
  constructor(public dialogRef: MatDialog,
  public customLinkService: CustomLinkService,
  public utils: Utils,
  public _httpService: HttpService,
  public dialog: MatDialog,
  public httpService: HttpService,
  private router: Router,
  public message: MessageService,
  ) { }
  ngOnInit(): void {
    if(this.router.url.includes('photo-upload')) {
      this.photoCapture = true;
    }
    else {
      this.photoCapture = false;
      this.getAgentSrDtls();
    }
  }
  capturePhoto() {
    this.cameraDialogRef = this.dialogRef.open(CameraComponent, { panelClass: 'camera-dialog', backdropClass: 'camera-dialog-backdrop', data: { maxFiles : 2 }, disableClose: true });
        this.cameraDialogRef.afterClosed().subscribe(imageArray => {
        if (imageArray && imageArray.length > 0) {
          if(this.validateFiles(imageArray)) {
            this.byteArrayGenerator(imageArray).then(result => {
              this.photoCapture = false;
              this.byteArray = result;
              this.saveAgentImage(this.byteArray);
            });
          }
      }
      else {
        this.message.openSnackBar('Please capture your photo', 'xy', ['error-snackbar']);
      }
    });
  }
  getAgentSrDtls() {
    let reqBody = {}
    return this.customLinkService.getAgentSRList(reqBody).subscribe(resp => {
     if (this._httpService.isSuccessResponse(resp)) {
        this.srList = resp.body.response;
        if(resp.body.response == "norecords") {
          this.noRecords = true;
        }
        else {
          this.noRecords = false
        }
        for(let item of this.srList) {
          let index = this.srList.indexOf(item);
          let date =  moment(item.REQ_DATE).format('DD/MM/YYYY');
          item["index"] = index+1;
          item["REQ_DATE"] = date;
        }
        this.dataSource = new MatTableDataSource(this.srList);
        this.addPaginationAndSorting();
      } else {
       this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  addPaginationAndSorting() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }
  uploadDocument(srArray) {
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      data: {
        srNumber: srArray,
        source:'agentDocUpload'
        } 
  })
}
saveAgentImage(item) {
  let reqBody = {
    agentImage:"data:image/jpegbase64,"+item,
  }
  return this.customLinkService.saveAgentImage(reqBody).subscribe((resp) => {
    if (this.httpService.isSuccessResponse(resp)) {
      this.router.navigate(['/my-link','sr-list']);
      this.getAgentSrDtls();
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  });
}
getAgentDocList(item) {
  let reqBody = {
    srNo: item
  }
  return this.customLinkService.getAgentDocList(reqBody).subscribe(resp => {
    if (this.httpService.isSuccessResponse(resp)) {
      this.srNum = [];
      this.srNum.push(item);
      if(resp.body.sr_number) {
      for(let i of resp.body.sr_number) {
        this.srNum.push(i.SR_NO);
      }
    }
      this.uploadDocument(this.srNum);
    }
    else{
      this.httpService.apiErrorHandler(resp);
    }
  });
}
async byteArrayGenerator(files: Array<any>) {
  let bytearray;
  for (let i = 0; i < files.length; i++) {
    let base64string: string;
    base64string = await this.utils.getBase64(files[i]);
    bytearray = base64string;
  }
  return bytearray;
}
validateFiles(files) {
  let filesExceeded: boolean;
    filesExceeded = files.length > this.maxNumOfFiles;
  if (filesExceeded) {
    this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar']);
    return false;
  }
  for (let i = 0; i < files.length; i++) {
    const filename = files[i].name;
    const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
    const filesize = files[i].size / 1024 / 1024;
    if (!this.fileFormats.includes(filetype)) {
      this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
      return false;
    } else if (filesize > this.maxFileSize) {
      this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], 'top');
      return false;
    }
  }
  return true;
}
}
