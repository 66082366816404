import { Component, OnInit } from '@angular/core';
import { FAQ } from 'src/app/utils/app-constants';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-facing-login-issues-popup',
  templateUrl: './facing-login-issues-popup.component.html',
  styleUrls: ['./facing-login-issues-popup.component.css']
})
export class FacingLoginIssuesPopupComponent implements OnInit {
  loginIssuesFaq = FAQ;
  isHNIUSer:boolean = false;
  userDetails: any = {};
  
  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
       this.isHNIUSer = true;
     }
  }
  downloadUserManual(isUserManual) {
    if (isUserManual) {
      let downloadLink = document.createElement("a");
      downloadLink.download = "MyAccount_UserManual";
      downloadLink.href = "assets/pdf/MyAccount_UserManual.pdf";
      downloadLink.click();
    }
  }
}


