<div class="bank-details">
    <div class="neft-details no-mobile">
        <div class="neft-details-header">
            NEFT Details
            <img src="assets/pen-red.svg" class="account-edit" (click)="onNeftEdit()">
            <div class="header-content">Registered bank details for all transactions</div>
        </div>
        <div class="account-div">
            <div class="account-number-div">
                <div class="account-number-title">Account Number</div>
                <div class="account-number" id="account-number">{{neftDtls.ACC_NUM | format}}</div>
            </div>
            <div class="account-details-content">
                <div class="account-details-left border-right">
                    <div class="details-div flex">
                        <img src="assets/bank-blue.svg" class="details-image">
                        <div class="account-details-text">
                            Bank Name
                            <div class="account-details-sub-text">
                                {{neftDtls.BANKNAME | format}}</div>

                        </div>
                    </div>
                    <div class="details-div flex">
                        <img src="assets/person.svg">
                        <div class="account-details-text">
                            Account Holder Name
                            <div class="account-details-sub-text">
                                {{neftDtls.ACCNAME | format}}
                            </div>
                        </div>
                    </div>
                    <div class="details-div flex">
                        <img src="assets/acc-type.svg">
                        <div class="account-details-text">
                            Account Type
                            <div class="account-details-sub-text">
                                {{neftDtls.NEFTACCOUNTTYPE | format}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-details-right">
                    <div class="details-div flex">
                        <img src="assets/ifsc.svg">
                        <div class="account-details-text">
                            IFSC Code
                            <div class="account-details-sub-text">
                                {{neftDtls.IFSCCODE | format}}
                            </div>
                        </div>
                    </div>
                    <div class="details-div flex">
                        <img src="assets/branch name.svg">
                        <div class="account-details-text">
                            Branch Name
                            <div class="account-details-sub-text">
                                {{neftDtls.BRANCHNAME | format}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="neft-details mobile">
        <div class="neft-details-header">
            NEFT Details
            <img src="assets/pen-red.svg" class="account-edit" (click)="onNeftEdit()">
            <div class="header-content">Registered bank details for all transactions</div>
        </div>
        <div class="account-number-div">
            <div class="account-number-title">Account Number</div>
            <div class="account-number" id="account-number">{{neftDtls.ACC_NUM | format}}</div>
        </div>
        <div class="account-details-content-mobile">
            <div class="details-div flex">
                <img src="assets/bank-blue.svg" class="details-image">
                <div class="account-details-text">
                    Bank Name
                    <div class="account-details-sub-text">
                        {{neftDtls.BANKNAME | format}}</div>
                </div>
            </div>
            <div class="details-div flex">
                <img src="assets/person.svg">
                <div class="account-details-text">
                    Account Holder Name
                    <div class="account-details-sub-text">
                        {{neftDtls.ACCNAME | format}}
                    </div>
                </div>
            </div>
            <div class="details-div flex">
                <img src="assets/acc-type.svg">
                <div class="account-details-text">
                    Account Type
                    <div class="account-details-sub-text">
                        {{neftDtls.NEFTACCOUNTTYPE | format}}
                    </div>
                </div>
            </div>
            <div class="details-div flex">
                <img src="assets/ifsc.svg">
                <div class="account-details-text">
                    IFSC Code
                    <div class="account-details-sub-text">
                        {{neftDtls.IFSCCODE | format}}
                    </div>
                </div>
            </div>
            <div class="details-div flex">
                <img src="assets/branch name.svg">
                <div class="account-details-text">
                    Branch Name
                    <div class="account-details-sub-text">
                        {{neftDtls.BRANCHNAME | format}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>