
<div class="cheque-page">
    <div class="main-text">
        <mat-card class="vserv-card">
            <div class="img-div">
                <mat-card-title class="title-text">Cheque pick up</mat-card-title>
            </div>
            <div class="main-content img-div">
            <div class="heading-text">
                Please provide the details below to schedule your cheque pickup
            </div>
            <div class="user form-fields form-label">{{clientDetails?.CICLAIMINTSALUTION}} {{clientDetails?.CIFIRSTNAME}} {{clientDetails?.CILASTNAME}}</div>
<form [formGroup]="chequeForm" autocomplete="off">
    <div class="form-fields">
       
        <div class="form-field-group">
            <div class="form-label">Pin Code<sup>*</sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field">
                <mat-placeholder>Pin Code</mat-placeholder>
                <input formControlName="pincode" type="text" decimalNumbersOnly  matInput
                  [maxLength]="6"  (keyup)="pincodeEntered($event.target.value)">
                <!-- <mat-autocomplete #selectPincode="matAutocomplete">
                    <mat-option *ngFor="let pincode of filteredPincode | async" [value]="pincode">{{pincode}}</mat-option>
            </mat-autocomplete> -->
            <!-- <div class="mat-error error-message" *ngIf="isPinValid== false ">
                Please enter valid pin code
            </div> -->
            <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.pincode.errors?.required)">
                  Please enter Pin Code
                </mat-error> 
                  
            </mat-form-field>
            
        </div>
        <div class="form-field-group dob-group">
            <div class="form-label">Date<sup>*</sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field">
                <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                <input matInput type="text" [min]="startDate" [max]="endDate"  [matDatepickerFilter]="holidays"  [matDatepicker]="picker"
                    formControlName="pickupdate" >
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
               <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.pickupdate.errors?.required)">
                  Please enter  Date 
                </mat-error>
                <mat-error class="error-message"
                *ngIf="chequeForm.controls.pickupdate.hasError('matDatepickerFilter')">
                Invalid  Date 
              </mat-error>
              <mat-error class="error-message"
                *ngIf="chequeForm.controls.pickupdate.hasError('matDatepickerMin')">
                Invalid  Date 
              </mat-error> 
              <mat-error class="error-message"
                *ngIf="chequeForm.controls.pickupdate.hasError('matDatepickerMax')">
                Invalid  Date 
              </mat-error> 
                
            </mat-form-field>
        </div>
        <div class="form-field-group">
            <div class="form-label">Time<sup>*</sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field">
                <mat-placeholder>Time</mat-placeholder>
                <mat-select disableRipple disableOptionCentering class="sum-assured-option" formControlName="pickuptime">
                    <!--(selectionChange)="valueChanged($event)"-->
                    <mat-option *ngFor="let item of timeList" [value]="item">{{item}} </mat-option>
                </mat-select>
                <mat-error class="error-message"
                  *ngIf="this.chequeForm.controls.pickuptime.errors?.required">
                  Please select Time
                </mat-error>  
                
            </mat-form-field>
        </div>
        <div class="form-field-group">
            <div class="form-label">House/Flat Number<sup></sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field" >
                <mat-placeholder>House/Flat Number</mat-placeholder>
                    <!-- <label class="form-field-label">House/Flat Number<span class="error-two">*</span></label> -->
                    <input matInput type="text" #second  formControlName="houseNo" maxlength="30">
                    <mat-error class="error-message"
                    *ngIf="this.chequeForm.controls.houseNo.errors?.required">
                    Please enter House/Flat Number 
                  </mat-error>
            </mat-form-field>
    </div>
    <div class="form-field-group">
        <div class="form-label">Address Line 1<sup>*</sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field" >
                <mat-placeholder>Address Line 1</mat-placeholder>
                   
                    <input matInput type="text" formControlName="address1" #third class="form-field-input" maxlength="30" >
                    <!-- <mat-error class="error-message" *ngIf="changeAddress.get('updateAddressOne').getError('errorMsg')">
                        {{changeAddress.get('updateAddressOne').getError('errorMsg')}}
                    </mat-error> -->
                    <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.address1.errors?.required)">
                  Please enter Address Line 1
                </mat-error>
                </mat-form-field>
    </div>                            
    
    <div class="form-field-group" >
        <div class="form-label">Address Line 2<sup>*</sup></div>
            <mat-form-field floatLabel="never" class="client-login-field login-field"  >
                <mat-placeholder>Address Line 2</mat-placeholder>
            <!-- <label class="form-field-label">Address 2<span class="error-two">*</span></label> -->
            <input matInput type="text" formControlName="address2" class="form-field-input" maxlength="30">
            <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.address2.errors?.required)">
                  Please enter Address Line 2
                </mat-error>
        </mat-form-field>
    </div>
    <div class="form-field-group" >
        <div class="form-label">City<sup>*</sup></div>
        <mat-form-field floatLabel="never" class="client-login-field login-field" >
            <mat-placeholder>City</mat-placeholder>
                    <!-- <label class="form-field-label">City <span class="error-two">*</span></label> -->
                    <input type="text" matInput formControlName="city" [readonly]="cityReadOnly" maxlength="30">
                    <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.city.errors?.required)">
                  Please enter City
                </mat-error>
            </mat-form-field>
</div>
<div class="form-field-group">
    <div class="form-label">Landmark<sup>*</sup></div>
    <mat-form-field floatLabel="never" class="client-login-field login-field"  >
        <mat-placeholder>Landmark</mat-placeholder>
            <!-- <label class="form-field-label">Area/Landmark</label> -->
            <input matInput type="text" class="form-field-input"  formControlName="landMark" maxlength="30">
            <mat-error class="error-message"
                  *ngIf="(this.chequeForm.controls.landMark.errors?.required)">
                  Please enter Landmark
                </mat-error>
        </mat-form-field>
</div>
        
        
        
    </div>
    
    
    <div class="submit-btn">
        <button class="submit" (click)="savedata()" >Book Appointment</button>
        <button class="cancel" (click)="clearFields()">Clear</button>
        <!-- <button class="submit" (click)="validateOtp()" *ngIf="otpFlag" [disabled]="!(this.chequeForm.valid && this.checked)" [class.disable]="!(this.chequeForm.valid && this.checked)">Submit</button> -->
    </div>
</form>
</div>
</mat-card>
</div>
</div>