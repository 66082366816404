import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdateMailingAddressComponent } from 'src/app/modules/my-account/update-mailing-address/update-mailing-address.component';
import { MessageService } from 'src/app/services/message/message.service';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { SrPopupComponent } from '../sr-popup/sr-popup.component';
import { EditCommunicationDetailsComponent } from 'src/app/modules/my-account/edit-communication-details/edit-communication-details.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { DocListPopupComponent } from '../doc-list-popup/doc-list-popup.component';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { ExistingNeftComponent } from '../existing-neft/existing-neft.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import * as moment from 'moment';
import tippy from 'tippy.js';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {

  @Input() uploadDocFlag: any;
  userDetails: any = {};
  isHNIUSer: boolean = false;
  policyNo: string = "";
  CommAddress: any = {
    "CITY": "",
    "COUNTRY": "",
    "HOUSE_NO": "",
    "LANDMARK": "",
    "PINCODE": "",
    "STATE": "",
    "STREET_ADDRESS": ""
  };
  ContactDetail: any = {
    "CONTACT_EMAIL_ADDRESS": "NA",
    "CONTACT_COUNTRY_CD": "NA",
    "CONTACT_CELL_PH_NUMBR": "NA",
    "CONTACT_HOME_PH_NUMBR": "NA",
    "CONTACT_HOME_STD": "NA",
    "CONTACT_WORK_PH_NUMBR": "NA",
    "CONTACT_WORK_STD": "NA",
    "CONTACT_ALT_COUNTRY_CD": "NA",
    "CONTACT_ALT_PH_NUM": "NA",
    "CONTACT_X_ALT_EMAIL": "NA"
  };
  NEFTDetail: any = {
    "ACCNAME": "NA",
    "ACC_NUM": "NA",
    "BANKNAME": "NA",
    "BRANCHNAME": "NA",
    "IFSCCODE": "NA",
    "MICRCODE": "NA",
    "NEFTACCOUNTTYPE": "NA"
  };
  @ViewChild('panDialog') public panDialog: TemplateRef<any>
  @ViewChild('VerifyPayee') public VerifyPayee: TemplateRef<any>
  editPanForm: FormGroup;
  editNeftForm: FormGroup;
  editPremiumForm: FormGroup;
  isPanValid: boolean = false;
  editPanErrMsg: string = '';
  editPanSuccMsg: string = '';
  panAadharLinkErrMsg: string = '';
  panAadharLinkSuccMsg: string = '';
  panAadharLinkCheckMsg: string = '';
  validatePANResp: any = {};
  accountNo:any;
  PremBankName: string;
  accHolderName: string;
  accountType: string;
  ifscCode: string;
  branchName: string;
  payoutData:any;
  payoutDtls:any;
  premiumPaidByNRE: any;
  premiumPaid: any;
  percentage:any;
  clientId: any;
  payeeName: any ="";
  payeeRole: any;
  pdtlsFirstName: any;
  pdtlsLastName: any;
  pdtlsGender: any;
  pdtlsDob: any;
  pdtlsMaritalStatus: any;
  houseNo: string;
  street: string;
  landmark: string;
  pincode:any
  city: string;
  state:string;
  country: string;
  email: any;
  countryCode: any;
  mobile: any;
  phoneNum: any;
  phoneCode: any;
  workPhoneNum: any;
  workPhoneCode: any;
  altMobCode: any;
  altMobNum: any;
  altEmail: any;
  panNum: any;
  panAadharLink: string;
  panVerified: string;
  popupMsg: any;
  popupMsgSecond: boolean = false;
  popupMsgPanNeft: boolean = false;
  popupMsgSecondSurr: boolean = false;
  popupPanMsg: boolean = false;
  chequeNre: boolean = false;
  annuityMsg:boolean = false;
  annuityMsgSecond:boolean = false;
  panNeftFlag:boolean = false;
  KycNeftmsg:boolean = false;
  Kycmsg:boolean = false;
  Neftmsg: boolean = false;
  chequeNeftMsg: any;
  displayPrem: boolean = false;
  chequePanMsg: boolean = false;
  chequeKycMsg: boolean = false;
  parentSr: any;
  paymentMode:any;
  payeeAccount: any;
  displayMsg:boolean = false;
  panPopup:boolean = false;
  chequeUpdMsg:boolean = false;
  premUpdMsg:boolean = false;
  srNum: any=[];
  isKycMessage: string ="Update KYC";
  kycExpiry: string;
  docType: any;
  validityStart: any;
  validityEnd:any;
  id: any;
  annuity:boolean = false;
  surrenderFlag: boolean = false;
  Neft_flag: boolean = false;
  kyc_flag:boolean = false;
  pan_flag: boolean = false;
  chequeDetails:boolean = false;
  payeeDtls:any;
  linkId:any;
  displayNeft: boolean = false;
  isCheque: boolean = false;
  displayCheque: boolean = false;
  displayPremium: boolean = false;
  NEFTVerifiedFlag: boolean = false;
  KycVerifiedFlag: boolean = false;
  updatedDetails: boolean = false
  ACCNAME: string;
  ACC_NUM: string;
  BANKNAME: string;
  BRANCHNAME: string;
  IFSCCODE: string;
  MICRCODE: string;
  NEFTACCOUNTTYPE: any;
  bankName: any;
  accountValue: any;
  editFlag: boolean = true;
  pendingNeft: boolean = false;
  pendingKyc: boolean = false;
  pendingPan: boolean = false;
  pendingNeftSr: any;
  pendingKycSr: any;
  pendingPanSr: any;
  pendingChequeSr: any;
  pendingCheque: boolean = false;
  payeeClientId: any;
  updateKYC: boolean = false;
  kycData: any;
  panRequirementRaised: any;
  panRequirementReceived: any;
  requirementFlag:boolean = false;
  surrendersubmitted: boolean = false;
  validKycCount : boolean = false;
  neftVerify: boolean = false;
  maskedAccNumber: any;
  headSection: boolean = false;
  neft_verified_flag: boolean;
  NEFTDetailAll: any;
  fetchDetailsbtn: boolean;
  @ViewChild('editPan') editPan : any;
  existingAccount: boolean = true;
  // payoutSR: any;
  constructor(public createSrService: CreateSRService, public _httpService: HttpService, public dialog: MatDialog,
    public myAccountService: MyAccountService, public message: MessageService, public dataService: DataService, public utils: Utils, public myPoliciesService: MyPoliciesService, public fb: FormBuilder, public auth: AuthService, public crypto: EncryptDecryptService) { 
      this.myPoliciesService.kycDataObs.subscribe(dtls => {
        this.getKycSrDtls(dtls);
      });
    }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.payeeDtls = this.myPoliciesService.getPayeedetails()
    this.linkId = this.payeeDtls.X_SR_LINK_ID
    // this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
    //   if (policyCardDtls[0] && policyCardDtls[0].POLICYNO) {
    //     this.policyNo = policyCardDtls[0].POLICYNO.replace(/\s+/g, '');
    //   }
    // });
    this.editPremiumForm = this.fb.group({
      "PremBankName": ['',Validators.required]
    })
    this.editPanForm = this.fb.group({
      "panNo": ['', [CustomValidators.panNo]],
      "panName": ['', [CustomValidators.clientName, Validators.required]],
      "panDOB": ['', [CustomValidators.noFutureDates]]
    });
    this.editPanForm.controls.panNo.valueChanges.pipe(distinctUntilChanged()).subscribe(s => { if (s) { this.editPanForm.controls.panNo.patchValue(s.toUpperCase()) }});
    this.editNeftForm = this.fb.group({
      "accNumber": ["", [CustomValidators.accountLength]],
      "accName": ["", [CustomValidators.personName]],
      "accType": ["", [CustomValidators.selectAccountType]],
      "ifscCode": ["", [CustomValidators.ifscCode]],
    });
    this.payoutData = this.myPoliciesService.getPayoutData();
    this.payoutDtls = this.myPoliciesService.getPayoutdetails();
    this.policyNo = this.payoutData.policyNo
    this.premiumPaidByNRE = this.payoutDtls.nrePaid
    this.premiumPaid = this.payoutDtls.totalPremium
    this.id = this.payoutData.id

    //this.payeeName = this.payoutData.FIRSTNAME + " " + this.payoutData.LASTNAME;
    let firstName = this.payoutData.FIRSTNAME;
    let lastName = this.payoutData.LASTNAME;
    if((firstName && lastName)) {
      this.payeeName = firstName + " " +  lastName
    }
    else if((firstName)) {
      this.payeeName = firstName
    }
    else if(lastName) {
      this.payeeName = lastName
    }
    this.payeeRole = this.payoutData.CLIENTROLE;
    let clientNo;
    if(this.id == "surrender" && this.payeeRole == "Beneficiary"){
      clientNo = this.payoutData.claimClientId // beneficiary client id 
    } else{
      clientNo = this.payoutData.clientNo?this.payoutData.clientNo: this.payoutData.CLIENTNO; // owner client id
    }
    this.clientId = clientNo;
    this.payeeClientId = this.payoutData.claimClientId;
    this.percentage = this.payoutData.PERCENTAGE != null ? this.payoutData.PERCENTAGE : "100"
    this.parentSr = this.payoutData.SRNUM;
    if(this.payoutData.paymentMode == "Manual" || this.payoutData.paymentMode == "NEFT & Cheque both"){
    this.paymentMode = "NEFT & Cheque both";
    } else {
      this.paymentMode = this.payoutData.paymentMode;
    }
    this.accountValue = this.payoutData.ACCOUNT_TYPE;
    if(this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO"){
    this.payeeAccount = "Savings/Current/NRO"
    this.displayNeft = true
    this.displayCheque = false
    this.displayPremium = false
    }else if(this.payoutData.ACCOUNT_TYPE == "NRE"){
      this.payeeAccount = "NRE"
      this.displayNeft = true
      this.displayCheque = false
      this.displayPremium = true
      this.isCheque = true
    } else{
      this.payeeAccount = "NRO & NRE"
      this.displayNeft = true
      this.displayCheque = true
      this.displayPremium = true
    }
    if(this.id == "surrender"){
      this.surrenderFlag = true;
      this.headSection = true;
      if(this.uploadDocFlag){
        this.pendingSRdtls();
        this.getPayeeKycDtls();
        this.getPanDtls();
        this.getPALDtls();
        this.getNeft();
        this.getPersonalDtls();
        this.getContactDtls();
        this.goToPayee(this.VerifyPayee);
      } else {
        this.pendingSRdtls();
        this.getPayeeKycDtls();
        this.getPanDtls();
        this.getPALDtls();
        this.getNeft();
        this.getPersonalDtls();
        this.getContactDtls();
      }
    }
    else if(this.id == "annuityPayout"){
      this.annuity = true
      this.getNeft();
    } else{
      this.annuity = false
      this.getNeft();
      this.getPersonalDtls();
      this.getAddressDtls();
      this.getContactDtls();
      this.getPayeeKycDtls();
      this.getPanDtls();
      this.getPALDtls();
    }
  }
  neftDtlsVerify(templateRef: TemplateRef<any>){
    if(this.mobile){
      if((this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag != true))){
      this.neftVerify = true;
      this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if((this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag == true))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if((this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag != true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if((this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag == true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if( (this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag != true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if( (this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag == true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if((this.id == "flic")&&((this.Neft_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag == true))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if((this.id == "flic")&&((this.Neft_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag == true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      } else if( (this.id == "flic")&&((this.Neft_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag == true)))){
        this.neftVerify = true;
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      }
    } else {
      this.neftVerify = true;
      this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
      this.message.openSnackBar("Mobile number & Email ID is not available. Please update the same.", 'xy', ['error-snackbar']);
    }
  }
  goToPayee(templateRef: TemplateRef<any>) {
    this.getKycSrCheck(templateRef);
    window.scrollTo(0, 0);
  }

  maskAccNumber(str,maskValue,n){
    if(str){
    let maskedValue = (''+str).slice(0,-n).replace(/./g,maskValue)+(''+str).slice(-n);
      return  maskedValue;
    } else{
      return str;
    }
  }

  showExistingNeft(accoType){
    if(this.mobile){
      this.getPennyDropCount(accoType,'mandatePenny');

      // this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: {NEFTup:Object.assign({}, this.NEFTDetail), type: accoType, id:this.id, clientid: this.clientId, mobileNo: this.mobile, emailId: this.email,
      // policyNo:this.policyNo,role:this.payeeRole, neftFlag: true, entryFlag: true, parentSr: this.parentSr, existingAccDtls : false, isPennyDrop : true,existingFlag: false }});        
    } else {
      this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: {NEFTup:Object.assign({}, this.NEFTDetail), type: accoType, id:this.id, clientid: this.clientId, mobileNo: this.mobile, emailId: this.email,
    policyNo:this.policyNo,role:this.payeeRole, neftFlag: true, entryFlag: true, parentSr: this.parentSr, existingAccDtls : false, isPennyDrop : false,existingFlag: false }});        
    }

  }

  close(){
    this.dialog.closeAll()
  }
  verify() {
    this.dialog.closeAll()
    if((this.id == "annuityPayout")&&(this.Neft_flag == true)){
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr, policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole}
    });
  } else if((this.id == "maturity" || this.id == "moneyBack")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings")&&((this.pan_flag != true)||(this.pan_flag == true)))){
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME,docUploadFlag: true, uploadFlag: this.uploadDocFlag}
    });
  } else if((this.id == "maturity" || this.id == "moneyBack")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&((this.pan_flag != true)||(this.pan_flag == true))))){
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME, docUploadFlag: true, uploadFlag: this.uploadDocFlag}
    });
  } else if((this.id == "maturity" || this.id == "moneyBack")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&((this.pan_flag != true)||(this.pan_flag == true))))){
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME, docUploadFlag: true}
    });
  } else if ( ( ((this.id == "surrender")&&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag != true))) || ((this.id == "surrender")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag != true)))) || ( (this.id == "surrender")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag != true)))) )){
    if(this.panVerified == "No" || this.panRequirementRaised == "yes" || this.requirementFlag == true){
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME, payeeClientId: this.payeeClientId, docUploadFlag: true, uploadFlag: this.uploadDocFlag}
      });
    }
  } else if (((this.id == "surrender")&&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag == true))) || ((this.id == "surrender")&&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag == true)))) || ((this.id == "surrender")&&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag == true))))){
      this.dialog.open(DocListPopupComponent, {
        panelClass: 'doc-list-dialog',
        backdropClass: 'doc-list-dialog-backdrop',
        disableClose: true,
        data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME, payeeClientId: this.payeeClientId, docUploadFlag: true, uploadFlag: this.uploadDocFlag}
      });
  }
    window.scrollTo(0, 0);
  }
  premiumDtls(){
    this.editFlag = false;
  }
  getKycSrCheck(templateRef){
    let kycreq = {
      "parentSrNum": this.parentSr,
      "policyno":this.policyNo,
      "payeeClients": this.clientId,
      "payeeRole": this.payeeRole
  }
    this.myPoliciesService.getKycPayoutSrCheck(kycreq).subscribe(respbody => {
      let payeeSR = respbody.body.kycpayoutsrCheck[0].payee_sr_list;
      if(respbody.body.kycpayoutsrCheck[0].panRequirementRaised){
      this.panRequirementRaised = respbody.body.kycpayoutsrCheck[0].panRequirementRaised.toLowerCase();
      } else {
        this.panRequirementRaised = "no";
      }
      this.srNum=[]
      let claimFlag:boolean = false;
      let annuityFlag:boolean = false;
      if((this.NEFTVerifiedFlag == true) && (this.panRequirementRaised == "yes") && (this.KycVerifiedFlag == true) &&(this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO")){    //(this.chequeDetails != true)&&
        this.neft_verified_flag = true
        this.Neft_flag = false
        // this.Neft_flag = true
        this.kyc_flag = true
        this.pan_flag = false
        this.displayMsg = true
        this.popupMsg = ""
        this.popupPanMsg = false
        this.popupMsgSecond = false
        this.popupMsgSecondSurr = true
      }
      if((this.panVerified == "Yes") && (this.NEFTVerifiedFlag == true) && (this.KycVerifiedFlag == true)){
        this.neft_verified_flag = true
        this.Neft_flag = false
        this.kyc_flag = true
        this.pan_flag = true
        this.displayMsg = false
          this.panPopup = false
          if((this.id=="surrender" && (this.pendingKyc == false && this.pendingNeft == false)) && ((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO"))){
            this.neftDtlsVerify(this.VerifyPayee);
            this.popupMsgSecond = false;
          } else{
          this.popupMsg = "Please update Premium Payment and Cheque details"
          this.popupMsgSecond = true
          this.neftVerify = false
          }
          this.popupPanMsg = false
      } else if((this.NEFTVerifiedFlag == true) && (this.panVerified != "Yes" && this.pan_flag != true) && (this.KycVerifiedFlag != true)){    //(this.chequeDetails != true)&&
        this.neft_verified_flag = true
        this.Neft_flag = false
        // this.Neft_flag = true
        this.kyc_flag = false
        this.pan_flag = false
        this.displayMsg = true
        this.popupMsg = ""
        this.popupMsg ="Please update PAN and KYC details."
          this.popupPanMsg = false
          this.popupMsgSecond = true
      } else if((this.NEFTVerifiedFlag == true) && (this.panVerified != "Yes") && (this.KycVerifiedFlag == true)){
        this.neft_verified_flag = true
        this.Neft_flag = false
        this.kyc_flag = true
        this.pan_flag = false
        this.displayMsg = false
        this.popupMsg = ""
          this.popupPanMsg = true
          this.panPopup = true
          this.popupMsgSecond = false
          this.popupMsgSecondSurr = false
      } else if((this.KycVerifiedFlag == true) && (this.panVerified != "Yes") && (this.NEFTVerifiedFlag != true)){
        this.kyc_flag = true
        this.pan_flag = false
        this.Neft_flag = false
        this.displayMsg = true
        this.Kycmsg = false
        this.Neftmsg = false
        this.popupMsg = ""
        this.popupMsg = "Please update PAN and Payout/NEFT details."
        this.popupMsgSecond = true
        this.popupPanMsg = false
      } else if((this.KycVerifiedFlag != true) && (this.panVerified == "Yes") && (this.NEFTVerifiedFlag != true)){
        this.kyc_flag = false
        this.pan_flag = true
        this.Neft_flag = false
        this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Payout/NEFT details."
          this.panNeftFlag = false
          this.popupMsgSecond = true
          this.popupPanMsg = false
      } else if((this.KycVerifiedFlag != true) && (this.panVerified == "Yes" || this.pan_flag == true) && (this.NEFTVerifiedFlag == true)){
        this.kyc_flag = false
        this.pan_flag = true
        this.neft_verified_flag = true
        this.Neft_flag = false
        this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update KYC details."
          this.panNeftFlag = false
          this.popupMsgSecond = true
          this.popupPanMsg = false
      }  else if((this.KycVerifiedFlag == true) && (this.panVerified == "Yes" || this.pan_flag == true) && (this.NEFTVerifiedFlag != true)){
        this.kyc_flag = true
        this.pan_flag = true
        this.Neft_flag = false
        this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update NEFT details."
          this.panNeftFlag = false
          this.popupMsgSecond = true
          this.popupPanMsg = false
      }
      if(this.surrendersubmitted != true && (((this.KycVerifiedFlag != true) || (this.panVerified != "Yes") || (this.NEFTVerifiedFlag != true)) || this.payoutData.ACCOUNT_TYPE)){
        let SUB_ISSUE_CD;
        if(this.id == 'surrender' && payeeSR.length == 0){
          SUB_ISSUE_CD = "SURR_PROC";
          payeeSR.push(SUB_ISSUE_CD)
        }
      for(let item of payeeSR){
        if(item.SR_STAT_ID.includes("Pending Requirements")){
        this.srNum.push(item.SR_NUM);
        }
        if(item.SUB_ISSUE_CD == "SURR_PROC" && item.SR_STAT_ID != "Pending Requirements"){
          this.panRequirementReceived = "yes";
        }
        if(this.id == "surrender"){
        if((item.SUB_ISSUE_CD.includes("PAN_CARD_UPD") && (!item.SR_STAT_ID.includes("Cancelled")) ) && (this.panVerified != "Yes")){
          this.pan_flag = true
        } else if(item.SUB_ISSUE_CD.includes("STND_NEFT") && (!item.SR_STAT_ID.includes("Cancelled"))) {  //&& (this.NEFTVerifiedFlag != true)
          this.Neft_flag = true
        } else if((item.SUB_ISSUE_CD.includes("UPD_KYC") && (!item.SR_STAT_ID.includes("Cancelled")) ) && (this.KycVerifiedFlag != true)){
          this.kyc_flag = true
        } else if(item.SUB_ISSUE_CD.includes("SURR_PROC") && (item.SR_STAT_ID.includes("Pending Requirements")) ){
          claimFlag = true
          if(((this.panVerified == "Yes") && (this.NEFTVerifiedFlag == true) && (this.KycVerifiedFlag == true)) && item.SR_STAT_ID.includes("Pending Requirements")){
            this.requirementFlag = true;
          } else {
            this.requirementFlag = false;
          }
        } else if(item.SUB_ISSUE_CD.includes("ACCOUNT_TAGGING") && (!item.SR_STAT_ID.includes("Cancelled"))){
          this.chequeDetails = true
        }
      } else{
        if(item.SUB_ISSUE_CD.includes("PAN_CARD_UPD") || (this.panVerified == "Yes")){
          this.pan_flag = true
        } if(item.SUB_ISSUE_CD.includes("STND_NEFT") || (this.NEFTVerifiedFlag == true)){
          this.Neft_flag = true
        } if(item.SUB_ISSUE_CD.includes("UPD_KYC") || (this.KycVerifiedFlag == true)){
          this.kyc_flag = true
        } if(item.SUB_ISSUE_CD.includes("MAT_CLAIM")||item.SUB_ISSUE_CD.includes("MONY_BK_CLAIM")){
          claimFlag = true
        } if(item.SUB_ISSUE_CD.includes("ANN_PAYOUT")){
          annuityFlag = true
        } if(item.SUB_ISSUE_CD.includes("ACCOUNT_TAGGING")){
          this.chequeDetails = true
        }
      }
    }
      if(this.uploadDocFlag && claimFlag == true){
        this.verify()
      } else {
        // Savings msgs
        if((this.chequeDetails != true)&&((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.panVerified != "Yes" && this.pan_flag != true))&&((claimFlag == true))){
          this.displayMsg = true
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.Kycmsg = false
          this.Neftmsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if((this.Neft_flag != true)&&(annuityFlag == true)){
          this.displayMsg = true
          this.popupMsgSecond = false
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT details."
          this.annuityMsgSecond = false
          this.popupPanMsg = false
          this.Kycmsg = false
          this.Neftmsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if((this.Neft_flag == true)&&(annuityFlag == true)){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsgSecond = false
          this.annuityMsgSecond = true
          this.annuityMsg = false
          this.popupPanMsg = false
          this.Kycmsg = false
          this.Neftmsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&((this.chequeDetails != true)&&(this.Neft_flag == true || this.neft_verified_flag)&&(this.kyc_flag != true)&&(this.pan_flag != true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg ="Please update PAN and KYC details."
          this.popupPanMsg = false
          this.popupMsgSecond = true
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&((this.chequeDetails != true)&&(this.Neft_flag == true || this.neft_verified_flag)&&(this.kyc_flag == true)&&(this.pan_flag != true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.popupMsg = ""
          // this.popupMsg ="Please update PAN and KYC details." this.Neft_flag = true
          if(this.id == "surrender" && this.Neft_flag != true){
            this.neftDtlsVerify(this.VerifyPayee);
            this.popupPanMsg = false
            this.popupMsgSecond = false
            } else {
              this.neftVerify = false;
              this.popupPanMsg = true;
              this.panPopup = true;
              this.popupMsgSecond = false;
              this.popupMsgSecondSurr = false;
            }
       } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&(this.chequeDetails != true)&&((this.NEFTVerifiedFlag != true) && (this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag != true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.Kycmsg = true
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.annuityMsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&((this.neft_verified_flag != true)&&(this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag == true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.annuityMsg = false
          this.KycNeftmsg = false
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") &&((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true))&&(annuityFlag != true)){
          this.displayMsg = true
          this.Kycmsg = false
          this.Neftmsg = false
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Payout/NEFT details."
          this.panNeftFlag = false
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.annuityMsg = false
        } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") && (this.pan_flag == true)&&(this.Neft_flag == true)&&(this.kyc_flag == true)){
          this.displayMsg = true
          this.panPopup = false
          this.popupMsg = ""
          if(this.id=="surrender" && (claimFlag == true && this.neft_verified_flag == true && this.Neft_flag != true)){
            this.neftDtlsVerify(this.VerifyPayee);
            this.popupMsgSecond = false;
          } else{
            if((this.id=="surrender") && (this.pendingKyc == false && this.pendingNeft == false && this.panRequirementRaised != 'yes')){
              this.neftVerify = false;
              this.displayMsg = false;
              this.surrenderSubmit();
            } else {
              this.popupMsgSecondSurr = true
              this.neftVerify = false;
            }
          }
          this.popupPanMsg = false
          this.Kycmsg = false
          this.Neftmsg = false
          this.panNeftFlag = false
          this.KycNeftmsg = false
        } else if(claimFlag == true && (this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") && (this.pan_flag != true)&&(this.Neft_flag == true)&&(this.kyc_flag == true)){
          this.popupMsg = ""
          this.popupMsgSecond = false
          this.neftVerify = false
          if(this.panRequirementReceived != "yes" && this.panRequirementRaised != "yes"){
          this.popupPanMsg = true
          this.panPopup = true
          this.displayMsg = true
          this.popupMsgSecondSurr = false
          } else {
            this.popupMsgSecondSurr = true;
            this.displayMsg = true;
          }
      } else if((this.payoutData.ACCOUNT_TYPE != "NRE") && (this.payoutData.ACCOUNT_TYPE != "NRE and NRO") && (this.pan_flag != true)&&(this.Neft_flag == true || this.neft_verified_flag == true)){
          this.popupMsg = ""
          this.popupMsgSecond = false
          this.popupMsgSecondSurr = false
          if(this.id=="surrender" && this.Neft_flag != true){
            this.neftDtlsVerify(this.VerifyPayee);
          } else if(this.panRequirementReceived != "yes" && this.panRequirementRaised != "yes"){
          this.popupMsgSecondSurr = false
          this.popupPanMsg = true
          this.panPopup = true
          this.displayMsg = true
          this.neftVerify = false
          // this.neftVerify = false
          } else {
            this.neftVerify = false
            this.popupMsgSecondSurr = true
            this.displayMsg = true;
            // this.flicSubmit();
          }
        }
        // NRE msgs
        if((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid) && (this.KycVerifiedFlag == true && this.NEFTVerifiedFlag == true && this.panVerified == "Yes" && this.requirementFlag == true)){
          this.panPopup = false;
          this.popupPanMsg = false;
          this.popupMsgSecond = false;
          this.popupMsgSecondSurr = false;
          this.popupMsg = ""
          this.displayMsg = true;
          if(this.id == "surrender" && this.Neft_flag != true){
            this.neftDtlsVerify(this.VerifyPayee);
          } else{
            this.annuityMsgSecond = true;
            this.neftVerify = false;
          }
        } else if((this.payoutData.ACCOUNT_TYPE == "NRE") && !(this.editPremiumForm.controls.PremBankName.valid) &&((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if((this.payoutData.ACCOUNT_TYPE == "NRE") && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC, Payout/NEFT and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if((this.payoutData.ACCOUNT_TYPE == "NRE") && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if((this.payoutData.ACCOUNT_TYPE == "NRE") && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, KYC and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true))) {
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true))) {
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true || this.panRequirementRaised == "yes"))) {
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.panVerified != "Yes" && this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.neftVerify = false;
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true || this.panRequirementRaised == "yes")) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true || this.panRequirementRaised == "yes")) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true || this.panVerified == "Yes" || this.panRequirementRaised == "yes")) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Premium Payment details."
          this.popupMsgSecond = false
          this.popupPanMsg = false
          this.panPopup = false
          this.popupMsgPanNeft = true
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified == "Yes" || this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupMsgPanNeft = false
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Payout/NEFT details."
          this.popupMsgSecond = false
          this.popupMsgPanNeft = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true)&&(this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT and Premium Payment details."
          this.popupMsgPanNeft = true
          this.popupMsgSecond = false
          this.popupPanMsg = false
          this.panPopup = false
        } else if(claimFlag == true && this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.popupMsg = ""
          this.popupMsgSecond = false
          this.popupMsgSecondSurr = false
          if(this.id=="surrender" && this.neft_verified_flag == true && this.Neft_flag != true){
            this.neftDtlsVerify(this.VerifyPayee)
            this.popupPanMsg = false
            this.panPopup = false
          } else if(this.panRequirementReceived != "yes" && this.panRequirementRaised != "yes"){
            this.popupPanMsg = true
            this.panPopup = true
            this.neftVerify = false
            this.displayMsg = true
            } else {
              this.neftVerify = false
              this.popupMsgSecond = true;
              this.displayMsg = true;
          }
        } else if(this.payoutData.ACCOUNT_TYPE == "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsgPanNeft = false
          if(this.id=="surrender" && this.neft_verified_flag == true && this.Neft_flag != true){
            this.neftDtlsVerify(this.VerifyPayee)
            this.popupMsgSecond = false
          } else{
          this.popupMsgSecond = true
          this.neftVerify = false
          }
          this.popupPanMsg = false
          this.panPopup = false
        }
        // NRO msg
         if(this.displayCheque == true && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) &&((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if(this.displayCheque == true && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) &&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true || this.panVerified == "Yes"))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Premium Payment and Cheque details"
          this.popupMsgSecond = true
          this.popupPanMsg = false
        } else if(this.displayCheque == true && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) &&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.displayCheque == true && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) &&((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag != true))){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true  && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true || this.neft_verified_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, KYC, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true  && (this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true || this.neft_verified_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, KYC and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true  && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, KYC, Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT, KYC, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified !="Yes" && this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.annuityMsgSecond = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT, KYC and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified != "Yes" && this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(claimFlag == true && this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.panVerified != "Yes" && this.pan_flag != true)) && this.displayPremium == true){
          this.popupMsg = ""
          this.chequeNeftMsg = false
          this.chequeNre = false
          if(this.id=="surrender" && this.neft_verified_flag == true && this.Neft_flag != true){
            this.displayMsg = false;
            this.neftDtlsVerify(this.VerifyPayee)
            this.popupMsgSecond = false
            this.popupPanMsg = false
            this.panPopup = false
            this.displayMsg = false
          } else{
            this.popupPanMsg = true
            this.panPopup = true
            this.displayMsg = true
            this.neftVerify = false
          }
          this.popupMsgSecond = false
          this.popupMsgSecondSurr = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.chequeNeftMsg = false
          this.chequeNre = false
          this.popupPanMsg = false
          if(this.id=="surrender" && (claimFlag == true && this.Neft_flag != true && this.pendingKyc == false && this.pendingNeft == false && this.pendingCheque == false)){
            this.neftDtlsVerify(this.VerifyPayee)
            this.popupMsgSecond = false
          } else{
          this.popupMsgSecond = true
          this.neftVerify = false;
          }
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.panVerified == "Yes" || this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.annuityMsgSecond = false
          this.panPopup = false
          this.neftVerify = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true || this.panVerified == "Yes")) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.annuityMsgSecond = false
          this.panPopup = false
          this.neftVerify = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.neftVerify = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag == true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag == true || this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayCheque == true && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC, and PAN details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update PAN, Payout/NEFT and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayCheque == true && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag != true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and PAN details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.Neft_flag == true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC, Payout/NEFT and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC and Payout/NEFT details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails == true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified == "Yes" || this.pan_flag == true)) && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT and Premium Payment details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && !(this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified == "Yes" || this.pan_flag == true)) && this.displayCheque == true && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT, Premium Payment and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.annuityMsgSecond = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag != true)&&(this.pan_flag == true)) && this.displayCheque == true && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update KYC, Payout/NEFT and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
        } else if(this.payoutData.ACCOUNT_TYPE != "NRE" && this.chequeDetails != true && (this.editPremiumForm.controls.PremBankName.valid) && ((this.neft_verified_flag != true && this.Neft_flag != true)&&(this.kyc_flag == true)&&(this.panVerified == "Yes" || this.pan_flag == true)) && this.displayCheque == true && this.displayPremium == true){
          this.displayMsg = true
          this.popupMsg = ""
          this.popupMsg = "Please update Payout/NEFT and Cheque details."
          this.popupMsgSecond = true
          this.popupPanMsg = false
          this.panPopup = false
          this.annuityMsgSecond = false
        }
      }
      if((this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO") && (this.NEFTVerifiedFlag == true && this.Neft_flag == false) && (this.panRequirementRaised == "yes" || this.panVerified == "Yes") && (this.KycVerifiedFlag == true || this.kyc_flag == true)){    //(this.chequeDetails != true)&&
        this.Neft_flag = false
        this.neft_verified_flag = true
        this.kyc_flag = true
        this.pan_flag = false
        this.popupMsg = ""
        this.popupPanMsg = false
        this.popupMsgSecond = false
        this.panPopup = false
        if(this.id == "surrender"){
          this.neftDtlsVerify(this.VerifyPayee);
          this.popupMsgSecondSurr = false;
          this.displayMsg = false;
        } else {
          this.neftVerify = false;
          this.displayMsg = true
          this.popupMsgSecondSurr = true
        }
      } else  if((this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO") && (this.neft_verified_flag == true && this.Neft_flag != true) && (this.panRequirementRaised == "yes" || this.panVerified == "Yes") && (this.KycVerifiedFlag == true || this.kyc_flag == true) && this.id == "flic"){    //(this.chequeDetails != true)&&
        this.Neft_flag = false
        this.neft_verified_flag = true
        this.kyc_flag = true
        this.pan_flag = false
        this.popupMsg = ""
        this.popupPanMsg = false
        this.popupMsgSecond = false
        this.panPopup = false
        if(this.id == "flic"){
          this.neftDtlsVerify(this.VerifyPayee);
          this.popupMsgSecondSurr = false;
          this.displayMsg = false;
        } else {
          this.displayMsg = true
          this.popupMsgSecondSurr = true
          this.neftVerify = false
        }
      } else if((this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO") && (this.NEFTVerifiedFlag == true && this.Neft_flag == false) && (this.panRequirementRaised != "yes" || this.panVerified == "Yes") && (this.KycVerifiedFlag == true)){    //(this.chequeDetails != true)&&
        this.Neft_flag = false
        this.kyc_flag = true
        this.pan_flag = false
        this.popupMsg = ""
        this.popupMsgSecond = false
        if(this.id == "surrender" && this.neft_verified_flag == true){
          this.neftDtlsVerify(this.VerifyPayee);
          this.panPopup = false
          this.popupPanMsg = false
          this.popupMsgSecondSurr = false
        } else if(this.panRequirementRaised != "yes"){
          this.panPopup = true
          this.popupPanMsg = true
          this.neftVerify = false;
          this.popupMsgSecondSurr = false;
          } else if((this.id=="surrender") && (this.pendingKyc == false && this.pendingNeft == false && this.panRequirementRaised != 'yes')){
            this.neftVerify = false;
            this.displayMsg = false;
            this.surrenderSubmit();
          } else {
            this.neftVerify = false;
            this.panPopup = false
            this.popupPanMsg = false
            this.displayMsg = true
            this.popupMsgSecond = true
          }
        // }
      } else if(claimFlag == true && (this.panRequirementRaised == "yes") && (((this.id == "surrender")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && (this.payoutData.ACCOUNT_TYPE == "Savings" || this.payoutData.ACCOUNT_TYPE == "Current" || this.payoutData.ACCOUNT_TYPE == "NRO")&&(this.pan_flag != true))) || ((this.id == "surrender")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag != true)))) || ( (this.id == "surrender")&&((this.Neft_flag == true)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE") && (this.editPremiumForm.controls.PremBankName.valid)&&(this.pan_flag != true)))))){
        if(this.panRequirementReceived != "yes" && this.panRequirementRaised != "yes"){
        this.Neft_flag = true
        this.neftVerify = false
        this.kyc_flag = true
        this.pan_flag = false
        this.displayMsg = true
        this.popupMsg = ""
        if(!this.pan_flag && !this.pendingNeft && !this.pendingKyc){
          this.panPopup = false;
          this.popupPanMsg = false;
          this.popupMsgSecond = false
          this.popupMsgSecondSurr = true;
          this.popupMsgPanNeft = false;
          this.annuityMsgSecond = false;
        } else if(!this.pan_flag && this.pendingNeft && this.pendingKyc){
          this.panPopup = false;
          this.popupPanMsg = false;
          this.popupMsgSecond = true;
          this.popupMsgSecondSurr = false;
          this.popupMsgPanNeft = false;
          this.annuityMsgSecond = false;
        } else if(!this.pan_flag && this.pendingNeft && !this.pendingKyc){
          this.panPopup = false;
          this.popupPanMsg = false;
          this.popupMsgSecond = false;
          this.popupMsgSecondSurr = false;
          this.popupMsgPanNeft = true;
          this.annuityMsgSecond = false;
        } else if(this.pan_flag && this.pendingNeft && !this.pendingKyc){
          this.panPopup = false;
          this.popupPanMsg = false;
          this.popupMsgSecond = false;
          this.popupMsgSecondSurr = false;
          this.annuityMsgSecond = true;
        } else{
        this.panPopup = true;
        this.popupPanMsg = true;
        this.popupMsgSecondSurr = false;
        this.popupMsgPanNeft = false;
        this.annuityMsgSecond = false;
        }
      } else {
        this.Neft_flag = true
        this.neftVerify = false
        this.kyc_flag = true
        this.pan_flag = false
        if(this.panRequirementReceived != "yes" && this.panRequirementRaised == "yes"){
        this.displayMsg = true;
        this.popupMsgSecondSurr = true
        this.popupMsg = ""
        this.panPopup = false;
        this.popupPanMsg = false;
        }
      }
    } else if(claimFlag == true && (this.panRequirementRaised == "yes") && ((this.id == "surrender")&&((this.neft_verified_flag == true && this.Neft_flag != false)&&(this.kyc_flag == true) && ((this.payoutData.ACCOUNT_TYPE == "NRE and NRO") && ((this.chequeDetails == true) && (this.editPremiumForm.controls.PremBankName.valid))&&(this.pan_flag != true))))){
        this.neftDtlsVerify(this.VerifyPayee);
      }
    }
    if(!this.uploadDocFlag){
    if(this.displayPremium == true && this.editPremiumForm.controls.PremBankName.valid && (!(this.bankName == this.editPremiumForm.controls.PremBankName.value)||(!this.updatedDetails))){
      this.premiumDetails();
    }
      if(this.displayMsg == true){
        this.dialog.open(templateRef, { width: '600px', panelClass: 'surrender-quote-popup', backdropClass: 'surrender-quote-backdrop', disableClose: true });
        }
    } else {
      this.message.openSnackBar("Your surrender request is already in process", 'xy', ['error-snackbar']);
    }
    })
  }
  editBankName(){
    this.bankName = this.editPremiumForm.controls.PremBankName.value
  }
  premiumDetails(){
    let reqBody = {
      "clientno": this.clientId,
      "srnum": this.parentSr,
      "payoutmode": this.paymentMode,
      "accountValue": this.accountValue,
      "totalprempaid": this.premiumPaid,
      "nreprempaid": this.premiumPaidByNRE,
      "bankname": this.editPremiumForm.controls.PremBankName.value
    }
    return this.myPoliciesService.updatePremiumDtls(reqBody).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)){
        this.updatedDetails = true
      }
    });
  }
  PANredirect(type:String){
    let reqBody = {
      "genpan_client": this.clientId,
      "clientId":this.clientId,
      "genpan_sr": this.parentSr,
      "genpan_fn":this.payoutData.FIRSTNAME,
      "genpan_ln": this.payoutData.LASTNAME,
      "genpan_role": this.payeeRole
  }
  this.myPoliciesService.getPANreq(reqBody).subscribe(respbody => {
    if(this.srNum == ""){
      this.srNum.push(respbody.body.creatupdtrqmntres[0].srnum)
    }
    if(type == "editPAN"){
      this.dialog.closeAll();
      this.pan_flag = true
    }
  })
  this.displayMsg = true
  if(this.id=="surrender" && (this.Neft_flag != true && this.neft_verified_flag == true)){
    this.pan_flag = true;
    this.neftDtlsVerify(this.VerifyPayee);
    // this.popupMsgSecondSurr = true
  } else{
  this.popupMsgSecond = true
  this.neftVerify = false
  }
  // this.popupMsgSecond = true
  this.popupPanMsg = false
  this.Neftmsg = false
  this.Kycmsg = false
  this.panPopup = false
  }
  getNeft(){
    let neftreq = {
      "policyno":this.policyNo,
      "clientID":this.clientId
  }
    this.myPoliciesService.getPayoutNEFT(neftreq).subscribe(respbody => {
      if(respbody.body.NEFTAccountDetails == null){
        this.NEFTDetail.ACCNAME = "NA";
        this.NEFTDetail.ACC_NUM = "NA";
        this.NEFTDetail.BANKNAME = "NA";
        this.NEFTDetail.BRANCHNAME = "NA";
        this.NEFTDetail.IFSCCODE = "NA";
        this.NEFTDetail.BRANCHNAME = "NA";
        this.NEFTDetail.MICRCODE = "NA";
        this.NEFTDetail.NEFTVerifiedFlag = false;
        this.existingAccount = false;
      } else if(this.payeeRole == "Beneficiary"){
          this.NEFTDetail.BEN_ACCNUMBER = respbody.body.NEFTAccountDetails.ACC_NUM;
          this.NEFTDetail.BEN_BANKNAME = respbody.body.NEFTAccountDetails.BANK_NAME;
          this.NEFTDetail.BEN_ACCNAME = respbody.body.NEFTAccountDetails.ACC_HOLDER_NAME;
          this.NEFTDetail.BEN_BRANCHNAME = respbody.body.NEFTAccountDetails.BRANCH_NAME;
          this.NEFTDetail.BEN_IFSCCODE = respbody.body.NEFTAccountDetails.IFSC_CODE;
          this.NEFTDetail.MICRCODE = "NA";
          this.NEFTDetail.BEN_ACCTYPE = respbody.body.NEFTAccountDetails.NEFT_ACCOUNT_TYPE;
          this.maskedAccNumber = this.maskAccNumber(this.NEFTDetail.BEN_ACCNUMBER,'X',4);
          if (this.NEFTDetail.BEN_ACCNUMBER != "" && this.NEFTDetail.BEN_ACCNUMBER != undefined && this.NEFTDetail.BEN_BANKNAME != "" && this.NEFTDetail.BEN_BANKNAME != undefined && this.NEFTDetail.BEN_ACCNAME != "" && this.NEFTDetail.BEN_ACCNAME != undefined && this.NEFTDetail.BEN_ACCTYPE != "" && this.NEFTDetail.BEN_ACCTYPE != undefined && this.NEFTDetail.BEN_IFSCCODE != "" && this.NEFTDetail.BEN_IFSCCODE != undefined && this.NEFTDetail.BEN_BRANCHNAME != "" && this.NEFTDetail.BEN_BRANCHNAME != undefined){
            this.NEFTVerifiedFlag = true;
            this.neft_verified_flag = true;
          }
      } else{
        this.NEFTDetail.ACC_NUM = respbody.body.NEFTAccountDetails.ACC_NUM;
        this.NEFTDetail.BANKNAME = respbody.body.NEFTAccountDetails.BANK_NAME;
        this.NEFTDetail.ACCNAME = respbody.body.NEFTAccountDetails.ACC_HOLDER_NAME;
        this.NEFTDetail.NEFTACCOUNTTYPE = respbody.body.NEFTAccountDetails.NEFT_ACCOUNT_TYPE;
        this.NEFTDetail.IFSCCODE = respbody.body.NEFTAccountDetails.IFSC_CODE;
        this.NEFTDetail.BRANCHNAME = respbody.body.NEFTAccountDetails.BRANCH_NAME;
        this.NEFTDetail.MICRCODE = "NA";
        this.maskedAccNumber = this.maskAccNumber(this.NEFTDetail.ACC_NUM,'X',4);
        if(this.NEFTDetail.ACC_NUM != "" && this.NEFTDetail.ACC_NUM != undefined && this.NEFTDetail.BANKNAME != "" && this.NEFTDetail.BANKNAME != undefined && this.NEFTDetail.ACCNAME != "" && this.NEFTDetail.ACCNAME != undefined && this.NEFTDetail.NEFTACCOUNTTYPE != "" && this.NEFTDetail.NEFTACCOUNTTYPE != undefined && this.NEFTDetail.IFSCCODE != "" && this.NEFTDetail.IFSCCODE != undefined && this.NEFTDetail.BRANCHNAME != "" && this.NEFTDetail.BRANCHNAME != undefined){
          this.NEFTVerifiedFlag = true;
          this.neft_verified_flag = true;
        }
      }
    })
  }
  getPersonalDtls(){
    let reqBody = {
      "policyno":this.policyNo,
      "clientID":this.clientId
  }
    this.myPoliciesService.getPayeePersonalDtls(reqBody).subscribe(respbody => {
      this.pdtlsFirstName = respbody.body.payeePersonalDetails[0].PDTLS_FST_NAME;
      this.pdtlsLastName = respbody.body.payeePersonalDetails[0].PDTLS_LAST_NAME;
      this.pdtlsGender = respbody.body.payeePersonalDetails[0].PDTLS_GENDER;
      this.pdtlsDob = respbody.body.payeePersonalDetails[0].PDTLS_DOB;
      this.pdtlsMaritalStatus = respbody.body.payeePersonalDetails[0].PDTLS_MARITAL_STTS;
    })
  }
  getAddressDtls(){
    let reqBody = {
      "policyno":this.policyNo,
      "clientID":this.clientId
  }
  this.myPoliciesService.getPayeeAddressDtls(reqBody).subscribe(respbody => {
    let addrsDtls = respbody.body.payeeAddressDetails
    if(addrsDtls == null){
      this.houseNo = "NA";
      this.street = "NA";
      this.landmark = "NA"
      this.city = "NA";
      this.state = "NA";
      this.pincode = "NA"
      this.country = "NA";
    }
    else{
      this.houseNo = addrsDtls[0].HOUSENUMBR_ADDRESS_DTLS;
      this.street = addrsDtls[0].STREET_ADDRESS_DTLS;
      this.landmark = addrsDtls[0].LANDMARK_ADDRESS_DTLS
      this.city = addrsDtls[0].CITY_ADRRESS_DTLS;
      this.pincode = addrsDtls[0].PINCODE_ADDRESS_DTLS;
      this.state = addrsDtls[0].STATE_ADRRESS_DTLS;
      this.country = addrsDtls[0].COUNTRY_ADRRESS_DTLS;
    }
  })
  }
  getContactDtls(){
    let reqBody = {
      "policyno":this.policyNo,
      "clientID":this.clientId
  }
  this.myPoliciesService.getPayeeContactDtls(reqBody).subscribe(respbody => {
    let contactDtls = respbody.body.payeeContactDetails
    for(let item of contactDtls){
    this.email = item.CONTACT_EMAIL_ADDRESS;
    this.countryCode = item.CONTACT_COUNTRY_CD;
    this.mobile = item.CONTACT_CELL_PH_NUMBR;
    this.phoneNum = item.CONTACT_HOME_PH_NUMBR;
    this.phoneCode = item.CONTACT_HOME_STD;
    this.workPhoneNum = item.CONTACT_WORK_PH_NUMBR;
    this.workPhoneCode = item.CONTACT_WORK_STD;
    this.altMobCode = item.CONTACT_ALT_COUNTRY_CD;
    this.altMobNum = item.CONTACT_ALT_PH_NUM;
    this.altEmail = item.CONTACT_X_ALT_EMAIL
    }
  })
  }
  getPayeeKycDtls(){
    let reqBody = {
      "policyno": this.policyNo,
      "clientID": this.clientId
  }
  this.myPoliciesService.getPayeeKycDtls(reqBody).subscribe(respbody => {
    if (this._httpService.isSuccessResponse(respbody)) {
      if(respbody.body && respbody.body.payeeckycdetails[0].DAY_DIFF && respbody.body.payeeckycdetails[0].DAY_DIFF < 0){
        this.isKycMessage="Expired";
      }
      else if(respbody.body && respbody.body.payeeckycdetails[0].DAY_DIFF && respbody.body.payeeckycdetails[0].DAY_DIFF > 0){
        this.isKycMessage="Active";
        this.KycVerifiedFlag = true;
        this.updateKYC = true;
        this.kyc_flag = true
      }
      else{ 
        this.isKycMessage= "NA";
      }
      if(respbody.body && respbody.body.payeeckycdetails[0].KYC_VAL_DT) {
        this.kycExpiry= respbody.body.payeeckycdetails[0].KYC_VAL_DT;
      }
      else{ 
        this.kycExpiry= "NA";
      }
      if(respbody.body && respbody.body.payeeckycdetails[0].IDEN_PROOF) {
        this.docType=  respbody.body.payeeckycdetails[0].IDEN_PROOF;
      }
      else{ 
        this.docType= "NA";
      }
      if(respbody.body && respbody.body.payeeckycdetails[0].IDEN_PROOF_ST_DT) {
        this.validityStart= respbody.body.payeeckycdetails[0].IDEN_PROOF_ST_DT;
      }
      else{ 
        this.validityStart= "NA";
      }
      if(respbody.body && respbody.body.payeeckycdetails[0].IDEN_PROOF_EXP_DT) {
        this.validityEnd= respbody.body.payeeckycdetails[0].IDEN_PROOF_EXP_DT;
      }
      else{ 
        this.validityEnd= "NA";
      }
    } 
    else {
      this._httpService.apiErrorHandler(respbody);
    }
  })
  }
  getPanDtls(){
    let reqBody = {
      "policyno": this.policyNo,
      "clientID": this.clientId
  }
  this.myPoliciesService.getPanDtls(reqBody).subscribe(respbody => {
    this.panNum = respbody.body.payeepandetails.PAN_NO;
    this.panVerified = respbody.body.payeepandetails.ISPANVERIFIED == "Y" ? "Yes":"No";
  })
  }
  getPALDtls(){
    let reqBody = {
      "clientID": this.clientId
  }
  this.myPoliciesService.getPALDtls(reqBody).subscribe(respbody => {
    this.panAadharLink = respbody.body.pan_adhar_flg == "Y" ? "Yes":"No";
    // this.panAadharLink = respbody.body.payeepandetails.ISPANVERIFIED == "Y" ? "Yes":"No";
  })
  }
  updateMailingAddressPersonal() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_NAME'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        const dialogRef = this.dialog.open(UpdateMailingAddressComponent,
          {
            panelClass: 'app-update-mailing-address',
            backdropClass: 'edit-communication-backdrop',
            data: {
              policyNo: this.policyNo, role: this.payeeRole, parentSr: this.parentSr,id:this.id,
              manualDivFlag: true, entryFlag: true, CheckboxFlag: true, editNameFlag: true, clientId: this.clientId
            }, disableClose: true
          },
        );
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  updateMailingAddress(addressType: string) {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_ADDRESS',
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        const dialogRef = this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: { ComAdd: Object.assign({}, this.CommAddress), type: addressType, role: this.payeeRole,  clientId: this.clientId,parentSr: this.parentSr, policyNo: (this.policyNo).replace(/\s+/g, ''), manualDivFlag: true, entryFlag: true, CheckboxFlag: true, addPermanentAddressFlag: true } },
        );
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  editCommunicationDetails() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_CON_NUM'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.dialog.open(EditCommunicationDetailsComponent,
          {
            disableClose: true,
            panelClass: 'app-edit-communication-details',
            backdropClass: 'edit-communication-backdrop',
            data: {
              dtls: Object.assign({}, this.ContactDetail),
              policyNo: this.policyNo,
              role: this.payeeRole,
              clientId: this.clientId,
            }
          },
        );
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.dialog.open(SrPopupComponent,
          {
            disableClose: true,
            panelClass: 'app-sr-popup',
            backdropClass: 'sr-popup-backdrop',
            data: {
              inputText: resp.body.errormsg
            }
          },
        );
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  updateNeftDetails(accoType: string) {


    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'STND_NEFT',
      // clientid: this.clientId,
      // mode: 'all'
    }
    let accBody = {
      clientid: this.clientId,
      policyno: this.policyNo,
      mode: 'all'
    }
    this.myAccountService.getNEFTDetails(accBody).subscribe((response)=>{
      if (this._httpService.isSuccessResponse(response)) {
        this.NEFTDetailAll = response.body;
        
        if(this.NEFTDetailAll.length > 0){
          this.existingAccount = true;
        }else{
          this.existingAccount = false;
        }

     } else{
         this.NEFTDetailAll = response.body;
     }
   })


    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.getPennyDropCount(accoType,'editNeft');
       // this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: {NEFTup:Object.assign({}, this.nomineeResponseDetails), clientid: this.nomineeResponseDetails.CLIENTID, type: accoType, id:"beneficiary", policyNo:(this.carouselDetailsPolicyNo).replace(/\s+/g,''),role:role, neftFlag: true, entryFlag: true} });        
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));    
  }
  updateChequeDetails(accoType: string){
    let chequeDetail = {
      "ACCNAME": "NA",
      "ACC_NUM": "NA",
      "BANKNAME": "NA",
      "BRANCHNAME": "NA",
      "IFSCCODE": "NA",
      "MICRCODE": "NA",
      "NEFTACCOUNTTYPE": "NA"
    };
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'ACCOUNT_TAGGING',
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.dialog.open(ExistingNeftComponent, { 
          panelClass: 'existing-neft-popup', backdropClass: 'existing-neft-popup-backdrop', disableClose: true, 
          data: {NEFTup:Object.assign({}, chequeDetail), type: accoType, id:this.id, clientid: this.clientId,
           policyNo:this.policyNo,role:this.payeeRole, neftFlag: false, chequeFlag: true, entryFlag: true, parentSr: this.parentSr,isPennyDrop: false} }); 
        // this.getPennyDropCount(accoType);
       // this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: {NEFTup:Object.assign({}, this.nomineeResponseDetails), clientid: this.nomineeResponseDetails.CLIENTID, type: accoType, id:"beneficiary", policyNo:(this.carouselDetailsPolicyNo).replace(/\s+/g,''),role:role, neftFlag: true, entryFlag: true} });        
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
       }
  getPennyDropCount(accoType,type) {
    let reqBody = {
      clientId: this.clientId,
    };
    return this.createSrService.getPennyDropCount(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if(resp.body.pennyStatus == true){
          this.fetchDetailsbtn = true
     }  
        if(type == 'editNeft' && (this.existingAccount || this.NEFTDetailAll.length > 0) ){
        this.dialog.open(ExistingNeftComponent, {
          panelClass: 'existing-neft-popup', backdropClass: 'existing-neft-popup-backdrop',disableClose: true, 
          data: {NEFTup:Object.assign({}, this.NEFTDetail), type: accoType, id:this.id, clientid: this.clientId, fetchDetailsbtn : this.fetchDetailsbtn,mobileNo: this.mobile, emailId: this.email, bankname : true,
           policyNo:this.policyNo,role:this.payeeRole, neftFlag: true, entryFlag: true, parentSr: this.parentSr, isPennyDrop: resp.body.pennyStatus,} });
        } else {
          this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', disableClose: true, data: {NEFTup:Object.assign({}, this.NEFTDetail), type: accoType, id:this.id, fetchDetailsbtn : this.fetchDetailsbtn,showFetch:true,clientid: this.clientId, mobileNo: this.mobile, emailId: this.email,
          policyNo:this.policyNo,role:this.payeeRole, neftFlag: true, entryFlag: true, parentSr: this.parentSr, existingAccDtls : false, isPennyDrop : resp.body.pennyStatus,existingFlag: false,bankname : false }});        
        }
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    });
  }
  updatePanDetails(templateRef: TemplateRef<any>) {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'PAN_CARD_UPD'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        // this.dialog.open(templateRef, { width: '500px', panelClass: 'update-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
        this.editPanDetails(this.editPan);
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  editPanDetails(templateRef: TemplateRef<any>) {
    // let isVirtualBranch = this.deeplinkOperation?true:false
    let panNum: string = '';
    panNum = this.panNum;
    this.editPanForm.controls.panNo.setValue(panNum);
    this.editPanForm.controls.panName.setValue('');
    if (this.pdtlsDob && this.pdtlsDob != 'NA') {
      let dob = moment(this.pdtlsDob, 'DD/MM/YYYY');
      this.editPanForm.controls.panDOB.setValue(dob);
    } else {
      this.editPanForm.controls.panDOB.setValue('');
      setTimeout(() => tippy(".tooltip-popover-pan-dob", { content: "In case of corporate entities, please enter Date of Incorporation", theme: "light-border-pan" }), 500);      
    }
    this.isPanValid = false;
    this.validatePANResp = {};
    this.editPanErrMsg = '';
    this.editPanSuccMsg = '';
    this.panAadharLinkErrMsg = '';
    this.panAadharLinkCheckMsg = '';
    this.panAadharLinkSuccMsg = '';
    this.dialog.closeAll();
    this.dialog.open(templateRef, { width: '500px', panelClass: 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
  }
  submitEditPanForm() {
    if (this.editPanForm && this.editPanForm.valid) {
      this.validatePAN();
    } else {
      this.editPanForm.markAllAsTouched();
    }
  }
  validatePAN() {
    let panFirstName;
    let panLastName;
    if(this.payeeRole == "Beneficiary"){
      panFirstName = this.payoutData.FIRSTNAME;
      panLastName = this.payoutData.LASTNAME;
    } else {
      panFirstName = this.userDetails.FIRST_NM;
      panLastName = this.userDetails.LAST_NM;
    }
    let panno = this.editPanForm.controls.panNo.value;
    let reqBody = {
      userid: this.clientId,
      pan: panno,
      uname: this.editPanForm.controls.panName.value,
      udob: moment(this.editPanForm.controls.panDOB.value).format('DD/MM/YYYY'),
    }
    return this.createSrService.panValidation(reqBody, "validatePanAadhar").subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.error_msg) {
          this.panAadharLinkErrMsg = resp.body.error_msg;
        }
        if (resp.body && resp.body.succ_msg) {
          this.panAadharLinkSuccMsg = resp.body.succ_msg;
        }
        if (resp.body && resp.body.check_msg) {
          this.panAadharLinkCheckMsg = resp.body.check_msg;
        }
        if (resp.body && resp.body.isValid === "YES") {
          //this.validatePANResp = resp.body.pandtls[0];
          this.validatePANResp["panno"] = panno;
          this.validatePANResp["aadharseedingstatus"] = resp.body.aadharseedingstatus;
          this.isPanValid = true;
          this.editPanSuccMsg = resp.body.msg;
          this.createUpdatePanSR();
          this.panVerified = "Yes";
          this.panNum = this.editPanForm.controls.panNo.value;

        } else {
          this.editPanErrMsg = resp.body.msg;
        }
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  createUpdatePanSR() {
    //let isVirtualBranch = this.deeplinkOperation?true:false
    const reqBody = {
      policyno: this.policyNo,
      clientid: this.clientId,
      role: this.payeeRole,
      subactivity: 'PAN_CARD_UPD',
      action: "CREATE",
      panno: this.validatePANResp.panno,
      parentsrno : this.parentSr,
      payout_acc_tag: "Y",
      linkid: this.linkId,
      aadharseeding: this.validatePANResp.aadharseedingstatus === 'Y' ? 'Y' : 'N',
      /* srinput: {
        pandtls: {
          panfname: this.validatePANResp.firstname,
          panlname: this.validatePANResp.lastname,
          panmname: this.validatePANResp.middlename,
          panexctmatch: "YES",
          aadharseeding: this.validatePANResp.aadharseedingstatus === 'Y' ? 'Y' : 'N' 
        }
      }, */
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
    }
    };
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    reqBody.panno = this.crypto.encryptJson(reqBody.panno);
    reqBody.aadharseeding = this.crypto.encryptJson(reqBody.aadharseeding);
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      this.dialog.closeAll();
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.message.openSnackBar(`Service Request for PAN Update (SR No. ${resp.body.srbasicinfo.srno}) has been created and pending for requirement`, 'xy', ['success-snackbar']);
        this.panVerified = 'Yes';
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  updateKycDetails() {
    let clientNo;
    if(this.id == "surrender" && this.payeeRole == "Beneficiary"){
      clientNo = this.payoutData.claimClientId // beneficiary client id 
    } else{
      clientNo = ""; // owner client id
    }
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'UPD_KYC',
      payeeflag : "Y",
      process : this.id,
      benId : clientNo
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop',
         data: { policyNo: this.policyNo,kycFlag: true,kycId:"KYC", entryFlag: true, CheckboxFlag: true, id:this.id,role:this.payeeRole, parentSr: this.parentSr,clientid: this.clientId,validKycCount : resp.body.ocrstatus, mobileNo:this.mobile, emailId: this.email}, disableClose: true });        
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this._httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  closeDialog() {
    this.dialog.closeAll()
  }

  pendingSRdtls(){
    let reqbody = {
      "policyno": this.policyNo,
      "clientid": this.clientId,
      "role": this.payeeRole
    }
    return this.myPoliciesService.getPendignSrDtls(reqbody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        resp.body.pendingSRTrack.forEach(item => {
          if(item.SUBISSUE.includes("STND_NEFT") && item.SR_STAT_ID == "Pending Requirements"){
            this.pendingNeftSr = item.SRNUM;
            this.pendingNeft = true;
          } else if(item.SUBISSUE.includes("UPD_KYC") && item.SR_STAT_ID == "Pending Requirements"){
            this.pendingKycSr = item.SRNUM;
            this.pendingKyc = true;
          } else if(item.SUBISSUE.includes("PAN_CARD_UPD") && item.SR_STAT_ID == "Pending Requirements"){
            this.pendingPanSr = item.SRNUM;
            this.pendingPan = true;
          } else if(item.SUBISSUE.includes("ACCOUNT_TAGGING") && item.SR_STAT_ID == "Pending Requirements"){
            this.pendingChequeSr = item.SRNUM;
            this.pendingCheque = true;
          } if(item.SUBISSUE.includes("UPD_KYC") && item.SR_STAT_ID != "Cancelled"){
            if(this.isKycMessage != "Active" && item.SR_STAT_ID == "Closed"){
              this.updateKYC = false;
            } else {
              this.updateKYC = true; 
            }
          }
          //  else{
          //   this.pendingNeft = false;
          //   this.pendingKyc = false;
          //   this.pendingPan = false;
          // }
        })
      }

    })
  }
  uploadDoc(type: String){
    this.srNum = [];
    let subactivity;
    if(type == "NEFT"){
      this.srNum = this.pendingNeftSr;
      this.pendingNeft = false;
      subactivity = "STND_NEFT";
    } else if(type == "KYC"){
      this.srNum = this.pendingKycSr;
      this.pendingKyc = false;
      subactivity = "UPD_KYC";
    } else if(type == "CHEQUE"){
      this.srNum = this.pendingChequeSr;
      this.pendingCheque = false;
      subactivity = "ACCOUNT_TAGGING";
    } else{
      this.srNum = this.pendingPanSr;
      subactivity = "PAN_CARD_UPD";
    }
    this.dialog.open(DocListPopupComponent, {
      panelClass: 'doc-list-dialog',
      backdropClass: 'doc-list-dialog-backdrop',
      disableClose: true,
      data:{srno: this.srNum, id :this.id,claimNo:this.parentSr,policyno: this.policyNo, clientid: this.clientId,role:this.payeeRole,firstName: this.payoutData.FIRSTNAME, lastName: this.payoutData.LASTNAME, uploadDocFlag: true, type: type,
        submitSRReqDtls: {
          srno: this.srNum,
          subactivity: subactivity,
          policyno: this.policyNo,
          role: this.payeeRole,
          clientid: this.clientId,
        }, }
    });
  }

  surrenderSubmit(){
    let reqbody = {
      "policyno": this.policyNo,
        "clientid": this.clientId,
        "subactivity": "SURR_PROC",
        "action": "SUBMIT",
        "role": "Owner",
        "srNum": this.parentSr,
        "payeeclientno": this.payeeClientId,
        "payeefirstname": this.payoutData.FIRSTNAME,
        "payeelastname": this.payoutData.LASTNAME,
        "paymentMode": this.payoutDtls.paymentMode,
        "prmpaidnre": "",
        "payeeclientrole": this.payeeRole,
        "surrReason": this.payoutDtls.surReason,
        "productType": this.payoutDtls.prodType,
        "prodCode": this.payoutDtls.prodCode,
        "surrQuoteGenDt": this.payoutDtls.quoteGenDate,
        "surrVal": this.payoutDtls.surrValue,
        "accountValue": this.payoutDtls.accountValue
    }
    return this.myPoliciesService.surrenderSubmitSR(reqbody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        // if (this.dataService.getIsCustomLink()) {
          let text = "https://myaccount.hdfclife.com/login";
          let link = text.link("https://myaccount.hdfclife.com/login");
          this.dialog.closeAll();
          if(resp.body.srinfo){
          this._httpService.showSuccessMsg(`Your request to Surrender (SR No. ${resp.body.srinfo[0].srbasicinfo.srno}) your HDFC Life policy ${this.policyNo} has been successfully submitted. Your payout is subject to verification of submitted documents and information. You can expect your reimbursement within 7 to 10 business days. We will contact you if any more documentation is needed. You can track the status of your Surrender request by visiting the My Service Requests tab on My Account (
            ${link}).`, true);
          } else{
            this._httpService.showSuccessMsg(`Your request to Surrender (SR No. ${resp.body.srnum}) your HDFC Life policy ${this.policyNo} has been successfully submitted. Your payout is subject to verification of submitted documents and information. You can expect your reimbursement within 7 to 10 business days. We will contact you if any more documentation is needed. You can track the status of your Surrender request by visiting the My Service Requests tab on My Account (
              ${link}).`, true);
          }
        // } else {
        //   this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${resp.body.srinfo[0].srbasicinfo.srno}`, 'xy', ['success-snackbar'], 'top');          // this.srTypeArray = [{ "srNum": resp.body.srinfo.srbasicinfo.srno, "srType": "SURR_PROC" }];
          // this.createSrService.setSRTypeResp(this.srTypeArray);
          // this.dialog.closeAll()
          // this.router.navigate(['/customer-service', 'service-request-details']);
        // }    
      } else {
        if(resp.head.errordetails.message == "Please update NEFT details for payout process"){
          // this.goToPayee(this.VerifyPayee)
          this.neft_verified_flag = true
          this.kyc_flag = true
          this.pan_flag = true
          this.neftDtlsVerify(this.VerifyPayee)
        } else {
          this._httpService.apiErrorHandler(resp);
        }
      }
    })
  }
  backBtn(){
    this.auth.logout('A');
  }
  getKycSrDtls(dtls){
      // this.kycData = this.myPoliciesService.getPayoutData();
    if(dtls.subActivity == 'KYC'){
      this.pendingKycSr = dtls.srno
      if(this.id == "surrender"){
        this.pendingKyc = true;
        this.updateKYC = true;
      } else{
        this.pendingKyc = false;
      }
    } else if(dtls.subActivity == 'NEFT'){
      this.pendingNeftSr = dtls.srno
      if(this.id == "surrender"){
        this.pendingNeft = true;
        // this.updateKYC = true;
      } else{
        this.pendingNeft = false;
      }
    } else if(dtls.subActivity == "CHEQUE"){
      this.pendingChequeSr = dtls.srno
      if(this.id == "surrender"){
        this.pendingCheque = true;
        // this.updateKYC = true;
      } else{
        this.pendingCheque = false;
      }
    }
  }

  }
