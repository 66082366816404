import { Component, OnInit, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, SimpleChange, Output } from '@angular/core';
import * as html2canvas from 'html2canvas/dist/html2canvas.js';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit, AfterViewInit {
  @ViewChild('captcha') captcha: ElementRef;
  @ViewChild('captchaImg') captchaImg: ElementRef;
  @Input() enteredCaptcha: string;
  generatedCaptcha: string;
  showCaptcha: boolean = true;
  isValid: boolean = false;
  @Output() validateCaptchaEvent = new EventEmitter<string>();
  @Output() reloadEvent = new EventEmitter<void>();
  // alphaNumeric: string = "aAbBcCdDeEfFgGhHjJkKmMnNpPqQrRsStTuUvVwWxXyYzZ123456789";
  numeric: string = "123456789";

  constructor() { }

  ngOnInit(): void {
    this.generateCaptcha();
  }
  ngAfterViewInit() {
    this.generateCaptchaImg();
  }
  ngOnChanges(changes: SimpleChange) {
    this.validateCaptcha();
  }
  reloadCaptcha() {
    this.reloadEvent.emit();
    this.captchaImg.nativeElement.src = '';
    this.generateCaptcha();
    setTimeout(() => {
      this.generateCaptchaImg();
    }, 0);    
  }
  generateCaptchaImg() {
    html2canvas(this.captcha.nativeElement).then(canvas => {
      this.showCaptcha = false;
      this.captchaImg.nativeElement.src = canvas.toDataURL();
    });
  }
  generateRandChar() {
    return this.numeric[Math.floor(Math.random() * this.numeric.length)];
  }
  generateCaptcha() {
    let captchaBuilder = '';
    for(let i=0; i<6; i++) {
      captchaBuilder += this.generateRandChar();
    }
    this.generatedCaptcha = captchaBuilder;
    this.showCaptcha = true;
  }
  validateCaptcha() {
    if(this.enteredCaptcha === this.generatedCaptcha) {
      this.isValid = true;
    }
    else {
      this.isValid = false;
    }
    this.validateCaptchaEvent.emit(String(this.isValid));     
  }
}
