import { Component, OnInit } from '@angular/core';
import { Form, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { HttpService } from 'src/app/services/http/http.service';
import { DataService } from 'src/app/services/data/data.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MessageService } from 'src/app/services/message/message.service';
import { aadharConsentTerms } from 'src/app/utils/app-constants';
import { Utils } from 'src/app/utils/common-functions';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../message-popup/message-popup.component';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  lang : string = "english";
  langServiceDestroy : any;
  terms : any;
  accepted : boolean;
  showcheckbox : boolean;
  action : string;
  token : string;
  type: string;
  shorttoken : string;
  showPage : boolean = false;  //for Terms and conditions within page
  mail ="service@hdfclife.com";
  link = this.mail.link("mailto:service@hdfclife.com");
  notAuthorizedError: string = "You are not authorised to view this page. Kindly get in touch with your nearest Branch or write an e-mail to our Service desk";
  linkInvalidError: string = `The link is invalid. For any further assistance, please contact us on 1860 267 9999 or email us at ${this.link}`;
  showaudio : boolean = false;

  constructor(
    private myAccService : MyAccountService,
    private route : ActivatedRoute,
    public customLinkService : CustomLinkService,
    public dataService : DataService,
    public _httpService: HttpService,
    public utils: Utils,
    public mypoliciesServices : MyPoliciesService,
    public message : MessageService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.langServiceDestroy = this.myAccService.language.subscribe(lang => {
      this.lang = lang;
      this.terms = aadharConsentTerms[lang];
    })
   }

  ngOnInit(): void {
    this.lang = this.myAccService.initialLanguage;
    this.terms = aadharConsentTerms[this.lang];

    this.shorttoken = this.route.snapshot.params['_token'];
    this.type= this.route.snapshot.queryParamMap.get('type');
    this.token = this.route.snapshot.queryParamMap.get('token');

    if(this.shorttoken ||  this.token){
      this._httpService.init({}).subscribe(resp => {
        this._httpService.initResp = resp;
          this.getTokenDtls();
      }),
      catchError((error) => throwError(error)); 
    }
    else{
      this.showcheckbox = false;
      this.showPage = true;
   }

  }

  selectedOption(event:any){
    this.accepted = event.checked;
  }

  getTokenDtls() {
    let reqBody = {
      "token": this.shorttoken ? this.shorttoken : this.token ? this.token : undefined,
      "process": 'AadhaarConsent',
      "type" : this.type? this.type : undefined
    }   
    return this.customLinkService.validateLinkToken(reqBody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.status) {
          if (resp.body.status == "SUCCESS") {
            this.showPage = true;
            this.showcheckbox = true;
          } 
          else if (resp.body && resp.body.resMsg){
            this.openErrorPopup(resp.body.resMsg);
          }
          else{
            this.openErrorPopup();
          }
        }
        
      }
      else {
        let msg = resp.head && resp.head.errordetails && resp.head.errordetails.message ? resp.head.errordetails.message : this.linkInvalidError;
        this.openErrorPopup(msg);
      }
    }),
    catchError(error => throwError(error));
  }


  aadhaarConsent(){
    let reqBody = {
      "token" : this.shorttoken ? this.shorttoken : this.token ? this.token : undefined,
      "process": 'AadhaarConsent',
      "type" : this.type ? this.type : undefined
    };
    this.mypoliciesServices.aadhaarConsent(reqBody).subscribe(resp => {
      if(this._httpService.isSuccessResponse(resp)){
        this.dialog.open(MessagePopupComponent, {
          width: '500px',
          panelClass: 'message-popup-panel',
          backdropClass: 'email-voc-popup', disableClose: true,
          data: {
            closeIcon: false,
            title: "Success",
            mainMessageHtml: 'Your consent has been submitted successfully' ,
          }  
        })
      } else {
        if(!this._httpService.isSuccessResponse(resp))
        this._httpService.apiErrorHandler(resp);
      }
    })
  }


  openErrorPopup(errorMsg?) {
    if (!errorMsg) {
      errorMsg = this.notAuthorizedError;
    }
    this.dialog.open(MessagePopupComponent, {
      width: '500px',
      panelClass: 'message-popup-panel',
      backdropClass: 'email-voc-popup', disableClose: true,
      data: {
        closeIcon: false,
        title: "Error",
        mainMessageHtml: errorMsg,
      }
    });
    this.showPage = false;
  }

}
