import { NgControl } from '@angular/forms';
import { HostListener, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[alphabeticOnly]'
})
export class onlyAlphabeticDirective{
/**
 * This helps in injecting the desired services.
 * @param _el this is the elemnet reference ex: input
 */
  constructor(private _el: ElementRef, private control: NgControl){ }
  @HostListener('input',['$event']) onInputChange(event){
      const initalValve = this._el.nativeElement.value;
      let value = initalValve.replace(/[^a-zA-Z\s]*/g, '');
      this._el.nativeElement.value = initalValve.replace(/[^a-zA-Z\s]*/g, '');
      if( this.control &&  this.control.control){
          this.control.control.patchValue(value);
      }
      if( initalValve !== this._el.nativeElement.value) {
          event.stopPropagation();
      }
  }
}
