<div class="hni-popups">
  <div class="sr-popup">
          <div class="sr-popup-message">
                <div class="content-one">
                    <span class="content-one" [ngClass]="{'success-text': isSuccessMsg}" [innerHTML]="srMessage"></span>
                    <!-- {{srMessage}} -->
                  </div>
                <div *ngIf="showClose" class="button-div flex">
                  <button (click)="onCloseClick()" class="close-btn" type="button">Close</button>
                </div>
              </div>
              
         
  </div>
  
</div>

