import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-neft-deep-link',
  templateUrl: './edit-neft-deep-link.component.html',
  styleUrls: ['./edit-neft-deep-link.component.css']
})
export class EditNeftDeepLinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {       
  }  

}
