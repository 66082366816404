import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MyDownloadsService } from 'src/app/services/my-downloads/my-downloads.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-policy-number-dropdown',
  templateUrl: './policy-number-dropdown.component.html',
  styleUrls: ['./policy-number-dropdown.component.css']
})
export class PolicyNumberDropdownComponent implements OnInit {
  @Output() policyNoClickEvent = new EventEmitter<string>();
  @Input() parentCompName: string = '';
  policyDtls: Array<any> = [];
  policyNoArray: Array<any> = [];
  selectedPolicy = new FormControl('', Validators.required);
  userDtls: any;
  readOnly: boolean;

  constructor(public myPoliciesService: MyPoliciesService, public myDownloadsService: MyDownloadsService, public dataService: DataService) { 
    this.myPoliciesService.getPolicyDetails().subscribe(dtls => {
      this.policyDtls = dtls;
      this.selectAPolicyFunction();
    });
    this.myDownloadsService.prdtName.subscribe(name => {
      this.policyNoArray=[];
      if(name[1]){
        for(let item of name[1]){
          this.policyNoArray.push({label: item, val: item.replace(/\s+/g, '')})
        }
        if(this.policyNoArray.length == 1){
          this.readOnly = true;
          this.selectedPolicy.setValue(this.policyNoArray[0].val)
          this.onPolicySelect(this.policyNoArray[0].val)
        }
      }
      else{
        this.policyNoArray = this.createPolicyNoArray(this.policyDtls);
        if(this.policyNoArray.length == 1){
          this.selectedPolicy.setValue(this.policyNoArray[0].val)
          this.onPolicySelect(this.policyNoArray[0].val)
        }
      }
  });
}
  ngOnInit(): void {
    this.userDtls = this.dataService.getLoginResp();

    this.selectAPolicyFunction();
  }
  selectAPolicyFunction(){
    let name = this.myDownloadsService.selectPolicyProdName;
      this.policyNoArray=[];
      if(name[1]){
        for(let item of name[1]){
          this.policyNoArray.push({label: item, val: item.replace(/\s+/g, '')})
        }
        if(this.policyNoArray.length == 1){
          this.readOnly = true;
          this.selectedPolicy.setValue(this.policyNoArray[0].val)
          this.onPolicySelect(this.policyNoArray[0].val)
        }
      }
      else{
        this.policyNoArray = this.createPolicyNoArray(this.policyDtls);
        if(this.policyNoArray.length == 1){
          this.selectedPolicy.setValue(this.policyNoArray[0].val)
          this.onPolicySelect(this.policyNoArray[0].val)
        }
      }
      if (this.dataService.isDeepLink && this.userDtls && this.userDtls.Policyno) {
        this.selectedPolicy.setValue(this.userDtls.Policyno);
        setTimeout(() => {
          this.onPolicySelect(this.userDtls.Policyno);
        }, 300); 
      } else if(this.dataService.getRedirectValue()){
        let policyNo = this.dataService.getRedirectValue()
        this.selectedPolicy.setValue(policyNo)
      }
  }
  createPolicyNoArray(policyData) {
    let policyNumbers: Array<any> = [];
    if(policyData.length == 1){
      this.readOnly = true;
      
    }
    for (let item of policyData) {
      if (this.parentCompName === 'UnitStatements') {
        if (item.PRODUCT_TYPE == "ULIP") {
          policyNumbers.push({label: item.POLICYNO, val: item.POLICYNO.replace(/\s+/g, '')});
        }
      } else {
        policyNumbers.push({label: item.POLICYNO, val: item.POLICYNO.replace(/\s+/g, '')});
      }
    }
    return policyNumbers;
  }
  onPolicySelect(policyNo: string) {
    policyNo = policyNo.replace(/\s+/g, '');
    this.policyNoClickEvent.emit(policyNo);
  }
}
