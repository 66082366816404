import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { startWith, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { searchBarMap } from 'src/app/utils/search-bar-map';
import { Utils } from 'src/app/utils/common-functions';
import { MyDownloadsService } from 'src/app/services/my-downloads/my-downloads.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  userDetails: any = {};
  isHNIUser: boolean = false;
  searchActions: Array<any> = [];
  trending: Array<any> = [];
  filteredActions: Observable<any[]>;
  policies: Array<any> = [];
  filteredPolicies: Array<any> = [];
  searchForm = this.fb.group({
    'actionCtrl': ['', [Validators.required]],
    'policyCtrl': [''],
  }, { validator: this.searchFormValidator });
  showPolicyField: boolean = false;
  showPlaceholder = true;
  placeholders = [];
  isGoEnabled: boolean = false;
  isSearchEnabled: boolean = false;
  selectedpolicyno: Array<any> = [];
  selectedPrdtname: Array<any> = [];

  constructor(
    public dataService: DataService, 
    public dialogRef: MatDialogRef<SearchComponent>, 
    public dialog: MatDialog, 
    public myPoliciesService: MyPoliciesService,
    public myDownloadsService: MyDownloadsService, 
    public router: Router, 
    public httpService: HttpService, 
    public message: MessageService, 
    public utils: Utils,
    public fb: FormBuilder,
  ) {
    this.filteredActions = this.actionCtrl.valueChanges.pipe(
      startWith(''),
      map(input => input ? this.getFilteredActions(input, this.searchActions) : []),
    );
    this.myPoliciesService.getPolicyDetails().subscribe(dtls => {
      this.policies = dtls;
    });
  }

  ngOnInit(): void {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUser = true;
    }    
    this.getSearchActions();
  }

  get actionCtrl() {
    return this.searchForm.controls.actionCtrl;
  }

  get policyCtrl() {
    return this.searchForm.controls.policyCtrl;
  }

  getFilteredActions(input: any, data: Array<any>): Array<any> {
    let result = [];
    if (input && typeof input === 'string') {
      result = data.filter(item => item["keywords"].some(word => word.toLowerCase().includes(input.toLowerCase())));
    }
    setTimeout(() => {
      this.isSearchEnabled = input && typeof input === 'string' && input.length > 0 && result.length == 0 ? true : false;
    });
    return result;    
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  displayValue(selectedOption): string {
    return selectedOption ? selectedOption.label : '';
  }

  onActionClicked(eve) {
    this.handleActionClick(eve.option.value)
  }

  handleActionClick(action) {
    this.resetPolicyCtrl();
    if (action && action.isPolicyLevel) {
      this.onPolicyLevelAction(action);
    } else if (action && !action.isPolicyLevel && action.trendingBtnClicked) {
      this.submitForm();
    }
  }

  submitForm() {
    let action = this.actionCtrl.value ? this.actionCtrl.value : null;
    let policy = this.policyCtrl.value ? this.policyCtrl.value : null;
    if (action && policy && action.isPolicyLevel) {
      let idx = this.getPolicyIdx(policy);
      this.dataService.setCarouselPosition(idx);
      action["policyCarouselPos"] = idx;
      action["selectedPolicyType"] = this.policies[idx]['PRODUCT_TYPE'];
      if ((action.page === 'MY_DOWNLOADS' && action.tab && action.tab === 'MEDICAL') || action.page === 'SERVICE_REQUESTS') {  
        let idx = this.dataService.getCarouselPosition();    
        this.selectedPrdtname.push(this.policies[idx]['PRDNAME']);
        this.selectedpolicyno.push(this.policies[idx]['POLICYNO']);
        this.myDownloadsService.setSelectPolicyPrdtName(this.selectedPrdtname, this.selectedpolicyno,null);
      }
    }
    if (action && action.func_rk) {
      this.createSearchHistory().subscribe(resp => {
        if (action.page === 'MY_DOWNLOADS' || action.page === 'POLICY_DETAILS') {
          this.router.navigate(['/my-search'], { queryParams: { id: action.func_rk } });
        } else {
          this.router.navigate(action.path);
        }
        this.dataService.searchSubj.next({
          ...action,
        });
        this.closeDialog();
      });
    }
  }

  onTrendingClick(action) {
    this.actionCtrl.setValue(action);
    this.showPlaceholder = false;
    this.handleActionClick({ ...action, trendingBtnClicked: true });
  }

  onActionFocusIn() {
    this.showPlaceholder = false;
  }

  onActionFocusOut() {
    if (this.actionCtrl.value) {
      this.showPlaceholder = false;
    } else {
      this.showPlaceholder = true;
    }
  }

  onPolicyLevelAction(action) {
    this.filteredPolicies = this.getFilteredPolicies(action.productType, this.policies);
    if (this.filteredPolicies && this.filteredPolicies.length == 1) {
      let policyNo = this.filteredPolicies[0]['POLICYNO'].replace(/\s/, '');
      this.policyCtrl.setValue(policyNo);
      this.showPolicyField = false;
      if (action.trendingBtnClicked) { this.submitForm() }
    } else if (this.filteredPolicies && this.filteredPolicies.length < 1) {
      this.message.openSnackBar('No applicable policies found', 'xy', ['error-snackbar']);
      this.resetActionCtrl();
      this.showPolicyField = false;
    } else {
      this.showPolicyField = true;
    }
  }

  getFilteredPolicies(productType: string, policies: Array<any>): Array<any> {
    if (productType === 'ULIP') {
      return policies.filter(item => item["PRODUCT_TYPE"] === productType);
    } else if (productType === 'Annuity') {
      return policies.filter(item => item['standaloneAnnuity'] === 'Y');
    } else {
      return policies;
    }
  }

  resetPolicyCtrl() {
    this.showPolicyField = false;
    this.policyCtrl.setValue('');
  }

  resetActionCtrl() {
    this.actionCtrl.setValue('');
  }

  getPolicyIdx(policy: string) {
    return this.policies.findIndex(item => item.POLICYNO.replace(/\s/, '') == policy.replace(/\s/, ''))
  }

  getSearchActions() {
    forkJoin([ this.getMasterData(), this.getTrendingSearches() ])
    .subscribe(([ masterData, trendingMap ]) => {
      this.searchActions = this.createSearchActions(masterData, trendingMap);
      this.trending = this.getTrending(this.searchActions);
      this.placeholders = this.getPlaceholders(this.trending);
    });
  }

  getMasterData(): Observable<any> {
    return this.myPoliciesService.getSearchMasterData().pipe(
      map(resp => {
        if (this.httpService.isSuccessResponse(resp) && resp.body && resp.body.SEARCH_KEYWORD) {
          return resp.body.SEARCH_KEYWORD;
        } else {
          this.httpService.apiErrorHandler(resp);
          this.closeDialog();
          return {};
        }
      })
    );
  }

  getTrendingSearches(): Observable<any> {
    let reqBody = {
      "clientID": this.utils.getClientId()
    }
    return this.myPoliciesService.getTrendingSearches(reqBody).pipe(
      map(resp => {
        if (this.httpService.isSuccessResponse(resp) && resp.body && resp.body.trending) {
          let trendingMap = {};
          for (let item of resp.body.trending) {
            trendingMap[item['SEARCH_FUNC_RK']] = item['priority'];
          }
          return trendingMap;
        } else {
          this.httpService.apiErrorHandler(resp);
          this.closeDialog();
          return {};
        }
      })
    );
  }

  createSearchActions(masterData, trendingMap): Array<any> {
    let result = [];
    for (let item of Object.values(masterData)) {
      let action = result.find(i => i['func_rk'] === item[0])
      if (action && action.keywords && action.keywords.length > 0) { 
        action['keywords'].push(item[4][0]) 
      } else {
        result.push({
          'func_rk': item[0],
          'label': item[1],
          'isPolicyLevel': item[5][0] === 'N',        
          'productType': item[5][1] === 'ULIP policy' ? 'ULIP' : item[5][1],
          'placeholder': item[5][2],
          'keywords': item[4],
          'priority': trendingMap[item[0]] && trendingMap[item[0]] > 0 ? trendingMap[item[0]] : null,
          ...searchBarMap[item[0]]
        });
      }
    }
    return result;
  }

  getTrending(actions: Array<any>): Array<string> {
    return actions.filter(i => i.priority && i.priority > 0).sort((a, b) => a.priority - b.priority);
  }

  getPlaceholders(trending: Array<any>): Array<string> {
    const placeholders = trending.map(i => i.placeholder);
    return ['Please write keywords you want to search'].concat(placeholders);
  }

  createSearchHistory() {
    let action = this.actionCtrl.value;
    let reqBody = {
      "function_rk": action.func_rk,
      "clientID": this.utils.getClientId(),
      "policyno": this.policyCtrl.value,
      "action": "EXISTING_KEY"
    }
    return this.myPoliciesService.createSearchHistory(reqBody);
  }

  insertNewSearchQuery() {
    let reqBody = {
      "clientID": this.utils.getClientId(),
      "KeyWord": this.actionCtrl.value && !this.actionCtrl.value.func_rk ? this.actionCtrl.value : '',
      "action": "NEW_KEY"
    }
    return this.myPoliciesService.createSearchHistory(reqBody).subscribe(resp => {
      this.message.openSnackBar('Unable to find the transaction. Please try with different keywords', 'xy', ['error-snackbar']);
    });
  }

  searchFormValidator(group: FormGroup): ValidationErrors | null {
    const actionCtrl = group.get('actionCtrl');
    const policyCtrl = group.get('policyCtrl');
    if (
      actionCtrl.value && 
      (actionCtrl.value.isPolicyLevel === true && policyCtrl.value) || 
      actionCtrl.value.isPolicyLevel === false
    ) {
        return null;
    } else {
      return { 'errorMsg': 'Invalid input' }
    }
  }

}
