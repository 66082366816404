import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { CameraComponent } from '../camera/camera.component';
import { TOUCHPOINT } from 'src/app/utils/app-constants';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-generic-doc-upload',
  templateUrl: './generic-doc-upload.component.html',
  styleUrls: ['./generic-doc-upload.component.css']
})
export class GenericDocUploadComponent implements OnInit {
  docTypeDropdown: FormControl;
  docList: Array<any> = [
    {code: "Other Documents", label: "Other Documents"},
    {code: "Final Response Document", label: "Final Response Document"},
    {code: "Reopen Response Document", label: "Reopen Response Document"},
  ];
  showUploadBox: boolean = false;
  docTypeFocusOut: boolean = false;
  isSubmitSuccess: Array<boolean> = [];
  uploadedFileList: Array<any> = [];
  byteArray: Array<any> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'bmp'];
  maxFileSize: number = 5;
  maxNumOfFiles: number = 5;
  isHNIUSer: boolean = false;
  docArray: Array<any> = [];
  userDetails: any = {};
  isUploadSuccess: Array<boolean> = [];
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  imageArray: Array<any> = [];
  isInstaServ: boolean = false;
  showCamera: boolean = false;
  osvControl: FormControl;
  osvOpts: Array<any> = [
    { code: 'Original is seen and verified by me.',
      label: 'Original is seen and verified by me'
    },
    { code: "Original is seen and verified by Sales person who's agent code verified by me.",
      label: "Original is seen and verified by Sales person who's agent code verified by me"
    },
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data, public createSrService: CreateSRService,
  public dialog: MatDialog, public httpService: HttpService,public message: MessageService, public utils: Utils, public dialogRef: MatDialogRef<GenericDocUploadComponent>, public dataService: DataService,
  private loader: LoaderService, public auth: AuthService) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.docTypeDropdown = new FormControl('', /* [Validators.required] */);
    this.osvControl = new FormControl('', [Validators.required]);
    this.isInstaServ = this.data.source == "INSTA_SERV" ? true : false;
    if (this.isInstaServ) {
      this.maxNumOfFiles = 10;
      this.maxFileSize = 6;
    }
    this.showUploadBox = true;
    this.setIsHNIUser();
  }

  OnDocTypeFocusOut() {
    this.docTypeFocusOut = true;
  }
  setIsHNIUser() {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
  }
  onDocSelect(docCode: string) {
    this.uploadedFileList = [];
    this.byteArray = [];
    this.isUploadSuccess = [];
    this.showUploadBox = false;
    this.showCamera = false;
    this.osvControl.setValue('');
    if (docCode) {
      this.showUploadBox = true;
    }
  }
  onDocumentLoad(files) {    
    if (this.validateFiles(files)) {
      if (this.isInstaServ) {
        this.loader.show();
        this.uploadedFileList.push(...files);
        this.byteArrayGenerator(files).then(result => {
          this.byteArray.push(...result);
          this.loader.hide();
        }, () => this.loader.hide());
      } else {
        this.uploadedFileList = files;
        this.byteArrayGenerator(this.uploadedFileList).then(result => {
          this.byteArray = result;
        });
      }      
    }   
  }
  validateFiles(files) {
    let filesExceeded: boolean;
    if (this.isInstaServ) {
      filesExceeded = (this.uploadedFileList.length + files.length) > this.maxNumOfFiles;
    } else {
      filesExceeded = files.length > this.maxNumOfFiles;
    }       
    if (filesExceeded) {
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar']);
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name;
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], 'top');
        return false;
      } else if (filesize > this.maxFileSize) {
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], 'top');
        return false;
      }
    }
    return true;
  }
  onSubmit() {    
    this.submitUploadDocForm();
  }
  async submitUploadDocForm() {
    if (this.showUploadBox) {
      if (this.byteArray && this.byteArray.length > 0) {
        for (let i = 0; i < this.byteArray.length; i++) {
          this.isUploadSuccess.push(false);
        }
        for (let docIndex = 0; docIndex < this.byteArray.length; docIndex++) {
          await new Promise(resolve => {
            this.uploadDocument(this.byteArray[docIndex]).subscribe((resp) => {
              if (this.httpService.isSuccessResponse(resp)) {
                this.isUploadSuccess[docIndex] = true;
                if (!this.isUploadSuccess.includes(false)) {                     
                  if (this.isInstaServ) {
                    this.showUploadBox = false;
                    this.httpService.showSuccessMsg("Document(s) uploaded successfully. Please click on Navigate to Home Screen button.");
                    localStorage.removeItem("source");
                  }                                   
                }
              } else {
                this.httpService.apiErrorHandler(resp);
              }
              resolve(true);
            }, () => resolve(false));
          });
        }
      } else {
        this.message.openSnackBar("Please upload a document", 'xy', ['error-snackbar']);
      }
    }
  }
  uploadDocument(file) {
    const reqBody = {
      documentVerificationType: this.osvControl.value,
      srNo: this.data.srno,
      documentImage: file.bytestream,
      fileType: file.name.split('.').pop(),
      documentName: file.name ? file.name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : file.name,
    };
    let touchpoint = TOUCHPOINT;
    if (this.data.source == "INSTA_SERV") {
      touchpoint = "TOUCHPOINT_INSTASERVOPSNEW";
    }
    return this.createSrService.genericDocUpload(reqBody, touchpoint);
  }
  onCancel() {
    if (!this.data.disableClose) {
      this.dialogRef.close();
    }
  } 
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i], this.isInstaServ);
      bytearray.push({
        name: files[i].name,
        bytestream: base64string,
      });           
    }
    //this.byteArray = [];
    return bytearray;
  }
  openCamera() {
    this.cameraDialogRef = this.dialog.open(CameraComponent, { panelClass: 'camera-dialog', backdropClass: 'camera-dialog-backdrop', disableClose: true,
    data: { ...this.data, maxFiles: (5 - this.uploadedFileList.length) }, 
  });
    this.cameraDialogRef.afterClosed().subscribe(imageArray => {
      if (imageArray && imageArray.length > 0) {
        this.imageArray = imageArray;
        let files = this.docArray.concat(this.imageArray);
        this.onDocumentLoad(files);
      }
    });
  }
  customLinkLogout() {
    //this.auth.logout('M');
  }
  onOsvChange(eve) {
    this.osvControl.setValue(eve.value);
  }
}
