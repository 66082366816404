import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomSnackbarComponent } from 'src/app/modules/shared/components/custom-snackbar/custom-snackbar.component';
import { SrPopupComponent } from 'src/app/modules/shared/components/sr-popup/sr-popup.component';
import { Utils } from 'src/app/utils/common-functions';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private _snackBar: MatSnackBar, private dialog: MatDialog, private utils: Utils) {}

    private _snackBarDuration = 10000; // milliseconds
    private _snackBarHorizontalPosition: MatSnackBarHorizontalPosition =
        'center';

    /**
     * ##SnackBar Message Service
     *
     * @param message The message to be displayed
     * @param action The action to be performed like 'Dismiss', 'Close', 'Undo'
     * @param vp Vertical Position of the snackbar like 'top', 'bottom'
     * @param panelClass The custom CSS class defined in styles.scss/any global scss/css file
     */
    openSnackBar(
        message,
        action,
        panelClass: string[],
        obj:any = null,
    ) {
        /* let snackBarRef = this._snackBar.open(message, action, { 
            duration: this._snackBarDuration,           
            horizontalPosition: this._snackBarHorizontalPosition,
            verticalPosition: vp,
            panelClass: panelClass,
        }); */

        this.openCustomSnackBar(message, panelClass, obj); 
    }

    openDialog(dialogComponent, dialogConfig: MatDialogConfig) {
        let dialogRef = this.dialog.open(dialogComponent, {
            ...dialogConfig,
        });
        return dialogRef;
    }

    openCustomSnackBar(
        message,
        panelClass: string[],
        obj
    ) {
        let vp: MatSnackBarVerticalPosition = 'top';
        let snackBarRef = this._snackBar.openFromComponent(CustomSnackbarComponent, { 
            duration: this._snackBarDuration,           
            horizontalPosition: this._snackBarHorizontalPosition,
            verticalPosition: vp,
            panelClass: panelClass,
            data: {
                msg: message,
                snackbarType: panelClass[0],
            }
        });
        if(obj && obj != { } && obj != {} && obj != null && (obj.error_code != null)){
        this.utils.trackUserActions({ 'eventName': `message - ${message}`,...obj});   
        }
        else {
            
        }
    }

    public showSuccessMsg(message: string, options?) {
        this.dialog.open(SrPopupComponent,
            {
                disableClose: true,
                panelClass: 'app-sr-popup',
                backdropClass: 'sr-popup-backdrop',
                data: {
                    inputText: message,
                    successMsg: options && options.success ? options.success : false,
                    showClose: options && options.showClose ? options.showClose : false,
                }
            });
    }
}
