import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { loginService } from "../../../services/login/login.service";
import { DataService } from 'src/app/services/data/data.service';
import { Utils } from 'src/app/utils/common-functions';
import { Router } from '@angular/router';
import { MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/services/message/message.service';
import { HttpService } from 'src/app/services/http/http.service';


@Component({
  selector: 'app-change-secret-questions',
  templateUrl: './change-secret-questions.component.html',
  styleUrls: ['./change-secret-questions.component.css'],
  providers:[
    {provide:MAT_SNACK_BAR_DEFAULT_OPTIONS,useValue:{duration:2500}}
  ]
})
export class ChangeSecretQuestionsComponent implements OnInit {

  secretPwdForm: FormGroup;
  firstQuesFocus: boolean = false;
  secondQuesFocus: boolean = false;
  firstAnswerFocus: boolean = false;
  secondAnswerFocus: boolean = false;
  
  isFirstAnswerMasked: boolean = true;
  isSecondAnswerMasked: boolean = true;
  secretQuesResp = [];
 userDetails: any = {};
  secretQues:any =[];
  secretQuesRkValue:any = [];
  firstQuesRkValue;
  secondQuesRkValue;
  questions;
  questionsRk;
  secretQues2: any = [];
  constructor(private _snackBar : MatSnackBar, public _httpService: HttpService, private message: MessageService , private fb: FormBuilder, public loginService: loginService, public utils: Utils, public dataService: DataService, public router: Router) { 
    
  }

  ngOnInit(): void {

    this.secretPwdForm = this.fb.group({
      firstSecretQues: ['', [Validators.required]],
      secondSecretQues: ['', [Validators.required]],
      first: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25),Validators.pattern(/^[^\s].+[^\s]$/)]],
      second: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25),Validators.pattern(/^[^\s].+[^\s]$/)]],
    });
    

    let reqBody = {}
    this.loginService.masterSecretQues(reqBody)
              .subscribe((resp) => {
                if(this._httpService.isSuccessResponse(resp)) { 
                  this.secretQuesResp = resp.body;
                  for(let item of this.secretQuesResp){
                   this.secretQues.push(item.QUESTION_DESC);
                   this.secretQuesRkValue.push({"ques": item.QUESTION_DESC, "quesRk": item.QUESTION_RK});                  
                }
               } else {
                this._httpService.apiErrorHandler(resp);
              }
              });
  }

  get validation() {return this.secretPwdForm.controls; }

  firstQuesFocusOut(event: any) {
    this.firstQuesFocus = true;
  }
  populateSecondQtn(event) {
    this.secretQues2=[];
    this.secretPwdForm.controls.first.reset();
    this.secretPwdForm.controls.second.reset();
    this.secretPwdForm.controls.secondSecretQues.reset();
        for (let item of this.secretQues){
      if (item != event.value){
        this.secretQues2.push(item);
      }
    }
  }
  secondQuesFocusOut(event: any) {
    this.secondQuesFocus = true;
  }
  firstAnswerFocusOut(event: any) {
    this.firstAnswerFocus = true;
  }
  secondAnswerFocusOut(event: any) {
    this.secondAnswerFocus = true;
  }
  redirectToLogin() {
    this.router.navigate(['/my-policies']);
  }
  
  submitSecretPwdForm() {
    if(this.secretPwdForm.valid) {
      this.userDetails = this.dataService.getLoginResp();
      for(let element of this.secretQuesRkValue) {
          if(element.ques == this.secretPwdForm.value.firstSecretQues) {
            this.firstQuesRkValue = element.quesRk;
          }
          if(element.ques == this.secretPwdForm.value.secondSecretQues) {
            this.secondQuesRkValue = element.quesRk;
          }
      }
    let submitSecretQuesReqBody: any = {
          //user_rk: this.userDetails.USER_RK? this.userDetails.USER_RK: '',
          "firstQuesRK": this.firstQuesRkValue,
          "cpfirstans": this.secretPwdForm.value.first,
          "secondQuesRK": this.secondQuesRkValue,
          "cpsecondans": this.secretPwdForm.value.second
    }
    this.loginService.submitSecretQues(submitSecretQuesReqBody)
              .subscribe((resp) => {
              if(this._httpService.isSuccessResponse(resp)) {
              let secretSuccessMessage =  this._snackBar.open("Secret questions and answers updated sucessfully",'xy',{ horizontalPosition:"center",verticalPosition:"top", panelClass:['success-snackbar']});
              secretSuccessMessage.onAction().subscribe(()=>this.router.navigate(['/my-policies']));
                this.router.navigate(["/my-policies"])
              } else {
                this._httpService.apiErrorHandler(resp);
              }
              });
  }
}
  toggleFirstInputType(img, input) {
    this.isFirstAnswerMasked = !this.isFirstAnswerMasked;
    if(this.isFirstAnswerMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecInputType(img, input) {
    this.isSecondAnswerMasked = !this.isSecondAnswerMasked;
    if(this.isSecondAnswerMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }

  SecretQues = [
    {ques: "Who is your childhood hero?"},
    {ques: "What is your spouse's name?"},
    {ques: "What is your mother's maiden name?"},
    {ques: "What is your city of birth?"},
    {ques: "What is your nickname?"},
    {ques: "What is your pet's name?"},
  ];

  secretQuestionsInstructions = [
    {instruction: "Please answer all questions without which you won't be able to proceed further."},
    {instruction: "Answer should be minimum three (3) and maximum twenty five (25) characters long."},
    {instruction: "The answers will be saved for future use in case you forget your password."},
    {instruction: "Space is not allowed at the beginning or at the end of the answer."},
    {instruction: "Answers are not case sensitive."}
  ];

}
