import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { catchError, map } from 'rxjs/operators';
import { throwError, of, Subject } from 'rxjs';
import { serviceURL } from "src/environments/environment";
import { Utils } from 'src/app/utils/common-functions';
import { Router } from '@angular/router';
import { eStmntDtlsReqBody, mailpdfReqBody, mailDownloads3ReqBody } from 'src/app/modules/my-downloads/my-downloads-data-modal';
import { MyPoliciesService } from '../my-policies/my-policies.service';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class MyDownloadsService {
  policyDtls: Array<any> = [];
  private policyPrdtName = new Subject<Array<any>>();
  prdtName = this.policyPrdtName.asObservable();
  selectPolicyProdName:Array<any> = [];
  paymentDtls: Array<any> = [];
  activeStatementTab: any="current";
  private formD02Flag = new Subject<any>();
  flag = this.formD02Flag.asObservable();
  formD02flag:boolean;
  private maturityLetterDtls = new Subject<Array<any>>();
  maturityDtls = this.maturityLetterDtls.asObservable();
  maturityletter:Array<any> = [];

constructor(public _httpService: HttpService,public myPoliciesService: MyPoliciesService , public router: Router, public utils: Utils, public crypto: EncryptDecryptService) { }
  getPolicypdfDtls(reqBody) {
    return this._httpService.post(serviceURL.policypdfDtls_url,this.utils.createRequest('','getPolicypdfDtls',reqBody))
      .pipe(
        map((resp) => {
          return resp
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
  insertD02Dtls(reqBody) {
      return this._httpService.post(serviceURL.formD02_url,this.utils.createRequest('','insertD02Dtls',reqBody))
        .pipe(
          map((resp) => {
            return resp
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    }
  getD02FormDtls(reqBody) {
      return this._httpService.post(serviceURL.getD02form_url,this.utils.createRequest('','getD02FormDtls',reqBody))
        .pipe(
          map((resp) => {
            return resp
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );
    }
  getPaymntRcptDtls(estmntType: string, policyNo: string) {
    let reqBody: eStmntDtlsReqBody = {
      policyno: policyNo,
      clientid: this.utils.getClientId(),
      estatementtype: estmntType
    }
        return this._httpService
          .post(
            serviceURL.paymntReceiptDtls_url,
            this.utils.createRequest(this.utils.getClientId(), 'getPremiumDetail', reqBody)
          )
          .pipe(
            map((resp) => {
              if(this._httpService.isSuccessResponse(resp)) {
                this.paymentDtls =resp.body; 
              }
               else {
                this.utils.trackUserActions({ 'eventName': `message - ${resp.head.errordetails.message?resp.head.errordetails.message:'Something went wrong. Please try again after sometime.'}`, "flowType": 'Premium Receipts','policyNo' : policyNo,"error_code":'UEE'});   
                this._httpService.apiErrorHandler(resp);
              }return this.paymentDtls;
            }),
            catchError((error) => {
              console.log(error);
              return throwError(error);
            })
          );
    }
  getTDSDtls(reqBody) {
    return this._httpService.post(serviceURL.tdsDtls_url, this.utils.createRequest(this.utils.getClientId(), "getTDSSearchDtls", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  getEstmntDtls(estmntType: string, policyNo: string) {
        let reqBody: eStmntDtlsReqBody = {
          policyno: this.crypto.encryptJson(policyNo),
          clientid: this.crypto.encryptJson(this.utils.getClientId()),
          estatementtype: estmntType,          
        }
        return this._httpService
          .post(
            serviceURL.estmntDtls_url, this.utils.createRequest(this.utils.getClientId(), 'getEstatementDtls', reqBody)
          )
          .pipe(
            map((resp) => {
              return resp;
            }),
            catchError((error) => {
              console.log(error);
              return throwError(error);
            })
          );
    }
  viewPdfMailService(reqBody,url,apiname) {
        return this._httpService.post(url,this.utils.createRequest('',apiname,reqBody))
          .pipe(
            map((resp) => {
              return resp
            }),
            catchError((error) => {
              console.log(error);
              return throwError(error);
            })
          );
    }
  getTDSMailPdf(reqBody) {
    return this._httpService.post(serviceURL.tdsMailPdf_url, this.utils.createRequest(this.utils.getClientId(), "getTDSPDFMailDtls", reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );    
  }
  setSelectPolicyPrdtName(prdtName:any,policyNo:any,planName:any) {
    this.policyPrdtName.next([prdtName,policyNo,planName]);
    this.selectPolicyProdName=[prdtName,policyNo,planName];
    }
  setactiveTabChange(activeTab:string){
    this.activeStatementTab =activeTab;
    }
  getAppNo(reqBody) {
      return this._httpService.post(serviceURL.getAppNo, this.utils.createRequest(this.utils.getClientId(), "getAppNo", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
  getMedicalPdfMail(reqBody) {
      return this._httpService.post(serviceURL.getMedicalPdfMail, this.utils.createRequest(this.utils.getClientId(), "getMedicalPdfMail", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
  estatementCreateSr(reqBody) {
      return this._httpService.post(serviceURL.estatementCreateSr_url, this.utils.createRequest(this.utils.getClientId(), "createNewEstmtReq", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
    getSubscribeValue(reqBody) {
      return this._httpService.post(serviceURL.getSubscriptionOnLoad_url, this.utils.createRequest(this.utils.getClientId(), "getsubscribeunitonload", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    } 
    subscribeForUnitStatement(reqBody) {
      return this._httpService.post(serviceURL.subscribeUnitStatement_url, this.utils.createRequest(this.utils.getClientId(), "subscribeunitsubmit", reqBody,"TOUCHPOINT_CUSTOMERPORTAL"))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
    getSubscribeUnitMaster() {
      return this._httpService.get(serviceURL.getSubscribeUnitMaster_url)
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
    }
    redirectCombiPolicy(reqBody) {
      reqBody.Mobno = this.crypto.encryptJson(reqBody.Mobno);
      reqBody.Emailid = this.crypto.encryptJson(reqBody.Emailid);
      return this._httpService.post(serviceURL.redirectCombiPolicy_url, this.utils.createRequest(this.utils.getClientId(), "getErgoRedirect", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
    getMedicalDtls(reqBody){
      return this._httpService.post(serviceURL.medicalDocDtlsStatement_url, this.utils.createRequest(this.utils.getClientId(), "getMedicalDocDtls", reqBody))
        .pipe(
          map((resp) => {
            return resp;
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          })
        );    
    }
    setFormD02RedirectFlag(flag:boolean) {
      this.formD02Flag.next(flag);
      this.formD02flag = flag;
      }
      setMaturityLetterDtls(dtls:any,flag:any) {
        this.maturityLetterDtls.next([dtls,flag]);
        this.maturityletter=[dtls,flag];
      }
      getFYPaymentDtls(reqBody){
        return this._httpService.post(serviceURL.getFYPayments, this.utils.createRequest(this.utils.getClientId(), "getFYPaymentDtls", reqBody))
          .pipe(
            map((resp) => {
              return resp;
            }),
            catchError((error) => {
              console.log(error);
              return throwError(error);
            })
          )
      }
      getMaturityData(reqBody){
        return this._httpService.post(serviceURL.getMatBiData_url, this.utils.createRequest(this.utils.getClientId(), "getMatBPolicies", reqBody))
          .pipe(
            map((resp) => {
              return resp;
            }),
            catchError((error) => {
              console.log(error);
              return throwError(error);
            })
          )
      }
}
