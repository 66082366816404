import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../../services/auth/auth.service";
import { HttpService } from '../../../services/http/http.service';
import { loginReqBody } from "./login-data-modal";
import { validateUserReqBody } from "./login-data-modal";
import { Utils } from 'src/app/utils/common-functions';
import { throwError, from, Observable } from 'rxjs';
import { catchError, timeout,startWith, map, distinctUntilChanged } from 'rxjs/operators';
import { loginService } from "../../../services/login/login.service";
import { usefulLinks } from "../../../utils/app-constants";
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from 'src/app/services/data/data.service';
import { FacingLoginIssuesPopupComponent } from '../facing-login-issues-popup/facing-login-issues-popup.component';
import { MessageService } from 'src/app/services/message/message.service';
import { ForgotClientIdComponent } from '../forgot-client-id/forgot-client-id.component';
import { PasaOfferPopupComponent } from '../../my-policies/pasa-offer-popup/pasa-offer-popup.component';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MyPoliciesDialogComponent } from '../../my-policies/my-policies-dialog/my-policies-dialog.component';
import {toasterReqBody} from '../../login/login/login-data-modal'
import { CaptchaComponent } from '../../shared/components/captcha/captcha.component';
import { SwitchPolicyGroupPopupComponent } from 'src/app/modules/shared/components/switch-policy-group-popup/switch-policy-group-popup.component';
import * as moment from 'moment';
import { AddnomineeService } from '../../my-policies/addnominee-service/addnominee.service';
import { serviceURL, serviceTimeout } from 'src/environments/environment';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { LoaderInterceptorService } from 'src/app/services/loader-interceptor/loader-interceptor.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { BuyNpsComponent } from '../../my-policies/buy-nps/buy-nps.component';

export function PassOTP(group: FormGroup): { [s: string]: boolean } {
  var PWD = group.controls.clientPassword;
  var OTP = group.controls.clientOTP;

  if (PWD != undefined && OTP != undefined)
    if (!(PWD.value && PWD.value.length || OTP.value && OTP.value.length))
      return { invalid: true};
}

@Component({
  selector: 'app-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.css'],
  /* providers:[{
    provide:DateAdapter,useClass:pickdateAdapter
  },{provide:MAT_DATE_FORMATS,useValue:my_formats}] */
})
export class NewLoginComponent  implements OnInit {
  loginClientForm: FormGroup;
  loginEmailForm: FormGroup;
  loginMobileForm: FormGroup;
  loginPolicyForm: FormGroup
  loginClientFormValid = false;
  loginClientFocusOut = false;
  passwordType;
  loginPasswordFocusOut = false;
  redirectLinks = usefulLinks;
  OTPGenerate = false;
  NotAvailable = false;

  loginCaptchaFocusOut = false;
  isCaptchaValid: boolean = false;
  clientSecureLoginCheck = false;
  OTPCheck = false;
  reveal = false;
  genOtpResp: any;

  validateUserResp: any = {};
  timerSec: number = 180;
  interval: any;
  maskMobileNo: string;
  maskEmailId: string;
  errorinfo: any = {};
  statusMessage: string;
  hours: number = 0;
  minutes: number = 0;
  date:any;

  @ViewChild('exideCovid') public exideCovidDialog:TemplateRef<any>
  @ViewChild('clientCaptcha') clientCaptcha: CaptchaComponent;
  @ViewChild('emailCaptcha') emailCaptcha: CaptchaComponent;
  @ViewChild('mobileCaptcha') mobileCaptcha: CaptchaComponent;
  @ViewChild('policyCaptcha') policyCaptcha: CaptchaComponent;

  //toaster
  showToaster: boolean = false;
  toasterData: any;
  policyDtls: Array<any> = [];
  expInfoPopupData = [];

  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  groupCustromer: string = serviceURL.groupCustomer;
  initInterval;
  hideFlagClientID : boolean = false;
  hideFlagEmail: boolean = false;
  hideFlagMobile: boolean = false;
  hideFlagPolicy : boolean =  false;
  displayFieldsClientID: boolean = false;
  displayFieldsEmail: boolean =  false;
  displayFieldsMobile: boolean = false;
  displayFieldsPolicy: boolean = false;
  today = new Date();
  dobFocus: boolean = false;
  concatenatedText: any;
  validateUserResponse: any;
  validateOtpResponse: any;
  checkSumResp: string;
  loginRequest:any;
  validatereqBody: any;
  dedupeResponse: any = null;
  dedupeReq: any;
  errorMessage: boolean = false;
  otpErrorMessage: boolean = false;
  specialCase: boolean = false;
  clientSource: any;
  displayMessage: boolean;
  userDetails: any;
  isHNIUSer: boolean;
  validationMessage: boolean = false;
  dedupeDetails: any = null;
  displayFlag: boolean;
  medium: string;
  action: string;
  caseId: any;

  get device() {
    return this.deviceService.getDeviceInfo();
  }
  public dropdownOptions: any[] = [
    {label: 'Home screen', value: 'Home screen', src:"/assets/home2.svg"},
    {label: 'Download & Statement', value: 'Download & Statement', src:"/assets/downloads_active2.svg"},
    {label: 'Update Bank Details', value: 'Update Bank Details', src:"/assets/bank-blue2.svg"},
    {label: 'Update Communication Details', value: 'Update Communication Details', src:"/assets/call-blue2.svg"},
    {label: 'Update PAN / KYC', value: 'Update PAN / KYC', src:"/assets/personal-icon2.svg"},
    {label: 'Track Service Requests', value: 'Track Service Requests', src:"/assets/service_req2.svg"},
  ]; 

  loginData: any;
  loginType: any;
  otpValidateErrorMsg: boolean= false;
  filteredOptions : Observable<any>;
  country  : any;

  constructor(public formBuilder: FormBuilder, public router: Router,
    public auth: AuthService, public _httpService: HttpService,
    public loginService: loginService, public utils: Utils,  public myPoliciesService: MyPoliciesService,
    public dialog: MatDialog, public dataService: DataService, private message: MessageService,public addnomineeService: AddnomineeService, public myAccountService: MyAccountService,public loaderInterceptor: LoaderInterceptorService,private loader: LoaderService,public route: ActivatedRoute,private deviceService: DeviceDetectorService, public createSrService: CreateSRService) { }

  ngOnInit(): void {
    //this.loader.show();
    this.passwordType = 'password';
    this.loginClientForm = this.formBuilder.group(
      {
        clientID: ['', [Validators.required, Validators.pattern('^.{0,8}$')]],
        //clientPassword: ['', [Validators.required, Validators.minLength(1)]],
        clientOTP: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]],
        clientSecureLogin: [false],
        //clientSecureLogin: [true],
        clientCaptcha: ['', [Validators.required]],
        dob: ['',[Validators.required,CustomValidators.noFutureDates]],
        landingScreen: ['Home screen']
      },
      { validator: PassOTP }
    ),
      this.loginEmailForm = this.formBuilder.group(
        {
          emailID: ['', [Validators.required, Validators.email]],
          //clientPassword: ['', [Validators.required, Validators.minLength(1)]],
          clientOTP: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]],
          clientSecureLogin: [false],
          //clientSecureLogin: [true],
          clientCaptcha: ['', [Validators.required]],
          dob: ['',[Validators.required,CustomValidators.noFutureDates]],
          landingScreen: ['Home screen']
        },
        { validator: PassOTP }
      ),
      this.loginMobileForm = this.formBuilder.group(
        {
          mobileNo: ['', [Validators.required/* , Validators.pattern('^[0-9]{10,20}$') */]],
          //clientPassword: ['', [Validators.required, Validators.minLength(1)]],
          clientOTP: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]],
          clientSecureLogin: [false],
          //clientSecureLogin: [true],
          clientCaptcha: ['', [Validators.required]],
          dob: ['',[Validators.required,CustomValidators.noFutureDates]],
          countryCode: ['91', [CustomValidators.countryCode]],
          landingScreen: ['Home screen']
        },
        { validators: [PassOTP, CustomValidators.mobileNum] }
      );
      this.loginPolicyForm = this.formBuilder.group(
        {
          policyNo: ['', [Validators.required, Validators.pattern('^[0-9]{8,8}$')]],
          clientPassword: ['', [Validators.minLength(1)]],
          clientOTP: ['', [Validators.pattern('^[0-9]{6,6}$')]],
          clientSecureLogin: [false],
          //clientSecureLogin: [true],
          clientCaptcha: ['', [Validators.required]],
          dob: ['',[Validators.required,CustomValidators.noFutureDatesPolicyId]],
          landingScreen: ['Home screen']
        },
        { validator: PassOTP }
      );
      //this.calculateChecksum();
      this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.medium = this.route.snapshot.queryParamMap.get('medium');
    this.loginClientForm.controls.clientID.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if(value){
        this.loginClientForm.controls.clientID.patchValue(value.toUpperCase())
      }
    })
    this.filteredOptions = this.loginMobileForm.get('countryCode').valueChanges.pipe(
      startWith(),map(value => this._filter(value))
    )
    this.getCountryCode();
  }
  editClient(loginType) {
    if(loginType == 'client') {
    this.hideFlagClientID = false;
    this.displayFieldsClientID = false;
    this.OTPGenerate = false;
    this.errorMessage =  false;
    this.otpErrorMessage = false;
    this.otpValidateErrorMsg = false;
    this.loginClientForm.controls['clientOTP'].reset();
    this.loginClientForm.controls['clientCaptcha'].reset();
    this.statusMessage = "";
    clearInterval(this.interval);
    this.timerSec = 180;
    this.displayMessage = false;
    this.validationMessage = false;
    }
    else if(loginType == 'email') {
      this.hideFlagEmail = false;
      this.displayFieldsEmail = false;
      this.OTPGenerate = false;
      this.errorMessage =  false;
      this.otpValidateErrorMsg = false;
      this.otpErrorMessage = false;
      this.loginEmailForm.controls['clientOTP'].reset();
      this.loginEmailForm.controls['clientCaptcha'].reset();
      this.statusMessage = "";
      clearInterval(this.interval);
      this.timerSec = 180;
      this.displayMessage = false;
      this.validationMessage = false;
    }
    else if(loginType == 'mobileNo') {
      this.hideFlagMobile = false;
      this.displayFieldsMobile = false;
      this.OTPGenerate = false;
      this.errorMessage =  false;
      this.otpErrorMessage = false;
      this.otpValidateErrorMsg = false;
      this.loginMobileForm.controls['clientOTP'].reset();
      this.loginMobileForm.controls['clientCaptcha'].reset();
      this.statusMessage = "";
      clearInterval(this.interval);
      this.timerSec = 180;
      this.displayMessage = false;
      this.validationMessage = false;
    }
    else {
      this.hideFlagPolicy = false;
      this.displayFieldsPolicy = false;
      this.OTPGenerate = false;
      this.errorMessage =  false;
      this.otpErrorMessage = false;
      this.otpValidateErrorMsg = false;
      this.loginPolicyForm.controls['clientOTP'].reset();
      this.loginPolicyForm.controls['clientCaptcha'].reset();
      this.statusMessage = "";
      clearInterval(this.interval);
      this.timerSec = 180;
      this.displayMessage = false;
      this.validationMessage = false;
      /* this.date = moment(this.loginPolicyForm.controls.dob.value);
        let dateofbirth = this.date.format(this.date,'input');
        console.log(dateofbirth) */
    }
  }
  enableLogin(loginType) {
     return this.init().subscribe((response) => { 
    this._httpService.initResp = response;
    setTimeout(() => {
      if(loginType == 'client') {
        this.hideFlagClientID = true
      //this.OTPpass('client');
      this.getDedupeResp('client');
      }
      else if(loginType == 'email') {
        this.hideFlagEmail = true
        //this.OTPpass('email');
        this.getDedupeResp('email');
      }
      else if(loginType == 'mobileNo')  {
        this.hideFlagMobile = true
        //this.OTPpass('mobileNo');
        this.getDedupeResp('mobileNo');
      } 
      else {
        this.hideFlagPolicy = true
        //this.OTPpass('policyNo');
        if(moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999" &&  serviceURL.specialLifeConfig == "ON") {
          this.specialCase =  true;
          this.displayFieldsPolicy = true;
          this.addnomineeService.setFlag(this.specialCase);
          this.OTPpass('policyNo');
        }
        else {
        this.getDedupeResp('policyNo');
        this.hideFlagPolicy = true
        }
      }
    }, 100);
   }),
  catchError((error) => throwError(error)); 
    //this.concatenatedText = document.getElementById('policyNo') + '' + document.getElementById('mobileNo') + '' + document.getElementById('dob')
    //this.exideForm.nativeElement.submit();
  }
  dobFocusOut(event) {
    // console.log(this.loginClientForm.controls.dob.value);
    //console.log(event)
/*     if(event.target.value=="") {
    this.dobFocus = true;
    }
    else if(this.loginClientForm.controls.dob.value) {
      this.dobFocus = false;
    } */
  }
  get clientvalidation() { return this.loginClientForm.controls; }
  get emailvalidation() { return this.loginEmailForm.controls; }
  get mobilevalidation() { return this.loginMobileForm.controls; }
  get policyValidation() { return this.loginPolicyForm.controls; }

  clientFocusOut(event: any) {
    this.loginClientFocusOut = true;
  }
  errorclear() {
    this.statusMessage = "";
  }
  passwordFocusOut(event: any) {
    this.loginPasswordFocusOut = true;
  }
  showPswd() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.reveal = true;
    } else {
      this.passwordType = 'password';
      this.reveal = false;
    }
  }

  clearRequired () {
    this.loginClientForm.get('clientPassword').clearValidators();
    this.loginClientForm.get('clientPassword').updateValueAndValidity();
    this.loginEmailForm.get('clientPassword').clearValidators();
    this.loginEmailForm.get('clientPassword').updateValueAndValidity();
    this.loginMobileForm.get('clientPassword').clearValidators();
    this.loginMobileForm.get('clientPassword').updateValueAndValidity();
    this.loginPolicyForm.get('clientPassword').clearValidators();
    this.loginPolicyForm.get('clientPassword').updateValueAndValidity();
  }
  SetRequired () {
    this.loginClientForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginClientForm.get('clientPassword').updateValueAndValidity();
    this.loginEmailForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginEmailForm.get('clientPassword').updateValueAndValidity();
    this.loginMobileForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginMobileForm.get('clientPassword').updateValueAndValidity();
    this.loginPolicyForm.get('clientPassword').setValidators([Validators.required, Validators.minLength(1)]);
    this.loginPolicyForm.get('clientPassword').updateValueAndValidity();
  }
  submitLoginForm(loginData, loginType) {
    this.loginData = loginData;
    this.loginType = loginType;
    if (this.isCaptchaValid) {
      //this.login(loginData, loginType);
       if(loginType == 'client' && this.loginClientForm.valid) {
      this.validateLoginOTP('client');
      }
      else if(loginType == 'email' && this.loginEmailForm.valid) {
        this.validateLoginOTP('email');
      }
      else if(loginType == 'mobileNo' && this.loginMobileForm.valid) {
        this.validateLoginOTP('mobileNo');
      }  
      else if(loginType == 'policyNo' && this.loginPolicyForm.valid) {
        if(moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999") {
          this.login(this.loginPolicyForm.value,'policyNo')
        }
        else {
        this.validateLoginOTP('policyNo');
        }
      }
      /* else if(loginType == 'policyNo' && this.loginPolicyForm.controls.dob.value == '01/01/9999' && this.loginPolicyForm.valid) {
        this.login(this.loginPolicyForm.value,'policyNo')
      } */
    } else if (loginType == 'client') {
      this.loginClientForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    } else if (loginType == 'email') {
      this.loginEmailForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    } else if(loginType == 'mobileNo') {
      this.loginMobileForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    } 
    else {
      this.loginPolicyForm.get('clientCaptcha').setErrors({ captchaIncorrect: true });
    }
  }
  login(loginData, loginType) {
    let loginbody = {
      "requesttype": "CUST_LOGIN",
      "cp_login_mode": '',
      //"optradio": '',
      "cp_loginid": "",
      //"cp_loginidEn": "",
      //"otpEntered": "",
      "password": "",
      //"trans_id": ""
      "dateofbirth":"",
      "clientid":this.validateUserResp.CLIENT_ID,
      "login_source":"Customer Portal",
      "medium":this.medium,
      "landingScreen": "",
      "device_type":this.device.deviceType,
      "browser":`${this.device.browser} - ${this.device.browser_version}`,
      "os_type":this.device.os
    }
    if (loginType === 'client') {
      //loginbody.optradio = 'clientID';
      loginbody.cp_loginid = loginData.clientID;
      loginbody.landingScreen = loginData.landingScreen;
       if (loginData) {
        loginbody.cp_login_mode = 'CO';
        loginbody.dateofbirth = moment(this.loginClientForm.controls.dob.value).format('DD/MM/YYYY');
        //loginbody.otpEntered = loginData.clientOTP
        //loginbody.trans_id = this.genOtpResp.trans_id
      } /* else {
        loginbody.cp_login_mode = 'CP';
        loginbody.cp_password = loginData.clientPassword
      } */
    } else if (loginType === 'email') {
      //loginbody.optradio = 'emailID';
      loginbody.cp_loginid = loginData.emailID.toLowerCase();
      loginbody.landingScreen = loginData.landingScreen;
      if (loginData) { 
        loginbody.cp_login_mode = 'EO';
        loginbody.dateofbirth = moment(this.loginEmailForm.controls.dob.value).format('DD/MM/YYYY');
        //loginbody.otpEntered = loginData.clientOTP
        //loginbody.trans_id = this.genOtpResp.trans_id
     }  /* else {
        loginbody.cp_login_mode = 'EP';
        loginbody.cp_password = loginData.clientPassword
      } */
    } else if (loginType === 'mobileNo'){
      //loginbody.optradio = 'mobileNo';
      loginbody.cp_loginid = loginData.mobileNo;
      loginbody["countryCode"] = loginData.countryCode;
      loginbody.landingScreen = loginData.landingScreen;
     if (loginData) { 
        loginbody.cp_login_mode = 'MO';
        loginbody.dateofbirth = moment(this.loginMobileForm.controls.dob.value).format('DD/MM/YYYY');
        //loginbody.otpEntered = loginData.clientOTP
        //loginbody.trans_id = this.genOtpResp.trans_id
    }   /* else {
        loginbody.cp_login_mode = 'MP';
        loginbody.cp_password = loginData.clientPassword
      } */
    }
    else {
      loginbody.cp_loginid = loginData.policyNo;
      loginbody.landingScreen = loginData.landingScreen;
      if (loginData) {
        if(moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999" &&  serviceURL.specialLifeConfig == "ON") {
         loginbody.password = this.loginPolicyForm.controls.clientPassword.value;
         loginbody.cp_login_mode = 'SP';
         loginbody.dateofbirth =  moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
        } 
        else {
         loginbody.cp_login_mode = 'PO';
         loginbody.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
        }
         //loginbody.otpEntered = loginData.clientOTP
         //loginbody.trans_id = this.genOtpResp.trans_id
     }   /* else {
         loginbody.cp_login_mode = 'MP';
         loginbody.cp_password = loginData.clientPassword
       } */
    }
    let httpHeaders = {
      "apiname": "CUST_LOGIN"
    }
    this.loginRequest = loginbody

   /*  return this._httpService.init(httpHeaders)
      .subscribe((response) => {
        this.utils.log(response);
        if (response) { */
          this.loginService.getUserLoginDetails(loginbody)
            .subscribe((resp) => {
              if (this._httpService.isSuccessResponse(resp)) {
                //this.addnomineeService.setLoginDetail(this.loginRequest);
               /*  if(this.validateUserResponse.length==1) {
                //this.dataService.setLoginResp(resp.body);
                //this.utils.setLocalData('userDetails', JSON.stringify(resp.body), true);
                //this.utils.setLocalData('loggedIn', 'true', false);
               // this.loginService.setCustLoginResp(loginbody);
                this.dialog.open(SwitchPolicyGroupPopupComponent, { width: '400px',panelClass: 'switch-policy-grp', backdropClass: 'switch-policy-grp-backdrop', disableClose: true})
                
                //this.router.navigate(['/my-policies']);
                 this.getToaster();
                }  */
               /* else {  */
                  this.dataService.setLoginResp(resp.body);
                  this.utils.setLocalData('loggedIn', 'true', false);
                  this.utils.setLocalData("id", resp.body.CLIENT_ID, false);
                  //this.utils.setLocalData("newlogin","true",false);
                  this.utils.sendToAnalytics({
                    'event': 'logged in', 
                    'custid': resp.body.CLIENT_ID,
                  });
                  if (resp.body.PWD_EXPIRED == "Y" && resp.body.DATEOFBIRTH == "01/01/9999") {
                    this.router.navigate(['/login', 'change-password']);
                  }
                  else {
                    if(loginData.landingScreen === 'Home screen') {
                      this.router.navigate(['/my-policies']);
                    } else if(loginData.landingScreen === 'Download & Statement') {
                      this.router.navigate(['/my-downloads/policy-document']);
                    } else if(loginData.landingScreen === 'Update Bank Details') {
                      this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
                    } else if(loginData.landingScreen === 'Update Communication Details') {
                      this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
                    } else if(loginData.landingScreen === 'Update PAN / KYC') {
                      this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
                    } else if(loginData.landingScreen === 'Track Service Requests') {
                      this.router.navigate(['/customer-service/service-requests']);
                    } else {
                      this.router.navigate(['/my-policies']);
                    }
                    
                    // if (loginType === 'client') {
                    //   if(loginData.landingScreen === 'Home screen') {
                    //     this.router.navigate(['/my-policies']);
                    //   } else if(loginData.landingScreen === 'Download & Statement') {
                    //     this.router.navigate(['/my-downloads/policy-document']);
                    //   } else if(loginData.landingScreen === 'Update Bank Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
                    //   } else if(loginData.landingScreen === 'Update Communication Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
                    //   } else if(loginData.landingScreen === 'Update PAN / KYC') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
                    //   } else {
                    //     this.router.navigate(['/my-policies']);
                    //   }
                    // } else if (loginType === 'email') {
                    //   if(loginData.landingScreen === 'Home screen') {
                    //     this.router.navigate(['/my-policies']);
                    //   } else if(loginData.landingScreen === 'Download & Statement') {
                    //     this.router.navigate(['/my-downloads/policy-document']);
                    //   } else if(loginData.landingScreen === 'Update Bank Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
                    //   } else if(loginData.landingScreen === 'Update Communication Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
                    //   } else if(loginData.landingScreen === 'Update PAN / KYC') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
                    //   } else {
                    //     this.router.navigate(['/my-policies']);
                    //   }
                    // } else if(loginType === 'mobileNo') {
                    //   if(loginData.landingScreen === 'Home screen') {
                    //     this.router.navigate(['/my-policies']);
                    //   } else if(loginData.landingScreen === 'Download & Statement') {
                    //     this.router.navigate(['/my-downloads/policy-document']);
                    //   } else if(loginData.landingScreen === 'Update Bank Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
                    //   } else if(loginData.landingScreen === 'Update Communication Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
                    //   } else if(loginData.landingScreen === 'Update PAN / KYC') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
                    //   } else {
                    //     this.router.navigate(['/my-policies']);
                    //   }
                    // } else {
                    //   if(loginData.landingScreen === 'Home screen') {
                    //     this.router.navigate(['/my-policies']);
                    //   } else if(loginData.landingScreen === 'Download & Statement') {
                    //     this.router.navigate(['/my-downloads/policy-document']);
                    //   } else if(loginData.landingScreen === 'Update Bank Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "bank"}});
                    //   } else if(loginData.landingScreen === 'Update Communication Details') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "contact"}});
                    //   } else if(loginData.landingScreen === 'Update PAN / KYC') {
                    //     this.router.navigate(['/my-account'],{queryParams: {tab: "personal"}});
                    //   } else {
                    //     this.router.navigate(['/my-policies']);
                    //   }
                    // }
                    // this.router.navigate(['/my-policies']);
                    this.getToaster();
                  }
               /*   }  */
              }
              else {
                this.statusMessage = resp.head.errordetails.message;
                this.errorMessage = true;
                this.otpValidateErrorMsg = false;
                this.otpErrorMessage = true;
                if(loginbody.cp_login_mode == "SP") {
                  this.otpErrorMessage = false;
                  this.displayMessage = true;
                }
                if (this.clientCaptcha) {
                  this.clientCaptcha.reloadCaptcha();
                  this.loginClientForm.reset();
                  this.loginClientForm.patchValue({landingScreen :'Home screen'});
                  this.loginType = '';
                  this.loginData = '';
                  this.loginClientFocusOut = false;
                  this.loginPasswordFocusOut = false;
                  this.loginCaptchaFocusOut = false;     
                  this.OTPGenerate = false;     
                  this.OTPCheck = false;        
                }
                if (this.emailCaptcha) {
                  this.emailCaptcha.reloadCaptcha();
                  this.loginEmailForm.reset();
                  this.loginEmailForm.patchValue({landingScreen :'Home screen'});
                  this.loginType = '';
                  this.loginData = '';
                  this.loginClientFocusOut = false;
                  this.loginPasswordFocusOut = false;
                  this.loginCaptchaFocusOut = false;     
                  this.OTPGenerate = false; 
                  this.OTPCheck = false;            
                }
                if (this.mobileCaptcha) {
                  this.mobileCaptcha.reloadCaptcha();
                  this.loginMobileForm.reset();
                  this.loginMobileForm.patchValue({landingScreen :'Home screen'});
                  // this.loginType = '';
                  // this.loginData = '';
                  this.loginMobileForm.controls.countryCode.setValue('91');
                  this.loginClientFocusOut = false;
                  this.loginPasswordFocusOut = false;
                  this.loginCaptchaFocusOut = false;      
                  this.OTPGenerate = false;       
                  this.OTPCheck = false;     
                }
                if (this.policyCaptcha) {
                  this.policyCaptcha.reloadCaptcha();
                  this.loginPolicyForm.reset();
                  this.loginPolicyForm.patchValue({landingScreen :'Home screen'});
                  this.loginType = '';
                  this.loginData = '';
                  this.loginClientFocusOut = false;
                  this.loginPasswordFocusOut = false;
                  this.loginCaptchaFocusOut = false;      
                  this.OTPGenerate = false;       
                  this.OTPCheck = false;     
                }
              }
            });
       /*  }
        return response;
      }), */
      catchError((error) => throwError(error))
  }
 
  generateOtp(loginType) {
    let genOtpReq = {
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "dateofbirth": "",
    }
    if (loginType === 'client') {
      genOtpReq.optradio = 'clientID';
      if (this.loginClientForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'CO';
        genOtpReq.cp_loginid = this.loginClientForm.controls.clientID.value;
        genOtpReq.dateofbirth = moment(this.loginClientForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if (loginType === 'email') {
      genOtpReq.optradio = 'emailID';
      if (this.loginEmailForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'EO';
        genOtpReq.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase();
        genOtpReq.dateofbirth = moment(this.loginEmailForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if (loginType === 'mobileNo') {
      genOtpReq.optradio = 'mobileNo';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'MO';
        genOtpReq.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
        genOtpReq.dateofbirth = moment(this.loginMobileForm.controls.dob.value).format('DD/MM/YYYY');
        genOtpReq["countryCode"] = this.loginMobileForm.controls.countryCode.value;
      }
    }
    else {
      genOtpReq.optradio = 'policyNo';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        genOtpReq.cp_login_mode = 'PO';
        genOtpReq.cp_loginid = this.loginPolicyForm.controls.policyNo.value;
        genOtpReq.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
      }
    }
    return this.loginService.sendOTP(genOtpReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.OTPGenerate = true;
          if (loginType === 'client') {
            this.displayFieldsClientID = true;
          } else if (loginType === 'email') {
            this.displayFieldsEmail = true;
          } else if(loginType === 'mobileNo'){
            this.displayFieldsMobile = true;
          }
          else {
            this.displayFieldsPolicy = true;
          }
          
          this.genOtpResp = response.body;
          this.OTPTimerStart();
          this.clearRequired();
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          if(this.statusMessage) {
            this.errorMessage =  true;
            this.otpErrorMessage = true;
            this.otpValidateErrorMsg = false;
            if (loginType === 'client') {
              this.loginClientForm.controls['clientOTP'].reset();
              this.clientCaptcha.reloadCaptcha();
            } else if (loginType === 'email') {
              this.loginEmailForm.controls['clientOTP'].reset();
              this.emailCaptcha.reloadCaptcha();
            } else if ( loginType=== 'mobileNo'){
              this.loginMobileForm.controls['clientOTP'].reset();
              this.mobileCaptcha.reloadCaptcha();
            } 
            else {
              this.loginPolicyForm.controls['clientOTP'].reset();
              this.loginPolicyForm.controls['clientCaptcha'].reset();
              this.policyCaptcha.reloadCaptcha();
            }
          }
        }
      }),
      catchError((error) => throwError(error))
  }
  // createOTP(loginType) {
  //   this.OTPTimerStart();
  //   if (this.OTPCheck) {
  //     if (loginType === 'client') {
  //       this.generateOtp('client');
  //     } else if (loginType === 'email') {
  //       this.generateOtp('email');
  //     } else {
  //       this.generateOtp('mobileNo');
  //     }
  //   }
  //   this.GetLoginOTP();
  // }
  RegenOTP(loginType) {
    clearInterval(this.interval);
    this.timerSec = 180;
      if (loginType === 'client') {
        this.generateOtp('client');
      } else if (loginType === 'email') {
        this.generateOtp('email');
      } else if (loginType === 'mobileNo') {
        this.generateOtp('mobileNo');
      }
      else {
        this.generateOtp('policyNo');
      }
  }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
      this.hours = ((this.timerSec / 60) | 0);
      // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
      if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
      // if (this.minutes == 60) {this.minutes = 0;} }
      else { this.minutes = this.timerSec; }
      this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  validateUser(loginType) {
    let validateUserReq = {
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid":"",
      "dateofbirth": "",
    }
    if (loginType === 'client') {
      validateUserReq.optradio = 'clientID';
      if (this.loginClientForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'CO';
        validateUserReq.cp_loginid = this.loginClientForm.controls.clientID.value;
        validateUserReq.dateofbirth = moment(this.loginClientForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if (loginType === 'email') {
      validateUserReq.optradio = 'emailID';
      if (this.loginEmailForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'EO';
        validateUserReq.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase();
        validateUserReq.dateofbirth = moment(this.loginEmailForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if (loginType === 'mobileNo') {
      validateUserReq.optradio = 'mobileNo';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'MO';
        validateUserReq.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
        validateUserReq.dateofbirth = moment(this.loginMobileForm.controls.dob.value).format('DD/MM/YYYY');
        validateUserReq["countryCode"] = this.loginMobileForm.controls.countryCode.value;
      }
    }
    else if(loginType === 'policyNo' && !(moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999")){
      validateUserReq.optradio = 'policyNo';
      if (this.loginPolicyForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'PO';
        validateUserReq.cp_loginid = this.loginPolicyForm.controls.policyNo.value;
        validateUserReq.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
      }
    }
    else if(loginType === 'policyNo' && moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999") {
      validateUserReq.optradio = 'policyNo';
      if (this.loginPolicyForm.controls.clientSecureLogin) {
        validateUserReq.cp_login_mode = 'SP';
        validateUserReq.cp_loginid = this.loginPolicyForm.controls.policyNo.value;
        validateUserReq.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
      }
    }
    return this.loginService.userValidate(validateUserReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateUserResponse = response.body;
          this.validateUserResp = response.body;
          this.displayFlag = true;
          this.validatereqBody = validateUserReq
          this.addnomineeService.setDeleteNomineeDtls(this.validateUserResp);
          if(!(moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY') == "01/01/9999")) {
            this.generateOtp(loginType);
            }
          // this.generateOtp(loginType);
        /*   for (let item of this.validateUserResponse) {
            if (loginType == 'client') {
            if(item.CLIENT_ID == this.loginClientForm.controls.clientID.value) {
            this.validateUserResp = item;
            }
          }
           else if(loginType == 'email') {
           // if(item.CLIENT_ID == this.loginEmailForm.controls.emailID.value) {
              this.validateUserResp = item;
          //    }
          }
          else {
           // if(item.CLIENT_ID == this.loginMobileForm.controls.mobileNo.value) {
              this.validateUserResp = item;
          //    }
          } 
        } */
        
           /* if(loginType == 'client') {
            this.generateOtp('client');
            }
            else if(loginType == 'email') {
              this.generateOtp('email');
            }
            else  {
              this.generateOtp('mobileNo');
            }  */
          if (this.validateUserResp.maskMobileNo.length < 10) {
            this.maskMobileNo = "not available";
          }
          else { this.maskMobileNo = this.validateUserResp.maskMobileNo }
          if (this.validateUserResp.maskEmailId.length < 5) {
            this.maskEmailId = "not available";
          }
          else { this.maskEmailId = this.validateUserResp.maskEmailId }
          if (this.maskMobileNo === this.maskEmailId) {
            this.statusMessage = "Your contact details are not registered with us. Please contact our branch/call center to update details.";
            this.NotAvailable = true;
            this.errorMessage = true;
            this.displayMessage = true;
            this.otpValidateErrorMsg = false;
            if(validateUserReq.cp_login_mode == "SP") {
              this.errorMessage = false;
              this.displayMessage = false;
              this.otpValidateErrorMsg = false;
            }
            else if(validateUserReq.cp_login_mode == "SP" && serviceURL.specialLifeConfig == "OFF") {
              this.errorMessage = true;
              this.displayMessage = true;
              this.otpValidateErrorMsg = false;
            }
          }
          else {
            this.statusMessage = "";
            this.NotAvailable = false;
          }
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          if(this.statusMessage) {
            this.errorMessage =  true;
            this.displayMessage = true;
            this.otpValidateErrorMsg = false;
           if(validateUserReq.cp_login_mode == "SP") {
             this.displayFieldsPolicy = false;
           }
          }
          this.NotAvailable = true;
        }
      }),
      catchError((error) => throwError(error))
  }
    getToaster() {
    let toasterReqBody: toasterReqBody = {
      searchtype: 'PopupToasterRule',
      clientid: this.utils.getClientId(),
    }
    return this.myPoliciesService.getToasterPopup(toasterReqBody)
      .subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          resp.body.sort(this.myPoliciesService.compareToasterObj);
          this.showToaster = this.myPoliciesService.showHideToaster(resp.body);
          this.toasterData = this.myPoliciesService.setToasterMsg(resp.body);
          this.expInfoPopupData = this.myPoliciesService.getExpInfoPopup(resp.body);
          this.getNpsDetails(this.expInfoPopupData);
          let popupshow = false;
          for(let item of this.expInfoPopupData){
            if(item.flag  == "aps" || item.flag == "neftexp" ||  item.flag == "panexp" || item.flag == "mobemailexp"){
              popupshow = true; 
            }
        }

        if(!popupshow && this.expInfoPopupData.length > 0){
          if (this.expInfoPopupData.findIndex(item => item.flag == "permAddressFlag") >= 0) {
            this.getAddressDtls();
          }
          else if(this.expInfoPopupData.findIndex(item => item.flag == "neftFlag") >= 0){
            this.getBankDtls();
          }
          else{
            this.getContactDtls()
          }
        }
        
      //   if(!popupshow){
      //   for(let item of this.expInfoPopupData){
      //     if( item.flag == "neftFlag"  ){
      //       this.getBankDtls(); 
      //       break;
      //     }
      //     else{
      //       if(item.flag == "contactFlag"){
      //         this.getContactDtls();
      //       }
      //     }
      // }}
          if (this.expInfoPopupData.length == 0) {
            //this.dialog.open(PasaOfferPopupComponent, { panelClass: 'pasa-offer-dialog', backdropClass: 'pasa-offer-dialog-backdrop' });
          } else {
            if(popupshow){
              this.openExpiredPopup();
            }
          }
        } else {
          this._httpService.apiErrorHandler(resp);
        }
      }),
      catchError((error) => throwError(error));
  }
  openExpiredPopup() {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData
      }
    });
  }
  showKeyboard(event: any) {
    this.clientSecureLoginCheck = (event.checked);
  }
  OTPpass(loginType) {
    //this.OTPCheck = (event.checked);
    clearInterval(this.interval);
    this.timerSec = 180;
    if (loginType === "client") {
      if ((this.loginClientForm.controls.clientID.valid)) {
        this.validateUser(loginType);
      }
    }
    else if (loginType === "email") {
      if ((this.loginEmailForm.controls.emailID.valid)) {
        this.validateUser(loginType);
      }
    }
    else if (loginType === "mobileNo") {
      if ((this.loginMobileForm.controls.mobileNo.valid && this.loginMobileForm.controls.countryCode.valid)) {
        this.validateUser(loginType);
      }
    }
    else if(loginType === "policyNo") {
      if ((this.loginPolicyForm.controls.policyNo.valid)) {
        this.validateUser(loginType);
      }
    }
  }
  captchaFocusOut(event: any) {
    this.loginCaptchaFocusOut = true;
  }

  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
      this.loginClientForm.controls.clientCaptcha.updateValueAndValidity();
      this.loginEmailForm.controls.clientCaptcha.updateValueAndValidity();
      this.loginMobileForm.controls.clientCaptcha.updateValueAndValidity();
      this.loginPolicyForm.controls.clientCaptcha.updateValueAndValidity();
    }, 10);
  }
  clearCheckbox(changeEvent: MatTabChangeEvent) {
    this.loginClientForm.reset();
    this.loginClientForm.patchValue({landingScreen :'Home screen'});
    this.loginEmailForm.reset();
    this.loginEmailForm.patchValue({landingScreen :'Home screen'});
    this.loginMobileForm.reset();
    this.loginMobileForm.patchValue({landingScreen :'Home screen'});
    this.loginPolicyForm.reset();
    this.loginPolicyForm.patchValue({landingScreen :'Home screen'});
    this.loginType = '';
    this.loginData = '';
    this.loginMobileForm.controls.countryCode.setValue('91');
    this.hideFlagClientID = false;
    this.hideFlagEmail = false;
    this.hideFlagMobile = false;
    this.hideFlagPolicy = false;
    this.displayFieldsClientID = false;
    this.displayFieldsEmail = false;
    this.displayFieldsMobile = false;
    this.displayFieldsPolicy = false;
    this.timerSec = 180;
    this.OTPGenerate = false;
    this.NotAvailable = false;
    this.statusMessage = "";
    this.SetRequired();
    clearInterval(this.interval);
    this.clientSecureLoginCheck = false;
    this.OTPCheck = false;
    this.otpErrorMessage = false;
    this.errorMessage = false;
    this.displayMessage = false;
    this.otpValidateErrorMsg = false;
    this.utils.trackUserActions({ 'eventName': `My Account - ${changeEvent.tab.textLabel}` });
  }
  goToNewUser() {
    this.router.navigate(['/login', 'user-registration']);
  }
  goToForgotPwd() {
    this.router.navigate(['/login', 'forgot-password']);
  }
  clearClientCaptcha() {
    this.clientvalidation.clientCaptcha.setValue('');
  }
  clearEmailCaptcha() {
    this.emailvalidation.clientCaptcha.setValue('');
  }
  clearMobileCaptcha() {
    this.mobilevalidation.clientCaptcha.setValue('');
  }
  TemplateRef: any;
  forgotPopupOpen() {
    this.dialog.open(ForgotClientIdComponent, { panelClass: 'forgot-id-popup', backdropClass: 'forgot-id-backdrop' , disableClose: true });
  }
  loginIssuesFaq() {
    this.dialog.open(FacingLoginIssuesPopupComponent, { panelClass: 'login-issue-faq-popup', backdropClass: 'login-issue-faq-backdrop' , disableClose: true})
  }  
  downloadCovidPdf(){
    let downloadLink = document.createElement("a");
    downloadLink.download = "Customer_FAQ_For_BCP_Scenario_VF";
    downloadLink.href = "assets/pdf/Customer_FAQ_For_BCP_Scenario_VF.pdf";
    downloadLink.click();
  }
  init() {
    return this._httpService.init(this.httpHeaders);
    /* return this._httpService.init(this.httpHeaders).subscribe((resp) => {
      this._httpService.initResp = resp;
    }),
      catchError((error) => throwError(error)); */
  }
  startInitTimer() {
    let initTimeoutDuration = 15 * 60;
    this.initInterval = setInterval(() => {
      if (initTimeoutDuration > 0) {
        initTimeoutDuration--;
      } else {
        this.init();
        initTimeoutDuration = 15 * 60;
      }
    }, 1000);
  }
  ngOnDestroy() {
    clearInterval(this.initInterval);
  }
  validateLoginOTP(loginType) {
    let validateOtpReq = {
      "optradio": "",
      "trans_id": this.genOtpResp.trans_id,
      "otpEntered":"",
      "cp_loginid":"",
      "cp_login_mode": "",
      "dateofbirth": "",
      "login_source":"Customer Portal",
      "medium":this.medium,
      "device_type":this.device.deviceType,
      "browser":`${this.device.browser} - ${this.device.browser_version}`,
      "os_type":this.device.os,
    }
    if (loginType === 'client') {
      validateOtpReq.optradio = 'clientID';
      validateOtpReq.cp_login_mode = 'CO';
      if (this.loginClientForm.controls.clientSecureLogin) {
        validateOtpReq.otpEntered = this.loginClientForm.controls.clientOTP.value;
        validateOtpReq.cp_loginid = this.loginClientForm.controls.clientID.value
        validateOtpReq.dateofbirth = moment(this.loginClientForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if (loginType === 'email') {
      validateOtpReq.optradio = 'emailID';
      validateOtpReq.cp_login_mode = 'EO';
      if (this.loginEmailForm.controls.clientSecureLogin) {
        validateOtpReq.otpEntered = this.loginEmailForm.controls.clientOTP.value;
        validateOtpReq.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase();
        validateOtpReq.dateofbirth = moment(this.loginEmailForm.controls.dob.value).format('DD/MM/YYYY');
      }
    } else if(loginType === 'mobileNo') {
      validateOtpReq.optradio = 'mobileNo';
      validateOtpReq.cp_login_mode = 'MO';
      if (this.loginMobileForm.controls.clientSecureLogin) {
        validateOtpReq.otpEntered = this.loginMobileForm.controls.clientOTP.value;
        validateOtpReq.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
        validateOtpReq.dateofbirth = moment(this.loginMobileForm.controls.dob.value).format('DD/MM/YYYY');
        validateOtpReq["countryCode"] = this.loginMobileForm.controls.countryCode.value;
      }
    }
    else {
      validateOtpReq.optradio = 'policyNo';
      validateOtpReq.cp_login_mode = 'PO';
      if (this.loginPolicyForm.controls.clientSecureLogin) {
        validateOtpReq.otpEntered = this.loginPolicyForm.controls.clientOTP.value;
        validateOtpReq.cp_loginid = this.loginPolicyForm.controls.policyNo.value;
        validateOtpReq.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
      }
    }
    let httpHeaders = {
      "apiname": "CUST_LOGIN"
    }
    return this.loginService.validateLoginOtp(validateOtpReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateOtpResponse = response.body;
          if(this.validateOtpResponse.status == "SUCCESS") {
            //this.getDedupeData();
            setTimeout(() => {
              if (loginType === 'client') {
                this.getDedupeData('client')
               } else if (loginType === 'email') {
                this.getDedupeData('email')
               } else if ( loginType=== 'mobileNo'){
                this.getDedupeData('mobileNo')
               } 
               else {
                this.getDedupeData('policyNo')
               }
            }, 100);
        }
      }
        else {
          this.statusMessage = response.head.errordetails.message;
          if(this.statusMessage) {
            this.displayMessage =  false;
            this.otpErrorMessage = false;
            this.errorMessage = true;
            this.validationMessage = true;
            this.otpValidateErrorMsg = true;
            if (loginType === 'client') {
              this.hideFlagClientID = true;
              this.loginClientForm.controls['clientOTP'].reset();
              this.clientCaptcha.reloadCaptcha();
            } else if (loginType === 'email') {
              this.hideFlagEmail = true;
              this.loginEmailForm.controls['clientOTP'].reset();
              this.emailCaptcha.reloadCaptcha();
            } else if ( loginType=== 'mobileNo'){
              this.hideFlagMobile = true;
              this.loginMobileForm.controls['clientOTP'].reset();
              this.mobileCaptcha.reloadCaptcha();
            } 
            else {
              this.hideFlagPolicy = true
              this.loginPolicyForm.controls['clientOTP'].reset();
              this.loginPolicyForm.controls['clientCaptcha'].reset();
              this.policyCaptcha.reloadCaptcha();
            }
          }
        }
        return response;
    }),
      catchError((error) => throwError(error))
  }
getDedupeResp(loginType) {
  let dedupeRequest = {
    "cp_login_mode": "",
    "cp_loginid": "",
    "dateofbirth": "",
    "fields": "",
  }
  if (loginType === 'client') {
    dedupeRequest.cp_login_mode = 'CO';
    if (this.loginClientForm.controls.clientSecureLogin) {
      dedupeRequest.cp_loginid = this.loginClientForm.controls.clientID.value
      dedupeRequest.dateofbirth = moment(this.loginClientForm.controls.dob.value).format('DD/MM/YYYY');
    }
  }
  else if (loginType === 'email') {
    dedupeRequest.cp_login_mode = 'EO';
    if (this.loginEmailForm.controls.clientSecureLogin) {
      dedupeRequest.cp_loginid = this.loginEmailForm.controls.emailID.value.toLowerCase()
      dedupeRequest.dateofbirth = moment(this.loginEmailForm.controls.dob.value).format('DD/MM/YYYY');
    }
  }
  else if(loginType === 'mobileNo') {
    dedupeRequest.cp_login_mode = 'MO';
    if (this.loginMobileForm.controls.clientSecureLogin) {
      dedupeRequest.cp_loginid = this.loginMobileForm.controls.mobileNo.value;
      dedupeRequest.dateofbirth = moment(this.loginMobileForm.controls.dob.value).format('DD/MM/YYYY');
      dedupeRequest["countryCode"] = this.loginMobileForm.controls.countryCode.value;
    }
  }
  else {
    dedupeRequest.cp_login_mode = 'PO';
    if (this.loginPolicyForm.controls.clientSecureLogin) {
      dedupeRequest.cp_loginid = this.loginPolicyForm.controls.policyNo.value;
      dedupeRequest.dateofbirth = moment(this.loginPolicyForm.controls.dob.value).format('DD/MM/YYYY');
    }
  }
  this.dedupeReq =  Object.assign({}, dedupeRequest)
  /* if (loginType === 'client') {
    this.OTPpass('client');
  } else if (loginType === 'email') {
    this.OTPpass('email');
  } else if ( loginType=== 'mobileNo'){
    this.OTPpass('mobileNo');
  } 
  else {
    this.OTPpass('policyNo');
  } */
  return this.loginService.getDedupeResp(dedupeRequest)
  .pipe(timeout(serviceTimeout),catchError(this.handleDedupeError.bind(this,loginType)))
  .subscribe((response) => {
      this.addnomineeService.setLoginDetail(this.dedupeReq);
      if (this._httpService.isSuccessResponse(response)) {
        /* if (loginType == 'client') {
          this.hideFlagClientID = true
        } else if (loginType === 'email') {
          this.hideFlagEmail = true
        } else if ( loginType=== 'mobileNo'){
          this.hideFlagMobile = true;
        }  
        else {
          this.hideFlagPolicy =true       
        } */
        this.dedupeResponse = response.body;
        if(response.body.source) {
        this.clientSource = response.body.source;
        }
        if(this.dedupeResponse == null) {
          if (loginType === 'client') {
            this.OTPpass('client');
          } else if (loginType === 'email') {
            this.OTPpass('email');
          } else if ( loginType=== 'mobileNo'){
            this.OTPpass('mobileNo');
          } 
          else {
            this.OTPpass('policyNo');
          }
        }
        /* else if((this.dedupeResponse.source).toUpperCase()== "NONE") {
          this.statusMessage = "Invalid Login Id";
          this.errorMessage = true;
          this.displayMessage = true;
          this.otpValidateErrorMsg = false;
        } */ 
        else 
          if((this.dedupeResponse.source).toUpperCase()== "NONE" || (this.dedupeResponse.source).toUpperCase() == "HDFC" || this.dedupeResponse.source == "HDFC,EXIDE") {
            if (loginType === 'client') {
              this.OTPpass('client');
            } else if (loginType === 'email') {
              this.OTPpass('email');
            } else if ( loginType=== 'mobileNo'){
              this.OTPpass('mobileNo');
            } 
            else {
              this.OTPpass('policyNo');
            }
          }
          else if((this.dedupeResponse.source).toUpperCase() == "EXIDE") {
            // if(this.dedupeResponse.caseId && this.dedupeResponse.caseId != null){
            //   this.dialog.open(this.exideCovidDialog, { panelClass: 'exide-covid-popup', backdropClass: 'exide-covid-popup-backdrop', disableClose: true });
            //   this.caseId= this.dedupeResponse.caseId;
            //   }
            //   else {
            //      window.open(serviceURL.exideURL,'_self');
            //   }
              window.open(serviceURL.exideURL,'_self');
              /* this.loginClientForm.reset();
              this.loginEmailForm.reset();
              this.loginMobileForm.reset();
              this.loginPolicyForm.reset(); */
          }
      }
    else {
      if (loginType === 'client') {
        this.OTPpass('client');
      } else if (loginType === 'email') {
        this.OTPpass('email');
      } else if ( loginType=== 'mobileNo'){
        this.OTPpass('mobileNo');
      } 
      else {
        this.OTPpass('policyNo');
      }
    }
  });
}
getDedupeData(loginType) {
  let dedupeReq = {
    "cp_login_mode":this.dedupeReq.cp_login_mode?this.dedupeReq.cp_login_mode:this.userDetails.LOGINMODE,
    "cp_loginid":this.dedupeReq.cp_loginid?this.dedupeReq.cp_loginid:this.userDetails.LOGINID,
    "dateofbirth":this.dedupeReq.dateofbirth? this.dedupeReq.dateofbirth :this.userDetails.DATEOFBIRTH,
    "fields": "policy,profile"
  }
 /* if(dedupeReq.cp_login_mode == "CO") {
    this.loginId = this.userDetails.CLIENT_ID;
  }
  else if(dedupeReq.cp_login_mode == "MO") {
    this.loginId = this.userDetails.MOBILE_NUM
  }
  else if(dedupeReq.cp_login_mode  == "EO") {
    this.loginId = this.userDetails.EMAIL_ID
  }
  else {
    this.loginId = this.policyId
  }  */
  this.dataService.setLandingScreenDetails(this.loginData);
  return this.loginService.getDedupeResp(dedupeReq)
  .pipe(timeout(serviceTimeout),catchError(this.handleTimeOutError.bind(this,loginType)))
  .subscribe((response) => {
    if (this._httpService.isSuccessResponse(response)) {
      //if(this.dedupeDetails) {
       
        let dedupeResp = response.body;
        this.dedupeDetails = response.body.dedUpeDetails;
        this.dataService.setDedupeResp(this.dedupeDetails);
        this.dataService.setDedupeData(response.body);
         if(!this.dedupeDetails || (this.dedupeDetails.length == 0)) {
           if (loginType === 'client') {
            this.login(this.loginClientForm.value, 'client');
          } else if (loginType === 'email') {
            this.login(this.loginEmailForm.value, 'email');
          } else if ( loginType=== 'mobileNo'){
            this.login(this.loginMobileForm.value, 'mobileNo');
          } 
          else {
            this.login(this.loginPolicyForm.value,'policyNo');
          } 
          //this.handleTimeOutError.bind(this,loginType)
        } 
         else if(dedupeResp == null) {
          if (loginType === 'client') {
            this.login(this.loginClientForm.value, 'client');
          } else if (loginType === 'email') {
            this.login(this.loginEmailForm.value, 'email');
          } else if ( loginType=== 'mobileNo'){
            this.login(this.loginMobileForm.value, 'mobileNo');
          } 
          else {
            this.login(this.loginPolicyForm.value,'policyNo');
          }
        }
       else if(this.dedupeDetails.length > 1) { /*this.clientSource.toUpperCase() == "HDFC,EXIDE" || */
        this.dialog.open(SwitchPolicyGroupPopupComponent, { width: '400px',panelClass: 'switch-policy-grp', backdropClass: 'switch-policy-grp-backdrop', disableClose: true, data: this.loginData.landingScreen })
        this.loginClientForm.reset();
        this.loginClientForm.patchValue({landingScreen :'Home screen'});
        this.loginEmailForm.reset();
        this.loginEmailForm.patchValue({landingScreen :'Home screen'});
        this.loginMobileForm.reset();
        this.loginMobileForm.patchValue({landingScreen :'Home screen'});
        this.loginMobileForm.controls.countryCode.setValue('91'); 
        this.loginPolicyForm.reset();
        this.loginPolicyForm.patchValue({landingScreen :'Home screen'});
        this.hideFlagClientID = false;
        this.hideFlagEmail = false;
        this.hideFlagMobile = false;
        this.hideFlagPolicy =  false;
        this.displayFieldsClientID = false;
        this.displayFieldsEmail =  false;
        this.displayFieldsMobile = false;
        this.displayFieldsPolicy= false;
        this.OTPGenerate = false;
        this.loginClientForm.markAsUntouched();
        this.loginEmailForm.markAsUntouched();
        this.loginMobileForm.markAsUntouched();
        this.loginPolicyForm.markAsUntouched();
      }
      else {
        if (loginType === 'client') {
          this.login(this.loginClientForm.value, 'client');
        } else if (loginType === 'email') {
          this.login(this.loginEmailForm.value, 'email');
        } else if ( loginType=== 'mobileNo'){
          this.login(this.loginMobileForm.value, 'mobileNo');
        } 
        else {
          this.login(this.loginPolicyForm.value,'policyNo');
        }
      }  
    //}
  }
else {
  this.dataService.setDedupeResp(null);
  if (loginType === 'client') {
    this.login(this.loginClientForm.value, 'client');
  } else if (loginType === 'email') {
    this.login(this.loginEmailForm.value, 'email');
  } else if ( loginType=== 'mobileNo'){
    this.login(this.loginMobileForm.value, 'mobileNo');
  } 
  else {
    this.login(this.loginPolicyForm.value,'policyNo');
  }
}});
}
handleDedupeError(loginType) {
 LoaderInterceptorService.requestCount=0;
 this.loader.hide();
  setTimeout(() => {
  if (loginType === 'client') {
    this.OTPpass('client');
  } else if (loginType === 'email') {
    this.OTPpass('email');
  } else if ( loginType=== 'mobileNo'){
    this.OTPpass('mobileNo');
  } 
  else {
    this.OTPpass('policyNo');
  }
}, 50); 
}
handleTimeOutError(loginType) {
  this.dataService.setDedupeResp(null);
  LoaderInterceptorService.requestCount=0;
  this.loader.hide();
  setTimeout(() => {
    if (loginType === 'client') {
      this.login(this.loginClientForm.value, 'client');
    } else if (loginType === 'email') {
      this.login(this.loginEmailForm.value, 'email');
    } else if ( loginType=== 'mobileNo'){
      this.login(this.loginMobileForm.value, 'mobileNo');
    } 
    else {
      this.login(this.loginPolicyForm.value,'policyNo');
    }
  }, 50);
  }
    /* openExpiredPopup(showKyc: boolean) {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData,
        showKycExpired: showKyc
      }
    });
  }*/
  openConfirmPopup(flag: string, dtls: any) {
    this.dialog.open(MyPoliciesDialogComponent, {
      panelClass: 'my-policies-dialog', disableClose: true,
      data: {
        dtls: this.expInfoPopupData,
        confirmPopup: flag,
        displayDtls: dtls
      }
    });
  }
  getBankDtls() {
    let reqBody = {
      clientid: this.utils.getClientId(),
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(reqBody).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            let bankDtls = response.body[0];
            if(bankDtls.ACC_NUM && bankDtls.ACCNAME){
            for (let key of Object.keys(bankDtls)) {
              bankDtls[key] = bankDtls[key] ? bankDtls[key] : "NA";
            }
            this.openConfirmPopup("BANK", bankDtls);
          }
        }
        } else {          
          //this._httpService.apiErrorHandler(response);
        }

      }),
      catchError((error) => throwError(error));
  }
  getContactDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getCContactDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let contactDetail = response.body[0];
          if(contactDetail.CONTACT_CELL_PH_NUMBR && contactDetail.CONTACT_EMAIL_ADDRESS){
          for (let key of Object.keys(contactDetail)) {
            contactDetail[key] = contactDetail[key] ? contactDetail[key] : "NA";
          }
          this.openConfirmPopup("CONTACT", contactDetail);
        }
        } 
      } else {
        //this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  } 
  mobileNoKeyPress(event: KeyboardEvent) {
    if (this.loginMobileForm.controls.countryCode.value == '91' && this.loginMobileForm.controls.mobileNo.value && this.loginMobileForm.controls.mobileNo.value.length >= 10) {
      event.preventDefault();
    } else if (this.loginMobileForm.controls.countryCode.value != '91' && this.loginMobileForm.controls.mobileNo.value  && this.loginMobileForm.controls.mobileNo.value.length >= 16) {
      event.preventDefault();
    }
  }

  getAddressDtls() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getClientAddressDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          let address = response.body[0];
          if(address.HOUSENUMBER && address.STREET){
          for (let key of Object.keys(address)) {
            address[key] = address[key] ? address[key] : "NA";
          }
          this.openConfirmPopup("ADDRESS", address);
        }
        } 
      } else {
        this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }

  onBtnClick() {
    this.dialog.closeAll();
    window.open(serviceURL.sidebannerLink, "_blank");
  }

  redirectExideLink(){
    window.open(serviceURL.exideURL,'_self');
  }
  redirectCovidForm(){
    this.router.navigate(['/dcq']);
    this.dialog.closeAll();
  }

  onInputChanges(evt : any): void{
    let input = evt.target;
    input.value = input.value.replace(/[^0-9/]/g,'');
  }

  getCountryCode(){

    this.loginService.getCountryCode().subscribe((res) => {
        if(this._httpService.isSuccessResponse(res)){
            if(res.body.COUNTRY_CODE != null){
              let data = res.body.COUNTRY_CODE;
              let code = [];
              let majorCntry = [];
              for(let keys of Object.keys(data)){
                  if(data[keys][5][2] !== 'us' && data[keys][5][2] !== 'ae')
                      code.push(data[keys][5])
                  else
                      majorCntry.push(data[keys][5])
              }
              code.sort((a,b) => a[1].localeCompare(b[1]))
              majorCntry.reverse();
              for(let c of majorCntry){
                  code.unshift(c);
              }
              this.country = code;
            }
        }
    })
  }
  private _filter(value){
    console.log(value);
    let type = Number(value);
    if(isNaN(type)){
      const fvalue = value.toLowerCase();
      return this.country.filter(option => option[1].toLowerCase().startsWith(fvalue))
    } else{
      return this.country.filter(option => option[0].includes(value))
    }
    
  }
  getNpsDetails(expData) {
    let reqBody = {
      clientId: this.utils.getClientId(),
    }
    return this.myPoliciesService.getNpsValue(reqBody).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.NPSNudge == 'Y' && expData.length == 0) {
          this.dialog.open(BuyNpsComponent, { width: '800px',panelClass: 'buy-nps', backdropClass: 'buy-nps-backdrop', disableClose: true,data:{src:'nudge'}});
          this.dataService.setNpsCheck(response.body.NPSCheck);
        } 
        else if(response.body && response.body.NPSCheck) {
          this.dataService.setNpsCheck(response.body.NPSCheck);
        }
      } else {
        this._httpService.apiErrorHandler(response);
      }
    }),
      catchError((error) => throwError(error));
  }
}

