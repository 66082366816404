<div class="login-page">
    <div class="login-page-padding">
    <div class="login-page flex">
        <div class="login-left-div">
            <mat-card class="login-left-section">
            <app-policy-based-login></app-policy-based-login>
            <div class="surrender-notes-border"><div class="surrender-notes"><span class="surrender-notes-titles">Please Note:</span> Surrender is applicable only for policies that have acquired a Surrender value as per terms and conditions.</div>
        </div></mat-card>
        </div>
        <div class="login-right-div">
            <mat-card class="login-right-section">
                <img src="/assets/Login-landing.png">
            </mat-card>
        </div>
    </div>
</div>  
</div>    
