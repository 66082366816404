<div class="wellness-voucher">
        <div class="sr-details-top">
            Wellness Voucher
        </div>
        <div class="flex wellness-voucher-split">
            <div class="wellness-voucher-left">
                <mat-card class="voucher-card" >
                    <div *ngIf="!displayMessage">
                    <div class="sr-details-options" *ngFor="let item of voucherDetails" >
                        <div class="sr-details-header flex">
                            <div class="voucher-id">Voucher ID : {{item.VOUCHER_ID}}</div>
                            <div class="button-div">
                                <button mat-button class="upload-btn" (click)="generateVoucherPdf('view')">View</button>
                                <button mat-button class="upload-btn" (click)="generateVoucherPdf('download')">Download</button>
                            </div>
                        </div>
                        <div class="sr-details-content flex">
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Client ID
                                </div>
                                <div class="sr-detail-text" *ngIf="item.CLIENT_ID">
                                    {{item.CLIENT_ID}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.CLIENT_ID">
                                   NA
                                </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    PH Name
                                </div>
                                <div class="sr-detail-text" *ngIf="item.PH_NAME">
                                    {{item.PH_NAME}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.PH_NAME">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Policy Number
                                </div>
                                <div class="sr-detail-text" *ngIf="item.POLICY_NO">
                                    {{item.POLICY_NO}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.POLICY_NO">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Age
                                </div>
                                <div class="sr-detail-text" *ngIf="item.AGE">
                                    {{item.AGE}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.AGE">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Gender
                                </div>
                                <div class="sr-detail-text" *ngIf="item.GENDER">
                                    {{item.GENDER}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.GENDER">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Value
                                </div>
                                <div class="sr-detail-text" *ngIf="item.TOKEN_AMOUNT">
                                    {{item.TOKEN_AMOUNT}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.TOKEN_AMOUNT">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    Date Of Issuance
                                </div>
                                <div class="sr-detail-text" *ngIf="item.VOUCHER_ISSUE_DT">
                                        {{voucherIssueDt}}
                                    </div>
                                <div class="sr-detail-text" *ngIf="!item.VOUCHER_ISSUE_DT">
                                    NA
                                 </div>
                            </div>
                            <div class="sr-details-field">
                                <div class="sr-detail-title">
                                    UIN Number
                                </div>
                                <div class="sr-detail-text" *ngIf="item.UIN_NO">
                                    {{item.UIN_NO}}
                                </div>
                                <div class="sr-detail-text" *ngIf="!item.UIN_NO">
                                    NA
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="validity">
                        <div class=" heading flex">
                            <div class="validity-header">Validity:</div>
                            <div class="disclaimer-popup" (click)="openDisclaimerPopup(disclaimerPopup)"> View Disclaimer</div>
                        </div>
                        <div class="heading">
                            <div class="validity-message">Valid up to one year from the date of issuance.</div>
                        </div>
                    </div> 
                    </div>
                    <div class="display-message" *ngIf="displayMessage">No Records Found</div>
                </mat-card>
            </div>
            <div class="fill-remaining-space"></div>
             <div class="wellness-voucher-right" *ngIf="!isCustomLink">
                <app-sidebanner></app-sidebanner>
            </div> 
        </div>
        <ng-template #disclaimerPopup>
                <div class="disclaimer-popup">
                    <div mat-dialog-actions class="mat-dialog-actions">
                        <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                            Close
                            <img src="assets/close.svg">
                        </div>
                    </div>
                    <div class="disclaimer-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">
                        Disclaimer
                    </div>
                    <div class="disclaimer-popup-content popup-content-height">
                        <div> <span>Dear Customer,</span>
                            <div class="disclaimer-info" *ngFor="let item of voucherDisclaimer">
                                {{item}}
                            </div>
                            </div>
                        <div class="disclaimer-popup-btns">
                            <button class="close-button" (click)="closeDialog()">Close</button>                    
                        </div>
                    </div>            
                </div>
            </ng-template>
    </div>
