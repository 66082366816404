import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { Router } from '@angular/router';
import { validateUserReqBody } from '../login/login-data-modal';
import { loginService } from 'src/app/services/login/login.service';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-set-up-social-media-id',
  templateUrl: './set-up-social-media-id.component.html',
  styleUrls: ['./set-up-social-media-id.component.css']
})
export class SetUpSocialMediaIdComponent implements OnInit {

  LoginData = this.dataService.getLoginResp();
  UserEmailID: string = this.LoginData.EMAIL_ID || "NA";
  redirectMyAccountClicked: boolean;
  UserMobileNo: string = this.LoginData.MOBILE_NUM || "NA";
  UserClientID: string = this.LoginData.CLIENT_ID;
  validateUserResp: any = {};
  statusMessage: string;
  NotAvailable: boolean;
  mailData: string;
  mobileData: string;

  constructor(public dataService: DataService, private router: Router, public loginService: loginService, public _httpService: HttpService) { }

  ngOnInit(): void {
    // this.validateUseremail();
    // this.validateUsermobile();
  }

  redirectToMyAccount() {
    this.router.navigate(['/my-account']);
  }

  validateUseremail() {
    let validateUserReq: validateUserReqBody = {
      "requesttype": "Validateuser",
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    validateUserReq.optradio = 'emailID';
    validateUserReq.cp_login_mode = 'EO';
    validateUserReq.cp_loginid = this.dataService.getLoginResp().EMAIL_ID;
    return this.loginService.validateUser(validateUserReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateUserResp = response.body;
          console.log(this.validateUserResp.maskMobileNo);
        }
        else {
          this.mailData = this.dataService.getLoginResp().EMAIL_ID;
          this.errorMessages();
        }
      }),
      catchError((error) => throwError(error))
  }
  validateUsermobile() {
    let validateUserReq: validateUserReqBody = {
      "requesttype": "Validateuser",
      "cp_login_mode": "",
      "optradio": "",
      "cp_loginid": "",
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    validateUserReq.optradio = 'mobileNo';
    validateUserReq.cp_login_mode = 'MO';
    validateUserReq.cp_loginid = this.dataService.getLoginResp().MOBILE_NUM;
    return this.loginService.validateUser(validateUserReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.validateUserResp = response.body;
          console.log(this.validateUserResp.maskMobileNo);
        }
        else {
          this.mobileData = this.dataService.getLoginResp().MOBILE_NUM;
          this.errorMessages();
        }
      }),
      catchError((error) => throwError(error))
  }
  errorMessages() {
    if (this.mailData != null && this.mobileData != null) {
      this.statusMessage = this.mailData + "/" + this.mobileData + " is linked to multiple client Ids. Please update the email id/contact number.";
    }
    else if (this.mailData == null && this.mobileData != null) { this.statusMessage =  this.mobileData + "is linked to multiple client Ids. Please update the contact number."; }
    else if (this.mailData != null && this.mobileData == null) { this.statusMessage = this.mailData + "is linked to multiple client Ids. Please update the email id."; }

  }
}
