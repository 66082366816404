import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { serviceURL } from 'src/environments/environment';
import { FormBuilder,FormGroup,FormControl } from '@angular/forms';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { Utils } from 'src/app/utils/common-functions'

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.css']
})
export class MessagePopupComponent implements OnInit {
  title: string;
  mainMessage: string
  subMessage: string;
  closeIcon: boolean;
  submitBtnLabel: string;
  cancelBtnLabel: string;
  mainMessageHtml: string;
  isHNIUSer: boolean = false;
  userDetails: any = {};
  terms : FormGroup;
  proceedBtn : string;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dataService: DataService, public dialogRef: MatDialogRef<MessagePopupComponent>,public router: Router, public auth: AuthService, public fb : FormBuilder,public myPoliciesService: MyPoliciesService,public _httpService: HttpService,public utils: Utils,) { }

  get getAccept(){
    return this.terms.get('accept').value
  }

  ngOnInit(): void {
    this.setIsHNIUser();
    this.title = this.data.title;
    this.mainMessage = this.data.mainMessage;
    this.subMessage = this.data.subMessage;
    this.closeIcon = this.data.closeIcon;
    this.submitBtnLabel = this.data.submitBtnLabel;
    this.cancelBtnLabel = this.data.cancelBtnLabel;
    this.mainMessageHtml = this.data.mainMessageHtml;
    this.proceedBtn = this.data.proceedBtn;

    this.terms = this.fb.group({
      accept : []
    })
  }
  setIsHNIUser() {
    this.userDetails = this.dataService.getLoginResp();
     if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
       this.isHNIUSer = true;
     }
  }
  onSubmit() {
    if (this.data.submitAction == "OKYC") {
      this.dialogRef.close("OKYC");
    }
    else if(this.data.submitAction == "ok"){
      this.router.navigate(['/my-link'], { queryParams : {action : 'cp_unsecuredChequePickUpPage'}});
      setTimeout(() => {
        this.dialogRef.close();       
      }, 40);
      this.auth.logout();
    }
  }
  onCancel() {
    this.dialogRef.close();
    if(this.data.source == 'voc') {
      this.router.navigate(['/login']);
    }
    else if(this.data.source == 'block-advance-payment') {
      this.advPaymentInsert('Update');
    }
  }
  closeDialog() {
    this.dialogRef.close();
    if(this.data.source == 'voc') {
      this.router.navigate(['/login']);
    }
  }
  redirect(data){
    if(data == 'reEnter'){
      this.dialogRef.close('true');
    }
    else if(data == 'onlinePayment'){
      window.open(serviceURL.onlinePaymentcheque,'_blank','noopener noreferrer');
      // this.router.navigate([serviceURL.onlinePaymentcheque])
    }else if(data == 'branchLocator'){
      window.open(serviceURL.branchLocator,'_blank','noopener noreferrer');
    }
  }
  readTnC(event: any){
    this.terms.setValue(event.checked);    
  }

  proceedAction(){
    if(this.data.proceedBtn){
      this.dialogRef.close("PROCEED");
    }
  }
  advPaymentInsert(e:any) {
    let reqBody = {
      "clientid": this.utils.getClientId(),
      "policyno": this.data.policyNo,
      "action": e,
      "popupShown":'Y',
    }
    return this.myPoliciesService.advancePayment(reqBody).subscribe((response) => { 
      if (this._httpService.isSuccessResponse(response)) {
        if(response.body && (response.body.msg).toLowerCase() == "success") {
          if(e=='Update') {
            this.dialogRef.close();
          }
        }
      } else {          
        this._httpService.apiErrorHandler(response);
      }
    }),
    catchError((error) => throwError(error));
  }
 }
