<div class="existing-fund">
        <div class="flex tab-wrapper">
                <div class="edit-payment-btn ">
                    <a href="/assets/pdf/Surrender_Journey_step_Banner.pdf" target="_blank" rel="noopener noreferrer">
                    <button class="delete-btn" >Help</button>
                    <button class="delete-btn mobile-help">Help</button></a>
                </div>
                <mat-tab-group class="surrender-policy-details" [(selectedIndex)]="tabIndex">
                    <mat-tab label="quoteGeneration" [disabled]="!isTabActive">
                            <ng-template mat-tab-label>
                                    <div class="quote-icon ">
                                        <img src='/assets/quote_generation_active.svg' class="tab-icon">
                                    </div>
                                    <div class="flex policy-label" [ngClass]="{'policy-label-active':isTabActive}"><span class="">Quote</span><span
                                            class="label-second-word">Generation</span>
                                        <div class="bottom-border"></div>
                                    </div>
                                    <div class="quote-icon progress-bar">
                                            <img src='/assets/progress_bar_arrow.svg' class="progress-arrow">
                                        </div>
                                </ng-template>
                <div class="policy-carousel" *ngIf="!payeeDtls">
                                <mat-card class="carousel-card policy-details-card">
                                    <div class="card-top">
                                       <div class="flex policy-info">
                                            <div class="policy-icon">
                                                <img src="assets/Investment icon.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Investment'">
                                                <img src="assets/policy_icon4.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Health'">
                                                <img src="assets/policy_icon3.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Pension'">
                                                <img src="assets/policy_icon2.svg"
                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children'">
                                                <img src="assets/policy_icon1.svg"
                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'">
                                            </div>
                                            <div class="policy-content">
                                                <div class="details-top">
                                                    <div [ngClass]="{'pink-policy': policyCardDtls.POLICYSTATUS==='Lapsed' }">
                                                        <div class="policy-number">{{policyCardDtls.POLICYNO}} - {{policyCardDtls.POLICYSTATUS}}</div>
                                                    </div>
                                                    <div class="brand-name"><span>HDFC</span> Life</div>
                                                    <div class="plan-name">{{policyCardDtls.PRDNAME}} - {{policyCardDtls.PRODUCT_TYPE}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="policy-amount-details">
                                            <div class="flex details-split">
                                                <div class="premium-date">
                                                    <div class="date-img">
                                                        <img src="assets/calendar_blue.svg">
                                                    </div>
                                                    <div class="policy-metadata">
                                                        <div class="label-text">Surrender Value
                                                        </div>
                                                        <div class="due-date">
                                                                <span *ngIf="quoteAmount !== 'NA'"><span class="premium-rupee-sign">&#8377;</span>
                                                               <span>{{quoteAmount | number:'1.0-0':'en-IN'}}</span></span><span *ngIf="quoteAmount == 'NA'">NA</span></div>
                                                    </div>
                                                </div>
                                                <div class="line"></div>
                                                <div class="premium-amount">
                                                    <div class="premium-img">
                                                        <img src="assets/calender.svg">
                                                    </div>
                                                    <div class="policy-metadata">
                                                        <div class="label-text" *ngIf="productType != 'ULIP'">Quote generated date</div>
                                                        <div class="label-text" *ngIf="productType == 'ULIP'">Quote valid till </div>
                                                        <div class="flex amount-total" *ngIf="productType != 'ULIP'">
                                                            <span *ngIf="quoteGeneratedDate != 'NA'">{{quoteGeneratedDate }}</span>
                                                            <span *ngIf="quoteGeneratedDate == 'NA'">NA</span>
                                                        </div>
                                                        <div class="flex amount-total" *ngIf="productType == 'ULIP'">
                                                            <span *ngIf="NavDate != 'NA'">{{ NavDate }}</span>
                                                            <span *ngIf="NavDate == 'NA'">NA</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="surrender-noteContent" *ngIf="productType == 'ULIP'">
                                        <div><span class="surrender-note-heading">Please Note:</span> <span class="surrender-note">For this policy, surrender quote shown above is considering surrender date as {{NavDate}}. Surrender value for ULIP policies are calculated based on the date on which all the required documents are submitted. If you submit all the required documents before 3PM today, you will get 'same day' NAV. If you submit the documents after 3PM today, you will get 'next working day' NAV as surrender pay-out. Thus, the surrender quote shown above might differ from your actual surrender pay-out.</span></div>
                                    </div>
                                    <mat-card class="policy-details-card">
                                            <div class="flex policy-card">
                                                <div class="column-one">
                                                    <div class="details-part">
                                                        <div class="policy-cont">
                                                            <div class="first-row-left no-margin" *ngFor="let item of details">
                                                                <div *ngIf="item.maintext" class="spacing-top">
                                                                    <div class="d-flex">
                                                                        <img class="icon" src="{{item.icon}}">
                                                                        <div class="frequency-info">
                                                                            <div class="label-text">{{item.label}}</div>
                                                                            <div class="main-text">{{item.maintext}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column-two">
                                                    <div class="select-action">Select an action</div>
                                                    <div class="arrow-openclose">
                                                        <mat-list class="select-act"  >
                                                            <mat-list-item class="list-item"
                                                             *ngFor="let item of selectAction" 
                                                             (click)=selectActionRedirect(item.action,editPan,item,maturityClaim,item.data)>
                                                                <div class="img-box">
                                                                    <img src="{{item.image}}">
                                                                </div>{{item.action}}
                                                            </mat-list-item>
                                                        </mat-list>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="surrender-noteContent">
                                                <div *ngIf="productType == 'ULIP'"><span class="surrender-note-heading">Note:</span> <span class="surrender-note">Surrender Value for ULIPs is applicable as on the last Net Asset Value(NAV) date. Please check NAV history for details.</span></div>
                                                <div *ngIf="productType != 'ULIP'"><span class="surrender-note-heading">Note:</span> <span class="surrender-note">Surrender Value for Conventional products is applicable as on the date your Surrender request is processed.</span></div>
                                            </div>
                                            <!-- <div class="surrender-noteContent" *ngIf="productType == 'ULIP'">
                                                <div><span class="surrender-note-heading">Please Note:</span> <span class="surrender-note">For this policy, surrender quote shown above is considering surrender date as {{NavDate}}. Surrender value for ULIP policies are calculated based on the date on which all the required documents are submitted. If you submit all the required documents before 3PM today, you will get 'same day' NAV. If you submit the documents after 3PM today, you will get 'next working day' NAV as surrender pay-out. Thus, the surrender quote shown above might differ from your actual surrender pay-out.</span></div>
                                            </div> -->
                                            <div class="dnd-options" >
                                                    <div class="button-div flex">
                                                            <button matButton class="close-button update-pan-btn cancel-button-width" (click)="backBtn()">Cancel</button>
                                                            <button matButton class="save-button yes-button-width" [disabled]="surrenderBtn" (click)="surrenderAccept()">Surrender</button>
                                                        </div>
                                                    </div>
                                        </mat-card>
                                </mat-card>
                    </div>
        </mat-tab>
             <mat-tab label="acceptQuote" [disabled]="!isTabActive">
                            <ng-template mat-tab-label>
                                    <div class="acceptance-icon ">
                                        <img src='/assets/acceptance_of_quote_active.svg' class="tab-icon">
                                    </div>
                                    <div class="flex policy-label" [ngClass]="{'policy-label-active':isTabActive}"><span class="">Acceptance</span><span
                                            class="label-second-word">of Quote</span>
                                        <div class="bottom-border"></div>
                                    </div>
                                    <div class="quote-icon progress-bar">
                                            <img src='/assets/progress_bar_arrow.svg' class="progress-arrow">
                                        </div>
                                </ng-template>
                                <div class="policy-carousel" *ngIf = "surrenderFlag">
                                                <mat-card class="carousel-card policy-details-card">
                                                    <div class="card-top">
                                                       <div class="flex policy-info">
                                                            <div class="policy-icon">
                                                                <img src="assets/Investment icon.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Investment'">
                                                                <img src="assets/policy_icon4.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Health'">
                                                                <img src="assets/policy_icon3.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Pension'">
                                                                <img src="assets/policy_icon2.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children'">
                                                                <img src="assets/policy_icon1.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'">
                                                            </div>
                                                            <div class="policy-content">
                                                                <div class="details-top">
                                                                    <div [ngClass]="{'pink-policy': policyCardDtls.POLICYSTATUS==='Lapsed' }">
                                                                        <div class="policy-number">{{policyCardDtls.POLICYNO}} - {{policyCardDtls.POLICYSTATUS}}</div>
                                                                    </div>
                                                                    <div class="brand-name"><span>HDFC</span> Life</div>
                                                                    <div class="plan-name">{{policyCardDtls.PRDNAME}} - {{policyCardDtls.PRODUCT_TYPE}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="policy-amount-details">
                                                            <div class="flex details-split">
                                                                <div class="premium-date">
                                                                    <div class="date-img"
                                                                    *ngIf="((policyCardDtls.FREQUENCYOFPAYMENT !== 'Single') && (policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children' || policyCardDtls.PRODUCTPLAN==='Investment'|| policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'))
                                                                    || (policyCardDtls.PRODUCTPLAN==='Pension' || policyCardDtls.PRODUCTPLAN==='Health' || policyCardDtls.FREQUENCYOFPAYMENT === 'Single')">
                                                                        <img src="assets/calendar_blue.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text">Surrender Value</div>
                                                                        <div class="due-date">
                                                                                <span *ngIf="quoteAmount !== 'NA'"><span class="premium-rupee-sign">&#8377;</span>
                                                                                <span>{{quoteAmount | number:'1.0-0':'en-IN'}}</span></span><span *ngIf="quoteAmount == 'NA'">NA</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="line"></div>
                                                                <div class="premium-amount">
                                                                    <div class="premium-img">
                                                                        <img src="assets/calender.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text" *ngIf="productType != 'ULIP'">Quote generated date</div>
                                                                        <div class="label-text" *ngIf="productType == 'ULIP'">Quote valid till </div>
                                                                        <div class="flex amount-total" *ngIf="productType != 'ULIP'">
                                                                            <span *ngIf="quoteGeneratedDate != 'NA'">{{quoteGeneratedDate }}</span>
                                                                            <span *ngIf="quoteGeneratedDate == 'NA'">NA</span>
                                                                        </div>
                                                                        <div class="flex amount-total" *ngIf="productType == 'ULIP'">
                                                                            <span *ngIf="NavDate != 'NA'">{{ NavDate }}</span>
                                                                            <span *ngIf="NavDate == 'NA'">NA</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                <mat-card class="dnd-options-page" >
                        <div class="dnd-options" >
                            <div class="dnd-options-header">
                                Stay Invested !
                            </div>
                            <div class="inner-card">
                                    <ul>
                                        <li>Insurance plans help you to secure the financial future of your loved ones.</li>
                                        <li>Surrendering your policy will result in forfeiting the maturity benefits or other policy benefits applicable to your plan.</li>
                                        <li>Insurance is always beneficial for the long term and is the only instrument that offers you risk cover based on your plan specifications.</li>
                                        <li>Premiums paid towards insurance policies are eligible for tax benefits subject to the provisions under the Income-tax Act, 1961. Tax laws are subject to change.</li>
                                        <li>Buying a new policy with the same Sum Assured may require you to pay a higher premium.</li>
                                        <li>In case of Pension product, I understand that my surrender proceed is taxable in accordance with the provisions of the Income-tax Act, 1961.</li>
                                        <li>All Unit Linked Life Insurance Policies (other than pension and annuity policies) issued on or after Feb 1, 2021 with an aggregate annual premium exceeding INR. 2.50 Lakhs for any of the years during the policy term, shall NOT be eligible for exemption under section 10(10D) of the Income Tax Act, 1961 (except on death).</li>
                                        <li>All TRADITIONAL PLANS (i.e. all non-Unit Linked plans other than pension and annuity plans) issued on or after April 1, 2023 with an aggregate annual premium exceeding INR. 5.00 lakhs for any of the years during the policy term, shall NOT be eligible for exemption under section 10(10D) of the Income Tax Act, 1961 (except on death).</li>
                                        <li>Surrender value mentioned is approximate value and may differ as on surrender request received date.</li>
                                        <li>If there is any outstanding loan against the policy, the surrender value computation shall be the amount after deducting the outstanding loan and interest. As on date of Surrender, outstanding loan value plus interest will be calculated and deducted from the surrender value. Here no fresh consent will be called for any difference in actual amount payable due to loan adjustment. - Is this point covered in Surrender value computation for loan cases. If surrender link can't be generated to loan cases then we can omit this point.</li>
                                    </ul>
                                <div>
                                    <div class="inner-card-text-heading">Contact your nearest HDFC Life branch for more information !</div>
                                    <div class="inner-card-content-one">They will help on addressing your immediate needs and advice on other suitable options.</div>
                                </div>
                                <div class="inner-card-content-two">
                                        <div>If you wish to reconsider your decision to surrender your policy please click Cancel Request. If you still wish to continue with surrender please click Proceed.</div>
                                </div>
                                <div class="button-div flex">
                                        <button matButton class="close-button update-pan-btn cancel-button-width" (click)="cancelRequest()">Back</button>
                                        <button matButton class="save-button yes-button-width" (click)="proceedSurrender(surrenderPopupMsg)">Proceed</button>
                                        <button matButton class="close-button update-pan-btn cancel-button-width" (click)="investedBtn(surrenderPopupMsg)">Stay Invested</button>
                                    </div>
                            </div>
                        </div>
                    </mat-card>
        </mat-card>
        </div>
        </mat-tab>
             <mat-tab label="surrenderSR" [disabled]="!isTabActive">
                            <ng-template mat-tab-label>
                                    <div class="surrender-icon ">
                                        <img src='/assets/surrender_sr_creation_active.svg' class="tab-icon">
                                    </div>
                                    <div class="flex policy-label" [ngClass]="{'policy-label-active':isTabActive}"><span>Surrender</span><span
                                            class="label-second-word ">SR Creation</span>
                                        <div class="bottom-border"></div>
                                    </div>
                                    <div class="quote-icon progress-bar">
                                            <img src='/assets/progress_bar_arrow.svg' class="progress-arrow">
                                        </div>
                                </ng-template>
                                <div class="policy-carousel" *ngIf = "surrenderFlag">
                                                <mat-card class="carousel-card policy-details-card" >
                                                    <div class="card-top" *ngIf="!payout && payeeDtls">
                                                       <div class="flex policy-info">
                                                            <div class="policy-icon">
                                                                <img src="assets/Investment icon.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Investment'">
                                                                <img src="assets/policy_icon4.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Health'">
                                                                <img src="assets/policy_icon3.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Pension'">
                                                                <img src="assets/policy_icon2.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children'">
                                                                <img src="assets/policy_icon1.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'">
                                                            </div>
                                                            <div class="policy-content">
                                                                <div class="details-top">
                                                                    <div [ngClass]="{'pink-policy': policyCardDtls.POLICYSTATUS==='Lapsed' }">
                                                                        <div class="policy-number">{{policyCardDtls.POLICYNO}} - {{policyCardDtls.POLICYSTATUS}}</div>
                                                                    </div>
                                                                    <div class="brand-name"><span>HDFC</span> Life</div>
                                                                    <div class="plan-name">{{policyCardDtls.PRDNAME}} - {{policyCardDtls.PRODUCT_TYPE}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="policy-amount-details">
                                                            <div class="flex details-split">
                                                                <div class="premium-date">
                                                                    <div class="date-img">
                                                                        <img src="assets/calendar_blue.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text">Surrender Value</div>
                                                                        <div class="due-date">
                                                                                <span *ngIf="quoteAmount !== 'NA'"><span class="premium-rupee-sign">&#8377;</span>
                                                                                <span>{{quoteAmount | number:'1.0-0':'en-IN'}}</span></span><span *ngIf="quoteAmount == 'NA'">NA</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="line"></div>
                                                                <div class="premium-amount">
                                                                    <div class="premium-img">
                                                                        <img src="assets/calender.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text" *ngIf="productType != 'ULIP'">Quote generated date</div>
                                                                        <div class="label-text" *ngIf="productType == 'ULIP'">Quote valid till </div>
                                                                        <div class="flex amount-total" *ngIf="productType != 'ULIP'">
                                                                            <span *ngIf="quoteGeneratedDate != 'NA'">{{quoteGeneratedDate }}</span>
                                                                            <span *ngIf="quoteGeneratedDate == 'NA'">NA</span>
                                                                        </div>
                                                                        <div class="flex amount-total" *ngIf="productType == 'ULIP'">
                                                                            <span *ngIf="NavDate != 'NA'">{{ NavDate }}</span>
                                                                            <span *ngIf="NavDate == 'NA'">NA</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  class="policy-maturity">
                                                        <div class="card-bottom">
                                                            <div class="alert" >
                                                                <div class="alert-message">Please confirm account type and payment mode for Surrender payout processing</div>
                                                            </div>
                                                        </div>
                                                    </div>
                <mat-card  class="payee-details-card" >
                            <div class="payee-details" *ngIf="!payout && payeeDtls">
                                <div class="payee-details-header">
                                    Payee Details
                                </div>
                                <form [formGroup]="maturityClaim">
                                    <div class="payee-details-content flex">
                                        <div class="client-icon no-mobile">
                                                        <img src='/assets/Icon style 1.svg'>
                                                    </div>
                                        <div class="payee-details-field">
                                            <div class="flex payee-details-title"><span>
                                                Client Id </span>
                                            </div>
                                            <div class="payee-details-text">
                                                {{surrClientId}}
                                            </div>
                                        </div>
                                        <div class="client-icon no-mobile">
                                            <img src='/assets/name.svg'>
                                        </div>
                                        <div class="payee-details-field">
                                            <div class="payee-details-title">
                                                Name
                                            </div>
                                            <div class="payee-details-text">
                                                {{clientName}}
                                            </div>
                                        </div>
                                        <div class="client-icon no-mobile">
                                            <img src='/assets/role.svg'>
                                        </div>
                                        <div class="payee-details-field">
                                            <div class="payee-details-title">
                                                Role
                                            </div>
                                            <div class="payee-details-text">
                                                {{role}}
                                            </div>
                                        </div>
                                        <div class="client-icon no-mobile">
                                            <img src='/assets/allocation.svg'>
                                        </div>
                                        <div class="payee-details-field">
                                            <div class="payee-details-title">
                                                Allocation in %
                                            </div>
                                            <div class="payee-details-text">
                                                {{allocatePercent}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="payee-account-content flex">
                                            <div class="payee-account-field">
                                                    <div class="payee-details-title">
                                                        Payee Account of {{ clientName }}
                                                    </div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field select-field">
                                                            <mat-select value="Select" class="payee-account-option"
                                                                formControlName="requestType" disableRipple disableOptionCentering (selectionChange)="selectPayment($event)">
                                                                <mat-option *ngFor="let item of payModeArray" [value]="item">{{item.label}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="payee-account-field" *ngIf="maturityClaim.controls.requestType.value.label">
                                                    <div class="payee-details-title">
                                                        Payment Mode
                                                    </div>
                                                    <div class="payee-details-text">
                                                        {{payementMode}}
                                                    </div>
                                                </div>
                                                <div class="payee-account-field"
                                                    *ngIf="!savingsFlag">
                                                    <div class="payee-details-title">
                                                        Total Premium Paid through NRE A/c.
                                                    </div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field text-field">
                                                                <input type="tel" matInput formControlName="totalPremium" maxlength="9" decimalNumbersOnly value="ExistingPremium.value" readonly> 
                                                                <mat-error class="error-message"
                                                                *ngIf="maturityClaim.get('totalPremium').errors?.errorMsg">
                                                                {{maturityClaim.get('totalPremium').errors?.errorMsg}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="payee-account-field"
                                                    *ngIf="!savingsFlag">
                                                    <div class="payee-details-title">
                                                        Total Premium Paid
                                                    </div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field text-field">
                                                            <input type="tel" matInput formControlName="ExistingPremium" maxlength="9"
                                                                readonly>
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="payee-account-field" *ngIf="pensionFlag">
                                                    <div class="payee-details-title">
                                                        Surrender Payout (for Pension Plans)
                                                    </div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field select-field">
                                                            <mat-select value="Select" class="payee-account-option"
                                                                formControlName="payoutValue" disableRipple disableOptionCentering (selectionChange)="selectSurrender()">
                                                                <mat-option *ngFor="let item of surrenderPayout" [value]="item.value">{{item.label}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="payee-account-field" *ngIf="pensionFlag">
                                                    <div class="payee-details-title">
                                                        Surrender Payout (in % for Pension Plans)
                                                    </div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field text-field">
                                                            <input type="tel" matInput formControlName="surrenderPayoutPercent" [readonly]="disableSurrender">
                                                                <mat-error class="error-message" *ngIf="maturityClaim.get('surrenderPayoutPercent').errors?.errorMsg">{{maturityClaim.get('surrenderPayoutPercent').errors?.errorMsg}}</mat-error>    
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="payee-account-field payee-reason">
                                                    <div class="payee-details-title">Reason for Surrender</div>
                                                    <div class="payee-details-text">
                                                        <mat-form-field floatLabel="never" class="payee-details-form-field select-field">
                                                            <mat-select #select multiple class="payee-account-option"
                                                                formControlName="surReason" disableRipple disableOptionCentering panelClass="payee-reason">
                                                                        <mat-checkbox color="primary"[(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="IsReasonChecked()"  class="select-checkbox">Select All</mat-checkbox>
                                                                        <mat-option *ngFor="let item of surReasonArray" [value]="item.code" (click)="optionClick()">{{item.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                    </div>
                                <div class="payee-details-noteContent flex">
                                        <div class="payee-details-note">
                                            Declaration :    
                                             <div class="payee-details-checkbox-outer flex">
                                                    <mat-checkbox color="primary" (change)="IsChecked($event)" class="payee-checkbox" [disabled]="!maturityClaim.valid">
                                                    </mat-checkbox>
                                                    <div class="confirm-msg">I/We hereby surrender the policy at the surrender value provided above, to HDFC Life Insurance Company (hereinafter "Company") in full and final settlement of all my/our claims and demands under the given policy and the policy contract is hereby treated as cancelled with immediate effect. 
                                                        I/We understand that surrender of the policy results in termination of the policy and that I/We are required to return the original policy document to the company. I/We hereby agree to indemnify the Company against all liabilities that the Company may incur on account of any claim being made by any other person, however, once the policy is surrendered as stated above. 
                                                        I/We understand that policy payouts will be subject to TDS (Tax Deduction at Source) as applicable as per the Income-tax Act 1961. I/We also understand that if TDS is applicable and a valid PAN is not submitted as required by law, tax will be deducted at source from the policy payout at higher rate and the tax so deducted and paid to the Government will not be refunded to the payee company. 
                                                        I/We confirm that I/We have read the terms and conditions and I/We accept the same.</div>
                        
                                                </div>
                                        </div>
                                    </div>
                                    <div class="payee-details-noteContent flex">
                                            <div class="payee-details-note">
                                                Disclaimers : 
                                                <div class="payee-details-checkbox-outer flex" *ngIf="productType == 'ULIP'">
                                                        <mat-checkbox color="primary" (change)="IsDisclaimerChecked($event)" class="payee-checkbox" [disabled]="!maturityClaim.valid">
                                                        </mat-checkbox>
                                                        <div class="confirm-msg">
                                                                As per the IRDAI guidelines, the cut-off timings for NAV application and redemption of units stands revised to 3 PM IST w.e.f June 1, 2007, hence the following is implied (subject to changes as and when amended by IRDAI):
                                                                a) Application for surrender along with all required documents received up to 3 PM IST on a working weekday (Mon-Fri) by HDFC Life, the same day's unit value will be applicable. In case a prior unit allocation is pending, your current request will be processed on the successive working day. b) If the Application for surrender along with all required documents is received after 3 PM IST on a working weekday by HDFC Life, then the next working day's unit value will be applicable (when the applicable day is not a valuation day, NAV of the next immediate valuation day would be considered.)
                                                        </div>
                                                        </div>
                                                <ul>
                                                    <li *ngIf="productType != 'ULIP'">As per the IRDAI guidelines, the cut-off timings for NAV application and redemption of units stands revised to 3 PM IST w.e.f June 1, 2007, hence the following is implied (subject to changes as and when amended by IRDAI):
                                                        a) Application for surrender along with all required documents received up to 3 PM IST on a working weekday (Mon-Fri) by HDFC Life, the same day's unit value will be applicable. In case a prior unit allocation is pending, your current request will be processed on the successive working day. b) If the Application for surrender along with all required documents is received after 3 PM IST on a working weekday by HDFC Life, then the next working day's unit value will be applicable (when the applicable day is not a valuation day, NAV of the next immediate valuation day would be considered.)</li>
                                                    <li>HDFC Life will reject charge back request if any premium is paid by Credit Card / Debit Card once the surrender value is paid to the customer.</li>
                                                </ul>
                                            </div>
                                        </div>

                                    <div class="flex edit-payments-btn ">
                                            <button *ngIf="!isCustomLink" class="cancel-btn" (click)="srBackBtn()">Back</button>
                                        <button *ngIf="productType != 'ULIP'" class="delete-btn continue-btn"  (click)="goToPayee(payee,'surrender')" [disabled]="!ButtonVisible">Continue</button>
                                        <button *ngIf="productType == 'ULIP'" class="delete-btn continue-btn"  (click)="goToPayee(payee,'surrender')" [disabled]="!ButtonVisible || !disclaimerChecked">Continue</button>
                                    </div>
                                </form>
                            </div>
                    </mat-card>
                    </mat-card>
                    </div>
                    </mat-tab>
            <div  state="last" label="Update payee details">
                    <mat-tab label="updPayeeDtls" [disabled]="!isTabActive">
                            <ng-template mat-tab-label>
                                    <div class="payee-icon ">
                                        <img src='/assets/update_payee_details_active.svg' class="tab-icon"> 
                                    </div>
                                    <div class="flex policy-label" [ngClass]="{'policy-label-active':isTabActive}"><span>Update</span><span
                                            class="label-second-word ">Payee Details</span>
                                        <div class="bottom-border"></div>
                                    </div>
                                    <div class="quote-icon progress-bar">
                                            <img src='/assets/progress_bar_arrow.svg' class="progress-arrow">
                                        </div>
                                </ng-template>
                                <div class="policy-carousel" *ngIf="payoutView">
                                                <mat-card class="carousel-card policy-details-card" >
                                                    <div class="card-top">
                                                       <div class="flex policy-info">
                                                            <div class="policy-icon">
                                                                <img src="assets/Investment icon.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Investment'">
                                                                <img src="assets/policy_icon4.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Health'">
                                                                <img src="assets/policy_icon3.svg" *ngIf="policyCardDtls.PRODUCTPLAN==='Pension'">
                                                                <img src="assets/policy_icon2.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children'">
                                                                <img src="assets/policy_icon1.svg"
                                                                    *ngIf="policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'">
                                                            </div>
                                                            <div class="policy-content">
                                                                <div class="details-top">
                                                                    <div [ngClass]="{'pink-policy': policyCardDtls.POLICYSTATUS==='Lapsed' }">
                                                                        <div class="policy-number">{{policyCardDtls.POLICYNO}} - {{policyCardDtls.POLICYSTATUS}}</div>
                                                                    </div>
                                                                    <div class="brand-name"><span>HDFC</span> Life</div>
                                                                    <div class="plan-name">{{policyCardDtls.PRDNAME}} - {{policyCardDtls.PRODUCT_TYPE}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="policy-amount-details">
                                                            <div class="flex details-split">
                                                                <div class="premium-date">
                                                                    <div class="date-img"
                                                                    *ngIf="((policyCardDtls.FREQUENCYOFPAYMENT !== 'Single') && (policyCardDtls.PRODUCTPLAN==='Savings' || policyCardDtls.PRODUCTPLAN==='Children' || policyCardDtls.PRODUCTPLAN==='Investment'|| policyCardDtls.PRODUCTPLAN==='Protection' || policyCardDtls.PRODUCTPLAN==='Combi'))
                                                                    || (policyCardDtls.PRODUCTPLAN==='Pension' || policyCardDtls.PRODUCTPLAN==='Health' || policyCardDtls.FREQUENCYOFPAYMENT === 'Single')">
                                                                        <img src="assets/calendar_blue.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text">Surrender Value</div>
                                                                        <div class="due-date">
                                                                                <span *ngIf="quoteAmount !== 'NA'"><span class="premium-rupee-sign">&#8377;</span>
                                                                                <span>{{quoteAmount | number:'1.0-0':'en-IN'}}</span></span><span *ngIf="quoteAmount == 'NA'">NA</span></div>
                                                                    </div>
                                                                </div>
                                                                <div class="line"></div>
                                                                <div class="premium-amount">
                                                                    <div class="premium-img">
                                                                        <img src="assets/calender.svg">
                                                                    </div>
                                                                    <div class="policy-metadata">
                                                                        <div class="label-text">Quote generated date</div>
                                                                        <div class="flex amount-total">
                                                                                <span *ngIf="quoteGeneratedDate != 'NA'">{{quoteGeneratedDate }}</span>
                                                                                <span *ngIf="quoteGeneratedDate == 'NA'">NA</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div  class="policy-maturity" *ngIf="role == 'Beneficiary'">
                                                        <div class="card-bottom">
                                                            <div class="alert" >
                                                                <div class="alert-message"><b>Please Note:</b> For this policy, payout will be processed for beneficiary only. Kindly provide KYC, PAN and NEFT details of beneficiary to successfully process the request.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                    <mat-card class="payee-details-card" >
                            <app-payout [uploadDocFlag] = "uploadFlag"></app-payout>
                        </mat-card>
                        </mat-card>
                        </div>
                        </mat-tab>
                </div>
               
                <mat-tab label="docUpload" [disabled]="!isTabActive">
                    <div  class="upload-content">
                    <ng-template mat-tab-label>
                            <div class="payee-icon ">
                                <img src='/assets/documet_upload_active.svg' class="tab-icon"> 
                            </div>
                            <div class="flex policy-label" [ngClass]="{'policy-label-active':isTabActive}"><span class="">Document</span><span
                                    class="label-second-word ">Upload</span>
                                <div class="bottom-border"></div>
                            </div>
                        </ng-template>
                    </div>
                        </mat-tab>
                    </mat-tab-group>
                    </div>
</div>

<ng-template #payee>
        <div class="maturity-claim-details">
            <div class="maturity-claim-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Surrender Process</div>
            <div class="pan-card popup-content-height">
                <!-- <div class="maturity-claim-popup-content">Service Request for Surrender (Sr No. <b>{{parentSr}}</b>) for your HDFC Life policy {{policyNo}} has been created. Now, we require you to update KYC, PAN and NEFT/Bank details and upload necessary documents to complete the process and submit this Surrender request.</div> -->
                <div class="maturity-claim-popup-content">Your request for surrender will not be completed until your KYC, PAN, NEFT/bank details are updated and necessary documents uploaded. Please update all the mentioned details in the next step to complete the surrender request and initiate the payout process.</div>
                    <div class="payee-details-note">
                            <b>Please Note : </b>   
                            <ul>
                                <li>For ULIP policies, Submit the Service Request successfully along with the documents mentioned above to freeze your NAV for payout. 
                                    If the Service request is submitted before 3 pm IST 'SAME DAY NAV' will be applicable and if it is submitted after 3 pm IST 'NEXT WORKING DAY NAV' will be applicable.</li>
                            </ul>
                        </div>
                <div class="button-div flex">
                    <button matButton class="okay-button" (click)="continuePopup()">Continue</button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #policyBasedPopup>
        <div class="maturity-claim-details">
                <div mat-dialog-actions class="mat-dialog-actions" (click)="closePopup()">
                        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                            Close
                            <img src="assets/close.svg">
                        </div>
                    </div>
            <div class="maturity-claim-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Important</div>
            <div class="pan-card popup-content-height">
                <div class="maturity-claim-popup-content"[innerHTML]="annuityMessage"></div>
                <div class="button-div flex">
                    <button matButton class="close-button update-pan-btn cancel-button-width" (click)="closePopup()">No</button>
                    <button matButton class="save-button yes-button-width" (click)="yesBtn()">Yes</button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #surrenderPopupMsg>
        <div class="maturity-claim-details">
            <div class="maturity-claim-popup-header" *ngIf="proceed" [ngClass]="{'hni-popups-header':isHNIUSer}">Surrender Process</div>
            <div class="maturity-claim-popup-header" *ngIf="!proceed" [ngClass]="{'hni-popups-header':isHNIUSer}">Important !</div>
            <div class="pan-card popup-content-height">
                <div class="maturity-claim-popup-content" *ngIf="proceed">We are sorry to hear that you wish to Surrender your policy. To proceed with the Surrender process, you will need a soft copy of the following documents: </div>
                <div class="maturity-claim-popup-content" *ngIf="!proceed">Dear Customer, you have not initiated the surrender request for your policy. As a result, you will still be entitled for your maturity and other insurance advantages and your policy status will remain the same.</div>
                    <div class="payee-details-note" *ngIf="proceed">
                            <ul>
                                <li>KYC - Passport / Aadhaar / Voter ID Card / Driving License</li>
                                <li>PAN - PAN card / Form 60</li>
                                <li>NEFT - Bank statement / Cancelled cheque</li>
                                <li>NRE Bank proof - In case of NRE payout cases </li>
                            </ul>
                        </div>
                        <!-- <div class="maturity-claim-popup-content">Please keep the above details handy with you.</div> -->
                <div class="button-div flex">
                    <button matButton *ngIf="proceed" class="okay-button" (click)="surrenderPopup()">Continue</button>
                    <button matButton *ngIf="!proceed" class="okay-button" (click)="backBtn()">close</button>
                </div>
            </div>
        </div>
    </ng-template>
