<div [ng2-loading]="showLoader" [config]="loaderConfig" class="app-loader">
    <ng-container *ngIf="!isVirtualBranch && !(router.url.includes('/my-vb-test')) && !(router.url.includes('/myFrame'))">
        <app-header></app-header>
    </ng-container>
    <div class="my-account-bg" [ngClass]="{'login-bg': (router.url == '/login' || router.url.includes('/my-vb-test') || (router.url =='/investigator')  ||  (router.url =='/surrender-aversion') || (router.url.includes('/my-link') && !router.url.includes('/my-link/update-bank-details') && !router.url.includes('/my-link/wb-voucher') && !router.url.includes('/my-link/surrender'))), 'hni-bg': isHNIUSer, 'my-account-bg-full': isVirtualBranch, 'surrender-aversion': (router.url.includes('/surrender-aversion/')), 'custom-link-bg': (router.url.includes('/my-link/') &&  !router.url.includes('/my-link/wb-voucher') &&  !router.url.includes('/my-link/surrender') && !router.url.includes('/my-link/update-bank-details')) ,'my-login': (router.url == '/my-login')}">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>
<!-- <div [ng2-loading]="showLoader" [config]="loaderConfig" class="app-loader">
        <ng-container><app-policy-based-header></app-policy-based-header></ng-container>
        <div class="surrender-bg">
            <router-outlet></router-outlet>
        </div>
        <ng-container class="surrender-bg">
        <app-footer></app-footer></ng-container>
</div> -->