import { Component, OnInit } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import { Router } from '@angular/router';
import { serviceURL } from 'src/environments/environment';
import { Utils } from 'src/app/utils/common-functions';

@Component({
  selector: 'app-sidebanner',
  templateUrl: './sidebanner.component.html',
  styleUrls: ['./sidebanner.component.css']
})
export class SidebannerComponent implements OnInit {
  activeTabIndex = 0;
  tabChangeInterval;

   constructor(public router: Router, public utils: Utils) { }


  ngOnInit(): void {
    this.tabChangeInterval = setInterval(() => {
      if (this.activeTabIndex == 0) {
        this.activeTabIndex = 1;
      } else if (this.activeTabIndex == 1) {
        this.activeTabIndex = 0;
      }
    }, 5000);
  }

  onBtnClick() {
    this.utils.trackUserActions({ 'eventName' : 'Know more' }); 
    window.open(serviceURL.sidebannerLink, "_blank");
  }
  downloadUserManual() {    
    let downloadLink = document.createElement("a");
    downloadLink.download = "MyAccount_UserManual";
    downloadLink.href = "assets/pdf/MyAccount_UserManual.pdf";
    downloadLink.click();    
    this.utils.trackUserActions({ 'eventName' : 'Download now' }); 
  }
  ngOnDestroy() {
    if (this.tabChangeInterval) {
      clearInterval(this.tabChangeInterval);
      this.tabChangeInterval = null;
    }
  }

}
