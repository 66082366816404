<div class="doc-list-popup" [ngClass]="{'virtual-branch': dataService.isVirtualBranch}">
    <div *ngIf="!data.disableClose && !data.hideClose" mat-dialog-actions>
        <div mat-dialog-close *ngIf="!finishFlag" class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}" (click)="close()">
            <div class="no-mobile">
                Close<img src="assets/close.svg" class="close-icon">
            </div>
            <img src="assets/cross_circle_blue.svg" class="mobile">
        </div>
    </div>
    <div *ngIf="data.logoutOnClose && showRequirementList" mat-dialog-actions>
        <div class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}" (click)="customLinkLogout()">
            <div class="no-mobile">Close<img src="assets/close.svg" class="close-icon"></div>
            <img src="assets/cross_circle_blue.svg" class="mobile">
        </div>
    </div>
    <div class="doc-list-popup-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Upload Document</div>
    <div class="doc-list-popup-content popup-content-height">
        <div *ngIf="showRequirementList" class="requirements-wrapper">
            <div *ngFor="let item of requirementDtlsList;let idx=index" class="requirement-item">
                <div class="sub-issue" *ngIf="(this.data.source == 'agentDocUpload') && (idx==0 || requirementDtlsList[idx].subissue != requirementDtlsList[idx-1].subissue) && (item.subissue)">{{item.subissue}}</div>
                <div class="requirement-item flex">
                <div>
                    <div class="proof-type">{{ item.requirementdesc }}
                            <div *ngIf="item.subissue == 'Revival'" class="proof-type">{{item.clientname}}</div>
                    </div>
                    <div *ngIf="item.subissue == 'Top Up' && item.reqcd == incomeProofDocCode" class="valid-income-proof">Please <a [href]="incomeProofDocument" target="_blank" rel="noopener noreferrer">click here</a> to check list of valid income proof.</div>
                </div>
                <button *ngIf="(!(isSubmitSuccess[idx]) && (item.reqcd == 'CSPS_REQ_003'))" mat-button class="submit-button upload-button" (click)="onUploadClick(idx)">Fill form</button>
                <button *ngIf="(!(isSubmitSuccess[idx]) && (item.reqcd == 'CSPS_REQ_505'|| item.reqcd == 'CSPS_REQ_332'|| item.reqcd == 'CSPS_REQ_366' || item.reqcd == 'CSPS_REQ_345'))" mat-button class="submit-button upload-button" (click)="onUploadClick(idx)">Digital Form</button>
                <button *ngIf="(!(isSubmitSuccess[idx]) && !isClickToUpload && !(item.reqcd == 'CSPS_REQ_505'|| item.reqcd == 'CSPS_REQ_332'|| item.reqcd == 'CSPS_REQ_366' || item.reqcd == 'CSPS_REQ_345' || item.reqcd == 'CSPS_REQ_003'))" mat-button class="submit-button upload-button" (click)="onUploadClick(idx)">Upload</button>
                <button *ngIf="(!(isSubmitSuccess[idx]) && isClickToUpload && !(item.reqcd == 'CSPS_REQ_003' || item.reqcd == 'CSPS_REQ_505'|| item.reqcd == 'CSPS_REQ_332'|| item.reqcd == 'CSPS_REQ_366' || item.reqcd == 'CSPS_REQ_345'))" mat-button class="submit-button upload-button" (click)="onUploadClick(idx)">Upload</button>
                <div class="green-tick-div flex" *ngIf="isSubmitSuccess[idx]">
                    <img src="assets/green-tick.svg">
                </div>
            </div>
            </div> 
            <div *ngIf="requirementDtlsList.length == 0" class="proof-type">{{requiremntMsg}}</div>      
            <div *ngIf="requirementDtlsList.length == 0 && isInstaServ" class="proof-type padding-top">Please click Submit SR button to complete the process</div>                                                  
            <div *ngIf="showRequirementList && showPaymentBtn && isPaymentPending" class="requirement-item payment-item flex">                
                <button mat-button class="submit-button upload-button payment-btn" (click)="makeTopUpPayment()">Proceed to Pay</button>                
            </div>       
            <div *ngIf="showRequirementList && finishFlag && !data.disableSubmitSR" class="requirement-item payment-item flex">                
                <button mat-button class="submit-button upload-button payment-btn" (click)="finishBtn()">Finish</button>                
            </div>                
        </div>        
        <div *ngIf="!showRequirementList" class="overflow-container">
            <div class="top-section">
                <div class="proof-type">{{requirementDtls.requirementdesc}}</div>
                <div class="doc-type-select-wrapper">
                    <div class="form-label">Document Type<span class="mandatory">*</span></div>
                    <mat-form-field floatLabel="never" class="doc-list-form-field select-field"
                        [ngClass]="{'error-field': docTypeFocusOut && docTypeDropdown.hasError('required')}">
                        <mat-placeholder>Please select a document</mat-placeholder>
                        <mat-select disableRipple disableOptionCentering class="doc-type-select" panelClass="doc-type-select-panel"
                            [formControl]="docTypeDropdown" (focusout)="OnDocTypeFocusOut()">
                            <mat-option *ngFor="let item of docList" [value]="item[0]" (click)="onDocSelect(item[0])">
                                {{item[1]}}</mat-option>
                        </mat-select>
                        <img mat-suffix src="assets/angle_down.svg">
                        <mat-error *ngIf="docTypeFocusOut && docTypeDropdown.hasError('required')"
                            class="error-message">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="docTypeDropdown.value == inLieuPANDocCode" class="pan-card-note">In absence of PAN card, 20% TDS will be charged on the payout amount</div>
                <div *ngIf="docTypeDropdown.value == nrebankStatementCode" class="pan-card-note-small">Note: Please make sure the NRE bank statement you submit covers all of the premiums you paid with your NRE account. Otherwise, further requirement will be generated for NRE proof.</div>                        
            </div>
            <div *ngIf="isInstaServ && docTypeDropdown.value == aadhaarCardDocCode && !showInstaServConsent && consentMode && consentMode!='DeathClaim'" class="consent-error">Aadhar consent received already in {{consentMode}} mode.</div>
            <div class="instaServeAadhaar" *ngIf="docTypeDropdown.value == aadhaarCardDocCode && showInstaServConsent">
                <div class="consent-required">Aadhar Consent is required</div>
                <div class="consent-type-group flex">
                    <div class="form-label">Select a mode<span class="mandatory">*</span>:</div>
                    <mat-form-field class="consent-type doc-list-form-field select-field">
                        <mat-select  disableRipple disableOptionCentering class="doc-type-select" panelClass="doc-type-select-panel"
                            [formControl]="instaServDropdown" (selectionChange)="refresh('changeMode')" [disabled]="isPhysicalEnabled || consentAccepted">
                            <mat-option value="Digital consent">Digital consent</mat-option>
                            <mat-option value="Physical consent">Physical consent</mat-option>
                        </mat-select>
                        <img mat-suffix src="assets/angle_down.svg">
                        <mat-error *ngIf="instaServDropdown.hasError('required')"
                            class="error-message">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                </div>  
                <button class="submit-button send-link" *ngIf="instaServDropdown.value == 'Digital consent' && !showDigitalInfo" (click)="sendCustomlink()">Send Custom Link</button>
                <div *ngIf="showDigitalInfo && !exhausted && !isPhysicalEnabled && !consentAccepted" class="consent-note">The link for aadhaar consent been shared to <span class="consent-note-color">{{ maskedDetails[0] }}</span> and <span class="consent-note-color">{{ maskedDetails[1] }}</span>, To confirm the reception of the digital consent response, wait for <span class="consent-note-color">one minute</span>, then click the <span class="consent-note-color">Submit</span> button. </div>
                <div *ngIf="exhausted && !consentAccepted" class="consent-error">{{ consentErrorMsg }}</div>
                <div *ngIf="consentAccepted" class="consent-error">Aadhar consent received from link!!</div>
                <div *ngIf="showDigitalInfo && !consentAccepted" class="button-group">
                    <button *ngIf="!exhausted && !isPhysicalEnabled" class="submit-button resend-link" [disabled]="timerSec != 0" (click)="resendLink()">Re-send link</button>
                    <!-- <button class="cancel-button refresh" [ngClass]="{'resend-link' : exhausted || isPhysicalEnabled}" [disabled]="disableRefresh" (click)="refresh('refreshAction')">Refresh</button> -->
                    <div *ngIf="!exhausted" class="timer">Resend button enabled after : <span class="timer-count">{{ hours|number:'2.0-0'}}:{{ minutes | number:'2.0-0'}}</span></div>
                </div>
            </div>
            <div class="separator-line" *ngIf="docTypeDropdown.valid && showForm"></div>
            <form (ngSubmit)="onSubmit()" autocomplete="off">
                <div class="form-section" *ngIf="docTypeDropdown.valid && showForm" [formGroup]="docListPopupForm">
                    <div class="flex form-fields-flex">
                    <div *ngIf="nsdlValidation" class="attribute-field-wrapper">
                            <div class="form-label">PAN No.<span class="mandatory">*</span></div>
                            <mat-form-field floatLabel="never" class="doc-list-form-field text-field">
                                <mat-placeholder>Please enter PAN No.</mat-placeholder>
                                <input type="text" maxlength="10" matInput formControlName="panNo">
                                <mat-error class="error-message" *ngIf="docListPopupForm.controls.panNo.errors?.errorMsg">
                                    {{docListPopupForm.controls.panNo.errors?.errorMsg}}
                                </mat-error>                                
                            </mat-form-field>
                        </div>
                        <div *ngIf="nsdlValidation && (!data.DOB || data.DOB == 'NA')" class="attribute-field-wrapper">
                            <div class="form-label">DOB as per PAN<span class="mandatory">*</span><img src="assets/info_active.svg" class="tooltip-popover-pan-dob info-img"></div>
                            <mat-form-field floatLabel="never" class="doc-list-form-field text-field">
                                <mat-placeholder>dd/mm/yyyy</mat-placeholder>
                                <input matInput [matDatepicker]="pickerPAN" (focus)="pickerPAN.open()" formControlName="panDOB">
                                <mat-datepicker-toggle matSuffix [for]="pickerPAN">
                                    <img src="assets/datepicker.svg" matDatepickerToggleIcon class="picker-img">
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerPAN></mat-datepicker>
                                <mat-error class="error-message" *ngIf="docListPopupForm.controls.panDOB.errors?.errorMsg">
                                    {{docListPopupForm.controls.panDOB.errors?.errorMsg}}
                                </mat-error>                                
                            </mat-form-field>
                        </div>
                        <div *ngIf="nsdlValidation" class="attribute-field-wrapper">
                            <div class="form-label">Name as per PAN card<span class="mandatory">*</span></div>
                            <mat-form-field floatLabel="never" class="doc-list-form-field text-field">
                                <mat-placeholder>Please enter name as per PAN</mat-placeholder>
                                <input type="text" maxlength="85" matInput formControlName="panName">
                                <mat-error class="error-message" *ngIf="docListPopupForm.controls.panName.errors?.errorMsg">
                                    {{docListPopupForm.controls.panName.errors?.errorMsg}}
                                </mat-error>
                                <mat-error class="error-message" *ngIf="docListPopupForm.controls.panName.errors?.required">
                                    {{docListPopupForm.controls.panName.errors?.required}}
                                </mat-error>                                
                            </mat-form-field>
                        </div>
                        <div class="attribute-field-wrapper" *ngFor="let item of attributeList">
                            <div class="form-label">{{item.label}}<span class="mandatory" *ngIf="!(item.isOptional)">*</span></div>
                            <mat-form-field *ngIf="item.type === 'TEXT'" floatLabel="never"
                                class="doc-list-form-field text-field">
                                <mat-placeholder>Please enter {{item.label}}</mat-placeholder>
                                <input type="text" matInput formControlName={{item.key}}>
                                <mat-error class="error-message" *ngIf="uploadFormControls[item.key].errors?.required">
                                    This field is required.
                                </mat-error>
                                <mat-error class="error-message">{{uploadFormControls[item.key].errors?.errorMsg}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="item.type === 'DATE'" floatLabel="never"
                                class="doc-list-form-field text-field">
                                <mat-placeholder>Please select a date</mat-placeholder>
                                <input matInput [matDatepicker]="picker" (focus)="picker.open()" formControlName={{item.key}}>
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <img src="assets/datepicker.svg" matDatepickerToggleIcon class="picker-img">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error-message" *ngIf="uploadFormControls[item.key].errors?.required">
                                    This field is required.
                                </mat-error>
                                <mat-error class="error-message">{{uploadFormControls[item.key].errors?.errorMsg}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="editPanErrMsg" [innerHTML]="editPanErrMsg" class="pan-update-inner-html red-text"></div>
                    <div *ngIf="editPanSuccMsg" [innerHTML]="editPanSuccMsg" class="pan-update-inner-html"></div>
                    <div *ngIf="panAadharLinkErrMsg" [innerHTML]="panAadharLinkErrMsg" class="pan-update-inner-html"></div>
                    <div *ngIf="panAadharLinkSuccMsg" [innerHTML]="panAadharLinkSuccMsg" class="pan-update-inner-html"></div>
                    <div *ngIf="panAadharLinkCheckMsg" [innerHTML]="panAadharLinkCheckMsg" class="pan-update-inner-html"></div>
                </div>
                <div class="adhaar-consent-checkbox" *ngIf="showUploadBox && docTypeDropdown.value == aadhaarCardDocCode && termsAndCnds">
                    <form [formGroup]="terms">
                        <mat-checkbox color = "primary" class="checkbox-text" (change)="selectedOption($event)" disableRipple = "true" formControlName="accept">
                                I agree to <a href="/my-atnc" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        </mat-checkbox>
                    </form>
                </div>
                <div *ngIf="showUploadBox && docTypeDropdown.value == aadhaarCardDocCode" class="aadhaar-note">Note: For Aadhaar card, please remember to upload both front and back sides</div>                        
                <div *ngIf="showUploadBox" class="doc-upload-flex flex">
                    <!-- <div class="doc-upload-wrapper">
                        <div class="upload-file">
                            <div (click)="openCamera()" class="file-input-content flex">
                                <span class="input-content-span-one">Take a photo of your identity </span>
                                <img class="camera-image" src="assets/camera.svg">
                                <div class="file-upload-mobile">Take a photo</div>
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="!showCamera" class="doc-upload-wrapper">
                        <app-drag-drop (fileDropped)="onDocumentLoad($event)"></app-drag-drop>
                        <div class="no-mobile">
                            <div class="upload-file-info flex">
                                <span>Accepted File types: .jpeg, .png, .pdf, .bmp</span>
                                <span>Maximum File Size: {{maxFileSize}} MB</span>
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="upload-file-info flex">
                                <span>File types: .jpeg, .png, .pdf, .bmp</span>
                                <span>Max. File Size: {{maxFileSize}} MB</span>
                            </div>
                        </div>
                        <div *ngIf="uploadedFileList && uploadedFileList.length > 0 && showUploadBox" class="uploaded-file-name">
                            <div *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</div>
                        </div>
                    </div>
                    <div *ngIf="showCamera" class="doc-upload-wrapper">
                        <!-- <app-camera (click)="openCamera()"></app-camera> -->
                        <div class="upload-file">
                            <div (click)="openCamera()" class="file-input-content flex">
                                <span class="input-content-span-one">Take a photo</span>
                                <img class="camera-image" src="assets/camera.svg">
                                <div class="file-upload-mobile">Take a photo</div>
                            </div>
                        </div>                        
                        <div *ngIf="uploadedFileList && uploadedFileList.length > 0 && showUploadBox" class="uploaded-file-name">
                            <div *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="docTypeDropdown.valid && showUploadBox && isInstaServ" class="osv-checkbox-wrapper">
                    <mat-radio-group class="alloc-radio-group flex" formControlName="osvControl">
                        <mat-radio-button class="alloc-radio-sec" (change)="onOsvChange($event)"
                            *ngFor="let item of osvOpts"
                            [value]="item.code">
                            <span class="radio-sec-label">{{item.label}}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="button-section flex">
                    <button mat-button class="cancel-button" type="button" (click)="onBackBtnClick()">Back</button>
                    <button *ngIf="docListPopupForm && !nsdlValidation" mat-button class="submit-button" type="submit" 
                    [disabled]="!docTypeDropdown.valid || !docListPopupForm.valid || (isInstaServ && (!osvControl.valid || uploadedFileList.length == 0)) || (docTypeDropdown.value == aadhaarCardDocCode && (!getAccept && termsAndCnds) || ((showInstaServConsent && instaServDropdown.value == 'Physical consent'  && !isPhysicalEnabled)))">Submit</button>
                    <button *ngIf="docListPopupForm && nsdlValidation && !isPanValid" mat-button class="submit-button" type="submit" [disabled]="!docListPopupForm.valid">Verify PAN</button>
                    <!-- <button *ngIf="docListPopupForm && nsdlValidation && isPanValid" mat-button class="submit-button" type="button" (click)="fulfillRequirement()">Continue</button> -->
                    <button *ngIf="!docListPopupForm" mat-button class="submit-button" type="button" [disabled]="!docTypeDropdown.valid || (isInstaServ && (!osvControl.valid || uploadedFileList.length == 0))" (click)="onSubmit()">Submit</button>
                </div>
            </form>
        </div>
    </div>
    <ng-template #checklistPopup>
        <div class="checklist-popup">
            <!-- <div mat-dialog-actions class="mat-dialog-actions">
                <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                    Close
                    <img src="assets/close.svg">
                </div>
            </div> -->
            <div class="checklist-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">
                Checklist
            </div>
            <div class="checklist-popup-content popup-content-height">
                <ng-container *ngIf="!isChkLstEmty">
                    <div class="list-item flex" *ngFor="let item of checklist">
                        <img src="assets/li-style.svg" class="list-item-img">
                        <span>{{item}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="isChkLstEmty">
                    <div class="checklist-empty-msg">No checklist available.</div>
                </ng-container>
                <div class="checklist-popup-btns">
                    <button class="close-button" type="button" (click)="closeChecklist()">Cancel</button>
                    <button class="save-button" *ngIf="!isPhysicalEnabled" type="button" (click)="submitUploadDocForm()">{{ isChkLstEmty ? 'Proceed' : 'Confirm'}}</button>
                    <button class="save-button" *ngIf="isPhysicalEnabled" type="button" (click)="confirmMode()">{{ isChkLstEmty ? 'Proceed' : 'Confirm'}}</button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #physicalConsentAlert>
        <div class="physical-consent-alert" >
            <div class="message-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">Note</div>
            <div class="popup-content popup-content-height">
                <div class="main-message">Aadhaar digital consent will not be accessbile if physical consent is choosen. To continue with physical consent, select 'YES'. To cancel, click 'No'.</div>
                <div class="button-div">
                    <button matButton class="save-button" [disabled]="consentAccepted" (click)="physicalMode()">Yes</button>
                    <button matButton class="close-button" (click)="closeConsentAlert('Digital consent')">No</button>
                </div>
            </div>            
            
        </div>
    </ng-template>
    <ng-template #physicalConsentConfirmAlert>
        <div class="physical-consent-alert" >
            <div class="message-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">Note</div>
            <div class="popup-content popup-content-height">
                <div class="main-message">Please confirm that you have acquired customer consent via the Aadhaar consent form prior to proceeding.</div>
                <div class="button-div">
                    <button matButton class="save-button" (click)="physicalConsentConfirm()">Yes</button>
                    <button matButton class="close-button" (click)="closePhysicalConfirmAlert()">No</button>
                </div>
            </div>            
            
        </div>
    </ng-template>
    <ng-template #srSubmitpopup>
        <div class="physical-consent-alert" >
                <div mat-dialog-actions>
                        <div class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}" (click)="closePopup()">
                            <div class="no-mobile">Close<img src="assets/close.svg" class="close-icon"></div>
                            <img src="assets/cross_circle_blue.svg" class="mobile">
                        </div>
                    </div>
            <div class="message-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}">Message</div>
            <div class="popup-content popup-content-height">
                <div class="main-message flex">
                        <div class="green-tick-div flex">
                                <img src="assets/green-tick.svg">
                        </div>
                    Document(s) uploaded successfully for SR number: {{submittedSrNo}}</div>
                <div class="message-sec flex">
                        <div class="info-active flex">
                                <img src="assets/info_active.svg">
                        </div>Would you also like to </div>
                <div class="update-button-div">
                    <button matButton *ngIf="!KycBtn" class="update-button" (click)="updateKYC()">Update KYC</button>
                    <button matButton *ngIf="!PanBtn" class="update-button" (click)="updatePAN()">Update PAN</button>
                    <button matButton *ngIf="!NeftBtn" class="update-button" (click)="updateNEFT()">Update NEFT</button>
                </div>
            </div>            
            
        </div>
    </ng-template>

    <ng-template #consentAlert>
        <div class="physical-consent-alert" >
            <div class="message-popup-header flex" [ngClass]="{'hni-get-touch':isHNIUSer}"><span *ngIf="templateHeader">{{ templateHeader }}</span></div>
            <div class="popup-content popup-content-height">
                <div class="main-message">{{ templateMsg }}</div>
                <div class="button-div">
                    <button matButton class="close-button" (click)="closeConsent()">Close</button>
                </div>
            </div>                         
        </div>
    </ng-template>
</div>