<div class="suspense-doc-upload" [ngClass]="{'virtual-branch': dataService.isVirtualBranch}">
    <div *ngIf="!data.disableClose" mat-dialog-actions (click)="onBack()">
        <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
            <div class="no-mobile">
                Close<img src="assets/close.svg" class="close-icon">
            </div>
            <img src="assets/cross_circle_blue.svg" class="mobile">
        </div>
    </div>
    <div class="suspense-doc-upload-header" [ngClass]="{'hni-popups-header':isHNIUSer}">Upload Document</div>
    <div class="suspense-doc-upload-content popup-content-height">
        <div *ngIf="showRequirementList" class="requirements-wrapper">
            <div class="requirement-item">
                <div class="requirement-item flex">
                    <div class="requirement-text">
                        <div class="requirement-sub-text">
                            In case you want to submit supporting documents, please click on Upload button
                        </div>
                        <div class="requirement-sub-text">
                            (Maximum of 2 files can be uploaded)
                        </div>
                    </div>        
                    <button mat-button class="submit-button upload-button" type="button"
                        [disabled]="uploadedFileList.length>=maxNumOfFiles"
                        (click)="onUploadClick()">
                        Upload
                    </button>
                </div>
                <div *ngIf="uploadedFileList && uploadedFileList.length > 0"
                    class="uploaded-files-preview flex">
                    <div *ngFor="let item of uploadedFileList;let idx=index" class="uploaded-file">
                        <span>{{item.name}} - Uploaded Successfully</span>
                        <img src="assets/cross.svg" class="cross-icon" (click)="deleteFile(idx)">
                    </div>
                </div>
                <div class="button-section flex">
                    <button mat-button class="cancel-button" type="button"
                        (click)="onBack()">Back</button>
                    <button mat-button class="submit-button" type="button"
                        (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </div>
        <div *ngIf="!showRequirementList" class="overflow-container">
            <form (ngSubmit)="onNext()" autocomplete="off">
                <div class="doc-upload-flex flex">
                    <div class="doc-upload-wrapper">
                        <app-drag-drop (fileDropped)="onDocumentLoad($event)"></app-drag-drop>
                        <div class="no-mobile">
                            <div class="upload-file-info flex">
                                <span>Accepted File types: .jpeg, .png, .pdf, .bmp, .heic</span>
                                <span>Max. File Size: {{maxFileSize}} MB</span>
                            </div>
                        </div>
                        <div class="mobile">
                            <div class="upload-file-info flex">
                                <span>File types: .jpeg, .png, .pdf, .bmp, .heic</span>
                                <span>Max. File Size: {{maxFileSize}} MB</span>
                            </div>
                        </div>
                        <div *ngIf="uploadedFileList && uploadedFileList.length > 0"
                            class="uploaded-file-name">
                            <div *ngFor="let item of uploadedFileList">Document Uploaded - {{item.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="button-section flex">
                    <button mat-button class="cancel-button" type="button"
                        (click)="onBack()">Back</button>
                    <button mat-button class="submit-button" type="button" [disabled]="uploadedFileList.length==0"
                        (click)="onNext()">Next</button>
                </div>
            </form>
        </div>
    </div>
</div>