import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpService } from 'src/app/services/http/http.service';
import { Utils } from 'src/app/utils/common-functions';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { catchError } from 'rxjs/operators';
import { throwError, from } from 'rxjs';
import { loginService } from 'src/app/services/login/login.service'
import { PasaOfferPopupComponent } from '../my-policies/pasa-offer-popup/pasa-offer-popup.component';
import { MyPoliciesDialogComponent } from '../my-policies/my-policies-dialog/my-policies-dialog.component';
import { toasterReqBody } from '../cpadmin-portal/cpadmin-portal-data-modal';
import { HttpOptions } from 'src/app/utils/app-data.model';

@Component({
  selector: 'app-cpadmin-portal',
  templateUrl: './cpadmin-portal.component.html',
  styleUrls: ['./cpadmin-portal.component.css']
})
export class CpadminPortalComponent implements OnInit {
  showToaster: boolean = false;
  toasterData: any;
  urlstring: any;
  isCPAdminPortal: boolean = false;
  policyDtls: Array<any> = [];
  expInfoPopupData = [];
  id: string;
  status: string;
  url:any;
  constructor(public formBuilder: FormBuilder, public router: Router,
    public auth: AuthService, public _httpService: HttpService,public route: ActivatedRoute,
    public loginService: loginService, public utils: Utils,  public myPoliciesService: MyPoliciesService,
    public dialog: MatDialog, public dataService: DataService, private message: MessageService) { }
    
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: any) => {
      let paramValue = { ...params }
      if (paramValue != undefined && paramValue != null && paramValue != {}) {
        this.id = this.route.snapshot.queryParamMap.get('id');
      }
      if (this.id) {
        this.login();
      }
    })
    this.isCPAdminPortal = true;
    console.log(this.isCPAdminPortal);
  }
  login() {
    const loginbody = {
      "requesttype": "CUST_LOGIN",
      "cp_login_mode": "CO",
      "optradio": "clientID",
      "cp_loginid": this.id,
      //loginaccesstoken: this.accesstoken
    }
    return this._httpService.reinit()
      .subscribe((response) => {
        this.utils.log(response);
        if (response != null) {
            return this.loginService.getAdmLoginDetails(loginbody).subscribe((resp) => {
              if (this._httpService.isSuccessResponse(resp)) {
                this.dataService.setLoginResp(resp.body);
                console.log(loginbody);
                //this.utils.setLocalData('userDetails', JSON.stringify(resp.body), true);
                this.utils.setLocalData('loggedIn', 'true', false);
                this.myPoliciesService.getNpsDetails();
                this.router.navigate(['/my-policies']);
                //this.getToaster();
        } else {
          this._httpService.apiErrorHandler(resp);
        }
      });
      }
      return response;
      }),
      catchError((error) => throwError(error))
  } 
  // getToaster() {
  //   let toasterReqBody: toasterReqBody = {
  //     searchtype: 'PopupToasterRule',
  //     clientid: this.utils.getClientId(),
  //   }
  //   return this.myPoliciesService.getToasterPopup(toasterReqBody)
  //     .subscribe((resp) => {
  //       if (this._httpService.isSuccessResponse(resp)) {
  //         resp.body.sort(this.myPoliciesService.compareToasterObj);
  //         this.showToaster = this.myPoliciesService.showHideToaster(resp.body);
  //         this.toasterData = this.myPoliciesService.setToasterMsg(resp.body);
  //         this.expInfoPopupData = this.myPoliciesService.getExpInfoPopup(resp.body);
  //         if (this.expInfoPopupData.length == 0) {
  //           this.dialog.open(PasaOfferPopupComponent, { panelClass: 'pasa-offer-dialog', backdropClass: 'pasa-offer-dialog-backdrop' });
  //         } else {
  //           this.openExpiredPopup();
  //         }
  //       } else {
  //         this._httpService.apiErrorHandler(resp);
  //       }
  //     }),
  //     catchError((error) => throwError(error));
  // }
  // openExpiredPopup() {
  //   this.dialog.open(MyPoliciesDialogComponent, {
  //     panelClass: 'my-policies-dialog', disableClose: true,
  //     data: {
  //       dtls: this.expInfoPopupData
  //     }
  //   });
  // }  
  // login() {
  //   let loginbody: cploginReqBody = {
  //     "requesttype": "CUST_LOGIN",
  //     "cp_login_mode": 'CO',
  //     "optradio": 'clientID',
  //     "cp_loginid":'' ,
  //   }
  //   let httpHeaders = {
  //     "apiname": "CUST_LOGIN",
  //   }
  //   return this._httpService.init(httpHeaders)
  //     .subscribe((response) => {
  //       this.utils.log(response);
  //       if (response) {
  //         this.loginService.getAdmLoginDetails(loginbody)
  //           .subscribe((resp) => {
  //             if (this._httpService.isSuccessResponse(resp)) {
  //               this.dataService.setLoginResp(resp.body);
  //               console.log(resp);
  //               this.utils.setLocalData('userDetails', JSON.stringify(resp.body), true);
  //               this.utils.setLocalData('loggedIn', 'true', false);
  //                   this.router.navigate(['/my-policies']);
  //             }
  //           });
  //       }
  //       return response;
  //     }),
  //     catchError((error) => throwError(error))
  // }

}
