import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-virtual-branch-test',
  templateUrl: './virtual-branch-test.component.html',
  styleUrls: ['./virtual-branch-test.component.css']
})
export class VirtualBranchTestComponent implements OnInit {
  virtualBranchGetToken = "portal/authenticate/server/gettoken"
  virtualBranchValidateToken = "portal/authenticate/server/validatetoken"
  iframeURL = null;
  showIframe = false
  options = { headers : new HttpHeaders({'X-csps-xrfkey': 'CSPS-HDFC_001'}) }
  userid = ''
  policy = ''
  action = ''
  virtualBranchForm: FormGroup;
  actionDropDown: Array<any> = [
    { code: "CHNG_CON_NUM", label: "Update Contact" },
    { code: "CHNG_NAME", label: "Update Name" },
    { code: "UPD_KYC", label: "Update KYC" },
    { code: "FUND_SWT", label: "Switch Fund" },
    { code: "PREMIUM_REDRN", label: "Premium Redirection" },
  ];


  constructor(public http: HttpClient, public sanitizer: DomSanitizer, public route: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.virtualBranchForm = this.fb.group({
      "clientid": ['', Validators.required],
      "policyno": ['', Validators.required],
      "action": ['', Validators.required]
    });
    /* setTimeout(() => {
      this.action = this.route.snapshot.queryParamMap.get('action')
      this.userid = this.route.snapshot.queryParamMap.get('userid')
      this.policy = this.route.snapshot.queryParamMap.get('policy')
      this.getToken().subscribe(resp => {
        this.validateToken(resp).subscribe(validateResp => {
          this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(validateResp.url);
          this.showIframe = true
        })  
      })
    }, 100) */    
  }
  onSubmit() {
    setTimeout(() => {
      this.action = this.virtualBranchForm.controls.action.value
      this.userid = this.virtualBranchForm.controls.clientid.value
      this.policy = this.virtualBranchForm.controls.policyno.value
      this.getToken().subscribe(resp => {
        this.validateToken(resp).subscribe(validateResp => {
          this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(validateResp.url);
          this.showIframe = true
        })  
      })
    }, 100)
  }
  getToken() {
    let postRequest = {      
        "userid": this.userid,
        "xrfkey": "CSPS-HDFC_001",
        "source": "VIRTUAL_BRANCH"
    }
    return this.http
    .post<any>(this.virtualBranchGetToken, postRequest, this.options)
    .pipe(
        map((resp) => {
            let apiResponse: any;                
                apiResponse = resp;
            
            return apiResponse;

         }),
         catchError(error => {console.error(error); return throwError(error);} )
    )
  }
  validateToken(tokenResp) {
    let postRequest = {
      "accessToken": tokenResp.accesstoken,
      "xrfkey": "CSPS-HDFC_001",
      "source": "VIRTUAL_BRANCH",
      "action": this.action,
      "policyNo": this.policy
  }
    return this.http
    .post<any>(this.virtualBranchValidateToken, postRequest, this.options)
    .pipe(
        map((resp) => {
            let apiResponse: any;                
                apiResponse = resp.body;
            
            return apiResponse;

         }),
         catchError(error => {console.error(error); return throwError(error);} )
    )
  }
  

}
