<div class="manual-page" [ngClass]="{'virtual-branch': data.disableClose}">
                <div mat-dialog-actions *ngIf="!queryParams" (click)="cancelManualUpdate();trackUserActions('close')">
                                <div mat-dialog-close class="close-dialog" [ngClass]="{'hni-close-dialog':isHNIUSer}">
                                    Close
                                    <img src="assets/close.svg">
                                </div>  
                </div>
                <div class="manual-header flex" [ngClass]="{'hni-popups-header':isHNIUSer}">{{addressbody.header}}</div>                
                <div *ngIf="!showOcrUpdate" class="manual-content popup-content-height">
                <form [formGroup]="changeAddress" (ngSubmit)="submitAddress()">
                    <div class="mandatory"><span class="error-two">*</span>Mandatory Field</div> 
                    <div class="form-field-section flex">
                            <div class="form-field-div">
                                <mat-form-field class="mailing-form-field"  [ngClass]="{'error-field': (validation.updateCountry.errors?.required)}">
                                            <label class="form-field-label">Country<span class="error-two">*</span></label>
                                            <input type="text" matInput formControlName="updateCountry" [matAutocomplete]="selectCountry">
                                            <mat-autocomplete #selectCountry="matAutocomplete" [displayWith]="displayValue" (optionSelected)="countrySelected($event)">
                                                    <mat-option *ngFor="let country of filteredCountry | async" [value]="{label: country[1], value: country[5][0]}">{{country[1]}}</mat-option>
                                            </mat-autocomplete>
                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateCountry').getError('errorMsg')">
                                                {{changeAddress.get('updateCountry').getError('errorMsg')}}
                                        </mat-error>
                                </mat-form-field>
                        </div>
                            <div class="form-field-div">
                                    <mat-form-field class="mailing-form-field" [ngClass]="{'error-field': (validation.updateHouseNumber.errors?.required)}">
                                            <label class="form-field-label">House/Flat Number<span class="error-two">*</span></label>
                                            <input matInput type="text" #second formControlName="updateHouseNumber" maxlength="30">
                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateHouseNumber').getError('errorMsg')">
                                                {{changeAddress.get('updateHouseNumber').getError('errorMsg')}}
                                            </mat-error>
                                    </mat-form-field>
                            </div>
                            <div class="form-field-div">
                                    <mat-form-field class="mailing-form-field" [ngClass]="{'error-field': (validation.updateAddressOne.errors?.required)}">
                                            <label class="form-field-label">Street Address<span class="error-two">*</span></label>
                                            <input matInput type="text" #third class="form-field-input" formControlName="updateAddressOne">
                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateAddressOne').getError('errorMsg')">
                                                {{changeAddress.get('updateAddressOne').getError('errorMsg')}}
                                            </mat-error>
                                        </mat-form-field>
                            </div>                            
                            <div class="form-field-div">
                                    <mat-form-field class="mailing-form-field"  [ngClass]="{'error-field': ( validation.updateLandmark.errors?.errorMsg)}">
                                            <label class="form-field-label">Area/Landmark</label>
                                            <input matInput type="text" class="form-field-input" formControlName="updateLandmark">
                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateLandmark').getError('errorMsg')">
                                                {{changeAddress.get('updateLandmark').getError('errorMsg')}}
                                            </mat-error>
                                        </mat-form-field>
                            </div>
                            <div class="form-field-div" *ngIf="inputFlag">
                                    <mat-form-field class="mailing-form-field"  [ngClass]="{'error-field': ( validation.updatePincode.errors?.required)}">
                                    <label class="form-field-label">Pincode<span class="error-two">*</span></label>
                                    <input matInput type="tel" decimalNumbersOnly class="form-field-input" formControlName="updatePincode" [matAutocomplete]="selectPincode" (keyup)="pincodeEntered($event.target.value)" maxlength = "6" >
                                    <mat-autocomplete #selectPincode="matAutocomplete">
                                            <mat-option *ngFor="let pincode of filteredPincode | async" [value]="pincode">{{pincode}}</mat-option>
                                    </mat-autocomplete>
                                    <mat-error class="error-message" *ngIf="changeAddress.get('updatePincode').getError('errorMsg')">
                                        {{changeAddress.get('updatePincode').getError('errorMsg')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-div" *ngIf="!inputFlag">
                                <mat-form-field class="mailing-form-field" >
                                        <label class="form-field-label">Pincode<span class="error-two">*</span></label>
                                        <input matInput type="text" class="form-field-input" style="text-transform: uppercase" formControlName="updatePincode" [maxLength]="maxLength">
                                        <mat-error class="error-message" *ngIf="changeAddress.get('updatePincode').getError('errorMsg')">
                                                {{changeAddress.get('updatePincode').getError('errorMsg')}}
                                        </mat-error>
                                </mat-form-field>
                        </div>
                            <div class="form-field-div" *ngIf="inputFlag">
                                    <mat-form-field class="mailing-form-field" [ngClass]="{'error-field': (validation.updateCity.errors?.required)}">
                                                <label class="form-field-label">City <span class="error-two">*</span></label>
                                                <input type="text" matInput formControlName="updateCity" [matAutocomplete]="selectCity">
                                                <mat-autocomplete #selectCity="matAutocomplete" [displayWith]="displayValue" (optionSelected)="citySelected($event)">
                                                        <mat-option *ngFor="let city of filteredCity | async" [value]="{label: city[1], value: city[0]}">{{city[1]}}</mat-option>
                                                </mat-autocomplete>
                                                <mat-error class="error-message" *ngIf="changeAddress.get('updateCity').getError('errorMsg')">
                                                        {{changeAddress.get('updateCity').getError('errorMsg')}}
                                                </mat-error>
                                        </mat-form-field>
                            </div>
                            <div class="form-field-div" *ngIf="!inputFlag">
                                <mat-form-field class="mailing-form-field" >
                                        <label class="form-field-label">City</label>
                                        <input matInput type="text" class="form-field-input" formControlName="updateCity">
                                        <mat-error class="error-message" *ngIf="changeAddress.get('updateCity').getError('errorMsg')">
                                                {{changeAddress.get('updateCity').getError('errorMsg')}}
                                        </mat-error>
                                </mat-form-field>
                        </div>
                            <div class="form-field-div" *ngIf="inputFlag">
                                    <mat-form-field class="mailing-form-field"  [ngClass]="{'error-field': (validation.updateState.errors?.required)}">
                                                <label class="form-field-label">State<span class="error-two">*</span></label>
                                                <input type="text" matInput formControlName="updateState" [matAutocomplete]="selectState">
                                                <mat-autocomplete #selectState="matAutocomplete" [displayWith]="displayValue" (optionSelected)="stateSelected($event)">
                                                        <mat-option *ngFor="let state of filteredState | async" [value]="{label: state[1], value: state[0]}">{{state[1]}}</mat-option>
                                                </mat-autocomplete>
                                                <mat-error class="error-message" *ngIf="changeAddress.get('updateState').getError('errorMsg')">
                                                        {{changeAddress.get('updateState').getError('errorMsg')}}
                                        </mat-error>
                                        </mat-form-field>
                            </div>
                            <div class="form-field-div" *ngIf="!inputFlag">
                                <mat-form-field class="mailing-form-field">
                                        <label class="form-field-label">State</label>
                                        <input matInput type="text" class="form-field-input" formControlName="updateState">
                                        <mat-error class="error-message" *ngIf="changeAddress.get('updateState').getError('errorMsg')">
                                                {{changeAddress.get('updateState').getError('errorMsg')}}
                                        </mat-error>
                                </mat-form-field>
                        </div>
                </div>   
                        <div class="manual-address-wrap">
                                <mat-checkbox color = "primary" class="checkbox-text manual-add" disableRipple = "true" *ngIf="data.role == 'Owner' && addressbody.header == 'Add Mailing Address'" (change)="isSame($event)">
                                        Update this as my permanent address too
                                </mat-checkbox>
                        </div>             
                <div class="button-div flex">
                        <button mat-button class="cancel-button" type="button" (click)="cancelManualUpdate();trackUserActions('cancel')">Cancel</button>
                        <button mat-button class="submit-button" type="submit">Submit</button>
                </div> 
        </form>  
                </div>                
        
        <div *ngIf="showOcrUpdate" class="update-file-div ocr-update-popup">                
                <div class="ocr-update-popup-content popup-content-height">
                <div class="back-button-div" *ngIf="!proceedFlag">
                        <button mat-button class="back-button" type="button" (click)="onBackBtnClick()">< Back</button>
                </div>
                <form [formGroup]="changeAddress" (ngSubmit)="submitAddress()">
                <div class="main-content-wrapper flex" [ngClass]="{'offline-aadhar': data.offlineAadhar}">
                        <div *ngIf="!data.offlineAadhar" class="left-div">
                        <div *ngFor="let item of uploadedFilesURL" class="id-proof-div">
                                <img *ngIf="!showPdf" [src]="item" class="id-proof-img">
                                <pdf-viewer *ngIf="showPdf" 
                        [src]="item" 
                        [original-size]="false" 
                        [render-text] = "true"
                        style="width: 200px; height: 200px">
                        </pdf-viewer>
                        </div>
                        <div class="id-proof-status flex">
                                <!-- <div class="id-proof-sec flex">
                                <div class="id-proof-content">ID PROOF NOT PROPERLY UPLOADED</div>
                                <img src="assets/close-red.svg" class="close-red">
                                </div> -->
                                <button mat-button class="rescan-button" type="button" (click)="onBackBtnClick()">Rescan Document</button>
                        </div>
                        </div>
                        <div class="right-div">
                        <div [ngClass]="{'notify-div-mobile': showDiv, 'notify-div-mobile-hide': !showDiv}">
                                <div class="notify-div" *ngIf="showDiv">
                                <div class="notify-text">
                                        If you make edits to the details scanned from the document,
                                        a physical verification would be required.
                                </div>
                                <div class="notify-text-two">
                                        This typically takes 24-48 hours
                                </div>
                                <div class="notify-text-three">
                                        Do you want to make edits?
                                </div>
                                <div class="notify-button flex">
                                        <button mat-button class="cancel-notify" (click)="hideDiv()" type="button">Cancel</button>
                                        <button mat-button class="proceed-notify"
                                        (click)="hideDivProceed()" type="button">Proceed</button>
                                </div>
                                </div>
                        </div>
                        <div class="form-field-section" [ngClass]="{'flex': data.offlineAadhar}">
                                <div class="edit-image">
                                <img src="assets/edit.svg" *ngIf="proceedFlag" [hidden]="showDiv" (click)="displayAddress()">
                                </div>                        
                                <div class="form-field-div update-form-top">
                                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                            <label class="form-field-label">Country<span class="error-two">*</span></label>
                                                            <input class="form-field-input" [readonly]="proceedFlag" type="text" matInput formControlName="updateCountry" [matAutocomplete]="selectCountry">
                                                            <mat-autocomplete #selectCountry="matAutocomplete" [displayWith]="displayValue" (optionSelected)="countrySelected($event)">
                                                                    <mat-option *ngFor="let country of filteredCountry | async" [value]="{label: country[1], value: country[5][0]}">{{country[1]}}</mat-option>
                                                            </mat-autocomplete>
                                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateCountry').getError('errorMsg')">
                                                                {{changeAddress.get('updateCountry').getError('errorMsg')}}
                                                        </mat-error>
                                                </mat-form-field>
                                        </div>
                                            <div class="form-field-div">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                            <label class="form-field-label">House/Flat Number<span class="error-two">*</span></label>
                                                            <input class="form-field-input" [readonly]="proceedFlag" matInput type="text" #second formControlName="updateHouseNumber" maxlength="30">
                                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateHouseNumber').getError('errorMsg')">
                                                                {{changeAddress.get('updateHouseNumber').getError('errorMsg')}}
                                                            </mat-error>
                                                    </mat-form-field>
                                            </div>
                                            <div class="form-field-div">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                            <label class="form-field-label">Street Address<span class="error-two">*</span></label>
                                                            <input class="form-field-input" [readonly]="proceedFlag" matInput type="text" #third formControlName="updateAddressOne">
                                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateAddressOne').getError('errorMsg')">
                                                                {{changeAddress.get('updateAddressOne').getError('errorMsg')}}
                                                            </mat-error>
                                                        </mat-form-field>
                                            </div>                                            
                                            <div class="form-field-div">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                            <label class="form-field-label">Area/Landmark</label>
                                                            <input matInput type="text" class="form-field-input" [readonly]="proceedFlag" formControlName="updateLandmark">
                                                            <mat-error class="error-message" *ngIf="changeAddress.get('updateLandmark').getError('errorMsg')">
                                                                {{changeAddress.get('updateLandmark').getError('errorMsg')}}
                                                            </mat-error>
                                                        </mat-form-field>
                                            </div>
                                            <div class="form-field-div" *ngIf="inputFlag">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                    <label class="form-field-label">Pincode<span class="error-two">*</span></label>
                                                    <input matInput type="tel" decimalNumbersOnly class="form-field-input" [readonly]="proceedFlag" formControlName="updatePincode" [matAutocomplete]="selectPincode" (keyup)="pincodeEntered($event.target.value)" [maxLength]="maxLength" >
                                                    <mat-autocomplete #selectPincode="matAutocomplete">
                                                            <mat-option *ngFor="let pincode of filteredPincode | async" [value]="pincode">{{pincode}}</mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error class="error-message" *ngIf="changeAddress.get('updatePincode').getError('errorMsg')">
                                                        {{changeAddress.get('updatePincode').getError('errorMsg')}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-field-div" *ngIf="!inputFlag">
                                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}" >
                                                        <label class="form-field-label">Pincode<span class="error-two">*</span></label>
                                                        <input matInput type="text" class="form-field-input" style="text-transform: uppercase" [readonly]="proceedFlag" formControlName="updatePincode" [maxLength]="maxLength">
                                                        <mat-error class="error-message" *ngIf="changeAddress.get('updatePincode').getError('errorMsg')">
                                                                {{changeAddress.get('updatePincode').getError('errorMsg')}}
                                                        </mat-error>
                                                </mat-form-field>
                                        </div>
                                            <div class="form-field-div" *ngIf="inputFlag">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                                <label class="form-field-label">City <span class="error-two">*</span></label>
                                                                <input class="form-field-input" [readonly]="proceedFlag" type="text" matInput formControlName="updateCity" [matAutocomplete]="selectCity">
                                                                <mat-autocomplete #selectCity="matAutocomplete" [displayWith]="displayValue" (optionSelected)="citySelected($event)">
                                                                        <mat-option *ngFor="let city of filteredCity | async" [value]="{label: city[1], value: city[0]}">{{city[1]}}</mat-option>
                                                                </mat-autocomplete>
                                                                <mat-error class="error-message" *ngIf="changeAddress.get('updateCity').getError('errorMsg')">
                                                                        {{changeAddress.get('updateCity').getError('errorMsg')}}
                                                                </mat-error>
                                                        </mat-form-field>
                                            </div>
                                            <div class="form-field-div" *ngIf="!inputFlag">
                                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}" >
                                                        <label class="form-field-label">City</label>
                                                        <input matInput type="text" class="form-field-input" [readonly]="proceedFlag" formControlName="updateCity">
                                                        <mat-error class="error-message" *ngIf="changeAddress.get('updateCity').getError('errorMsg')">
                                                                {{changeAddress.get('updateCity').getError('errorMsg')}}
                                                        </mat-error>
                                                </mat-form-field>
                                        </div>
                                            <div class="form-field-div" *ngIf="inputFlag">
                                                    <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                                <label class="form-field-label">State<span class="error-two">*</span></label>
                                                                <input class="form-field-input" [readonly]="proceedFlag" type="text" matInput formControlName="updateState" [matAutocomplete]="selectState">
                                                                <mat-autocomplete #selectState="matAutocomplete" [displayWith]="displayValue" (optionSelected)="stateSelected($event)">
                                                                        <mat-option *ngFor="let state of filteredState | async" [value]="{label: state[1], value: state[0]}">{{state[1]}}</mat-option>
                                                                </mat-autocomplete>
                                                                <mat-error class="error-message" *ngIf="changeAddress.get('updateState').getError('errorMsg')">
                                                                        {{changeAddress.get('updateState').getError('errorMsg')}}
                                                        </mat-error>
                                                        </mat-form-field>
                                            </div>
                                            <div class="form-field-div" *ngIf="!inputFlag">
                                                <mat-form-field class="update-id-form-field" [ngClass]="{'update-id-disable-form': proceedFlag}">
                                                        <label class="form-field-label">State</label>
                                                        <input matInput type="text" class="form-field-input" [readonly]="proceedFlag" formControlName="updateState">
                                                        <mat-error class="error-message" *ngIf="changeAddress.get('updateState').getError('errorMsg')">
                                                                {{changeAddress.get('updateState').getError('errorMsg')}}
                                                        </mat-error>
                                                </mat-form-field>
                                        </div>
                                        
                        </div>
                        <div class="right-sub-div" *ngIf="!proceedFlag">This typically takes 24-48 hours.</div>
                        </div>
                </div>
                <!-- <ng-container *ngFor="let accepeted in alreadyAccepted"> -->
                        <div class="otp-antc-container" *ngIf="this.data.ocrDocCode == 'DOC_018' && showOtpSection && (this.data.id == 'beneficiary' || this.data.id == 'life assured')">
                        
                                <div class="tnc-otp">
                                        <div class="otp-sent-msg" *ngIf="(showOtpSection)">
                                                OTP is triggered to Beneficiaries registered mobile no. & Email id, Please enter OTP to accept T&C
                                        </div>
                                        <div class="otp-timer-div" *ngIf="(showOtpSection)">
                                                Your OTP will be valid for <span
                                                          class="otp-timer">{{hours|number:'2.0-0'}}:{{minutes|number:'2.0-0'}}</span>
                                                        <a (click)="RegenOTP()" *ngIf="(timerSec==0)"><img src="assets/refresh.png" class="refresh-img">Regenerate OTP</a>
                                        </div>
                                        <div class="OTP-generation" >
                                                <div class="form-field-div">  
                                                        <div class="form-label">Enter the OTP Received<sup>*</sup></div>
                                                        <form [formGroup] = "beneficiaryOtpForm">
                                                                <mat-form-field floatLabel="never" class="otp-field">
                                                                          <mat-placeholder>*******</mat-placeholder>
                                                                            <input class="form-field-input" formControlName="otp" decimalNumbersOnly [type]="passwordType" maxlength="6" matInput
                                                                                        (focusout)="otpFocusOut($event)" autocomplete="off">
                                                                                     
                                                                                    <div class="cursor-point" matSuffix (click)="showPswd()">
                                                                                        <img formcontrolName="otp" class="iris-closed" matSuffix src="assets/eye_closed.svg"
                                                                                          *ngIf="reveal">
                                                                                        <img formcontrolName="otp" class="iris-open" matSuffix src="assets/eye.png"
                                                                                          *ngIf="!reveal">
                                                                                </div>
                                                                                <mat-error class="error-message" *ngIf="(otpFocus && beneficiaryOtpForm.controls.otp.errors?.required)">
                                                                                        Please enter OTP.
                                                                                    </mat-error>
                                                                                    <mat-error class="error-message" *ngIf="(otpFocus && beneficiaryOtpForm.controls.otp.errors?.pattern)">
                                                                                        Must be 6 digits.
                                                                                    </mat-error>                                                                 
                                                                </mat-form-field>                                                       
                                                        </form>
                                                </div>
                                                                   
                                        </div>
                                </div>
                                <div class="adhaar-consent-checkbox left" *ngIf="(this.data.ocrDocCode ==  'DOC_018') &&  ((this.data.id == 'beneficiary' || this.data.id == 'life assured') && showOtpSection)">
                                        <form [formGroup]="terms">
                                                <mat-checkbox color = "primary" class="checkbox-text" (change)="readTnC($event)" disableRipple = "true" formControlName="accept">
                                                        I agree to <a href="/my-atnc" target="_blank" rel = "noopener noreferrer">Terms and Conditions</a>
                                                </mat-checkbox>
                                        </form>
                                </div> 
        
                                <div *ngIf="showOtpSection" class="otp-error-msg">{{this.statusMessage}}</div>
                        </div>
        
                        <div class="adhaar-consent-checkbox center" *ngIf="(this.data.ocrDocCode ==  'DOC_018') && (this.data.role == 'Owner' || !showOtpButton && !showOtpSection)">
                                <form [formGroup]="terms">
                                        <mat-checkbox color = "primary" class="checkbox-text" (change)="readTnC($event)" disableRipple = "true" formControlName="accept">
                                                I agree to <a href="/my-atnc" target="_blank" rel = "noopener noreferrer">Terms and Conditions</a>
                                        </mat-checkbox>
                                </form>
                                <mat-checkbox color = "primary" class="checkbox-text" disableRipple = "true" *ngIf="data.role == 'Owner' && addressbody.header == 'Add Mailing Address'" (change)="isSame($event)">
                                        Update this as my permanent address too
                                </mat-checkbox>
                        </div>      
                        <!-- </ng-container> -->
                <div class="button-div flex" [ngClass]="{'update-id-btn-opacity': showDiv}">
                        <button *ngIf="proceedFlag" mat-button class="cancel-button" type="button" (click)="onBackBtnClick()">Back</button>
                <button *ngIf="proceedFlag && !showOtpButton && !showOtpSection" mat-button class="submit-button" type="submit" [disabled]="this.data.ocrDocCode ==  'DOC_018' && !getAccept">Submit</button>
                <button *ngIf="proceedFlag && showOtpButton" mat-button class="submit-button" type="button" (click)="generateOtp()">Send OTP</button>
                <button *ngIf="proceedFlag && !showOtpButton && showOtpSection" class="submit-button" mat-flat-button type="button" (click)="validateOTP()" [disabled]="!getAccept || !beneficiaryOtpForm.valid">Submit</button>
                <button *ngIf="!proceedFlag" mat-button class="cancel-button" type="button" (click)="onCancelBtnClick();trackUserActions('cancel')">Cancel</button>
                <button *ngIf="!proceedFlag && !edited  && !showOtpSection" mat-button class="submit-button" type="submit" [disabled]="this.data.ocrDocCode ==  'DOC_018' && !getAccept">Save Changes</button>
                <button *ngIf="!proceedFlag && edited" mat-button class="submit-button" type="button" (click)="generateOtp()" >Send OTP</button>
                <button *ngIf="!proceedFlag && !showOtpButton && showOtpSection" class="submit-button" type="button" mat-flat-button (click)="validateOTP()" [disabled]="!getAccept || !beneficiaryOtpForm.valid">Save Changes</button>
                </div>
                </form>
                </div>
        </div>                    
</div>