import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { serviceURL } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as moment from 'moment';
import { MessageService } from 'src/app/services/message/message.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SurrenderAversionService } from 'src/app/services/surrender-aversion/surrender-aversion.service';

@Component({
  selector: 'app-vserv-page',
  templateUrl: './vserv-page.component.html',
  styleUrls: ['./vserv-page.component.css']
})
export class VservPageComponent implements OnInit {
  @ViewChild('panDialog') public panDialog:TemplateRef<any>
  nameFocusOut: boolean;
  statusMessage: string;
  branchLocatorUrl: string = serviceURL.branchLocator;
  isCaptchaValid: boolean;
  today = new Date();
  otpFlag: boolean;
  languages: any;
  checked: boolean;
  validateResp: any;
  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }
  otpResponse: any;
  interval: any;
  timerSec: number;
  hours: number;
  minutes: number;
  maskMobileNo: any;
  enableButton: boolean;
  action: any = "";
  nriVservFlag: boolean = false ;
  maskEmailId: any;
  msgShowOnload: boolean = false ;
  reqBody: any;
  medium: string;
  validateMessage: any;
  validateNriMsg: boolean = false;

  get device() {
    return this.deviceService.getDeviceInfo();
  }

  constructor(public formBuilder: FormBuilder, public customLinkService: CustomLinkService,public surrenderService: SurrenderAversionService,
    public httpService: HttpService,public message: MessageService, public route: ActivatedRoute, public dialog: MatDialog,private deviceService: DeviceDetectorService) { }
  vservForm: FormGroup;
  isOtpMasked: boolean = true;

  ngOnInit(): void {
    this.httpService.init({}).subscribe(resp => {
      this.httpService.initResp = resp;
      this.getLanguages();
    }),
    catchError((error) => throwError(error));
    this.vservForm = this.formBuilder.group(
      {
        clientName: ['', [Validators.required]],
        policyNo: ['', [Validators.required , Validators.pattern('^[0-9]{8,8}$')]],
        dob: ['', [Validators.required, CustomValidators.noFutureDates]],
        language: ['', [Validators.required]],
        captcha: ['', [Validators.required]],
        otp: [''],
      },
    );
    this.action = this.route.snapshot.queryParamMap.get('action');
    this.medium = this.route.snapshot.queryParamMap.get('medium');
    if(this.action == "nri_vserv"){
      this.nriVservFlag = true;
    }
    else {
      this.nriVservFlag = false;
    }
    console.log(this.action);
  }
  clientFocusOut(event: any) {
    this.nameFocusOut = true;
  }
  errorclear() {
    this.statusMessage = "";
  }
  clearClientCaptcha() {
    this.vservForm.get('captcha').setValue('');
  }
  checkCaptcha(isValid: string) {
    if (isValid === 'true') {
      this.isCaptchaValid = true;
    } else {
      this.isCaptchaValid = false;
    }
    setTimeout(() => {
      this.vservForm.controls.captcha.updateValueAndValidity();
    }, 10);
  }
  toggleMasking() {
    this.isOtpMasked = !this.isOtpMasked;
  }
  isChecked(event: MatCheckboxChange) {
    this.checked = event.checked;
  }
  RegenOTP() {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.vservForm.controls.otp.setValue('');
    if(this.action == "nri_vserv"){
      this.generateOtpNri();
    }
    else {
    this.generateOtp();
    }
 }
  getLanguages() {
    let reqBody = {
      "language": "Y"
    }
    let apiName = 'getVserveOnlaod';
    if(this.nriVservFlag){
      apiName = 'getVserveNriOnload';
    }
    return this.customLinkService.getVservOnLoad(reqBody,apiName).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if(resp.body && resp.body.finalStatus && resp.body.finalStatus == 'Failure'){
        //   this.languages =[
        //     {
        //         "DD_VALUE": 1,
        //         "DD_NAME": "English"
        //     },
        //     {
        //         "DD_VALUE": 2,
        //         "DD_NAME": "Hindi"
        //     },
        //     {
        //         "DD_VALUE": 3,
        //         "DD_NAME": "Tamil"
        //     },
        //     {
        //         "DD_VALUE": 5,
        //         "DD_NAME": "Punjabi"
        //     },
        //     {
        //         "DD_VALUE": 6,
        //         "DD_NAME": "Urdu"
        //     },
        //     {
        //         "DD_VALUE": 7,
        //         "DD_NAME": "Assamese"
        //     },
        //     {
        //         "DD_VALUE": 8,
        //         "DD_NAME": "Malayalam"
        //     },
        //     {
        //         "DD_VALUE": 9,
        //         "DD_NAME": "Odia"
        //     }
        // ];
          this.msgShowOnload = true;
          this.validateMessage = resp.body.validMessage;
        this.dialog.open(this.panDialog, { width: '500px', panelClass: 'vserv-popup-panel', backdropClass: 'email-voc-popup', disableClose: true });
        }
        else {
          this.languages = resp.body.Languagelist;
        }
        // this.dialog.open(this.panDialog, { width: '500px', panelClass: 'vserv-popup-panel', backdropClass: 'email-voc-popup', disableClose: true });
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  generateOtp() {
    let reqBody = {
      policyno: this.vservForm.controls.policyNo.value,
      dob: moment(this.vservForm.controls.dob.value).format('DD/MM/YYYY'),
      language: this.vservForm.controls.language.value,
      name : this.vservForm.controls.clientName.value
    }
    return this.customLinkService.generateVservOtp(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if(this.isCaptchaValid) {
        this.otpResponse = resp.body;
        this.otpFlag = true;
        this.timerSec = 180;
        this.OTPTimerStart();
        this.maskMobileNo = resp.body.MOBILE;
        let message = "OTP has been sent to your registered mobile number "+this.maskMobileNo+ "";
        this.message.openSnackBar(message, 'xy', ['success-snackbar']);  
        this.vservForm.controls.otp.setValidators([Validators.required,Validators.pattern('^[0-9]{6,6}$')]);
        this.vservForm.updateValueAndValidity();
        }
        else {
          this.vservForm.get('captcha').setErrors({ captchaIncorrect: true })
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  submitForm() {
    if(this.checked == true && this.vservForm.valid && this.isCaptchaValid) {
      this.httpService.init({}).subscribe(resp => {
        this.httpService.initResp = resp;
        if(this.action == "nri_vserv"){
          this.generateOtpNri();
        }
        else {
        this.generateOtp();
        }
    });
    }
    else {
      this.vservForm.get('captcha').setErrors({ captchaIncorrect: true })
    }
  
  }
  SubmitValidateOtp(){
    if(this.action == "nri_vserv"){
      this.verifyOtpNri();
    }
    else {
    this.validateOtp();
    }
  }
  validateOtp() {
    let reqBody ={
      otpEntered: this.vservForm.controls.otp.value,
      trans_id: this.otpResponse.trans_id,
      policyno: this.vservForm.controls.policyNo.value,
      name: this.vservForm.controls.clientName.value,
      language: this.vservForm.controls.language.value,
    }
    return this.customLinkService.validateVservOtp(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.validateResp = resp.body;
        if(this.validateResp.otp_flag =='Y' && this.validateResp.WORKAPPS_FLAG == 'Y') {
          window.open(resp.body.URL, '_self');
        }
        else if(this.validateResp.URL == "" && this.validateResp.message && this.validateResp.WORKAPPS_FLAG == "N") {
          this.message.openSnackBar(this.validateResp.message,'xy', ['error-snackbar']);
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  init() {
    return this.httpService.init(this.httpHeaders);
  }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
      this.hours = ((this.timerSec / 60) | 0);
      // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
      if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
      // if (this.minutes == 60) {this.minutes = 0;} }
      else { this.minutes = this.timerSec; }
      this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  closeDialog(){
    this.dialog.closeAll();
    // this.dialog.open(this.panDialog, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
  }

  generateOtpNri() {
    let userBody: any = {
      policyno: this.vservForm.controls.policyNo.value,
      dob: moment(this.vservForm.controls.dob.value).format('DD/MM/YYYY'),
      srno: '',
      action: this.action,
      activityno: '',
    }
    return this.surrenderService.getSurrenderUserDetails(userBody).subscribe((resp)=> {
      if(this.httpService.isSuccessResponse(resp)) {
        if(resp.body && resp.body.finalStatus && resp.body.finalStatus == 'Failure'){
          this.msgShowOnload = true;
          this.validateMessage = resp.body.validMessage;
        this.dialog.open(this.panDialog, { width: '500px', panelClass: 'vserv-popup-panel', backdropClass: 'email-voc-popup', disableClose: true });
        }
        else {
        this.otpResponse = resp.body;
        this.otpFlag = true;
        this.timerSec = 180;
        this.OTPTimerStart();
        this.maskMobileNo = resp.body.mobilenum;
        let message ="";
          this.maskEmailId = resp.body.Emailid;
          message = "OTP has been sent to your registered mobile number "+ this.maskMobileNo+ " and email id " +this.maskEmailId+" ";
        this.message.openSnackBar(message, 'xy', ['success-snackbar']);  
        this.vservForm.controls.otp.setValidators([Validators.required,Validators.pattern('^[0-9]{6,6}$')]);
        this.vservForm.updateValueAndValidity();
        }
      }
      else {
        this.httpService.apiErrorHandler(resp); 
      }
    }),
    catchError((error) => throwError(error));
  }

  verifyOtpNri() {
   let validateOtpRequest = {
     device_type:this.device.deviceType,
     browser:`${this.device.browser} - ${this.device.browser_version}`,
     os_type:this.device.os,
     otpEntered: this.vservForm.controls.otp.value,
     trans_id: this.otpResponse.trans_id,
     policyno: this.vservForm.controls.policyNo.value,
     action: this.action,
     login_source: "NRI VSERV",
     medium:this.medium,
     login_mode: 'PO',
   }
   return this.surrenderService.validateSurrenderOtp(validateOtpRequest).subscribe((resp) => {
     if(this.httpService.isSuccessResponse(resp)) {
       if (resp.body && this.action) {
         this.validateResp = resp.body;
        if(this.validateResp.OTPVALIDATION =='Y' && this.validateResp.OTPstatus == 'SUCCESS') {
          this.validateNriVserv();
        }
        else {
          this.message.openSnackBar(this.validateResp.message,'xy', ['error-snackbar']);
        }
       }        
     }
     else {
       this.httpService.apiErrorHandler(resp);
     }
   });
  }
  validateNriVserv() {
    let reqBody ={
      dob: moment(this.vservForm.controls.dob.value).format('DD/MM/YYYY'),
      policyno: this.vservForm.controls.policyNo.value,
      name: this.vservForm.controls.clientName.value,
      language: this.vservForm.controls.language.value,
    }
    return this.customLinkService.validateNriVserv(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.validateResp = resp.body;
        if(resp.body && resp.body.finalStatus && resp.body.finalStatus == 'Failure'){
          this.msgShowOnload = true;
          this.validateNriMsg = true;
          this.validateMessage = resp.body.validMessage;
        this.dialog.open(this.panDialog, { width: '500px', panelClass: 'vserv-popup-panel', backdropClass: 'email-voc-popup', disableClose: true });
        }
       else if(this.validateResp.flag == 'Y') {
          window.open(resp.body.redirectUrl, '_self');
        }
        else if(this.validateResp.redirectUrl == "" && this.validateResp.message && this.validateResp.flag == "N") {
          // this.message.openSnackBar(this.validateResp.message,'xy', ['error-snackbar']);
          this.msgShowOnload = true;
          this.validateNriMsg = true;
          this.validateMessage = resp.body.message;
          this.dialog.open(this.panDialog, { width: '500px', panelClass: 'vserv-popup-panel', backdropClass: 'email-voc-popup', disableClose: true });
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
}
