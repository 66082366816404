import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { loginService } from "../../../services/login/login.service";
import { HttpService } from '../../../services/http/http.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { userReqBody } from "./user-data-modal";
import { Utils } from 'src/app/utils/common-functions';
import * as moment from 'moment';
import { instructionSteps } from "../../../utils/app-constants";
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/services/message/message.service';
import { CustomValidators } from 'src/app/utils/custom-validators';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class UserRegistrationComponent implements OnInit {
  timerSec: number = 180;
  createuserDetail: any = {};
  mobileNumber: any;
  email: any;
  interval: any;
  policyNumber = "";
  dob = "";
  instruction = instructionSteps;

  policyNoForm: FormGroup;
  otpForm: FormGroup;
  scrtQtnsForm: FormGroup;
  createPwdForm: FormGroup;

  policyFocus: boolean = false;
  dobFocus: boolean = false;
  otpFocus: boolean = false;
  firstQuesFocus: boolean = false;
  secondQuesFocus: boolean = false;
  firstAnsFocus: boolean = false;
  secondAnsFocus: boolean = false;
  firstFocus: boolean = false;
  secondFocus: boolean = false;
  buttonClick: boolean = false;
  isFirstMasked: boolean = true;
  isSecondMasked: boolean = true;
  isFirstAnsMasked: boolean = true;
  isSecondAnsMasked: boolean = true;
  genOtpResp: any;
  hours: number = 0;
  minutes: number = 0;
  verifyOtpResp: any;
  otpMatch: boolean = false;


  // qtns = [
  //   ['0', 'Select'],
  //   ['1', 'Option 1']
  // ];
  //SecretQtns
  newPasswordType;
  confirmPasswordType;
  firstQuesRkValue;
  secondQuesRkValue;
  secretQuesResp = [];
  userDetails: any = {};
  secretQues: any = [];
  secretQues2: any = [];
  ipaddress = '';
  secretQuesRkValue: any = [];
  passwordChangedResp: any;
  ov_loginid: any;
  MaskmobileNumber: any;
  Maskemail: any;
  statusMessage: string;
  genOtpStatus: any;
  OtpFlag: boolean;
  clientSecureLoginCheck: boolean = false;
  today = new Date();
  httpHeaders = {
    "apiname": "CUST_LOGIN"
  }

  constructor(private fb: FormBuilder, private message: MessageService, private _snackBar: MatSnackBar, private router: Router, public _httpService: HttpService,
    public loginService: loginService, public utils: Utils, private http: HttpClient, public auth: AuthService) { }

  ngOnInit(): void {
    this.newPasswordType = "password";
    this.confirmPasswordType = "password";
    this.policyNoForm = this.fb.group({
      policyNo: ['', Validators.required],
      dob: ['', Validators.required]
    });
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
    });
    this.scrtQtnsForm = this.fb.group({
      firstQtn: ['', [Validators.required]],
      firstAns: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      secondQtn: ['', [Validators.required]],
      secondAns: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
    });
    this.createPwdForm = this.fb.group({
      pwd: ['', [Validators.required, CustomValidators.newPassword]],
      confirmPwd: ['', [Validators.required, CustomValidators.newPassword]],
    });
    this._httpService.init(this.httpHeaders).subscribe((resp) => {
      this._httpService.initResp = resp;
      let reqBody ={}
      this.loginService.masterSecretQues(reqBody)
      .subscribe((resp) => {
        if (resp) {
          this.secretQuesResp = resp.body.SECRET_QUES;
          for (let item of Object.values(this.secretQuesResp)) {
            this.secretQues.push(item[1]);
            this.secretQuesRkValue.push({ "ques": item[1], "quesRk": item[0] });
          }
        }
      });
    }),
    catchError((error) => throwError(error));     
  }
  getclientIP() {
    this.http.get('http://api64.ipify.org/?format=json').subscribe((address: any) => {
      this.ipaddress = address.ip;
    });
  }
  clearError() {
    this.statusMessage = "";
  }
  submitCreatePwdForm() {
    if (this.createPwdForm.controls.pwd.value != this.createPwdForm.controls.confirmPwd.value) {
      this.statusMessage = "New password and Re-enter password do not match"
    }
    else {
      this.createnewPass();
    }
  }
  getCreateuserDetails(stepper: MatStepper) {
    let dateOfbirth = moment(this.policyNoForm.controls.dob.value).format("DD/MM/YYYY");
    let userbody: userReqBody = {
      policyno: this.policyNoForm.controls.policyNo.value,
      dob: dateOfbirth,
      clientIP: this.ipaddress,
    }    
          return this.loginService.getCreateuserDetails(userbody)
            .subscribe((resp) => {
              console.log(resp);
              if (resp && resp.head && resp.head.status === "success") {
                //if(!resp.body.suberrormsg){
                this.createuserDetail = resp.body;
                this.mobileNumber = this.createuserDetail.mobileNo;
                this.email = this.createuserDetail.emailID;
                if (this.createuserDetail.maskMobileNo != null) {
                  this.MaskmobileNumber = "mobile number " + this.createuserDetail.maskMobileNo;
                }
                else {
                  this.MaskmobileNumber = "";
                }
                if (this.createuserDetail.maskEmailID != null) {
                  if (this.MaskmobileNumber == null) {
                    this.Maskemail = "email id " + this.createuserDetail.maskEmailID;
                  }
                  else { this.Maskemail = "and email id " + this.createuserDetail.maskEmailID; }
                }
                else {
                  this.Maskemail = "";
                }
                // this.getLoginOtp(stepper);
                this.genOtp(stepper);
                this.statusMessage = "";
                stepper.next();
              }
              else {
                this.statusMessage = resp.head.errordetails.message;
              }
            }),
            catchError((error) => throwError(error));
  }
  genOtp(stepper: MatStepper) {

    let genOtpReq = {
      "cp_login_mode": 'CO',
      "optradio": 'clientID',
      cp_loginid: this.createuserDetail.clientID,
      "cp_loginidEn": "",
      "otpEntered": "",
      "cp_password": ""
    }
    return this.loginService.genRegOtp(genOtpReq)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.genOtpStatus = response.head.status;
          this.genOtpResp = response.body;
          this.OtpFlag = true
          this.OTPTimerStart();
        }
        else {
          this.statusMessage = "Unable to send OTP. Please try again";
          stepper.previous();
        }
      }),
      catchError((error) => throwError(error))
  }
  RegenOTP(stepper: MatStepper) {
    clearInterval(this.interval);
    this.timerSec = 180;
    this.genOtp(stepper);
    this.statusMessage = "";
  }
  verifyOtp(stepper: MatStepper) {
    let validateOtpReq = {
      optradio: 'mobileNo',
      trans_id: this.genOtpResp.trans_id,
      otpEntered: this.otpForm.controls.otp.value
    }
    this.ov_loginid = this.createuserDetail.mobileNo;
    return this.loginService.validateRegOtp(validateOtpReq, this.ov_loginid)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.verifyOtpResp = response;
          stepper.next();
          this.statusMessage = "";
        }
        else {
          this.statusMessage = response.head.errordetails.message;
          // this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
        }
      }),
      catchError((error) => throwError(error))
  }
  get policyValidation() { return this.policyNoForm.controls; }
  get otpValidation() { return this.otpForm.controls; }
  get qtnValidation() { return this.scrtQtnsForm.controls; }
  get createPwdValidation() { return this.createPwdForm.controls; }
  OTPTimerStart() {
    this.interval = setInterval(() => {
      if (this.timerSec > 0) {
        this.hours = ((this.timerSec / 60) | 0);
        // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
        if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
        // if (this.minutes == 60) {this.minutes = 0;} }
        else { this.minutes = this.timerSec; }
        this.timerSec--;
      }
      else {
        this.hours = 0;
        this.minutes = 0;
      }
    }, 1000)
  }
  validateOtp(stepper: MatStepper) {
    this.buttonClick = true;
    if (this.otpForm.valid) {
      this.verifyOtp(stepper);
    }
  }
  numberMasking(mobileNumber) {
    var mask = "";
    if (mobileNumber) {
      for (let i = 1; i <= mobileNumber.length - 4; i++) {
        mask += "*";
      }
      return mobileNumber.slice(0, 2) + mask + mobileNumber.slice(8, 10);
    }
    else {
      return null;
    }
  }
  emailMasking(email) {
    if (email) {
      email = email.split("");
      let email_array: any = [];
      let length = email.indexOf('@');
      email.forEach((item: any, pos: any) => {
        (pos >= 2 && pos <= length - 1) ? email_array.push('*') : email_array.push(email[pos]);
      });
      return email_array.join("");
    }
    else {
      return null;
    }
  }
  redirectToLogin() {
    this.router.navigate(['/']);
  }
  submitSecretQForm(stepper: MatStepper) {
    if (this.scrtQtnsForm.valid) {
      this.userDetails = this.createuserDetail;
      for (let element of this.secretQuesRkValue) {
        if (element.ques == this.scrtQtnsForm.controls.firstQtn.value) {
          this.firstQuesRkValue = element.quesRk;
        }
        if (element.ques == this.scrtQtnsForm.controls.secondQtn.value) {
          this.secondQuesRkValue = element.quesRk;
        }
      }
      let submitSecretQuesReqBody: any = {
        user_rk: this.createuserDetail.userRk,
        "client_id": this.createuserDetail.clientID,
        "registrationMode": this.createuserDetail.registrationMode,
        "firstQuesRK": this.firstQuesRkValue,
        "cpfirstans": this.scrtQtnsForm.controls.firstAns.value,
        "secondQuesRK": this.secondQuesRkValue,
        "cpsecondans": this.scrtQtnsForm.controls.secondAns.value
      }
      this.loginService.submitRegSecretQues(submitSecretQuesReqBody)
        .subscribe((resp) => {
          this.statusMessage = "";
          stepper.next();
          if (resp && resp.body == "Secret Answers updated successfully") {
          }
        });
    }
  }
  createnewPass() {
    let setpasswordReq = {
      createpassword: this.createPwdForm.controls.pwd.value,
      confirmpassword: this.createPwdForm.controls.confirmPwd.value,
      userRk: this.createuserDetail.userRk,
    }
    return this.loginService.SetNewPassword(setpasswordReq)
      .subscribe((response) => {
        if (response && response.body.message == "Password successfully updated") {
          this.passwordChangedResp = response.body;
          this.statusMessage = "Registration completed successfully"
          this.message.openSnackBar(this.statusMessage, 'xy', ['success-snackbar']);
          this.auth.logout();
          this.router.navigate(['/']);
        }
        else {
          this.statusMessage = response.body.suberrormsg;
          // this.message.openSnackBar(this.statusMessage, 'xy', ['error-snackbar']);
        }
      }),
      catchError((error) => throwError(error));
  }
  policyFocusOut() {
    this.policyFocus = true;
  }
  dobFocusOut() {
    this.dobFocus = true;
  }
  otpFocusOut() {
    this.otpFocus = true;
  }
  firstQtnFocusOut() {
    this.firstQuesFocus = true;
  }
  secondQtnFocusOut() {
    this.secondQuesFocus = true;
  }
  firstAnsFocusOut() {
    this.firstAnsFocus = true;
  }
  secondAnsFocusOut() {
    this.secondAnsFocus = true;
  }
  firstFocusOut() {
    this.firstFocus = true;
  }
  secondFocusOut() {
    this.secondFocus = true;
  }
  toggleFirstAns(img, input) {
    this.isFirstAnsMasked = !this.isFirstAnsMasked;
    if (this.isFirstAnsMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecAns(img, input) {
    this.isSecondAnsMasked = !this.isSecondAnsMasked;
    if (this.isSecondAnsMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleFirstInputType(img, input) {
    this.isFirstMasked = !this.isFirstMasked;
    if (this.isFirstMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecInputType(img, input) {
    this.isSecondMasked = !this.isSecondMasked;
    if (this.isSecondMasked) {
      input.type = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      input.type = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleFirstInputAnsType(img) {
    this.isFirstMasked = !this.isFirstMasked;
    if (this.isFirstMasked) {
      this.newPasswordType = 'password';
      img.src = 'assets/eye_closed.svg';
    }
    else {
      this.newPasswordType = 'text';
      img.src = 'assets/eye.png';
    }
  }
  toggleSecInputAnsType(img) {
    this.isSecondMasked = !this.isSecondMasked;
    if (this.isSecondMasked) {
      this.confirmPasswordType = 'password';
      img.src = 'assets/eye_closed.svg';
    } else {
      this.confirmPasswordType = 'text';
      img.src = 'assets/eye.png';
    }
  }
  showKeyboard(event: any) {
    this.clientSecureLoginCheck = (event.checked);
  }
  populateSecondQtn(event) {
    this.secretQues2 = [];
    this.scrtQtnsForm.controls.firstAns.reset();
    this.scrtQtnsForm.controls.secondAns.reset();
    this.scrtQtnsForm.controls.secondQtn.reset();
    for (let item of this.secretQues) {
      if (item != event.value) {
        this.secretQues2.push(item);
      }
    }
  }
  submitPolicyNoForm() { }
  submitOtpForm() { }
  submitScrtQtnsForm() { }
  submitPwdForm() {
    //this.router.navigate(['/login']);
  }

  SecretQues = [
    { ques: "Who is your childhood hero?" },
    { ques: "What is your spouse's name?" },
    { ques: "What is your mother's maiden name?" },
    { ques: "What is your city of birth?" },
    { ques: "What is your nickname?" },
    { ques: "What is your pet's name?" },
  ];

  secretQuestionsInstructions = [
    { instruction: "Please answer all questions without which you won't be able to proceed further." },
    { instruction: "Answer should be minimum three (3) and maximum twenty five (25) characters long." },
    { instruction: "The answers will be saved for future use in case you forget your password." },
    { instruction: "Space is not allowed at the beginning or at the end of the answer." },
    { instruction: "Answers are not case sensitive." }
  ];
  
  blockPaste(e) { e.preventDefault() }

}

