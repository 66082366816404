<div class="virtual-branch-page">
    <div *ngIf="!showIframe" class="form-div flex">
        <div class="background">
            <form [formGroup]="virtualBranchForm" (ngSubmit)="onSubmit()">
                <div class="form-field-div">
                    <mat-form-field>
                        <mat-label>Client Id</mat-label>
                        <input matInput type="text" class="form-field-input" formControlName="clientid">
                    </mat-form-field>
                </div>
                <div class="form-field-div">
                    <mat-form-field>
                        <mat-label>Policy No</mat-label>
                        <input matInput type="text" class="form-field-input" formControlName="policyno">
                    </mat-form-field>
                </div>
                <div class="form-field-div">
                    <mat-form-field>
                        <mat-label>Action</mat-label>
                        <mat-select panelClass="update-country" formControlName="action">
                            <mat-option *ngFor="let item of actionDropDown" [value]="item.code">{{item.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex button-div">
                    <button type="submit" class="submit-button">Submit</button>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="showIframe" class="flex container">
        <iframe class="margin" [src]="iframeURL" frameborder="1"></iframe>
    </div>
</div>