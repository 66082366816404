<div class="search-popup">
    <div mat-dialog-actions (click)="closeDialog()">
        <div mat-dialog-close class="close-dialog no-mobile" [ngClass]="{'hni-close-dialog':isHNIUser}">
            Close<img src="assets/close.svg">
        </div>
    </div>
    <div>
        <div class="search-popup-header flex" [ngClass]="{'hni-popups-header':isHNIUser}">What would you like to search?</div>
        <div class="popup-content popup-content-height">
            <div class="trending-section">
                <div class="trending-heading">Trending Searches</div>
                <div class="trending-buttons-div flex">
                    <ng-container *ngFor="let item of trending">
                        <button *ngIf="item.priority" matButton class="trending-button" (click)="onTrendingClick(item)">{{item.label}}</button>
                    </ng-container> 
                </div>                               
            </div>
            <form class="form-section" [formGroup]="searchForm" (ngSubmit)="submitForm()" autocomplete="off">
                <div class="form-field-section flex">
                    <div class="form-field-div">
                        <div class="form-label">Search Keywords <span class="error-two">*</span></div>
                        <mat-form-field floatLabel="never" class="search-form-field text-field" [ngClass]="{'shorten': showPlaceholder}" (focusin)="onActionFocusIn()" (focusout)="onActionFocusOut()">
                            <div *ngIf="showPlaceholder" class="search-placeholder-window">
                                <div class="search-placeholder rotation">
                                    <ng-container *ngFor="let placeholder of placeholders">
                                        <div>{{placeholder}}</div>
                                    </ng-container>                                    
                                </div> 
                            </div>   
                            <input type="text" matInput [formControl]="actionCtrl" [matAutocomplete]="selectAction">
                            <mat-autocomplete #selectAction="matAutocomplete" [displayWith]="displayValue"
                                (optionSelected)="onActionClicked($event)">
                                <mat-option *ngFor="let item of filteredActions | async" [value]="item">
                                    {{item.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <button *ngIf="isSearchEnabled" matSuffix class="search-button" type="button" [disabled]="!isSearchEnabled" (click)="insertNewSearchQuery()">Search</button>
                        </mat-form-field>
                    </div>
                    <div class="form-field-div" *ngIf="showPolicyField">
                        <div class="form-label">Policy Number <span class="error-two">*</span></div>
                        <mat-form-field floatLabel="never" class="search-form-field select-field">                            
                            <mat-select disableRipple disableOptionCentering class="policy-select"
                                [formControl]="policyCtrl" panelClass="policy-select-panel">
                                <mat-option *ngFor="let item of filteredPolicies" [value]="item.POLICYNO">
                                    {{item.POLICYNO}}
                                </mat-option>
                            </mat-select>                            
                        </mat-form-field>
                    </div>
                </div>
                <div class="button-div flex">                    
                    <button *ngIf="!isSearchEnabled || !actionCtrl.value" matButton class="save-button" type="submit" [disabled]="!searchForm.valid">Go</button>
                </div>
            </form>
        </div>
    </div>
</div>