import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { HttpService } from 'src/app/services/http/http.service';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from 'src/app/services/message/message.service';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { Router } from '@angular/router';
import { CameraComponent } from '../camera/camera.component';
import { TOUCHPOINT } from 'src/app/utils/app-constants';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PayoutComponent } from 'src/app/modules/shared/components/payout/payout.component';
import { MyPoliciesDialogComponent } from 'src/app/modules/my-policies/my-policies-dialog/my-policies-dialog.component';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { serviceURL } from 'src/environments/environment';
import { UpdateMailingAddressComponent } from 'src/app/modules/my-account/update-mailing-address/update-mailing-address.component';
import { MyAccountComponent } from 'src/app/modules/my-account/my-account/my-account.component';
import * as moment from 'moment';
import tippy from 'tippy.js';

@Component({
  selector: 'app-doc-list-popup',
  templateUrl: './doc-list-popup.component.html',
  styleUrls: ['./doc-list-popup.component.css'],
  providers: [PayoutComponent,MyAccountComponent]
})
export class DocListPopupComponent implements OnInit {
  docTypeDropdown: FormControl;
  docListPopupForm: FormGroup;
  docRequirementlist: Array<any> = [];
  docList: Array<any> = [];
  document: Array<any> = []; 
  showForm: boolean = false;
  showUploadBox: boolean = false;
  showRequirementList: boolean = true;
  docTypeFocusOut: boolean = false;
  attributeList: Array<any> = [];
  requirementDtls: any = {};
  requirementDtlsList: Array<any> = [];
  isSubmitSuccess: Array<boolean> = [];
  selectedReqmntIndex: number;
  uploadedFileList: Array<any> = [];
  byteArray: Array<any> = [];
  fileFormats: Array<string> = ['jpeg', 'jpg', 'pdf', 'png', 'bmp'];
  maxFileSize: number = 2;
  maxNumOfFiles: number = 5;
  isHNIUSer: boolean = false;
  nsdlValidation: boolean = false;
  showPaymentBtn: boolean = false;
  isPaymentPending: boolean = false;
  editPanErrMsg: string = '';
  editPanSuccMsg: string = '';
  panAadharLinkErrMsg: string = '';
  panAadharLinkSuccMsg: string = '';
  panAadharLinkCheckMsg: string = '';
  isPanValid: boolean = false;
  userDetails: any = {};
  optionalFields: Array<string> = ['MNAME', 'MIDDLE_NM_PAN_ACK', 'MIDDLE_NM_FRM60','MIDDLE_NM_DECLARATION'];
  isUploadSuccess: Array<boolean> = [];
  requirementSliceArray: any = [];
  selectedArray: any;
  finishFlag: boolean = false;
  payoutDtls:any;
  payeeDtls: any;
  linkId: any;
  srTypeArray: any = [];
  cameraDialogRef: MatDialogRef<CameraComponent> = null;
  imageArray: Array<any> = [];
  docArray: Array<any> = [];
  requiremntMsg: String;
  isInstaServ: boolean = false;
  checklist: Array<string> = [];
  @ViewChild('checklistPopup') checklistPopup: TemplateRef<any>;
  @ViewChild('srSubmitpopup') srSubmitpopup : TemplateRef<any>;
  checklistPopupRef: MatDialogRef<any, any>;
  requirementsResp: Array<any> = [];
  showCamera: boolean = false;
  digiConsentReqCode: string = "CSPS_REQ_001";
  digiConsentDocCode: string = "DOC_589";
  inLieuPANDocCode: string = "DOC_476";
  nrebankStatementCode : string = "DOC_077";
  aadhaarCardDocCode: string = "DOC_018";
  subId: string;
  isClickToUpload: boolean = false;
  isDigitalConsent: boolean = false;
  osvControl: FormControl;

  showconsent : boolean = false;
  terms : FormGroup;
  accepted: boolean;
  adhaarConsent: string;
  instaServDropdown : FormControl;
  @ViewChild('physicalConsentAlert') physicalConsentAlert: TemplateRef<any>;
  physicalConsentAlertRef : MatDialogRef<any>;
  @ViewChild('physicalConsentConfirmAlert') physicalConsentConfirmAlert : TemplateRef<any>;
  physicalConsentConfirmAlertRef : MatDialogRef<any>;
  @ViewChild('consentAlert') consentAlert : TemplateRef<any>;
  consentAlertRef : MatDialogRef<any>;

  osvOpts: Array<any> = [
    { code: 'Original is seen and verified by me.',
      label: 'Original is seen and verified by me'
    },
    { code: "Original is seen and verified by Sales person who's agent code verified by me.",
      label: "Original is seen and verified by Sales person who's agent code verified by me"
    },
  ]
  incomeProofDocCode : string = "CSPS_REQ_319"
  incomeProofDocument = serviceURL.incomeProofDocuments

  showDigitalInfo: boolean = false;
  showAlert: boolean = false;
  resend_interval: any;
  refresh_interval : any;
  resend_timer: number;
  refresh_timer : number;
  disableResend: boolean = true;
  disableRefresh: boolean = true;
  count: number = 0;
  exhausted: boolean = false;
  isPhysicalEnabled: boolean = false;
  showInstaServConsent: boolean = false;
  consentAccepted: boolean = false;
  maskedDetails: any = [];
  consentErrorMsg: any;
  submittedSrNo: any;
  NeftBtn: boolean = false;
  PanBtn: boolean = false;
  KycBtn: boolean = false;
  termsAndCnds: boolean = false;
  instaSuccess : boolean = true;
  timerSec: number = 60;
  hours: number;
  minutes: number;
  consentMode = '';
  templateHeader: string = "";
  templateMsg: string = "";
  srchecklist: any;
  isChkLstEmty: boolean = true;

  get getAccept(){
    return this.terms.get('accept').value;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data, public createSrService: CreateSRService,public router: Router,
  public dialog: MatDialog, public httpService: HttpService,public myPoliciesService: MyPoliciesService,public message: MessageService, public utils: Utils, public dialogRef: MatDialogRef<DocListPopupComponent>, public dataService: DataService,
  public customLinkService: CustomLinkService, private loader: LoaderService, public auth: AuthService, public payout: PayoutComponent, public fb : FormBuilder, public myAccountService : MyAccountService, public crypto : EncryptDecryptService, public myAccount: MyAccountComponent) { }

  ngOnInit(): void {
    this.terms = this.fb.group({
      'accept' : []
    })
    this.dialogRef.disableClose = true;
    this.docTypeDropdown = new FormControl('', [Validators.required]);
    this.osvControl = new FormControl('', [Validators.required]);
    this.instaServDropdown = new FormControl('Digital consent',[Validators.required]);
    this.isInstaServ = this.data.source == "INSTA_SERV" ? true : false;
    this.isClickToUpload = this.data.source == "CLICK2UPLOAD" ? true : false;
    this.isDigitalConsent = this.data.source == "DIGITAL_CONSENT" ? true : false;
    this.payoutDtls = this.myPoliciesService.getPayoutdetails()
    this.payeeDtls = this.myPoliciesService.getPayeedetails()
    this.linkId = this.payeeDtls.X_SR_LINK_ID
    if (this.isInstaServ) {
      this.maxNumOfFiles = 10;
      this.maxFileSize = 6;
    }
    this.setIsHNIUser();
    if(this.data.source == 'agentDocUpload') {
      this.getAgentDocList();
    }
    else {
    this.getRequirementDetails();
    }

    this.resend_timer = 0;
    this.refresh_timer = 0;
    this.timerSec = 60;
  }

  OnDocTypeFocusOut() {
    this.docTypeFocusOut = true;
  }
  get uploadFormControls() {
    return this.docListPopupForm.controls;
  }
  setIsHNIUser() {
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
  }
  async getRequirementDetails() {
    let srnum = [];
    if((this.data.submitSRReqDtls) || ((this.data.id != "maturity")&&(this.data.id != "moneyBack")&&(this.data.id != "annuityPayout")&&(this.data.id != "surrender"))){
      srnum.push(this.data.srno)
    } else{
      srnum = this.data.srno;
    }
    for (let i = 0; i < srnum.length; i++) {
    let reqBody = {
      "srno": srnum[i]
    }
    await new Promise(resolve => {
    return this.createSrService.getRequirementDetails(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.getrequirementdetails) {
          this.createSrService.setSrPolicyStatus(resp.body.polciyDetails?resp.body.polciyDetails.POLICY_STATUS:'');
          if (this.isDigitalConsent) {
            let digitalConsentReq = resp.body.getrequirementdetails.find(i => i.reqcd == this.digiConsentReqCode);
            if (digitalConsentReq) {
              this.requirementsResp.push(digitalConsentReq);
            }
            if (this.requirementsResp.length == 0) {
              this.requiremntMsg = "No Records Found"
              return
            }
          } else {
            this.requirementsResp = resp.body.getrequirementdetails;
          }
          for (let i of this.requirementsResp) {
            if (i.subissuecd == "SUB_ACT_REVIVAL") {
              if (i.reqtype == "6911") {
                this.requirementDtlsList.push(i);
              }
            }
            else {
              this.requirementDtlsList.push(i);
            }
          }
          this.requirementSliceArray.push([this.requirementDtlsList.length - this.requirementsResp.length,this.requirementDtlsList.length - 1])
          this.requirementsResp.forEach((item) => this.isSubmitSuccess.push(false));
        }
        if (resp.body && resp.body["Payment requirement"] && resp.body["Payment requirement"].status == "Requirement Raised" && !this.isClickToUpload) {
          this.isPaymentPending = true;
        }
      } else {
        if (!this.data.disableClose) {
          this.dialogRef.close();
        }
        this.httpService.apiErrorHandler(resp);
      }
      resolve(true)
    }, () => resolve(false));
      // catchError((error) => throwError(error));
  })
}
if(this.requirementDtlsList.length == 0 && this.data.submitSRReqDtls && !this.isPaymentPending && (this.data.id != "maturity") && (this.data.id != "moneyBack")){
  this.requiremntMsg = "No Records Found"
  if (!this.data.disableSubmitSR && !(this.data.submitSRReqDtls.subactivity == "SURR_PROC")) {
    this.submitSR();
  } else if(((this.data.submitSRReqDtls.subactivity == "SURR_PROC" && !this.data.uploadFlag) || this.data.submitSRReqDtls.relSrSubActivity.includes("SURR_PROC")) && (!(this.data.submitSRReqDtls.relSrStatus.includes("Pending Requirements")) || !(this.data.submitSRReqDtls.relSrStatus.includes("Cancelled")))) {
    this.surrenderSubmit();
  }
}
 else if(this.requirementDtlsList.length == 0 && (this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")){
  if(this.data.uploadDocFlag){
    this.requiremntMsg = "No Records Found"
   } else{
  this.requiremntMsg = "No Documents are required for SR No :" + this.data.claimNo
   this.finishFlag = true;
   }
 } else if (this.requirementDtlsList.length == 0 && this.isPaymentPending) {
  this.requiremntMsg = "No Documents are required for SR No : " + this.data.srno
  this.showPaymentBtn = true;
 } else if(this.requirementDtlsList.length == 0 && (this.data.id == "surrender" && !this.data.uploadFlag)){
   this.requiremntMsg = "No Records Found";
  this.surrenderSubmit()
 }
 else {
  this.requiremntMsg = "No Records Found"
 }
 if (this.isDigitalConsent && this.requirementDtlsList.length > 0) {
  this.onUploadClick(0);
}
  }
  onUploadClick(index: number) {
    this.requirementDtls = this.requirementDtlsList[index];
    this.selectedReqmntIndex = index;
    this.trackUserActions(this.requirementDtls);
    for(let array of this.requirementSliceArray){
      if((this.selectedReqmntIndex <= array[1]) && (this.selectedReqmntIndex >= array[0])){
        this.selectedArray = array
      }
    }
    this.uploadedFileList = [];
    this.byteArray = [];
    this.isUploadSuccess = [];
    this.attributeList = [];
    this.docListPopupForm = null;
    this.docTypeDropdown.setValue('');
    this.osvControl.setValue('');
    this.showCamera = false;
    if(this.requirementDtls.reqcd == "CSPS_REQ_505"){
      this.router.navigate(['/my-policies','covid']);
      this.createSrService.setSrDtlsResp(this.requirementDtls);
      this.dialog.closeAll();
   }
   else if (this.requirementDtls.reqcd == "CSPS_REQ_332"){
    this.router.navigate(['/my-policies','phs']);
    this.createSrService.setSrDtlsResp(this.requirementDtls);
    this.dialog.closeAll();
 }
 else if (this.requirementDtls.reqcd == "CSPS_REQ_366"){
  this.router.navigate(['/my-policies','smq']);
  this.createSrService.setSrDtlsResp(this.requirementDtls);
  this.dialog.closeAll();
}
else if (this.requirementDtls.reqcd == "CSPS_REQ_345"){
  this.router.navigate(['/my-policies','short-phs']);
  this.createSrService.setSrDtlsResp(this.requirementDtls);
  this.dialog.closeAll();
}
else if (this.requirementDtls.reqcd == "CSPS_REQ_003"){
  this.dialog.closeAll();
  this.router.navigate(['/my-policies','nri']);
  // this.requirementDtls.push(this.data);
  let data=[];
  data.push(this.requirementDtls);
  data.push(this.data)
  this.createSrService.setSrDtlsResp(data);
  let srInputParams = [{"srNum": this.requirementsResp[0].srno, "srType" : 'CHNG_ADDRESS', "docList":this.data}];
  this.createSrService.setSRTypeResp(srInputParams);
}
else if (this.isClickToUpload) {
  this.getDocListMasterData(this.requirementDtls);
  return
}

/* else if (this.requirementDtls.reqcd == this.digiConsentReqCode && this.isDigitalConsent) {
  this.docList = [[this.digiConsentDocCode, 'Digital Consent']]
  this.showRequirementList = false;
  if (this.isDigitalConsent) {
    this.docTypeDropdown.setValue(this.digiConsentDocCode);
    this.docTypeDropdown.disable(); 
    this.showCamera = true;
    this.showUploadBox = true;
    this.openCamera();           
  }
} */
else {
  if((this.data.source? (this.data.source == "agentDocUpload" && this.requirementsResp ? this.requirementsResp.includes("SURR_PROC") : "") : this.data.id == "surrender" || this.data.surrenderFlag) || this.data.useAgentDocsMaster){
   this.getSurrenderDocListMasterData(this.requirementDtls);  
  } else {
  this.getDocListMasterData(this.requirementDtls);}
}  
}
getSurrenderDocListMasterData(requirementObj: any){
  return this.createSrService.getSurrDocumentListMasterData(requirementObj.reqcd, requirementObj.subissuecd, requirementObj.lobcd, requirementObj.typeofchngcd).subscribe(resp => {
    if (this.httpService.isSuccessResponse(resp)) {
      const docListKey: string = requirementObj.typeofchngcd ? `REQ_DOC_ALT--${requirementObj.reqcd}@@${requirementObj.subissuecd}@@${requirementObj.lobcd}@@${requirementObj.typeofchngcd}` : `REQ_DOC_ALT--${requirementObj.reqcd}@@${requirementObj.subissuecd}@@${requirementObj.lobcd}@@`;
      if (resp.body && resp.body[docListKey]) {
        this.docRequirementlist = Object.values(resp.body[docListKey]);
        this.document = []
        for(let i of this.docRequirementlist){
          this.document.push(i)
        }
        let arr =[];
        this.docList = [];
        for(let j of this.document){
          if(!arr.includes(j[0])){
            if (!((requirementObj.subissuecd == "CHNG_CON_NUM" ||this.data.source == "agentDocUpload"/*  || this.isInstaServ */) && j[0] == this.digiConsentDocCode)) {
              this.docList.push(j)
              arr.push(j[0]);
            }              
          }
        }
                
        this.showRequirementList = false;
      }
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  }),
    catchError((error) => throwError(error));
}
  getDocListMasterData(requirementObj: any) {
    return this.createSrService.getDocumentListMasterData(requirementObj.reqcd, requirementObj.subissuecd, requirementObj.lobcd, requirementObj.clientrolecd, requirementObj.typeofchngcd).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        const docListKey: string = requirementObj.typeofchngcd ? `REQ_DOC--${requirementObj.reqcd}@@${requirementObj.subissuecd}@@${requirementObj.lobcd}@@${requirementObj.clientrolecd}@@${requirementObj.typeofchngcd}` : `REQ_DOC--${requirementObj.reqcd}@@${requirementObj.subissuecd}@@${requirementObj.lobcd}@@${requirementObj.clientrolecd}@@`;        
        if (resp.body && resp.body[docListKey]) {
          this.docRequirementlist = Object.values(resp.body[docListKey]);
          this.document = []
          for(let i of this.docRequirementlist){
            this.document.push(i)
          }
          let arr =[];
          this.docList = [];
          for(let j of this.document){
            if(!arr.includes(j[0])){
              if (!((requirementObj.subissuecd == "CHNG_CON_NUM" ||this.data.source == "agentDocUpload"/*  || this.isInstaServ */) && j[0] == this.digiConsentDocCode)) {
                this.docList.push(j)
                arr.push(j[0]);
              } 
            }
          }
                  
          this.showRequirementList = false;
          if (this.isDigitalConsent) {
            this.docTypeDropdown.setValue(this.digiConsentDocCode);
            this.docTypeDropdown.disable(); 
            this.showCamera = true;
            this.showUploadBox = true;
            this.openCamera();           
          }
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  onDocSelect(docCode: string) {
    this.uploadedFileList = [];
    this.byteArray = [];
    this.attributeList = [];
    this.isUploadSuccess = [];
    this.docListPopupForm = null;
    this.showForm = false;
    this.showUploadBox = false;
    this.showCamera = false;
    this.isPanValid = false;
    this.editPanErrMsg = '';
    this.editPanSuccMsg = '';
    this.panAadharLinkErrMsg = '';
    this.panAadharLinkSuccMsg = '';
    this.panAadharLinkCheckMsg = '';
    this.osvControl.setValue('');   
    if (this.requirementDtls.subissuecd == "TOPUP" && docCode == "DOC_025") {            
      this.docListPopupForm = new FormGroup({
        "panNo": new FormControl('', [CustomValidators.panNo]),
        "panName": new FormControl('', [CustomValidators.clientName, Validators.required]),
        "panDOB": new FormControl('', [CustomValidators.noFutureDates])
      });
      this.docListPopupForm.controls.panNo.valueChanges.pipe(distinctUntilChanged()).subscribe(s => { if (s) { this.docListPopupForm.controls.panNo.patchValue(s.toUpperCase()) }});
      this.myAccountService.getPersonalDtls({ clientid: this.utils.getClientId() }).subscribe(resp => {
        if (resp && resp.body && resp.body.length > 0 && resp.body[0] && resp.body[0]['DOB']) {
          this.data['DOB'] = resp.body[0]['DOB'];
        }
        if (resp && resp.body && resp.body.length > 0 && resp.body[0] && resp.body[0]['PAN_NUMBER']) {
          this.docListPopupForm.controls.panNo.setValue(resp.body[0]['PAN_NUMBER']);
        }
        if (this.data.DOB && this.data.DOB != 'NA') {
          let dob = moment(this.data.DOB, 'DD/MM/YYYY');
          this.docListPopupForm.controls.panDOB.setValue(dob);
        } else {
          this.docListPopupForm.controls.panDOB.setValue('');
          setTimeout(() => tippy(".tooltip-popover-pan-dob", { content: "In case of corporate entities, please enter Date of Incorporation", theme: "light-border-pan" }), 500);      
        }
      });
      this.nsdlValidation = true;
      this.showForm = true; 
      this.checklist = [];
      this.isChkLstEmty = true;     
    } else {
      this.nsdlValidation = false;
      if (this.requirementDtls.reqcd == this.digiConsentReqCode && docCode == this.digiConsentDocCode) {
        this.showCamera = true;
        this.showUploadBox = true;
      } else {
        this.getDocAttrMasterData(docCode);
      }
    }

    if(this.isInstaServ && this.isPhysicalEnabled){
      this.isPhysicalEnabled = false;
      this.instaServDropdown.setValue('Digital consent');
    }

    if(docCode == this.aadhaarCardDocCode){
      let reqBody = {
        "clientId" : this.crypto.encryptJson(this.requirementDtls.clientid),
        "srNo" : this.crypto.encryptJson(this.requirementDtls.srno),
        "role" : this.isInstaServ ? this.crypto.encryptJson(this.requirementDtls.clientrolecd) : undefined
      }
      return this.myAccountService.getAadhaarConsentFlag(reqBody).subscribe((resp) => {
        if(this.httpService.isSuccessResponse(resp)) {
          if(resp.body && resp.body.aadhaarConsentFlag){
            this.showconsent = (this.crypto.decryptString(resp.body.aadhaarConsentFlag) == "Y")? true : false; 
            console.log(this.crypto.decryptString(resp.body.aadhaarConsentFlag))
            if(this.showconsent){
              if(this.isInstaServ){
                this.showInstaServConsent = true;
                this.termsAndCnds = false;
              } else{
                this.showInstaServConsent = false;
                this.termsAndCnds = true;
              }
            } else {
              this.showInstaServConsent = false;
              this.termsAndCnds = false;
            }
          }
          if(resp.body && resp.body.aadhaarConsentMode){
            this.consentMode = this.crypto.decryptString(resp.body.aadhaarConsentMode);
          }
        } else {
          this.httpService.apiErrorHandler(resp);
        }
      }), catchError((error) => throwError(error))
    }
  }
  getDocAttrMasterData(docCode: string) {
    return this.createSrService.getAttributesMasterData(this.requirementDtls.reqcd, docCode).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        const attrListRespKey = `REQ_DOC_ATTRIBUTE--${this.requirementDtls.reqcd}@@${docCode}`;
        if (resp.body && resp.body[attrListRespKey]) {
          this.docListPopupForm = this.generateForm(resp.body[attrListRespKey]);
          this.showForm = true;
          this.showUploadBox = true;
        } else {
          this.showUploadBox = true;
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  generateForm(attributeMasterData: any) {
    this.attributeList = [];
    const group: any = {};
    for (let key of Object.keys(attributeMasterData)) {
      const attrData: Array<any> = attributeMasterData[key];
      this.attributeList.push({
        key: attrData[0],
        label: attrData[5][0],
        type: attrData[5][1],
        attr_code: attrData[5][2],
        isOptional: this.optionalFields.includes(attrData[5][2])
      });
    }
    for (let item of this.attributeList) {
      group[item.key] = new FormControl('', this.utils.getValidator(item.attr_code));
    }
    return new FormGroup(group);
  }
  onDocumentLoad(files) {
    if (this.validateFiles(files)) {
      if (this.isInstaServ) {
        this.loader.show();
        this.uploadedFileList.push(...files);
        this.byteArrayGenerator(files).then(result => {
          this.byteArray.push(...result);
          this.loader.hide();
        }, () => this.loader.hide());
      } else {
        this.uploadedFileList = files;
        this.byteArrayGenerator(this.uploadedFileList).then(result => {
          this.byteArray = result;
          if (this.isDigitalConsent) {
            this.submitUploadDocForm()
          }
        });
      }
    }
  }
  validateFiles(files) {
    let filesExceeded: boolean;
    if (this.isInstaServ) {
      filesExceeded = (this.uploadedFileList.length + files.length) > this.maxNumOfFiles;
    } else {
      filesExceeded = files.length > this.maxNumOfFiles;
    } 
    if (filesExceeded) {
            let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"VE"};
      this.message.openSnackBar(`Maximum of ${this.maxNumOfFiles} files can be uploaded for a requirement`, 'xy', ['error-snackbar'],trackMsgObj);
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      const filename = files[i].name;
      const filetype = files[i].type ? files[i].type.split('/')[1].toLowerCase() : '';
      const filesize = files[i].size / 1024 / 1024;
      if (!this.fileFormats.includes(filetype)) {
        let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"VE"};
        this.message.openSnackBar(`${filename} has a wrong file format`, 'xy', ['error-snackbar'], trackMsgObj);
        return false;
      } else if (filesize > this.maxFileSize) {
        let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"VE"};
        this.message.openSnackBar(`${filename} exceeds the maximum file size`, 'xy', ['error-snackbar'], trackMsgObj);
        return false;
      }
    }
    return true;
  }
  onSubmit() {
    if (this.isInstaServ) {
      if(this.showInstaServConsent && this.instaServDropdown.value == 'Digital consent' && this.docTypeDropdown.value == this.aadhaarCardDocCode)
        this.refresh('refreshAction');
      else {
        this.checklistPopupRef = this.dialog.open(this.checklistPopup, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
        this.getSrCheckList();
      }
    } else {
      this.submitUploadDocForm();
    }
  }
  
  async submitUploadDocForm() {
    if (this.checklistPopupRef) {
      this.checklistPopupRef.close();
    }
    if (this.nsdlValidation) {
      if (this.docListPopupForm.valid) {
        this.validatePAN();
      } else {
        this.docListPopupForm.markAllAsTouched();
      }
      return;
    }
    if ((this.docListPopupForm && this.docListPopupForm.valid) || (this.docListPopupForm == null && this.showUploadBox)) {
      let docAttributes = [];
      if (this.attributeList.length > 0) {
        docAttributes = this.getAttributeValues();
      }
      if (this.byteArray && this.byteArray.length > 0) {
        for (let i = 0; i < this.byteArray.length; i++) {
          this.isUploadSuccess.push(false);
        }
        for (let docIndex = 0; docIndex < this.byteArray.length; docIndex++) {
          await new Promise(resolve => {
            this.uploadDocument(docAttributes, this.byteArray[docIndex]).subscribe((resp) => {
              if (this.httpService.isSuccessResponse(resp)) {
                this.isUploadSuccess[docIndex] = true;
                if (!this.isUploadSuccess.includes(false)) {
                  if (this.isDigitalConsent) {
                    this.isSubmitSuccess[this.selectedReqmntIndex] = true;
                    this.showRequirementList = true;
                    this.showForm = false;
                    this.showUploadBox = false;
                    this.httpService.showSuccessMsg(`Digital consent submitted successfully for SR number: ${this.requirementDtls.srno}`, true);
                    return;                    
                  }
                  if (this.requirementDtls.subissuecd != "TOPUP") {
                    this.isSubmitSuccess[this.selectedReqmntIndex] = true;
                    if(this.isInstaServ && !this.isSubmitSuccess.includes(false)){
                      this.instaSuccess = true;
                    } else {
                      this.instaSuccess = false;
                    } 
                    if(this.isInstaServ && this.isPhysicalEnabled){
                      // this.requirementDtlsList = [];
                      // this.isSubmitSuccess = [];
                      this.getConsentRequirement();
                      this.showRequirementList = true;
                      this.showForm = false;
                      this.showUploadBox = false;
                      this.instaSuccess = false;
                      this.isPhysicalEnabled = false;
                    } else{
                    if (this.isSubmitSuccess.includes(false)) {
                      if((!this.isSubmitSuccess.slice(this.selectedArray[0],this.selectedArray[1]+1).includes(false)) && ((this.requirementDtls.requirementdesc != "Pan Card/Declaration in Lieu of PAN Card")&&(this.requirementDtls.requirementdesc != "Pan Card/Declaration in Lieu of PAN Card/Form 60")) && (this.requirementDtls.requirementdesc != "Statement / bank confirmation letter to confirm the amount paid from NRE account")){
                        if (!this.data.disableSubmitSR) {
                          this.submitSR();
                        }
                      }
                      this.showRequirementList = true;
                      this.showForm = false;
                      this.showUploadBox = false;
                    } else {
                      if(((this.requirementDtls.requirementdesc != "Pan Card/Declaration in Lieu of PAN Card")&&(this.requirementDtls.requirementdesc != "Pan Card/Declaration in Lieu of PAN Card/Form 60")) && (this.requirementDtls.requirementdesc != "Statement / bank confirmation letter to confirm the amount paid from NRE account")){
                        this.showRequirementList = true;
                        this.showForm = false;
                        this.showUploadBox = false;
                        if (!this.data.disableSubmitSR && (this.data.submitSRReqDtls ? this.data.submitSRReqDtls.subactivity != "SURR_PROC" : this.requirementDtls.subissuecd != "SURR_PROC")) {
                          this.submitSR();
                          }/*  else {
                          this.httpService.showSuccessMsg("Document(s) uploaded successfully. Please click Submit SR button to complete the process.");
                          localStorage.removeItem("source");
                        } */
                      }
                      if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")||((this.data.id == "surrender")&&(!this.data.uploadDocFlag))){
                      this.showRequirementList = true;
                      this.showForm = false;
                      this.showUploadBox = false;
                      this.finishFlag = true
                      }
                      if (this.isInstaServ && this.instaSuccess) {
                        this.showRequirementList = true;
                        this.showForm = false;
                        this.showUploadBox = false;
                        this.httpService.showSuccessMsg("Document(s) uploaded successfully. Please click Submit SR button to complete the process.");
                        localStorage.removeItem("source");
                      }
                    }
                  }
                  } else {
                    this.isSubmitSuccess[this.selectedReqmntIndex] = true;
                    this.showRequirementList = true;
                    this.showForm = false;
                    this.showUploadBox = false;
                    if (this.isSubmitSuccess.includes(false)) {
                      this.showPaymentBtn = false;
                    } else {
                      let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"SM"};
                      this.message.openSnackBar(this.utils.getSubmitSRSuccessMsg(this.requirementDtls.srno, this.requirementDtls.subissuecd), 'xy', ['success-snackbar'],trackMsgObj);
                      this.showPaymentBtn = true;
                    }
                  }
                }
              } else {
                let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"UEE"};
                this.httpService.apiErrorHandler(resp,null,trackMsgObj);
              }
              resolve(true);
            }, () => resolve(false));
          });
        }
      } else {
        let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"VE"};
        this.message.openSnackBar("Please upload a document", 'xy', ['error-snackbar'],trackMsgObj);
      }
    } else if (this.docListPopupForm && !this.docListPopupForm.valid) {
      this.docListPopupForm.markAllAsTouched();
    }
  }
  getAttributeValues() {
    let docAttrList: Array<any> = [];
    for (let obj of this.attributeList) {
      let attributeValue = this.docListPopupForm.value[obj.key];
      if (attributeValue instanceof Date) {
        attributeValue = attributeValue.toLocaleDateString('en-IN');
      }
      let attribute = {
        docattributekey: obj.key,
        docattributeval: attributeValue
      };
      docAttrList.push(attribute);
    }
    return docAttrList;
  }
  uploadDocument(docAttributes: Array<any>, file) {
    const reqBody = {
      aadhaarConsentFlag : this.getAccept == true ? this.crypto.encryptJson("Y") : undefined,
      osvflag: this.data.source == "INSTA_SERV" ? this.osvControl.value : "Received through email or letter.",
      policyno: this.requirementDtls.policyno,
      srno: this.requirementDtls.srno,
      clientid: this.requirementDtls.clientid,
      bytearray: file.bytestream,
      reqcd: this.requirementDtls.reqcd,
      reqtranskey: this.requirementDtls.reqtranskey,
      reqstatuscd: this.data.source == "INSTA_SERV" ? "REQ_STATUS_4" : this.requirementDtls.reqstatuscd,
      filenetfldr: this.requirementDtls.filenetfldr,
      doccd: this.docTypeDropdown.value,
      doctype: file.name.split('.').pop(),
      docname: file.name ? file.name.replace(/\.(?=.*\.)/g, '').replace(/[^\w.\- ]/g, '') : file.name,
      docattributelist: docAttributes,
      clientrolecd : this.requirementDtls.clientrolecd
    };
    let touchpoint = TOUCHPOINT;
    if (this.data.source == "INSTA_SERV") {
      touchpoint = "TOUCHPOINT_INSTASERVOPSNEW";
    }
    if(this.getAccept) {
      this.adhaarConsent = "Y";
    } else {
      this.adhaarConsent = undefined;
    }
    return this.createSrService.documentUpload(reqBody, touchpoint);
  }
  submitSR() {
    let acc_tag;
    let parentSR;
    let linkid;
    if((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout")||(this.data.id == "surrender")){
      acc_tag = this.data.acc_tag ? this.data.acc_tag : "Y"
      parentSR = this.data.claimNo
      linkid = this.linkId ? this.linkId: this.data.linkId
    } else if(this.data.surrenderFlag) {
      acc_tag = "Y"
      parentSR = this.data.srno
      linkid = this.linkId
    } else{
      acc_tag = "";
      parentSR = "";
      linkid = "";
    }
    
    if(this.getAccept) {
      this.adhaarConsent = "Y";
    } else {
      this.adhaarConsent = undefined;
    }


    let reqBody;
    if(this.requirementDtlsList.length == 0 && this.data.submitSRReqDtls){
      reqBody = {
         aadhaarConsentFlag : this.getAccept == true ? this.crypto.encryptJson("Y") : undefined,
         policyno: this.data.submitSRReqDtls.policyno,
         clientid: this.data.submitSRReqDtls.clientid,
         subactivity: this.data.submitSRReqDtls.subactivity,
         action: "SUBMIT",
         srno: this.data.submitSRReqDtls.srno,
         role: this.data.submitSRReqDtls.role,
         subactivityType: '',
         nachsi: '',
         payout_acc_tag: acc_tag,
         parentsrno: parentSR, 
         linkid: linkid,
         panno: this.data.submitSRReqDtls.panNo,
       };
     } else{
     reqBody = {
      aadhaarConsentFlag : this.getAccept == true ? this.crypto.encryptJson("Y") : undefined,
      policyno: this.requirementDtls.policyno,
      clientid: this.requirementDtls.clientid,
      subactivity: this.requirementDtls.subissuecd,
      action: "SUBMIT",
      srno: this.requirementDtls.srno,
      role: this.requirementDtls.clientrolecd,
      subactivityType: '',
      nachsi: '',
      payout_acc_tag: acc_tag,
      parentsrno: parentSR, 
      linkid: linkid,
    };
   }
    if (reqBody.subactivity === "SI_ECS_ACTV_REQ") {
      reqBody.subactivityType = 'NACH_SI_CORRECTION',
        reqBody.nachsi = "NACH"
    }
    if(reqBody.subactivity === "SUB_ACT_REVIVAL") {
      this.myPoliciesService.createSubmitRevivalSR(reqBody).subscribe((resp) => {
        if (this.httpService.isSuccessResponse(resp)) {
                    let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"SM"};
          this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${this.requirementDtls.srno}`, 'xy', ['success-snackbar'],trackMsgObj);
          if (!this.data.disableClose) {
            this.dialogRef.close();
          }
        } else {
          this.httpService.apiErrorHandler(resp);
        }
      }),
      catchError((error) => throwError(error));
    }
    else {
    this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.submittedSrNo = resp.body.srbasicinfo.srno;
        let message;
        if(reqBody.subactivity === "STND_NEFT" && this.data.surrenderFlag){
          this.subId = "NEFT";
          message = `Document(s) uploaded successfully for NEFT update request (SR No. ${resp.body.srbasicinfo.srno})`;
        } else if(reqBody.subactivity === "ACCOUNT_TAGGING" && this.data.surrenderFlag){
          this.subId = "CHEQUE";
          message = `Document(s) uploaded successfully for Cheque update request (SR No. ${resp.body.srbasicinfo.srno})`;
        }
         else if(reqBody.subactivity === "UPD_KYC" && this.data.surrenderFlag){
           this.subId = "KYC";
          message = `Document(s) uploaded successfully for KYC update request (SR No. ${resp.body.srbasicinfo.srno})`;
        } else {
          if((reqBody.subactivity === "STND_NEFT" || reqBody.subactivity === "UPD_KYC")&& (this.data.id !="surrender")){
            if(reqBody.subactivity === "STND_NEFT"){
              this.subId = "NEFT";
            } else if(reqBody.subactivity === "UPD_KYC"){
              this.subId = "KYC"
            }
            if (!this.data.disableClose && !this.dataService.getIsCustomLink() && !this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
              this.getNextBestAction(this.subId);
            }
          }
          message = `Document(s) uploaded successfully for SR number: ${resp.body.srbasicinfo.srno}`;
        }
        if((this.data.id != "maturity")&&(this.data.id != "moneyBack")&&(this.data.id != "annuityPayout")&&(this.data.id != "surrender")){
          if (!this.data.disableClose && (!this.dataService.getIsCustomLink() || this.data.surrenderFlag)) {
            this.dialogRef.close();
            if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && (this.data.unclaimedAmtFlags.KYC_ACTIVE=='N' || this.data.unclaimedAmtFlags.neft_exist=='N')) {
              this.dialog.open(MyPoliciesDialogComponent, {
                panelClass: 'my-policies-dialog', disableClose: true,
                data: {
                  policyNo: this.requirementDtls.policyno,
                  unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                  unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                  disableClose: this.dataService.getIsCustomLink(),
                }
              });
            } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
              this.createSrService.showUnclaimedAmtFinalMsg();
            }
            let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"SM"};
            this.message.openSnackBar(message, 'xy', ['success-snackbar'], trackMsgObj);
          } else {
            if (this.data.unclaimedAmtFlow && this.data.unclaimedAmtFlags && (this.data.unclaimedAmtFlags.KYC_ACTIVE=='N' || this.data.unclaimedAmtFlags.neft_exist=='N')) {
              this.dialog.open(MyPoliciesDialogComponent, {
                panelClass: 'my-policies-dialog', disableClose: true,
                data: {
                  policyNo: this.requirementDtls.policyno,
                  unclaimedAmtFlags: this.data.unclaimedAmtFlags,
                  unclaimedAmtFlow: this.data.unclaimedAmtFlow,
                  disableClose: this.dataService.getIsCustomLink(),
                }
              });
            } else if (this.data.unclaimedAmtFlow && !this.data.unclaimedAmtFlags) {
              this.createSrService.showUnclaimedAmtFinalMsg();
            } else {
            this.httpService.showSuccessMsg(message, true);
          }
          }
        } else{
          if(!this.data.docUploadFlag){
          let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"SM"};
          this.message.openSnackBar(message, 'xy', ['success-snackbar'], trackMsgObj);
            this.dialogRef.close();
            } else{
            let trackMsgObj ={"flowType": this.trackUserActionsFlowType,"error_code":"SM"};
            this.message.openSnackBar(message, 'xy', ['success-snackbar'],trackMsgObj);
            }
        }
        // if ((!this.isSubmitSuccess.includes(false))&&((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout"))) {
        //   // this.dialogRef.close();
        //   this.finishFlag = true
        // } else if((this.isSubmitSuccess.includes(false))&&((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout"))) {
        //   this.finishFlag = false
        // } else{
        //   this.dialogRef.close();
        // }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  }
  onBackBtnClick() {
    this.showRequirementList = true;
    this.showForm = false;
    this.showUploadBox = false;
    if(this.isInstaServ && this.isPhysicalEnabled){
      this.isPhysicalEnabled = false;
      this.instaServDropdown.setValue('Digital consent');
    }
  }
  makeTopUpPayment() {
    let reqBody = {
      "policyno": this.data.policyno,
      "premiumType": "Top up",
      "srNo": this.data.srno,
      "reqCd": "CSPS_REQ_011"
    };
    this.createSrService.getTopUpPayment(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.token) {
          window.open(resp.body.token, '_self');
          this.dialogRef.close();
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  validatePAN() {
    let reqBody = {
      userid: this.utils.getClientId(),
      pan: this.docListPopupForm.controls.panNo.value,
      uname: this.docListPopupForm.controls.panName.value,
      udob: moment(this.docListPopupForm.controls.panDOB.value).format('DD/MM/YYYY'),
    }
    return this.createSrService.panValidation(reqBody, "validatePanAadhar").subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.error_msg) {
          this.panAadharLinkErrMsg = resp.body.error_msg;
        }
        if (resp.body && resp.body.succ_msg) {
          this.panAadharLinkSuccMsg = resp.body.succ_msg;
        }
        if (resp.body && resp.body.check_msg) {
          this.panAadharLinkCheckMsg = resp.body.check_msg;
        }
        if (resp.body && resp.body.isValid === "YES") {          
          this.isPanValid = true;
          this.fulfillRequirement();
          this.editPanSuccMsg = resp.body.msg;
        } else {
          this.editPanErrMsg = resp.body.msg;
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  fulfillRequirement() {
    let reqBody = {
      "laClientId": this.requirementDtls.laclientid,
      "srnum": this.requirementDtls.srno,
      "reqcd": this.requirementDtls.reqcd,
      "reqtranskey": this.requirementDtls.reqtranskey
    }
    return this.createSrService.fulfillReqmnt(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.isSubmitSuccess[this.selectedReqmntIndex] = true;
        this.showRequirementList = true;
        this.showForm = false;
        this.showUploadBox = false;
        if (this.isSubmitSuccess.includes(false)) {
          this.showPaymentBtn = false;
        } else {
          this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${this.requirementDtls.srno}`, 'xy', ['success-snackbar'], 'top');
          this.showPaymentBtn = true;
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    });
  }
  async byteArrayGenerator(files: Array<any>) {
    let bytearray = [];
    for (let i = 0; i < files.length; i++) {
      let base64string: string;
      base64string = await this.utils.getBase64(files[i], this.isInstaServ);
      bytearray.push({
        name: files[i].name,
        bytestream: base64string,
      });
    }
    //this.byteArray = [];
    return bytearray;
  }
  finishBtn(){
    if(this.data.id != "surrender"){
    this.claimSubmit();
    } else {
      this.surrenderSubmit();
    }
  }
  claimSubmit(){
    let subactivity;
    if(this.data.id == "maturity"){
      subactivity = "MAT_CLAIM"
    } else if(this.data.id == "moneyBack"){
      subactivity = "MONY_BK_CLAIM"
    } else{
      subactivity = "ANN_PAYOUT"
    }
    let reqbody;
    if(this.requirementDtlsList.length == 0 && this.data.submitSRReqDtls){
      reqbody = {
        "claimNo": this.data.submitSRReqDtls.claimNo,
      "subactivity": subactivity,
      "action": "SUBMIT",
      "policyno": this.data.submitSRReqDtls.policyno,
      "clientid": this.data.submitSRReqDtls.clientid,
      "role": this.data.role,
      // "nrePremPaid": this.payoutDtls.nrePaid,
      "srno": this.data.submitSRReqDtls.claimNo,
      "claimsettlementoption":  this.payoutDtls.mode,
      "settlementterm": this.payoutDtls.settlementTerm,
      "settlementfreq": this.payoutDtls.settlementFreq,
      "benefitoption": this.payoutDtls.benefit,
      "totmatamt": "",
      "totalnreprem": this.payoutDtls.nrePaid,
      "payoutmode": this.payoutDtls.paymentMode,
      "accountValue": this.payoutDtls.accountValue
    }
  } else{
     reqbody = {
      "claimNo": this.data.claimNo,
      "subactivity": subactivity,
      "action": "SUBMIT",
      "policyno": this.data.policyno,
      "clientid": this.data.clientid,
      "role": this.data.role,
      // "nrePremPaid": this.payoutDtls.nrePaid,
      "srno": this.data.claimNo,
      "claimsettlementoption":  this.payoutDtls.mode,
      "settlementterm": this.payoutDtls.settlementTerm,
      "settlementfreq": this.payoutDtls.settlementFreq,
      "benefitoption": this.payoutDtls.benefit,
      "totmatamt": "",
      "totalnreprem": this.payoutDtls.nrePaid,
      "payoutmode": this.payoutDtls.paymentMode,
      "accountValue": this.payoutDtls.accountValue
  }
}
  return this.myPoliciesService.maturitySubmitSR(reqbody).subscribe(resp => {
    if (this.httpService.isSuccessResponse(resp)) {
      if (this.dataService.getIsCustomLink()) {
        this.dialog.closeAll();
        this.httpService.showSuccessMsg(`Document(s) uploaded successfully for SR number: ${resp.body.srinfo.srbasicinfo.srno}`, true);
      } else {
        this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${resp.body.srinfo.srbasicinfo.srno}`, 'xy', ['success-snackbar'], 'top');
        this.srTypeArray = [{ "srNum": resp.body.srinfo.srbasicinfo.srno, "srType": subactivity }];
        this.createSrService.setSRTypeResp(this.srTypeArray);
        this.dialog.closeAll()
        this.router.navigate(['/customer-service', 'service-request-details']);
      }  
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  })
  }
  surrenderSubmit(){
    let reqbody;
    if(this.requirementDtlsList.length == 0 && this.data.submitSRReqDtls){
      reqbody = {
        "policyno": this.data.submitSRReqDtls.policyno,
        "clientid": this.data.submitSRReqDtls.clientid,
        "subactivity": "SURR_PROC",
        "action": "SUBMIT",
        "role": "Owner",
        "srNum": this.data.submitSRReqDtls.srno,
        "payeeclientno": this.data.payeeClientId,
        "payeefirstname": this.data.firstName,
        "payeelastname": this.data.lastName,
        "paymentMode": this.payoutDtls.paymentMode,
        "prmpaidnre": "",
        "payeeclientrole": this.data.submitSRReqDtls.role,
        "surrReason": this.payoutDtls.surReason,
        "productType": this.payoutDtls.prodType,
        "prodCode": this.payoutDtls.prodCode,
        "surrQuoteGenDt": this.payoutDtls.quoteGenDate,
        "surrVal": this.payoutDtls.surrValue,
        "accountValue": this.payoutDtls.accountValue
    }
  } else{
    reqbody = {
      "policyno": this.data.policyno,
        "clientid": this.data.clientid ? this.data.clientid : this.data.submitSRReqDtls.clientid,
        "subactivity": "SURR_PROC",
        "action": "SUBMIT",
        "role": "Owner",
        "srNum": this.data.claimNo,
        "payeeclientno": this.data.payeeClientId,
        "payeefirstname": this.data.firstName,
        "payeelastname": this.data.lastName,
        "paymentMode": this.payoutDtls.paymentMode,
        "prmpaidnre": "",
        "payeeclientrole": this.data.role,
        "surrReason": this.payoutDtls.surReason,
        "productType": this.payoutDtls.prodType,
        "prodCode": this.payoutDtls.prodCode,
        "surrQuoteGenDt": this.payoutDtls.quoteGenDate,
        "surrVal": this.payoutDtls.surrValue,
        "accountValue": this.payoutDtls.accountValue
    }
  }
    return this.myPoliciesService.surrenderSubmitSR(reqbody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (this.dataService.getIsCustomLink()) {
          let text = "https://myaccount.hdfclife.com/login";
          let link = text.link("https://myaccount.hdfclife.com/login");
          this.dialog.closeAll();
          if(resp.body.srinfo){
          this.httpService.showSuccessMsg(`Your request to Surrender (SR No. ${resp.body.srinfo[0].srbasicinfo.srno}) your HDFC Life policy ${this.data.policyno} has been successfully submitted. Your payout is subject to verification of submitted documents and information. You can expect your reimbursement within 7 to 10 business days. We will contact you if any more documentation is needed. You can track the status of your Surrender request by visiting the My Service Requests tab on My Account (
            ${link}).` , true);
          } else{
            this.httpService.showSuccessMsg(`Your request to Surrender (SR No. ${resp.body.srnum}) your HDFC Life policy ${this.data.policyno} has been successfully submitted. Your payout is subject to verification of submitted documents and information. You can expect your reimbursement within 7 to 10 business days. We will contact you if any more documentation is needed. You can track the status of your Surrender request by visiting the My Service Requests tab on My Account (
              ${link}).`, true);
          }
        } else {
          let srNo;
          if(resp.body.srinfo){
            srNo = resp.body.srinfo[0].srbasicinfo.srno
          } else {
            srNo = resp.body.srnum;
          }
          this.message.openSnackBar(`Document(s) uploaded successfully for SR number: ${srNo}`, 'xy', ['success-snackbar'], 'top');
          // this.srTypeArray = [{ "srNum": resp.body.srinfo.srbasicinfo.srno, "srType": "SURR_PROC" }];
          // this.createSrService.setSRTypeResp(this.srTypeArray);
          this.dialog.closeAll()
          // this.router.navigate(['/customer-service', 'service-request-details']);
        }    
      } else {
        if(resp.head.errordetails.message == "Please update NEFT details for payout process"){
          let policy = {
            srNum: reqbody.srNum,
            policyNo: reqbody.policyno,
            payeeClientId: reqbody.payeeclientno,
            payeeRole: reqbody.payeeclientrole
          }
          // this.dataService.setPolicyDtls(policy);
          this.payout.ngOnInit();
          this.payout.goToPayee(this.data.verifyPay)
        } else {
        this.httpService.apiErrorHandler(resp);
      }
      }
    })
  }
  openCamera() {
    this.cameraDialogRef = this.dialog.open(CameraComponent, { panelClass: 'camera-dialog', backdropClass: 'camera-dialog-backdrop', disableClose: true,
    data: { ...this.data, maxFiles: this.requirementDtls.reqcd == this.digiConsentReqCode && this.docTypeDropdown.value == this.digiConsentDocCode ? 1 : (5 - this.uploadedFileList.length), isDigiConsent: this.requirementDtls.reqcd == this.digiConsentReqCode && this.docTypeDropdown.value == this.digiConsentDocCode }, 
  });
    this.cameraDialogRef.afterClosed().subscribe(imageArray => {
      if (imageArray && imageArray.length > 0) {
        this.imageArray = imageArray;
        let files = this.docArray.concat(this.imageArray);
        this.onDocumentLoad(files);
      }
    });
  }
  getCheckList() {
    let reqBody = {
      "DOCUMENT_CD": this.docTypeDropdown.value,
      "REQUIREMENT_CD": this.requirementDtls.reqcd,
      "CLIENT_ROLE_CD": this.requirementDtls.clientrolecd
    }
    return this.createSrService.getChecklist(reqBody, "TOUCHPOINT_INSTASERVOPSNEW").subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.checklist = resp.body.Checklist;
        if (this.checklist && this.checklist.length == 0) {
          this.submitUploadDocForm();
        } else {
        this.checklistPopupRef = this.dialog.open(this.checklistPopup, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
    catchError(error => throwError(error));
  }
  closeChecklist() {
    // this.uploadedFileList = [];
    // this.byteArray = [];
    // this.showUploadBox = false;
    // setTimeout(() => {
    //   this.showUploadBox = true;
       this.checklistPopupRef.close();
       this.checklist = [];
       this.isChkLstEmty = true;
    // }, 300);
  }
  close(){
    if(this.data.id == "surrender" || this.data.surrenderFlag == true){
    let kycData = {
      srno: this.data.srno,
      subActivity: this.subId ? this.subId : this.data.type
    }
    this.myPoliciesService.setKycdata(kycData);
  }
  }
  async getAgentDocList() {
    let srNumber = [];
      for(let sr of this.data.srNumber) {
        srNumber.push(sr);
      }
    for (let i = 0; i < srNumber.length; i++) {
    let reqBody = {
      srNo: srNumber[i],
    }
    await new Promise(resolve => {
      return this.customLinkService.getAgentDocList(reqBody).subscribe(resp => {
        if (this.httpService.isSuccessResponse(resp)) {
          if (resp.body && resp.body.getrequirementdetails) {
            this.createSrService.setSrPolicyStatus(resp.body.polciyDetails?resp.body.polciyDetails.POLICY_STATUS:'');
            if (this.isDigitalConsent) {
              this.requirementsResp.push(resp.body.getrequirementdetails.find(i => i.reqcd == this.digiConsentReqCode));
              if (this.requirementsResp.length == 0) {
                this.requiremntMsg = "No Records Found"
                return
              }
            } else {
              this.requirementsResp = resp.body.getrequirementdetails;
            }
            for (let i of this.requirementsResp) {
              if (i.subissuecd == "SUB_ACT_REVIVAL") {
                if (i.reqtype == "6911") {
                  this.requirementDtlsList.push(i);
                }
              }
              else {
                this.requirementDtlsList.push(i);
              }
            }
            this.requirementSliceArray.push([this.requirementDtlsList.length - this.requirementsResp.length,this.requirementDtlsList.length - 1])
            this.requirementsResp.forEach((item) => this.isSubmitSuccess.push(false));
          }
          if (resp.body && resp.body["Payment requirement"] && resp.body["Payment requirement"].status == "Requirement Raised" && !this.isClickToUpload) {
            this.isPaymentPending = true;
          }
        } else {
          if (!this.data.disableClose) {
            this.dialogRef.close();
          }
          this.httpService.apiErrorHandler(resp);
        }
        resolve(true)
      }, () => resolve(false));
        // catchError((error) => throwError(error));
    })
  }
  if(this.requirementDtlsList.length == 0 && this.data.submitSRReqDtls && !this.isPaymentPending){
    this.requiremntMsg = "No Records Found"
    if (!this.data.disableSubmitSR) {
      this.submitSR();
    }
  }
   else if(this.requirementDtlsList.length == 0 && ((this.data.id == "maturity")||(this.data.id == "moneyBack")||(this.data.id == "annuityPayout"))){    //||(this.data.id == "surrender")
     if(this.data.uploadDocFlag){
      this.requiremntMsg = "No Records Found"
     } else{
      this.requiremntMsg = "No Documents are required for SR No :" + this.data.claimNo
      this.finishFlag = true;
    }
   } else if (this.requirementDtlsList.length == 0 && this.isPaymentPending) {
    this.requiremntMsg = "No Documents are required for SR No : " + this.data.srno
    this.showPaymentBtn = true;
   }
   else {
    this.requiremntMsg = "No Records Found"
   }
   if (this.isDigitalConsent && this.requirementDtlsList.length > 0) {
     this.onUploadClick(0);
  }
}
customLinkLogout() {
  this.auth.logout('M');
}
trackUserActions(reqDtls) {
  let policy_status='';
  policy_status = this.createSrService.getSrPolicyStatus();

  let flowType = 'my account';
  if (reqDtls && reqDtls.clientrolecd === "Life Assured") { 
    flowType = 'Beneficiaries - LA' 
  }
  if (reqDtls && reqDtls.clientrolecd === "Beneficiary") {
    flowType = 'Beneficiaries'
  }
  if (reqDtls && reqDtls.subissuecd === "CHNG_NAME") {
    this.utils.trackUserActions({ 'eventName': 'Edit Name - upload', 'flowType': flowType });
  }
  if (reqDtls && reqDtls.subissuecd === "UPD_KYC" && flowType === 'my account') {
    this.utils.trackUserActions({ 'eventName': 'Edit KYC - manual - upload', 'flowType': flowType });
  }
  if (reqDtls && reqDtls.subissuecd === "CHNG_ADDRESS") {
    let type = this.data.addressType ? this.data.addressType : 'Mailing';
    this.utils.trackUserActions({ 'eventName': `Edit ${type} Address - manual - upload`, 'flowType': flowType });
  }  
  if(reqDtls && reqDtls.subissuecd === "SUB_ACT_REVIVAL" && this.requirementDtls.reqcd != "CSPS_REQ_505" && this.requirementDtls.reqcd != "CSPS_REQ_332"){
    this.utils.trackUserActions({ "eventName" : "FR-Documents","flowType" : "revival journey", 'policyNo': reqDtls.policyno, "my_account_contract_status": policy_status, "my_account_sr_number":reqDtls.srno});  
  }
  if(this.requirementDtls.reqcd == "CSPS_REQ_505"){
    this.utils.trackUserActions({ "eventName" : "FR-COVID19","flowType" : "revival journey", 'policyNo':  reqDtls.policyno, "my_account_contract_status": policy_status, "my_account_sr_number":reqDtls.srno});  
 }
 else if (this.requirementDtls.reqcd == "CSPS_REQ_332"){
  this.utils.trackUserActions({ "eventName" : "FR-PHS","flowType" : "revival journey", 'policyNo':  reqDtls.policyno, "my_account_contract_status": policy_status, "my_account_sr_number":reqDtls.srno});  
}
}
trackUserActionsFlowType(reqDtls) {
  let flowType = 'my account';
  if (reqDtls && reqDtls.clientrolecd === "Life Assured") { 
    flowType = 'Beneficiaries - LA' 
  }
  if (reqDtls && reqDtls.clientrolecd === "Beneficiary") {
    flowType = 'Beneficiaries'
  }
  if(this.data.id == "maturity"){
    flowType = 'maturity'
  }
  if(this.data.id == "moneyBack"){
    flowType = 'moneyBack'
  }
  if(this.data.id == "annuityPayout"){
    flowType = 'annuityPayout'
  }
  return flowType;
}
selectedOption(event:any){
  this.accepted = event.checked;
}
onOsvChange(eve) {
  this.osvControl.setValue(eve.value);
}

sendCustomlink(){
  let reqBody = {
    "srNo" : this.crypto.encryptJson(this.requirementDtls.srno),
    "clientId" : this.crypto.encryptJson(this.requirementDtls.clientid),
    "role" : this.crypto.encryptJson(this.requirementDtls.clientrolecd),
    "policyNo" : this.crypto.encryptJson(this.requirementDtls.policyno),
  }
  return this.myAccountService.sendAadhaarConsentLink(reqBody).subscribe((resp) => {
    if(this.httpService.isSuccessResponse(resp)){
      if(resp && resp.body && resp.body.msg){
        this.maskedDetails = resp.body.msg.match(/\*+[0-9a-zA-Z.]+/g)
        this.timerSec = 60;
        this.startResendTimer();
        this.startRefreshTimer();
        this.showDigitalInfo = true;
      }
    }
    else{
      if(!this.httpService.isSuccessResponse(resp)){
        if(resp && resp.head && resp.head.errordetails && resp.head.errordetails.message.includes('Maximum')){
          this.consentErrorMsg = resp.head.errordetails.message;
          this.exhausted = true;
        }
        else {
          this.message.openSnackBar(resp.head.errordetails.message, 'xy', ['error-snackbar'], 'top');
      }
    }
  }}
  ), catchError((err) => throwError(err)) 


  
}

startResendTimer(){
  this.resend_interval = setInterval(() => {
    if (this.timerSec > 0) {
      this.hours = ((this.timerSec / 60) | 0);
      // if (this.timerSec >= 59) { this.minutes = 60-(((this.hours+1)*60 % (this.timerSec))); 
      if (this.timerSec >= 59) { this.minutes = 60 - ((this.hours + 1) * 60 - this.timerSec) }
      // if (this.minutes == 60) {this.minutes = 0;} }
      else { this.minutes = this.timerSec; }
      this.timerSec--;
    }
    else {
      this.hours = 0;
      this.minutes = 0;
    }
  }, 1000)
}

startRefreshTimer(){
    this.refresh_interval = setTimeout(() => {
        this.disableRefresh = false;
    }, 15000);
}

resendLink(){
  clearTimeout(this.refresh_interval)
  clearTimeout(this.resend_interval)
  if(this.resend_interval){
    clearInterval(this.resend_interval)
  }
  this.disableRefresh = true;
  this.disableResend = true;
  let reqBody = {
    "clientId" : this.crypto.encryptJson(this.requirementDtls.clientid),
    "srNo" : this.crypto.encryptJson(this.requirementDtls.srno),
    "role" : this.isInstaServ ? this.crypto.encryptJson(this.requirementDtls.clientrolecd) : undefined
  }
  return this.myAccountService.getAadhaarConsentFlag(reqBody).subscribe((resp) => {
    if(this.httpService.isSuccessResponse(resp)) {
      if(resp.body && resp.body.aadhaarConsentFlag){
        this.showconsent = (this.crypto.decryptString(resp.body.aadhaarConsentFlag) == "N")? true : false; 
        if(this.showconsent){
          this.templateHeader = "";
          this.templateMsg = "Aadhaar consent has been received.";
          this.consentAlertRef = this.dialog.open(this.consentAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
        } else {
          this.sendCustomlink(); 
        }
      }
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  }), catchError((error) => throwError(error))
}

refresh(action : any){
  let reqBody = {
    "clientId" : this.crypto.encryptJson(this.requirementDtls.clientid),
    "srNo" : this.crypto.encryptJson(this.requirementDtls.srno),
    "role" : this.isInstaServ ? this.crypto.encryptJson(this.requirementDtls.clientrolecd) : undefined
  }
  return this.myAccountService.getAadhaarConsentFlag(reqBody).subscribe((resp) => {
    if(this.httpService.isSuccessResponse(resp)) {
      if(resp.body && resp.body.aadhaarConsentFlag){
        this.consentAccepted = (this.crypto.decryptString(resp.body.aadhaarConsentFlag) == "N")? true : false; 
        if(action == 'changeMode'){
          if(this.consentAccepted){
            this.instaServDropdown.setValue('Digital consent'); //to change dropdown value to digital when consent is given preventing user switch to physical
            this.physicalConsentAlertRef.close();
          } else {
              if(this.instaServDropdown.value == "Physical consent"){
                this.physicalConsentAlertRef = this.dialog.open(this.physicalConsentAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
            }
          }
        } else {
            if(this.showInstaServConsent && this.instaServDropdown.value == 'Digital consent' && this.showDigitalInfo && !this.consentAccepted) {
              this.templateHeader = "Alert";
              this.templateMsg = "Please provide consent from the T&C link triggered to proceed further.";
              this.consentAlertRef = this.dialog.open(this.consentAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
            } else if (this.showInstaServConsent && this.instaServDropdown.value == 'Digital consent' && !this.showDigitalInfo){
              this.templateHeader = "Alert";
              this.templateMsg = "Please click Send Custom Link button to proceed further.";
              this.consentAlertRef = this.dialog.open(this.consentAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
            } 
            else{
                if(this.resend_interval){
                   clearInterval(this.resend_interval)
                }
                this.templateHeader = "";
                this.templateMsg = "Aadhar consent received from the link!!";
                this.consentAlertRef = this.dialog.open(this.consentAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
                this.consentAlertRef.afterClosed().subscribe(result => {
                  if(result == 'close'){
                    this.checklistPopupRef = this.dialog.open(this.checklistPopup, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
                    this.getSrCheckList();
                  }
                })
            }
        }
      }
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  }), catchError((error) => throwError(error))
}

physicalMode(){
  //check whether consent has been taken or not from the link
  //disable link if consent is not taken.
  this.isPhysicalEnabled = true;
  this.instaSuccess = false;
  this.closeConsentAlert(this.instaServDropdown.value);
}

closeConsentAlert(value : any){
  this.instaServDropdown.setValue(value);
  console.log(this.instaServDropdown.value);
  this.physicalConsentAlertRef.close();
}

confirmMode(){
  this.physicalConsentConfirmAlertRef = this.dialog.open(this.physicalConsentConfirmAlert, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
}

physicalConsentConfirm(){
  this.closePhysicalConfirmAlert();
  this.submitUploadDocForm();
}

closePhysicalConfirmAlert(){
  this.physicalConsentConfirmAlertRef.close();
}
getNextBestAction(type){
  let reqBody = {
    "clientid" : this.requirementDtls.clientid,
    "ACTION" : type
  }
  return this.myAccountService.getNextBestAction(reqBody).subscribe((resp) => {
    if(this.httpService.isSuccessResponse(resp)) {
      console.log(resp)
      if(resp.body.NEFT == "N" || resp.body.PAN == "N" || resp.body.KYC == "N"){
      if(resp.body.NEFT == "Y" || type == "NEFT"){
        this.NeftBtn = true;
      } else{
        this.NeftBtn = false;
      } if(resp.body.PAN == "Y" || type == "PAN"){
        this.PanBtn = true;
      } else{
        this.PanBtn = false;
      } if(resp.body.KYC == "Y" || type == "KYC"){
        this.KycBtn = true;
      } else{
        this.KycBtn = false;
      }
      this.dialog.open(this.srSubmitpopup, { width: '500px', panelClass: 'checklist-popup-container', backdropClass: 'checklist-popup-backdrop', disableClose: true });
    } else  {
      this.message.openSnackBar( `Document(s) uploaded successfully for SR number: ${this.submittedSrNo}`, 'xy', ['success-snackbar'], 'top');
    }
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  }), catchError((error) => throwError(error))
}

updatePAN(){
    this.dialog.closeAll();
    this.router.navigate( ['/my-account'],{ queryParams: { 'action': 'UPD_PAN' }});
    this.dialog.open(MyAccountComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop'});
}
updateKYC(){
  console.log("KYC")
    let reqBody = {
      policyno: this.requirementDtls.policyno,
      subactivity: 'UPD_KYC',
    }
   //pass response value to data;
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {
        this.dialog.open(UpdateMailingAddressComponent, { panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop',
               data: { policyNo: this.requirementDtls.policyno, kycFlag: true, entryFlag: true, CheckboxFlag: true, disableClose: this.dataService.isDeepLink} });        
      } else if (this.httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar']);
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
}
updateNEFT(){
  this.myAccount.ngOnInit();
  this.myAccount.updateNeftDetails('NEFT');
}
closePopup() {
  this.dialog.closeAll();
}

closeConsent(){
  this.consentAlertRef.close('close');
}

async getConsentRequirement(){
  let reqBody = {
    "srno" : this.data.srno
  }
  await new Promise(resolve => {
    return this.createSrService.getRequirementDetails(reqBody).subscribe(resp => {
      if(this.httpService.isSuccessResponse(resp)) {
        if(resp.body && resp.body.getrequirementdetails) {
          this.createSrService.setSrPolicyStatus(resp.body.polciyDetails?resp.body.polciyDetails.POLICY_STATUS:'');
          this.requirementsResp = resp.body.getrequirementdetails;
          for (let i of this.requirementsResp) {
            if (i.subissuecd == "SUB_ACT_REVIVAL") {
              if (i.reqtype == "6911") {
                this.requirementDtlsList.push(i);
              }
            }
            else {
              let result = this.requirementDtlsList.find(value => value.requirementdesc == i.requirementdesc)
              if(result === undefined)
                this.requirementDtlsList.push(i);
            }
          }
          this.isSubmitSuccess.push(false)
          this.requirementSliceArray[0][1]+=1;
        }
      } else {
          if (!this.data.disableClose) {
            this.dialogRef.close();
          }
          this.httpService.apiErrorHandler(resp);
        }
        resolve(true)
    },() => resolve(false))
  })
}
getSrCheckList() {
  let reqBody = {
    "srNo" : this.crypto.encryptJson(this.requirementDtls.srno),
    "documentCode": this.crypto.encryptJson(this.docTypeDropdown.value),
    "requirementCode": this.crypto.encryptJson(this.requirementDtls.reqcd),
  }
  return this.createSrService.getSrCheckList(reqBody, "CUSTOMERPORTAL").subscribe(resp => {
    if (this.httpService.isSuccessResponse(resp)) {
      this.srchecklist = resp.body;
      if(this.srchecklist && this.srchecklist.length > 0){
        this.isChkLstEmty = false;
        this.checklist = this.srchecklist.map(chklist => chklist.paramdesc);
      } else{
        this.isChkLstEmty = true;
      }
    } else {
      this.httpService.apiErrorHandler(resp);
    }
  }),
  catchError(error => throwError(error));
}
} 
