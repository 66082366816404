import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { ChangeSecretQuestionsComponent } from './change-secret-questions/change-secret-questions.component';
import { SetUpSocialMediaIdComponent } from './set-up-social-media-id/set-up-social-media-id.component';
import { NewLoginComponent } from './new-login/new-login.component';


const routes: Routes = [  
  { path: '', component: NewLoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ForgotPasswordComponent },
  { path: 'user-registration', component: UserRegistrationComponent },
  { path: 'secret-questions', component: ChangeSecretQuestionsComponent },
  //{ path: 'my-login',component: LoginComponent},
  // { path: 'social-logins', component: SetUpSocialMediaIdComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }