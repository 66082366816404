<div class="terms-and-conditions" *ngIf="showPage">
    <div class="header">Terms and Conditions</div>
    
    <mat-card class="main">
        <ol class="orderLanguage" [ngClass]="{'changeDirection' : lang=='urdu' || lang=='kashmiri', 'no-numbers' : lang=='gujarati' || lang=='kashmiri'}">  
            
            <ng-container *ngFor="let item of terms">
                    <li  class="terms-and-condition points">{{ item.value}}</li>
                    
                    <ol *ngIf="item.desc != ''" class="orderedSubitems">
                        <li  *ngFor="let subItem of item.desc" class="subpoints">{{subItem.value}}</li>
                    </ol>
            </ng-container>
            <div *ngIf="showaudio" class="audio">
                <audio src="assets/audio/scr_259.mp3" controls></audio>
            </div>
        </ol>
        <ng-container *ngIf="showcheckbox">
                <div class="read-terms">
                    <mat-checkbox color="primary" disableRipple="true" (change)="selectedOption($event)" class="read-box" [ngClass]="{'space' : lang == 'gujarati'}">I agree to Terms and Conditions</mat-checkbox>
                </div>
                <div class="submit-button-div flex">
                    <button type="submit" matButton class="submit-button" [disabled]="!(accepted)" (click)="aadhaarConsent()">Submit</button>
                </div>
        </ng-container>
        
    </mat-card>
</div>