import { Component, OnInit, Inject } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {
  userDetails: any = {};
  isHNIUSer: boolean = false;
  showWebcam: boolean = true;
  isCameraExist: boolean = true;
  isMultipleCameras: boolean = true;
  errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  fileList: Array<any> = [];
  webCamImage: WebcamImage = null;
  nextSnapshot: boolean = false;
  videoOptions: MediaTrackConstraints = {
    width: { ideal: 500 },
    height: { ideal : 300 },
  };
  title: string = "Take A Photo";
  showConsent: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dataService: DataService, public cameraDialogRef: MatDialogRef<CameraComponent>, public message: MessageService) { }

  ngOnInit(): void {
    this.title = this.data.isDigiConsent ? "Digital Consent" : this.title;
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
      this.isMultipleCameras = mediaDevices && mediaDevices.length > 1;
    });
    this.userDetails = this.dataService.getLoginResp();
    if (this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
  }
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  takeSnapshot() {
    this.trigger.next();
  }
  onOffWebCam() {
    this.showWebcam = !this.showWebcam;
  }
  handleInitError(error: WebcamInitError) {
    this.message.openSnackBar("Unable to access camera", 'xy', ['error-snackbar']);
    this.cameraDialogRef.close(null);
  }
  changeWebCam(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }
  handleImage(webCamImage: WebcamImage) {
    this.webCamImage = webCamImage;
    let arr = webCamImage.imageAsDataUrl.split(",");
    let bstr = atob(arr[1]);
    let n = bstr.length;
    const u8Array = new Uint8Array(n);
    while (n--) {
      u8Array[n] = bstr.charCodeAt(n);
    }
    let now = new Date().toISOString().replace(/\D/g, '');
    let file: File = new File([u8Array], `img${now}.jpeg`, { type: "image/jpeg" });
    this.fileList.push(file);
    this.showWebcam = false;
  }
  acceptSnapshot() {
    if (this.data.isDigiConsent) {
      this.showConsent = true;
    } else {
      this.webCamImage = null;
      this.nextSnapshot = true;
      if (this.data.maxFiles == this.fileList.length) {
        this.closeCamera();
      }
    }
  }
  rejectSnapshot() {
    this.webCamImage = null;
    this.fileList.pop();
    this.showWebCam();
  }
  showWebCam() {
    this.nextSnapshot = false;
    this.showWebcam = true;
  }
  closeCamera() {
    this.cameraDialogRef.close(this.fileList);
  }
  onConsentSubmit() {
    //this.webCamImage = null;
    this.closeCamera();
  }
}
