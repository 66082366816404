import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http/http.service';
import { MyAccountService } from 'src/app/services/my-account/my-account.service';
import { Utils } from 'src/app/utils/common-functions';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { UpdateMailingAddressComponent } from 'src/app/modules/my-account/update-mailing-address/update-mailing-address.component';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-existing-neft',
  templateUrl: './existing-neft.component.html',
  styleUrls: ['./existing-neft.component.css']
})
export class ExistingNeftComponent implements OnInit {

  neftAccTypeCode: any;
  editNeftProceed: boolean = false;
  existingNeftDtlsFlag: boolean = false;
  pennyDropStatus: any;
  noExistingNeft: boolean;
  NEFTDetailAll: any;
  neftACCNo: any = [];
  isHNIUSer:boolean = false;
  isCPAdminPortal: boolean = false;
  userDetails: any = {};
  policyNo: string = "";
  NEFTDetail: any;
  ECSDetail: any;
  clientID: any;
  chequeFlag: boolean = false;
  fetchDetailsbtn: boolean;
  bankname: boolean;

  constructor(public router: Router, public dialog: MatDialog, public _httpService: HttpService,public myPoliciesService: MyPoliciesService,
    public myAccountService: MyAccountService, public utils: Utils, public dataService: DataService,@Inject(MAT_DIALOG_DATA) public data,
    public message: MessageService, public auth: AuthService, public dialogRef: MatDialogRef<ExistingNeftComponent>) { }

  ngOnInit(): void {
    if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    this.isCPAdminPortal = this.dataService.checkCPadmin();
    this.userDetails = this.dataService.getLoginResp();
    if(this.data.type == "CHEQUE"){
      this.chequeFlag = true;
    }
    this.NEFTDetail = this.data.NEFTup;
    this.ECSDetail = this.data.ECSup;
    this.pennyDropStatus = this.data.isPennyDrop;
    this.clientID = this.data.clientid;
    if (this.data.unclaimedAmtFlow) {
      //this.editNeftProceed = true;
      this.valueChanged({ value: "Add New" });
    }
    if(this.data.fetchDetailsbtn){
      this.fetchDetailsbtn = true;

  
    }
  }

   closeDialog() {
    if (!this.data.disableClose) {
      this.dialog.closeAll();
     } else {
       this.auth.logout('M');
     }
  }
  editNeftDtls(){
    this.editNeftProceed = true;
    this.existingNeftDtlsFlag = false;
  }
  valueChanged(event){
    this.noExistingNeft = false;
    this.NEFTDetail =[];
    if(event.value == 'Add New'){
      if(this.data.id == 'beneficiary' || this.data.role == "Beneficiary"){
        this.data.NEFTup= {
          "BEN_BANKNAME": this.data.NEFTup.BEN_BANKNAME,
          "BEN_ACCNUMBER": this.data.NEFTup.BEN_ACCNUMBER,
          "BEN_IFSCCODE": this.data.NEFTup.BEN_IFSCCODE,
          "BEN_BRANCHNAME": this.data.NEFTup.BEN_BRANCHNAME,
          "BEN_ACCNAME": this.data.NEFTup.BEN_ACCNAME,
          "BEN_ACCTYPE": this.data.NEFTup.BEN_ACCTYPE
        };
      } else {
        this.trackUserActions('add new');
      }
      this.dialog.open(UpdateMailingAddressComponent, {
        panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
        data: {
          NEFTup: Object.assign({}, this.data.NEFTup), ECSup: this.ECSDetail, type: this.data.type, 
          clientid: this.clientID, policyNo: this.data.policyNo, role: this.data.role, existingFlag: true,
          neftFlag: this.data.neftFlag, bankname : true, entryFlag: true, isPennyDrop: this.pennyDropStatus,id:this.data.id,parentSr: this.data.parentSr,chequeFlag: this.data.chequeFlag, mobileNo: this.data.mobileNo, emailId: this.data.emailId, unclaimedAmtFlags: this.data.unclaimedAmtFlags, unclaimedAmtFlow: this.data.unclaimedAmtFlow, disableClose: this.dataService.isDeepLink,fetchDetailsbtn : this.fetchDetailsbtn
        }, 
        disableClose: true
      });
      if (this.dataService.getIsCustomLink() && this.data.unclaimedAmtFlow) {
        this.dialogRef.close();
      }
      }
      if(event.value == 'Existing Account'){
        this.existingNeftDtls();
        this.trackUserActions('existing');
    }
  }
  valueChangedAccountNo(event){
    if(event.value != 'Select' && event.value != null){
      this.NEFTDetailAll.forEach(item => {
        if(event.value == item.ACC_NUM){
          this.NEFTDetail= item;       
          }
      });
      if(this.data.id == 'beneficiary' || this.data.role == "Beneficiary"){
        this.NEFTDetail= {
          "BEN_BANKNAME": this.NEFTDetail.BANKNAME,
          "BEN_ACCNUMBER": this.NEFTDetail.ACC_NUM,
          "BEN_IFSCCODE": this.NEFTDetail.IFSCCODE,
          "BEN_BRANCHNAME": this.NEFTDetail.BRANCHNAME,
          "BEN_ACCNAME": this.NEFTDetail.ACCNAME,
          "BEN_ACCTYPE": this.NEFTDetail.NEFTACCOUNTTYPE
        };
      }
      this.dialog.open(UpdateMailingAddressComponent, {
        panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
        data: {
          NEFTup: Object.assign({}, this.NEFTDetail), 
          ECSup: this.ECSDetail, type: this.data.type, 
          clientid: this.clientID,
           policyNo: this.data.policyNo, role: this.data.role, existingFlag: true,
           bankname : true,
          neftFlag: this.data.neftFlag, entryFlag: true, 
          isPennyDrop: this.pennyDropStatus,chequeFlag: this.data.chequeFlag, 
          existingAccDtls: true,id:this.data.id,parentSr: this.data.parentSr, 
          mobileNo: this.data.mobileNo, emailId: this.data.emailId, unclaimedAmtFlags: this.data.unclaimedAmtFlags,
           unclaimedAmtFlow: this.data.unclaimedAmtFlow, disableClose: this.dataService.isDeepLink,
           fetchDetailsbtn : this.fetchDetailsbtn
        }, 
        disableClose: true
      });
      if (this.dataService.getIsCustomLink() && this.data.unclaimedAmtFlow) {
        this.dialogRef.close();
      }
        }
  }
  existingNeftDtls(){
    let getDetails = {
      clientid: this.clientID,
      policyno: this.data.policyNo,
      mode: 'all'
    }
    this.neftACCNo= [];
    return this.myAccountService.getNEFTDetails(getDetails).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            this.NEFTDetailAll = response.body;
            this.bankname = true;
            // for (let key of Object.keys(this.NEFTDetail)) {
            //   this.NEFTDetail[key] = this.NEFTDetail[key] ? this.NEFTDetail[key] : "NA";
            // }
            this.NEFTDetailAll.forEach(item => {
              this.neftACCNo.push({"acc_no" : item.ACC_NUM, "bank_name" : item.BANKNAME});
            });
          }
        } else {          
          this._httpService.apiErrorHandler(response);
        }
        if(this.NEFTDetailAll){
          this.existingNeftDtlsFlag = true;
      }
      else{
        this.noExistingNeft = true;}
      }),
      catchError((error) => throwError(error));
  }
  trackUserActions(action: string) {
    let flowType = 'my account';
    if (this.data && this.data.viewDtls == 'lifeAssured') { 
      flowType = 'Beneficiaries - LA'
    }
    if (this.data && this.data.viewDtls == 'beneficiary') {
      flowType = 'Beneficiaries'
    }
    if (!this.chequeFlag) {
      this.utils.trackUserActions({ 'eventName': `Edit NEFT details - ${action}`, 'flowType': flowType });
    }
  }

}
