<div class="sr-track-page">
    <div class="sr-track-page-padding">
        <div class="sr-track-page-split flex">
            <div class="sr-track-page-left">
                <mat-card class="sr-track-left-section">
                    <mat-card-title class="login-title">Track now</mat-card-title>
                    <!-- <mat-card-subtitle class="details">Please provide details below to validate your information.
                    </mat-card-subtitle> -->
                    <!-- <form class="form-section" [formGroup]="srTrackForm" autocomplete="off">
                        <div class="form-field-section flex">
                            <div class="form-field-div">
                                <label class="form-field-label">Policy No <span class="error-two">*</span></label>
                                <mat-form-field class="mailing-form-field" floatLabel="never">
                                    <mat-placeholder>Policy No</mat-placeholder>
                                    <input type="text" matInput formControlName="policyNo" [readonly]="policyReadOnly"
                                        maxlength="8">
                                    <mat-error *ngIf="srTrackForm.controls.policyNo.errors?.required"
                                        class="error-message">
                                        Policy No is required
                                    </mat-error>
                                    <mat-error *ngIf="srTrackForm.controls.policyNo.errors?.pattern"
                                        class="error-message">
                                        Enter valid policy number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-div">
                                <label class="form-field-label">Date Of Birth <span class="error-two">*</span></label>
                                <mat-form-field class="mailing-form-field" floatLabel="never">
                                    <mat-placeholder>DD/MM/YYYY</mat-placeholder>
                                    <input type="text" matInput formControlName="dob" [max]="today"
                                        [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <img src="assets/date.png" matDatepickerToggleIcon class="picker-img">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="srTrackForm.controls.dob.errors?.required" class="error-message">
                                        Date of Birth is required
                                    </mat-error>
                                    <mat-error *ngIf="srTrackForm.controls.dob.errors?.errorMsg && !(srTrackForm.controls.dob.errors?.required)" class="error-message">
                                           {{srTrackForm.controls.dob.errors?.errorMsg}}
                                        </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-field-div captcha-field">
                                <label class="form-field-label">Captcha <span class="error-two">*</span></label>
                                <mat-form-field class="mailing-form-field" floatLabel="never">
                                    <mat-placeholder>Captcha</mat-placeholder>
                                    <input type="text" matInput formControlName="captcha">
                                    <app-captcha #clientCaptcha (validateCaptchaEvent)="checkCaptcha($event)"
                                        (reloadEvent)="clearClientCaptcha()" [enteredCaptcha]="srTrackForm.controls.captcha.value">
                                    </app-captcha>
                                    <mat-error *ngIf="srTrackForm.controls.captcha.errors?.required"
                                        class="error-message">
                                        Captcha is required
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="srTrackForm.controls.captcha.errors?.pattern">
                                        Invalid Captcha.
                                    </mat-error>
                                    <mat-error class="error-message"
                                        *ngIf="srTrackForm.controls.captcha.errors?.captchaIncorrect">
                                        Incorrect Captcha.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="button-div flex">
                            <button mat-button class="submit-button" type="submit" (click)="fetchSrDetails()"
                                *ngIf="!srNum" [disabled]="!(this.srTrackForm.valid)"
                                [class.disable]="!(this.srTrackForm.valid)">Validate</button>
                            <button mat-button class="submit-button" type="submit" (click)="validateSRPolicyDob()"
                                *ngIf="srNum" [disabled]="!(this.srTrackForm.valid)"
                                [class.disable]="!(this.srTrackForm.valid)">Validate</button>
                            <button mat-button class="clear-button" (click)="clearFields()">Clear</button>
                        </div>
                    </form> -->
                    <div *ngIf="srList.length > 0 && showTable" class="table-section">
                        <div class="sr-request">Please click on the SR number to track the request</div>
                        <div class="table-wrapper">
                            <table mat-table matSort [dataSource]="dataSource" class="sr-table">
                                <ng-container matColumnDef="SR_Number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>SR Number</th>
                                    <td mat-cell *matCellDef="let element" (click)="showSRDtls(element)">
                                        {{element.SR_NUM}}</td>
                                </ng-container>
                                <ng-container matColumnDef="Policy_No">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy No</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.POLICY_NUM}}</td>
                                </ng-container>
                                <ng-container matColumnDef="Date_Logged">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Logged</th>
                                    <td mat-cell *matCellDef="let element">{{element.CREATEDDATE}}</td>
                                </ng-container>
                                <ng-container matColumnDef="Request_type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Type</th>
                                    <td mat-cell *matCellDef="let element">{{element.REQTYPE}}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                    <div *ngIf="srList.length == 0 && !showTable" class="table-section">
                        <div class="display-message">{{displayMessage}}</div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>