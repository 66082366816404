import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MyAccountService } from 'src/app/services/my-account/my-account.service'
import { HttpService } from 'src/app/services/http/http.service';
import { Observable, throwError } from 'rxjs';
import { Utils } from "../../../utils/common-functions";
import { catchError, map, retry, distinctUntilChanged } from 'rxjs/operators';
import { EditCommunicationDetailsComponent } from '../edit-communication-details/edit-communication-details.component';
import { UpdateMailingAddressComponent } from 'src/app/modules/my-account/update-mailing-address/update-mailing-address.component';
import { DataService } from 'src/app/services/data/data.service';
import { MessageService } from 'src/app/services/message/message.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { SrPopupComponent } from '../../shared/components/sr-popup/sr-popup.component';
import { UpdateMailingAddressManualComponent } from '../update-mailing-address-manual/update-mailing-address-manual.component';
import { ExistingNeftComponent } from '../../shared/components/existing-neft/existing-neft.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwitchPolicyGroupPopupComponent } from '../../shared/components/switch-policy-group-popup/switch-policy-group-popup.component';
import * as moment from 'moment';
import tippy from 'tippy.js';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { nomineeMasterData } from '../../shared/nomineeMasterData';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  isHNIUSer:boolean = false;
  isCPAdminPortal: boolean = false;
  userDetails: any = {};
  dndresp: any;
  whatsappresp: any;
  policyNo: string = "";
  editPanForm: FormGroup;
  tabIndex: number = 0;
  isKycMessage: string ="Update KYC";
  deepLinkAction: string = '';
  @ViewChild('panDialog') public panDialog:TemplateRef<any>
  @ViewChild('editPan') public editPanDialog:TemplateRef<any>
  kycExpiry: string;
  redirectSIurl: string ='';
  panAadharLink: string;
  editPanErrMsg: string = '';
  editPanSuccMsg: string = '';
  panAadharLinkErrMsg: string = '';
  panAadharLinkSuccMsg: string = '';
  panAadharLinkCheckMsg: string = '';
  validatePANResp: any = {};
  isPanValid: boolean = false;
  offlineAadharAddrDtls: any = null;
  offlineAadharFlag: any;
  isVirtualBranch: boolean = false;

  isCallMeChecked :boolean ;
  isWhatsappChecked : boolean;
  whatsappStatus: boolean;
  callmeStatus: boolean;
  @ViewChild('dndDialog') public dndDialog: TemplateRef<any>;

  bankAccordionOpen: boolean;
  dndTabActive = false;
  neftFlag: boolean;
  ecsFlag: boolean;
  kycFlag: boolean;
  manualDivFlag: boolean;
  addPermanentAddressFlag: boolean;
  editNameFlag: boolean;
  templateRef: any;
  showPersonaldata: boolean = false;
  hideMailingAddress: boolean = false;
  hidePermanentAddress: boolean = false;
  NEFTDetail: any = {
    "ACCNAME": "NA",
    "ACC_NUM": "NA",
    "BANKNAME": "NA",
    "BRANCHNAME": "NA",
    "IFSCCODE": "NA",
    "MICRCODE": "NA",
    "NEFTACCOUNTTYPE": "NA"
  };
  ContactDetail: any = {
    "CONTACT_EMAIL_ADDRESS": "NA",
    "CONTACT_COUNTRY_CD": "NA",
    "CONTACT_CELL_PH_NUMBR": "NA",
    "CONTACT_HOME_PH_NUMBR": "NA",
    "CONTACT_HOME_STD": "NA",
    "CONTACT_WORK_PH_NUMBR": "NA",
    "CONTACT_WORK_STD": "NA",
    "CONTACT_ALT_COUNTRY_CD": "NA",
    "CONTACT_ALT_PH_NUM": "NA",
    "CONTACT_X_ALT_EMAIL": "NA"
  };
  ClientAddress: any = {            
    "CITY": "",
    "COUNTRY": "",
    "HOUSENUMBER": "",
    "LANDMARK": "",
    "PINCODE": "",
    "STATE": "",
    "STREET": ""
  };
  CommAddress: any = {           
    "CITY": "",
    "COUNTRY": "",
    "HOUSE_NO": "",
    "LANDMARK": "",
    "PINCODE": "",
    "STATE": "",
    "STREET_ADDRESS": ""
  }; 
  ECSDetail: any = {};
  personal_detail: any = {
    "FST_NAME": "NA",
    "DOB": "NA",
    "GENDER": "NA",
    "LAST_NAME": "NA",
    "MARITAL_STS": "NA",
    "PAN_NUMBER": "NA",
    "PDTLS_MID_NAME": "NA",
    "PDTLS_SALUTATION": "NA",
    "EIANo": "NA"
  };
  client_id:any= this.utils.getClientId();
  isDeepLink: boolean = false;
  ckycNumber: string;
  isMultiple: any;
  isDedupe: any;
  @ViewChild('srSubmitpopup') srSubmitpopup : TemplateRef<any>;
  NeftBtn: boolean = false;
  PanBtn: boolean = false;
  KycBtn: boolean = false;
  srNo: any;
  isPanVerified : boolean = false;
  fetchDetailsbtn: boolean
  NEFTDetailAll : string;
  salutationArrayNew: Array<any> = [];
  newMasterData: any;
  showSalutation: boolean = false;
  salutationArray: Array<any> = [];
  masterData: any;


  constructor(public router: Router, public dialog: MatDialog, public _httpService: HttpService,
    public myAccountService: MyAccountService, public utils: Utils, public dataService: DataService, public snackBar: MatSnackBar,
    public message: MessageService, public createSrService: CreateSRService, public myPoliciesService: MyPoliciesService, public fb: FormBuilder, public route: ActivatedRoute, public auth: AuthService, public crypto: EncryptDecryptService) { }

  ngOnInit(): void { 
    let tabName: string = this.route.snapshot.queryParamMap.get('tab');

    this.isCPAdminPortal = this.dataService.checkCPadmin();
    this.userDetails = this.dataService.getLoginResp();
    if(this.userDetails && this.userDetails.HNI_FLG === "Y") {
      this.isHNIUSer = true;
    }
    if (this.dataService.dedupeRespone && this.dataService.dedupeRespone.length > 1) {
      this.isMultiple = true
    }
    else {
      this.isMultiple = false
    }
    this.dataService.dedupeResp$.subscribe(isDedupe => {
      //this.getDedupeData();
      this.isDedupe = isDedupe;
      if (isDedupe) {
        this.isMultiple = true
      }
      else {
        this.isMultiple = false
      }
    });
    if (this.userDetails && this.userDetails.action) {
      this.deepLinkAction = this.userDetails.action;
    }
    this.isVirtualBranch = this.dataService.getIsVirtualBranch();
    this.isDeepLink = this.dataService.isCustomLink || this.isVirtualBranch;
    let virtualBranchData = this.dataService.getVirtualBranchDtls();
    if (virtualBranchData && virtualBranchData.action) {
      this.deepLinkAction = virtualBranchData.action;
    }
    this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
      if (policyCardDtls[0] && policyCardDtls[0].POLICYNO) {
        this.policyNo = policyCardDtls[0].POLICYNO.replace(/\s+/g,'');
        this.getOfflineAadharAddrDtls();
        this.userDetails = this.dataService.getLoginResp();
        let action = this.route.snapshot.queryParamMap.get('action');
        if (this.userDetails && this.userDetails.action) {
          this.deepLinkAction = this.userDetails.action;
        } else if (action == "UPD_PAN") {
          this.deepLinkAction = "PAN_CARD_UPD";
          this.setTabIndex('personal');
        }
        if (this.dataService.isDeepLink && this.deepLinkAction) {
          this.startDeepLinkJourney(this.deepLinkAction)
        } else {
          this.setTabIndex(tabName);
        }
        this.dataService.searchSubj.subscribe(data => {
          if (data && data.page && data.page === 'MY_ACCOUNT') {
            this.setTabIndex(data.tab);            
          }
        });
      }
    });
    this.editPanForm = this.fb.group({
      "panNo": ['', [CustomValidators.panNo]],
      "panName": ['', [CustomValidators.clientName, Validators.required]],
      "panDOB": ['', [CustomValidators.noFutureDates]]
    });
    this.editPanForm.controls.panNo.valueChanges.pipe(distinctUntilChanged()).subscribe(s => { if (s) { this.editPanForm.controls.panNo.patchValue(s.toUpperCase()) }});
   }

  getNEFTDetails() {
    let getDetails = {
      clientid: this.utils.getClientId(),
      policyno: this.policyNo,
      mode: 'active'
    }
    return this.myAccountService.getNEFTDetails(getDetails).subscribe((response) => { 
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            this.NEFTDetail = response.body[0];
            for (let key of Object.keys(this.NEFTDetail)) {
              this.NEFTDetail[key] = this.NEFTDetail[key] ? this.NEFTDetail[key] : "NA";
            }
            this.NEFTDetailAll = response.body;
          }else{
           this.NEFTDetailAll = response.body;
          }
        } else {          
          let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};                 
          this._httpService.apiErrorHandler(response,null,trackMsgObj); 
        }
      }),
      catchError((error) => throwError(error));
  }
  getECSDetails() {
    let getECSDetail = {
      clientid: this.utils.getClientId(),
      policyno: this.policyNo,
      subactivity: "NACH_SIECS"
    }
    return this.myAccountService.getECSDetails(getECSDetail)
    .subscribe((response) => { 
      if (this._httpService.isSuccessResponse(response)) {
      this.ECSDetail = response.body;
    }
    else {
      let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
      this._httpService.apiErrorHandler(response,null,trackMsgObj);
    }
    }),
    catchError((error) => throwError(error));
  }
  getPersonalDtls() {
    let personalDtls = {
      clientid: this.utils.getClientId()
    }
    this.showPersonaldata = true;
    return this.myAccountService.getPersonalDtls(personalDtls)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          if (response.body && response.body.length != 0) {
            this.personal_detail = response.body[0];
            this.panAadharLink = this.personal_detail.PALFLAG == "Y" ? "Yes" : "No";
            if(this.personal_detail.PAN_NUMBER && this.personal_detail.PanVerified == "Y")
              this.isPanVerified =  true
            else
              this.isPanVerified = false;
            for (let key of Object.keys(this.personal_detail)) {
              this.personal_detail[key] = this.personal_detail[key] && this.personal_detail[key].trim() ? this.personal_detail[key] : "NA";
            }
            setTimeout(() => {
              tippy('.tooltip-popover-eia', {
                content: "It has all insurance policy documents in electronic format",
                theme: 'light-border-custom',
              })
            }, 10);
            if((this.personal_detail && this.personal_detail.GENDER == "Male")){
              this.masterData = nomineeMasterData.GCOM_TITLE_SAL_MALE;
              this.newMasterData = nomineeMasterData.GCOM_TITLE_SAL_MALE_NEW;
            } else{
              this.masterData = nomineeMasterData.GCOM_TITLE_SAL_FEMALE;
              this.newMasterData = nomineeMasterData.GCOM_TITLE_SAL_FEMALE_NEW;
            }
            let salArray = Object.values(this.masterData);
            for (let item of salArray) {
              this.salutationArray.push(item);
            }
            for(let i of this.salutationArray){
              if(i.includes(this.personal_detail.PDTLS_SALUTATION)){
              this.showSalutation = true
            }
          }
          let salArrayNew = Object.values(this.newMasterData);
          for (let item of salArrayNew) {
            this.salutationArrayNew.push(item);  
          }
          for(let j of this.salutationArrayNew){
            if(j.includes(this.personal_detail.PDTLS_SALUTATION)){
              this.personal_detail.PDTLS_SALUTATION = j[1];
              this.showSalutation = true
            }
          }
          }
        }
        else {
          let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
          this._httpService.apiErrorHandler(response,null,trackMsgObj);
        }
        if (this.deepLinkAction == "CHNG_NAME") {
          this.startUpdateNameJourney();
        } else if (this.deepLinkAction == "PAN_CARD_UPD") {
          this.startPanJourney();
        }
      }),
      catchError((error) => throwError(error));
  }
  CContactDetailsval() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getCContactDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response)) {
        if (response.body && response.body.length != 0) {
          this.ContactDetail = response.body[0];
          for (let key of Object.keys(this.ContactDetail)) {
            this.ContactDetail[key] = this.ContactDetail[key] ? this.ContactDetail[key] : "NA";
          }
        }
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
        this._httpService.apiErrorHandler(response,null,trackMsgObj);
      }
      if (this.deepLinkAction == "CHNG_CON_NUM") {
        this.startUpdateContactJourney();
      }
    }),
      catchError((error) => throwError(error));
  }
  ClientAddressVal() {
    let getDetails = {
      clientid: this.utils.getClientId(),
    }
    return this.myAccountService.getClientAddressDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response) && response.body && response.body.length !== 0) {
        for (let key of Object.keys(this.ClientAddress)) {
          this.ClientAddress[key] = response.body[0][key] ? response.body[0][key] : "";
        }
        this.hidePermanentAddress = Object.values(this.ClientAddress).every(val => val == "");
      } else {
        this.hidePermanentAddress = true;
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
        this._httpService.apiErrorHandler(response,null,trackMsgObj);
      }
      if (this.deepLinkAction == "CHNG_ADDRESS_PERM") {
        this.updateMailingAddress("Permanent");
      }
    }),
      catchError((error) => throwError(error));
  } 
  CommAddressVal(policyNo: string) {
    let getDetails = {
      clientid: this.utils.getClientId(),
      policyno: policyNo
    }
    return this.myAccountService.getCommAddressDetails(getDetails).subscribe((response) => {
      if (this._httpService.isSuccessResponse(response) && response.body && response.body.length !== 0) {
        for (let key of Object.keys(this.CommAddress)) {
          this.CommAddress[key] = response.body[0][key] ? response.body[0][key] : "";
        }
        this.hideMailingAddress = Object.values(this.CommAddress).every(val => val == "");
      } else {
        this.hideMailingAddress = true;
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
        this._httpService.apiErrorHandler(response,null,trackMsgObj);
      }
      if (this.deepLinkAction == "CHNG_ADDRESS_COMM") {
        this.updateMailingAddress("Mailing");
      }
    }),
      catchError((error) => throwError(error));
  }  
  onCallMeChange(e) {
    if (!this.isCPAdminPortal) {
      this.isCallMeChecked = e.checked;
      this.checkCallme();
      if (e.checked) {
        this.utils.trackUserActions({ "eventName": "DND call me - on" });
      } else {
        this.utils.trackUserActions({ "eventName": "DND call me - off" });
      }
    }
  }
  onWhatsappChange(e) {
    if (!this.isCPAdminPortal) {
      this.isWhatsappChecked = e.checked;
      this.checkWhatsapp(this.dndDialog);
      if (e.checked) {
        this.utils.trackUserActions({ "eventName": "DND whatsapp me - on" });
      } else {
        this.utils.trackUserActions({ "eventName": "DND whatsapp me - off" });
      }
    }
  }
  myAccountTabs(tab) {
    this.dndTabActive = false;
    if (tab.tab.textLabel == "dnd") {
      this.dndTabActive = true;
      this.getDNDstatus();
      this.getWhatsappStatus();
    } else if (tab.tab.textLabel == "personal") {
      this.trackUserActions({ "eventName" : "Personal details" });
      this.onPersonalTabClick();
      this.tabIndex = 2;
    } else if (tab.tab.textLabel == "bank") {
      this.trackUserActions({ "eventName" : "Bank details" });
      this.getNEFTDetails();
      this.tabIndex = 1;
    } else if (tab.tab.textLabel == "contact") {
      this.trackUserActions({ "eventName" : "Contact details" });
      this.onContactTabClick();
      this.tabIndex = 0;
    }
  }
  dndCancel() {
    /* if (this.id == "Call") {
      this.isCallMeChecked = this.callmeStatus;
    } else {
      this.isWhatsappChecked = this.whatsappStatus;
    } */
    this.isWhatsappChecked = this.whatsappStatus;
    this.dialog.closeAll();
  }
  checkWhatsapp(templateRef: TemplateRef<any>) {
    if (!this.isCPAdminPortal) {
      if (this.isWhatsappChecked) {
        this.updateWhatsappDND();
      } else {
        this.dialog.open(templateRef, { width: '500px', panelClass: 'update-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
      }
    }
  }
  checkCallme() {
    if (!this.isCPAdminPortal) {
      let reqBody = {
        policyno: this.policyNo,
        subactivity: 'DND_REGISTRATION'
      }
      return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
        if (this._httpService.isSuccessResponse(resp)) {
          this.createCallmeSR();
          this.dialog.closeAll();
        } else {
          let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
          this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        }
      }),
        catchError((error) => throwError(error));
    }    
  }
  getDNDstatus() {
    let dndreqbody = {
      clientID: this.utils.getClientId()
    }
    return this.myAccountService.getDNDstatus(dndreqbody)
      .subscribe((response) => {
        if (this._httpService.isSuccessResponse(response)) {
          this.dndresp = response.body; 
          this.callmeStatus = this.dndresp.CALL_FLAG == 'Y' ? false : true;
          this.isCallMeChecked = this.dndresp.CALL_FLAG == 'Y' ? false : true;
 }
      else {
          let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
          this._httpService.apiErrorHandler(response,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getWhatsappStatus() {
    let whatsappreqbody = {
      "clientid": this.utils.getClientId()
    }
    return this.myAccountService.getWhatsappDetails(whatsappreqbody).subscribe((resp) => {
      if (resp && resp.head && resp.head.status && resp.head.status.toLowerCase() === "success") {
          this.whatsappresp = resp.body; 
          this.whatsappStatus = this.whatsappresp.method == 'OPTIN' ? true : false;
          this.isWhatsappChecked = this.whatsappresp.method == 'OPTIN' ? true : false;
         }
         else {
          this.isWhatsappChecked = false
          let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
          this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        }
    })
  }
  updateWhatsappDND() {
    if (this.ContactDetail.CONTACT_CELL_PH_NUMBR != "NA") {
      let method
      if (this.isWhatsappChecked == false) {
        method = "OPT_OUT"
      } else {
        method = "OPT_IN"
      }
      let whatsappreqbody = {
        "method": method,
        "clientid": this.utils.getClientId()
      }
      return this.myAccountService.getWhatsappUpdate(whatsappreqbody).subscribe((resp) => {
        if (resp.head.status.toLowerCase() === "success" && whatsappreqbody.method == "OPT_IN") {
          this.isWhatsappChecked = true;
          this.whatsappStatus = true;
          this.message.openSnackBar(`Dear Customer, your subscription has been enabled for WhatsApp services. To know more, refer to our page <a href="https://www.hdfclife.com/whatsapp" target="_blank" rel="noopener noreferrer">https://www.hdfclife.com/whatsapp</a>`, 'xy', ['success-snackbar']);
          this.trackUserActions({ 'eventName': `message - Dear Customer, your subscription has been enabled for WhatsApp services. To know more, refer to our page <a href="https://www.hdfclife.com/whatsapp" target="_blank" rel="noopener noreferrer">https://www.hdfclife.com/whatsapp</a>`,"error_code":"SM" });
        } else if (resp.head.status.toLowerCase() === "success" && whatsappreqbody.method == "OPT_OUT") {
          this.isWhatsappChecked = false;
          this.whatsappStatus = false;
          this.message.openSnackBar("Dear Customer, you are successfully opted out from WhatsApp services", 'xy', ['success-snackbar']);
          this.trackUserActions({ 'eventName': `message - Dear Customer, you are successfully opted out from WhatsApp services`,"error_code":"SM" });
        } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
          // this.message.openSnackBar("Please update your mobile number from My Account section, post that you can optin for WhatsApp.", 'xy', ['error-snackbar']);
          this.isWhatsappChecked = !this.isWhatsappChecked;
        }
        this.dialog.closeAll();
      })
    } else {
      this.isWhatsappChecked = false
      this.message.openSnackBar("Please update your mobile number from My Account section, post that you can optin for WhatsApp.", 'xy', ['error-snackbar']);
      this.trackUserActions({ 'eventName': `message - Please update your mobile number from My Account section, post that you can optin for WhatsApp.`,"error_code":"VE" });
      /* if (this.dataService.isVirtualBranch) {
        this.message.postMessage({status: 'F'})
      } */
    }
    this.dialog.closeAll();
  }

  createCallmeSR() {
    let reason = this.isCallMeChecked == false ? "Y" : "N";
    const reqBody = {
      mobileno: this.dndresp.MOBILE_NUM,
      clientid: this.utils.getClientId(),
      subactivity: 'DND_REGISTRATION',
      action: "CREATE",
      salesFlag: "N",
      modeofauth: "OTP",
      verifytransrk: "4",
      srinput: {
        dncdtls: {
          "dncmsg": [{
            "reason": reason
          }],
          "dnccall": [{
            "reason": reason
          }],
        },
        dnddtls: {
          dndsalesflag: [{
            "reason": "N"
          }]
        }
      },
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
    }
    };
    return this.createSrService.createSRDND(reqBody).subscribe((resp) => {
      this.dialog.closeAll();
      if (this._httpService.isSuccessResponse(resp)) {
        this.callmeStatus = this.isCallMeChecked;
        let trackMsgObj ={"flowType": 'my account',"error_code":"SM"};
        this.message.openSnackBar(`DND details updated successfully`, 'xy', ['success-snackbar'],trackMsgObj);
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
        this.isCallMeChecked = false
      }
    }),
      catchError((error) => throwError(error));
  }
  updatePanDetails() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'PAN_CARD_UPD'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.startPanJourney();        
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  startPanJourney() {
    setTimeout(() => {
      // if (this.personal_detail && this.personal_detail.PAN_NUMBER != "" && this.personal_detail.PAN_NUMBER != "NA") {
      //   this.dialog.open(this.panDialog, { width: '500px', panelClass: 'update-pan-popup', backdropClass: 'update-pan-popup-backdrop',disableClose: true});
      // } else {
        this.editPanDetails(this.editPanDialog);
        //this.dialog.open(this.editPanDialog, { width: '500px', panelClass: 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop',disableClose:true});
      // }
    })
  }
  editPanDetails(templateRef: TemplateRef<any>) {
    let panNum: string = '';
    panNum = this.personal_detail.PAN_NUMBER != 'NA' ? this.personal_detail.PAN_NUMBER : '';
    this.editPanForm.controls.panNo.setValue(panNum);
    this.editPanForm.controls.panName.setValue('');
    if (this.personal_detail.DOB && this.personal_detail.DOB != 'NA') {
      let dob = moment(this.personal_detail.DOB, 'DD/MM/YYYY');
      this.editPanForm.controls.panDOB.setValue(dob);
    } else {
      this.editPanForm.controls.panDOB.setValue('');
      setTimeout(() => tippy(".tooltip-popover-pan-dob", { content: "In case of corporate entities, please enter Date of Incorporation", theme: "light-border-pan" }), 500);      
    }
    this.isPanValid = false;
    this.validatePANResp = {};
    this.editPanErrMsg = '';
    this.editPanSuccMsg = '';
    this.panAadharLinkErrMsg = '';
    this.panAadharLinkCheckMsg = '';
    this.panAadharLinkSuccMsg = '';
    this.dialog.closeAll();
    this.dialog.open(templateRef, { width: '500px', panelClass: 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop',disableClose:true});
  }
  updateMailingAddress(addressType: string) {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_ADDRESS'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        const dialogRef = this.dialog.open(UpdateMailingAddressComponent, { 
          panelClass: this.isVirtualBranch ? 'app-update-mailing-address-iframe' : 'app-update-mailing-address', 
          backdropClass: 'edit-communication-backdrop', 
          data: {
            CliAdd: Object.assign({}, this.ClientAddress), ComAdd: Object.assign({}, this.CommAddress), type: addressType, policyNo: this.policyNo, role: "Owner", clientid: this.utils.getClientId(),
            manualDivFlag: true, entryFlag: true, CheckboxFlag: true, addPermanentAddressFlag: true, disableClose: this.isDeepLink,
          }, 
          disableClose: true },
        );        
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  updateMailingAddressPersonal() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_NAME'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.startUpdateNameJourney();

      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  startUpdateNameJourney() {
    this.dialog.open(UpdateMailingAddressComponent, 
      {
        panelClass: 'app-update-mailing-address',
        backdropClass: 'edit-communication-backdrop',
        disableClose: true,
        data: {
          policyNo: this.policyNo, role: "Owner", clientid: this.utils.getClientId(), gender:this.personal_detail.GENDER,
          manualDivFlag: true, entryFlag: true, CheckboxFlag: true, editNameFlag: true, disableClose: this.isDeepLink
        }
      },
    );
  }
  updateKycDetails() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'UPD_KYC'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.startKycJourney();
      } else {        
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  startKycJourney() {
    this.dialog.open(UpdateMailingAddressComponent, { 
      panelClass: this.isVirtualBranch ? 'app-update-mailing-address-iframe' : 'app-update-mailing-address', 
      backdropClass: 'edit-communication-backdrop', 
      disableClose: true,
      data: { policyNo: this.policyNo, kycFlag: true, entryFlag: true, CheckboxFlag: true, disableClose: this.dataService.isDeepLink },
    });        
  }
  updateNeftDetails(accoType: string) {
    this.trackUserActions({ 'eventName': 'Edit NEFT details' });
    let subactivity = accoType == 'NEFT' ? 'STND_NEFT':'SI_ECS_ACTV_REQ'
    let reqBody = {
      policyno: this.policyNo,
      subactivity: subactivity
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {        
        if(accoType == 'NEFT') {
          this.getPennyDropCount();        
        }
        else if(accoType == 'ECS') {
          this.getredirectActivateSI();
        }
      } else if (this._httpService.isSuccessResponse(resp) && !(resp.body.status.toLowerCase() === "success")) {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};         
        this.message.openSnackBar(resp.body.errormsg, 'xy', ['error-snackbar'],trackMsgObj);
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));    
  }
  editCommunicationDetails() {
    let reqBody = {
      policyno: this.policyNo,
      subactivity: 'CHNG_CON_NUM'
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.startUpdateContactJourney();
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"VE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));    
  }
  startUpdateContactJourney() {
    this.dialog.open(EditCommunicationDetailsComponent,
      {
        disableClose: true,
        panelClass: this.isVirtualBranch  ? 'app-edit-communication-details-iframe' : 'app-edit-communication-details',
        backdropClass: 'edit-communication-backdrop',
        data: {
          dtls: Object.assign({}, this.ContactDetail),
          policyNo: this.policyNo,
          role: "Owner",
          disableClose: this.isDeepLink
        }
      },
    );
  }
  createUpdatePanSR() {
    const reqBody = {
      policyno: this.policyNo,
      clientid: this.utils.getClientId(),
      role: 'Owner',
      subactivity: 'PAN_CARD_UPD',
      action: "CREATE",
      panno: this.validatePANResp.panno,
      aadharseeding: this.validatePANResp.aadharseedingstatus === 'Y' ? 'Y' : 'N',
      /* srinput: {
        pandtls: {
          panfname: this.validatePANResp.firstname,
          panlname: this.validatePANResp.lastname,
          panmname: this.validatePANResp.middlename,
          panexctmatch: "YES",
          aadharseeding: this.validatePANResp.aadharseedingstatus === 'Y' ? 'Y' : 'N' 
        }
      }, */
      meetingdetails: {
        meetingplace: "",
        communicationtype: "",
        datetime: ""
    }
    };
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    reqBody.panno = this.crypto.encryptJson(reqBody.panno);
    reqBody.aadharseeding = this.crypto.encryptJson(reqBody.aadharseeding);
    return this.createSrService.createSubmitSR(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.srNo = resp.body.srbasicinfo.srno;
        this.dialog.closeAll();
        if (this.isDeepLink) {
          this._httpService.showSuccessMsg(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, true);
          this.trackUserActions({ 'eventName': `message - Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`,"error_code":"SM" });
        } else {
          // this.message.openSnackBar(`Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar']);
          this.getNextBestAction('PAN');
          this.trackUserActions({ 'eventName': `message - Service Request No. ${resp.body.srbasicinfo.srno} has been created successfully. To track it, please visit the service request section of my account portal.`,"error_code":"SM" });
        }
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  validatePAN() {
    let panno = this.editPanForm.controls.panNo.value;
    let reqBody = {
      userid: this.utils.getClientId(),
      pan: panno,
      uname: this.editPanForm.controls.panName.value,
      udob: moment(this.editPanForm.controls.panDOB.value).format('DD/MM/YYYY'),
    }    
    this.isPanValid = false;
    this.validatePANResp = {};
    this.editPanErrMsg = '';
    this.editPanSuccMsg = '';
    this.panAadharLinkErrMsg = '';
    this.panAadharLinkCheckMsg = '';
    this.panAadharLinkSuccMsg = '';
    this.trackUserActions({'eventName': 'Edit PAN - verify pan'})
    return this.createSrService.panValidation(reqBody, "validatePanAadhar").subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.error_msg) {
          this.panAadharLinkErrMsg = resp.body.error_msg;
          this.trackUserActions({'eventName': 'Edit PAN - verify pan', 'error': this.panAadharLinkErrMsg });
        }
        if (resp.body && resp.body.succ_msg) {
          this.panAadharLinkSuccMsg = resp.body.succ_msg;
          this.trackUserActions({'eventName': 'Edit PAN - verify pan', 'error': this.panAadharLinkSuccMsg });
        }
        if (resp.body && resp.body.check_msg) {
          this.panAadharLinkCheckMsg = resp.body.check_msg;
          this.trackUserActions({'eventName': 'Edit PAN - verify pan', 'error': this.panAadharLinkCheckMsg });
        }
        if (resp.body && resp.body.isValid === "YES") {
          //this.validatePANResp = resp.body.pandtls[0];
          this.validatePANResp["panno"] = panno;
          this.validatePANResp["aadharseedingstatus"] = resp.body.aadharseedingstatus;
          this.isPanValid = true;
          this.editPanSuccMsg = resp.body.msg;
          this.createUpdatePanSR();
          this.isPanVerified =  true;
        } else {
          this.editPanErrMsg = resp.body.msg;
          this.trackUserActions({'eventName': 'Edit PAN - verify pan', 'error': this.editPanErrMsg });
        }
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
    catchError((error) => throwError(error));
  }
  getKycDtls() {
    let reqBody = {
      "clientid": this.utils.getClientId()
    }
    return this.myAccountService.getKycExpiryDtls(reqBody).subscribe(resp => {
      if (this._httpService.isSuccessResponse(resp)) {
        if(resp.body && resp.body.DAY_DIFF && resp.body.DAY_DIFF < 0){
          this.isKycMessage="Expired";
        }
        else if(resp.body && resp.body.DAY_DIFF && resp.body.DAY_DIFF > 0){
          this.isKycMessage="Active";
        }
        else{ 
          this.isKycMessage= "NA";
        }
        if(resp.body && resp.body.KYC_VAL_DT) {
          this.kycExpiry= "Expiry date: " + resp.body.KYC_VAL_DT;
        }
        else{ 
          this.kycExpiry= "Expiry date: NA";
        }
        if(resp.body && resp.body.ckycNo && resp.body.ckycNo !=null ){
          let ckycNo = resp.body.ckycNo.replace(/\s+/g,'');
          if(ckycNo.length != 0){
            this.ckycNumber = "CKYC no: " + ckycNo;
          } else {
            this.ckycNumber = null;
          }
        }
        else{
          this.ckycNumber = null;
        }
      } 
      else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
    catchError((error) => throwError(error));
  }
  onPersonalTabClick() {
    this.getPersonalDtls();
    this.getKycDtls();
  }
  onContactTabClick() {
    this.CContactDetailsval();
    this.ClientAddressVal();
    this.CommAddressVal(this.policyNo);
  }
  setTabIndex(tabName: string) {
    tabName = tabName ? tabName.toLowerCase() : tabName;
    this.dndTabActive = false;
    if (tabName == "bank") {
      this.trackUserActions({ "eventName" : "Bank details" });
      this.getNEFTDetails();
      this.tabIndex = 1;
    } else if (tabName == "personal") {
      this.trackUserActions({ "eventName" : "Personal details" });
      this.onPersonalTabClick();
      this.tabIndex = 2;
    } else if (tabName == "dnd") {
      this.dndTabActive = true;
      this.getDNDstatus();
      this.getWhatsappStatus();
      this.tabIndex = 3;
    } else {
      this.trackUserActions({ "eventName" : "Contact details" });
      this.onContactTabClick();
      this.tabIndex = 0;
    }
  }
  startDeepLinkJourney(action: string) {
    if (action == "CHNG_CON_NUM") {
      this.CContactDetailsval();
    } else if (action == "PAN_CARD_UPD" || action == "CHNG_NAME") {
      this.getPersonalDtls();
    } else if (action == "UPD_KYC") {
      this.startKycJourney();
    } else if (action == "CHNG_IN_MARITALSTATUS") {
      this.updateMaritalStatus();
    }
  }
  closeDialog() {
    if (!this.isDeepLink) {
      this.dialog.closeAll();
      this.router.navigate(['/my-account'])
    } else if (this.dataService.isCustomLink) {
      this.auth.logout('M');
    }   
  }
  submitEditPanForm() {
    if (this.editPanForm && this.editPanForm.valid) {
      this.validatePAN();
    } else {
      this.editPanForm.markAllAsTouched();
    }
  }
  getredirectActivateSI(){
    const activateSIreqBody = {
      "clientid": this.utils.getClientId(),
      "policyno": this.policyNo
    };
    return this.myPoliciesService.redirectActivateSI(activateSIreqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        this.redirectSIurl=resp.body.url+resp.body.data;
        window.open(this.redirectSIurl,'_blank');
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));
  }
  getPennyDropCount() {
    let reqBody = {
      clientId: this.utils.getClientId()
    };
    return this.createSrService.getPennyDropCount(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp)) {
        if(resp.body.pennyStatus == true){
             this.fetchDetailsbtn = true
        } else {
          this.fetchDetailsbtn = false;
        }
        if(this.NEFTDetailAll.length == 0){
          this.dialog.open(UpdateMailingAddressComponent, {
            panelClass: 'app-update-mailing-address', backdropClass: 'edit-communication-backdrop', 
            data: {
              NEFTup: Object.assign({}, this.NEFTDetail), ECSup: this.ECSDetail, type: 'NEFT', clientid: this.utils.getClientId(), policyNo: this.policyNo, role: "Owner",bankname : false,
              neftFlag: true, entryFlag: true, isPennyDrop: resp.body.pennyStatus,existingFlag: true,disableClose: this.dataService.isDeepLink,fetchDetailsbtn : this.fetchDetailsbtn,showFetch:true
            },
            disableClose: true
          });          
         }
         else{
        this.dialog.open(ExistingNeftComponent, {
          panelClass: 'existing-neft-popup', backdropClass: 'existing-neft-popup-backdrop', 
          data: {
              NEFTup: Object.assign({}, this.NEFTDetail), ECSup: this.ECSDetail, type: 'NEFT', clientid: this.utils.getClientId(), policyNo: this.policyNo, role: "Owner",bankname : true,
              neftFlag: true, entryFlag: true, isPennyDrop: resp.body.pennyStatus,fetchDetailsbtn : this.fetchDetailsbtn,existingAccDtls : true
          }, 
          disableClose: true
        });
         }
              
     
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    });
  }
  getOfflineAadharAddrDtls() {
    this.offlineAadharFlag = this.route.snapshot.queryParamMap.get('redirect');
    this.offlineAadharAddrDtls = this.dataService.getOfflineAadharAddrDtls();
    if (this.offlineAadharFlag == 'ekyc' && this.offlineAadharAddrDtls) {
      let type = this.offlineAadharAddrDtls.eKYC_editRequestPanelID == "address_details_ocr" ? "Permanent" : "Mailing";
      this.dialog.open(UpdateMailingAddressManualComponent, {
        panelClass: 'app-update-mailing-address-manual', backdropClass: 'edit-communication-backdrop',
        data: {
          showOcrUpdate: true,
          offlineAadhar: true,
          ocrResp: this.offlineAadharAddrDtls,
          offlineadhaarConsent : "Y", //checkbox is selected
          CliAdd: Object.assign({}, this.ClientAddress), ComAdd: Object.assign({}, this.CommAddress), type: type, policyNo: this.policyNo, role: "Owner",
        },
        disableClose: true
      });
    }
  }
  trackUserActions(payload) {
    this.utils.trackUserActions({ 'flowType': 'my account', ...payload });
  }
  switchClientId() {
    this.utils.trackUserActions({ "eventName" : "Switch client ID" });
    this.dialog.open(SwitchPolicyGroupPopupComponent, { width: '400px',panelClass: 'switch-policy-grp', backdropClass: 'switch-policy-grp-backdrop', disableClose: true})
    //data: { landingScreen : this.landingScreenDetail.landingScreen } 
  }
  getNextBestAction(type){
    let reqBody = {
      "clientid" : this.utils.getClientId(),
      "ACTION" : type
    }
    return this.myAccountService.getNextBestAction(reqBody).subscribe((resp) => {
      if(this._httpService.isSuccessResponse(resp)) {
        console.log(resp)
        if(resp.body.NEFT == "N" || resp.body.PAN == "N" || resp.body.KYC == "N"){
        if(resp.body.NEFT == "Y" || type == "NEFT"){
          this.NeftBtn = true;
        } else{
          this.NeftBtn = false;
        } if(resp.body.PAN == "Y" || type == "PAN"){
          this.PanBtn = true;
        } else{
          this.PanBtn = false;
        } if(resp.body.KYC == "Y" || type == "KYC"){
          this.KycBtn = true;
        } else{
          this.KycBtn = false;
        }
        this.dialog.open(this.srSubmitpopup, { width: '500px', panelClass: 'edit-pan-popup', backdropClass: 'update-pan-popup-backdrop', disableClose: true });
      } else {
        this.message.openSnackBar(`Service Request No. ${this.srNo} has been created successfully. To track it, please visit the service request section of my account portal.`, 'xy', ['success-snackbar']);
      }
      } else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }), catchError((error) => throwError(error))
  }

  updateMaritalStatus(){
    let reqBody = {
      policyno: this.policyNo,
      subactivity: "CHNG_IN_MARITALSTATUS"
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this._httpService.isSuccessResponse(resp) && resp.body.status.toLowerCase() === "success") {  
    
    this.dialog.open(UpdateMailingAddressComponent, 
      {
        panelClass: 'app-update-mailing-address',
        backdropClass: 'edit-communication-backdrop',
        data: {
          policyNo: this.policyNo, role: "Owner", clientid: this.utils.getClientId(), maritalStatus: this.personal_detail.MARITAL_STS,
          manualDivFlag: true, entryFlag: true, CheckboxFlag: true, editMaritalFlag: true, disableClose: this.isDeepLink
        }, disableClose: true
      },
    ); 
      }
      else {
        let trackMsgObj ={"flowType": 'my account',"error_code":"UEE"};        
        this._httpService.apiErrorHandler(resp,null,trackMsgObj);
      }
    }),
      catchError((error) => throwError(error));    
  }
  
}
