import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { MyPoliciesService } from 'src/app/services/my-policies/my-policies.service';
import { MessageService } from 'src/app/services/message/message.service';
import { usefulLinks } from 'src/app/utils/app-constants';
import { Utils } from 'src/app/utils/common-functions';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { deepLinkRouteMap } from 'src/app/utils/deeplink-route-map';
import { HttpService } from 'src/app/services/http/http.service';
import { CreateSRService } from 'src/app/services/create-sr/create-s-r.service';
import { serviceURL } from 'src/environments/environment';
import { CustomLinkService } from 'src/app/services/custom-link/custom-link.service';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../../shared/components/message-popup/message-popup.component';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';


@Component({
  selector: 'app-custom-link',
  templateUrl: './custom-link.component.html',
  styleUrls: ['./custom-link.component.css']
})
export class CustomLinkComponent implements OnInit {
  customLinkDtls: any;
  isFormReset: boolean = false;
  redirectLinks = usefulLinks;
  isCustomLink: boolean;
  pageId: string = '';
  showPage: boolean = false;
  action: string = '';
  loginSource: String;
  covidRedirectFlag: string;
  srInputParams: { "srNum": string; "srType": any; }[];
  token: string;
  policyDtls: any;
  cspsUrlRk: string = '';
  srno: string = '';
  srNumber: string ='';
  mail ="service@hdfclife.com";
  link = this.mail.link("mailto:service@hdfclife.com");
  notAuthorizedError: string = "You are not authorised to view this page. Kindly get in touch with your nearest Branch or write an e-mail to our Service desk";
  linkInvalidError: string = `The link is invalid. For any further assistance, please contact us on 1860 267 9999 or email us at  ${this.link}`;
  phsFlag: string = '';
  readOnly: boolean = false;

  constructor(public router: Router, public dataService: DataService, public myPoliciesService: MyPoliciesService, public message: MessageService, public utils: Utils,public route: ActivatedRoute, public httpService: HttpService, public customLinkService: CustomLinkService, public dialog: MatDialog, public crypto: EncryptDecryptService, public createSrService: CreateSRService) { }

  ngOnInit(): void {
    this.pageId = this.route.snapshot.queryParamMap.get('_pageid');
    this.action = this.route.snapshot.queryParamMap.get('action');
    this.action = this.action ? this.action : this.pageId ? this.pageId : '';
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.cspsUrlRk = this.route.snapshot.queryParamMap.get('csps_url_rk');

    if (!this.action || (this.action && !deepLinkRouteMap[this.action])) {
      this.openErrorPopup(this.linkInvalidError);
      return;
    }

    if (this.action && deepLinkRouteMap[this.action] && !deepLinkRouteMap[this.action].loginRequired) {
      this.router.navigate(deepLinkRouteMap[this.action]["path"], { queryParamsHandling: "preserve" });
    } else if (this.action && deepLinkRouteMap[this.action] && deepLinkRouteMap[this.action].loginRequired) {
      if (deepLinkRouteMap[this.action].linkValidation) {
        this.httpService.init({}).subscribe(resp => {
          this.httpService.initResp = resp;
          this.readOnly = true;
          if (deepLinkRouteMap[this.action].canExpire) {
            if (this.action && this.token) {
              this.getTokenDtls();
            } else {
              this.openErrorPopup(this.linkInvalidError);
              return;
            }
          } else {
            if (this.action && this.cspsUrlRk && this.token) {
              this.getCspsUrlRkTokenDtls();
            } else {
              this.openErrorPopup(this.linkInvalidError);
              return;
            }
          }
        }),
          catchError((error) => throwError(error));
      }
      this.loginSource = deepLinkRouteMap[this.action].loginSource;
      this.showPage = true;
    }
  }

  onLoginSuccess() {
    this.isFormReset = false;
    this.customLinkDtls = this.dataService.getLoginResp();
    this.isCustomLink = this.dataService.getIsCustomLink();
    if (this.customLinkDtls && this.isCustomLink) {
      if (["MAT_CLAIM", "STMNT"].includes(this.customLinkDtls.action)) {
        if (this.customLinkDtls.Policyno) {
          this.getPolicyList();
        }
      } else if (["UNCLAIM_AMT", "SUSPENSE_AMT"].includes(this.customLinkDtls.action)) {
        if (this.customLinkDtls.Policyno) {
          this.getPolicyDetail();
        }
      } else if (["PHS"].includes(this.action) && this.customLinkDtls.Policyno) {
        this.utils.setLocalData("action", this.customLinkDtls.action, false);
          this.getCovid('PHS');
      } else if (["COVID"].includes(this.action)  && this.customLinkDtls.Policyno) {
        this.utils.setLocalData("action", this.customLinkDtls.action, false);
          this.getCovid('COVID');
      } else if (["UPD_KYC", "PAN_CARD_UPD", "CHNG_CON_NUM"].includes(this.customLinkDtls.action)) {
        this.validateSR();
      } else if (["cp_SurrenderPage"].includes(this.customLinkDtls.action)) {
        this.utils.setLocalData("action", this.customLinkDtls.action, false);
        const coBrowseCookieVal = this.customLinkDtls.MOBILE_NUM && this.customLinkDtls.Policyno
                                    && this.customLinkDtls.MOBILE_NUM.length > 4                                      
                                    ? this.customLinkDtls.Policyno.concat(this.customLinkDtls.MOBILE_NUM.slice(-4))
                                    : '';
        document.cookie = `co_id=${coBrowseCookieVal}`;
        this.utils.setLocalData("action", this.customLinkDtls.action, false);
        this.router.navigate(deepLinkRouteMap[this.customLinkDtls.action]["path"], { queryParamsHandling: "preserve" });
      } else if (deepLinkRouteMap[this.customLinkDtls.action]) {        
        this.router.navigate(deepLinkRouteMap[this.customLinkDtls.action]["path"], { queryParamsHandling: "preserve" });        
      }
      this.utils.setLocalData("action", this.customLinkDtls.action, false);
    }    
  }
  getCovid(type){
    this.covidPhsValidation(type);
  }
  covidPhsValidation(type){
    let reqBody = {
      policyNo: this.dataService.getLoginResp().Policyno ? this.dataService.getLoginResp().Policyno : "",
    }
    let reqType = '';
    if(type === 'COVID'){
      reqType = 'validatecovidpolicydob';
    }
    else{
      reqType = 'validatephspolicydob';
    }
    return this.customLinkService.getValidationResp(reqBody,reqType).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.srNumber = resp.body.digitalPhsDetails.srNo;
        this.phsFlag = resp.body.digitalPhsDetails.isPhs;
        this.getRequirementDetails();
        // this.covidFlag = resp.body.digitalPhsDetails.isPhs;
      }
      else {
        this.resetForm();
        this.httpService.apiErrorHandler(resp);
      }
    }),
    catchError((error) => this.handleError.bind(this, error));
  }
  getRequirementDetails() {
    this.covidRedirectFlag = 'N';
   let srno = this.srNumber;
    let reqBody = {
      "srno": this.srNumber
    }
    return this.createSrService.getRequirementDetails(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.getrequirementdetails) {
          this.createSrService.setSrPolicyStatus(resp.body.polciyDetails?resp.body.polciyDetails.POLICY_STATUS:'');
          for (let i of resp.body.getrequirementdetails) {
            if (i.subissuecd == "SUB_ACT_REVIVAL" && i.reqtype == "6911") {
              if (i.reqcd == "CSPS_REQ_505" && ["COVID"].includes(this.action)) {
                this.srInputParams = [{"srNum": srno, "srType" : 'SUB_ACT_REVIVAL'}];
                this.createSrService.setSrDtlsResp(i);
                this.createSrService.setSRTypeResp(this.srInputParams);
                this.router.navigate(['/my-policies', 'covid']);
                this.covidRedirectFlag = 'Y';
                break;
              }
              else if (i.reqcd == "CSPS_REQ_332" && ["PHS"].includes(this.action)) {
                this.srInputParams = [{"srNum": srno, "srType" : 'SUB_ACT_REVIVAL'}];
                this.createSrService.setSRTypeResp(this.srInputParams);
                this.createSrService.setSrDtlsResp(i);
                this.router.navigate(['/my-policies', 'phs']);
                break;
              }
              // else if (i.reqcd == "CSPS_REQ_366") {
              //   this.srInputParams = [{"srNum": srno, "srType" : 'SUB_ACT_REVIVAL'}];
              //   this.createSrService.setSRTypeResp(this.srInputParams);
              //   this.createSrService.setSrDtlsResp(i);
              //   this.router.navigate(['/my-policies', 'smq']);
              //   break;
              // }
              // else if (i.reqcd == "CSPS_REQ_345") {
              //   this.srInputParams = [{"srNum": srno, "srType" : 'SUB_ACT_REVIVAL'}];
              //   this.createSrService.setSRTypeResp(this.srInputParams);
              //   this.createSrService.setSrDtlsResp(i);
              //   this.router.navigate(['/my-policies', 'short-phs']);
              //   break;
              // }
              else{

              }
            }
            else {
              console.log('No Covid Requirements');
            }
          }
        }
        else {
          console.log('No Requirements');
        }
      } else {
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => throwError(error));
  }
  getPolicyList() {
    this.myPoliciesService.getPolicyDetails().subscribe(policyCardDtls => {
      if (policyCardDtls && policyCardDtls.length > 0) {
        let policyIndex = policyCardDtls.findIndex((item) => item.POLICYNO.replace(/\s+/g, '') == this.customLinkDtls.Policyno);
        if (policyIndex >= 0) {
          this.dataService.setCarouselPosition(policyIndex);
          if (this.customLinkDtls.action == "MAT_CLAIM") {
            if (policyCardDtls[policyIndex]["MaturityCheck"] == "Y") {
              this.utils.setLocalData("action", this.customLinkDtls.action, false);
              this.router.navigate(deepLinkRouteMap[this.customLinkDtls.action]["path"], { queryParamsHandling: "preserve" });
            } else {
              this.resetForm();
              this.message.openSnackBar("Maturity claim is not applicable for the given policy", 'xy', ['error-snackbar']);
            }
          }
          if (["STMNT", "UNCLAIM_AMT", "SUSPENSE_AMT"].includes(this.customLinkDtls.action)) {
            this.utils.setLocalData("action", this.customLinkDtls.action, false);
            this.router.navigate(deepLinkRouteMap[this.customLinkDtls.action]["path"], { queryParamsHandling: "preserve" });
          }
        }
      }
    }), catchError((error) => this.handleError.bind(this, error));
  }
  getPolicyDetail() {
    let reqBody = {
      'clientID': this.utils.getClientId(),
      'policyno': this.customLinkDtls.Policyno
    }
    return this.myPoliciesService.getPolicyDtls(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (this.customLinkDtls.action == "UNCLAIM_AMT") {
          if (resp.body && resp.body.policydetails && resp.body.policydetails.unclaimed == 'Y') {
            this.getPolicyList();
          } else {
            this.resetForm();
            this.openErrorPopup(`We are unable to find any unclaimed amount lying with HDFC Life in this policy. For more information, please write to us at ${this.link}`)
          }
        }
        if (this.customLinkDtls.action == "SUSPENSE_AMT") {
          if (resp.body && resp.body.policydetails && resp.body.policydetails.suspense == 'Y') {
            this.getPolicyList();
          } else {
            this.resetForm();
            this.openErrorPopup(`We are unable to find any amount lying with HDFC Life in this policy. For more information, please write to us at ${this.link}`)
          }
        }
      }
    })
  }
  handleError(error: any): any {
    this.resetForm();
    throwError(error);
  }
  resetForm() {
    this.isFormReset = true;
    this.dataService.setIsCustomLink(null);
    this.dataService.setLoginResp(null);
    localStorage.clear();
  }
  getTokenDtls() {
    let reqBody = {
      "token": this.token,
      "process": this.getProcess(),
    }    
    return this.customLinkService.validateLinkToken(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.policyDtls && resp.body.policyDtls.status) {
          if (resp.body.policyDtls.status == "SUCCESS") {
            this.policyDtls = {
              policyno: resp.body.policyDtls.policyno ? this.crypto.decryptString(resp.body.policyDtls.policyno) : '',
              srno: resp.body.policyDtls.srno ? this.crypto.decryptString(resp.body.policyDtls.srno) : '',
              clientid: resp.body.policyDtls.clientid ? this.crypto.decryptString(resp.body.policyDtls.clientid) : '',
              activityNo: resp.body.policyDtls.activity_no ? this.crypto.decryptString(resp.body.policyDtls.activity_no) : '',
            }
            this.dataService.SurrPolicyNum = this.policyDtls.policyno;
            this.srno = this.policyDtls.srno;
          } else if (resp.body && resp.body.policyDtls && resp.body.policyDtls.resMsg) {
            this.openErrorPopup(resp.body.policyDtls.resMsg);
          } else {
            this.openErrorPopup();
          }
        }
      } else {
        let msg = resp.head && resp.head.errordetails && resp.head.errordetails.message ? resp.head.errordetails.message : '';
        this.openErrorPopup(msg);
      }
    }),
    catchError(error => throwError(error));
  }
  openErrorPopup(errorMsg?) {
    if (!errorMsg) {
      errorMsg = this.notAuthorizedError;
    }
    this.dialog.open(MessagePopupComponent, {
      width: '500px',
      panelClass: 'message-popup-panel',
      backdropClass: 'email-voc-popup', disableClose: true, autoFocus: false,
      data: {
        closeIcon: false,
        title: "Error",
        mainMessageHtml: errorMsg,
        /* cancelBtnLabel: "OK",
        source: '' */
      }
    });
    this.showPage = false;
  }
  getCspsUrlRkTokenDtls() {
    let reqBody = {
      token: this.token,
      cspsUrlRk: this.cspsUrlRk
    }
    return this.customLinkService.getCspsUrlRkTokenDtls(reqBody).subscribe(resp => {
      if (this.httpService.isSuccessResponse(resp)) {
        if (resp.body && resp.body.flag && resp.body.policyNo && resp.body.srNum && resp.body.flag == "Y") {
          this.policyDtls = {
            policyno: resp.body.policyNo ? atob(resp.body.policyNo) : '',
            srno: resp.body.srNum ? atob(resp.body.srNum) : '',
          }
          this.srno = this.policyDtls.srno;
        } else {
          this.openErrorPopup();
        }
      } else {
        let msg = resp.head && resp.head.errordetails && resp.head.errordetails.message ? resp.head.errordetails.message : '';
        this.openErrorPopup(msg);
      }
    }),
    catchError(error => throwError(error));
  }
  getProcess() {
    if (this.action == "consent") {
      return "DigitalConsent";
    } else if (this.action == "okyc") {
      return "OfflineAadhaar";
    }  else if (this.action == "cp_SurrenderPage") {
      return "Surrender";
    } else {
      return this.action;
    }
  }
  validateSR() {
    let reqBody = {
      policyno: this.customLinkDtls.Policyno,
      subactivity: this.customLinkDtls.action
    }
    return this.createSrService.validateServiceReq(reqBody).subscribe((resp) => {
      if (this.httpService.isSuccessResponse(resp)) {
        this.utils.setLocalData("action", this.customLinkDtls.action, false);
        this.router.navigate(deepLinkRouteMap[this.customLinkDtls.action]["path"], { queryParamsHandling: "preserve" });
      } else {   
        this.resetForm();     
        this.httpService.apiErrorHandler(resp);
      }
    }),
      catchError((error) => this.handleError.bind(this, error));
  }
  
  onBtnClick() {
    window.open(serviceURL.sidebannerLink, "_blank");
  }

}
