import { Component, OnInit } from '@angular/core';
import { serviceURL } from 'src/environments/environment';
import { NavigationEnd, Router, Event, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { Utils } from 'src/app/utils/common-functions';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.css']
})
export class ChatBotComponent implements OnInit {
  lifeAiDiv: HTMLDivElement = null;
  applozic: HTMLScriptElement = null;
  applozicScript: HTMLScriptElement = null;
  style: HTMLLinkElement = null;
  script: HTMLScriptElement = null;

  routesWithoutChatBot = [
    "/login",
    "/login/user-registration",
    "/login/forgot-password",
    "/my-payment",
    "/my-kyc",
    "/my-doc-upload",
    "/myFrame",
    "/my-vb-test",
    "/my-link",
    "/cpadmin",
  ];

  constructor(public router: Router, public dataService: DataService, public utils: Utils, public route: ActivatedRoute) {
    this.router.events.subscribe((eve: Event) => {
      if(eve instanceof NavigationEnd) {
        let hideChatBot: boolean = this.dataService.getIsVirtualBranch();
        let action = this.route.snapshot.queryParamMap.get('action');
        if (action!="cp_SurrenderPage" && (this.routesWithoutChatBot.includes(eve.urlAfterRedirects) || !this.utils.isUserAuthenticated() || hideChatBot)) {
          setTimeout(() => this.removeChatBot());
        } else {
          this.showChatBot();
        }
      }
    });
  }

  ngOnInit(): void { 
  }

  showChatBot() {
    if (!this.lifeAiDiv) {
      this.lifeAiDiv = document.createElement("div");
      this.lifeAiDiv.setAttribute("id","life-ai");
      document.body.appendChild(this.lifeAiDiv);
    }    
    var head = document.head || document.getElementsByTagName("head")[0];
    if (!this.applozic) {
      this.applozic = document.createElement("script");
      this.applozic.type = "text/javascript";
      this.applozic.src = serviceURL.applozicChatBot;
      this.applozic.defer = true;
      head.appendChild(this.applozic);
    }
    if (!this.applozicScript) {
      this.applozicScript = document.createElement("script");
      this.applozicScript.type = "text/javascript";
      this.applozicScript.src = serviceURL.applozicChat;
      this.applozicScript.defer = true;
      head.appendChild(this.applozicScript);
    }
    if (!this.style) {
      this.style = document.createElement("link");
      this.style.type = "text/css";
      this.style.rel = "stylesheet";
      this.style.href = serviceURL.lifeai;     //https://lifeai-uat.api-hdfclife.com/widget/v1/js?apiKey=557aba71-3102-4c40-8851-47a4478502e2
      head.appendChild(this.style);
    }
    if (!this.script) {
      this.script = document.createElement("script");
      this.script.type = "text/javascript";
      this.script.src = serviceURL.lifeai;    //https://lifeai.api-hdfclife.com/widget/v1/js?apiKey=1c5665a8-32d6-481a-bje9-2ca2e0kt73hg
      document.body.appendChild(this.script)
    }    
  }
  removeChatBot() {
    if (this.lifeAiDiv) {
      this.lifeAiDiv.remove();
    }
    if (this.applozic) {
      this.applozic.remove();
    }
    if (this.applozicScript) {
      this.applozicScript.remove();
    }
    if (this.style) {
      this.style.remove();
    }
    if (this.script) {
      this.script.remove();
    }
    let chatbotIframe = document.getElementById("life-ai-iframe");
    if (chatbotIframe) {
      chatbotIframe.remove();
    }
    this.lifeAiDiv = null;
    this.applozic = null;
    this.applozicScript = null;
    this.style = null;
    this.script = null;
  }
}
