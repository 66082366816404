import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { serviceURL } from 'src/environments/environment';
import { Utils } from 'src/app/utils/common-functions';
import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class CustomLinkService {

  constructor(public httpService: HttpService, public utils: Utils,public crypto: EncryptDecryptService) { }

  getHospitalStates(reqBody) {
    return this.httpService.post(serviceURL.getHospitalStates_url, this.utils.createRequest('', 'gethospitalstates', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getHospitalCityByState(reqBody) {
    return this.httpService.post(serviceURL.getHospitalCityByState_url, this.utils.createRequest('', 'gethospitalcities', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getHospitalList(reqBody) {
    return this.httpService.post(serviceURL.getHospitalList_url, this.utils.createRequest('', 'gethospitallist', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getEmailVocQuestions(reqBody) {
    return this.httpService.post(serviceURL.emailVocOnLoad_url, this.utils.createRequest('', 'emailVocQnsOnload', reqBody))
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  submitEmailVocQuestions(reqBody) {
    return this.httpService.post(serviceURL.emailvocSubmit_url, this.utils.createRequest('', 'emailVocAnsSubmit', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getVservOnLoad(reqBody,apiName) {
    return this.httpService.post(serviceURL.vservOnload_url, this.utils.createRequest('', apiName, reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  generateVservOtp(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.dob = this.crypto.encryptJson(reqBody.dob);
    reqBody.name = this.crypto.encryptJson(reqBody.name);
    return this.httpService.post(serviceURL.vservOtp_url, this.utils.createRequest('', 'sendVserveOTP', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateVservOtp(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.otpEntered = this.crypto.encryptJson(reqBody.otpEntered);
    reqBody.name = this.crypto.encryptJson(reqBody.name);
    reqBody.trans_id = this.crypto.encryptJson(reqBody.trans_id);
    return this.httpService.post(serviceURL.vservValidateOtp_url, this.utils.createRequest('', 'validateVserveOTP', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateLinkToken(reqBody) {
    return this.httpService.post(serviceURL.customLinkValidateToken_url, this.utils.createRequest('', 'validateCustomLinkToken', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getCspsUrlRkTokenDtls(reqBody) {
    return this.httpService.post(serviceURL.getUrlRkDtls_url, this.utils.createRequest('', 'frDocuploadOnload', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  fetchSRDtls(reqBody) {
    //reqBody.policyNo = this.crypto.encryptJson(reqBody.policyNo);
    //reqBody.dateOfBirth = this.crypto.encryptJson(reqBody.dateOfBirth);
    return this.httpService.post(serviceURL.fetchSRDetails_url, this.utils.createRequest('', 'fetchsrdetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateSRPolicyDob(reqBody) {
    reqBody.policyNo = this.crypto.encryptJson(reqBody.policyNo);
    reqBody.dateOfBirth = this.crypto.encryptJson(reqBody.dateOfBirth);
    return this.httpService.post(serviceURL.validateSrPolicyDob_url, this.utils.createRequest('', 'fetchsrdetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  fetchPolicy(reqBody) {
    return this.httpService.post(serviceURL.fetchPolicy_url, this.utils.createRequest('', 'fetchsrdetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  fetchSRTrackDtls(reqBody) {
    //reqBody.clientID = this.crypto.encryptJson(reqBody.clientID);
    return this.httpService.post(serviceURL.fetchSRTrackDtls_url, this.utils.createRequest('', 'fetchsrdetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getSrDocUploadLink(reqBody) {
    reqBody.policy = this.crypto.encryptJson(reqBody.policy);
    reqBody.srNo = this.crypto.encryptJson(reqBody.srNo);
    return this.httpService.post(serviceURL.fetchDocUpload_url, this.utils.createRequest('', 'srtrackdocupload', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getVoucherDetails(reqBody) {
    return this.httpService.post(serviceURL.getVoucherDetails_url, this.utils.createRequest('', 'VoucherDetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  generateVoucherPdf(reqBody) {
    return this.httpService.post(serviceURL.customVoucherPdf_url, this.utils.createRequest('', 'VoucherDetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateAgent(reqBody) {
    reqBody.agentId = this.crypto.encryptJson(reqBody.agentId);
    return this.httpService.post(serviceURL.validateAgent_url, this.utils.createRequest('', 'validateAgent', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  generateAgentOtp(reqBody) {
    reqBody.agentId = this.crypto.encryptJson(reqBody.agentId);
    //reqBody.country_code = this.crypto.encryptJson(reqBody.country_code);
    //reqBody.mobile_num = this.crypto.encryptJson(reqBody.mobile_num)
    return this.httpService.post(serviceURL.generateAgentOtp_url, this.utils.createRequest('', 'ganerateAgentOtp', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateAgentOtp(reqBody) {
    reqBody.agentId = this.crypto.encryptJson(reqBody.agentId);
    reqBody.trans_id = this.crypto.encryptJson(reqBody.trans_id);
    reqBody.otpEntered = this.crypto.encryptJson(reqBody.otpEntered);
    return this.httpService.post(serviceURL.validateAgentOtp_url, this.utils.createRequest('', 'validateAgentOtp', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getAgentSRList(reqBody) {
    return this.httpService.post(serviceURL.agentSrDtls_url,this.utils.createRequest('', 'agentSrDetails', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  saveAgentImage(reqBody) {
    return this.httpService.post(serviceURL.saveAgentImage_url, this.utils.createRequest('', 'saveAgentImage', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getAgentDocList(reqBody) {
    reqBody.srNo = this.crypto.encryptJson(reqBody.srNo);
    return this.httpService.post(serviceURL.getAgentDocList_url, this.utils.createRequest('', 'onloadAgentDocUpload', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  ClientDtls(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    return this.httpService.post(serviceURL.ClientDtls_url, this.utils.createRequest('', '"sendSurrenderOTP",', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  bookappointment(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.clientid = this.crypto.encryptJson(reqBody.clientid);
    reqBody.mobile = this.crypto.encryptJson(reqBody.mobile);
    return this.httpService.post(serviceURL.bookappintment_url, this.utils.createRequest('', '"sendSurrenderOTP",', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getPincodeMasterData(masterDataType, filterBy, masterVersion?: string | number) {
    const url = `${serviceURL.pincodeMasterData_url}/${filterBy}`;
    return this.httpService.get(url)
      .pipe(
        map((resp) => {
          return resp
        }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
    }
  generateOtp(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.dob = this.crypto.encryptJson(reqBody.dob);
    return this.httpService.post(serviceURL.exideGenerateOtp_url, this.utils.createRequest('', "CustomlinkGenerateOTP", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateOtp(reqBody) {
    reqBody.client_id = this.crypto.encryptJson(reqBody.client_id);
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.dob = this.crypto.encryptJson(reqBody.dob);
    reqBody.trans_id = this.crypto.encryptJson(reqBody.trans_id);
    reqBody.otpEntered = this.crypto.encryptJson(reqBody.otpEntered);
    return this.httpService.post(serviceURL.exideValidateOtp_url, this.utils.createRequest('', "CustomlinkCheckOTP", reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getValidationResp(reqBody,reqType) {
    return this.httpService.post(serviceURL.validatePhs_url + `${reqType}`, this.utils.createRequest('', reqType, reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  validateNriVserv(reqBody) {
    reqBody.policyno = this.crypto.encryptJson(reqBody.policyno);
    reqBody.name = reqBody.name;
    reqBody.dob = this.crypto.encryptJson(reqBody.dob);
    return this.httpService.post(serviceURL.validateNriVserv_url, this.utils.createRequest('','validateNriVserv', reqBody))
    .pipe(
      map((resp) => {
        return resp;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
}
