import { AbstractControl } from '@angular/forms';
import {FormGroup} from '@angular/forms';
import * as moment from 'moment';

/**
 * @exports CustomValidators
 */

export class CustomValidators {
    /**
     * Date Validator
     *
     * Validates if the date input is a valid past date.
     * Returns error message if future dates are entered.
     * @static
     * @param control
     * @returns { null | { errorMsg: string } }
     */

    public static noFutureDatesPolicyId(control: AbstractControl) {
        if (!control.value) {
            return { errorMsg: 'Enter a valid DOB' };
        } else if (control.value.isAfter() && moment(control.value).format('DD/MM/YYYY') != "01/01/9999") {
            return { errorMsg: 'Enter a valid DOB' };
        } else if( control.value.isAfter() && moment(control.value).format('DD/MM/YYYY') == "01/01/9999"){
            return null;
        }
            else return null;
    }
    public static noFutureDates(control: AbstractControl) {
        if (!control.value) {
            return { errorMsg: 'Enter a valid DOB' };
        } else if (control.value.isAfter()) {
            return { errorMsg: 'Enter a valid DOB' };
        }
            else return null;
    }

    public static alphabetsOnly(control: AbstractControl) {}
    public static alphaNumeric(control: AbstractControl) {}

    public static numbersOnly(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        } else if (!control.value.match(/^[0-9]+$/)) {
            return { errorMsg: 'Please enter valid Number.' };
        } else {
            return null;
        }
    }

    public static fundPercent(control: AbstractControl) {
        if (control.value === null || control.value === '') {
            return null;
        } else if (!control.value.match(/^\d*$/)) {
            return { errorMsg: 'Please enter numeric values' };
        } else if (control.value < 0 || control.value > 100) {
            return { errorMsg: 'Choose value within 0 - 100' };
        } else {
            return null;
        }
    }
    public static fundPercentIncrease(control: AbstractControl) {
        if (control.value === null || control.value === '') {
            return null;
        } else if (!control.value.match(/^\d*$/)) {
            return { errorMsg: 'Please enter numeric value' };
        } else if (control.value < 0 || control.value > 100) {
            return { errorMsg: 'Please enter values within 0 - 100' };
        } else {
            return null;
        }
    }
    public static futureDatesOnly(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'Please enter a valid date' };
        } else if (            
            moment(control.value, 'DD/MM/YYYY').isBefore(
                new Date().setFullYear(new Date().getFullYear())
            )
        ) {
            return { errorMsg: 'Please enter a valid date' };
        } else {
            return null;
        }
    }

    public static clientName(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return null;
        }else if (control.value.length > 60) {
            return { errorMsg: 'Only 60 characters allowed' };
        } else if (!control.value.match(/^[a-zA-Z ]{0,60}$/)) {
            return { errorMsg: 'Only alphabets allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        }
    }
    public static houseNumber(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter house number' };
        }
        else if (control.value.length > 30) {
            return { errorMsg: 'Only 30 characters allowed' };
        } else if (!control.value.match(/^[a-zA-Z0-9\s,\-'()/.]{0,30}$/)) {
            return { errorMsg: 'Special characters not allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }
    public static addressLine(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter address' };
        }
        else if (control.value.length > 30) {
            return { errorMsg: 'Only 30 characters allowed' };
        } else if (!control.value.match(/^[a-zA-Z0-9\s,\-'()/.]{0,30}$/)) {
            return { errorMsg: 'Special characters not allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }
    public static landmark(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return null;
        } else if (control.value.length > 30) {
            return { errorMsg: 'Only 30 characters allowed' };
        } else if (!control.value.match(/^[a-zA-Z0-9\s,\-'()/.]{0,30}$/)) {
            return { errorMsg: 'Special characters not allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }

    public static selectValue(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please select value' };
        } else if (typeof control.value === 'string') {
            return { errorMsg: 'Please select value from the list' };
        } else {
            return null;
        }
    }
    public static selectCountryValue(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please select Country' };
        } else if (typeof control.value === 'string') {
            return { errorMsg: 'Please select Country from the list' };
        } else {
            return null;
        }
    }
    public static selectStateValue(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if (group.controls['updateCountry'] && group.controls['updateCountry'].value && group.controls['updateCountry'].value.label && group.controls['updateCountry'].value.label.toLowerCase() == "india") {
                if (control.value == null || control.value.length === 0 || control.value == undefined) {
                    return { errorMsg: 'Please select State' };
                } else if (typeof control.value === 'string') {
                    return { errorMsg: 'Please select State from the list' };
                } else {
                    return null;
                }
            }            
        else {
            if (control.value == null || control.value.length === 0 || control.value == undefined) {
                return null;
            } else if (control.value.length > 30) {
                return { errorMsg: 'Only 30 characters allowed' };
            } else if (!control.value.match(/^[a-zA-Z]{0,30}$/)) {
                return { errorMsg: 'Only alphabets allowed' };
            } else {
                return null;
            }
        }
    }
    }
    public static selectCityValue(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if (group.controls['updateCountry'] && group.controls['updateCountry'].value && group.controls['updateCountry'].value.label && group.controls['updateCountry'].value.label.toLowerCase() == "india") {
                if (control.value == null || control.value.length === 0 || control.value == undefined) {
                    return { errorMsg: 'Please select City' };
                } else if (typeof control.value === 'string') {
                    return { errorMsg: 'Please select City from the list' };
                } else {
                    return null;
                }
            }            
         else {
            if (control.value == null || control.value.length === 0 || control.value == undefined) {
                return null;
            } else if (control.value.length > 30) {
                return { errorMsg: 'Only 30 characters allowed' };
            } else if (!control.value.match(/^[a-zA-Z]{0,30}$/)) {
                return { errorMsg: 'Only alphabets allowed' };
            } else {
                return null;
            }
        }
    } 
    }
    public static pincode(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if (group.controls['updateCountry'] && group.controls['updateCountry'].value && group.controls['updateCountry'].value.label &&
            group.controls['updateCountry'].value.label.toLowerCase() == "india") {
                if (control.value == null || control.value.length === 0) {
                    return { errorMsg: 'Please enter pincode' };
                } else if (!control.value.match(/^([1-9]{1})([0-9]{5})$/)) {
                    return { errorMsg: 'Please enter valid pincode' };
                } else {
                    return null;
                }
            }            
        else {
            if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter pincode' };
            } else if (!control.value.match(/^[a-zA-Z0-9\s]{0,10}$/)) {
                return { errorMsg: 'Please enter valid pincode' };
            } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
                return { errorMsg: 'Space not allowed at first or last character' };
          }   else {
                return null;
            }
        }
    }
    }
    public static nomineePincode(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if(group.controls['countryInput'].value == "India" || group.controls['country'].value && group.controls['country'].value.label && group.controls['country'].value.label == "India") {
            if ((group.controls['pincode'] && group.controls['pincode'].value) || (group.controls['pincodeInput'] && group.controls['pincodeInput'].value)) {
                  if ((group.controls['pincode'].value && !group.controls['pincode'].value.match(/^([1-9]{1})([0-9]{5})$/)) || (group.controls['pincodeInput'].value && !group.controls['pincodeInput'].value.match(/^([1-9]{1})([0-9]{5})$/))) {
                    return { errorMsg: 'Please enter valid pincode' };
                } else {
                    return null;
                }
            } else if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter pincode' };
            } else {
                return null;
            }
        } else {
            if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter pincode' };
            } else if (!control.value.match(/^[a-zA-Z0-9\s]{0,10}$/)) {
                return { errorMsg: 'Please enter valid pincode' };
            } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
                return { errorMsg: 'Space not allowed at first or last character' };
            } else {
                return null;
            }
        }      
    }
    }
    public static nomineeCity(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if(group.controls['countryInput'].value == "India" || group.controls['country'].value.label == "India") {
            if (group.controls['city'] && group.controls['city'].value ) { 
                if (typeof control.value === 'string') {
                    return { errorMsg: 'Please select city from the list' };
                } else {
                    return null;
                }
            } else if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter city' };
            } else {
                return null;
            }
        } else {
            if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter city' };
            } else {
                return null;
            }
        }         
    }
    }
      public static nomineeState(control: AbstractControl) {
        const group = control.parent;
        if (group) {
            if(group.controls['countryInput'].value == "India" || group.controls['country'].value.label == "India") {
            if (group.controls['state'] && group.controls['state'].value ) {
                if (typeof control.value === 'string') {
                    return { errorMsg: 'Please select State from the list' };
                } else {
                    return null;
                }
            } else if (control.value == null || control.value.length === 0){
                return { errorMsg: 'Please enter state' };
            }  else{
                return null;
            }
        } else {
            if (control.value == null || control.value.length === 0) {
                return { errorMsg: 'Please enter state' };
            } else {
                return null;
            }
        }         
    }
    }
    public static panNo(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'PAN number required' };
        } else if (
            !control.value.match(/^([A-Z]{5})([0-9]{4})([A-Z]{1})$/)
        ) {
            return { errorMsg: 'Pan Number is not valid.' };
        } else {
            return null;
        }
    }

    public static email(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'Email is required' };
        } else if (
            !control.value.match(
                // /^[a-zA-Z0-9]+([\.a-zA-Z0-9_&#$%^'}{|`~*+=?!-]+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{1,6})+$/
                /^([a-zA-Z0-9]{1})([a-zA-Z0-9_\.\-\+\~\!]{1,63})@([\w\.]{1,100})\.(\w{1,6})$/
            )
        ) {
            return { errorMsg: 'Email is not valid.' };
        } else {
            return null;
        }
    }
    public static nomineeEmail(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        }
        else if (
            !control.value.match(
                // /^[a-zA-Z0-9]+([\.a-zA-Z0-9_&#$%^'}{|`~*+=?!-]+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{1,6})+$/
                /^([a-zA-Z0-9]{1})([a-zA-Z0-9_\.\-\+\~\!]{1,63})@([\w\.]{1,100})\.(\w{1,6})$/
            )
        ) {
            return { errorMsg: 'Email is not valid.' };
        } else {
            return null;
        }
    }

    public static mobNo(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'Mobile number required' };
        } else if (!(control.value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
            return { errorMsg: 'Please enter valid number.' };
        } else {
            return null;
        }
    }
    public static alternateEmail(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        } else if (
            !control.value.match(
                // /^[a-zA-Z0-9]+([\.a-zA-Z0-9_&#$%^'}{|`~*+=?!-]+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{1,6})+$/
                /^([a-zA-Z0-9]{1})([a-zA-Z0-9_\.\-\+\~\!]{1,63})@([\w\.]{1,100})\.(\w{1,6})$/
            )
        ) {
            return { errorMsg: 'Email is not valid.' };
        } else {
            return null;
        }
    }

    public static alternateMobNo(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        } else if (!(control.value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
            return { errorMsg: 'Please enter valid number.' };
        } else {
            return null;
        }
    }

    public static ifscCode(control: AbstractControl) {
        return control.value == null || control.value.length == 0
            ? { errorMsg: 'Please enter IFSC code' }
            : !control.value.match(/^([a-zA-Z]{4})([0]{1})([a-zA-Z0-9]{6})$/)
            ? { errorMsg: 'Please enter valid IFSC Code.' }
            : null;
    }

    public static accountLength(control: AbstractControl) {
        return control.value == null || control.value.length == 0
            ? { errorMsg: 'Please enter account no.' }
            : null;
    }

    public static accNumValid(group) {
        if(group){
            if(group.controls['ifscCode'].value != null ){
                if((group.controls['ifscCode'].value.toUpperCase().slice(0,4) != ('HDFC'))){
                    if(group.controls['accNumber'].value == null || group.controls['accNumber'].value.length == 0){
                         group.controls['accNumber'].setErrors({errorMsg:'Please enter account no.'}) 
                    } else if(!group.controls['accNumber'].value.match(/^\d{9,18}$/)){
                        group.controls['accNumber'].setErrors({errorMsg:'Length must be from 9 to 18' })
                    } else{
                        group.controls['accNumber'].setErrors(null);
                }
            } else if((group.controls['ifscCode'].value.toUpperCase().slice(0,4)  == ('HDFC'))){
                if(group.controls['accNumber'].value == null || group.controls['accNumber'].value.length == 0){
                    group.controls['accNumber'].setErrors({errorMsg:'Please enter account no.' })
                } else if(!group.controls['accNumber'].value.match(/^\d{9,14}$/)){
                    group.controls['accNumber'].setErrors({errorMsg:'Length must be from 9 to 14' })
                } else{
                    group.controls['accNumber'].setErrors(null);
                }
                }
            }
        }
    }
    public static dateOfIssue(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'Please enter a valid date' };
        } else if (
            moment(control.value, 'DD/MM/YYYY').isAfter(
                new Date().setFullYear(new Date().getFullYear())
            ) ||
            moment(control.value, 'DD/MM/YYYY').isBefore(
                new Date().setFullYear(new Date().getFullYear() - 150)
            )
        ) {
            return { errorMsg: 'Please enter a valid date' };
        } else {
            return null;
        }
    }    

    /**
     * all alphabets
     * no 3 consecutive letters
     * no special characters
     */
    public static personName(control: AbstractControl) {
        if (control.value == null || control.value.length == 0) {
            return { errorMsg: 'Please enter name' };
        } else if (control.value.length > 120) {
            return { errorMsg: 'Only 120 characters allowed' };
        } else if (!control.value.match(/^[a-zA-Z ]*$/)) {
            return { errorMsg: 'Only alphabets are allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        }
        // let noConsecutiveThreeLetters = true;
        // let t = '';
        // let c = 0;
        // loop: for (let i = 0; i < control.value.length; i++) {
        //     const ch = (control.value as string).charAt(i);
        //     c = t === ch ? c + 1 : 0;
        //     if (c === 2) {
        //         noConsecutiveThreeLetters = false;
        //         break loop;
        //     }
        //     t = ch;
        // }
        // return noConsecutiveThreeLetters
        //     ? null
        //     : { errorMsg: '3 or more consecutive letters are not allowed' };
    }

    public static aadharNo(control: AbstractControl) {
        return control.value == null || control.value.length === 0
            ? null
            : !control.value.match(/^\d{12}$/)
            ? { errorMsg: 'Enter valid aadhar number' }
            : null;
    }

    public static mobCode(control: AbstractControl) {        
        return control.value == null || control.value.length == 0
            ? null
            : control.value.match(/^\d{1,6}$/)
            ? null
            : { errorMsg: 'Invalid input.' };
    }
    public static countryCode(control: AbstractControl) {
        return control.value == null || control.value.length == 0
        ? { errorMsg: 'Enter code' }
        : control.value.match(/^\d{1,6}$/)
        ? null
        : { errorMsg: 'Invalid code' };
    }
    public static mobileNum(group: FormGroup) {
        if (group) {
        if (group.controls['countryCode'].value == '91') {
            if (group.controls['mobileNo'].value == null || group.controls['mobileNo'].value.length == 0) {
            return null;
            } else if (!(group.controls['mobileNo'].value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
            group.controls['mobileNo'].setErrors({ errorMsg: 'Please enter valid number' });
            }
        } else {
            if (group.controls['mobileNo'].value == null || group.controls['mobileNo'].value.length == 0) {
                return null;
            }
            //let maxMobLength: string = `${16 - group.controls['countryCode'].value.length}`;
            let maxMobLength = 16;
            if (!group.controls['mobileNo'].value.match(new RegExp('^[0-9]{0,' + maxMobLength + '}$'))) {
            group.controls['mobileNo'].setErrors({ errorMsg: 'Please enter valid number' });
            }
        }
        return null;
        }
    }

    public static telephoneNo(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        } else if (!(control.value + '').match(/^\d{5,10}$/)) {
            return { errorMsg: 'Please enter valid number.' };
        } else {
            return null;
        }
    }

    public static stdCode(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return null;
        } else if (!(control.value + '').match(/^\d{1,5}$/)) {
            return { errorMsg: 'Please enter valid number.' };
        } else {
            return null;
        }
    }

    public static selectAccountType(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please select Account Type' };
        } else {
            return null;
        }
    }
    public static selectMICR(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter MICR Code' };
        }  else if (!control.value.match(/^[a-zA-Z0-9 ]{0,30}$/)) {
            return { errorMsg: 'Special characters not allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }
    public static MatchAccountNumber(control: AbstractControl) {
        let accountNumber = control.get('accNumber').value;
        let reEnterAccountNumber =  control.get('reAccNumber').value;
        if(reEnterAccountNumber.length <14){
            control.get('reAccNumber').setErrors({Matchlength:true})
        }
        else if(accountNumber != reEnterAccountNumber) {
            control.get('reAccNumber').setErrors({MatchAccountNum:true})
        } else {
            return null;
        }
    }
    public static billingDate(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please select preffered billing date' };
        } else {
            return null;
        }
    }
    public static reduceTerm(control: AbstractControl) {
        const group = control.parent;
        if(group){
            if(group.controls['revisedValue'].value == null || group.controls['revisedValue'].value.length === 0){
                return { errorMsg: 'Please enter a value' };
            }else if(parseInt(group.controls['revisedValue'].value) >= parseInt(group.controls['ExistingPolicyTerm'].value)){
                return { errorMsg:'Enter values less than Existing Term'}
            }else if(parseInt(group.controls['revisedValue'].value) == 0){
                return { errorMsg:'Please enter a valid value'}
            }  else {
                return null;
            }
        }
    }
    public static increaseTerm(control: AbstractControl) {
        const group = control.parent;
        if(group){
            if(group.controls['revisedValue'].value == null || group.controls['revisedValue'].value.length === 0){
                return { errorMsg: 'Please enter a value' };
            }else if(parseInt(group.controls['revisedValue'].value) <= parseInt(group.controls['ExistingPolicyTerm'].value)){
                return { errorMsg:'Enter values more than Existing Term'}
            } else {
                return null;
            }
        }
    }
    public static totalPremiumPaid(control: AbstractControl) {
        const group = control.parent;
        if(group){
            if(group.controls['totalPremium'].value == null || group.controls['totalPremium'].value.length === 0){
                return { errorMsg: 'Please enter a value' };
            }else if(parseInt(group.controls['totalPremium'].value) > parseInt(group.controls['ExistingPremium'].value)){
                return { errorMsg:'NRE Amount should be greater than 0 and less than or equal to Total Premium Amount'}
            }else if(parseInt(group.controls['totalPremium'].value) == 0){
                return { errorMsg:'Please enter a valid value'}
            }  else {
                return null;
            }
        }
    }
    public static annuityOption(control: AbstractControl) {
        const group = control.parent;
        if(group){
            if(group.controls['commutation'].value == null || group.controls['commutation'].value.length === 0){
                return { errorMsg: 'This field is required' };
            }else if(group.controls['preferredAnnuity'].value == "60% Cash Lump Sum- 40% Annuity" && (group.controls['commutation'].value) > 60){
                return { errorMsg:'Commutation Percentage Should not be greater than 60%.'}
            }else if(group.controls['preferredAnnuity'].value == "1/3 Cash Lump Sum-2/3 Annuity" && (group.controls['commutation'].value) > 33.33){
                return { errorMsg:'Commutation Percentage Should not be greater than 33.33%.'}
            }  else {
                return null;
            }
        }
    }
    public static majorAgeValidation(control:AbstractControl){
        let today = new Date();
        let days;
        let age;
        let dob = new Date(control.value);
        function isLeapYear(yr){
            var d = new Date(yr, 1, 28);
            d.setDate(d.getDate() + 1);
            return d.getMonth() == 1;
        }
        let years = today.getFullYear() - dob.getFullYear();
        dob.setFullYear(dob.getFullYear() + years);
        if(dob>today){
            years --;
            dob.setFullYear(dob.getFullYear() - 1);
        }
        days = (today.getTime() - dob.getTime()) / (3600 * 24 * 1000)
        age = Math.floor(years + days / (isLeapYear(today.getFullYear())?366:365));
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter Date of Birth' };
        }  else if (age < 18) {
            return { errorMsg: 'Age should not be less than 18' };
        } else {
            return null;
        }
    }
    public static minorAgeValidation(control:AbstractControl){
        let today = new Date();
        let days;
        let age;
        let dob = new Date(control.value);
        function isLeapYear(yr){
            var d = new Date(yr, 1, 28);
            d.setDate(d.getDate() + 1);
            return d.getMonth() == 1;
        }
        let years = today.getFullYear() - dob.getFullYear();
        dob.setFullYear(dob.getFullYear() + years);
        if(dob>today){
            years --;
            dob.setFullYear(dob.getFullYear() - 1);
        }
        days = (today.getTime() - dob.getTime()) / (3600 * 24 * 1000)
        age = Math.floor(years + days / (isLeapYear(today.getFullYear())?366:365));
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter Date of Birth' };
        }  else if (age >= 18) {
            return { errorMsg: 'Age should not be greater than or equal to 18' };
        } else {
            return null;
        }
    }
    public static mobileNoValidator(control:AbstractControl) {
        const group = control.parent;   
          if (group) {
            if (group.controls['primarycountrycode'].value == '91') {
              if (group.controls['mobileNo'].value == null || group.controls['mobileNo'].value.length == 0) {
                return null;
              } else if (!(group.controls['mobileNo'].value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
                return { errorMsg: 'Please enter valid number.' };
              }
            } else if (group.controls['countryCode'].value == '91') {
                if (group.controls['secondary'].value == null || group.controls['secondary'].value.length == 0) {
                  return null;
                } else if (!(group.controls['secondary'].value + '').match(/^(6|7|8|9)[\d]{9,9}$/)) {
                  return { errorMsg: 'Please enter valid number.' };
                }
              } else {
              let maxMobLength: string = `${16 - group.controls['primarycountrycode'].value.length}`;
              if (!group.controls['mobileNo'].value.match(new RegExp('^[0-9]{0,' + maxMobLength + '}$'))) {
                return { errorMsg: 'Please enter valid number.' };
              }
            }
            return null;
          }
      }
      public static newPassword(control: AbstractControl) {
        /* if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return null;
        } else if (!control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&#+={}()_/|:,.~`\][\^\-])(?!.*[<>;*"\\\\\'\s])[A-Za-z\d$@$!%*?&#+={}()_/|:,.~`\][\^\-].{7,11}$/)) {
            return { errorMsg: 'Invalid Password' };
        } */
        let alphaNumRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,12}$/;
        let splCharRegex = /[\\\\<>';*\"]/;
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return null;
        } else if (!alphaNumRegex.test(control.value)) {
            return { errorMsg: 'Invalid Password' };
        } else if (splCharRegex.test(control.value)) {
            return { errorMsg: 'Invalid Password' };
        }
    } 
    public static surrenderPayoutPercent(control: AbstractControl) {
        const group = control.parent;
        if(group){
            if(group.controls['surrenderPayoutPercent'].value == null || group.controls['surrenderPayoutPercent'].value.length === 0){
                return { errorMsg: 'Please enter a value'};
            } else if(group.controls['payoutValue'].value == "Others" && (parseInt(group.controls['surrenderPayoutPercent'].value) < 1 || parseFloat(group.controls['surrenderPayoutPercent'].value) > 33.33333)){
                return { errorMsg: 'Please enter a value between 1 and 33.33333' };
            }
            else {
                return null;
            }
        }
    } 
    public static nriQuestOnlyAlphabets(control: AbstractControl) {
        if (control.value == null || control.value.length === 0) {
            return { errorMsg: 'Please enter details' };
        } else if (!control.value.match(/^[a-zA-Z ]{0,300}$/)) {
            return { errorMsg: 'Only alphabets allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }
    public static nriQuestAlphNumSpace(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter details' };
        } else if (!control.value.match(/^[a-zA-Z0-9 ]{0,300}$/)) {
            return { errorMsg: 'Only alphabets allowed' };
        } else if (!control.value.match(/^[^\s].*$/) || !control.value.match(/^.*[^\s]$/)) {
            return { errorMsg: 'Space not allowed at first or last character' };
        } else {
            return null;
        }
    }
    public static nriQuest(control: AbstractControl) {
        if (control.value == null || control.value.length === 0 || control.value == undefined) {
            return { errorMsg: 'Please enter details' };
        } else {
            return null;
        }
    }
    public static validSpecialChars(control : AbstractControl){
        if(control.value === null || control.value == undefined || control.value.length == 0){
            return { errorMsg : 'Please enter a value'};
        } else if(control.value.match(/[~!<>^%$*?]/)){
            return { errorMsg : 'Special character not allowed' }
        } else{
            return null;
        }
    }
}
