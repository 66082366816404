<div class="set-up-ids">
    <div class="set-up-id-wrapper">
        <mat-card class="dnd-options-page social-ids-page">
            <div class="dnd-options">
                <div class="dnd-options-header">
                    Set up social media ID
                </div>
                <div class="social-ids-page-section">
                    <!-- <div class="client-id">
                            <div class="client-id-section">Client ID</div>
                            <div class="client-id-number">{{UserClientID}}</div>
                        </div> -->
                    <div class="social-ids-page-content flex">
                        <div class="left-content">
                            <div class="name-div flex">
                                <img src="assets/person.svg" class="person-image ">
                                <div class="name-text-div">
                                    <div class="name-text">Client ID
                                        <img class="edit-button" src="assets/edit_btn.svg"
                                            (click)="redirectToMyAccount()">
                                    </div>
                                    <span class="name-span">
                                        <span class="text-span">{{UserClientID}}</span>&nbsp;<span></span>
                                    </span>
                                </div>
                            </div>
                            <div class="email-mobile flex">
                                <div class="name-div flex">
                                    <img src="assets/email.svg" class="person-image ">
                                    <div class="name-text-div">
                                        <div class="name-text">Email ID
                                            <img class="edit-button" src="assets/edit_btn.svg"
                                                (click)="redirectToMyAccount()">
                                        </div>
                                        <span class="name-span">
                                            <span class="text-span">{{UserEmailID}}</span>&nbsp;<span></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="name-div flex">
                                    <img src="assets/mobile.svg" class="person-image ">
                                    <div class="name-text-div">
                                        <div class="name-text">Mobile No
                                            <img class="edit-button" src="assets/edit_btn.svg"
                                                (click)="redirectToMyAccount()">
                                        </div>
                                        <span class="name-span">
                                            <span class="text-span">{{UserMobileNo}}</span>&nbsp;<span></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-content">
                            <div class="name-div flex">
                                <img src="/assets/facebook_hover.svg" class=" person-image ">
                                <div class="name-text-div">
                                    <div class="name-text">Facebook ID</div>
                                    <div class="name-span flex">
                                        <mat-form-field class="icon-form-field">
                                            <input matInput>
                                        </mat-form-field>
                                        <button class="upload-kyc-button">Add</button>
                                    </div>
                                </div>
                            </div>
                            <div class="name-div flex">
                                <img src="assets/google.svg" class="person-image ">
                                <div class="name-text-div">
                                    <div class="name-text">Google ID</div>
                                    <div class="name-span flex">
                                        <mat-form-field class="icon-form-field">
                                            <input matInput>
                                        </mat-form-field>
                                        <button class="upload-kyc-button">Add</button>
                                    </div>
                                </div>
                            </div>
                            <div class="name-div flex">
                                <img src="assets/twitter_hover.svg" class="person-image ">
                                <div class="name-text-div">
                                    <div class="name-text">Twitter ID</div>
                                    <div class="name-span flex">
                                        <mat-form-field class="icon-form-field">
                                            <input matInput>
                                        </mat-form-field>
                                        <button class="upload-kyc-button">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="social-id-footer">Disclaimer</div>
                    <div class="id-confirm-checkbox">
                        <mat-checkbox>
                            I will not hold HDFC Life responsible
                            for any risk associated with log in via
                            my Facebook/Twitter/Google id.
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>