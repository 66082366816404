import { Component, OnInit } from "@angular/core";
import { loans_T_C } from "../../../../utils/app-constants";

@Component({
    selector: 'loan-terms',
    templateUrl: './loans-terms-and-conditions.html',
    styleUrls: ['./loans-terms-and-conditions.css']
  })

  export class loanTermsComponent implements OnInit{ 

    lang : string = "english";
    showPage : boolean = true;  
    reference  : string = 'The Policyholder (hereinafter referred to as “Borrower”), has expressed the desire to receive an interest-bearing loan (“Loan”) from HDFC Life Insurance Company Limited (hereinafter referred to as “HDFC Life”) against the surrender value of the insurance policy (Policy), and HDFC Life agree to lend to the borrower such loan basis such surrender value and subject to the terms and conditions of the Policy, and the terms and conditions given below.'
    notAuthorizedError: string = "You are not authorised to view this page. Kindly get in touch with your nearest Branch or write an e-mail to our Service desk";
    mail ="service@hdfclife.com";
    items : any;
    ngOnInit(): void {
        this.items  = loans_T_C;
    }
    
}